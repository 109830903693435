import React, { Component } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { renderPriceField, renderField } from "../../utils/renderFields";
import { addMenuItemOption } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemOptionAdd",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.selection_type) {
    errors.selection_type = "Please choose type";
  }

  if (!formProps.items || !formProps.items.length) {
    errors.items = { _error: "At least one item must be entered" };
  } else {
    const itemsArrayErrors = [];
    formProps.items.forEach((item, itemIndex) => {
      const itemErrors = {};
      if (!item || !item.name) {
        itemErrors.name = "Required";
        itemsArrayErrors[itemIndex] = itemErrors;
      }
      // if (!item || !item.additional_cost) {
      //   itemErrors.additional_cost = 'Required'
      //   itemsArrayErrors[itemIndex] = itemErrors
      // }
    });
    if (itemsArrayErrors.length) {
      errors.items = itemsArrayErrors;
    }
  }

  return errors;
}

const renderOptionItems = ({ fields = [], meta: { error, submitFailed } }) => (
  <>
    <div className="form-row">
      <div className="col-6">Choices</div>
      <div className="col-6 text-right">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => {
            fields.push({ name: undefined, additional_cost: undefined });
          }}
        >
          <Icon.Plus size={16} />
          {` `}
          {`Add Choice`}
        </button>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
    <div className="form-row">
      <div className="col-12">
        <hr />
      </div>
    </div>
    <div className="form-row">
      <div className="col-1">
        {` `}
      </div>
      <div className="col-6">
        {`Name`}
      </div>
      <div className="col-3">
        {`Price`}
      </div>
      <div className="col-2">
        {`Hidden`}
      </div>
    </div>
    {fields.map((item, index) => (
      <React.Fragment key={index}>
        <div className="form-row">
          <div className="col-1">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm form-control"
              title="Remove Item"
              onClick={() => fields.remove(index)}
            >
              <Icon.Trash2 size={16} />
            </button>
          </div>
          <div className="col-6">
            <Field
              name={`${item}.name`}
              className="form-control"
              component={renderField}
              placeholder="Name"
              type="text"
            />
          </div>
          <div className="col-3">
            <Field
              name={`${item}.additional_cost`}
              className="form-control"
              component={renderPriceField}
              type="number"
            />
          </div>
          <div className="col-2">
            <Field
              name={`${item}.hidden_from_public`}
              className="form-control"
              component={renderField}
              type="checkbox"
            />
          </div>
        </div>
      </React.Fragment>
    ))}
  </>
);

class MenuItemOptionAddModal extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.addMenuItemOption(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      formProps
    );
    $(".close").click();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <>
        <button
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#menuItemOptionAddModal"
          data-backdrop="static"
        >
          <Icon.Plus size={16} />
          {` `}New Option
        </button>

        <div
          className="modal fade"
          id="menuItemOptionAddModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#menuItemOptionAddModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="menuItemOptionAddModalLabel">
                    {"Add Option"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label className="col-form-label">Name</label>
                          <Field
                            name="name"
                            className="form-control"
                            component={renderField}
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Type</label>
                          <div>
                            <div className="form-check form-check-inline">
                              <Field
                                name="selection_type"
                                className="form-check-input"
                                component="input"
                                type="radio"
                                value="Single"
                              />
                              <label className="form-check-label">
                                {` `}
                                {`Single Selection`}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Field
                                name="selection_type"
                                className="form-check-input"
                                component="input"
                                type="radio"
                                value="Multiple"
                              />
                              <label className="form-check-label">
                                {` `}
                                {`Multiple Selection`}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Minimum items to select</label>
                          <Field
                            name="min_selected"
                            className="form-control"
                            component={renderField}
                            type="number"
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Maximum items to select</label>
                          <Field
                            name="max_selected"
                            className="form-control"
                            component={renderField}
                            type="number"
                          />
                        </div>     
                        <div className="form-group">
                          <label className="col-form-label">Number of free options</label>
                          <Field
                            name="number_of_free_options"
                            className="form-control"
                            component={renderField}
                            type="number"
                          />
                        </div>   
                        <div className="form-group">
                          <label className="col-form-label">Note</label>
                          <Field
                            name="information"
                            className="form-control"
                            component={renderField}
                            type="number"
                          />
                        </div>    
                        <div className="form-group">
                          <label className="col-form-label">Visibility</label>
                          <div>
                          <div className="form-check form-check-inline">
                            <Field
                              name="hidden_from_public"
                              className="form-check-input"
                              component="input"
                              type="checkbox"
                            />
                            <label className="form-check-label">
                              {` `}
                              {`Hidden From Public`}
                            </label>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-8">
                        <FieldArray
                          name="items"
                          component={renderOptionItems}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {"Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMenuItemOption: (menuId, menuSectionId, menuItemId, formProps) =>
    dispatch(addMenuItemOption(menuId, menuSectionId, menuItemId, formProps)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MenuItemOptionAddModal));
