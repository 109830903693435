import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

import Numeral from "numeral";

import Loading from "../Common/Loading";

import {
  fetchCurrentInvoice,
  fetchSubscription,
  fetchBillingProfile,
} from "../../actions/tenant";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentInvoiceLoading: false,
      subscriptionLoading: false,
      billingProfileLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.setState({
        currentInvoiceLoading: true,
        subscriptionLoading: true,
        billingProfileLoading: true,
      });
      this.props.fetchCurrentInvoice(this.props.tenant._id, () => {
        this.setState({
          currentInvoiceLoading: false,
        });
      });
      this.props.fetchSubscription(this.props.tenant._id, () => {
        this.setState({
          subscriptionLoading: false,
        });
      });
      this.props.fetchBillingProfile(this.props.tenant._id, () => {
        this.setState({
          billingProfileLoading: false,
        });
      });
    }
  }

  render() {
    const { invoice, tenant, subscription, handleViewInvoice, billingProfile } =
      this.props;

    return (
      <div className="row">
        <div className="col-12 col-lg-8">
          {this.state.currentInvoiceLoading && <Loading />}
          {invoice && this.state.currentInvoiceLoading === false && (
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">{`Running Invoice Total`}</h3>
                    <h4>
                      <strong>
                        {Numeral(invoice.total).format("$0,0.00")}
                      </strong>
                    </h4>
                    <div className="row">
                      <div className="col-6">
                        <div className="mt-3">
                          <strong>{`Billing Period`}</strong>
                        </div>
                        <div className="mt-1">{`${moment(
                          invoice.billing_period_from
                        )
                          .tz(tenant.settings.time_zone)
                          .format(tenant.settings.date_format)} - ${moment(
                          invoice.billing_period_to
                        )
                          .tz(tenant.settings.time_zone)
                          .format(tenant.settings.date_format)}`}</div>
                      </div>
                      {/* <div className="col-6">
                              <div className="mt-3"><strong>{`Last Billed On`}</strong></div>
                              <div className="mt-1">{`${moment(invoice.billing_period_from).format('MM/DD/YYYY')} - ${moment(invoice.billing_period_to).format('MM/DD/YYYY')}`}</div>
                            </div> */}
                    </div>
                    {/* <div className="row mt-1">
                            <div className="col-6">
                              <div className="mt-3"><strong>{`Previous Invoice`}</strong></div>
                              <div className="mt-1">{`${Numeral(invoice.total).format("$0,0.00")}`}</div>
                            </div>
                            <div className="col-6">
        
                            </div>
                          </div> */}
                    <div className="row mt-3">
                      <div className="col-12">
                        <button
                          className="btn btn-info btn-block"
                          onClick={() => handleViewInvoice(invoice)}
                        >{`View Current Invoice`}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-8">
                        <strong>{`Description`}</strong>
                      </div>
                      <div className="col-4 text-right">
                        <strong>{`Running Total`}</strong>
                      </div>
                    </div>
                    <div className="row pb-1 border-bottom">
                      <div className="col-8">
                        <span>{`Website`}</span>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          {Numeral(invoice.website_fee).format("$0,0.00")}
                        </span>
                      </div>
                    </div>
                    <div className="row py-1 border-bottom">
                      <div className="col-8">
                        <span>{`Online Order`}</span>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          {Numeral(
                            invoice.online_order_fee.base_fee +
                              invoice.online_order_fee.quantity_based_fee +
                              invoice.online_order_fee.amount_based_fee
                          ).format("$0,0.00")}
                        </span>
                      </div>
                    </div>
                    <div className="row py-1 border-bottom">
                      <div className="col-8">
                        <span>{`Call-In Order`}</span>
                      </div>
                      <div className="col-4 text-right">
                        <span>
                          {Numeral(
                            invoice.call_in_order_fee.base_fee +
                              invoice.call_in_order_fee.quantity_based_fee +
                              invoice.call_in_order_fee.amount_based_fee
                          ).format("$0,0.00")}
                        </span>
                      </div>
                    </div>
                    {invoice.gift_card_order_fee && (
                      <div className="row py-1 border-bottom">
                        <div className="col-8">
                          <span>{`Gift Card Order`}</span>
                        </div>
                        <div className="col-4 text-right">
                          <span>
                            {Numeral(
                              invoice.gift_card_order_fee.base_fee +
                                invoice.gift_card_order_fee.quantity_based_fee +
                                invoice.gift_card_order_fee.amount_based_fee
                            ).format("$0,0.00")}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-4">
          <div className="card mb-3">
            <div className="card-body">
              {this.state.subscriptionLoading && <Loading />}
              {this.state.subscriptionLoading === false && (
                <>
                  <h3 className="card-title">{`Plan Details`}</h3>
                  {subscription && <p>{subscription.plan.name}</p>}
                </>
              )}
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body">
              <h3 className="card-title">{`Payment Method`}</h3>
              <div className="media">
                <i className="fas fa-file-invoice-dollar fa-3x mr-3" />
                <div className="media-body">
                  <div>
                    <strong>{`Active Invoicing Period`}</strong>
                  </div>
                  <div>{`We will inovice you based on monthly usage.`}</div>
                </div>
              </div>
              {/* <div className="mt-3">{`Ending in 6789`}</div>
              <div>{`Expires 07/23 `}</div> */}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">{`Billing Profile`}</h3>
              {this.state.billingProfileLoading && <Loading />}
              {billingProfile && this.state.billingProfileLoading === false && (
                <>
                  <div>
                    <strong>{`Account Created`}</strong>
                  </div>
                  <div>{`${moment(tenant.createdAt)
                    .tz(tenant.settings.time_zone)
                    .format(tenant.settings.date_format)} - ${moment(
                    tenant.createdAt
                  )
                    .tz(tenant.settings.time_zone)
                    .format(tenant.settings.time_format)}`}</div>

                  <div className="mt-3">
                    <strong>{`Billing Email Address`}</strong>
                  </div>
                  <div>{tenant.email}</div>

                  <div className="mt-3">
                    <strong>{`Invoice Address`}</strong>
                  </div>
                  <div>
                    {billingProfile.name}
                    <br />
                    {`${billingProfile.address_line_1} ${
                      billingProfile.address_line_2 ?? ""
                    }, ${billingProfile.city_town}, ${
                      billingProfile.province
                    }, ${billingProfile.postal_code}, ${
                      billingProfile.country
                    }`}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    invoice: state.tenant.currentInvoice,
    subscription: state.tenant.subscription,
    billingProfile: state.tenant.billingProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentInvoice: (tenantId, callback) =>
    dispatch(fetchCurrentInvoice(tenantId, callback)),
  fetchSubscription: (tenantId, callback) =>
    dispatch(fetchSubscription(tenantId, callback)),
  fetchBillingProfile: (tenantId, callback) =>
    dispatch(fetchBillingProfile(tenantId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
