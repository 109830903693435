import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import Switch from "react-switch";

import { renderDropzoneInput } from "../../utils/renderFields";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "loyaltyRewardProgramSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class LoyaltyRewardProgramSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleLoyaltyPointsPerOrderChange = this.handleLoyaltyPointsPerOrderChange.bind(
      this
    );
    this.handleLoyaltyPointsPerDollarChange = this.handleLoyaltyPointsPerDollarChange.bind(
      this
    );
    this.handleLoyaltyPointsPerReferralChange = this.handleLoyaltyPointsPerReferralChange.bind(
      this
    );
    this.state = {
      tenantId: props.tenant._id,
      loyalty_reward_program: props.tenant.settings.loyalty_reward_program,
      loyalty_points_per_order: props.tenant.settings.loyalty_points_per_order,
      loyalty_points_per_dollar:
        props.tenant.settings.loyalty_points_per_dollar,
      loyalty_points_per_referral:
        props.tenant.settings.loyalty_points_per_referral
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        loyalty_reward_program: this.state.loyalty_reward_program,
        loyalty_points_per_order: this.state.loyalty_points_per_order,
        loyalty_points_per_dollar: this.state.loyalty_points_per_dollar,
        loyalty_points_per_referral: this.state.loyalty_points_per_referral
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleLoyaltyPointsPerOrderChange(event) {
    this.setState({ loyalty_points_per_order: event.target.value });
  }

  handleLoyaltyPointsPerDollarChange(event) {
    this.setState({ loyalty_points_per_dollar: event.target.value });
  }

  handleLoyaltyPointsPerReferralChange(event) {
    this.setState({ loyalty_points_per_referral: event.target.value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      tenant,
      handleViewSettings,
      handleSubmit,
    } = this.props;

    return (
      <>
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewSettings}
            >
              <i className="fas fa-cog" />
              {` `}
              <span className="d-none d-sm-inline">{`Settings`}</span>
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <button type="button" className="btn btn-light" disabled>
              <i className="fa fa-award" />
              {` `}
              <span className="d-none d-sm-inline">{`Loyalty & Reward`}</span>
            </button>
          </li>
        </ol>
      </nav>
      <form onSubmit={handleSubmit(this.handleFormSubmit)}>
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Tenant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.tenant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Loyalty Reward Program
        </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => { this.setState(prevState => { return { loyalty_reward_program: !prevState.loyalty_reward_program } }) }}
              checked={this.state.loyalty_reward_program || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2
                  }}
                >
                  No
                  </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2
                  }}
                >
                  Yes
                  </div>
              }
            />

          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Loyalty Points Per Order
        </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="loyalty_points_per_order"
              value={this.state.loyalty_points_per_order}
              disabled={this.state.loyalty_reward_program === false}
              onChange={this.handleLoyaltyPointsPerOrderChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Loyalty Points Per Dollar
        </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="loyalty_points_per_dollar"
              value={this.state.loyalty_points_per_dollar}
              disabled={this.state.loyalty_reward_program === false}
              onChange={this.handleLoyaltyPointsPerDollarChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Loyalty Points Per Referral
        </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="loyalty_points_per_referral"
              value={this.state.loyalty_points_per_referral}
              disabled={this.state.loyalty_reward_program === false}
              onChange={this.handleLoyaltyPointsPerReferralChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Image
        </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            {tenant.settings.loyalty_reward_program_image && (
              <Field
                name="image"
                slug={tenant.slug}
                imgsrc={tenant.settings.loyalty_reward_program_image.url}
                component={renderDropzoneInput}
              />
            )}
            {(!tenant || !tenant.settings.loyalty_reward_program_image) && (
              <Field name="image" component={renderDropzoneInput} />
            )}
            <p className="text-muted pt-3">
              Image will be automaticaly resized to 330 X 240
              </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>
      </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(LoyaltyRewardProgramSettings));
