import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import moment from "moment";
import Numeral from "numeral";

import * as Icon from "react-feather";

import Pagination from "../Common/Pagination";
import { Bar, Pie } from "react-chartjs-2";

import {
  blockCustomer,
  unblockCustomer,
  fetchCustomer,
  fetchCustomerOrders,
} from "../../actions/tenant";

class SearchCustomerView extends Component {
  constructor(props) {
    super(props);

    this.handleBlockCustomer = this.handleBlockCustomer.bind(this);
    this.handleUnblockCustomer = this.handleUnblockCustomer.bind(this);
    this.handleViewCustomers = this.handleViewCustomers.bind(this);

    this.state = {
      allOrders: [],
      currentOrders: [],
      currentPage: null,
      totalPages: null,
    };
  }

  handleBlockCustomer() {
    this.props.blockCustomer(
      this.props.tenant._id,
      this.props.customer._id,
      (data) => {
        this.handleViewCustomers();
      }
    );
  }

  handleViewCustomers() {
    this.setState({ redirectTo: "/searchcustomers" });
  }

  handleUnblockCustomer() {
    this.props.unblockCustomer(
      this.props.tenant._id,
      this.props.customer._id,
      (data) => {
        this.handleViewCustomers();
      }
    );
  }

  componentDidMount() {
    if (this.props.match.params.customerId && this.props.tenant) {
      this.props.fetchCustomer(
        this.props.tenant._id,
        this.props.match.params.customerId,
        (data) => {}
      );
    }
  }

  onPageChanged = (data) => {
    const { orders } = this.props;
    const { currentPage, totalPages, pageLimit } = data;

    const offset = (currentPage - 1) * pageLimit;
    const currentOrders = orders.slice(offset, offset + pageLimit);

    this.setState({ currentPage, currentOrders, totalPages });
  };

  render() {
    const {
      customer,
      orders = [],
      favoriteItems = [],
      analyticsPerDay = {},
      analyticsPerMenu = {},
      tenant,
    } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (!customer) {
      return <></>;
    }

    const { currentOrders, currentPage, totalPages } = this.state;
    const totalOrders = orders.length;

    let orders_per_day_chart_data = {
      labels: analyticsPerDay.labels,
      datasets: [
        {
          label: "Orders",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: analyticsPerDay.orders,
        },
      ],
    };

    let analytics_per_section_chart_data = {
      labels: analyticsPerMenu.salesPerSection.labels,
      datasets: [
        {
          label: "Sales",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: analyticsPerMenu.salesPerSection.amount,
        },
      ],
    };

    let analytics_per_menu_chart_data = {
      labels: analyticsPerMenu.salesPerMenu.labels,
      datasets: [
        {
          label: "Sales",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          data: analyticsPerMenu.salesPerMenu.amount,
        },
      ],
    };

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleViewCustomers}
              >
                Customers
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Customer: {customer.name}
              </button>
            </li>
          </ol>
        </nav>
        {/* <div className="row pb-3">
        <div className="col-1">
          <button
            type="button"
            className="btn btn-light"
            onClick={handleViewCustomers}
          >
            <Icon.ChevronLeft size={24} />
          </button>
        </div>
      </div> */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row ">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-light mr-2"
                      onClick={this.handleViewCustomers}
                    >
                      <Icon.ChevronLeft size={24} />
                    </button>
                    <strong>{`Customer Details`}</strong>
                  </div>
                  <div className="col-6 text-right">
                    {!customer.isBlocked && (
                      <button
                        type="button"
                        className="btn btn-warning mr-2"
                        onClick={this.handleBlockCustomer}
                      >
                        {`Block This Customer`}
                      </button>
                    )}
                    {customer.isBlocked && (
                      <button
                        type="button"
                        className="btn btn-warning mr-2"
                        onClick={this.handleUnblockCustomer}
                      >
                        {`Unblock This Customer`}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Customer Name:</strong>
                    </span>
                    {customer.name ? customer.name : ""}
                  </div>
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Email:</strong>
                    </span>
                    {customer.email}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Phone:</strong>
                    </span>
                    {customer.telephone}
                  </div>
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Points:</strong>
                    </span>
                    {customer.points}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Joined Since:</strong>
                    </span>
                    {moment(customer.joinedSince).format(
                      tenant.settings.date_format
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Last Order On:</strong>
                    </span>
                    {moment(customer.lastOrderAt).format(
                      tenant.settings.date_format
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Total Orders:</strong>
                    </span>
                    {customer.totalOrders}
                  </div>
                  <div className="col-12 col-md-6">
                    <span className="mr-2">
                      <strong>Total Amount:</strong>
                    </span>
                    {Numeral(customer.totalAmount).format("$0,0.00")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Favorite Items</strong>
              </div>
              <div className="card-body text-center">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col-9" className="text-left">
                        Item
                      </th>
                      <th scope="col-3" className="text-right">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {favoriteItems.map((item, index) => (
                      <tr key={index}>
                        <td className="text-left">{item.name}</td>
                        <td className="text-right">{item.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Analytics By Day</strong>
              </div>
              <div className="card-body text-center">
                {analyticsPerDay.labels && (
                  <Bar
                    data={orders_per_day_chart_data}
                    options={{ barShowStroke: false }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Analytics By Category</strong>
              </div>
              <div className="card-body text-center">
                {analyticsPerMenu.salesPerSection.labels && (
                  <Bar
                    data={analytics_per_section_chart_data}
                    options={{ barShowStroke: false }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Analytics By Menu</strong>
              </div>
              <div className="card-body text-center">
                {analyticsPerMenu.salesPerMenu.labels && (
                  <Pie data={analytics_per_menu_chart_data} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header text-center">
                <div className="flex-row h-100 d-flex justify-content-between align-items-center">
                  <div>
                    <h5>
                      <strong className="text-secondary mr-2">
                        {totalOrders}
                      </strong>
                      Orders
                    </h5>
                  </div>
                  <div>
                    {currentPage && (
                      <h5 className="text-secondary">
                        Page{" "}
                        <span className="font-weight-bold">{currentPage}</span>{" "}
                        / <span className="font-weight-bold">{totalPages}</span>
                      </h5>
                    )}
                  </div>
                  <div className="mr-2">
                    <Pagination
                      totalRecords={totalOrders}
                      pageLimit={10}
                      pageNeighbours={1}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body text-center">
                <div className="row border-bottom pb-3 mb-3">
                  <div className="col-12 col-md-2 d-none d-md-inline text-left">
                    <strong>Order Number</strong>
                  </div>
                  <div className="col-12 col-md-3 d-none d-md-inline text-left">
                    <strong>Date</strong>
                  </div>
                  <div className="col-12 col-md-1 d-none d-md-inline text-right">
                    <strong>Amount</strong>
                  </div>
                  <div className="col-12 col-md-2 d-none d-md-inline text-left">
                    <strong>Payment Method</strong>
                  </div>
                  <div className="col-12 col-md-2 d-none d-md-inline text-left">
                    <strong>Type</strong>
                  </div>
                  <div className="col-12 col-md-2 d-none d-md-inline text-left">
                    <strong>Status</strong>
                  </div>
                </div>
                {currentOrders.map((order) => (
                  <div className="row border-bottom pb-3 mb-3" key={order._id}>
                    <div className="col-12 col-md-2 text-left">
                      <span className="d-inline d-md-none">
                        <strong>Order Number: </strong>{" "}
                      </span>{" "}
                      {order.orderNumber}
                    </div>
                    <div className="col-12 col-md-3 text-left">
                      <span className="d-inline d-md-none">
                        <strong>Date: </strong>
                      </span>
                      {moment(order.receivedAt).format(
                        tenant.settings.date_format
                      )}
                    </div>
                    <div className="col-12 col-md-1 text-left text-md-right">
                      <span className="d-inline d-md-none">
                        <strong>Amount: </strong>
                      </span>
                      {Numeral(order.total).format("$0,0.00")}
                    </div>
                    <div className="col-12 col-md-2 text-left ">
                      <span className="d-inline d-md-none">
                        <strong>Payment Method: </strong>
                      </span>
                      {order.paymentMethod}
                    </div>
                    <div className="col-12 col-md-2 text-left ">
                      <span className="d-inline d-md-none">
                        <strong>Order Type: </strong>
                      </span>
                      {order.orderType}
                    </div>
                    <div className="col-12 col-md-2 text-left ">
                      <span className="d-inline d-md-none">
                        <strong>Order Status: </strong>
                      </span>
                      {order.status}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    customer: state.tenant.customer,
    orders: state.tenant.orders,
    favoriteItems: state.tenant.favoriteItems,
    analyticsPerDay: state.tenant.analyticsPerDay,
    analyticsPerMenu: state.tenant.analyticsPerMenu,
  };
};

const mapDispatchToProps = (dispatch) => ({
  blockCustomer: (tenantId, customerviewId, callback) =>
    dispatch(blockCustomer(tenantId, customerviewId, callback)),
  unblockCustomer: (tenantId, customerviewId, callback) =>
    dispatch(unblockCustomer(tenantId, customerviewId, callback)),
  fetchCustomer: (tenantId, customerviewId, callback) =>
    dispatch(fetchCustomer(tenantId, customerviewId, callback)),
  fetchCustomerOrders: (tenantId, phone, callback) =>
    dispatch(fetchCustomerOrders(tenantId, phone, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomerView);
