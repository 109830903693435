import axios from "axios";
import Cookies from "universal-cookie";

import { STATIC_ERROR, FETCH_USER, SEND_CONTACT_FORM } from "./types";

const cookies = new Cookies();
//================================================================================
// Utility Actions
//================================================================================

export function fetchUser(uid) {
  return function(dispatch) {
    axios
      .get(process.env.REACT_APP_API_BASE + `/api/eatery/user/${uid}`, {
        headers: { Authorization: cookies.get("utoken") }
      })
      .then(response => {
        dispatch({
          type: FETCH_USER,
          payload: response.data.user
        });
      })
      .catch(response => dispatch(errorHandler(response.data.error)));
  };
}

export function errorHandler(dispatch, error, type) {
  console.log("errorHandler", error)
  let errorMessage = error?.data?.error;

  if (error?.status === 401) {
    errorMessage =
      "Username and/or password is not correct. Please try again";
  }

  dispatch({
    type,
    payload: errorMessage || error?.data?.message
  });
}

// Post Request
export function postData(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  data,
  callback
) {
  let headers = {};
  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get("utoken") } };
  }

  axios
    .post(url, data, headers)
    .then(response => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

// Post Request
export function postData2(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  data,
  callback
) {
  let headers = {};
  if (isAuthReq) {
    headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("utoken")
      }
    };
  } else {
    headers = { headers: { "Content-Type": "multipart/form-data" } };
  }

  axios
    .post(url, data, headers)
    .then(response => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

// Get Request
export function getData(action, errorType, isAuthReq, url, dispatch, callback) {
  let headers = { headers: { Locale: "en" } };

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get("utoken") } };
  }

  axios
    .get(url, headers)
    .then(response => {
      callback && callback(response.data);
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

// Put Request
export function putData(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  data,
  callback
) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get("utoken") } };
  }

  axios
    .put(url, data, headers)
    .then(response => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

export function patchData(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  data,
  callback
) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get("utoken") } };
  }

  axios
    .patch(url, data, headers)
    .then(response => {
      callback && callback(response.data);
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

export function patchData2(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  data,
  callback
) {

  let headers = {};
  if (isAuthReq) {
    headers = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: cookies.get("utoken")
      }
    };
  } else {
    headers = { headers: { "Content-Type": "multipart/form-data" } };
  }

  axios
    .patch(url, data, headers)
    .then(response => {
      callback && callback(response.data);
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      console.log(error)
      errorHandler(dispatch, error.response, errorType);
    });
}

// Delete Request
export function deleteData(
  action,
  errorType,
  isAuthReq,
  url,
  dispatch,
  callback
) {
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: cookies.get("utoken") } };
  }

  axios
    .delete(url, headers)
    .then(response => {
      callback && callback();
      dispatch({
        type: action,
        payload: response.data
      });
    })
    .catch(error => {
      errorHandler(dispatch, error.response, errorType);
    });
}

//=============================================================================
// Static Page Actions
//=============================================================================
export function sendContactForm({ name, email, telephone, subject, message }) {
  return function(dispatch) {
    axios
      .post(process.env.REACT_APP_API_BASE + `/api/eatery/communication/contact`, {
        senderName: name, senderEmail: email, senderTelephone: telephone, subject: subject, message: message, receiverEmail: "myfoodcourt@gmail.com"
      })
      .then(response => {
        dispatch({
          type: SEND_CONTACT_FORM,
          payload: response.data.message
        });
      })
      .catch(error => {
        errorHandler(dispatch, error.response, STATIC_ERROR);
      });
  };
}
