import { getData, postData } from './index';

import {
  FETCH_IMAGES,
  ADD_IMAGE,
  ADD_FOLDER,
  IMAGE_ERROR,
} from './types';

export function fetchImages(tenantId, relativePath, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/images` + (relativePath ? `?relativePath=${relativePath}` : ``);
  return dispatch => getData(FETCH_IMAGES, IMAGE_ERROR, true, url, dispatch, callback);
}


export function addImage(tenantId, { caption, image, relativePath }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/images`;
  let formData = new FormData();
  formData.append("caption", caption);

  if (image.length > 0) {
    formData.append("image", image[0], image[0].name);
  }

  if (relativePath) {
    formData.append("relativePath", relativePath);
  }

  return dispatch =>
    postData(
      ADD_IMAGE,
      IMAGE_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function addFolder(tenantId, { name, relativePath }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/folders`;

  return dispatch =>
    postData(
      ADD_FOLDER,
      IMAGE_ERROR,
      true,
      url,
      dispatch,
      {
        name: name,
        relativePath: relativePath
      },
      callback
    );
}


