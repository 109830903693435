import React, { Component } from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

import "./CustomerReceipt.css";
import { sort_menu_items } from "../../utils/sortUtil";

class CustomerReceipt extends Component {
  render() {
    const { order, tenant, restaurant, coupon } = this.props;
    if (!order) {
      return <></>;
    }

    const startAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`;

    const endAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
          .add(
            (order.deliveryMethod === "Pickup" ? 1 : 2) *
              restaurant.settings.pickup_time_interval_in_minutes,
            "minutes"
          )
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
          .add(
            (order.deliveryMethod === "Pickup" ? 1 : 2) *
              restaurant.settings.pickup_time_interval_in_minutes,
            "minutes"
          )
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`;

    const pickupTimeRange = `${startAt} - ${endAt}`;

    const sorted_items = sort_menu_items(order.items);

    return (
      <div className="container-fluid">
        {/* <div className="card mb-3">
          <div className="card-header text-white bg-info font-weight-bold">
            <div className="row">
              <div className="col-6">
                <span>{`Paid ${order.paymentMethod}`}</span>
              </div>
              <div className="col-6 text-right">
                {order.onlinePaymentInfo &&
                  order.onlinePaymentInfo.cardBrand === "Visa" && (
                    <img src={visa_icon} alt="visa" width={25} height={16} />
                  )}
                {order.onlinePaymentInfo &&
                  order.onlinePaymentInfo.cardBrand === "MasterCard" && (
                    <img
                      src={mastercard_icon}
                      alt="mastercard"
                      width={25}
                      height={16}
                    />
                  )}
                {order.onlinePaymentInfo &&
                  order.onlinePaymentInfo.cardBrand === "American Express" && (
                    <img src={amex_icon} alt="amex" width={25} height={16} />
                  )}
              </div>
            </div>
          </div>
          {order.paymentMethod === "Online" && order.onlinePaymentInfo && (
            <div className="card-body">
              <div className="row">
                <div className="col-6">{`EXP ${order.onlinePaymentInfo.cardExpMonth
                  }/${order.onlinePaymentInfo.cardExpYear}`}</div>
                <div className="col-6 text-right">{`ending in ${order.onlinePaymentInfo.cardLast4
                  }`}</div>
              </div>
            </div>
          )}
        </div>
        <div className="card text-white bg-info mb-3">
          <div className="card-header font-weight-bold">
            {order.adjustedPickupTime
              ? moment(order.adjustedPickupTime)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)
              : moment(order.pickupTime)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span className="font-weight-bold">{order.deliveryMethod}</span>
            <span>{` Time:`}</span>
          </div>
          <div className="col-6 text-right">{pickupTimeRange}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {order.deliveryMethod === "Delivery" &&
          order.deliveryAddress &&
          order.deliveryAddress.address && (
            <div className="row">
              <div className="col-12">
                {`Address: ${order.deliveryAddress.address}`}
              </div>
            </div>
          )}
        {order.deliveryMethod === "Delivery" &&
          order.deliveryAddress &&
          order.deliveryAddress.suiteApartmentNumber && (
            <div className="row">
              <div className="col-12">
                {`SuiteApartment#: ${order.deliveryAddress.suiteApartmentNumber}`}
              </div>
            </div>
          )}
        {order.deliveryMethod === "Delivery" &&
          order.deliveryAddress &&
          order.deliveryAddress.businessName && (
            <div className="row">
              <div className="col-12">
                {`Business Name: ${order.deliveryAddress.businessName}`}
              </div>
            </div>
          )}
        {order.deliveryMethod === "Delivery" &&
          order.deliveryAddress &&
          order.deliveryAddress.otherVenueName && (
            <div className="row">
              <div className="col-12">
                {`Other Venue: ${order.deliveryAddress.otherVenueName}`}
              </div>
            </div>
          )}
        {order.deliveryMethod === "Delivery" && (
          <div className="row">
            <div className="col-12">
              <hr className="dashed-line" />
            </div>
          </div>
        )}
        {/* <div className="row">
          <div className="col-12">
            <strong>Order details:</strong>
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span>Order #:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.orderNumber}</span>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6">
            <span>Accepted at:</span>
          </div>
          <div className="col-6 text-right">
            <span>
              {moment(order.confirmedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span>Phone:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.phone}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Name:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.nameOrderedUnder}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {order.specialRequests && (
          <>
            <div className="row">
              <div className="col-12">
                <i className="fas fa-comment mr-3" />
                <strong className="highlight-text">
                  {order.specialRequests}
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="dashed-line" />
              </div>
            </div>
          </>
        )}
        {/* <div className="row">
          <div className="col-12">
            <strong>Order details:</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <strong>Order details:</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Number:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.orderNumber}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Placed at:</span>
          </div>
          <div className="col-6 text-right">
            <span>
              {moment(order.receivedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Accepted at:</span>
          </div>
          <div className="col-6 text-right">
            <span>
              {moment(order.confirmedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Fulfilled at:</span>
          </div>
          <div className="col-6 text-right">
            <span>
              {moment(order.fulfilledAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <strong>Customer info:</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Customer Name:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.nameOrderedUnder}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Email:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.email}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Phone:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.phone}</span>
          </div>
        </div>
        {order.specialRequests && (
          <div className="row">
            <div className="col-2">
              <i className="fas fa-comment" />
            </div>
            <div className="col-10">
              <strong>{order.specialRequests}</strong>
            </div>
          </div>
        )} */}
        {/* <div className="row pt-3 border-bottom mb-3">
          <div className="col-12">
            <strong>Item:</strong>
          </div>
        </div> */}
        {sorted_items.map((item) => (
          <div key={item._id} className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-9">
                  <strong>{`${item.quantity}X `}</strong>
                  {item.cd && <span>{`${item.cd}.`}</span>}
                  <span>{` ${item.name}`}</span>
                  <ul className="list-unstyled ml-3">
                    {item.size && (
                      <li className="text-muted font-italic">
                        <small className="highlight-text">
                          Size: {item.size}
                        </small>
                      </li>
                    )}
                    {item.options.map((option, index) => (
                      <li className="text-muted font-italic" key={index}>
                        <small className="highlight-text">
                          {`${option.name}`}
                          {option.value && <span>{`: ${option.value}`}</span>}
                          {option.total && (
                            <span>{` (${Numeral(option.total).format(
                              "$0,0.00"
                            )})`}</span>
                          )}
                        </small>
                      </li>
                    ))}
                    {item.specialInstructions &&
                      item.specialInstructions.length > 0 &&
                      item.specialInstructions.map((instruction, index) => (
                        <li
                          className="text-muted font-italic"
                          key={`si-${index}`}
                        >
                          <small className="highlight-text">
                            {instruction}
                          </small>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="col-3 text-right">
                  <span>
                    {Numeral(item.baseTotal + item.extraTotal).format(
                      "$0,0.00"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
        {order.promotions &&
          order.promotions.map((promotion) => (
            <div key={promotion._id} className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-9">
                    <strong>{`${promotion.quantity}X`}</strong>
                    <span>{`${promotion.promotionName}`}</span>
                    <br />
                    <span className="promotion-label">{`You saved: ${Numeral(
                      promotion.discountAmount
                    ).format("$0,0.00")}`}</span>
                  </div>
                  <div className="col-3 text-right">
                    <span>{Numeral(promotion.amount).format("$0,0.00")}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <hr className="dashed-line" />
                  </div>
                </div>
                {promotion.discountedItems &&
                  promotion.discountedItems.map((item) => (
                    <React.Fragment key={item._id}>
                      <div className="row">
                        <div className="col-9">
                          {item.cd && <span>{`${item.cd}.`}</span>}
                          <span>{` ${item.name}`}</span>
                          <ul className="list-unstyled ml-3">
                            {item.size && (
                              <li className="text-muted font-italic">
                                <small>Size: {item.size}</small>
                              </li>
                            )}
                            {item.options.map((option, index) => (
                              <li
                                className="text-muted font-italic"
                                key={index}
                              >
                                <small>
                                  {`${option.name}:`}
                                  {option.value && (
                                    <span>{` ${option.value}`}</span>
                                  )}
                                  {option.total && (
                                    <span>{` (${Numeral(option.total).format(
                                      "$0,0.00"
                                    )})`}</span>
                                  )}
                                </small>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="col-3 text-right">
                          <div className="text-muted font-italic">
                            {Numeral(item.baseTotal + item.extraTotal).format(
                              "$0,0.00"
                            )}
                          </div>
                          {item.discountAmount > 0 && (
                            <div className="text-muted font-italic">
                              {Numeral(-1 * item.discountAmount).format(
                                "$0,0.00"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {item.specialInstructions && (
                        <div className="row">
                          <div className="col-12">
                            <i className="fas fa-comment mr-3" />
                            <span>{item.specialInstructions}</span>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ))}
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        <div className="row">
          <div className="col-10">{`Subtotal`}</div>
          <div className="col-2 text-right">
            {Numeral(order.subtotal).format("$0,0.00")}
          </div>
        </div>
        <div className="row">
          <div className="col-10">{`GST`}</div>
          <div className="col-2 text-right">
            {Numeral(order.gst).format("$0,0.00")}
          </div>
        </div>
        <div className="row">
          <div className="col-10">{`PST`}</div>
          <div className="col-2 text-right">
            {Numeral(order.pst).format("$0,0.00")}
          </div>
        </div>
        {order.deliveryMethod === "Delivery" && (
          <>
            <div className="row">
              <div className="col-10">{`Delivery Fee`}</div>
              <div className="col-2 text-right">
                {Numeral(order.deliveryFee).format("$0,0.00")}
              </div>
            </div>
            <div className="row">
              <div className="col-10">{`Delivery GST`}</div>
              <div className="col-2 text-right">
                {Numeral(order.deliveryGST).format("$0,0.00")}
              </div>
            </div>
          </>
        )}
        {order.discounts &&
          order.discounts
            .filter((discount) => discount.isNextOrderCoupon === false)
            .map((discount, index) => (
              <div className="row" key={index}>
                <div className="col-9">
                  <span>{`Coupon:`}</span>
                </div>
                <div className="col-3 text-right">
                  {discount.amount && (
                    <span>
                      {Numeral(-1 * discount.amount).format("$0,0.00")}
                    </span>
                  )}
                </div>
              </div>
            ))}
        {order.redeemedGiftCards &&
          order.redeemedGiftCards.map((redeemedGiftCard, index) => (
            <div className="row" key={index}>
              <div className="col-9">
                <span>{`Gift Card: ${redeemedGiftCard.giftCardNumber}`}</span>
              </div>
              <div className="col-3 text-right">
                {redeemedGiftCard.amountToRedeem && (
                  <span>
                    {Numeral(-1 * redeemedGiftCard.amountToRedeem).format(
                      "$0,0.00"
                    )}
                  </span>
                )}
              </div>
            </div>
          ))}
        <div className="row">
          <div className="col-10">
            <span>{`Total`}</span>
          </div>
          <div className="col-2 text-right">
            <span>{Numeral(order.total).format("$0,0.00")}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {coupon && (
          <>
            <div className="row">
              <div className="col-12">
                {`${
                  coupon.name
                }. Code: ${coupon.couponCode.toUpperCase()}. Expires: ${moment(
                  coupon.validUntil
                )
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.date_format)}`}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="dashed-line" />
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12 text-center">
            {tenant.website_url && (
              <div className="row">
                <div className="col-12 text-center">
                  {`Order Online`}
                  <br className="mb-3" />
                  {tenant.website_url}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {restaurant.name}
            <br />
            {`${restaurant.address_1}, ${restaurant.city_town}, ${restaurant.state_province}`}
            <br />
            {`${restaurant.phone}`}
            <br />
            {`GST#: ${restaurant.settings.gst_number}`}
          </div>
        </div>
        {/* <hr className="dashed-line" />
        <div className="row">
          <div className="col-12 font-weight-bold">
            {`Customer confirmation`}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {`I acknowledge reception of order ID ${order.orderNumber} from ${restaurant.name
              } on ${moment(order.fulfilledAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}.`}
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">{`My name is:`}</div>
        </div>
        <div className="row pb-2">
          <div className="col-12">
            <i className="far fa-circle mr-2" />
            {order.nameOrderedUnder}
          </div>
        </div>
        <div className="row pb-2">
          <div className="col-12">
            <i className="far fa-circle mr-2" />
            <span>{`..........................................`}</span>
            <br />
            <span className="ml-4">
              <small>{`I am authorized to act on behalf of ${order.nameOrderedUnder
                }`}</small>
            </span>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">{`Signature:..........................................`}</div>
        </div> */}
      </div>
    );
  }
}

export default CustomerReceipt;
