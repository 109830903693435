import {
  ADD_COUPON,
  UPDATE_COUPON,
  COUPON_ERROR,
  FETCH_COUPONS,
  FETCH_EMAIL_BY_PHONE,
  GENERATE_COUPON_CODE,
  FETCH_COUPON_BY_ORDER_NUMBER
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  coupons: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_COUPON:
      return {
        ...state,
        coupons: [action.payload.coupon, ...state.coupons],
        couponCode: ""
      };
    case UPDATE_COUPON:
      return {
        ...state,
        coupons: state.coupons.map(coupon => {
          if (coupon._id === action.payload.coupon._id) {
            return action.payload.coupon;
          } else {
            return coupon;
          }
        })
      };
    case FETCH_COUPONS:
      return {
        ...state,
        coupons: action.payload.coupons,
      };
    case FETCH_EMAIL_BY_PHONE:
      return {
        ...state,
        emails: action.payload.emails,
      };
    case FETCH_COUPON_BY_ORDER_NUMBER:
      return {
        ...state,
        coupon: action.payload.coupon,
      };
    case GENERATE_COUPON_CODE:
      return {
        ...state,
        couponCode: action.payload.couponCode,
      };
    case COUPON_ERROR:
      return { ...state, errorMessage: action.payload, coupon: undefined };
    default:
      return state;
  }
}
