import React, { Component } from "react";
import { connect } from "react-redux";

import CartItems from "./CartItems";
import CartPromotions from "./CartPromotions";
import Subtotal from "./Subtotal";
import Tax from "./Tax";
import Total from "./Total";

import {
  removeFromCart,
  removePromotionFromCart,
  decreaseQuantity,
  increaseQuantity,
  getSubtotal,
  getTax,
  getGST,
  getPST,
  getQuantity,
  clearCart,
} from "../../actions/cart";
import CartDiscounts from "./CartDiscounts";
import DeliveryFee from "./DeliveryFee";
import DeliveryWarning from "./DeliveryWarning";

class Cart extends Component {
  constructor(props) {
    super(props);

    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleRemovePromotion = this.handleRemovePromotion.bind(this);
    this.handleIncreaseQuantity = this.handleIncreaseQuantity.bind(this);
    this.handleDecreaseQuantity = this.handleDecreaseQuantity.bind(this);

  }

  handleRemoveItem(item) {
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
      restaurantId: this.props.restaurant._id
    };
    this.props.removeFromCart(item, promoData);
  }

  handleRemovePromotion(promotion) {
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
      restaurantId: this.props.restaurant._id
    };
    this.props.removePromotionFromCart(promotion, promoData);
  }

  handleDecreaseQuantity(item) {
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
      restaurantId: this.props.restaurant._id
    };
    this.props.decreaseQuantity(item, promoData);
  }

  handleIncreaseQuantity(item) {
    const promoData = {
      isCouponEnabled: this.props.tenant.settings.coupon,
      promotionRules: this.props.promotionRules,
      timezone: this.props.restaurant.settings.time_zone,
      timeFormat: this.props.restaurant.settings.time_format,
      deliveryMethod: this.props.deliveryMethod,
      pickupTime: this.props.pickupTime,
      pickupDate: this.props.pickupDate,
      deliveryDistance: this.props.deliveryDistance,
      postalCode: this.props.postalCode,
      deliveryFee: this.props.deliveryFee,
      deliveryGST: this.props.deliveryGST,
      restaurantId: this.props.restaurant._id
    };
    this.props.increaseQuantity(item, promoData);
  }

  render() {

    const {
      items,
      promotions,
      subtotal,
      tax,
      gst,
      pst,
      taxRate,
      gstRate,
      pstRate,
      total,
      discounts,
      showSubtotal = true,
      showTax = true,
      showTotal = true,
      deliveryMethod,
      restaurant,
      deliveryFee,
      deliveryGST,
      deliveryDistance,
    } = this.props;

    const changeable = true;

    // if (!restaurant) {
    //   return <ChooseRestaurant />;
    // }
    return (
      <>
        {this.props.messages && (
          <ul>
            {this.props.messages.map((message, index) => (
              <li key={index} className="alert alert-info">
                {message}
              </li>
            ))}
          </ul>
        )}
        <div className="card">
          <div className="card-header">
            {/* <strong>
            {quantity} {quantity > 1 ? "ITEMS" : "ITEM"} IN CART
          </strong> */}
            <div className="row">
              <div className="col-7 col-cart-angkor">
                <strong>{`Item`}</strong>
              </div>
              <div className="col-3 col-cart-angkor text-right">
                <strong>{`Price`}</strong>
              </div>
              <div className="col-2 col-cart-angkor">{` `}</div>
            </div>
          </div>
          <div className="card-body">
            <CartItems
              items={items}
              handleRemoveItem={this.handleRemoveItem}
              changeable={changeable}
              enableSpecialInstruction={
                restaurant.settings.special_instruction_settings
                  ?.enable_special_instruction
              }
            />
            <CartPromotions
              promotions={promotions}
              handleRemovePromotion={this.handleRemovePromotion}
              changeable={changeable}
            />
            {(!items || items.length === 0) &&
              (!promotions || promotions.length === 0) && (
                <div className="row border-bottom pb-3">
                  <div className="col-12">Your cart is empty.</div>
                </div>
              )}

            {showSubtotal && <Subtotal subtotal={subtotal} />}
            {showTax && (
              <Tax
                tax={tax}
                taxRate={taxRate}
                gst={gst}
                gstRate={gstRate}
                pst={pst}
                pstRate={pstRate}
              />
            )}
            {deliveryMethod === "Delivery" && (
              <DeliveryFee
                deliveryFee={deliveryFee}
                deliveryGST={deliveryGST}
                gstRate={restaurant ? restaurant.settings.gst_rate : 0}
                deliveryDistance={deliveryDistance}
              />
            )}
            <CartDiscounts discounts={discounts} />
            {showTotal && (
              <Total total={total} />
            )}
            {deliveryMethod === "Delivery" && (
              <DeliveryWarning
                subtotal={subtotal}
                minimumOrderAmount={
                  restaurant.settings.delivery_options.minimum_order_amount
                }
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const taxRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.tax_rate
    : 0;
  const gstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.gst_rate
    : 0;
  const pstRate = state.tenant.restaurant
    ? state.tenant.restaurant.settings.pst_rate
    : 0;
  return {
    tenant: state.tenant.tenant,
    items: state.cart.items,
    promotions: state.cart.promotions,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    taxRate: taxRate,
    gst: getGST(state, gstRate),
    gstRate: gstRate,
    pst: getPST(state, pstRate),
    pstRate: pstRate,
    quantity: getQuantity(state),
    removable: true,
    promotionRules: state.tenant.promotions || [],
    messages: state.cart.messages,
    discounts: state.cart.discounts,
    discountTotal: state.cart.discountTotal,
    minimumOrderAmount:
      state.tenant.restaurant.settings.delivery_options.minimum_order_amount,

  };
};

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (item, promoData) => dispatch(removeFromCart(item, promoData)),
  removePromotionFromCart: (promo, promoData) => dispatch(removePromotionFromCart(promo, promoData)),
  increaseQuantity: (item, promoData) => dispatch(increaseQuantity(item, promoData)),
  decreaseQuantity: (item, promoData) => dispatch(decreaseQuantity(item, promoData)),
  clearCart: () => dispatch(clearCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
