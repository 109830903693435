import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { fetchSubscription } from "../../actions/tenant";
import PropTypes from "prop-types";
import Numeral from "numeral";

const form = reduxForm({
  form: "changeSubscription",
  validate
});

function validate(formProps) {
  const errors = {};



  return errors;
}

class ChangeSubscription extends Component {

  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchSubscription(this.props.tenant._id);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { subscription } = this.props;
    if (!subscription) {
      return null;
    }

    return (
      <>
      <div className="row pt-3 pb-6 mb-3 border-bottom">
        <div className="col-12">
          <h3>
            <strong>CHANGE SUBSCRIPTION</strong>
          </h3>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          {`Current Plan: ${subscription.plan.name}`}
        </div>
        <div className="card-body">
          <p>{`Features:`}</p>
          <ul>
            {subscription.plan.features && subscription.plan.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
          <p>{`Website Monthly Fee: ${Numeral(subscription.website_monthly_base_fee).format("$0,0.00")}`}</p>
          <p>{`Online Order Base Monthly Fee: ${Numeral(subscription.online_order_monthly_base_fee).format("$0,0.00")}`}</p>
          <p>{`Online Order Unit Price Per Order: ${Numeral(subscription.online_order_unit_price_per_order).format("$0,0.00")}`}</p>
          <p>{`Online Order Unit Price Per Dollar: ${Numeral(subscription.online_order_unit_price_per_dollar * 100).format("0,0.00")}%`}</p>
          <p>{`Call-In Order Base Monthly Fee: ${Numeral(subscription.call_in_order_monthly_base_fee).format("$0,0.00")}`}</p>
          <p>{`Call-In Order Unit Price Per Order: ${Numeral(subscription.call_in_order_unit_price_per_order).format("$0,0.00")}`}</p>
          <p>{`Call-In Order Unit Price Per Dollar: ${Numeral(subscription.call_in_order_unit_price_per_dollar * 100).format("0,0.00")}%`}</p>
          <p>{`Gift Card Order Base Monthly Fee: ${Numeral(subscription.gift_card_order_monthly_base_fee).format("$0,0.00")}`}</p>
          <p>{`Gift Card Order Unit Price Per Order: ${Numeral(subscription.gift_card_order_unit_price_per_order).format("$0,0.00")}`}</p>
          <p>{`Gift Card Order Unit Price Per Dollar: ${Numeral(subscription.gift_card_order_unit_price_per_dollar * 100).format("0,0.00")}%`}</p>
        </div>
      </div>
      
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    tenant: state.tenant.tenant,
    subscription: state.tenant.subscription
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSubscription: (tenantId) =>
    dispatch(fetchSubscription(tenantId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ChangeSubscription));
