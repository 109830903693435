import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import {
  updateRestaurantInfo,

} from "../../actions/restaurant";

import { renderEmailField, renderPhoneField, renderField } from '../../utils/renderFields';
import normalizePhone from '../../utils/normalizePhone';

const form = reduxForm({
  form: "restaurantInfo",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter restaurant name";
  }

  return errors;
}

class EditRestaurant extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {

  }


  handleFormSubmit(formProps) {
    this.props.updateRestaurantInfo(formProps, () => {
      this.props.handleViewRestaurants()
    });

  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewRestaurants, restaurant, handleSubmit } = this.props;
    return (
      <>
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewRestaurants}
            >
              {`Restaurants`}
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <button type="button" className="btn btn-light">
              {restaurant.name}
            </button>
          </li>
        </ol>
      </nav>
      <form onSubmit={handleSubmit(this.handleFormSubmit)}>
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Restaurant Name</label>
          <div className="col-lg-6">
            <Field
              name="name"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Brief Description</label>
          <div className="col-lg-6">
            <Field
              name="brief_description"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Full Description</label>
          <div className="col-lg-6">
            <Field
              name="full_description"
              className="form-control"
              component="textarea"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">
            Street Address</label>
          <div className="col-lg-6">
            <Field
              name="address_1"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Address 2</label>
          <div className="col-lg-6">
            <Field
              name="address_2"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">
            City</label>
          <div className="col-lg-6">
            <Field
              name="city_town"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Province</label>
          <div className="col-lg-6">
            <Field
              name="state_province"
              className="form-control"
              component="select"
            >
              <option />
              <option value="SK">Saskatchewan</option>
              <option value="AB">Alberta</option>
            </Field>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Postal Code</label>
          <div className="col-lg-6">
            <Field
              name="postal_code"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>

        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Country</label>
          <div className="col-lg-6">
            <Field
              name="country"
              className="form-control"
              component="select"
            >
              <option />
              <option value="CA">Canada</option>
              <option value="US">United States</option>
            </Field>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Latitude</label>
          <div className="col-lg-6">
            <Field
              name="latitude"
              className="form-control"
              component={renderField}
              type="number"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Longitude</label>
          <div className="col-lg-6">
            <Field
              name="longitude"
              className="form-control"
              component={renderField}
              type="number"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Email</label>
          <div className="col-lg-6">
            <Field
              name="email"
              className="form-control"
              component={renderEmailField}
              type="email"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Phone</label>
          <div className="col-lg-6">
            <Field
              name="phone"
              className="form-control"
              component={renderPhoneField}
              type="text"
              normalize={normalizePhone}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-lg-2 text-lg-right">Fax</label>
          <div className="col-lg-6">
            <Field
              name="fax"
              className="form-control"
              component={renderPhoneField}
              type="text"
              normalize={normalizePhone}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={handleViewRestaurants}
            >
              {`Cancel`}
            </button>
            <button type="submit" className="btn btn-primary">
              {"Save"}
            </button>
          </div>
        </div>
      </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
    initialValues: ownProps.restaurant
  };
};

const mapDispatchToProps = dispatch => ({

  updateRestaurantInfo: (formProps, callback) => dispatch(updateRestaurantInfo(formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(EditRestaurant));
