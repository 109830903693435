import React, { Component } from "react";

class AllowedOrderChannel extends Component {
  render() {
    const {
      allowedChannel,
      handleAllowedChannelChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Allowed order channel:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="allowed_order_channel_callin"
              defaultChecked={allowedChannel?.includes("CallIn")}
              onChange={handleAllowedChannelChange}
            />
            <label className="form-check-label">{`CallIn`}</label>
          </div>
          <div className="form-check mb-3">
          <input
              className="form-check-input"
              type="checkbox"
              name="allowed_order_channel_online"
              defaultChecked={allowedChannel?.includes("Online")}
              onChange={handleAllowedChannelChange}
            />
            <label className="form-check-label">
              {`Online`}
            </label>
          </div>
        </div>
      </div>

    );
  }
}

export default AllowedOrderChannel;