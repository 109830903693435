import React from "react";

import Numeral from "numeral";

const CartDiscounts = ({ discounts = [] }) => {

  return (
    <>
      {discounts.map((discount, index) => (
        <React.Fragment key={index}>
        <div className="row border-bottom pb-3 pt-3 mb-3 bg-light" key={index}>
          <div
            className="col-7 text-left col-cart-angkor"
          >
            <span><strong>{discount.discount_name}</strong></span><br/>
            <span className="promotion-label">{`You saved: ${Numeral(discount.discount_amount).format("$0,0.00")}`}</span>
          </div>
          <div className="col-3 text-right col-cart-angkor">
            <span>              
                <strong>{Numeral(-1 * discount.discount_amount).format("$0,0.00")}</strong>
            </span>
          </div>
        </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default CartDiscounts;
