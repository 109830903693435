import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";

import currency_data from "currency-codes/data";

const form = reduxForm({
  form: "taxRateSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class TaxRateSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleTaxRateChange = this.handleTaxRateChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleGstRateChange = this.handleGstRateChange.bind(this);
    this.handlePstRateChange = this.handlePstRateChange.bind(this);

    this.handleGstNumberChange = this.handleGstNumberChange.bind(this);
    this.handlePstNumberChange = this.handlePstNumberChange.bind(this);
    this.handleHstNumberChange = this.handleHstNumberChange.bind(this);

    this.state = {
      restaurantId: props.restaurant._id,
      tax_rate: props.restaurant.settings.tax_rate,
      currency_code: props.restaurant.settings.currency_code,
      gst_rate: props.restaurant.settings.gst_rate,
      pst_rate: props.restaurant.settings.pst_rate,
      gst_number: props.restaurant.settings.gst_number,
      pst_number: props.restaurant.settings.pst_number,
      hst_number: props.restaurant.settings.hst_number,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        tax_rate: this.state.tax_rate,
        currency_code: this.state.currency_code,
        gst_rate: this.state.gst_rate,
        pst_rate: this.state.pst_rate,
        gst_number: this.state.gst_number,
        pst_number: this.state.pst_number,
        hst_number: this.state.hst_number,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleTaxRateChange(event) {
    this.setState({ tax_rate: event.target.value });
  }

  handleCurrencyChange(event) {
    this.setState({ currency_code: event.target.value });
  }

  handleGstRateChange(event) {
    this.setState({ gst_rate: event.target.value });
  }

  handlePstRateChange(event) {
    this.setState({ pst_rate: event.target.value });
  }

  handleGstNumberChange(event) {
    this.setState({ gst_number: event.target.value });
  }

  handlePstNumberChange(event) {
    this.setState({ pst_number: event.target.value });
  }

  handleHstNumberChange(event) {
    this.setState({ hst_number: event.target.value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } =
      this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-dollar-sign" />
                {` `}
                <span className="d-none d-sm-inline">{`Tax Rate`}</span>
              </button>
            </li>
          </ol>
        </nav>

        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Restaurant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.restaurant.name}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Currency
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              className="form-control"
              name="currency_code"
              value={this.state.currency_code}
              onChange={this.handleCurrencyChange}
            >
              {currency_data &&
                currency_data.map((currency, index) => (
                  <option
                    key={index}
                    value={currency.code}
                  >{`${currency.code} - ${currency.currency}`}</option>
                ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Tax Rate
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="input-group">
              <input
                type="number"
                className="form-control"
                name="tax_rate"
                value={this.state.tax_rate}
                onChange={this.handleTaxRateChange}
              />
              <div className="input-group-append">
                <span className="input-group-text">%</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            GST Rate
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="gst_rate"
              value={this.state.gst_rate}
              onChange={this.handleGstRateChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            PST Rate
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="pst_rate"
              value={this.state.pst_rate}
              onChange={this.handlePstRateChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            GST Number
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="gst_number"
              value={this.state.gst_number}
              onChange={this.handleGstNumberChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            PST Number
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="pst_number"
              value={this.state.pst_number}
              onChange={this.handlePstNumberChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            HST Number
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="hst_number"
              value={this.state.hst_number}
              onChange={this.handleHstNumberChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              // disabled={pristine || submitting}
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, formProps, callback) =>
    dispatch(updateSettings(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(TaxRateSettings));
