import {
  FETCH_GIFTCARDPRODUCTS,
  GIFTCARDPRODUCT_ERROR,
  ADD_GIFTCARDPRODUCT,
  UPDATE_GIFTCARDPRODUCT,
  DELETE_GIFTCARDPRODUCT
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  giftCardProducts: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GIFTCARDPRODUCTS:
      return { ...state, giftCardProducts: action.payload.giftCardProducts };
    case ADD_GIFTCARDPRODUCT:
      return {
        ...state,
        giftCardProducts: [...state.giftCardProducts, action.payload.giftCardProduct]
      };
    case UPDATE_GIFTCARDPRODUCT:
      return {
        ...state,
        giftCardProducts: state.giftCardProducts.map(giftCardProduct => {
          if (giftCardProduct._id === action.payload.giftCardProduct._id) {
            return action.payload.giftCardProduct;
          } else {
            return giftCardProduct;
          }
        })
      };
      case DELETE_GIFTCARDPRODUCT:
      return {
        ...state,
        giftCardProducts: state.giftCardProducts.filter(giftCardProduct => giftCardProduct._id !== action.payload.giftCardProductId)
      };      
    case GIFTCARDPRODUCT_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
