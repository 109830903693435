import {
  FETCH_RESERVATIONS,
  ACCEPT_RESERVATION,
  REJECT_RESERVATION,
  FETCH_RESERVATION
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  reservations: [],
  reservation: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RESERVATIONS:
      return { ...state, reservations: action.payload.reservations };
    case FETCH_RESERVATION:
      return { ...state, reservation: action.payload.reservation };
    case ACCEPT_RESERVATION:
      return {
        ...state,
        reservation: action.payload.reservation
      };
    case REJECT_RESERVATION:
      return {
        ...state,
        reservation: action.payload.reservation
      };

    default:
      return state;
  }
}
