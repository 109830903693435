import { FETCH_IMAGES, ADD_IMAGE, IMAGE_ERROR, ADD_FOLDER } from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: ""
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case FETCH_IMAGES:
      return { ...state, images: action.payload.images, folders: action.payload.folders, parent_folders: action.payload.parent_folders };
    case ADD_IMAGE:
      return {
        ...state,
        images: [...state.images, action.payload.image]
      };
    case ADD_FOLDER:
      return {
        ...state,
        folders: [...state.folders, action.payload.folder]
      };
    case IMAGE_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
