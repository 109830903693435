import {
  FETCH_ANNOUNCEMENTS,
  ANNOUNCEMENT_ERROR,
  ADD_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  announcements: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ANNOUNCEMENTS:
      return { ...state, announcements: action.payload.announcements };
    case ADD_ANNOUNCEMENT:
      return {
        ...state,
        announcements: [...state.announcements, action.payload.announcement]
      };
    case UPDATE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.map(announcement => {
          if (announcement._id === action.payload.announcement._id) {
            return action.payload.announcement;
          } else {
            return announcement;
          }
        })
      };
      case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.filter(announcements => announcements._id !== action.payload.announcementId)
      };      
    case ANNOUNCEMENT_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
