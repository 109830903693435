import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { markMenuItemAsSoldOut, fetchMenuItem, markMenuItemAsAvailable } from "../../actions/menu";

import { renderDateTimePicker } from "../../utils/renderFields";

import moment from "moment-timezone";

import sold_out_img from "../../assets/sold_out_48_32.png";

const form = reduxForm({
  form: "markMenuItemAsSoldOut",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class MarkMenuItemAsSoldOut extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleSoldOutOptionChange = this.handleSoldOutOptionChange.bind(this);
    this.handleMarkAsAvailable = this.handleMarkAsAvailable.bind(this);

    this.state = {
      soldOutOption: undefined,
    };
  }

  componentDidMount() {
    if (
      this.props.menu !== undefined &&
      this.props.menuSection !== undefined &&
      this.props.menuItem !== undefined
    ) {
      this.props.fetchMenuItem(
        this.props.menu._id,
        this.props.menuSection._id,
        this.props.menuItem._id
      );
    }
  }

  handleFormSubmit(formProps) {
    this.props.markMenuItemAsSoldOut(
      this.props.menu._id,
      this.props.menuSection._id,
      this.props.menuItem._id,
      {
        soldOutOption: this.state.soldOutOption,
        soldOutUntil: formProps.soldOutUntil,
      },
      () => {
        this.props.handleViewMenuSection(this.props.menuSection);
      }
    );
  }

  handleSoldOutOptionChange(event) {
    this.setState({
      soldOutOption: event.target.value,
    });
  }

  handleMarkAsAvailable() {
    this.props.markMenuItemAsAvailable(
      this.props.menu._id,
      this.props.menuSection._id,
      this.props.menuItem._id,
      () => {
        this.props.handleViewMenuSection(this.props.menuSection);
      });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      menu,
      menuSection,
      menuItem,
      handleSubmit,
      handleViewMenu,
      handleViewMenus,
      handleViewMenuSection,
      handleViewMenuItem,
      restaurant,
    } = this.props;

    const isMarkedAsSoldOut =
      menuItem.sold_out_until &&
      moment(menuItem.sold_out_until)
        .tz(restaurant.settings.time_zone)
        .isAfter(moment().tz(restaurant.settings.time_zone));

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {menuSection.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuItem(menuItem)}
              >
                {menuItem ? menuItem.name : ""}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {"Availability"}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h5>{`${menuItem.cd}. ${menuItem.name}`}</h5>
                {isMarkedAsSoldOut && (
                  <div className="alert alert-warning">
                    <img src={sold_out_img} alt={"Sold out"} className="mr-3" />
                    <strong>{`Sold out until ${moment(menuItem.sold_out_until)
                      .tz(restaurant.settings.time_zone)
                      .format(restaurant.settings.datetime_format)}`}</strong>
                  </div>
                )}
                <p className="my-3">{`How long will this item be sold out for?`}</p>
              </div>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="soldOutOption"
                id="soldOut1Hour"
                value="1-hour"
                checked={this.state.soldOutOption === "1-hour"}
                onChange={this.handleSoldOutOptionChange}
              />
              <label className="form-check-label">{`1 Hour`}</label>
            </div>

            <div className="form-check my-3">
              <input
                className="form-check-input"
                type="radio"
                name="soldOutOption"
                id="soldOut4Hours"
                value="4-hours"
                checked={this.state.soldOutOption === "4-hours"}
                onChange={this.handleSoldOutOptionChange}
              />
              <label className="form-check-label">{`4 Hours`}</label>
            </div>
            <div className="form-check my-3">
              <input
                className="form-check-input"
                type="radio"
                name="soldOutOption"
                id="soldOutUntilTomorrow"
                value="tomorrow"
                checked={this.state.soldOutOption === "tomorrow"}
                onChange={this.handleSoldOutOptionChange}
              />
              <label className="form-check-label">{`Until Tomorrow`}</label>
            </div>
            <div className="form-check my-3">
              <input
                className="form-check-input"
                type="radio"
                name="soldOutOption"
                id="soldOutUntilSpecificDate"
                value="specific-date"
                checked={this.state.soldOutOption === "specific-date"}
                onChange={this.handleSoldOutOptionChange}
              />
              <label className="form-check-label">
                {`Longer`}
                <Field
                  name="soldOutUntil"
                  className="form-control"
                  component={renderDateTimePicker}
                  disabled={this.state.soldOutOption !== "specific-date"}
                  showTime={false}
                  dateFormat={restaurant.settings.date_format}
                  timeFormat={restaurant.settings.time_format}
                  datetimeFormat={restaurant.settings.date_time_format}
                />
              </label>
            </div>
            <div className="form-check my-3">
              <input
                className="form-check-input"
                type="radio"
                name="soldOutOption"
                id="soldOutForever"
                value="forever"
                checked={this.state.soldOutOption === "forever"}
                onChange={this.handleSoldOutOptionChange}
              />
              <label className="form-check-label">{`Remove Item`}</label>
            </div>

            <div className="row">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={() => handleViewMenuItem(menuItem)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary mr-3"
                disabled={!this.state.soldOutOption}
              >
                {"Mark Item As Sold Out"}
              </button>
              <button
                type="buton"
                className="btn btn-secondary mr-3"
                disabled={!isMarkedAsSoldOut}
                onClick={() => this.handleMarkAsAvailable()}
              >
                {"Mark Item AS Available"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let menu_item = state.menu.menuItem ? state.menu.menuItem : ownProps.menuItem;

  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    menuItem: menu_item,
    initialValues: {
      menuSectionId: menu_item.section,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  markMenuItemAsSoldOut: (
    menuId,
    menuSectionId,
    menuItemId,
    formProps,
    callback
  ) =>
    dispatch(
      markMenuItemAsSoldOut(
        menuId,
        menuSectionId,
        menuItemId,
        formProps,
        callback
      )
    ),

  markMenuItemAsAvailable: (menuId, menuSectionId, menuItemId, callback) =>
    dispatch(
      markMenuItemAsAvailable(menuId, menuSectionId, menuItemId, callback)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MarkMenuItemAsSoldOut));
