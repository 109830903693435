import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";
import moment from "moment";

import Numeral from "numeral";

import { unarchiveMenu, unarchiveMenuSection, unarchiveMenuItem } from "../../actions/menu";

class Archive extends Component {
  constructor(props) {
    super(props);

    this.handleShowArchivedMenus = this.handleShowArchivedMenus.bind(this);
    this.handleShowArchivedSections = this.handleShowArchivedSections.bind(
      this
    );
    this.handleShowArchivedItems = this.handleShowArchivedItems.bind(this);
    this.handleUnarchiveMenu = this.handleUnarchiveMenu.bind(this);
    this.handleUnarchiveMenuSection = this.handleUnarchiveMenuSection.bind(this);
    this.handleUnarchiveMenuItem = this.handleUnarchiveMenuItem.bind(this);

    this.state = {
      showArchivedMenus: true,
      showArchivedSections: false,
      showArchivedItems: false
    };
  }

  componentDidMount() {

  }

  handleShowArchivedMenus() {
    this.setState({
      showArchivedMenus: true,
      showArchivedSections: false,
      showArchivedItems: false
    });
  }

  handleShowArchivedSections() {
    this.setState({
      showArchivedMenus: false,
      showArchivedSections: true,
      showArchivedItems: false
    });
  }

  handleShowArchivedItems() {
    this.setState({
      showArchivedMenus: false,
      showArchivedSections: false,
      showArchivedItems: true
    });
  }

  handleUnarchiveMenu(menuId) {
    this.props.unarchiveMenu(menuId);
  }

  handleUnarchiveMenuSection(menuId, sectionId) {
    this.props.unarchiveMenuSection(menuId, sectionId);
  }

  handleUnarchiveMenuItem(menuId, sectionId, itemId) {
    this.props.unarchiveMenuItem(menuId, sectionId, itemId);
  }

  render() {
    const { handleViewMenus, restaurant } = this.props;
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {`Archive`}
              </button>
            </li>
          </ol>
        </nav>

        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary mr-2"
            onClick={this.handleShowArchivedMenus}
          >
            <span>Menus</span>
            {` `}{" "}
            <span className="badge badge-success">
              {this.props.archivedItems.menus ? this.props.archivedItems.menus.length : 0}
            </span>
          </button>
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={this.handleShowArchivedSections}
          >
            <span>Sections</span>
            {` `}{" "}
            <span className="badge badge-success">
              {this.props.archivedItems.sections
                ? this.props.archivedItems.sections.length
                : 0}
            </span>
          </button>
          <button
            type="button"
            className="btn btn-info"
            onClick={this.handleShowArchivedItems}
          >
            <span>Items</span>
            {` `}{" "}
            <span className="badge badge-success">
              {this.props.archivedItems.items ? this.props.archivedItems.items.length : 0}
            </span>
          </button>
        </div>

        {this.state.showArchivedMenus && (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-4 d-none d-sm-inline">
                <strong>{`Name`}</strong>
              </div>
              <div className="col-sm-2 d-none d-sm-inline">
                <strong>{`Contains`}</strong>
              </div>
              <div className="col-sm-4  d-none d-sm-inline">
                <strong>{`Date Archived`}</strong>
              </div>
              <div className="col-2" />
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.showArchivedMenus &&
          this.props.archivedItems.menus &&
          this.props.archivedItems.menus.map(menu => (
            <React.Fragment key={menu.id}>
              <div className="row">
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Name: </strong>{" "}
                  </span>{" "}
                  {menu.name}
                </div>
                <div className="col-12 col-sm-2">
                  <span className="d-inline d-sm-none">
                    <strong>Contains: </strong>{" "}
                  </span>{" "}
                  {menu.sections ? menu.sections.length : 0} Sections
                </div>
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Date Archived: </strong>{" "}
                  </span>{" "}
                  {moment(menu.updatedAt).format(restaurant.settings.datetime_format)}
                </div>
                <div className="col-12 col-sm-2 text-center mt-3 mt-sm-0">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.handleUnarchiveMenu(menu._id)}
                  >
                    <Icon.RotateCcw size={16} />
                    <span className="d-inline d-sm-none">Unarchive</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </React.Fragment>
          ))}
          {this.state.showArchivedSections && (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-4 d-none d-sm-inline">
                <strong>{`Name`}</strong>
              </div>
              <div className="col-sm-2 d-none d-sm-inline">
                <strong>{`Contains`}</strong>
              </div>
              <div className="col-sm-4  d-none d-sm-inline">
                <strong>{`Date Archived`}</strong>
              </div>
              <div className="col-2" />
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.showArchivedSections &&
          this.props.archivedItems.sections &&
          this.props.archivedItems.sections.map(section => (
            <React.Fragment key={section.id}>
              <div className="row">
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Name: </strong>{" "}
                  </span>{" "}
                  {section.name}
                </div>
                <div className="col-12 col-sm-2">
                  <span className="d-inline d-sm-none">
                    <strong>Contains: </strong>{" "}
                  </span>{" "}
                  {section.items ? section.items.length : 0} Items
                </div>
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Date Archived: </strong>{" "}
                  </span>{" "}
                  {moment(section.updatedAt).format(restaurant.settings.datetime_format)}
                </div>
                <div className="col-12 col-sm-2 text-center mt-3 mt-sm-0">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.handleUnarchiveMenuSection(section.menu, section._id)}
                  >
                    <Icon.RotateCcw size={16} />
                    <span className="d-inline d-sm-none">Unarchive</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </React.Fragment>
          ))}
          {this.state.showArchivedItems && (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-4 d-none d-sm-inline">
                <strong>{`Name`}</strong>
              </div>
              <div className="col-sm-2 d-none d-sm-inline">
                <strong>{`Price`}</strong>
              </div>
              <div className="col-sm-4  d-none d-sm-inline">
                <strong>{`Date Archived`}</strong>
              </div>
              <div className="col-2" />
            </div>
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.showArchivedItems &&
          this.props.archivedItems.items &&
          this.props.archivedItems.items.map(item => (
            <React.Fragment key={item.id}>
              <div className="row">
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Name: </strong>{" "}
                  </span>{" "}
                  {item.name}
                </div>
                <div className="col-12 col-sm-2">
                  <span className="d-inline d-sm-none">
                    <strong>Price: </strong>{" "}
                  </span>{" "}
                  {item.price && Numeral(item.price).format("$0,0.00")} 
                  {item.sizes &&
                    item.sizes.map(itemSize => (
                      <small key={itemSize._id} className="text-muted">
                        <strong>{itemSize.name} :{` `}
                          {Numeral(itemSize.price).format("$0,0.00")}
                          {` `}</strong>
                      </small>
                    ))}
                </div>
                <div className="col-12 col-sm-4">
                  <span className="d-inline d-sm-none">
                    <strong>Date Archived: </strong>{" "}
                  </span>{" "}
                  {moment(item.updatedAt).format(restaurant.settings.datetime_format)}
                </div>
                <div className="col-12 col-sm-2 text-center mt-3 mt-sm-0">
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.handleUnarchiveMenuItem(item.section.menu, item.section._id, item._id)}
                  >
                    <Icon.RotateCcw size={16} />
                    <span className="d-inline d-sm-none">Unarchive</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
  };
};

const mapDispatchToProps = dispatch => ({
  unarchiveMenu: menuId => dispatch(unarchiveMenu(menuId)),
  unarchiveMenuSection: (menuId, sectionId) => dispatch(unarchiveMenuSection(menuId, sectionId)),
  unarchiveMenuItem: (menuId, sectionId, itemId) => dispatch(unarchiveMenuItem(menuId, sectionId, itemId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Archive);
