import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { updateTenantInfo } from "../../actions/tenant";

import {
  renderEmailField,
  renderPhoneField,
  renderField
} from "../../utils/renderFields";
import normalizePhone from "../../utils/normalizePhone";

const form = reduxForm({
  form: "tenantInfo",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  return errors;
}

class GeneralInfoSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateTenantInfo(formProps, () => {
      this.props.handleViewSettings();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewSettings, handleSubmit } = this.props;
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-info" />
                {` `}
                <span className="d-none d-sm-inline">{`General Info`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Name
            </label>
            <div className="col-lg-6">
              <Field
                name="name"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Slug
            </label>
            <div className="col-lg-6">
              <Field
                name="slug"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Slogan
            </label>
            <div className="col-lg-6">
              <Field
                name="slogan"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Brief Description
            </label>
            <div className="col-lg-6">
              <Field
                name="brief_description"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Full Description
            </label>
            <div className="col-lg-6">
              <Field
                name="full_description"
                className="form-control"
                component="textarea"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Email
            </label>
            <div className="col-lg-6">
              <Field
                name="email"
                className="form-control"
                component={renderEmailField}
                type="email"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Phone
            </label>
            <div className="col-lg-6">
              <Field
                name="phone"
                className="form-control"
                component={renderPhoneField}
                type="text"
                normalize={normalizePhone}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">Fax</label>
            <div className="col-lg-6">
              <Field
                name="fax"
                className="form-control"
                component={renderPhoneField}
                type="text"
                normalize={normalizePhone}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              WebSite Url
            </label>
            <div className="col-lg-6">
              <Field
                name="website_url"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Facebook Url
            </label>
            <div className="col-lg-6">
              <Field
                name="facebook_url"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Twitter Url
            </label>
            <div className="col-lg-6">
              <Field
                name="twitter_url"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-lg-2 text-lg-right">
              Wechat Url
            </label>
            <div className="col-lg-6">
              <Field
                name="wechat_url"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewSettings}
              >
                {`Cancel`}
              </button>
              <button type="submit" className="btn btn-primary">
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
    initialValues: state.tenant.tenant || ownProps.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  updateTenantInfo: (formProps, callback) =>
    dispatch(updateTenantInfo(formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(GeneralInfoSettings));
