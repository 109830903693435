import React, { Component } from "react";

class PromoRedeemInfo extends Component {
  render() {
    const {
      sectionNumber,
      handleChoiceChange,
      choice,
      handlePromoApplyChoiceChange,
      promoApplyChoice,
      handleRedeemOncePerClientChange,
      redeemOncePerClient,
      handleRedeemOnLimitedStockChange,
      redeemOnLimitedStock,
      handleLimitedStockChange,
      limitedStock
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Decide how this promo deal is redeemable (Allow this once per
                client, show it only to coupon holders)
              </strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="redeem_option"
                  value="Show on menu"
                  onChange={handleChoiceChange}
                  checked={choice === "Show on menu"}
                />
                <label className="form-check-label">
                  {`Show on menu (Default) `}
                  <br />
                  <small>
                    All eligible clients can see this on the menu.
                      </small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="redeem_option"
                  value="Hide from menu"
                  onChange={handleChoiceChange}
                  checked={choice === "Hide from menu"}
                />
                <label className="form-check-label">
                  {`Hide from menu`}
                  <br />
                  <small>
                    Only clients that inserted a coupon code or came via a
                        special URL link can see this.
                      </small>
                </label>
              </div>
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="promo_apply_option"
                  value="Automatically"
                  onChange={handlePromoApplyChoiceChange}
                  checked={promoApplyChoice === "Automatically"}
                />
                <label className="form-check-label">
                  {`Automatically (Default) `}
                  <br />
                  <small>
                  Apply this promotion automatically when conditions are satisfied.
                      </small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="promo_apply_option"
                  value="Manually"
                  onChange={handlePromoApplyChoiceChange}
                  checked={promoApplyChoice === "Manually"}
                />
                <label className="form-check-label">
                  {`Manually`}
                  <br />
                  <small>
                    Apply this promotion only when user manually enter the promo code.
                      </small>
                </label>
              </div>
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="redeem_only_once_per_client"
                  defaultChecked={redeemOncePerClient}
                  onChange={handleRedeemOncePerClientChange}
                />
                <label className="form-check-label">
                  {`Only once per client  `}
                  <br />
                  <small>
                    Allow this promo deal to be redeemed only once per
                        client. Once a client uses this promo it will not be
                        shown to this client any longer.
                      </small>
                </label>
              </div>
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="form-check mb-3">
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="redeem_on_limited_stock"
                    defaultChecked={redeemOnLimitedStock}
                    onChange={handleRedeemOnLimitedStockChange}
                  />
                  <label className="form-check-label">
                    {`Limited stock `}
                    <br />
                    <small>
                      Allow this promo deal to be used for the number of
                          orders set below:
                        </small>
                    <div className="form-group mt-3">
                      <input
                        className="form-control"
                        name="limited_stock"
                        type="number"
                        value={limitedStock}
                        disabled={!redeemOnLimitedStock}
                        onChange={handleLimitedStockChange}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoRedeemInfo;