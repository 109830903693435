import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchBillingProfile } from "../../actions/tenant";
import moment from "moment-timezone";

import Numeral from "numeral";

import "./InvoiceDetail.css";

class InvoiceDetail extends Component {
  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchBillingProfile(this.props.tenant._id);
    }
  }

  render() {
    const { invoice, tenant, billingProfile } = this.props;

    if (!invoice) {
      return <></>;
    }

    return (
      <>
        <div className="row mb-3">
          <div className="col-6">
            <strong className="mr-3">{`Invoice`}</strong>
            <span className="invoice-status">{`${invoice.status}`}</span>
          </div>
          {/* <div className="col-6">
            <div className="btn-toolbar  justify-content-end">
              <strong className="mr-3 align-middle">{`Export To`}</strong>
              <div className="btn-group btn-group-sm">
                <button type="button" className="btn btn-secondary mr-1">{`PDF`}</button>
                <button type="button" className="btn btn-secondary mr-1">{`TXT`}</button>
                <button type="button" className="btn btn-secondary">{`CSV`}</button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="mt-3">
                          <strong className="card-title">{`Running Total`}</strong>
                        </div>
                        <div className="mt-1">
                          <strong>
                            {Numeral(invoice.total).format("$0,0.00")}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="mt-3">
                          <strong>{`Previous Balance`}</strong>
                        </div>
                        <div className="mt-1">{`${Numeral(
                          invoice.previous_balance
                        ).format("$0,0.00")}`}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="mt-3">
                          <strong>{`Billing Period`}</strong>
                        </div>
                        <div className="mt-1">{`${moment(
                          invoice.billing_period_from
                        )
                          .tz(tenant.settings.time_zone)
                          .format(tenant.settings.date_format)} - ${moment(
                          invoice.billing_period_to
                        )
                          .tz(tenant.settings.time_zone)
                          .format(tenant.settings.date_format)}`}</div>
                      </div>
                    </div>
                    {invoice._id && (
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-3">
                            <strong>{`Invoice Number`}</strong>
                          </div>
                          <div className="mt-1">{invoice._id}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  {billingProfile && (
                    <div className="col-12 col-lg-5">
                      <div className="row">
                        <div className="col-12">
                          <div className="mt-3">
                            <strong>{`Billed To`}</strong>
                          </div>
                          <div className="mt-1">
                            {billingProfile.name}
                            <br />
                            {`${billingProfile.address_line_1} ${
                              billingProfile.address_line_2 ?? ""
                            }, ${billingProfile.city_town}, ${
                              billingProfile.province
                            }, ${billingProfile.postal_code}, ${
                              billingProfile.country
                            }`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 ">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title py-3">{`Summary By Restaurant`}</h3>
                <div className="row mb-3 pb-3 border-bottom">
                  <div className="col-6">
                    <strong>{`Restaurant`}</strong>
                  </div>
                  <div className="col-6">
                    <strong>{`Total`}</strong>
                  </div>

                </div>
                {tenant.restaurants.map(restaurant => (
                  <div className="row pb-1 border-bottom">
                    <div className="col-6">
                      <strong>{restaurant.name}</strong>
                    </div>
                    <div className="col-6 text-right">

                    </div>

                  </div>
                ))}


              </div>
            </div>

          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-12 ">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title py-3">{`Summary By Restaurant`}</h3>
                <div className="row mb-3 pb-3 border-bottom">
                  <div className="col-2">
                    <strong>{`Restaurant`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Online Order`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Amount`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Call-In Order`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Amount`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Total`}</strong>
                  </div>
                </div>
                {(invoice.fees_by_restaurant || []).map(
                  (fee_by_restaurant, index) => (
                    <div className="row pb-1 border-bottom" key={index}>
                      <div className="col-2">
                        {fee_by_restaurant.restaurant_name}
                      </div>
                      <div className="col-2 text-right">
                        {fee_by_restaurant.online_order.quantity} /{" "}
                        {Numeral(
                          fee_by_restaurant.online_order.total_amount
                        ).format("$0,0.00")}
                      </div>
                      <div className="col-2 text-right">
                        {Numeral(
                          fee_by_restaurant.online_order.quantity_based_fee +
                            fee_by_restaurant.online_order.amount_based_fee
                        ).format("$0,0.00")}
                      </div>
                      <div className="col-2 text-right">
                        {fee_by_restaurant.call_in_order.quantity} /{" "}
                        {Numeral(
                          fee_by_restaurant.call_in_order.total_amount
                        ).format("$0,0.00")}
                      </div>
                      <div className="col-2 text-right">
                        {Numeral(
                          fee_by_restaurant.call_in_order.quantity_based_fee +
                            fee_by_restaurant.call_in_order.amount_based_fee
                        ).format("$0,0.00")}
                      </div>
                      <div className="col-2 text-right">
                        {Numeral(
                          fee_by_restaurant.online_order.quantity_based_fee +
                            fee_by_restaurant.online_order.amount_based_fee +
                            fee_by_restaurant.call_in_order.quantity_based_fee +
                            fee_by_restaurant.call_in_order.amount_based_fee
                        ).format("$0,0.00")}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 ">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title py-3">{`Summary By Service`}</h3>
                <div className="row mb-3 pb-3 border-bottom">
                  <div className="col-6">
                    <strong>{`Description`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Unit Price`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Quantity`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>{`Amount`}</strong>
                  </div>
                </div>

                <div className="row pb-1 border-bottom">
                  <div className="col-6">
                    <strong>{`Website`}</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(invoice.website_fee).format("$0,0.00")}
                    </strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>1</strong>
                  </div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(invoice.website_fee).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-6">
                    <strong>{`Online Order`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(
                        invoice.online_order_fee.base_fee +
                          invoice.online_order_fee.quantity_based_fee +
                          invoice.online_order_fee.amount_based_fee
                      ).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Base`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.online_order_fee.base_fee).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>1</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.online_order_fee.base_fee).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Qauantity Based Fee`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.online_order_fee.unit_price_per_order
                      ).format("$0,0.00")}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>{invoice.online_order_fee.quantity}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.online_order_fee.quantity_based_fee
                      ).format("$0,0.00")}
                    </small>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Amount Based Fee`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.online_order_fee.unit_price_per_dollar * 100
                      ).format("0,0.00")}
                      %
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.online_order_fee.total_amount).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.online_order_fee.amount_based_fee
                      ).format("$0,0.00")}
                    </small>
                  </div>
                </div>

                <div className="row py-1 border-bottom">
                  <div className="col-6">
                    <strong>{`Call-In Order`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(
                        invoice.call_in_order_fee.base_fee +
                          invoice.call_in_order_fee.quantity_based_fee +
                          invoice.call_in_order_fee.amount_based_fee
                      ).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Base`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.call_in_order_fee.base_fee).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>1</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.call_in_order_fee.base_fee).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Qauantity Based Fee`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.call_in_order_fee.unit_price_per_order
                      ).format("$0,0.00")}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>{invoice.call_in_order_fee.quantity}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.call_in_order_fee.quantity_based_fee
                      ).format("$0,0.00")}
                    </small>
                  </div>
                </div>
                <div className="row py-1 border-bottom">
                  <div className="col-1"></div>
                  <div className="col-5">
                    <small>{`Amount Based Fee`}</small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.call_in_order_fee.unit_price_per_dollar * 100
                      ).format("0,0.00")}
                      %
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(invoice.call_in_order_fee.total_amount).format(
                        "$0,0.00"
                      )}
                    </small>
                  </div>
                  <div className="col-2 text-right">
                    <small>
                      {Numeral(
                        invoice.call_in_order_fee.amount_based_fee
                      ).format("$0,0.00")}
                    </small>
                  </div>
                </div>
                {invoice.gift_card_order_fee && (
                  <>
                    <div className="row py-1 border-bottom">
                      <div className="col-6">
                        <strong>{`Gift Card Order`}</strong>
                      </div>
                      <div className="col-2 text-right"></div>
                      <div className="col-2 text-right"></div>
                      <div className="col-2 text-right">
                        <strong>
                          {Numeral(
                            invoice.gift_card_order_fee.base_fee +
                              invoice.gift_card_order_fee.quantity_based_fee +
                              invoice.gift_card_order_fee.amount_based_fee
                          ).format("$0,0.00")}
                        </strong>
                      </div>
                    </div>
                    <div className="row py-1 border-bottom">
                      <div className="col-1"></div>
                      <div className="col-5">
                        <small>{`Base`}</small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(invoice.gift_card_order_fee.base_fee).format(
                            "$0,0.00"
                          )}
                        </small>
                      </div>
                      <div className="col-2 text-right">
                        <small>1</small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(invoice.gift_card_order_fee.base_fee).format(
                            "$0,0.00"
                          )}
                        </small>
                      </div>
                    </div>
                    <div className="row py-1 border-bottom">
                      <div className="col-1"></div>
                      <div className="col-5">
                        <small>{`Qauantity Based Fee`}</small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(
                            invoice.gift_card_order_fee.unit_price_per_order
                          ).format("$0,0.00")}
                        </small>
                      </div>
                      <div className="col-2 text-right">
                        <small>{invoice.gift_card_order_fee.quantity}</small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(
                            invoice.gift_card_order_fee.quantity_based_fee
                          ).format("$0,0.00")}
                        </small>
                      </div>
                    </div>
                    <div className="row py-1 border-bottom">
                      <div className="col-1"></div>
                      <div className="col-5">
                        <small>{`Amount Based Fee`}</small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(
                            invoice.gift_card_order_fee.unit_price_per_dollar *
                              100
                          ).format("0,0.00")}
                          %
                        </small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(
                            invoice.gift_card_order_fee.total_amount
                          ).format("$0,0.00")}
                        </small>
                      </div>
                      <div className="col-2 text-right">
                        <small>
                          {Numeral(
                            invoice.gift_card_order_fee.amount_based_fee
                          ).format("$0,0.00")}
                        </small>
                      </div>
                    </div>
                  </>
                )}

                <div className="row py-3 border-bottom">
                  <div className="col-6">
                    <strong>{`Subtotal`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(invoice.subtotal).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                {invoice.discount?.amount > 0 && (
                  <div className="row py-3 border-bottom">
                    <div className="col-6">
                      <strong>{`Discount`}</strong>
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-11">
                          <small>{invoice.discount.note}</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-2 text-right"></div>
                    <div className="col-2 text-right"></div>
                    <div className="col-2 text-right">
                      <strong>
                        {Numeral(invoice.discount.amount).format("$0,0.00")}
                      </strong>
                    </div>
                  </div>
                )}
                <div className="row py-3 border-bottom">
                  <div className="col-6">
                    <strong>{`GST`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>{Numeral(invoice.gst).format("$0,0.00")}</strong>
                  </div>
                </div>
                <div className="row py-3 border-bottom">
                  <div className="col-6">
                    <strong>{`PST`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>{Numeral(invoice.pst).format("$0,0.00")}</strong>
                  </div>
                </div>
                <div className="row py-3 border-bottom">
                  <div className="col-6">
                    <strong>{`Previous Balance`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>
                      {Numeral(invoice.previous_balance).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row py-3 border-bottom">
                  <div className="col-6">
                    <strong>{`Total`}</strong>
                  </div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right"></div>
                  <div className="col-2 text-right">
                    <strong>{Numeral(invoice.total).format("$0,0.00")}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.tenant.errorMessage,
    message: state.tenant.message,
    tenant: state.tenant.tenant,
    billingProfile: state.tenant.billingProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchBillingProfile: (tenantId) => dispatch(fetchBillingProfile(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail);
