import React from "react";

import moment from "moment";

const ReservationListView = ({ restaurant, reservations, handleViewDetail }) => {
  return (
    <React.Fragment>
      <div className="row pt-3 pb-3 border-bottom">
        <div className="col-sm-1 d-none d-sm-inline text-right"><strong>#</strong></div>
        <div className="col-sm-2 d-none d-sm-inline text-left">
          <strong>Customer</strong>
        </div>
        <div className="col-sm-1 d-none d-sm-inline text-right"><strong>Party Size</strong></div>
        <div className="col-sm-2 d-none d-sm-inline text-right"><strong>Status</strong></div>
        <div className="col-sm-3 d-none d-sm-inline text-right">
          <strong>Reserved Time</strong>
        </div>
        <div className="col-sm-3 d-none d-sm-inline text-right">
          <strong>Location</strong>
        </div>
      </div>
      {reservations.map(reservation => (
        <React.Fragment key={reservation._id}>
          <div
            className="row clickable-row pt-3 pb-3 border-bottom"
            onClick={() => handleViewDetail(reservation)}
          >
            <div className="col-12 col-sm-1 text-left text-sm-right">
              <span className="d-inline d-sm-none">
                <strong>Reservation Number: </strong>{" "}
              </span>{" "}
              {reservation.reservationNumber}
            </div>
            <div className="col-12 col-sm-2 text-left text-sm-left">
              <span className="d-inline d-sm-none">
                <strong>Customer: </strong>
              </span>
              {reservation.nameReservedUnder}
            </div>
            <div className="col-12 col-sm-1 text-left text-sm-right">
              <span className="d-inline d-sm-none">
                <strong>Party Size: </strong>
              </span>
              {reservation.partySize}
            </div>
            <div className="col-12 col-sm-2 text-left text-sm-right">
              <span className="d-inline d-sm-none">
                <strong>Status: </strong>
              </span>
              {reservation.status}
            </div>
            <div className="col-12 col-sm-3 text-left text-sm-right">
              <span className="d-inline d-sm-none">
                <strong>Reserved Time: </strong>
              </span>
              {moment(reservation.reservedDate).tz(restaurant.settings.time_zone).format(
                restaurant.settings.datetime_format
              )}}
            </div>
            <div className="col-12 col-sm-3 text-left text-sm-right">
              <span className="d-inline d-sm-none">
                <strong>Location: </strong>
              </span>
              {reservation.restaurant.name}
            </div>
          </div>
        </React.Fragment>

      ))}
    </React.Fragment>

  );
};

export default ReservationListView;
