import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import OrderInfoCard from "./OrderInfoCard";


import {
  fetchGiftCardOrder,

} from "../../actions/giftcardorder";

import "./OrderDetail.css";
import SendEmail from "./SendEmail";
import RejectOrder from './RejectOrder';
import UpdateOrder from './UpdateOrder';
import TakePayment from "./TakePayment";

import IssueCards from "./IssueCards";

class OrderDetailCard extends Component {

  render() {
    const { order, restaurant, coupon } = this.props;
    return (
      <div className="row">
        <div className="col-12">
          <OrderInfoCard order={order} restaurant={restaurant} coupon={coupon} />
        </div>
      </div>
    );
  }
}

class GiftCardOrderDetail extends Component {

  componentDidMount() {
    if (this.props.orderId) {
      this.props.fetchGiftCardOrder(this.props.tenant._id, this.props.orderId);
    }
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order,  restaurant, handleViewOrders } = this.props;

    if (!order) {
      return <></>;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewOrders}
              >
                <i className="fas fa-search" />
                {` `}Gift Card Orders
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {order.giftCardOrderNumber}
              </button>
            </li>
          </ol>
        </nav>
        {this.renderAlert()}
        <div className="row pb-3">
          <div className="col-1">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewOrders}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-11 text-right">
            <div className="btn-group">
              <SendEmail order={order} />
        
              {order.status === "Received" && (
                <>
                  <UpdateOrder order={order} fetchPeriod={this.props.fetchPeriod} />
                  <RejectOrder order={order} fetchPeriod={this.props.fetchPeriod} />
                  <TakePayment order={order} fetchPeriod={this.props.fetchPeriod} />
                </>
              )}
              {order.status === "Paid" && (
                <>
                  <IssueCards order={order} fetchPeriod={this.props.fetchPeriod} />

                </>
              )}
            </div>
          </div>
        </div>
        {/* {this.renderAlert()} */}
        <OrderDetailCard order={order} restaurant={restaurant}  />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.giftCardOrder.giftCardOrder,
    settings: state.tenant.restaurant.settings || state.tenant.tenant.settings,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.giftCardOrder.errorMessage,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGiftCardOrder: (restaurantId, orderId) => dispatch(fetchGiftCardOrder(restaurantId, orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardOrderDetail);
