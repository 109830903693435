import React, { Component } from "react";
import { connect } from "react-redux";

import { searchCoupons } from "../../actions/coupon";

import CouponListView from "./CouponListView";

import * as FaIcons from "react-icons/fa";

import normalizePhone from "../../utils/normalizePhone";

class SearchCoupons extends Component {
  constructor(props) {
    super(props);

    this.handleCouponNumberFilterChange =
      this.handleCouponNumberFilterChange.bind(this);
    this.handlePhoneNumberFilterChange =
      this.handlePhoneNumberFilterChange.bind(this);

    this.handleOrderNumberFilterChange =
      this.handleOrderNumberFilterChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      loading: false,
      couponNumberFilter: undefined,
      phoneNumberFilter: undefined,
      orderNumberFilter: undefined,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  handleCouponNumberFilterChange(event) {
    this.setState({
      couponNumberFilter: event.target.value,
    });
  }

  handleOrderNumberFilterChange(event) {
    this.setState({
      orderNumberFilter: event.target.value,
    });
  }

  handlePhoneNumberFilterChange(event) {
    this.setState({
      phoneNumberFilter: normalizePhone(event.target.value),
    });
  }

  handleSearch() {
    this.props.searchCoupons(
      this.props.tenant._id,
      this.state.couponNumberFilter,
      this.state.phoneNumberFilter,
      this.state.orderNumberFilter
    );
  }

  render() {
    const { tenant, coupons = [] } = this.props;

    if (tenant === undefined) {
      return <></>;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <FaIcons.FaSearchDollar />
                {` `}
                <span>{`Coupons`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="couponNumberFilter"
              type="text"
              className="form-control"
              placeholder={"Coupon Number"}
              onChange={this.handleCouponNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="phoneNumberFilter"
              type="tel"
              className="form-control"
              placeholder={"123-456-7890"}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={this.handlePhoneNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="orderNumberFilter"
              type="text"
              className="form-control"
              placeholder={"Order Number"}
              onChange={this.handleOrderNumberFilterChange}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        <CouponListView
          showRedeemedColumn={true}
          showExpireColumn={true}
          coupons={coupons}
          tenant={tenant}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant,
    coupons: state.coupon.coupons,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchCoupons: (tenantId, couponNumber, phoneNumber, orderNumber) =>
    dispatch(searchCoupons(tenantId, couponNumber, phoneNumber, orderNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCoupons);
