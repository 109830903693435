import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import Switch from "react-switch";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "onlineOrderingSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

const leadTimes = [
  15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105,
  100, 115, 120,
];

const intervalTimes = [5, 10, 15, 20, 25, 30];

class OnlinePaymentSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFutureOrderDaysChange =
      this.handleFutureOrderDaysChange.bind(this);
    this.handleOnlineOrderStartChange =
      this.handleOnlineOrderStartChange.bind(this);
    this.handleOnlineOrderEndChange =
      this.handleOnlineOrderEndChange.bind(this);
    this.handleOnlineOrderLeadTimeChange =
      this.handleOnlineOrderLeadTimeChange.bind(this);
    this.handleDeliveryOrderLeadTimeChange =
      this.handleDeliveryOrderLeadTimeChange.bind(this);
    this.handlePickupTimeAdjusmentOptionsChange =
      this.handlePickupTimeAdjusmentOptionsChange.bind(this);
    this.handleAcceptOnlinePaymentChange =
      this.handleAcceptOnlinePaymentChange.bind(this);
    this.handlePickupTimeIntervalChange =
      this.handlePickupTimeIntervalChange.bind(this);
      this.handleDeliveryTimeIntervalChange =
      this.handleDeliveryTimeIntervalChange.bind(this);      
    this.state = {
      online_ordering: props.restaurant.settings.online_ordering,
      future_order_days: props.restaurant.settings.future_order_days,
      online_order_start: props.restaurant.settings.online_order_start,
      online_order_end: props.restaurant.settings.online_order_end,
      online_order_lead_time: props.restaurant.settings.online_order_lead_time,
      delivery_order_lead_time:
        props.restaurant.settings.delivery_order_lead_time,
      pickup_time_adjustment_options:
        props.restaurant.settings.pickup_time_adjustment_options,
      pickup_time_interval_in_minutes:
        props.restaurant.settings.pickup_time_interval_in_minutes,
        delivery_time_interval_in_minutes:
        props.tenant.settings.delivery_time_interval_in_minutes,        
      accept_online_payment:
        props.restaurant.settings.online_payment_settings.accept_online_payment,
      menu_special_request_allowed:
        props.restaurant.settings.menu_special_request_allowed,
      menu_extra_option: props.restaurant.settings.menu_extra_option,
      require_payment_receipt_number:
        props.restaurant.settings.require_payment_receipt_number,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        online_ordering: this.state.online_ordering,
        future_order_days: this.state.future_order_days,
        online_order_start: this.state.online_order_start,
        online_order_end: this.state.online_order_end,
        online_order_lead_time: this.state.online_order_lead_time,
        delivery_order_lead_time: this.state.delivery_order_lead_time,
        pickup_time_interval_in_minutes:
          this.state.pickup_time_interval_in_minutes,
        delivery_time_interval_in_minutes:
          this.state.delivery_time_interval_in_minutes,          
        pickup_time_adjustment_options:
          this.state.pickup_time_adjustment_options,
        accept_online_payment: this.state.accept_online_payment,
        menu_special_request_allowed: this.state.menu_special_request_allowed,
        menu_extra_option: this.state.menu_extra_option,
        require_payment_receipt_number:
          this.state.require_payment_receipt_number,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleFutureOrderDaysChange(event) {
    this.setState({ future_order_days: event.target.value });
  }

  handleOnlineOrderStartChange(event) {
    this.setState({ online_order_start: event.target.value });
  }

  handleOnlineOrderEndChange(event) {
    this.setState({ online_order_end: event.target.value });
  }

  handleOnlineOrderLeadTimeChange(event) {
    this.setState({ online_order_lead_time: event.target.value });
  }

  handleDeliveryOrderLeadTimeChange(event) {
    this.setState({ delivery_order_lead_time: event.target.value });
  }

  handlePickupTimeIntervalChange(event) {
    this.setState({ pickup_time_interval_in_minutes: event.target.value });
  }

  handleDeliveryTimeIntervalChange(event) {
    this.setState({ delivery_time_interval_in_minutes: event.target.value });
  }

  handlePickupTimeAdjusmentOptionsChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = target.id;

    this.setState((prevState) => ({
      pickup_time_adjustment_options: value
        ? prevState.pickup_time_adjustment_options.concat([id])
        : prevState.pickup_time_adjustment_options.filter((i) => i !== id),
    }));
  }

  handleAcceptOnlinePaymentChange(event) {
    this.setState({ accept_online_payment: event.target.value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } =
      this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-utensils" />
                {` `}
                <span className="d-none d-sm-inline">{`Online Ordering`}</span>
              </button>
            </li>
          </ol>
        </nav>

        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Restaurant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.restaurant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Online Ordering
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { online_ordering: !prevState.online_ordering };
                });
              }}
              checked={this.state.online_ordering || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Future Order
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="future_order_days"
              className="form-control"
              value={this.state.future_order_days}
              disabled={this.state.online_ordering === false}
              onChange={this.handleFutureOrderDaysChange}
            >
              <option value="0">Today Only</option>
              <option value="1">1 day ahead</option>
              <option value="2">2 days haead</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Online Order Start
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="online_order_start"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.online_order_start}
              onChange={this.handleOnlineOrderStartChange}
            >
              <option value="0">Opening</option>
              <option value="15">15 minutes after opening</option>
              <option value="30">30 minutes after opening</option>
              <option value="45">45 minutes after opening</option>
              <option value="60">60 minutes after opening</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Online Order End
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="online_order_end"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.online_order_end}
              onChange={this.handleOnlineOrderEndChange}
            >
              <option value="0">Closing</option>
              <option value="15">15 minutes prior closing</option>
              <option value="30">30 minutes prior closing</option>
              <option value="45">45 minutes prior closing</option>
              <option value="60">60 minutes prior closing</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup Order Lead Time
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="online_order_lead_time"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.online_order_lead_time}
              onChange={this.handleOnlineOrderLeadTimeChange}
            >
              {leadTimes.map((leadTime) => (
                <option value={`${leadTime}`}>{`${leadTime} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Order Lead Time
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="delivery_order_lead_time"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.delivery_order_lead_time}
              onChange={this.handleDeliveryOrderLeadTimeChange}
            >
              {leadTimes.map((leadTime) => (
                <option value={`${leadTime}`}>{`${leadTime} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup Time Interval
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="pickup_time_interval_in_minutes"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.pickup_time_interval_in_minutes}
              onChange={this.handlePickupTimeIntervalChange}
            >
              {intervalTimes.map((intervalTime) => (
                <option
                  value={intervalTime}
                >{`${intervalTime} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Time Interval
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="delivery_time_interval_in_minutes"
              className="form-control"
              disabled={this.state.online_ordering === false}
              value={this.state.delivery_time_interval_in_minutes}
              onChange={this.handleDeliveryTimeIntervalChange}
            >
              {intervalTimes.map((intervalTime) => (
                <option
                  value={intervalTime}
                >{`${intervalTime} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup/Delivery Time Adjustment Options
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="0"
                id="0"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  0
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">No Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="15"
                id="15"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  15
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">15 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="30"
                id="30"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  30
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">30 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="45"
                id="45"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  45
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">45 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="60"
                id="60"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  60
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">60 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="75"
                id="75"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  75
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">75 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="90"
                id="90"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  90
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">90 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="105"
                id="105"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  105
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">105 Minutes Delay</label>
            </div>
            <div className="form-check">
              <input
                name="pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.online_ordering === false}
                type="checkbox"
                value="120"
                id="120"
                defaultChecked={this.state.pickup_time_adjustment_options.includes(
                  120
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">120 Minutes Delay</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right ">
            Menu Special Request Allowed
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    menu_special_request_allowed:
                      !prevState.menu_special_request_allowed,
                  };
                });
              }}
              checked={this.state.menu_special_request_allowed || false}
              disabled={!this.state.online_ordering}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Accept Online Payment
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    accept_online_payment: !prevState.accept_online_payment,
                  };
                });
              }}
              checked={this.state.accept_online_payment || false}
              disabled={!this.state.online_ordering}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Menu Extra Option
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { menu_extra_option: !prevState.menu_extra_option };
                });
              }}
              checked={this.state.menu_extra_option || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Require Payment Receipt Number
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    require_payment_receipt_number:
                      !prevState.require_payment_receipt_number,
                  };
                });
              }}
              checked={this.state.require_payment_receipt_number || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (tenantId, formProps, callback) =>
    dispatch(updateSettings(tenantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(OnlinePaymentSettings));
