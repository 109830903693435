import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Switch from "react-switch";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "callinOrderSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

const pickup_order_lead_time_options = [10, 20, 30, 40, 50, 60];

const delivery_order_lead_time_options = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120,
];

const interval_options = [5, 10, 15, 20, 25, 30];

const adjustment_options = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120];

class CallInOrderSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFutureOrderDaysChange =
      this.handleFutureOrderDaysChange.bind(this);
    this.handleCallInOrderStartChange =
      this.handleCallInOrderStartChange.bind(this);
    this.handleCallInOrderEndChange =
      this.handleCallInOrderEndChange.bind(this);
    this.handleCallInPickupOrderLeadTimeChange =
      this.handleCallInPickupOrderLeadTimeChange.bind(this);
    this.handleCallInDeliveryOrderLeadTimeChange =
      this.handleCallInDeliveryOrderLeadTimeChange.bind(this);
    this.handlePickupTimeAdjusmentOptionsChange =
      this.handlePickupTimeAdjusmentOptionsChange.bind(this);
    this.handlePickupTimeIntervalChange =
      this.handlePickupTimeIntervalChange.bind(this);
    this.state = {
      call_in_order: props.tenant.settings.call_in_order,
      call_in_future_order_days:
        props.tenant.settings.call_in_future_order_days,
      call_in_order_start: props.tenant.settings.call_in_order_start,
      call_in_order_end: props.tenant.settings.call_in_order_end,
      call_in_pickup_order_lead_time:
        props.tenant.settings.call_in_pickup_order_lead_time,
      call_in_delivery_order_lead_time:
        props.tenant.settings.call_in_delivery_order_lead_time,
      call_in_pickup_time_adjustment_options:
        props.tenant.settings.call_in_pickup_time_adjustment_options || [],
      call_in_pickup_time_interval_in_minutes:
        props.tenant.settings.call_in_pickup_time_interval_in_minutes,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        call_in_order: this.state.call_in_order,
        call_in_future_order_days: this.state.call_in_future_order_days,
        call_in_order_start: this.state.call_in_order_start,
        call_in_order_end: this.state.call_in_order_end,
        call_in_pickup_order_lead_time:
          this.state.call_in_pickup_order_lead_time,
        call_in_delivery_order_lead_time:
          this.state.call_in_delivery_order_lead_time,
        call_in_pickup_time_interval_in_minutes:
          this.state.call_in_pickup_time_interval_in_minutes,
        call_in_pickup_time_adjustment_options:
          this.state.call_in_pickup_time_adjustment_options,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleFutureOrderDaysChange(event) {
    this.setState({ call_in_future_order_days: event.target.value });
  }

  handleCallInOrderStartChange(event) {
    this.setState({ call_in_order_start: event.target.value });
  }

  handleCallInOrderEndChange(event) {
    this.setState({ call_in_order_end: event.target.value });
  }

  handleCallInPickupOrderLeadTimeChange(event) {
    this.setState({ call_in_pickup_order_lead_time: event.target.value });
  }

  handleCallInDeliveryOrderLeadTimeChange(event) {
    this.setState({ call_in_delivery_order_lead_time: event.target.value });
  }

  handlePickupTimeIntervalChange(event) {
    this.setState({
      call_in_pickup_time_interval_in_minutes: event.target.value,
    });
  }

  handlePickupTimeAdjusmentOptionsChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const id = parseInt(target.id);
    this.setState((prevState) => {
      const new_list = value
        ? prevState.call_in_pickup_time_adjustment_options.concat([id])
        : prevState.call_in_pickup_time_adjustment_options.filter(
            (i) => i !== id
          );
      new_list.sort();
      return {
        call_in_pickup_time_adjustment_options: new_list,
      };
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { tenant, handleViewSettings } = this.props;

    if (tenant === undefined) {
      return <></>;
    }
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-phone" />
                {` `}
                <span className="d-none d-sm-inline">{`Call-in Order`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Tenant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.tenant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Call-in Order
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { call_in_order: !prevState.call_in_order };
                });
              }}
              checked={this.state.call_in_order || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Future Order
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_future_order_days"
              className="form-control"
              value={this.state.call_in_future_order_days}
              disabled={this.state.call_in_order === false}
              onChange={this.handleFutureOrderDaysChange}
            >
              <option value="0">Today Only</option>
              <option value="1">1 day ahead</option>
              <option value="2">2 days haead</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Call-in Order Start
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_order_start"
              className="form-control"
              disabled={this.state.call_in_order === false}
              value={this.state.call_in_order_start}
              onChange={this.handleCallInOrderStartChange}
            >
              <option value="0">Opening</option>
              <option value="15">15 minutes after opening</option>
              <option value="30">30 minutes after opening</option>
              <option value="45">45 minutes after opening</option>
              <option value="60">60 minutes after opening</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Call-in Order End
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_order_end"
              className="form-control"
              disabled={this.state.call_in_order === false}
              value={this.state.call_in_order_end}
              onChange={this.handleCallInOrderEndChange}
            >
              <option value="0">Closing</option>
              <option value="15">15 minutes prior closing</option>
              <option value="30">30 minutes prior closing</option>
              <option value="45">45 minutes prior closing</option>
              <option value="60">60 minutes prior closing</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup Order Lead Time
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_pickup_order_lead_time"
              className="form-control"
              disabled={this.state.call_in_order === false}
              value={this.state.call_in_pickup_order_lead_time}
              onChange={this.handleCallInPickupOrderLeadTimeChange}
            >
              {pickup_order_lead_time_options.map((lead_time) => (
                <option
                  value={`${lead_time}`}
                  key={`pickup_${lead_time}`}
                >{`${lead_time} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Order Lead Time
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_delivery_order_lead_time"
              className="form-control"
              disabled={this.state.call_in_order === false}
              value={this.state.call_in_delivery_order_lead_time}
              onChange={this.handleCallInDeliveryOrderLeadTimeChange}
            >
              {delivery_order_lead_time_options.map((lead_time) => (
                <option
                  value={`${lead_time}`}
                  key={`delivery_${lead_time}`}
                >{`${lead_time} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup/Delivery Time Interval
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="call_in_pickup_time_interval_in_minutes"
              className="form-control"
              disabled={this.state.call_in_order === false}
              value={this.state.call_in_pickup_time_interval_in_minutes}
              onChange={this.handlePickupTimeIntervalChange}
            >
              {interval_options.map((lead_time) => (
                <option
                  value={`${lead_time}`}
                  key={`interval_${lead_time}`}
                >{`${lead_time} Minutes`}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Pickup/Delivery Time Adjustment Options
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="call_in_pickup_time_adjustment_options"
                className="form-check-input"
                disabled={this.state.call_in_order === false}
                type="checkbox"
                value="0"
                id="0"
                defaultChecked={this.state.call_in_pickup_time_adjustment_options.includes(
                  0
                )}
                onChange={this.handlePickupTimeAdjusmentOptionsChange}
              />
              <label className="form-check-label">No Delay</label>
            </div>
            {adjustment_options.map((option) => (
              <div className="form-check" key={`adjustment_${option}`}>
                <input
                  name="call_in_pickup_time_adjustment_options"
                  className="form-check-input"
                  disabled={this.state.call_in_order === false}
                  type="checkbox"
                  value={`${option}`}
                  id={`${option}`}
                  defaultChecked={this.state.call_in_pickup_time_adjustment_options.includes(
                    option
                  )}
                  onChange={this.handlePickupTimeAdjusmentOptionsChange}
                />
                <label className="form-check-label">{`${option} Minutes Delay`}</label>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(CallInOrderSettings));
