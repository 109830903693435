import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchAnnouncements } from "../../actions/announcement";

import * as Icon from "react-feather";
import AddAnnouncement from "./AddAnnouncement";
import EditAnnouncement from "./EditAnnouncement";

class Announcements extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleView = this.handleView.bind(this);

    this.state = {
      mode: "View",
      announcement: undefined
    };
  }

  componentDidMount() {
    if (this.props.tenant !== undefined) {
      this.props.fetchAnnouncements(this.props.tenant._id);
    }
  }

  handleAdd() {
    this.setState({ mode: "Add" });
  }

  handleEdit(announcement) {
    this.setState({ mode: "Edit", announcement: announcement });
  }

  handleView() {
    this.setState({ mode: "View" });
  }

  arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  render() {
    const { restaurant, announcements, tenant } = this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    if (this.state.mode === "Add") {
      return <AddAnnouncement handleView={this.handleView} />;
    }
    if (this.state.mode === "Edit") {
      return <EditAnnouncement handleView={this.handleView} announcement={this.state.announcement} />;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-bullhorn" />
                {` `}
                <span>{`Announcements`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <button className="btn btn-primary" onClick={this.handleAdd}>
              <Icon.Plus size={16} />
              {` `}Add Announcement
            </button>
          </div>
        </div>
        <div className="row">
          {announcements.map(announcement => (
            <div className="col-4 pt-3 pb-3" key={announcement._id}>
              <div className="card h-100 mb-3">
                {announcement.image && announcement.image.url && (
                  <img
                    className="card-img-top img-fluid"
                    src={process.env.REACT_APP_S3_BASE_URL + `${encodeURIComponent(tenant.slug + '/' + announcement.image.url)}`}
                    alt="Announcement"
                  />

                )}
                <div className="card-body text-center">
                  <h5 className="card-title">{announcement.title}</h5>
                  <p className="card-text">{announcement.description}</p>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => this.handleEdit(announcement)}
                  >
                    <Icon.Edit2 size={16} />
                    {` `}
                    Edit
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    announcements: state.announcement.announcements || []
  };
};

const mapDispatchToProps = dispatch => ({
  fetchAnnouncements: tenantId => dispatch(fetchAnnouncements(tenantId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcements);
