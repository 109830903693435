import React from "react";

import moment from "moment-timezone";
import Numeral from "numeral";
import { sort_menu_items } from "../../utils/sortUtil";

const OrderInfoCard = ({ order, restaurant, coupon }) => {
  if (!order) {
    return <></>;
  }

  const isNextDayOrder = moment(order.pickupTime)
    .tz(restaurant.settings.time_zone)
    .isAfter(moment().tz(restaurant.settings.time_zone).endOf("day"));

  const pickupTimeRange = `${moment(order.pickupTime)
    .tz(restaurant.settings.time_zone)
    .format(restaurant.settings.date_format)} ${moment(order.pickupTime)
    .tz(restaurant.settings.time_zone)
    .format(restaurant.settings.time_format)} - ${moment(order.pickupTime)
    .add(
      order.deliveryMethod === "Delivery"
        ? restaurant.settings.delivery_time_interval_in_minutes
        : restaurant.settings.pickup_time_interval_in_minutes,
      "minutes"
    )
    .tz(restaurant.settings.time_zone)
    .format(restaurant.settings.time_format)}`;
  const adjustedPickupTimeRange = order.adjustedPickupTime
    ? `${moment(order.adjustedPickupTime)
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.date_format)} ${moment(
        order.adjustedPickupTime
      )
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)} - ${moment(
        order.adjustedPickupTime
      )
        .add(
          (order.deliveryMethod === "Pickup" ? 1 : 2) *
            restaurant.settings.pickup_time_interval_in_minutes,
          "minutes"
        )
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`
    : "";

  const sorted_items = sort_menu_items(order.items);

  const pickupTimeType = order.pickupTimeType;

  return (
    <div className="card">
      <div className="card-body">
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Order Number: {order.orderNumber}
          </div>
          <div className="col-12 col-sm-6 col-lg-2 pb-1">
            <span>Order Type: {order.orderType} </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            <span>Delivery Method: {order.deliveryMethod} </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            <span>Payment Method: {order.paymentMethod} </span>
            {order.paymentMethod === "Offline" && (
              <i className="fas fa-cash-register" />
            )}
            {order.paymentMethod === "Online" && (
              <i className="fas fa-credit-card" />
            )}
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Status: {order.status}
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Channel: {order.channel}
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Restaurant: {restaurant.name}
          </div>
          {order.paymentReceiptNumber && (
            <div className="col-12 col-sm-6 col-lg-3 pb-1">
              Payment Receipt Number: {order.paymentReceiptNumber}
            </div>
          )}
        </div>
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-6 pb-1">
            <span>{`Requested ${order.deliveryMethod} Time: `}</span>
            {pickupTimeType && pickupTimeType === "ASAP" && (
              <span
                className={
                  isNextDayOrder
                    ? "text-warning font-weight-bold"
                    : "font-weight-bold"
                }
              >
                {pickupTimeType} /{" "}
              </span>
            )}

            <span
              className={
                isNextDayOrder
                  ? "text-warning font-weight-bold"
                  : "font-weight-bold"
              }
            >
              {pickupTimeRange}
            </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-6 pb-1">
            {order.adjustedPickupTime && (
              <>
                <span>{`Adjusted ${order.deliveryMethod} Time: `}</span>
                <span
                  className={
                    isNextDayOrder
                      ? "text-warning font-weight-bold"
                      : "font-weight-bold"
                  }
                >
                  {adjustedPickupTimeRange}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-sm-6 pb-1">
            <span>
              Received At:{" "}
              {moment(order.receivedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
          <div className="col-12 col-sm-6 pb-1">
            {order.status === "Accepted" && (
              <span>
                Accepted At:{" "}
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Cancelled" && (
              <span>
                Cancelled At:{" "}
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Rejected" && (
              <>
                <div>
                  Rejected At:{" "}
                  {moment(order.confirmedAt)
                    .tz(restaurant.settings.time_zone)
                    .format(restaurant.settings.datetime_format)}
                </div>
                <div>Reject Reason: {order.rejectReason}</div>
              </>
            )}
            {order.status === "Fulfilled" && (
              <span>
                Fulfilled At:{" "}
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            )}
          </div>
        </div>
        <div className="row pb-3 border-bottom">
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Customer Name: {order.nameOrderedUnder}
          </div>
          <div className="col-12 col-sm-6 col-lg-2 pb-1">
            Phone: {order.phone}
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Email: {order.email ?? ""}
          </div>
          {order.deliveryMethod === "Delivery" && order.deliveryAddress && (
            <div className="col-12 col-sm-6 col-lg-4 pb-1">
              Delivery Address: <strong>{order.deliveryAddress.address}</strong>
              <br />
              Suite/Apartment Number:{" "}
              <strong>{order.deliveryAddress.suiteApartmentNumber}</strong>
              <br />
              Postal Code: <strong>{order.deliveryAddress.postalCode}</strong>
              <br />
              {order.deliveryAddress.addressType === "Business" && (
                <>
                  Business Name:{" "}
                  <strong>{order.deliveryAddress.businessName}</strong>
                  <br />
                </>
              )}
              {order.deliveryAddress.addressType === "Other" && (
                <>
                  Other: <strong>{order.deliveryAddress.otherVenueName}</strong>
                  <br />
                </>
              )}
            </div>
          )}
        </div>
        {order.specialRequests && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-12 alert alert-warning">
              <span>
                Special Requests: <strong>{order.specialRequests}</strong>
              </span>
            </div>
          </div>
        )}
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-6">
            <span>
              <strong>Item</strong>
            </span>
          </div>
          <div className="col-2">
            <span>
              <strong>Qty</strong>
            </span>
          </div>
          <div className="col-4 text-right">
            <span>
              <strong>Price</strong>
            </span>
          </div>
        </div>
        {sorted_items.map((item) => (
          <div key={item._id} className="row pt-3 pb-3 border-bottom">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <strong>{`${item.cd ? item.cd + ". " : ""}${
                    item.name
                  }`}</strong>
                </div>
                <div className="col-2">
                  <strong>{`${item.quantity}`}</strong>
                </div>
                <div className="col-4 text-right">
                  <strong>
                    {Numeral(item.baseTotal + item.extraTotal).format(
                      "$0,0.00"
                    )}
                  </strong>
                </div>
              </div>
              {item.size && (
                <div className="row">
                  <div className="col-12">
                    <span className="text-muted ml-3">
                      <small className="highlight-text">
                        Size: {item.size}
                      </small>
                    </span>
                  </div>
                </div>
              )}
              {item.options.map((option, index) => (
                <div className="row" key={index}>
                  <div className="col-12">
                    <span className="text-muted ml-3">
                      <small className="highlight-text">
                        {`${option.name}`}
                        {option.value && <span>{`: ${option.value}`}</span>}
                        {option.total && (
                          <span>{` (${Numeral(option.total).format(
                            "$0,0.00"
                          )})`}</span>
                        )}
                      </small>
                    </span>
                  </div>
                </div>
              ))}

              {item.specialInstructions.map((instruction, index) => (
                <div className="row">
                  <div className="col-12">
                    <span className="text-muted ml-3" key={index}>
                      <small className="highlight-text">{instruction}</small>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {order.promotions &&
          order.promotions.map((promotion) => (
            <div key={promotion._id} className="row pt-3 pb-3 border-bottom">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <strong>{`${promotion.quantity}X ${promotion.promotionName}`}</strong>
                  </div>
                  <div className="col-4 text-right">
                    <strong>
                      {Numeral(promotion.amount).format("$0,0.00")}
                    </strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <span className="promotion-label">{`You saved: ${Numeral(
                      promotion.discountAmount
                    ).format("$0,0.00")}`}</span>
                  </div>
                </div>
                <hr className="dashed-line" />
                {promotion.discountedItems &&
                  promotion.discountedItems.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="row">
                        <div className="col-6">
                          <small>{`${item.cd ? item.cd + ". " : ""}${
                            item.name
                          }`}</small>
                        </div>
                        <div className="col-2">
                          <span>{item.quantity}</span>
                        </div>
                        <div className="col-4 text-right">
                          <div className="text-muted font-italic">
                            {Numeral(item.baseTotal + item.extraTotal).format(
                              "$0,0.00"
                            )}
                          </div>
                          {item.discountAmount > 0 && (
                            <div className="text-muted font-italic">
                              {Numeral(-1 * item.discountAmount).format(
                                "$0,0.00"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {item.size && (
                        <div className="row">
                          <div className="col-12">
                            <span className="text-muted ml-3">
                              <small>Size: {item.size}</small>
                            </span>
                          </div>
                        </div>
                      )}
                      {item.options &&
                        item.options.map((option, index1) => (
                          <div className="row" key={index1}>
                            <div className="col-12">
                              <span className="text-muted ml-3">
                                <small>
                                  {`${option.name}:`}
                                  {option.value && (
                                    <span>{` ${option.value}`}</span>
                                  )}
                                  {option.total && (
                                    <span>{` (${Numeral(option.total).format(
                                      "$0,0.00"
                                    )})`}</span>
                                  )}
                                </small>
                              </span>
                            </div>
                          </div>
                        ))}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          ))}

        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>Subtotal</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.subtotal).format("$0,0.00")}
          </div>
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>GST</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.gst).format("$0,0.00")}
          </div>
        </div>
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-8 text-left">
            <span>PST</span>
          </div>
          <div className="col-4 text-right">
            {Numeral(order.pst).format("$0,0.00")}
          </div>
        </div>
        {order.deliveryMethod === "Delivery" && (
          <>
            <div className="row pt-3 pb-3 border-bottom">
              <div className="col-8 text-left">
                <span>Delivery Fee</span>
                {order.deliveryAddress.distanceInMeter && (
                  <div className="ml-3">
                    <small>
                      Distance: {order.deliveryAddress.distanceInMeter / 1000}{" "}
                      km
                    </small>
                  </div>
                )}
              </div>
              <div className="col-4 text-right">
                {Numeral(order.deliveryFee).format("$0,0.00")}
              </div>
            </div>
            <div className="row pt-3 pb-3 border-bottom">
              <div className="col-8 text-left">
                <span>Delivery GST</span>
              </div>
              <div className="col-4 text-right">
                {Numeral(order.deliveryGST).format("$0,0.00")}
              </div>
            </div>
          </>
        )}

        {order.discounts &&
          order.discounts
            .filter((discount) => !!!discount.isNextOrderCoupon)
            .map((discount, index) => (
              <div className="row pt-3 pb-3 border-bottom" key={index}>
                <div className="col-8">
                  <span className="promotion-label">{`Coupon: ${discount.couponCode.toUpperCase()}`}</span>
                </div>
                <div className="col-4 text-right">
                  {discount.amount && (
                    <strong>
                      {Numeral(-1 * discount.amount).format("$0,0.00")}
                    </strong>
                  )}
                </div>
              </div>
            ))}

        {order.redeemedGiftCards &&
          order.redeemedGiftCards.map((redeemedGiftCard, index) => (
            <div className="row pt-3 pb-3 border-bottom" key={index}>
              <div className="col-8">
                <span>{`Gift Card: ${redeemedGiftCard.giftCardNumber.toUpperCase()}`}</span>
              </div>
              <div className="col-4 text-right">
                {redeemedGiftCard.amountToRedeem && (
                  <strong>
                    {Numeral(-1 * redeemedGiftCard.amountToRedeem).format(
                      "$0,0.00"
                    )}
                  </strong>
                )}
              </div>
            </div>
          ))}
        <div className="row pt-3 pb-3 border-bottom">
          <div className="col-6 text-left">
            <span>
              <strong>Total</strong>
            </span>
          </div>
          <div className="col-2">
            <strong>{`${order.items.length}`}</strong>
          </div>
          <div className="col-4 text-right">
            <strong>{Numeral(order.total).format("$0,0.00")}</strong>
          </div>
        </div>

        {coupon && (
          <div className="row pt-3 pb-3 border-bottom">
            <div className="col-8">
              <span className="promotion-label">{`${
                coupon.name
              }: Code: ${coupon.couponCode.toUpperCase()}`}</span>
            </div>
          </div>
        )}
        {!coupon &&
          order.discounts &&
          order.discounts
            .filter((discount) => discount.isNextOrderCoupon === true)
            .map((discount, index) => (
              <div className="row pt-3 pb-3 border-bottom" key={index}>
                <div className="col-8">
                  <span className="promotion-label">{`You are qualified for coupon: ${discount.name}`}</span>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default OrderInfoCard;
