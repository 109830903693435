import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchReservations,
  acceptReservation,
  rejectReservation
} from "../../actions/reservation";

import ReservationListView from "./ReservationListView";
import ReservationDetail from "./ReservationDetail";

import * as RiIcons from "react-icons/ri";

class Reservations extends Component {
  constructor(props) {
    super(props);

    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewReservations = this.handleViewReservations.bind(this);

    this.handleShowAllReservations = this.handleShowAllReservations.bind(this);
    this.handleShowUpcomingReservations = this.handleShowUpcomingReservations.bind(
      this
    );
    this.handleShowOpenReservations = this.handleShowOpenReservations.bind(
      this
    );

    this.state = {
      showOpenReservations: true,
      showUpcomingReservations: false,
      showAllReservations: false,
      selectedReservation: undefined,
      selectedResturant: undefined
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.props.fetchReservations(this.props.restaurant._id);
    }
  }

  handleViewDetail(reservation) {
    this.setState({ selectedReservation: reservation });
  }

  handleViewReservations() {
    this.setState({ selectedReservation: undefined });
    this.props.fetchReservations();
  }

  handleShowOpenReservations() {
    this.setState({
      showOpenReservations: true,
      showUpcomingReservations: false,
      showAllReservations: false
    });
  }

  handleShowUpcomingReservations() {
    this.setState({
      showOpenReservations: false,
      showUpcomingReservations: true,
      showAllReservations: false
    });
  }

  handleShowAllReservations() {
    this.setState({
      showOpenReservations: false,
      showUpcomingReservations: false,
      showAllReservations: true
    });
  }

  render() {
    const {
      tenant,
      restaurant,
      allReservations,
      openReservations,
      upcomingReservations
    } = this.props;
    const filteredAllReservations = this.state.selectedResturant
      ? allReservations.filter(
          reservation =>
            reservation.restaurant._id === this.state.selectedResturant._id
        )
      : allReservations;
    const filteredOpenReservations = this.state.selectedResturant
      ? openReservations.filter(
          reservation =>
            reservation.restaurant._id === this.state.selectedResturant._id
        )
      : openReservations;
    const filteredUpcomingReservations = this.state.selectedResturant
      ? upcomingReservations.filter(
          reservation =>
            reservation.restaurant._id === this.state.selectedResturant._id
        )
      : upcomingReservations;

    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.selectedReservation) {
      return (
        <ReservationDetail
          reservationId={this.state.selectedReservation._id}
          handleViewReservations={this.handleViewReservations}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{tenant.name}</span>
              </button>
            </li> */}
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <RiIcons.RiReservedLine />
                {` `}
                <span>{`Reservations`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2" />
        </div>

        <div className="mb-3">
          <button
            type="button"
            className={`btn ${
              this.state.showOpenReservations
                ? " btn-primary"
                : "btn-outline-primary"
            }  mr-2`}
            onClick={this.handleShowOpenReservations}
          >
            <span>Open</span>
            {` `}{" "}
            <span className="badge badge-success">
              {filteredOpenReservations.length}
            </span>
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showUpcomingReservations
                ? " btn-secondary"
                : "btn-outline-secondary"
            }  mr-2`}
            onClick={this.handleShowUpcomingReservations}
          >
            <span>Upcoming</span>
            {` `}{" "}
            <span className="badge badge-success">
              {filteredUpcomingReservations.length}
            </span>
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showAllReservations ? " btn-info" : "btn-outline-info"
            }  mr-2`}
            onClick={this.handleShowAllReservations}
          >
            <span>All</span>
            {` `}{" "}
            <span className="badge badge-success">
              {filteredAllReservations.length}
            </span>
          </button>
        </div>

        {this.state.showAllReservations && (
          <ReservationListView
            restaurant={restaurant}
            reservations={filteredAllReservations}
            handleViewDetail={this.handleViewDetail}
          />
        )}
        {this.state.showUpcomingReservations && (
          <ReservationListView
            restaurant={restaurant}
            reservations={filteredUpcomingReservations}
            handleViewDetail={this.handleViewDetail}
          />
        )}
        {this.state.showOpenReservations && (
          <ReservationListView
            restaurant={restaurant}
            reservations={filteredOpenReservations}
            handleViewDetail={this.handleViewDetail}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    restaurants: state.tenant.restaurants || [],
    reservation: state.reservation.reservation,
    allReservations: state.reservation.reservations
      ? state.reservation.reservations
      : [],
    openReservations: state.reservation.reservations
      ? state.reservation.reservations.filter(
          reservation => reservation.status === "Received"
        )
      : [],
    upcomingReservations: state.reservation.reservations
      ? state.reservation.reservations.filter(
          reservation => reservation.status === "Accepted"
        )
      : []
  };
};

const mapDispatchToProps = dispatch => ({
  fetchReservations: restaurantId => dispatch(fetchReservations(restaurantId)),
  acceptReservation: reservationId =>
    dispatch(acceptReservation(reservationId)),
  rejectReservation: reservationId => dispatch(rejectReservation(reservationId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reservations);
