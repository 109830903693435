import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

import Numeral from "numeral";

import { fetchInvoices } from "../../actions/tenant";

import Loading from "../Common/Loading";

class Invoices extends Component {
  constructor(props) {
    super(props);

    this.handleSelectStatus = this.handleSelectStatus.bind(this);
    this.state = {
      status: "All",
      loading: false,
    }
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.setState({
        loading: true
      });
      this.props.fetchInvoices(this.props.tenant._id, () => {
        this.setState({
          loading: false
        });
      });

    }
  }

  handleSelectStatus(status) {
    this.setState({
      status: status
    })
  }

  render() {
    const { invoices = [], tenant, handleViewInvoice } = this.props;
    invoices.sort((a, b) => moment(a.invoice_date).isBefore(moment(b.invoice_date)) ? 1 : -1);
    const filteredInvoices = this.state.status === "All" ? invoices :  invoices.filter(invoice => invoice.status === this.state.status);
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-start mb-3 border-bottom">
              <div className="btn-toolbar mb-2">
                <div className="dropdown">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>{`Status ${this.state.status}`}</span>
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectStatus("All")}
                    >{`All`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectStatus("Pending")}
                    >{`Pending`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectStatus("Invoiced")}
                    >{`Invoiced`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectStatus("Paid")}
                    >{`Paid`}</button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row border-bottom pb-3">
          <div className="col-2">
            <strong>{`Invoice Status`}</strong>
          </div>
          <div className="col-2">
            <strong>{`Invoice Date`}</strong>
          </div>
          <div className="col-2">
            <strong>{`Invoice Period`}</strong>
          </div>
          <div className="col-2 text-right">
            <strong>{`Total`}</strong>
          </div>
          <div className="col-2 text-right">
            <strong>{`Discount`}</strong>
          </div>
          <div className="col-2 text-right">
            <strong>{`Net Total`}</strong>
          </div>
          {/* <div className="col-3">
            <strong>{`Download As`}</strong>
          </div> */}
        </div>
        {this.state.loading && <Loading />}
        {this.state.loading === false && filteredInvoices.map(invoice => (
          <div className="row clickable-row py-3 border-bottom" key={invoice._id} onClick={() => handleViewInvoice(invoice)}>
            <div className="col-2">
              {invoice.status}
            </div>
            <div className="col-2">
              {moment(invoice.invoice_date).tz(tenant.settings.time_zone).format(tenant.settings.date_format)}
            </div>
            <div className="col-2">
              {`${moment(invoice.billing_period_from).tz(tenant.settings.time_zone).format(tenant.settings.date_format)} - ${moment(invoice.billing_period_to).tz(tenant.settings.time_zone).format(tenant.settings.date_format)}`}
            </div>
            <div className="col-2 text-right">
            {Numeral(invoice.subtotal).format(
                      "$0,0.00"
                    )}
            </div>
            <div className="col-2 text-right">
            {invoice.discount && invoice.discount.amount && Numeral(invoice.discount.amount).format(
                      "$0,0.00"
                    )}
            </div>           
            <div className="col-2 text-right">
            {Numeral(invoice.total).format(
                      "$0,0.00"
                    )}
            </div>             
            {/* <div className="col-3">
              <div className="btn-toolbar">
                <div className="btn-group btn-group-sm">
                  <button type="button" className="btn btn-secondary mr-1">{`PDF`}</button>
                  <button type="button" className="btn btn-secondary mr-1">{`TXT`}</button>
                  <button type="button" className="btn btn-secondary">{`CSV`}</button>
                </div>
              </div>
            </div> */}
          </div>
        ))}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    invoices: state.tenant.invoices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: (tenantId, callback) => dispatch(fetchInvoices(tenantId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
