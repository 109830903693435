import React, { Component } from "react";
import * as Icon from "react-feather";

class MenuSectionList extends Component {
  render() {
    const {
      tenant,
      sections = [],
      handleViewMenuSection,
      handleAddMenuSection,
    } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Sections</h5>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                onClick={handleAddMenuSection}
              >
                <Icon.Plus size={16} />
                <span className="d-none d-lg-inline">{` `}New Section</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          {sections &&
            sections.map((section) => (
              <React.Fragment key={section._id}>
                <div
                  className="row clickable-row"
                  onClick={() => handleViewMenuSection(section)}
                >
                  <div className="col-12 col-sm-4 col-lg-3 text-center">
                    {section.image && section.image.url && (
                      <img
                        className="img-fluid mb-2"
                        src={
                          process.env.REACT_APP_S3_BASE_URL +
                          `${tenant.slug}/${section.image.url.replace(
                            ".jpg",
                            ""
                          )}.jpg`
                        }
                        alt="Menu Section"
                      />
                    )}

                    {(!section.image || !section.image.url) && (
                      <img
                        className={"img-fluid mr-3"}
                        src={`https://via.placeholder.com/250x150.png?text=${tenant.name}`}
                        alt={`menu section`}
                      />
                    )}
                  </div>
                  <div className="col-12 col-sm-8 col-lg-9 text-center text-sm-left">
                    <h5 className="mt-2 mt-sm-0">{section.name}</h5>
                    <p>{section.description}</p>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {section.items.filter((item) => item.disabled === false)
                        ? section.items.filter(
                            (item) => item.disabled === false
                          ).length
                        : 0}{" "}
                      Items
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
}

export default MenuSectionList;
