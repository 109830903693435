import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteCarousel } from "../../actions/tenant";

import { isOwner } from "../User";
import * as Icon from "react-feather";
import AddCarousel from "./AddCarousel";
import EditCarousel from "./EditCarousel";
class CarouselSettings extends Component {
  constructor(props) {
    super(props);

    this.handleAddCarousel = this.handleAddCarousel.bind(this);
    this.handleEditCarousel = this.handleEditCarousel.bind(this);
    this.handleDeleteCarousel = this.handleDeleteCarousel.bind(this);
    this.handleViewCarouselSettings = this.handleViewCarouselSettings.bind(
      this
    );

    this.state = {
      selectedCarouselItem: undefined,
      mode: undefined
    };

  }
  componentDidMount() { }

  handleAddCarousel() {
    this.setState({ mode: "Add" });
  }

  handleEditCarousel(selectedCarouselItem) {
    this.setState({ mode: "Edit", selectedCarouselItem: selectedCarouselItem });
  }

  handleDeleteCarousel(selectedCarouselItem) {

    this.props.deleteCarousel(
      this.props.tenant._id,
      selectedCarouselItem._id,
      () => {
        this.handleViewCarouselSettings();
      }
    );
  }

  handleViewCarouselSettings() {
    this.setState({ mode: undefined });
  }

  render() {
    const {
      carousel_items,
      tenant
    } = this.props;

    if (this.state.mode === "Add") {
      return (
        <AddCarousel
          tenant={tenant}
          handleViewCarouselSettings={this.handleViewCarouselSettings}
        />
      );
    }
    if (this.state.mode === "Edit") {
      return (
        <EditCarousel
          tenant={tenant}
          carousel_item={this.state.selectedCarouselItem}
          handleViewCarouselSettings={this.handleViewCarouselSettings}
        />
      );
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => window.location.href = "/settings"}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="far fa-images" />
                {` `}
                <span className="d-none d-sm-inline">{`Carousel`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="h-100">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h3 className="d-none d-sm-inline">Carousel</h3>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2">
                {isOwner() && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.handleAddCarousel()}
                  >
                    <Icon.Plus size={16} />
                    {` `}
                    Add New Carousel
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row pb-3 mb-3 border-bottom">
            <div className="col-lg-3 text-center"><strong>Image</strong></div>
            <div className="col-lg-1 text-center"><strong>Sequence</strong></div>
            <div className="col-lg-2 text-center"><strong>Caption</strong></div>
            <div className="col-lg-3 text-center"><strong>Description</strong></div>
            <div className="col-lg-3 text-center"><strong>Actions</strong></div>
          </div>
          {carousel_items.map((carousel_item, index) => (
            <div className="row clickable-row pb-3 my-3 border-bottom" key={carousel_item._id}>
              <div className="col-12 col-lg-3 text-center">
                {carousel_item.image && carousel_item.image.url && (
                  <img
                    className="img-fluid"
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${encodeURIComponent(
                        tenant.slug +
                        "/" +
                        carousel_item.image.url.replace(".jpg", "") +
                        ".jpg"
                      )}`
                    }
                    alt="Carousel"
                  />

                )}
              </div>
              <div className="col-12 col-lg-1 ">
                {carousel_item.sequence}
              </div>
              <div className="col-12 col-lg-2 ">
                {carousel_item.caption}
              </div>
              <div className="col-12 col-lg-3 ">
                {carousel_item.description}
              </div>
              <div className="col-12 col-lg-3 ">
                <button
                  type="button"
                  className="btn btn-light mr-3"
                  onClick={() => this.handleEditCarousel(carousel_item)}
                >
                  <Icon.Edit size={16} />
                  {` `}
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => this.handleDeleteCarousel(carousel_item)}
                >
                  <Icon.Trash size={16} />
                  {` `}
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    carousel_items: state.tenant.tenant?.carousel_items || []
  };
};

const mapDispatchToProps = dispatch => ({
  deleteCarousel: (tenantId, carouselItemId, callback) =>
    dispatch(deleteCarousel(tenantId, carouselItemId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSettings);
