import React, { Component } from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

import { isSame } from "../../utils/equalUtil";
import { sort_menu_items } from "../../utils/sortUtil";

class KitchenReceipt extends Component {
  render() {
    const { order, restaurant } = this.props;
    if (!order) {
      return <></>;
    }

    let items = [];
    if (order.items) {
      order.items.forEach((item) => {
        items.push(Object.assign({}, item));
      });
    }

    if (order.promotions) {
      order.promotions.forEach((promo) => {
        promo.discountedItems.forEach((item1) => {
          let found = false;
          items.forEach((item2) => {
            if (isSame(item2, item1)) {
              item2.quantity += item1.quantity;
              found = true;
            }
          });
          if (!found) {
            items.push(Object.assign({}, item1));
          }
        });
      });
    }

    const sorted_items = sort_menu_items(items);

    let totalQuantity = 0;
    items.forEach((item) => {
      totalQuantity += item.quantity;
    });

    const startAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`;

    const endAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
          .add(
            (order.deliveryMethod === "Pickup" ? 1 : 2) *
              restaurant.settings.pickup_time_interval_in_minutes,
            "minutes"
          )
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
          .add(
            (order.deliveryMethod === "Pickup" ? 1 : 2) *
              restaurant.settings.pickup_time_interval_in_minutes,
            "minutes"
          )
          .tz(restaurant.settings.time_zone)
          .format(restaurant.settings.time_format)}`;

    const pickupTimeRange = `${startAt} - ${endAt}`;

    return (
      <div className="container-fluid">
        {/* <div className="card text-white bg-info mb-3">
          <div className="card-header font-weight-bold">{order.orderType}</div>
        </div> */}
        {/* <div className="card text-white bg-info mb-3">
          <div className="card-header font-weight-bold">
            {order.deliveryMethod}
          </div>
        </div>
        <div className="card text-white bg-info mb-3">
          <div className="card-header font-weight-bold">
            {order.adjustedPickupTime
              ? moment(order.adjustedPickupTime)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)
              : moment(order.pickupTime)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span className="font-weight-bold">{order.deliveryMethod}</span>
            <span>{` Time:`}</span>
          </div>
          <div className="col-6 text-right">{pickupTimeRange}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <strong>Order details:</strong>
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span>Order #:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.orderNumber}</span>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6">
            <span>Accepted at:</span>
          </div>
          <div className="col-6 text-right">
            <span>
              {moment(order.confirmedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
        </div> */}
        <div className="row">
          <div className="col-6">
            <span>Phone:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.phone}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <span>Name:</span>
          </div>
          <div className="col-6 text-right">
            <span>{order.nameOrderedUnder}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {order.specialRequests && (
          <>
            <div className="row">
              <div className="col-12">
                <i className="fas fa-comment mr-3" />
                <strong className="highlight-text">
                  {order.specialRequests}
                </strong>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <hr className="dashed-line" />
              </div>
            </div>
          </>
        )}

        {/* <div className="row pt-3 border-bottom mb-3">
          <div className="col-12">
            <strong>Item:</strong>
          </div>
        </div> */}
        {sorted_items.map((item) => (
          <div key={item._id} className="row">
            <div className="col-12">
              <strong>{`${item.quantity}X `}</strong>
              {item.cd && <span>{`${item.cd}.`}</span>}
              <span>{` ${item.name}`}</span>
              <ul className="list-unstyled ml-3">
                {item.size && (
                  <li className="text-muted font-italic">
                    <small className="highlight-text">Size: {item.size}</small>
                  </li>
                )}
                {item.options.map((option, index) => (
                  <li className="text-muted font-italic" key={index}>
                    <small className="highlight-text">
                      {option.name}{" "}
                      {option.value && <span>: {option.value}</span>}
                    </small>
                  </li>
                ))}
                {item.specialInstructions &&
                  item.specialInstructions.length > 0 &&
                  item.specialInstructions.map((instruction, index) => (
                    <li className="text-muted font-italic" key={`si-${index}`}>
                      <small className="highlight-text">{instruction}</small>
                    </li>
                  ))}
              </ul>

              {/* <div className="col-1 text-right">
                  <i className="far fa-square" />
                </div> */}

            </div>
          </div>
        ))}
        <div className="row">
          <div className="col-12">
            <hr className="dashed-line" />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-10">{`Subtotal`}</div>
          <div className="col-2 text-right">
            {Numeral(order.subtotal).format("$0,0.00")}
          </div>
        </div>
        <div className="row">
          <div className="col-10">{`Tax`}</div>
          <div className="col-2 text-right">
            {Numeral(order.tax).format("$0,0.00")}
          </div>
        </div> */}
        <div className="row">
          <div className="col-10">{`Total Quantity`}</div>
          <div className="col-2 text-right">{totalQuantity}</div>
        </div>
        <div className="row">
          <div className="col-10">
            <span>{`Total`}</span>
          </div>
          <div className="col-2 text-right">
            <span>{Numeral(order.total).format("$0,0.00")}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default KitchenReceipt;
