import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import { updateMenuItemItem } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemItemEdit",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.spicy) {
    errors.spicy = "Please enter spicy";
  }

  return errors;
}

class MenuItemItemEditModal extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSpicyChange = this.handleSpicyChange.bind(this);

    this.state = {
      name: props.menuItemItem.name,
      spicy: props.menuItemItem.spicy,
    };
  }

  componentDidMount() { }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleSpicyChange(event) {
    this.setState({ spicy: event.target.value });
  }

  handleFormSubmit(formProps) {

    this.props.updateMenuItemItem(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      this.props.menuItemItemId,
      {
        name: this.state.name,
        spicy: this.state.spicy
      }
    );
    $(".close").click();
  }

  generateModalId(menuItemItemId) {
    return "menuItemItemEditModal" + menuItemItemId;
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      menuItemItemId
    } = this.props;

    return (
      <>
        <div
          className="modal fade"
          id={this.generateModalId(menuItemItemId)}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#menuItemItemEditModallLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="menuItemItemEditModalLabel">
                    {"Item Info"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleNameChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Spicy</label>
                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          name="spicy"
                          className="form-check-input"
                          value={`not`}
                          onChange={this.handleSpicyChange}
                          type="radio"
                          defaultChecked={!this.state.spicy || this.state.spicy === 'not'}
                        />
                        <label className="form-check-label">{` `}{`Not`}</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="spicy"
                          className="form-check-input"
                          value={`mild`}
                          onChange={this.handleSpicyChange}
                          type="radio"
                          defaultChecked={this.state.spicy === 'mild'}
                        />
                        <label className="form-check-label">{` `}{`Mild`}</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="spicy"
                          className="form-check-input"
                          value={`medium`}
                          onChange={this.handleSpicyChange}
                          type="radio"
                          defaultChecked={this.state.spicy === 'medium'}
                        />
                        <label className="form-check-label">{` `}{`Medium`}</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          name="spicy"
                          className="form-check-input"
                          value={`hot`}
                          onChange={this.handleSpicyChange}
                          type="radio"
                          defaultChecked={this.state.spicy === 'hot'}
                        />
                        <label className="form-check-label">{` `}{`Hot`}</label>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                  </button>
                    <button type="submit" className="btn btn-primary">
                      {"Save"}
                    </button>
                  </div>
              </form>
            </div>
            </div>
          </div>
      </>
        );
      }
    }
    
const mapStateToProps = state => {
  return {
          errorMessage: state.menu.errorMessage,
        message: state.menu.message,
      };
    };
    
const mapDispatchToProps = dispatch => ({
          updateMenuItemItem: (
          menuId,
          menuSectionId,
          menuItemId,
          menuItemItemId,
          formProps
        ) =>
          dispatch(
            updateMenuItemItem(
              menuId,
              menuSectionId,
              menuItemId,
              menuItemItemId,
              formProps
            )
          )
      });
      
      export default connect(
        mapStateToProps,
        mapDispatchToProps
      )(form(MenuItemItemEditModal));
