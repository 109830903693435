import axios from "axios";

import {
  FETCH_ORDER,
  FETCH_ORDERS,
  ACCEPT_ORDER,
  REJECT_ORDER,
  START_ORDER,
  COMPLETE_ORDER,
  FULFILL_ORDER,
  ORDER_ERROR,
  CHANGE_ORDER_TYPE,
  SEND_EMAIL,
  SAVE_ORDER,
  CLEAR_CART,
  FETCH_CALL_IN_ORDERS,
  UPDATE_ORDER,
  UPDATE_DELIVERY_INFO,
  APPLY_COUPON_TO_ORDER
} from "./types";

import { errorHandler, getData, patchData } from "./index";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export function changeOrderType(orderType) {
  return {
    type: CHANGE_ORDER_TYPE,
    payload: orderType
  };
}

export function fetchOrder(tenantId, orderNumber, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderNumber}`;
  return dispatch => getData(FETCH_ORDER, ORDER_ERROR, true, url, dispatch, callback);
}

export function fetchOrders(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/orders`;
  return dispatch => getData(FETCH_ORDERS, ORDER_ERROR, true, url, dispatch);
}

export function searchOrders(tenantId, restaurantIds, orderNumber, phoneNumber, periodFrom, periodTo) {
  const queryParams = [];
  if (restaurantIds && restaurantIds.length > 0) {
    for (const restaurantId of restaurantIds) {
      queryParams.push(`restaurantId=${restaurantId}`);
    }
  }
  if (orderNumber) {
    queryParams.push(`orderNumber=${orderNumber}`);
  }
  if (phoneNumber) {
    queryParams.push(`phoneNumber=${phoneNumber}`);
  }
  if (periodFrom) {
    queryParams.push(`periodFrom=${periodFrom}`);
  }
  if (periodTo) {
    queryParams.push(`periodTo=${periodTo}`);
  }
  const queryString = queryParams.join("&");
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders` + ((queryParams.length > 0) ? `?${queryString}` : "");

  return dispatch => getData(FETCH_ORDERS, ORDER_ERROR, true, url, dispatch);
}

export function fetchCallInOrders(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/callInOrders`;
  return dispatch => getData(FETCH_CALL_IN_ORDERS, ORDER_ERROR, true, url, dispatch);
}

export function acceptOrder(tenantId, orderId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/accept`;
  const data = {

  }
  return dispatch => patchData(ACCEPT_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function acceptOrderWithDelay(tenantId, orderId, delayInMinutes, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/accept`;
  const data = {
    delayInMinutes: delayInMinutes
  }
  return dispatch => patchData(ACCEPT_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function rejectOrder(tenantId, orderId, rejectReason, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/reject`;
  var data = {
    rejectReason: rejectReason
  }

  return dispatch => patchData(REJECT_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function startOrder(tenantId, orderId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/start`;
  const data = {

  }
  return dispatch => patchData(START_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function completeOrder(tenantId, orderId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/complete`;
  const data = {

  }
  return dispatch => patchData(COMPLETE_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}


export function fulfillOrder(tenantId, orderId, paymentReceiptNumber, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/fulfill`;
  const data = {
    offlinePaymentReceiptNumber: paymentReceiptNumber
  }
  return dispatch => patchData(FULFILL_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function sendEmail({ sender, recipient, bcc, subject, message }, callback) {

  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eatery/communication/email`, { sender, recipient, bcc, subject, message })
      .then((response) => {
        callback()
        dispatch({
          type: SEND_EMAIL,
          payload: response.data.message
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, ORDER_ERROR);
      });
  };
}

export function cancelOrder(callback) {
  return dispatch => callback(dispatch);
}

export function saveOrder(
  isCallInOrder,
  restaurantId,
  items,
  promotions,
  discounts,
  discountTotal,
  subtotal,
  tax,
  gst,
  pst,
  total,
  specialRequests,
  pickupDate,
  pickupTime,
  nameOrderedUnder,
  phone,
  address,
  payment,
  deliveryMethod,
  deliveryAddress,
  deliveryFee,
  deliveryGST,
  orderLink,
  successCallback
) {

  let headers = { headers: { Authorization: cookies.get("utoken") } };

  return function (dispatch) {
    axios
      .post(
        process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/orders`,
        {
          isCallInOrder,
          items,
          promotions,
          discounts,
          discountTotal,
          subtotal,
          tax,
          gst,
          pst,
          total,
          specialRequests,
          pickupDate,
          pickupTime,
          nameOrderedUnder,
          phone,
          address,
          payment,
          deliveryMethod,
          deliveryAddress,
          deliveryFee,
          deliveryGST,
          orderLink
        },
        headers)
      .then(response => {
        successCallback(response.data);
        dispatch({ type: SAVE_ORDER });
        dispatch({ type: CLEAR_CART });

      })
      .catch(error => {
        console.log("saveOrder", error)
        errorHandler(dispatch, error.response, ORDER_ERROR);
      });
  };
}

export function updateOrder(tenantId, orderId, {pickupTime, phone}, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/update`;
  var data = {
    pickupTime: pickupTime,
    phone: phone,
  }

  return dispatch => patchData(UPDATE_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function updateDeliveryInfo(tenantId, orderId, {deliveryAddress, deliveryFee, deliveryGST, total}, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/update-delivery-info`;
  var data = {
    deliveryAddress, 
    deliveryFee, 
    deliveryGST, 
    total
  }

  return dispatch => patchData(UPDATE_DELIVERY_INFO, ORDER_ERROR, true, url, dispatch, data, callback);
}

export function applyCoupon(tenantId, orderId, {couponCode}, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/orders/${orderId}/apply-coupon`;
  var data = {
    couponCode: couponCode,
  }

  return dispatch => patchData(APPLY_COUPON_TO_ORDER, ORDER_ERROR, true, url, dispatch, data, callback);
}