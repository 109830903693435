import React, { Component } from "react";

class FixedDiscountAmountOnCartPromoBenefit extends Component {
  render() {
    const {
      currencyCode,
      sectionNumber,
      discountAmount,
      handleDiscountAmountChange,
      isNextOrderCoupon,
      handleIsNextOrderCouponChange
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Set promo deal benefit (What's in it for your client)
              </strong>
        </div>
        <div className="card-body">
          <div className="pb-3">
            <strong>{`Grant fixed discount amount on total order value`}</strong>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">Set the benefit:</strong>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">
                  Discount:
                    </label>
                <div className="input-group">
                  <input
                    name="discount_amount"
                    type="number"
                    className="form-control col-4"
                    value={discountAmount}
                    onChange={handleDiscountAmountChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{currencyCode}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              {`The discount amount will be deducted from the order cart sub-total value and it will be explicitly shown in the cart calculation.`}
            </div>
          </div>
          <div className="form-check">
                <input type="checkbox" className="form-check-input" id="isNextOrderCoupon"
                  onChange={handleIsNextOrderCouponChange}
                  defaultChecked={isNextOrderCoupon}
                />
                <label className="form0-check-label" for="isNextOrderCoupon">Is next order coupon?</label>
              </div>
        </div>
      </div>
    );
  }
}

export default FixedDiscountAmountOnCartPromoBenefit;