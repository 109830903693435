import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { renderField } from "../../utils/renderFields";

import $ from "jquery";

import Numeral from "numeral";
import visa_icon from "payment-icons/min/flat/visa.svg";
import mastercard_icon from "payment-icons/min/flat/mastercard.svg";
import amex_icon from "payment-icons/min/flat/amex.svg";

import { fulfillOrder } from "../../actions/order";
import { socket } from "../../service/socket";

const form = reduxForm({
  form: "fulfillOrder",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.paymentReceiptNumber) {
    errors.message = "Please enter paymentReceiptNumber";
  }

  return errors;
}

class FulfillOrder extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      disabled: false,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.setState({ disabled: true });
    this.props.fulfillOrder(
      this.props.tenant._id,
      this.props.order._id,
      formProps.paymentReceiptNumber,
      (data) => {
        socket.emit(
          "fulfill order",
          this.props.restaurant._id,
          data.order,
          this.props.fetchPeriod
        );
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, order, restaurant } = this.props;
    return (
      <>
        <button
          type="button"
          disabled={order.status !== "Accepted"}
          className="btn btn-outline-info mr-2"
          data-toggle="modal"
          data-target="#fulfillOrderModal"
          data-backdrop="static"
        >
          <i className="far fa-credit-card d-inline mr-1" />
          <span className="d-none d-lg-inline">Fulfill</span>
        </button>

        <div
          className="modal fade"
          id="fulfillOrderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#fulfillOrderModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="fulfillOrderModalLabel">
                    {`Fulfill Order`}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  {order.paymentMethod === "Offline" &&
                    restaurant.settings.require_payment_receipt_number ===
                      true && (
                      <>
                        <p className="text-left">
                          <i className="fas fa-cash-register fa-2x mr-2"> </i>
                          {`Please collect money and enter the payment receipt number`}
                        </p>
                        <div className="form-group">
                          <label className="col-form-label">
                            Payment Receipt Number
                          </label>
                          <Field
                            name="paymentReceiptNumber"
                            className="form-control"
                            component={renderField}
                            type="text"
                          />
                        </div>
                      </>
                    )}
                  {order.paymentMethod === "Online" && order.onlinePaymentInfo && (
                    <>
                      <p className="text-left">
                        <i className="fas fa-credit-card fa-2x mr-2"> </i>
                        <span>Charge</span>
                        <strong>
                          {" "}
                          {Numeral(order.total).format("$0,0.00")}
                        </strong>{" "}
                        <span>
                          {" "}
                          on{" "}
                          {order.onlinePaymentInfo &&
                            order.onlinePaymentInfo.cardBrand === "Visa" && (
                              <img
                                src={visa_icon}
                                alt="visa"
                                width={25}
                                height={16}
                                className="mr-2"
                              />
                            )}
                          {order.onlinePaymentInfo &&
                            order.onlinePaymentInfo.cardBrand ===
                              "MasterCard" && (
                              <img
                                src={mastercard_icon}
                                alt="mastercard"
                                width={25}
                                height={16}
                                className="mr-2"
                              />
                            )}
                          {order.onlinePaymentInfo &&
                            order.onlinePaymentInfo.cardBrand ===
                              "American Express" && (
                              <img
                                src={amex_icon}
                                alt="amex"
                                width={25}
                                height={16}
                                className="mr-2"
                              />
                            )}
                        </span>
                      </p>
                      <p>
                        {`Card number: ending in ${order.onlinePaymentInfo.cardLast4}`}
                      </p>
                      <p>
                        {`Card expires: ${order.onlinePaymentInfo.cardExpMonth}/${order.onlinePaymentInfo.cardExpYear}`}
                      </p>
                      <p>
                        Card holder name:{" "}
                        {order.onlinePaymentInfo.cardHolderName}
                      </p>
                    </>
                  )}
                </div>
                <div className="modal-footer d-block text-center">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.disabled}
                  >
                    {order.paymentMethod === "Online" ? `OK` : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fulfillOrder: (
    tenantId,
    orderNumber,
    offlinePaymentReceiptNumber,
    callback
  ) =>
    dispatch(
      fulfillOrder(tenantId, orderNumber, offlinePaymentReceiptNumber, callback)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(FulfillOrder));
