import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import Switch from "react-switch";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "onlineReservationSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class OnlineReservationSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleMaximumPartySizeChange = this.handleMaximumPartySizeChange.bind(
      this
    );
    this.handleOnlineReservationStartChange = this.handleOnlineReservationStartChange.bind(
      this
    );
    this.handleOnlineReservationEndChange = this.handleOnlineReservationEndChange.bind(
      this
    );
    this.handleFutureReservationDaysChange = this.handleFutureReservationDaysChange.bind(
      this
    );

    this.state = {
      tenantId: props.tenant._id,
      online_reservation: props.tenant.settings.online_reservation,
      max_party_size: props.tenant.settings.max_party_size,
      future_reservation_days: props.tenant.settings.future_reservation_days,
      online_reservation_start: props.tenant.settings.online_reservation_start,
      online_reservation_end: props.tenant.settings.online_reservation_end
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        online_reservation: this.state.online_reservation,
        max_party_size: this.state.max_party_size,
        online_reservation_start: this.state.online_reservation_start,
        online_reservation_end: this.state.online_reservation_end,
        future_reservation_days: this.state.future_reservation_days
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleFutureReservationDaysChange(event) {
    this.setState({ future_reservation_days: event.target.value });
  }

  handleMaximumPartySizeChange(event) {
    this.setState({ max_party_size: event.target.value });
  }

  handleOnlineReservationStartChange(event) {
    this.setState({ online_reservation_start: event.target.value });
  }

  handleOnlineReservationEndChange(event) {
    this.setState({ online_reservation_end: event.target.value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }


  render() {
    const { handleViewSettings } = this.props;

    return (
      <>
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewSettings}
            >
              <i className="fas fa-cog" />
              {` `}
              <span className="d-none d-sm-inline">{`Settings`}</span>
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <button type="button" className="btn btn-light" disabled>
              <i className="fa fa-th-large" />
              {` `}
              <span className="d-none d-sm-inline">{`Online Reservation`}</span>
            </button>
          </li>
        </ol>
      </nav>
      {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
      {this.renderAlert()}
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          {`Tenant`}
        </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <input
            type="text"
            readOnly
            className="form-control-plaintext"
            value={this.props.tenant.name}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          {`Online Reservation`}
        </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <Switch
            className="react-switch"
            onChange={() => { this.setState(prevState => { return { online_reservation: !prevState.online_reservation } }) }}
            checked={this.state.online_reservation || false}
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  paddingRight: 2
                }}
              >
                No
                  </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  paddingRight: 2
                }}
              >
                Yes
                  </div>
            }
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          Maximum Party Size
          </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <input
            type="number"
            className="form-control"
            name="max_party_size"
            value={this.state.max_party_size}
            disabled={this.state.online_reservation === false}
            onChange={this.handleMaximumPartySizeChange}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          Future Reservation
          </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <select
            name="future_reservation_days"
            className="form-control"
            value={this.state.future_reservation_days}
            disabled={this.state.online_reservation === false}
            onChange={this.handleFutureReservationDaysChange}
          >
            <option value="0">Today Only</option>
            <option value="1">1 day ahead</option>
            <option value="2">2 days haead</option>
            <option value="3">3 days haead</option>
            <option value="4">4 days haead</option>
            <option value="5">5 days haead</option>
            <option value="6">6 days haead</option>
            <option value="7">7 days haead</option>
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          Online Reservation Start
          </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <select
            name="online_reservation_start"
            className="form-control"
            disabled={this.state.online_reservation === false}
            value={this.state.online_reservation_start}
            onChange={this.handleOnlineReservationStartChange}
          >
            <option value="0">Opening</option>
            <option value="15">15 minutes after opening</option>
            <option value="30">30 minutes after opening</option>
            <option value="45">45 minutes after opening</option>
            <option value="60">60 minutes after opening</option>
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          Online Reservation End
          </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <select
            name="online_reservation_end"
            className="form-control"
            disabled={this.state.online_reservation === false}
            value={this.state.online_reservation_end}
            onChange={this.handleOnlineReservationEndChange}
          >
            <option value="0">Closing</option>
            <option value="15">15 minutes prior closing</option>
            <option value="30">30 minutes prior closing</option>
            <option value="45">45 minutes prior closing</option>
            <option value="60">60 minutes prior closing</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center border-top pb-3 pt-3">
          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={this.props.handleViewSettings}
          >
            {`Cancel`}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleFormSubmit}
          >
            {"Save"}
          </button>
        </div>
      </div>

      {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(OnlineReservationSettings));
