import { getData, postData, patchData, deleteData } from "./index";

import {
  UPDATE_SETTINGS,
  UPDATE_RESTAURANT_INFO,
  FETCH_PUBLISHED_MENUS,

  FETCH_BUSINESS_HOURS,
  UPDATE_BUSINESS_HOUR,
  DELETE_BUSINESS_HOUR,
  ADD_BUSINESS_HOUR,
  RESTAURANT_ERROR,
  FETCH_PICKUP_DATES,
  FETCH_VALID_PROMOTIONS,
  FETCH_RESTAURANT
} from "./types";

export function fetchRestaurant(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}`;
  return dispatch => getData(FETCH_RESTAURANT, RESTAURANT_ERROR, false, url, dispatch);
}


export function fetchPublishedOnlineOrderMenus(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eater/restaurants/${restaurantId}/menus?onlineOrdering=true&includeHiddenFromPublic=true`;
  return dispatch => getData(FETCH_PUBLISHED_MENUS, RESTAURANT_ERROR, false, url, dispatch);
}

export function updateRestaurantInfo(
  {
    _id,
    name,
    store_number,
    brief_description,
    full_description,
    address_1,
    _address2,
    city_town,
    state_province,
    postal_code,
    country,
    email,
    phone,
    fax,
    latitude,
    longitude
  },
  callback
) {
  let data = {
    name,
    store_number,
    brief_description,
    full_description,
    address_1,
    _address2,
    city_town,
    state_province,
    postal_code,
    country,
    email,
    phone,
    fax,
    latitude,
    longitude
  };

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${_id}/`;
  return dispatch =>
    patchData(
      UPDATE_RESTAURANT_INFO,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateBusinessHour(
  businessHourId,
  date,
  isOpen,
  openTime,
  closeTime,
  callback
) {
  let data = {
    date: date,
    is_open: isOpen,
    open_time: openTime,
    close_time: closeTime
  };
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/businesshours/${businessHourId}`;
  return dispatch =>
    patchData(
      UPDATE_BUSINESS_HOUR,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function addBusinessHour(
  restaurantId,
  date,
  isOpen,
  openTime,
  closeTime,
  callback
) {
  let data = {
    date: date,
    is_open: isOpen,
    open_time: openTime,
    close_time: closeTime
  };
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/businesshours`;
  return dispatch =>
    postData(
      ADD_BUSINESS_HOUR,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function deleteBusinessHour(businessHourId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/businesshours/${businessHourId}`;
  return dispatch =>
    deleteData(
      DELETE_BUSINESS_HOUR,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      callback
    );
}

export function updateSettings(restaurantId, data, callback) {

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/settings`;
  return dispatch =>
    patchData(
      UPDATE_SETTINGS,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateRegularBusinessHours(
  restaurantId,
  regular_business_hours,
  callback
) {
  let data = { regular_business_hours: regular_business_hours };
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/regular_business_hours`;
  return dispatch =>
    patchData(
      UPDATE_RESTAURANT_INFO,
      RESTAURANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function fetchHolidayBusinessHours(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/businesshours`;
  return dispatch =>
    getData(FETCH_BUSINESS_HOURS, RESTAURANT_ERROR, true, url, dispatch);
}

export function fetchPickupDates(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/pickupdates`;
  return dispatch => getData(FETCH_PICKUP_DATES, RESTAURANT_ERROR, false, url, dispatch);
}

export function fetchPromotions(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/promotions`;
  return dispatch => getData(FETCH_VALID_PROMOTIONS, RESTAURANT_ERROR, true, url, dispatch);
}