import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchGiftCardProducts, deleteGiftCardProduct } from "../../actions/giftcardproduct";

import Numeral from "numeral";

import $ from "jquery";

import * as Icon from "react-feather";
import AddGiftCardProduct from "./AddGiftCardProduct";
import EditGiftCardProduct from "./EditGiftCardProduct";

import * as MdIcons from "react-icons/md";

class GiftCardProducts extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleViewGiftCardProducts = this.handleViewGiftCardProducts.bind(this);

    this.state = {
      mode: "View",
      giftCardProduct: undefined
    };
  }

  componentDidMount() {
    if (this.props.tenant !== undefined) {
      this.props.fetchGiftCardProducts(this.props.tenant._id);
    }
  }

  handleAdd() {
    this.setState({ mode: "Add" });
  }

  handleDelete(giftCardProductId) {
    this.props.deleteGiftCardProduct(giftCardProductId, () => { });
  }

  handleEdit(giftCardProduct) {
    this.setState({ mode: "Edit", giftCardProduct: giftCardProduct });
  }

  handleViewGiftCardProducts() {
    this.setState({ mode: "View" });
  }

  componentDidUpdate() {
    $(".card").hover(
      function () {
        $(this)
          .addClass("shadow-lg")
          .css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow-lg");
      }
    );
  }

  arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }

  render() {
    const { tenant, restaurant, giftCardProducts } = this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    if (this.state.mode === "Add") {
      return <AddGiftCardProduct handleViewGiftCardProducts={this.handleViewGiftCardProducts} />;
    }
    if (this.state.mode === "Edit") {
      return (
        <EditGiftCardProduct
        handleViewGiftCardProducts={this.handleViewGiftCardProducts}
          giftCardProduct={this.state.giftCardProduct}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <MdIcons.MdOutlineCardGiftcard />
                {` `}
                <span>{`Gift Card Products`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <button className="btn btn-primary" onClick={this.handleAdd}>
              <Icon.Plus size={16} />
              {` `}New Gift Card Product
            </button>
          </div>
        </div>
        <div className="row">
          {giftCardProducts.map(giftCardProduct => (
            <div
              className="col-12 col-md-6 col-lg-4 pt-3 pb-3"
              key={giftCardProduct._id}
            >
              <div className="card h-100 mb-3">
                {giftCardProduct.image && giftCardProduct.image.url && (
                  <img
                    className="card-img-top img-fluid"
                    src={process.env.REACT_APP_S3_BASE_URL + `${encodeURIComponent(tenant.slug + '/' + giftCardProduct.image.url)}`}
                    alt="Giftcard"
                  />

                )}
                <div className="card-body text-center">
                  <h5 className="card-title">{giftCardProduct.name}</h5>
                  <p className="card-text">{giftCardProduct.description}</p>
                  <p className="card-text">
                    {Numeral(giftCardProduct.price).format("$0,0.00")}
                  </p>
                  <button
                    className="btn btn-outline-danger mr-3"
                    onClick={() => this.handleDelete(giftCardProduct._id)}
                  >
                    <Icon.Trash2 size={16} /> Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => this.handleEdit(giftCardProduct)}
                  >
                    <Icon.Edit2 size={16} />
                    {` `}
                    Edit
                  </button>
                  {/* <GiftCardEditModal giftcard={giftcard} /> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    giftCardProducts: state.giftCardProduct.giftCardProducts || []
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGiftCardProducts: tenantId => dispatch(fetchGiftCardProducts(tenantId)),
  deleteGiftCardProduct: (giftCardProductId, callback) => dispatch(deleteGiftCardProduct(giftCardProductId, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardProducts);
