import React, { Component } from "react";
import { connect } from "react-redux";

import CouponListView from "./CouponListView";
import * as RiIcons from "react-icons/ri";

import {
  fetchCoupons
} from "../../actions/coupon";
import AddCoupon from "./AddCoupon";

import moment from "moment-timezone";
import { socket } from '../../service/socket';

import DateTimePicker from "react-widgets/lib/DateTimePicker";
class Coupons extends Component {
  constructor(props) {
    super(props);
    ;
    this.handleShowRedeemed = this.handleShowRedeemed.bind(this);
    this.handleShowUnredeemed = this.handleShowUnredeemed.bind(this);
    this.handleShowExpired = this.handleShowExpired.bind(this);
    this.handleSelectPeriod = this.handleSelectPeriod.bind(this);

    this.handlePeriodFromChange = this.handlePeriodFromChange.bind(this);
    this.handlePeriodToChange = this.handlePeriodToChange.bind(this);

    this.state = {
      loading: false,
      showUnredeemed: true,
      showRedeemed: false,
      showExpired: false,
      period: "Today",
      from: undefined,
      to: undefined,
    };
  }

  getCoupons = (coupons) => {
    this.setState((prevState) => {
      return { loading: false, coupons: coupons };
    });
  };

  componentDidMount() {
    if (this.props.tenant) {
      var state_current = this;
      this.setState({
        loading: true,
        from: moment().tz(this.props.tenant.settings.time_zone)
          .startOf("day")
          .toDate(),
        to: moment().tz(this.props.tenant.settings.time_zone)
          .endOf("day")
          .toDate()
      }, () => {
        socket.emit("initial_coupons", this.props.tenant._id, {
          from: this.state.from,
          to: this.state.to
        });
        socket.on("get_coupons", state_current.getCoupons);

      })

    }
  }

  componentWillUnmount() {
    socket.off("get_coupons");
  }

  handlePeriodFromChange(value) {
    this.setState(
      {
        loading: true,
        from: moment(value)
          .tz(this.props.tenant.settings.time_zone)
          .startOf("day")
          .toDate(),
      },
      () => {
        socket.emit(
          "initial_coupons", this.props.tenant._id, 
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handlePeriodToChange(value) {
    this.setState(
      {
        loading: true,
        to: moment(value)
          .tz(this.props.tenant.settings.time_zone)
          .endOf("day")
          .toDate(),
      },
      () => {
        socket.emit(
          "initial_coupons", this.props.tenant._id, 
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handleSelectPeriod(period) {
    this.setState({
      loading: true
    });

    if (period === "Today") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Yesterday") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .subtract(1, "days")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .subtract(1, "days")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 7 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(7, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 14 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(14, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 30 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(30, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 90 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(90, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 12 months") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(12, 'months')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last week") {
      const startOfLastWeek = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("week")
        .subtract(1, 'weeks');
      const endOfLastWeek = moment(startOfLastWeek).endOf("week");
      this.setState(
        {
          period: period,
          from: startOfLastWeek
            .toDate(),
          to: endOfLastWeek
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last month") {
      const startOfLastMonth = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("month")
        .subtract(1, 'months');
      const endOfLastMonth = moment(startOfLastMonth).endOf("month");
      this.setState(
        {
          period: period,
          from: startOfLastMonth.toDate(),
          to: endOfLastMonth.toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last quarter") {
      const startOfLastQuarter = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("quarter")
        .subtract(1, 'quarters');
      const endOfLastQuarter = moment(startOfLastQuarter).endOf("quarter");
      this.setState(
        {
          period: period,
          from: startOfLastQuarter
            .toDate(),
          to: endOfLastQuarter
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last year") {
      const startOfLasYear = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("year")
        .subtract(1, 'years');
      const endOfLastYear = moment(startOfLasYear).endOf("year");
      this.setState(
        {
          period: period,
          from: startOfLasYear
            .toDate(),
          to: endOfLastYear
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Week to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("week")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Month to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("month")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Quarter to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("quarter")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Year to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("year")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_coupons", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
  }

  handleShowUnredeemed() {
    this.setState({
      showUnredeemed: true,
      showRedeemed: false,
      showExpired: false,
    });
  }

  handleShowRedeemed() {
    this.setState({
      showUnredeemed: false,
      showRedeemed: true,
      showExpired: false,
    });
  }

  handleShowExpired() {
    this.setState({
      showUnredeemed: false,
      showRedeemed: false,
      showExpired: true,
    });
  }

  render() {
    const { tenant } = this.props;

    if (tenant === undefined) {
      return <></>;
    }

    const today = moment().tz(tenant.settings.time_zone).startOf("day");
    const coupons = this.state.coupons || [];

    const unredeemedCoupons = coupons.filter(coupon => (!coupon.validUntil || moment(coupon.validUntil).tz(tenant.settings.time_zone).isAfter(today)) && coupon.redeemedBy === undefined);
    const redeemedCoupons = coupons.filter(coupon => coupon.redeemedBy !== undefined);
    const expiredCoupons = coupons.filter(coupon => coupon.validUntil && moment(coupon.validUntil).tz(tenant.settings.time_zone).isBefore(today));

    const selectedPeriod = {
      from: this.state.from,
      to: this.state.to
    };

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <RiIcons.RiCoupon2Line />
                {` `}
                <span>{`Coupons`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row border-bottom">
          <div className="col-12 text-right">
            <div className="d-flex justify-content-end mb-3">
              <div className="btn-toolbar">
              <DateTimePicker
              onChange={this.handlePeriodFromChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"Start Date"}
              value={this.state.from}
              time={false}
              date={true}
              className="mr-3"
            />
            <DateTimePicker
              onChange={this.handlePeriodToChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"End Date"}
              value={this.state.to}
              time={false}
              date={true}
              className="mr-3"
            />
                {/* <button className="btn btn-light mr-3" disabled><strong>{`${moment(this.state.from).tz(this.props.tenant.settings.time_zone).format(this.props.tenant.settings.date_format)} - ${moment(this.state.to).tz(this.props.tenant.settings.time_zone).format(this.props.tenant.settings.date_format)}`}</strong></button> */}
                <div className="dropdown">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-calendar-alt" />
                    {` `}
                    <span>{this.state.period}</span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Today")}
                    >{`Today`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Yesterday")}
                    >{`Yesterday`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Week to date")}
                    >{`Week to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 7 days")}
                    >{`Last 7 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last week")}
                    >{`Last week`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 14 days")}
                    >{`Last 14 days`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Month to date")}
                    >{`Month to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 30 days")}
                    >{`Last 30 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last month")}
                    >{`Last month`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Quarter to date")}
                    >{`Quarter to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 90 days")}
                    >{`Last 90 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last quarter")}
                    >{`Last quarter`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Year to date")}
                    >{`Year to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 12 months")}
                    >{`Last 12 months`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last year")}
                    >{`Last year`}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row border-bottom mb-3 py-3">
          <div className="col-6">
            <button
              type="button"
              className={`btn ${this.state.showUnredeemed ? " btn-primary" : "btn-outline-primary"
                }  mr-2`}
              onClick={this.handleShowUnredeemed}
            >
              <span className="d-inline">Unredeemed</span>
              {` `} {this.state.loading === false && <span className="badge badge-danger">{unredeemedCoupons.length}</span>}
            </button>
            <button
              type="button"
              className={`btn ${this.state.showRedeemed
                ? " btn-success"
                : "btn-outline-success"
                }  mr-2`}
              onClick={this.handleShowRedeemed}
            >
              <span>Redeemed</span>
              {` `}{" "}
              {this.state.loading === false && <span className="badge badge-warning">
                {redeemedCoupons.length}
              </span>}
            </button>
            <button
              type="button"
              className={`btn ${this.state.showExpired
                ? " btn-secondary"
                : "btn-outline-secondary"
                }  mr-2`}
              onClick={this.handleShowExpired}
            >
              <span>Expired</span>
              {` `}{" "}
              {this.state.loading === false && <span className="badge badge-warning">
                {expiredCoupons.length}
              </span>}
            </button>
          </div>
          <div className="col-6 text-right">
            <AddCoupon period={selectedPeriod} />
          </div>

        </div>
        <CouponListView
            showRedeemedColumn={!this.state.showUnredeemed && !this.state.showExpired}
            showExpireColumn={this.state.showExpired}
            coupons={this.state.showUnredeemed ? unredeemedCoupons : (this.state.showExpired ? expiredCoupons: redeemedCoupons)}
            tenant={tenant}
            loading={this.state.loading}
          />

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    coupons: state.coupon.coupons,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCoupons: (tenantId) => dispatch(fetchCoupons(tenantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupons);
