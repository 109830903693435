import {
  FETCH_GIFT_CARDS,
  FETCH_GIFT_CARD,
  FETCH_GIFT_CARD_REDEMPTIONS,
  GIFT_CARD_ERROR,
  REMOVE_GIFT_CARD_REDEMPTION,
  REDEEM_GIFT_CARD
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  giftCards: [],
  giftCardRedemptions: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GIFT_CARDS:
      return { ...state, giftCards: action.payload.giftCards };
    case FETCH_GIFT_CARD:
      return {
        ...state,
        giftCard: { ...action.payload.giftCard },
        giftCardRedemptions: [ ...action.payload.giftCardRedemptions]
      };
    case FETCH_GIFT_CARD_REDEMPTIONS:
      return {
        ...state,
        giftCardRedemptions: action.payload.giftCardRedemptions,
      };
      case REMOVE_GIFT_CARD_REDEMPTION:
      return {
        ...state,
        giftCardRedemptions: state.giftCardRedemptions.filter(giftCardRedemption => giftCardRedemption._id !== action.payload.giftCardRedemptionId),
        giftCard: action.payload.giftCard
      };
      case REDEEM_GIFT_CARD:
        return {
          ...state,
          giftCardRedemptions: [...state.giftCardRedemptions, action.payload.giftCardRedemption],
          giftCard: action.payload.giftCard
        };
    case GIFT_CARD_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
