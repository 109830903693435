import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import {
  renderField,
} from "../../utils/renderFields";

import { updateCarousel } from "../../actions/tenant";

import CarouselPhotoPicker from "./CarouselPhotoPicker";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "carouselEdit",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class EditCarousel extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateCarousel(
      this.props.tenant._id,
      this.props.carousel_item._id,
      formProps,
      this.props.photoUrl,
      () => {
        this.props.handleViewCarouselSettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }


  render() {
    const {
      handleViewCarouselSettings,
      handleSubmit,
      tenant,
      pristine,
      submitting,
      photoUrl
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => window.location.href = "/settings"}
              >
                {`Settings`}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" onClick={handleViewCarouselSettings}>
                {`Carousel`}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                {`Edit`}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}

          <div className="form-row pb-3">
            <div className="col-3 text-right">
              {`Sequence`}
            </div>
            <div className="col-9">
              <Field
                name={`sequence`}
                className="form-control"
                component={renderField}
                placeholder="Sequence"
                type="number"
              />
            </div>
          </div>
          <div className="form-row pb-3">
            <div className="col-3 text-right">
              {`Caption`}
            </div>
            <div className="col-9">
              <Field
                name={`caption`}
                className="form-control"
                component={renderField}
                placeholder="Caption"
                type="text"
              />
            </div>
          </div>
          <div className="form-row pb-3">
            <div className="col-3 text-right">
              {`Description`}
            </div>
            <div className="col-9">
              <Field
                name={`description`}
                className="form-control"
                placeholder="Description"
                component="textarea"
              />
            </div>
          </div>
          <div className="form-row pb-3">
            <div className="col-3 text-right">
              {`Image`}
            </div>
            <div className="col-9">
              {photoUrl && (
                <img
                  className="img-fluid"
                  src={
                    process.env.REACT_APP_S3_BASE_URL +
                    `${tenant.slug +
                    "/" +
                    photoUrl.replace(".jpg", "") +
                    ".jpg"
                    }`
                  }
                  alt="Carousel"
                />
              )}
              {!photoUrl && <CarouselPhotoPicker tenant={tenant} />}
              <p className="text-muted pt-3">Image will be automaticaly resized to 1900 x 646</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewCarouselSettings}
              >
                {`Cancel`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </div>
          </div>

        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    initialValues: ownProps.carousel_item,
    photoUrl: ownProps.carousel_item.image.url || state.tenant.carouselPhotoUrl
  };
};

const mapDispatchToProps = dispatch => ({
  updateCarousel: (tenantId, carouselItemId, formProps, photoUrl, callback) =>
    dispatch(updateCarousel(tenantId, carouselItemId, formProps, photoUrl, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(EditCarousel));
