import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";


import {
  moveMenuItem,
  fetchMenuItem,
} from "../../actions/menu";

const form = reduxForm({
  form: "moveMenuItem",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.sectionId) {
    errors.sectionId = "Please enter menu section";
  }

  return errors;
}

class MoveMenuItem extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }


  componentDidMount() {
    if (
      this.props.menu !== undefined &&
      this.props.menuSection !== undefined &&
      this.props.menuItem !== undefined
    ) {
      this.props.fetchMenuItem(
        this.props.menu._id,
        this.props.menuSection._id,
        this.props.menuItem._id
      );
    }
  }

  handleFormSubmit(formProps) {
    this.props.moveMenuItem(
      this.props.menu._id,
      this.props.menuSection._id,
      this.props.menuItem._id,
      formProps,
      () => {
        this.props.handleViewMenuSection(this.props.menuSection);
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      menu,
      menuSection,
      menuItem,
      handleSubmit,
      pristine,
      submitting,
      handleViewMenu,
      handleViewMenus,
      handleViewMenuSection,
      handleViewMenuItem
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button type="button" className="btn btn-light" onClick={() => handleViewMenuSection(menuSection)}>
                {menuSection.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button type="button" className="btn btn-light" onClick={() => handleViewMenuItem(menuItem)}>
                {menuItem ? menuItem.name : ''}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {'Move'}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="col-form-label">Move to </label>
                  {menu.sections.filter(section => section.disabled === false && section._id !== menuSection._id).map(section => (
                    <div className="form-check" key={section._id}>
                      <Field
                        name="sectionId"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value={section._id}
                      />
                      <label className="form-check-label">{section.name}</label>
                    </div>
                  )
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={() => handleViewMenuItem(menuItem)}
            >
              Cancel
          </button>
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              {"Save"}
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let menu_item = state.menu.menuItem ? state.menu.menuItem : ownProps.menuItem


  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    menuItem: menu_item,
    initialValues: {
      menuSectionId: menu_item.section,

    }
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  moveMenuItem: (menuId, menuSectionId, menuItemId, formProps, callback) =>
    dispatch(moveMenuItem(menuId, menuSectionId, menuItemId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MoveMenuItem));
