import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";
import * as Icon from "react-feather";

import {sendEmail } from "../../actions/order";


const form = reduxForm({
  form: "sendEmail",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.message) {
    errors.message = "Please enter message";
  }

  return errors;
}

export class SendEmail extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      message: undefined,
      email: props.order.email,
      subject: 'Re. Order #' + props.order.orderNumber
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {

    const data = {
      sender: this.props.tenant.email,
      recipient: this.state.email,
      bcc: this.props.tenant.email,
      subject: this.state.subject,
      message: formProps.message
    }
    this.props.sendEmail(data, () => {
      $(".close").click();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-info mr-2"
          data-toggle="modal"
          data-target="#sendEmailModal"
          data-backdrop="static"
        >
          <Icon.Mail size={16} className="d-inline mr-1" />
          <span className="d-none d-lg-inline">Email</span>
        </button>

        <div
          className="modal fade"
          id="sendEmailModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#sendEmailModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="sendEmailModalLabel">
                    {`Send Email`}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div>
                    <label>Email</label>
                    <div>
                      <p>{this.state.email}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">
                      Message
                    </label>
                    <Field
                      name="message"
                      className="form-control"
                      component="textarea"
                    />
                  </div>
                </div>

                <div className="modal-footer d-block text-center">
                  <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={pristine || submitting}
                  >
                    <Icon.Send size={16} /> {` `}
                    {`Send`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message, 
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  sendEmail: (formProps, callback) =>
    dispatch(sendEmail(formProps,  callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(SendEmail));
