import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import * as MdIcons from "react-icons/md";

import { startOrder, completeOrder } from "../../actions/order";

import { socket } from "../../service/socket";
import KitchenOrder from "./KitchenOrder";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class KitchenOrders extends Component {
  constructor(props) {
    super(props);

    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewOrders = this.handleViewOrders.bind(this);

    this.handleStartOrder = this.handleStartOrder.bind(this);
    this.handleCompleteOrder = this.handleCompleteOrder.bind(this);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);

    this.state = {
      from: undefined,
      to: undefined,
      selectedRestaurantId: undefined,
    };
  }

  getOrders = (orders) => {
    this.setState((prevState) => {
      return { orders: orders };
    });
  };

  componentDidMount() {
    if (this.props.tenant && this.props.applicableRestaurants.length > 0) {
      const periodFrom = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("day")
        .toDate();
      const periodTo = moment()
        .tz(this.props.tenant.settings.time_zone)
        .endOf("day")
        .toDate();

      var state_current = this;
      this.setState(
        {
          loading: true,
          from: periodFrom,
          to: periodTo,
          selectedRestaurantId: this.props.applicableRestaurants[0].key,
        },
        () => {
          socket.emit("join", this.state.selectedRestaurantId);
          socket.emit("initial_orders", this.state.selectedRestaurantId, {
            from: periodFrom,
            to: periodTo,
          });
          socket.on("get_orders", state_current.getOrders);
        }
      );
    }
  }

  componentWillUnmount() {
    socket.off("get_orders");
  }

  handleViewDetail(order) {
    this.setState({ selectedOrder: order });
  }

  handleViewOrders() {
    this.setState({ selectedOrder: undefined });
  }

  handleStartOrder(order) {
    this.props.startOrder(this.props.tenant._id, order._id, (data) => {
      socket.emit("start order", this.state.selectedRestaurantId, data.order, {from: this.state.from, to: this.state.to});
    });
  }

  handleCompleteOrder(order) {
    this.props.completeOrder(this.props.tenant._id, order._id, (data) => {
      socket.emit("complete order", this.state.selectedRestaurantId, data.order, {from: this.state.from, to: this.state.to});
    });
  }

  getPickupTime = (order, restaurant) => {
    return order.adjustedPickupTime
      ? moment(order.adjustedPickupTime).tz(restaurant.settings.time_zone)
      : moment(order.pickupTime).tz(restaurant.settings.time_zone);
  };

  handleSelectRestaurant(restaurantId) {
    this.setState(
      {
        selectedRestaurantId: restaurantId,
      },
      () => {
        socket.emit("join", this.state.selectedRestaurantId);
        socket.emit("initial_orders", this.state.selectedRestaurantId, {
          from: this.state.from,
          to: this.state.to,
        });
        socket.on("get_orders", this.getOrders);
      }
    );
  }

  render() {
    const { tenant } = this.props;
    const allOrders = this.state.orders || [];

    if (tenant === undefined || !this.state.selectedRestaurantId) {
      return <></>;
    }

    const selectedRestaurant = tenant.restaurants.find(ar => ar._id === this.state.selectedRestaurantId);
    
    const applicableOrderStatuses = selectedRestaurant.settings.kitchen_order_settings.applicable_order_statuses || [];

    const inProgressOrders = allOrders
      .filter(
        (order) => applicableOrderStatuses.includes(order.status)
      )
      .sort((a, b) =>
        this.getPickupTime(a, selectedRestaurant).isAfter(
          this.getPickupTime(b, selectedRestaurant)
        )
          ? 1
          : -1
      );
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <div className="dropdown">
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownRestaurantButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <MdIcons.MdLocationPin />
                  {` `}
                  <span>
                    {
                      this.props.applicableRestaurants.find(
                        (ar) => ar.key === this.state.selectedRestaurantId
                      ).label
                    }
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownRestaurantButton"
                >
                  {this.props.applicableRestaurants.map((ar) => (
                    <button
                      key={ar.key}
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectRestaurant(ar.key)}
                    >
                      {ar.label}
                    </button>
                  ))}
                </div>
              </div>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <MdIcons.MdOutlineReceipt />
                {` `}
                <span>{`Kitchen Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="row">
          {inProgressOrders.map((order) => (
            <div className="col-md-6 col-lg-4 col-xl-3" key={order._id}>
              <KitchenOrder
                order={order}
                restaurant={selectedRestaurant}
                onHandleStartOrder={() => this.handleStartOrder(order)}
                onHandleCompleteOrder={() => this.handleCompleteOrder(order)}
              />
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const user = cookies.get("user");
  const applicableRestaurants = (
    user.role === "Owner"
      ? state.tenant.restaurants || []
      : (state.tenant.restaurants || []).filter((_restaurant) =>
          user.restaurants.includes(_restaurant._id)
        )
  ).map((_restaurant) => {
    return { key: _restaurant._id, label: _restaurant.name };
  });
  return {
    tenant: state.tenant.tenant,
    order: state.order.order,
    applicableRestaurants: applicableRestaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startOrder: (tenantId, orderId, callback) =>
    dispatch(startOrder(tenantId, orderId, callback)),
  completeOrder: (tenantId, orderId, callback) =>
    dispatch(completeOrder(tenantId, orderId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenOrders);
