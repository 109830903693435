import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_PROMOTION_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CLEAR_CART,
  VALIDATE_AND_APPLY_PROMOTIONS,
  UPDATE_CART_ITEM_SPECIAL_INSTRUCTIONS,
} from "./types";

import Numeral from "numeral";

const uuidv4 = require("uuid/v4");

export function addToCart(
  id,
  cd,
  name,
  printName,
  size,
  options,
  quantity,
  basePrice,
  baseTotal,
  extraPrice,
  extraTotal,
  specialInstructions,
  duration,
  isCouponEnabled,
  promotions,
  timeZone,
  timeFormat,
  deliveryMethod,
  pickupTime,
  pickupDate,
  deliveryDistance,
  postalCode,
  deliveryFee,
  deliveryGST,
  restaurantId,
  callback
) {
  const data = {
    id,
    cd,
    name,
    printName,
    size,
    options,
    quantity,
    basePrice,
    baseTotal,
    extraPrice,
    extraTotal,
    specialInstructions,
    uuid: uuidv4(),
  };

  const promoData = {
    isCouponEnabled,
    promotionRules: promotions,
    timezone: timeZone,
    timeFormat,
    deliveryMethod,
    pickupTime,
    pickupDate,
    deliveryDistance,
    postalCode,
    deliveryFee,
    deliveryGST,
    restaurantId,
  };

  callback();
  return {
    type: ADD_TO_CART,
    payload: { data, promoData },
  };
}

export function removeFromCart(item, promoData) {
  return {
    type: REMOVE_FROM_CART,
    payload: { uuid: item.uuid, promoData },
  };
}

export function removePromotionFromCart(promo, promoData) {
  return {
    type: REMOVE_PROMOTION_FROM_CART,
    payload: { uuid: promo.uuid, promoData },
  };
}

export function increaseQuantity(item, promoData) {
  return {
    type: INCREASE_QUANTITY,
    payload: { item, promoData },
  };
}

export function decreaseQuantity(item, promoData) {
  return {
    type: DECREASE_QUANTITY,
    payload: { item, promoData },
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
    payload: {},
  };
}

export function getSubtotal(state) {
  let subtotal1 = state.cart.items.reduce((acc, item) => {
    return acc + item.baseTotal + item.extraTotal;
  }, 0);

  let subtotal2 = state.cart.promotions.reduce((acc, promo) => {
    return acc + promo.amount;
  }, 0);

  return Numeral(Numeral(subtotal1 + subtotal2).format("$0,0.00")).value();
}

export function getDeliveryFee(state, deliveryMethod) {
  const deliveryFee =
    deliveryMethod === "Delivery"
      ? state.tenant.restaurant.settings.delivery_options.delivery_fee
        ? state.tenant.restaurant.settings.delivery_options.delivery_fee
        : 0
      : 0;
  return Numeral(Numeral(deliveryFee).format("$0,0.00")).value();
}

export function getDeliveryGST(state, deliveryMethod, gstRate) {
  const deliveryGST = getDeliveryFee(state, deliveryMethod) * (gstRate / 100);
  return Numeral(Numeral(deliveryGST).format("$0,0.00")).value();
}

export function getTax(state, gstRate, pstRate) {
  const tax = getGST(state, gstRate) + getPST(state, pstRate);
  return Numeral(Numeral(tax).format("$0,0.00")).value();
}

export function getGST(state, gstRate) {
  const gst = getSubtotal(state) * (gstRate / 100);
  return Numeral(Numeral(gst).format("$0,0.00")).value();
}

export function getPST(state, pstRate) {
  const pst = getSubtotal(state) * (pstRate / 100);
  return Numeral(Numeral(pst).format("$0,0.00")).value();
}

export function getDiscountTotal(state) {
  const subtotal2 = (state.cart.discounts || [])
  .filter((discount) => discount.is_next_order_coupon === false)
  .reduce((acc, discount) => {
    return acc + discount.discount_amount;
  }, 0);
  return subtotal2;
}

export function getCouponTotal(state) {
  const subtotal3 = (state.cart.coupons || []).reduce((acc, coupon) => {
    return acc + coupon.amount;
  }, 0);
  return subtotal3;
}

export function getRedemptionTotal(state) {
  const amountToRedeem = (state.cart.giftCardRedemptions || []).reduce(
    (acc, redemption) => {
      return acc + redemption.amountToRedeem;
    },
    0
  );
  return amountToRedeem;
}

export function getTotal(state, deliveryMethod, gstRate, pstRate) {
  const subtotal2 = (state.cart.discounts || [])
    .filter((discount) => discount.is_next_order_coupon === false)
    .reduce((acc, discount) => {
      return acc + discount.discount_amount;
    }, 0);

  const subtotal3 = (state.cart.coupons || []).reduce((acc, coupon) => {
    return acc + coupon.amount;
  }, 0);

  const amountToRedeem = (state.cart.giftCardRedemptions || []).reduce(
    (acc, redemption) => {
      return acc + redemption.amountToRedeem;
    },
    0
  );
  const total =
    getSubtotal(state) +
    getGST(state, gstRate) +
    getPST(state, pstRate) +
    (deliveryMethod === "Delivery" ? getDeliveryFee(state, deliveryMethod) : 0) +
    (deliveryMethod === "Delivery" ? getDeliveryGST(state, deliveryMethod, gstRate) : 0) -
    subtotal2 -
    subtotal3 -
    amountToRedeem;
  return Numeral(Numeral(total).format("$0,0.00")).value();
}

export function getQuantity(state) {
  return (
    (state.cart.items ? state.cart.items.length : 0) +
    (state.cart.promotions ? state.cart.promotions.length : 0)
  );
}

export function applyPromotions(
  promotions,
  restaurantTimezone,
  timeFormat,
  deliveryMethod,
  pickupTime,
  pickupDate,
  couponAmount,
  deliveryDistance,
  deliveryPostalCode,
  deliveryFee,
  deliveryGST,
  restaurantId
) {
  const promoData = {
    promotionRules: promotions,
    timezone: restaurantTimezone,
    timeFormat: timeFormat,
    deliveryMethod: deliveryMethod,
    pickupTime: pickupTime,
    pickupDate: pickupDate,
    couponAmount,
    deliveryDistance: deliveryDistance,
    postalCode: deliveryPostalCode,
    deliveryFee: deliveryFee,
    deliveryGST: deliveryGST,
    restaurantId: restaurantId
  };

  return {
    type: VALIDATE_AND_APPLY_PROMOTIONS,
    payload: {
      promoData
    },
  };
}

export function updateCartItemSpecialInstructions(item, instructions) {
  return {
    type: UPDATE_CART_ITEM_SPECIAL_INSTRUCTIONS,
    payload: { item, instructions },
  };
}
