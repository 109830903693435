import React from "react";

import moment from "moment-timezone";


const ReservationInfoCard = ({ restaurant, reservation }) => {

  return (
    <div className="card">
      <div className="card-body">
        <div className="row  pb-3 border-bottom">
          <div className="col-12 col-sm-6 col-lg-3 pb-1">Reservation #: {reservation.reservationNumber}</div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">Status: {reservation.status}</div>
          <div className="col-12 col-lg-6 pb-1">
            <span>
              Received:{" "}
              {moment(reservation.receivedAt).tz(restaurant.settings.time_zone).format(
                restaurant.settings.datetime_format
              )}
            </span>
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-4">Party Size</div>
          <div className="col-8">{reservation.partySize}</div>
        </div>
        <div className="row pb-3">
          <div className="col-4">Reserved Date</div>
          <div className="col-8">
            {moment(reservation.reservedDate).tz(restaurant.settings.time_zone).format(
                restaurant.settings.date_format
              )}
          </div>
        </div>
        <div className="row  pb-3">
          <div className="col-4">Reserved Time</div>
          <div className="col-8">
            {moment(reservation.reservedTime).tz(restaurant.settings.time_zone).format(
                restaurant.settings.time_format
              )}
          </div>
        </div>
        <div className="row  pb-3">
          <div className="col-4">Name Reserved Under</div>
          <div className="col-8">{reservation.nameReservedUnder}</div>
        </div>
        <div className="row  pb-3">
          <div className="col-4">Email</div>
          <div className="col-8">{reservation.email}</div>
        </div>
        <div className="row  pb-3">
          <div className="col-4">Phone</div>
          <div className="col-8">{reservation.phone}</div>
        </div>
      </div>
    </div>
  );
};

export default ReservationInfoCard;
