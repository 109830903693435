import {
  CHANGE_PICKUP_LOCATION,
  CHANGE_PICKUP_TIME,
  CHANGE_PICKUP_PERSON,
  CHANGE_CUSTOMER,
  CANCEL_ORDER,
  LOGIN_TO_PROCEED,
  CHANGE_SPECIAL_REQUEST,
  SAVE_ORDER,
  FETCH_ORDER,
  FETCH_ORDERS,
  ACCEPT_ORDER,
  REJECT_ORDER,
  FULFILL_ORDER,
  START_ORDER,
  COMPLETE_ORDER,
  CHANGE_ORDER_TYPE,
  ORDER_ERROR,
  FETCH_CALL_IN_ORDERS,
  UPDATE_ORDER,
  UPDATE_DELIVERY_INFO,
  APPLY_COUPON_TO_ORDER,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  orders: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_ORDER_TYPE:
      return { ...state, orderType: action.payload };
    case CHANGE_SPECIAL_REQUEST:
      return { ...state, specialRequests: action.payload };
    case CHANGE_PICKUP_LOCATION:
      return { ...state, pickupLocation: action.payload };
    case CHANGE_PICKUP_TIME:
      return {
        ...state,
        pickupDate: action.payload.pickupDate,
        pickupTime: action.payload.pickupTime,
      };
    case CHANGE_PICKUP_PERSON:
      return { ...state, pickupPerson: action.payload.personName };
    case CHANGE_CUSTOMER:
      return { ...state, customer: action.payload };
    case CANCEL_ORDER:
      return {
        ...state,
        pickupLocation: null,
        pickupDate: null,
        pickupTime: null,
        pickupPerson: null,
        customer: null,
        checkoutStep: 1,
        specialRequests: null,
        paymentMethod: null,
      };
    case LOGIN_TO_PROCEED:
      return {
        ...state,
        checkoutStep: action.payload.step,
      };
    case SAVE_ORDER:
      return {
        ...state,
        // callInOrders: [action.payload.order, ...state.callInOrders],
        pickupLocation: null,
        pickupDate: null,
        pickupTime: null,
        pickupPerson: null,
        customer: null,
        checkoutStep: 1,
        specialRequests: null,
        paymentMethod: null,
      };
    case FETCH_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case FETCH_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
        errorMessage: undefined,
      };
    case FETCH_CALL_IN_ORDERS:
      return {
        ...state,
        callInOrders: action.payload.orders,
      };
    case ACCEPT_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case REJECT_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case UPDATE_DELIVERY_INFO:
      return {
        ...state,
        order: action.payload.order,
      };
    case START_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case COMPLETE_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case FULFILL_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case APPLY_COUPON_TO_ORDER:
      return {
        ...state,
        order: action.payload.order,
        errorMessage: undefined,
      };
    case ORDER_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
