import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import { updateSettings } from "../../actions/restaurant";
import Switch from "react-switch";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "printingSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class PrintingSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handlePrinterIpAddressChange =
      this.handlePrinterIpAddressChange.bind(this);
    this.handlePrinterPortNumberChange =
      this.handlePrinterPortNumberChange.bind(this);
    this.handlePrinterIdChange = this.handlePrinterIdChange.bind(this);

    this.handlePrintOnlinePickupKitchenReceiptChange =
      this.handlePrintOnlinePickupKitchenReceiptChange.bind(this);
    this.handlePrintOnlinePickupCustomerReceiptChange =
      this.handlePrintOnlinePickupCustomerReceiptChange.bind(this);
    this.handlePrintCallInPickupKitchenReceiptChange =
      this.handlePrintCallInPickupKitchenReceiptChange.bind(this);
    this.handlePrintCallInPickupCustomerReceiptChange =
      this.handlePrintCallInPickupCustomerReceiptChange.bind(this);
    this.handlePrintOnlineDeliveryKitchenReceiptChange =
      this.handlePrintOnlineDeliveryKitchenReceiptChange.bind(this);
    this.handlePrintOnlineDeliveryCustomerReceiptChange =
      this.handlePrintOnlineDeliveryCustomerReceiptChange.bind(this);
    this.handlePrintCallInDeliveryKitchenReceiptChange =
      this.handlePrintCallInDeliveryKitchenReceiptChange.bind(this);
    this.handlePrintCallInDeliveryCustomerReceiptChange =
      this.handlePrintCallInDeliveryCustomerReceiptChange.bind(this);

    this.state = {
      restaurantId: props.restaurant._id,
      enable_printing:
        props.restaurant.settings.printing_settings.enable_printing,
      printer_ip_address:
        props.restaurant.settings.printing_settings.printer_ip_address,
      printer_port_number:
        props.restaurant.settings.printing_settings.printer_port_number,
      printer_id: props.restaurant.settings.printing_settings.printer_id,
      print_order_after_accept:
        props.restaurant.settings.printing_settings.print_order_after_accept,
      print_call_in_order_after_save:
        props.restaurant.settings.printing_settings
          .print_call_in_order_after_save,
      print_kitchen_receipt_after_accept_pickup_online_order:
        props.restaurant.settings.printing_settings
          .print_kitchen_receipt_after_accept_pickup_online_order,
      print_customer_receipt_after_accept_pickup_online_order:
        props.restaurant.settings.printing_settings
          .print_customer_receipt_after_accept_pickup_online_order,
      print_kitchen_receipt_after_accept_delivery_online_order:
        props.restaurant.settings.printing_settings
          .print_kitchen_receipt_after_accept_delivery_online_order,
      print_customer_receipt_after_accept_delivery_online_order:
        props.restaurant.settings.printing_settings
          .print_customer_receipt_after_accept_delivery_online_order,
      print_kitchen_receipt_after_accept_pickup_call_in_order:
        props.restaurant.settings.printing_settings
          .print_kitchen_receipt_after_accept_pickup_call_in_order,
      print_customer_receipt_after_accept_pickup_call_in_order:
        props.restaurant.settings.printing_settings
          .print_customer_receipt_after_accept_pickup_call_in_order,
      print_kitchen_receipt_after_accept_delivery_call_in_order:
        props.restaurant.settings.printing_settings
          .print_kitchen_receipt_after_accept_delivery_call_in_order,
      print_customer_receipt_after_accept_delivery_call_in_order:
        props.restaurant.settings.printing_settings
          .print_customer_receipt_after_accept_delivery_call_in_order,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        enable_printing: this.state.enable_printing,
        printer_ip_address: this.state.printer_ip_address,
        printer_port_number: this.state.printer_port_number,
        printer_id: this.state.printer_id,
        print_order_after_accept: this.state.print_order_after_accept,
        print_call_in_order_after_save:
          this.state.print_call_in_order_after_save,
        print_kitchen_receipt_after_accept_pickup_online_order:
          this.state.print_kitchen_receipt_after_accept_pickup_online_order,
        print_customer_receipt_after_accept_pickup_online_order:
          this.state.print_customer_receipt_after_accept_pickup_online_order,
        print_kitchen_receipt_after_accept_delivery_online_order:
          this.state.print_kitchen_receipt_after_accept_delivery_online_order,
        print_customer_receipt_after_accept_delivery_online_order:
          this.state.print_customer_receipt_after_accept_delivery_online_order,
        print_kitchen_receipt_after_accept_pickup_call_in_order:
          this.state.print_kitchen_receipt_after_accept_pickup_call_in_order,
        print_customer_receipt_after_accept_pickup_call_in_order:
          this.state.print_customer_receipt_after_accept_pickup_call_in_order,
        print_kitchen_receipt_after_accept_delivery_call_in_order:
          this.state.print_kitchen_receipt_after_accept_delivery_call_in_order,
        print_customer_receipt_after_accept_delivery_call_in_order:
          this.state.print_customer_receipt_after_accept_delivery_call_in_order,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handlePrinterIpAddressChange(event) {
    this.setState({ printer_ip_address: event.target.value });
  }

  handlePrinterPortNumberChange(event) {
    this.setState({ printer_port_number: event.target.value });
  }

  handlePrinterIdChange(event) {
    this.setState({ printer_id: event.target.value });
  }

  handlePrintOnlinePickupKitchenReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_kitchen_receipt_after_accept_pickup_online_order: value,
    });
  }

  handlePrintOnlinePickupCustomerReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_customer_receipt_after_accept_pickup_online_order: value,
    });
  }

  handlePrintOnlineDeliveryKitchenReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_kitchen_receipt_after_accept_delivery_online_order: value,
    });
  }

  handlePrintOnlineDeliveryCustomerReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_customer_receipt_after_accept_delivery_online_order: value,
    });
  }

  handlePrintCallInPickupKitchenReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_kitchen_receipt_after_accept_pickup_call_in_order: value,
    });
  }

  handlePrintCallInPickupCustomerReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_customer_receipt_after_accept_pickup_call_in_order: value,
    });
  }

  handlePrintCallInDeliveryKitchenReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_kitchen_receipt_after_accept_delivery_call_in_order: value,
    });
  }

  handlePrintCallInDeliveryCustomerReceiptChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      print_customer_receipt_after_accept_delivery_call_in_order: value,
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } =
      this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-print" />
                {` `}
                <span className="d-none d-sm-inline">{`Printing Settings`}</span>
              </button>
            </li>
          </ol>
        </nav>

        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Restaurant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.restaurant.name}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Enable Printing
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { enable_printing: !prevState.enable_printing };
                });
              }}
              checked={this.state.enable_printing || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Printer IP Address
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="printer_ip_address"
                value={this.state.printer_ip_address}
                onChange={this.handlePrinterIpAddressChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Printer Port Number
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="printer_port_number"
                value={this.state.printer_port_number}
                onChange={this.handlePrinterPortNumberChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Printer Id
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="printer_id"
              value={this.state.printer_id}
              onChange={this.handlePrinterIdChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Print Online Order After Accept
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    print_order_after_accept:
                      !prevState.print_order_after_accept,
                  };
                });
              }}
              checked={this.state.print_order_after_accept || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_kitchen_receipt_after_accept_pickup_online_order"
                className="form-check-input"
                disabled={this.state.print_order_after_accept === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_kitchen_receipt_after_accept_pickup_online_order ===
                  true
                }
                onChange={this.handlePrintOnlinePickupKitchenReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Kitchen Receipt for Online Pickup Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_customer_receipt_after_accept_pickup_online_order"
                className="form-check-input"
                disabled={this.state.print_order_after_accept === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_customer_receipt_after_accept_pickup_online_order ===
                  true
                }
                onChange={this.handlePrintOnlinePickupCustomerReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Customer Receipt for Online Pickup Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_kitchen_receipt_after_accept_delivery_online_order"
                className="form-check-input"
                disabled={this.state.print_order_after_accept === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_kitchen_receipt_after_accept_delivery_online_order ===
                  true
                }
                onChange={this.handlePrintOnlineDeliveryKitchenReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Kitchen Receipt for Online Delivery Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_customer_receipt_after_accept_delivery_online_order"
                className="form-check-input"
                disabled={this.state.print_order_after_accept === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_customer_receipt_after_accept_delivery_online_order ===
                  true
                }
                onChange={this.handlePrintOnlineDeliveryCustomerReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Customer Receipt for Online Delivery Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Print Call-In Order After Save
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    print_call_in_order_after_save:
                      !prevState.print_call_in_order_after_save,
                  };
                });
              }}
              checked={this.state.print_call_in_order_after_save || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_kitchen_receipt_after_accept_pickup_call_in_order"
                className="form-check-input"
                disabled={this.state.print_call_in_order_after_save === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_kitchen_receipt_after_accept_pickup_call_in_order ===
                  true
                }
                onChange={this.handlePrintCallInPickupKitchenReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Kitchen Receipt for CallIn Pickup Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_customer_receipt_after_accept_pickup_call_in_order"
                className="form-check-input"
                disabled={this.state.print_call_in_order_after_save === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_customer_receipt_after_accept_pickup_call_in_order ===
                  true
                }
                onChange={this.handlePrintCallInPickupCustomerReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Customer Receipt for CallIn Pickup Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_kitchen_receipt_after_accept_delivery_call_in_order"
                className="form-check-input"
                disabled={this.state.print_call_in_order_after_save === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_kitchen_receipt_after_accept_delivery_call_in_order ===
                  true
                }
                onChange={this.handlePrintCallInDeliveryKitchenReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Kitchen Receipt for CallIn Delivery Order
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">{}</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="form-check">
              <input
                name="print_customer_receipt_after_accept_delivery_call_in_order"
                className="form-check-input"
                disabled={this.state.print_call_in_order_after_save === false}
                type="checkbox"
                defaultChecked={
                  this.state
                    .print_customer_receipt_after_accept_delivery_call_in_order ===
                  true
                }
                onChange={this.handlePrintCallInDeliveryCustomerReceiptChange}
              />
              <label className="form-check-label">
                Automatically Print Customer Receipt for CallIn Delivery Order
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              // disabled={pristine || submitting}
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, formProps, callback) =>
    dispatch(updateSettings(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(PrintingSettings));
