import {
  FETCH_GIFT_CARD_ORDER,
  FETCH_GIFT_CARD_ORDERS,
  FULFILL_GIFT_CARD_ORDER,
  PAY_GIFT_CARD_ORDER,
  GIFT_CARD_ORDER_ERROR,
  REJECT_GIFT_CARD_ORDER,
  GENERATE_GIFT_CARD_NUMBERS,
  UPDATE_GIFT_CARD_ORDER
} from "./types";

import { getData, patchData } from "./index";

export function fetchGiftCardOrder(tenantId, giftCardOrderId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}`;
  return dispatch => getData(FETCH_GIFT_CARD_ORDER, GIFT_CARD_ORDER_ERROR, true, url, dispatch);
}

export function fetchGiftCardOrders(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders`;
  return dispatch => getData(FETCH_GIFT_CARD_ORDERS, GIFT_CARD_ORDER_ERROR, true, url, dispatch);
}

export function searchGiftCardOrders(tenantId, orderNumber, phoneNumber, periodFrom, periodTo) {
  const queryParams = [];
  if (orderNumber) {
    queryParams.push(`orderNumber=${orderNumber}`);
  }
  if (phoneNumber) {
    queryParams.push(`phoneNumber=${phoneNumber}`);
  }
  if (periodFrom) {
    queryParams.push(`periodFrom=${periodFrom}`);
  }
  if (periodTo) {
    queryParams.push(`periodTo=${periodTo}`);
  }
  const queryString = queryParams.join("&");
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders` + ((queryParams.length > 0) ? `?${queryString}` : "");
  return dispatch => getData(FETCH_GIFT_CARD_ORDERS, GIFT_CARD_ORDER_ERROR, true, url, dispatch);
}

export function takePayment(tenantId, giftCardOrderId, paymentReceiptNumber, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}/takepayment`;
  const data = {
    offlinePaymentReceiptNumber: paymentReceiptNumber
  }
  return dispatch => patchData(PAY_GIFT_CARD_ORDER, GIFT_CARD_ORDER_ERROR, true, url, dispatch, data, callback);
}

export function issueCards(tenantId, giftCardOrderId, giftCardNumbers, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}/issuecards`;
  const data = {
    giftCardNumbers: giftCardNumbers
  }
  return dispatch => patchData(FULFILL_GIFT_CARD_ORDER, GIFT_CARD_ORDER_ERROR, true, url, dispatch, data, callback);
}

export function rejectOrder(tenantId, giftCardOrderId, rejectReason, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}/reject`;
  const data = {
    rejectReason: rejectReason
  }

  return dispatch => patchData(REJECT_GIFT_CARD_ORDER, GIFT_CARD_ORDER_ERROR, true, url, dispatch, data, callback);
}

export function generateGiftCardNumbers(tenantId, giftCardOrderId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}/generategiftcardnumbers`;
  return dispatch =>
    getData(GENERATE_GIFT_CARD_NUMBERS, GIFT_CARD_ORDER_ERROR, true, url, dispatch);
}

export function updateOrder(tenantId, giftCardOrderId, {giftCardType}, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardorders/${giftCardOrderId}/update`;
  var data = {
    giftCardType: giftCardType
  }

  return dispatch => patchData(UPDATE_GIFT_CARD_ORDER, GIFT_CARD_ORDER_ERROR, true, url, dispatch, data, callback);
}