import React, { Component } from "react";

class PromoRulesAndRestrictionsHelp extends Component {

  componentDidMount() { }

  render() {
    return (
      <>
      <small>{`You can apply various rules & restrictions to each promo deal.`}</small>
      <button
        className="btn btn-sm btn-link"
        data-toggle="modal"
        data-target="#ruleAndConditionsHelpModal"
        // data-backdrop="static"
      >
        <small>{`Learn more`}</small>
      </button>

      <div
        className="modal fade"
        id="ruleAndConditionsHelpModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="#ruleAndConditionsHelpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title" id="ruleAndConditionsHelpModalLabel">
                {"You can apply rules and restrictions to any promo deal, for example:"}
              </span>
            </div>
            <div className="modal-body text-left">
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-clock fa-2x mr-2"></i>
                  <strong>Happy Hour</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available (or be shown) only on certain week days or hourly plans`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-map-marker-alt fa-2x mr-2"></i>
                  <strong>Delivery Area</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available (or to be different) depending on delivery areas or pick-up mode`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-shopping-cart fa-2x mr-2"></i>
                  <strong>Cart Value</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available (or to be different) depending on certain total cart value or range`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-money-check-alt fa-2x mr-2"></i>
                  <strong>Payment</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available (or to be different) depending on the chosen payment method`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-calendar-alt fa-2x mr-2"></i>
                  <strong>Expiration</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available only until a certain date`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-users fa-2x mr-2"></i>
                  <strong>Client Type</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available only for certain types of clients`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-wave-square fa-2x mr-2"></i>
                  <strong>Frequency</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to be available only once per client`}
                </div>
              </div>
              <div className="row mb-3 mt-3 pb-3 border-bottom">
                <div className="col-4">
                  <i className="fas fa-clock fa-2x mr-2"></i>
                  <strong>Exclusivity</strong>
                </div>
                <div className="col-8">
                  {`Allow the benefit to cummulate on top of other promo deal benefits arising from other promo deals (if the client is eligible) or make it redeemable only stand-alone`}
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-dismiss="modal"
              >
                {`Close`}
              </button>
            </div> */}
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default PromoRulesAndRestrictionsHelp;
