import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import ReservationInfoCard from "./ReservationInfoCard";

import {
  fetchReservation,
  acceptReservation,
  rejectReservation
} from "../../actions/reservation";

import "./ReservationDetail.css";
import SendEmail from "./SendEmail";

class ReservationDetail extends Component {
  constructor(props) {
    super(props);

    this.handleAcceptReservation = this.handleAcceptReservation.bind(this);
    this.handleRejectReservation = this.handleRejectReservation.bind(this);
  }

  componentDidMount() {
    if (this.props.reservationId) {
      this.props.fetchReservation(this.props.reservationId);
    }
  }

  handleAcceptReservation(reservation) {
    this.props.acceptReservation(reservation._id);
  }

  handleRejectReservation(reservation) {
    this.props.rejectReservation(reservation._id);
  }

  render() {
    const { restaurant, reservation, handleViewReservations } = this.props;

    if (!reservation) {
      return <></>;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewReservations}
              >
                Reservations
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Reservation: {reservation.reservationNumber}
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewReservations}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>

          <div className="col-9 text-right">
            <div className="row">
              <div className="col-12">
                <SendEmail reservation={reservation} />

                {` `}

                {reservation.status === "Received" && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => this.handleRejectReservation(reservation)}
                    >
                      <Icon.Slash size={16} className="d-inline" />
                      <span className="d-none d-md-inline">Reject</span>
                    </button>
                    {` `}
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => this.handleAcceptReservation(reservation)}
                    >
                      <Icon.Check size={16} className="d-inline" />
                      <span className="d-none d-md-inline">Accept</span>
                    </button>
                  </>
                )}
                {reservation.status === "Rejected" && (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => this.handleAcceptReservation(reservation)}
                  >
                    <Icon.Check size={16} className="d-inline" />
                    <span className="d-none d-md-inline">Accept</span>
                  </button>
                )}
                {reservation.status === "Accepted" && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => this.handleRejectReservation(reservation)}
                    >
                      <Icon.Slash size={16} className="d-inline" />
                      <span className="d-none d-md-inline">Reject</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ReservationInfoCard restaurant={restaurant} reservation={reservation} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    reservation: state.reservation.reservation,
    restaurant: state.tenant.restaurant
  };
};

const mapDispatchToProps = dispatch => ({
  fetchReservation: reservationId => dispatch(fetchReservation(reservationId)),
  acceptReservation: reservationId =>
    dispatch(acceptReservation(reservationId)),
  rejectReservation: reservationId => dispatch(rejectReservation(reservationId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationDetail);
