import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import "./Header.css";
import * as Icon from "react-feather";
import { isOwner, isAdmin, isManager } from "./User";
import { fetchTenant } from "../actions/tenant";

import Sound from "react-sound";

import Numeral from "numeral";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sound_file from "./got-it-done-613.mp3";

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";
import * as GiIcons from "react-icons/gi";
import * as CgIcons from "react-icons/cg";
import * as BsIcons from "react-icons/bs";

import { socket } from "../service/socket";

const cookies = new Cookies();

const OrderNotification = (data) => {
  return (
    <div>
      <Sound url={sound_file} playStatus={Sound.status.PLAYING} />
      <h6>{`New ${data.order.deliveryMethod} Order Arrived`}</h6>
      <p>Order Number: {data.order.orderNumber}</p>
      <p>
        Customer:{" "}
        {`${
          data.order.nameOrderedUnder ? data.order.nameOrderedUnder + " / " : ""
        }${data.order.phone}`}
      </p>
      <p>Amount: {Numeral(data.order.total).format("$0,0.00")}</p>
    </div>
  );
};

const GiftCardOrderNotification = (data) => {
  return (
    <div>
      <Sound url={sound_file} playStatus={Sound.status.PLAYING} />
      <h6>{`New ${data.giftCardOrder.giftCardType} Gift Card Order Arrived`}</h6>
      <p>Order Number: {data.giftCardOrder.giftCardOrderNumber}</p>
      <p>
        Customer:{" "}
        {`${
          data.giftCardOrder.nameOrderedUnder
            ? data.giftCardOrder.nameOrderedUnder + " / "
            : ""
        }${data.giftCardOrder.phone}`}
      </p>
      <p>Amount: {Numeral(data.giftCardOrder.price).format("$0,0.00")}</p>
    </div>
  );
};

class Header extends Component {

  raiseNewOrderNotification = (order) => {
    return toast.info(<OrderNotification order={order} />);
  };

  raiseNewGiftCardOrderNotification = (giftCardOrder) => {
    return toast.info(
      <GiftCardOrderNotification giftCardOrder={giftCardOrder} />
    );
  };

  componentDidMount() {
    if (!this.props.tenant) {
      this.props.fetchTenant();
      var state_current = this;
      socket.on(
        "raise_new_order_notification",
        state_current.raiseNewOrderNotification
      );
      socket.on(
        "raise_new_gift_card_order_notification",
        state_current.raiseNewGiftCardOrderNotification
      );
    }
  }

  componentWillUnmount() {
    socket.off("raise_new_order_notification");
    socket.off("raise_new_gift_card_order_notification");
  }

  render() {
    const user = cookies.get("user");
    const { tenant} = this.props;
    let userName = user
      ? user.firstName + " " + user.lastName + "( " + user.role + " )"
      : "";
    return (
      <header className="main-header fixed-top text-center">
        <nav className="navbar navbar-expand-md navbar-angkor">
          <a className="navbar-brand" href="/">
            {tenant && tenant.logo && tenant.logo.url && (
              <img
                // className="img-fluid"
                height="40px"
                src={
                  process.env.REACT_APP_S3_BASE_URL +
                  `${encodeURIComponent(tenant.slug + "/" + tenant.logo.url)}`
                }
                alt="Logo"
              />
            )}
          </a>
          <button
            className="navbar-toggler order-first"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav px-3 flex-column flex-sm-column d-md-none text-left mr-auto">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/dashboard"
                >
                  <FaIcons.FaChartLine /> {`Dashboard`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/orders"
                >
                  <RiIcons.RiFileCopy2Line /> {`Orders`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/take-order"
                >
                  <FiIcons.FiPhoneIncoming /> {`Take Order`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/searchorders"
                >
                  <AiIcons.AiOutlineFileSearch /> {`Search Orders`}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/kitchen-orders"
                >
                  <GiIcons.GiKitchenScale /> {`Kitchen Orders`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/cooking-queue"
                >
                  <BsIcons.BsLayoutThreeColumns /> {`Cooking Queue`}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/historical-orders"
                >
                  <AiIcons.AiOutlineFileDone /> {`Historical Orders`}
                </NavLink>
              </li> */}

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/coupons"
                >
                  <RiIcons.RiCoupon2Line /> {`Coupons`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/searchcoupons"
                >
                  <FaIcons.FaSearchDollar /> {`Search Coupons`}
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/giftcards"
                >
                  <MdIcons.MdCardGiftcard /> {`Gift Cards`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/giftcardorders"
                >
                  <IoIcons.IoIosPaper /> {`Gift Card Orders`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/searchgiftcardorders"
                >
                  <AiIcons.AiOutlineFileSearch /> {`Search Gift Card Orders`}
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/customers"
                >
                  <FiIcons.FiUsers /> {`Customers`}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/searchcustomers"
                >
                  <RiIcons.RiUserSearchLine /> {`Search Customers`}
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/reservations"
                >
                  <RiIcons.RiReservedLine /> {`Reservations`}
                </NavLink>
              </li> */}

              {(isOwner() || isAdmin() || isManager()) && (
                <>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/menus"
                    >
                      <MdIcons.MdOutlineRestaurantMenu /> {`Menus`}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/giftcardproducts"
                    >
                      <MdIcons.MdOutlineCardGiftcard />{" "}
                      <span>{`Gift Card Products`}</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/promotions"
                    >
                      <FaIcons.FaTags /> {`Promotions`}
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/announcements"
                    >
                      <FaIcons.FaBullhorn />{" "}
                      <span>{`Announcements`}</span>
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/images"
                    >
                      <FaIcons.FaImages /> {`Image Library`}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/settings"
                      id="navbarDropdownSettingsLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <FiIcons.FiSettings />
                      {` `}
                      {`Settings`}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/restaurants"
                    >
                      <FaIcons.FaMapMarkerAlt /> {`Restaurants`}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/businesshours"
                    >
                      <FaIcons.FaBusinessTime /> {`Business Hours`}
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      activeClassName="active"
                      to="/employees"
                    >
                      <FaIcons.FaUsersCog /> {`Employees`}
                    </NavLink>
                  </li>
                </>
              )}
              {(isOwner() || isAdmin()) && (
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    activeClassName="active"
                    to="/billing"
                  >
                    <FaIcons.FaFileInvoiceDollar />
                    {` `}
                    {`Billing`}
                  </NavLink>
                </li>
              )}
            </ul>
            <ul className="navbar-nav ml-auto px-3 text-left">
              {/* <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdownNotifications"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="far fa-bell" />
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownNotifications"
                >
                  <a className="dropdown-item row border-bottom" href="/orders">
                    <h6> New Order Received</h6>
                    <p>Order Id: 12345 Price: $12.57</p>
                  </a>
                </div>
              </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  id="navbarDropdownProfileLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Icon.User size={16} /> {userName}
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownProfileLink"
                >
                  <a className="dropdown-item" href="/profile">
                    <CgIcons.CgProfile /> Profile
                  </a>
                  <a className="dropdown-item" href="/logout">
                    <MdIcons.MdLogout /> Logout
                  </a>
                </div>
              </li>
              {/* <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/profile"
                >
                  <Icon.User size={16} />
                  {`My Profile`}
                </NavLink>
              </li>
              <div className="dropdown-divider" /> 
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to="/logout"
                >
                  <Icon.LogOut size={16} />
                  {`Logout`}
                </NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </header>
      // <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
      //   <Link className="navbar-brand col-sm-3 col-md-2 mr-0" to="/">
      //     {restaurantName}
      //   </Link>
      //   {/* <input className="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" /> */}
      //   {authenticated && (
      //     <span className="text-white">WELCOME {userName}</span>
      //   )}
      //   <ul className="navbar-nav px-3">
      //     {/* <li className>{authenticated && <span>WELCOME {userName}</span>}</li> */}

      //   </ul>
      // </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.authenticated,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchTenant: () => dispatch(fetchTenant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
