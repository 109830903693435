import React from "react";
import Numeral from "numeral";
import "./CartItem.css";

const CartPromotion = ({ promotion, handleRemovePromotion, changeable }) => {
  return (
    <div className="row border-bottom pb-3 pt-3">
      <div className="col-12">
        <div className="row">
          <div className="col-7 text-left col-cart-angkor">
            <strong>{`${promotion.quantity}X ${
              promotion.promotionName
            }`}</strong>
            <br />
            <span className="promotion-label">
              {`You saved: ${Numeral(promotion.discountAmount).format(
                "$0,0.00"
              )}`}
            </span>
          </div>
          <div className="col-3 text-right col-cart-angkor">
            <strong>{Numeral(promotion.amount).format("$0,0.00")}</strong>
          </div>
          {changeable && (
            <div className="col-2 text-right col-cart-angkor">
              <button
                className="btn btn-danger btn-sm btn-sm-angkor"
                onClick={() => handleRemovePromotion(promotion)}
              >
                {`X`}
              </button>
            </div>
          )}
        </div>
        <hr className="dashed-line" />
        {promotion.discountedItems &&
          promotion.discountedItems.map(item => (
            <div className="mb-2" key={item.uuid}>
              <div className="row">
                <div className="col-7 col-cart-angkor">
                  {item.name}
                  <ul className="list-unstyled ml-3">
                    {item.size && item.size.length > 0 && (
                      <li className="text-muted font-italic">
                        <small>Size: {item.size}</small>
                      </li>
                    )}
                    {item.options.map((option, index) => (
                      <li className="text-muted font-italic" key={index}>
                        <small>
                          {`${option.option_name}:`}
                          {option.option_value && (
                            <span>{` ${option.option_value}`}</span>
                          )}
                          {option.option_total && (
                            <span>{` (${Numeral(option.option_total).format(
                              "$0,0.00"
                            )})`}</span>
                          )}
                        </small>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-3 text-right col-cart-angkor">
                  <div className="text-muted font-italic">
                    {Numeral(item.baseTotal + item.extraTotal).format(
                      "$0,0.00"
                    )}
                  </div>
                  {item.discountAmount > 0 && (
                    <div className="text-muted font-italic">
                      {Numeral(-1 * item.discountAmount).format("$0,0.00")}
                    </div>
                  )}
                </div>
              </div>
              {item.specialInstructions && item.specialInstructions.length > 0 && (
                <div className="row">
                  <div className="col-12  col-cart-angkor">
                    <small>Special Requests: {item.specialInstructions}</small>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartPromotion;
