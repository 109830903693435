import {
  FETCH_USER,
  USER_ERROR,
  FETCH_USERS,
  ADD_USER,
  UPDATE_USER
} from "../actions/types";

const INITIAL_STATE = { profile: {}, message: "", error: "", users: [] };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload.user };
    case FETCH_USERS:
      return { ...state, users: action.payload.users };
    case ADD_USER:
      return { ...state, users: [...state.users, action.payload.user] };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
        users: state.users.map(user => {
          if (user._id === action.payload.user._id) {
            return action.payload.user;
          } else {
            return user;
          }
        })
      };
    case USER_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
