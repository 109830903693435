import React, { Component } from "react";

class PromoClientInfo extends Component {
  render() {
    const {
      sectionNumber,
      handleChoiceChange,
      choice
    } = this.props;

    return (
      <div className="card mb-3">
          <div className="card-header">
            <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
            <strong>
              Choose which clients can benefit from this promo deal
              </strong>
          </div>
          <div className="card-body">
            <div className="row promo-row pt-3 pb-3 mb-3">
              <div className="col-12">
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="eligible_client_option"
                    value="Any client, new or returning"
                    onChange={handleChoiceChange}
                    checked={choice ==="Any client, new or returning"}
                  />
                  <label className="form-check-label">
                    {`Any client, new or returning (Default)`}
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="eligible_client_option"
                    value="New clients only"
                    onChange={handleChoiceChange}
                    checked={choice === "New clients only"}
                  />
                  <label className="form-check-label">
                    {`New clients only`}
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="eligible_client_option"
                    value="Only returning clients"
                    onChange={handleChoiceChange}
                    checked={choice ==="Only returning clients"}
                  />
                  <label className="form-check-label">
                    {`Only returning clients`}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default PromoClientInfo;