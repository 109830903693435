import React, { Component } from "react";
import { connect } from "react-redux";

import { searchOrders } from "../../actions/order";

import OrderListView from "./OrderListView";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import { Redirect } from "react-router-dom";
import * as AiIcons from "react-icons/ai";

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import Cookies from "universal-cookie";

import normalizePhone from "../../utils/normalizePhone";

const cookies = new Cookies();

class SearchOrders extends Component {
  constructor(props) {
    super(props);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleOrderNumberFilterChange =
      this.handleOrderNumberFilterChange.bind(this);
    this.handlePhoneNumberFilterChange =
      this.handlePhoneNumberFilterChange.bind(this);
    this.handlePeriodFromChange = this.handlePeriodFromChange.bind(this);
    this.handlePeriodToChange = this.handlePeriodToChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.handleSelectedRestaurantsChange =
      this.handleSelectedRestaurantsChange.bind(this);

    this.state = {
      loading: false,
      orderNumberFilter: undefined,
      phoneNumberFilter: undefined,
      periodFrom: undefined,
      periodTo: undefined,
      selectedOrder: undefined,
      selectedRestaurantIds: [],
      applicableRestaurants: [],
    };
  }

  componentDidMount() {
    if (this.props.restaurants.length > 0) {
      const user = cookies.get("user");
      const applicableRestaurants = (
        user.role === "Owner"
          ? this.props.restaurants
          : this.props.restaurants.filter((_restaurant) =>
              user.restaurants.includes(_restaurant._id)
            )
      ).map((_restaurant) => {
        return { key: _restaurant._id, label: _restaurant.name };
      });
      this.setState({
        applicableRestaurants: applicableRestaurants,
        selectedRestaurantIds: applicableRestaurants.map((ar) => ar.key),
      });
    }
  }

  componentWillUnmount() {}

  handleSelectedRestaurantsChange(selected) {
    this.setState({ selectedRestaurantIds: selected }, () => {});
  }

  handleOrderNumberFilterChange(event) {
    this.setState({
      orderNumberFilter: event.target.value,
    });
  }

  handlePhoneNumberFilterChange(event) {
    this.setState({
      phoneNumberFilter: normalizePhone(event.target.value),
    });
  }

  handlePeriodFromChange(value) {
    this.setState({
      periodFrom: new Date(value),
    });
  }

  handlePeriodToChange(value) {
    this.setState({
      periodTo: new Date(value),
    });
  }

  handleViewDetail(order) {
    this.setState({ selectedOrder: order });
  }

  handleSearch() {
    this.props.searchOrders(
      this.props.tenant._id,
      this.state.selectedRestaurantIds,
      this.state.orderNumberFilter,
      this.state.phoneNumberFilter,
      this.state.periodFrom,
      this.state.periodTo
    );
  }

  render() {
    const { tenant, orders = [] } = this.props;

    if (tenant === undefined || this.state.applicableRestaurants.length <= 0) {
      return <></>;
    }

    if (this.state.selectedOrder) {
      return (
        <Redirect
          to={`/searchorders/${this.state.selectedOrder.orderNumber}`}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <AiIcons.AiOutlineFileSearch />
                {` `}
                <span>{`Search Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6 col-lg-3 mb-3">
            <DropdownMultiselect
              options={this.state.applicableRestaurants}
              name="selectedRestaurantIds"
              selected={this.state.selectedRestaurantIds}
              handleOnChange={this.handleSelectedRestaurantsChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="orderNumberFilter"
              type="text"
              className="form-control"
              placeholder={"Order Number"}
              onChange={this.handleOrderNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="phoneNumberFilter"
              type="tel"
              className="form-control"
              placeholder={"123-456-7890"}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={this.handlePhoneNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 ">
            <DateTimePicker
              onChange={this.handlePeriodFromChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"Start Date"}
              time={false}
              date={true}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <DateTimePicker
              onChange={this.handlePeriodToChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"End Date"}
              time={false}
              date={true}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        <OrderListView
          orders={orders}
          handleViewDetail={this.handleViewDetail}
          tenant={tenant}
          loading={this.state.loading}
          isSearchOrders={true}
          isNextDayOrder={false}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    orders: state.order.orders,
    restaurants: state.tenant.restaurants || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchOrders: (
    tenantId,
    restaurantIds,
    orderNumber,
    phoneNumber,
    periodFrom,
    periodTo
  ) =>
    dispatch(
      searchOrders(
        tenantId,
        restaurantIds,
        orderNumber,
        phoneNumber,
        periodFrom,
        periodTo
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrders);
