import React, { Component } from "react";

import * as Icon from "react-feather";

class MenuInfoCard extends Component {

  render() {
    const { tenant, handleEditMenu, menu } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-7">
              <h5>Menu Info</h5>
            </div>
            <div className="col-5 text-right">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleEditMenu(menu)}
              >
                <Icon.Edit2 size={16} />
                <span className="d-none d-lg-inline">{` `}Edit</span>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {this.props.menu.image && (
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_S3_BASE_URL + `${encodeURIComponent(tenant.slug + '/menu/' + this.props.menu.image.url)}`}
                  alt="Menu"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h6>{this.props.menu.name}</h6>
              <p>{this.props.menu.description}</p>
              <p>{this.props.menu.note}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuInfoCard;
