import React from "react";
import * as Icon from "react-feather";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "react-widgets/dist/css/react-widgets.css";
import Dropzone from "react-dropzone";

momentLocalizer(moment);

export const phoneNumber = value =>
  value && !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined;

export const required = value => value ? undefined : 'Required';

export const renderField = ({
  input,
  label,
  type,
  placeholder,
  isTextarea = false,
  meta: { touched, error, invalid },
}) => {
  const textareaType = <textarea {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} />;
  const inputType = <input {...input} placeholder={label} type={type} className={`form-control ${touched && invalid ? 'has-danger' : ''}`} />;

  return (
    <div>
      {isTextarea ? textareaType : inputType}
      {touched && error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export const renderField2 = ({
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div className="form-group">
    <label className="col-form-label">{label}</label>
    <div>
      <input {...input} type={type} placeholder={label} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
);

export const renderCheckboxField = ({
  input,
  type,
  meta: { touched, error },
}) => (
  <div>
    <input className="form-check-input" {...input} type={type} />
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderEmailField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">@</span>
      </div>
      <input
        className="form-control"
        {...input}
        type="email"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderTenantField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Home size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="text"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderUserNameField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.User size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="text"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPasswordField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Lock size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="password"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPhoneField = ({
  input,
  placeholder = "123-456-7890",
  withIcon = true,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      {withIcon === true && (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <Icon.Phone size={16} />
          </span>
        </div>
      )}

      <input
        className="form-control"
        {...input}
        type="text"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderPriceField = ({
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.DollarSign size={16} />
        </span>
      </div>
      <input
        className="form-control"
        {...input}
        type="number"
        placeholder={placeholder}
      />
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

export const renderDateTimePicker = ({
  input: { onChange, value },
  showTime,
  showDate = true,
  disabled = false,
  dateFormat = "DD MMM YYYY",
  timeFormat = "hh:mm A",
  datetimeFormat = "DD MMM YYYY hh:mm A",
}) => {
  let format = dateFormat;
  if (showDate) {
    if (showTime) {
      format = datetimeFormat;
    } else {
      format = dateFormat;
    }
  } else {
    if (showTime) {
      format = timeFormat;
    }
  }

  return (
    <DateTimePicker
      onChange={onChange}
      format={format}
      time={showTime}
      date={showDate}
      value={!value ? null : new Date(value)}
      // min={new Date()}
      disabled={disabled}
    />
  );
};

export const renderDropzoneInput = ({
  name,
  input,
  imgsrc,
  width = 330,
  height = 240,
  uploader,
  meta: { touched, error },
}) => {
  const files = input.value;
  let filesWithPreview = [];
  if (files && Array.isArray(files)) {
    filesWithPreview = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
  }

  const baseStyle = {
    width: width,
    height: height,
    borderWidth: 2,
    borderColor: "#666",
    borderStyle: "dashed",
    borderRadius: 5,
  };
  const activeStyle = {
    borderStyle: "solid",
    borderColor: "#6c6",
    backgroundColor: "#eee",
  };
  const rejectStyle = {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee",
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <Dropzone
          style={{
            // width: "330px",
            // height: "240px",
            borderWidth: "2px",
            borderColor: "rgb(102, 102, 102)",
            borderStyle: "dashed",
            borderRadius: "5px",
            padding: "20px",
          }}
          name={name}
          onDrop={(filesToUpload, e) => {
            input.onChange(filesToUpload);
            if (uploader) {
              uploader(filesToUpload);
            }
            filesToUpload = [];
          }}
          // ref={node => {
          //   let dropzoneRef = node;
          // }}
          maxSize={5242880}
          multiple={false}
          accept={"image/*"}
          className="drop-zone"
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
            acceptedFiles,
            rejectedFiles,
          }) => {
            let styles = { ...baseStyle };
            styles = isDragActive ? { ...styles, ...activeStyle } : styles;
            styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

            return (
              <div
                {...getRootProps()}
                style={styles}
                className="d-flex align-items-center justify-content-center"
              >
                <input {...getInputProps()} />
                {filesWithPreview.length === 0 && !imgsrc && (
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-lg"
                  >
                    <Icon.Plus size={48} />
                    <p><small>File</small></p>
                  </button>
                )}
                {filesWithPreview.length === 0 && imgsrc && (
                  <img
                    className="img-fluid"
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${imgsrc
                      }`
                    }
                    alt={name}
                  />
                )}
                {filesWithPreview.length > 0 && (
                  <img
                    src={filesWithPreview[0].preview}
                    className="img-fluid"
                    alt="uploaded"
                  />
                )}
                {isDragReject && <div>Unsupported file type...</div>}
              </div>
            );
          }}
        </Dropzone>
        {touched && error && <span className="error">{error}</span>}
      </div>
    </div>
  );
};

export const renderTimeSelectField = ({
  name,
  times,
  timeFormat,
  meta: { touched, error },
}) => (
  <div>
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon.Clock size={16} />
        </span>
      </div>
      <select className="form-control" name={name}>
        <option />
        {times &&
          times.map((time, index) => (
            <option key={moment(time)} value={moment(time)}>
              {moment(time).format(timeFormat)}
            </option>
          ))}
      </select>
    </div>
    {touched && error && <div className="alert alert-danger">{error}</div>}
  </div>
);

