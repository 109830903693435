import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import GiftCardListView from "./GiftCardListView";

import * as MdIcons from "react-icons/md";

import { fetchGiftCards } from "../../actions/giftcard";

class GiftCards extends Component {
  constructor(props) {
    super(props);

    this.handleGiftCardNumberFilterChange =
      this.handleGiftCardNumberFilterChange.bind(this);
    this.handlePhoneNumberFilterChange =
      this.handlePhoneNumberFilterChange.bind(this);
    this.handleEmailFilterChange = this.handleEmailFilterChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.handleViewDetail = this.handleViewDetail.bind(this);

    this.state = {
      loading: false,
      giftCardNumberFilter: undefined,
      phoneNumberFilter: undefined,
      emailFilter: undefined,
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.props.fetchGiftCards(
        this.props.restaurant._id,
        this.state.emailFilter,
        this.state.phoneNumberFilter,
        this.state.giftCardNumberFilter
      );
    }
  }

  componentWillUnmount() {}

  handleViewDetail(giftCard) {
    this.setState({ selectedGiftCard: giftCard });
  }

  handleGiftCardNumberFilterChange(event) {
    this.setState({
      giftCardNumberFilter: event.target.value,
    });
  }

  handlePhoneNumberFilterChange(event) {
    this.setState({
      phoneNumberFilter: event.target.value,
    });
  }

  handleEmailFilterChange(event) {
    this.setState({
      emailFilter: event.target.value,
    });
  }

  handleSearch() {
    this.props.fetchGiftCards(
      this.props.tenant._id,
      this.state.emailFilter,
      this.state.phoneNumberFilter,
      this.state.giftCardNumberFilter
    );
  }

  render() {
    const { tenant, giftCards = [] } = this.props;

    if (tenant === undefined) {
      return <></>;
    }

    if (this.state.selectedGiftCard) {
      return (
        <Redirect
          to={`/giftcards/${this.state.selectedGiftCard.giftCardNumber}`}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <MdIcons.MdCardGiftcard />
                {` `}
                <span>{`Gift Cards`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="emailFilter"
              type="text"
              className="form-control"
              placeholder={"Email"}
              onChange={this.handleEmailFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="phoneNumberFilter"
              type="tel"
              className="form-control"
              placeholder={"123-456-7890"}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={this.handlePhoneNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="giftCardNumberFilter"
              type="text"
              className="form-control"
              placeholder={"Gift card number"}
              onChange={this.handleGiftCardNumberFilterChange}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        <GiftCardListView
          giftCards={giftCards}
          handleViewDetail={this.handleViewDetail}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    giftCards: state.giftCard.giftCards,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCards: (teanantId, email, phoneNumber, giftCardNumber) =>
    dispatch(fetchGiftCards(teanantId, email, phoneNumber, giftCardNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
