import React, { Component } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";
import Numeral from "numeral";

import Cookies from "universal-cookie";

import { Bar, Pie, Line } from "react-chartjs-2";

import { socket } from "../service/socket";

import Loading from "./Common/Loading";

import DateTimePicker from "react-widgets/lib/DateTimePicker";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const cookies = new Cookies();

export class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.handleSelectPeriod = this.handleSelectPeriod.bind(this);

    this.handlePeriodFromChange = this.handlePeriodFromChange.bind(this);
    this.handlePeriodToChange = this.handlePeriodToChange.bind(this);

    this.handleSelectedRestaurantsChange =
      this.handleSelectedRestaurantsChange.bind(this);

    this.state = {
      loading: false,
      selectedRestaurantIds: [],
      applicableRestaurants: [],
      period: "Today",
      from: undefined,
      to: undefined,
      summary: {
        sales_count: 0,
        sales_number: 0,
        total_order_amount: 0,
        total_order_number: 0,
        received_order_amount: 0,
        received_order_number: 0,
        accepted_order_amount: 0,
        accepted_order_number: 0,
        fulfilled_order_amount: 0,
        fulfilled_order_number: 0,
        rejected_order_amount: 0,
        rejected_order_number: 0,
        cancelled_order_amount: 0,
        cancelled_order_number: 0,
        pickup_order_amount: 0,
        pickup_order_number: 0,
        delivery_order_amount: 0,
        delivery_order_number: 0,
        intransit_order_amount: 0,
        intransit_order_number: 0,
        online_order_amount: 0,
        online_order_number: 0,
        callin_order_amount: 0,
        callin_order_number: 0,
        gift_card_sold_amount: 0,
        gift_card_sold_count: 0,
        gift_card_redeemed_amount: 0,
        gift_card_redeemed_count: 0,
        coupon_redeemed_amount: 0,
        coupon_redeemed_count: 0,
        customer_count: 0,
        returning_customer_count: 0,
        new_registered_user_count: 0,
        new_guest_count: 0,
        best_selling_items: [],
        top_discounted_items: [],
        top_delivery_postal_codes: [],
        orders_per_hour: {
          labels: [],
          orders_received: [],
          orders_accepted: [],
          orders_fulfilled: [],
          orders_rejected: [],
          orders_cancelled: [],
        },
        sales_per_date: {
          labels: [],
          sales: [],
        },
        sales_per_hour: {
          labels: [],
          sales: [],
        },
        sales_per_menu: {
          salesPerMenu: {
            labels: [],
            quantity: [],
            amount: [],
          },
          salesPerSection: {
            labels: [],
            quantity: [],
            amount: [],
          },
        },
        sales_per_payment_method: {
          labels: [],
          counts: [],
          amounts: [],
        },
        sales_per_delivery_method: {
          labels: [],
          counts: [],
          amounts: [],
        },
        sales_per_channel: {
          labels: [],
          counts: [],
          amounts: [],
        },
        sales_per_promo: {
          labels: [],
          orderCounts: [],
          orderAmounts: [],
          discountAmounts: [],
        },
      },
    };
  }
  getData = (summary) => {
    this.setState((prevState) => {
      return { summary: summary, loading: false };
    });
  };

  componentDidMount() {
    if (this.props.restaurants.length > 0) {
      var state_current = this;
      const user = cookies.get("user");
      const applicableRestaurants = (
        user.role === "Owner"
          ? this.props.restaurants
          : this.props.restaurants.filter((_restaurant) =>
              user.restaurants.includes(_restaurant._id)
            )
      ).map((_restaurant) => {
        return { key: _restaurant._id, label: _restaurant.name };
      });
      this.setState(
        {
          loading: true,
          period: "Today",
          selectedRestaurantIds: applicableRestaurants.map((ar) => ar.key),
          applicableRestaurants: applicableRestaurants,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
          socket.on("get_data", state_current.getData);
        }
      );
    }
  }

  componentWillUnmount() {
    socket.off("get_data");
  }

  handlePeriodFromChange(value) {
    this.setState(
      {
        loading: true,
        from: moment(value)
          .tz(this.props.tenant.settings.time_zone)
          .startOf("day")
          .toDate(),
      },
      () => {
        socket.emit(
          "initial_data",
          this.props.tenant._id,
          this.state.selectedRestaurantIds,
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handlePeriodToChange(value) {
    this.setState(
      {
        loading: true,
        to: moment(value)
          .tz(this.props.tenant.settings.time_zone)
          .endOf("day")
          .toDate(),
      },
      () => {
        socket.emit(
          "initial_data",
          this.props.tenant._id,
          this.state.selectedRestaurantIds,
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handleSelectedRestaurantsChange(selected) {
    this.setState({ selectedRestaurantIds: selected, loading: true, }, () => {
      socket.emit(
        "initial_data",
        this.props.tenant._id,
        this.state.selectedRestaurantIds,
        {
          from: this.state.from,
          to: this.state.to,
        }
      );
    });
  }

  handleSelectPeriod(period) {
    this.setState({
      loading: true,
    });

    if (period === "Today") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Yesterday") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .subtract(1, "days")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .subtract(1, "days")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last 7 days") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .subtract(7, "days")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last 14 days") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .subtract(14, "days")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last 30 days") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .subtract(30, "days")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last 90 days") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .subtract(90, "days")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last 12 months") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .subtract(12, "months")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last week") {
      const startOfLastWeek = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("week")
        .subtract(1, "weeks");
      const endOfLastWeek = moment(startOfLastWeek).endOf("week");
      this.setState(
        {
          period: period,
          from: startOfLastWeek.toDate(),
          to: endOfLastWeek.toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last month") {
      const startOfLastMonth = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("month")
        .subtract(1, "months");
      const endOfLastMonth = moment(startOfLastMonth).endOf("month");
      this.setState(
        {
          period: period,
          from: startOfLastMonth.toDate(),
          to: endOfLastMonth.toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last quarter") {
      const startOfLastQuarter = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("quarter")
        .subtract(1, "quarters");
      const endOfLastQuarter = moment(startOfLastQuarter).endOf("quarter");
      this.setState(
        {
          period: period,
          from: startOfLastQuarter.toDate(),
          to: endOfLastQuarter.toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Last year") {
      const startOfLasYear = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("year")
        .subtract(1, "years");
      const endOfLastYear = moment(startOfLasYear).endOf("year");
      this.setState(
        {
          period: period,
          from: startOfLasYear.toDate(),
          to: endOfLastYear.toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Week to date") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("week")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Month to date") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("month")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Quarter to date") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("quarter")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
    if (period === "Year to date") {
      this.setState(
        {
          period: period,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("year")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit(
            "initial_data",
            this.props.tenant._id,
            this.state.selectedRestaurantIds,
            {
              from: this.state.from,
              to: this.state.to,
            }
          );
        }
      );
    }
  }

  render() {
    const { tenant } = this.props;
    if (
      tenant === undefined ||
      this.state.applicableRestaurants.length <= 0
    ) {
      return <></>;
    }

    let orders_per_hour_chart_data = {
      labels: this.state.summary.orders_per_hour.labels,
      datasets: [
        {
          label: "Orders Received",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.orders_per_hour.orders_received,
        },
        {
          label: "Orders Requested",
          backgroundColor: "rgba(65, 154, 244,0.2)",
          borderColor: "rgba(65, 154, 244,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(65, 154, 244,0.4)",
          hoverBorderColor: "rgba(65, 154, 244,1)",
          data: this.state.summary.orders_per_hour.orders_requested,
        },
        {
          label: "Orders Fulfilled",
          backgroundColor: "rgba(65, 244, 122,0.2)",
          borderColor: "rgba(65, 244, 122,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(65, 244, 122,0.4)",
          hoverBorderColor: "rgba(65, 244, 122,1)",
          data: this.state.summary.orders_per_hour.orders_fulfilled,
        },
      ],
    };

    let sales_per_hour_chart_data = {
      labels: this.state.summary.sales_per_hour.labels,
      datasets: [
        {
          label: "Sales",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_hour.sales,
        },
      ],
    };

    let sales_per_date_chart_data = {
      labels: this.state.summary.sales_per_date.labels,
      datasets: [
        {
          label: "Sales",
          fill: false,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.state.summary.sales_per_date.sales,
        },
      ],
    };

    let sales_per_section_chart_data = {
      labels: this.state.summary.sales_per_menu.salesPerSection.labels,
      datasets: [
        {
          label: "Sales",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_menu.salesPerSection.amount,
        },
      ],
    };

    let sales_per_menu_chart_data = {
      labels: this.state.summary.sales_per_menu.salesPerMenu.labels,
      datasets: [
        {
          label: "Sales",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          data: this.state.summary.sales_per_menu.salesPerMenu.amount,
        },
      ],
    };

    let sales_per_payment_method_chart_data = {
      labels: this.state.summary.sales_per_payment_method.labels,
      datasets: [
        {
          label: "Orders",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_payment_method.counts,
        },
        {
          label: "Amount",
          backgroundColor: "rgba(65, 154, 244,0.2)",
          borderColor: "rgba(65, 154, 244,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(65, 154, 244,0.4)",
          hoverBorderColor: "rgba(65, 154, 244,1)",
          data: this.state.summary.sales_per_payment_method.amounts,
        },
      ],
    };

    let sales_per_delivery_method_chart_data = {
      labels: this.state.summary.sales_per_delivery_method.labels,
      datasets: [
        {
          label: "Orders",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_delivery_method.counts,
        },
        {
          label: "Amount",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          borderColor: "rgba(65, 154, 244,1)",
          borderWidth: 1,
          hoverBorderColor: "rgba(65, 154, 244,1)",
          data: this.state.summary.sales_per_delivery_method.amounts,
        },
      ],
    };

    let sales_per_channel_chart_data = {
      labels: this.state.summary.sales_per_channel.labels,
      datasets: [
        {
          label: "Orders",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_channel.counts,
        },
        {
          label: "Amount",
          backgroundColor: ["#FF6384", "#36A2EB"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB"],
          borderColor: "rgba(65, 154, 244,1)",
          borderWidth: 1,
          hoverBorderColor: "rgba(65, 154, 244,1)",
          data: this.state.summary.sales_per_channel.amounts,
        },
      ],
    };

    let sales_per_promo_chart_data = {
      labels: this.state.summary.sales_per_promo.labels,
      datasets: [
        {
          label: "Orders",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: this.state.summary.sales_per_promo.orderCounts,
        },
        // {
        //   label: "Order Amount",
        //   backgroundColor: "rgba(65, 154, 244,0.2)",
        //   borderColor: "rgba(65, 154, 244,1)",
        //   borderWidth: 1,
        //   hoverBackgroundColor: "rgba(65, 154, 244,0.4)",
        //   hoverBorderColor: "rgba(65, 154, 244,1)",
        //   data: this.state.summary.sales_per_promo.orderAmounts
        // },
        // {
        //   label: "Discount Amount",
        //   backgroundColor: "rgba(65, 244, 122,0.2)",
        //   borderColor: "rgba(65, 244, 122,1)",
        //   borderWidth: 1,
        //   hoverBackgroundColor: "rgba(65, 244, 122,0.4)",
        //   hoverBorderColor: "rgba(65, 244, 122,1)",
        //   data: this.state.summary.sales_per_promo.discountAmounts
        // }
      ],
    };

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-chart-line" />
                {` `}
                <span>{`Dashboard`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="row border-bottom">
          <div className="col-12  col-md-4 col-lg-3 mb-3 input-group justify-content-start">
            <DropdownMultiselect
              options={this.state.applicableRestaurants}
              name="selectedRestaurantIds"
              selected={this.state.selectedRestaurantIds}
              handleOnChange={this.handleSelectedRestaurantsChange}
            />
          </div>
          <div className="col-12  col-md-8 col-lg-9 mb-3 input-group justify-content-start justify-content-md-end">
            <DateTimePicker
              onChange={this.handlePeriodFromChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"Start Date"}
              value={this.state.from}
              time={false}
              date={true}
              className="mr-3"
            />
            <DateTimePicker
              onChange={this.handlePeriodToChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"End Date"}
              value={this.state.to}
              time={false}
              date={true}
              className="mr-3"
            />
            <div className="dropdown">
              <button
                className="btn btn-outline-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-calendar-alt" />
                {` `}
                <span>{this.state.period}</span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Today")}
                >{`Today`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Yesterday")}
                >{`Yesterday`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Week to date")}
                >{`Week to date`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last 7 days")}
                >{`Last 7 days`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last week")}
                >{`Last week`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last 14 days")}
                >{`Last 14 days`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Month to date")}
                >{`Month to date`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last 30 days")}
                >{`Last 30 days`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last month")}
                >{`Last month`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Quarter to date")}
                >{`Quarter to date`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last 90 days")}
                >{`Last 90 days`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last quarter")}
                >{`Last quarter`}</button>
                <div className="dropdown-divider"></div>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Year to date")}
                >{`Year to date`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last 12 months")}
                >{`Last 12 months`}</button>
                <button
                  className="dropdown-item clickable"
                  onClick={() => this.handleSelectPeriod("Last year")}
                >{`Last year`}</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.sales_count} /{" "}
                      {Numeral(this.state.summary.sales_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Total Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.total_order_number} /{" "}
                      {Numeral(this.state.summary.total_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Received Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.received_order_number} /{" "}
                      {Numeral(this.state.summary.received_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Accepted Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.accepted_order_number} /{" "}
                      {Numeral(this.state.summary.accepted_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Fulfilled Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.fulfilled_order_number} /{" "}
                      {Numeral(
                        this.state.summary.fulfilled_order_amount
                      ).format("$0,0.00")}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Rejected/Cancelled Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.rejected_order_number +
                        this.state.summary.cancelled_order_number}{" "}
                      /{" "}
                      {Numeral(
                        this.state.summary.rejected_order_amount +
                          this.state.summary.cancelled_order_amount
                      ).format("$0,0.00")}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Pickup Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.pickup_order_number} /{" "}
                      {Numeral(this.state.summary.pickup_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Delivery Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.delivery_order_number} /{" "}
                      {Numeral(this.state.summary.delivery_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Online Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.online_order_number} /{" "}
                      {Numeral(this.state.summary.online_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Call-In Orders</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.callin_order_number} /{" "}
                      {Numeral(this.state.summary.callin_order_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sold Gift Cards</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.gift_card_sold_count} /{" "}
                      {Numeral(this.state.summary.gift_card_sold_amount).format(
                        "$0,0.00"
                      )}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Redeemed Gift Cards</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.gift_card_redeemed_count} /{" "}
                      {Numeral(
                        this.state.summary.gift_card_redeemed_amount
                      ).format("$0,0.00")}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Redeemed Coupons</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>
                      {this.state.summary.coupon_redeemed_count} /{" "}
                      {Numeral(
                        this.state.summary.coupon_redeemed_amount
                      ).format("$0,0.00")}
                    </>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Returning Customers</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>{this.state.summary.returning_customer_count}</>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>New Registered Users</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>{this.state.summary.new_registered_user_count}</>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>New Guests</strong>
              </div>
              <div className="card-body text-center">
                <h5 className="card-title">
                  {this.state.loading && <Loading />}
                  {this.state.loading === false && (
                    <>{this.state.summary.new_guest_count}</>
                  )}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Day</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_date.labels && (
                      <Line data={sales_per_date_chart_data} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Category</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_menu.salesPerSection
                      .labels && (
                      <Bar
                        data={sales_per_section_chart_data}
                        options={{ barShowStroke: false }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Orders Per Hour</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.orders_per_hour.labels && (
                      <Bar
                        data={orders_per_hour_chart_data}
                        options={{ barShowStroke: false }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Hour</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_hour.labels && (
                      <Bar
                        data={sales_per_hour_chart_data}
                        options={{ barShowStroke: false }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Delivery Method</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_delivery_method.labels && (
                      <Pie data={sales_per_delivery_method_chart_data} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Channel</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_channel.labels && (
                      <Pie data={sales_per_channel_chart_data} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Promo</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_promo.labels && (
                      <Bar
                        data={sales_per_promo_chart_data}
                        options={{ barShowStroke: false }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Menu</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_menu.salesPerMenu.labels && (
                      <Pie data={sales_per_menu_chart_data} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Sales Per Payment Method</strong>
              </div>
              <div className="card-body text-center">
                {this.state.loading && <Loading />}
                {this.state.loading === false && (
                  <>
                    {this.state.summary.sales_per_payment_method.labels && (
                      <Pie data={sales_per_payment_method_chart_data} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Top 10 Selling Items</strong>
              </div>
              <div className="card-body text-center">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col-6" className="text-left">
                        Item
                      </th>
                      <th scope="col-3" className="text-right">
                        Quantity
                      </th>
                      <th scope="col-3" className="text-right">
                        Gross Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading && (
                      <tr>
                        <td colSpan="3">
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {this.state.loading === false && (
                      <>
                        {this.state.summary.best_selling_items.map(
                          (item, index) => (
                            <tr key={index}>
                              <td className="text-left">{item.name}</td>
                              <td className="text-right">{item.quantity}</td>
                              <td className="text-right">
                                {Numeral(item.amount).format("$0,0.00")}
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Top 10 Delivery Areas</strong>
              </div>
              <div className="card-body text-center">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col-6" className="text-left">
                        Postal Code
                      </th>
                      <th scope="col-3" className="text-right">
                        Number of Orders
                      </th>
                      <th scope="col-3" className="text-right">
                        Food Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading && (
                      <tr>
                        <td colSpan="3">
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {this.state.loading === false && (
                      <>
                        {this.state.summary.top_delivery_postal_codes.map(
                          (item, index) => (
                            <tr key={index}>
                              <td className="text-left">{item.postalCode}</td>
                              <td className="text-right">{item.orderCount}</td>
                              <td className="text-right">
                                {Numeral(item.orderAmount).format("$0,0.00")}
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 pb-3">
            <div className="card">
              <div className="card-header text-center">
                <strong>Top 10 Free/Discounted Items</strong>
              </div>
              <div className="card-body text-center">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col-6" className="text-left">
                        Item
                      </th>
                      <th scope="col-3" className="text-right">
                        Quantity
                      </th>
                      <th scope="col-3" className="text-right">
                        Gross Sales
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.loading && (
                      <tr>
                        <td colSpan="3">
                          <Loading />
                        </td>
                      </tr>
                    )}
                    {this.state.loading === false && (
                      <>
                        {this.state.summary.top_discounted_items.map(
                          (item, index) => (
                            <tr key={index}>
                              <td className="text-left">{item.name}</td>
                              <td className="text-right">{item.quantity}</td>
                              <td className="text-right">
                                {Numeral(item.amount).format("$0,0.00")}
                              </td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const restaurants = state.tenant.restaurants || [];
  return {
    tenant: state.tenant.tenant,
    restaurants: restaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
