import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

class Logout extends Component {
  componentWillMount() {
    this.props.logout();
  }

  render() {
    return (
      <div className="row h-100 align-items-center">
        <div className="col-12 alert alert-info text-center">
          <h2>You are safely loged out. See you next time!</h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);


