import React, { Component } from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

import "./OrderListView.css";

import Loading from "../Common/Loading";
class OrderListView extends Component {
  componentDidMount() {}

  renderRow(order, status, tenant, handleViewDetail) {
    const isNextDayOrder = moment(order.pickupTime)
      .tz(tenant.settings.time_zone)
      .isAfter(moment().tz(tenant.settings.time_zone).endOf("day"));

    const isPickupTimeBeforeThanReceivedAt = moment(order.pickupTime)
      .tz(tenant.settings.time_zone)
      .isBefore(moment(order.receivedAt).tz(tenant.settings.time_zone));

    const noDeliveryFee =
      order.deliveryMethod === "Delivery" && order.deliveryFee < 0.01;

    const textClassName = isNextDayOrder
      ? "text-warning"
      : isPickupTimeBeforeThanReceivedAt || noDeliveryFee || order.status === "Cancelled"
      ? "text-danger"
      : "";

    return (
      <div
        className="row clickable-row pt-3 pb-3 border-bottom"
        onClick={() => handleViewDetail(order)}
      >
        <div className="col-12 col-xl-2 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Order Number: </strong>{" "}
          </span>{" "}
          <span className={textClassName}>{order.orderNumber}</span>
        </div>
        <div className="col-12 col-xl-2 text-left text-xl-center">
          <span className="d-inline d-xl-none">
            {status && <strong>{`${status} At:`}</strong>}
            {!status && <strong>Status:</strong>}
          </span>
          <span className={textClassName}>
            {order.status === "Received"
              ? moment(order.receivedAt)
                  .tz(tenant.settings.time_zone)
                  .format(tenant.settings.datetime_format)
              : order.status === "Accepted"
              ? moment(order.confirmedAt)
                  .tz(tenant.settings.time_zone)
                  .format(tenant.settings.datetime_format)
              : order.status === "Fulfilled"
              ? moment(order.fulfilledAt)
                  .tz(tenant.settings.time_zone)
                  .format(tenant.settings.datetime_format)
              : order.status === "Rejected"
              ? moment(order.rejectedAt)
                  .tz(tenant.settings.time_zone)
                  .format(tenant.settings.datetime_format)
              : order.status}
          </span>
        </div>
        <div className="col-12 col-xl-3 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Customer/Phone: </strong>
          </span>
          <span className={textClassName}>
            {`${order.nameOrderedUnder ? order.nameOrderedUnder + " / " : ""}${
              order.phone
            }`}
          </span>
        </div>
        <div className="col-12 col-xl-1 text-left text-xl-right">
          <span className="d-inline d-xl-none">
            <strong>Order Amount: </strong>
          </span>
          <span className={textClassName}>
            {Numeral(order.total).format("$0,0.00")}
          </span>
        </div>

        <div className="col-12 col-xl-2 text-left text-xl-center">
          <span className="d-inline d-xl-none">
            <strong>Channel/Delivery Method: </strong>
          </span>

          {order.deliveryMethod === "Delivery" && (
            <span className={textClassName}>
              <strong>{`${order.channel}/${order.deliveryMethod}`}</strong>
            </span>
          )}
          {order.deliveryMethod === "Pickup" && (
            <span className={textClassName}>
              {`${order.channel}/${order.deliveryMethod}`}
            </span>
          )}
        </div>
        <div className="col-12 col-xl-2 text-left text-xl-center">
          <span className="d-inline d-xl-none">
            <strong>Pickup/Delivery Time: </strong>
          </span>
          {order.pickupTimeType && order.pickupTimeType === "ASAP" && (
            <span className={`${textClassName} font-weight-bold pickup-time`}>
              {order.pickupTimeType} /{" "}
            </span>
          )}
          <span className={`${textClassName} font-weight-bold pickup-time`}>
            {!order.adjustedPickupTime &&
              moment(order.pickupTime)
                .tz(tenant.settings.time_zone)
                .format(tenant.settings.datetime_format)}

            {order.adjustedPickupTime &&
              moment(order.adjustedPickupTime)
                .tz(tenant.settings.time_zone)
                .format(tenant.settings.datetime_format)}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      orders = [],
      handleViewDetail,
      tenant,
      status,
      isNextDayOrder = false,
      isSearchOrders = false,
    } = this.props;
    const totalOrders = orders.length;
    const totalAmount = orders.reduce(
      (accumulator, order) => accumulator + order.total,
      0
    );

    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="flex-row h-100 d-flex justify-content-between align-items-center">
                <div>
                  <h5 className={isNextDayOrder ? "text-warning" : ""}>
                    {`${
                      isSearchOrders
                        ? totalOrders
                        : isNextDayOrder
                        ? "Tomorrow"
                        : "Today"
                    } - `}
                    {this.props.loading === false && (
                      <strong
                        className={`${
                          isNextDayOrder
                            ? "text-secondary text-warning"
                            : "text-secondary"
                        }`}
                      >
                        {Numeral(totalAmount).format("$0,0.00")}
                      </strong>
                    )}
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-3 pb-3 border-bottom">
                <div className="col-xl-2 d-none d-xl-inline text-left">
                  <strong>Order Number</strong>
                </div>
                <div className="col-xl-2 d-none d-xl-inline text-center">
                  {status && <strong>{`${status} At`}</strong>}
                  {!status && <strong>Status</strong>}
                </div>
                <div className="col-xl-3 d-none d-xl-inline text-left">
                  <strong>Customer/Phone</strong>
                </div>
                <div className="col-xl-1 d-none d-xl-inline text-right">
                  <strong>Order Amt</strong>
                </div>
                <div className="col-xl-2 d-none d-xl-inline text-center">
                  <strong>Channel/Delivery Method</strong>
                </div>
                <div className="col-xl-2 d-none d-xl-inline text-center">
                  <strong>Pickup/Delivery Time</strong>
                </div>
              </div>
              {this.props.loading && <Loading />}
              {this.props.loading === false && (
                <>
                  {orders.map((order) => (
                    <React.Fragment key={order._id}>
                      {this.renderRow(order, status, tenant, handleViewDetail)}
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderListView;
