import React, { Component } from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { fetchEmployee } from "../../actions/employee";
import * as Icon from "react-feather";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import ChangeSubscription from "./ChangeSubscription";
import UpdateBillingProfile from "./UpdateBillingProfile";

const cookies = new Cookies();

class Profile extends Component {
  constructor(props) {
    super(props);

    this.handleViewProfile = this.handleViewProfile.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeSubscription = this.handleChangeSubscription.bind(this);
    this.handleUpdateBillingProfile =
      this.handleUpdateBillingProfile.bind(this);

    this.state = {
      mode: undefined,
    };
  }

  componentDidMount() {
    // Fetch user data prior to component mounting
    const userId = cookies.get("user")._id.toString();

    this.props.fetchWorker(userId);
  }

  handleUpdateProfile() {
    this.setState({ mode: "update-profile" });
  }

  handleChangePassword() {
    this.setState({ mode: "change-password" });
  }

  handleChangeSubscription() {
    this.setState({ mode: "change-subscription" });
  }

  handleUpdateBillingProfile() {
    this.setState({ mode: "update-billing-profile" });
  }

  handleViewProfile() {
    this.setState({ mode: undefined });
  }

  render() {
    const { user } = this.props;
    if (this.state.mode === "update-profile") {
      return <UpdateProfile handleViewProfile={this.handleViewProfile} />;
    }
    if (this.state.mode === "change-password") {
      return <ChangePassword handleViewProfile={this.handleViewProfile} />;
    }
    if (this.state.mode === "change-subscription") {
      return <ChangeSubscription handleViewProfile={this.handleViewProfile} />;
    }
    if (this.state.mode === "update-billing-profile") {
      return (
        <UpdateBillingProfile handleViewProfile={this.handleViewProfile} />
      );
    }

    if (!user) {
      return <>Nouser</>;
    } else {
      return (
        <>
          <div className="row pt-3 pb-6 mb-3 border-bottom">
            <div className="col-12">
              <h3>
                <strong>MY PROFILE</strong>
              </h3>
            </div>
          </div>
          <h4 className="mb-3">
            <Icon.User size={24} />
            {` `} {user.firstName + " " + user.lastName}
          </h4>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="jumbotron">
                <div className="row">
                  <div className="col-4">
                    <strong>{`User Name`}</strong>
                  </div>
                  <div className="col-8">
                    <span>{user.userName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <strong>{`First Name`}</strong>
                  </div>
                  <div className="col-8">
                    <span>{user.firstName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <strong>{`Last Name`}</strong>
                  </div>
                  <div className="col-8">
                    <span>{user.lastName}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <strong>{`Phone`}</strong>
                  </div>
                  <div className="col-8">{user.telephone}</div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <strong>{`Email`}</strong>
                  </div>
                  <div className="col-8">{user.email}</div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.handleUpdateProfile}
                    >
                      <Icon.Edit3 size={16} />
                      {` `}Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-12 col-sm-6 col-lg-4 mb-3">
              <div
                className="card clickable-card"
                onClick={this.handleChangePassword}
              >
                <div className="card-body text-center">
                  <i className="fa fa-lock fa-3x" />
                  <h5 className="card-title">{`Login & PIN`}</h5>
                  <p className="card-text">Password and 2-step verification</p>
                </div>
              </div>
            </div>
            {user.role === "Owner" && (
              <>
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <div
                    className="card clickable-card"
                    onClick={this.handleChangeSubscription}
                  >
                    <div className="card-body text-center">
                      <i className="fas fa-file-invoice fa-3x" />
                      <h5 className="card-title">{`Subscription`}</h5>
                      <p className="card-text">
                        Plan that currently subscribed
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <div
                    className="card clickable-card"
                    onClick={this.handleUpdateBillingProfile}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-money-check-alt fa-3x" />
                      <h5 className="card-title">{`Biilling Profile`}</h5>
                      <p className="card-text">
                        Billing address and tax nmbers
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-lg-4 mb-3">
                  <div
                    className="card clickable-card"
                    onClick={this.handleChangePaymentMethods}
                  >
                    <div className="card-body text-center">
                      <i className="fa fa-credit-card fa-3x" />
                      <h5 className="card-title">{`Payment Methods`}</h5>
                      <p className="card-text">
                        Credit cards and Paypal accounts
                      </p>
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.employee.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchWorker: (userId) => dispatch(fetchEmployee(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
