import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { changePassword } from "../../actions/employee";
import PropTypes from "prop-types";
import { renderPasswordField } from "../../utils/renderFields";

const form = reduxForm({
  form: "changePassword",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.currentPassword) {
    errors.currentPassword = "Please enter current password";
  }

  if (!formProps.newPassword) {
    errors.newPassword = "Please enter new password";
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = "Please confirm new password";
  }

  if (formProps.newPassword !== formProps.passwordConfirm) {
    errors.passwordConfirm = "Passwords must match";
  }

  return errors;
}

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  static contextTypes = {
    router: PropTypes.object,
  };

  handleFormSubmit({ currentPassword, newPassword }) {
    this.props.changePassword(
      this.props.user._id,
      {
        currentPassword,
        newPassword,
      },
      () => {
        this.props.handleViewProfile();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="alert alert-success">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleViewProfile, handleSubmit, pristine, submitting } =
      this.props;

    return (
      <>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>CHANGE PASSWORD</strong>
            </h3>
          </div>
        </div>
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
          {this.renderAlert()}
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Current Password
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="currentPassword"
                component={renderPasswordField}
                type="password"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              New Password
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="newPassword"
                component={renderPasswordField}
                type="password"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Confirm New Password
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="passwordConfirm"
                component={renderPasswordField}
                type="password"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewProfile}
              >
                {`Cancel`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {`Save`}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.employee.error,
    message: state.employee.message,
    user: state.employee.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePassword: (userId, { currentPassword, newPassword }, callback) =>
    dispatch(changePassword(userId, { currentPassword, newPassword }, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ChangePassword));
