import React, { Component } from "react";

class AllowedDeliveryMethod extends Component {
  render() {
    const {
      allowedDeliveryMethod,
      handleAllowedDeliveryMethodChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Allowed delivery method:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_delivery_method"
              value="Any type"
              onChange={handleAllowedDeliveryMethodChange}
              checked={allowedDeliveryMethod === "Any type"}
            />
            <label className="form-check-label">{`Any type (Default)`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_delivery_method"
              value="Pickup"
              onChange={handleAllowedDeliveryMethodChange}
              checked={allowedDeliveryMethod === "Pickup"}
            />
            <label className="form-check-label">{`Pickup`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_delivery_method"
              value="Delivery"
              onChange={handleAllowedDeliveryMethodChange}
              checked={allowedDeliveryMethod === "Delivery"}
            />
            <label className="form-check-label">{`Delivery`}</label>
          </div>
        </div>
      </div>

    );
  }
}

export default AllowedDeliveryMethod;