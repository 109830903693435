import {
  FETCH_MENUS,
  FETCH_ARCHIVED_MENUS,
  FETCH_MENU,
  FETCH_MENU_SECTION,
  FETCH_MENU_ITEM,
  UPDATE_MENU_INFO,
  UPDATE_MENU_SECTION_INFO,
  UPDATE_MENU_ITEM_INFO,
  MENU_ERROR,
  ADD_MENU,
  ADD_MENU_SECTION,
  ADD_MENU_ITEM,
  ADD_MENU_ITEM_SIZE,
  UPDATE_MENU_ITEM_SIZE,
  FETCH_MENU_ITEM_SIZE,
  REMOVE_MENU_ITEM_SIZE,
  ADD_MENU_ITEM_ITEM,
  UPDATE_MENU_ITEM_ITEM,
  FETCH_MENU_ITEM_ITEM,
  REMOVE_MENU_ITEM_ITEM,
  ADD_MENU_ITEM_OPTION,
  UPDATE_MENU_ITEM_OPTION,
  FETCH_MENU_ITEM_OPTION,
  REMOVE_MENU_ITEM_OPTION,
  ARCHIVE_MENU,
  UNARCHIVE_MENU,
  ARCHIVE_MENU_ITEM,
  UNARCHIVE_MENU_ITEM,
  ARCHIVE_MENU_SECTION,
  UNARCHIVE_MENU_SECTION,
  VIEW_MENUS,
  VIEW_MENU,
  MOVE_MENU_ITEM,
  ADD_MENU_ITEM_PHOTO,
  UPDATE_MENU_ITEM_PHOTO,
  REMOVE_MENU_ITEM_PHOTO,
  UPDATE_MENU_SECTION_PHOTO,
  UPDATE_MENU_PHOTO,
  REMOVE_MENU_PHOTO,
  REMOVE_MENU_SECTION_PHOTO,
  UPDATE_ASSIGNED_LOCATIONS,
  UPDATE_MENU_SECTION_ASSIGNED_LOCATIONS,
  MARK_MENU_ITEM_AS_AVAILABLE,
  MARK_MENU_ITEM_AS_SOLD_OUT,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  menus: [],
  menu: undefined,
  menuSection: undefined,
  menuItem: undefined,
  archivedmenus: [],
  menuItemPhotos: [],
  menuSectionPhotoUrl: undefined,
  menuPhotoUrl: undefined,
  menuSections: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIEW_MENUS:
      return { ...state, menu: undefined };
    case VIEW_MENU:
      return { ...state, menuSection: undefined };
    case FETCH_MENUS:
      return { ...state, menus: action.payload.menus };
    case FETCH_ARCHIVED_MENUS:
      return { ...state, archivedItems: action.payload.archived_items };
    case FETCH_MENU:
      return { ...state, menu: action.payload.menu };
    case FETCH_MENU_SECTION:
      return { ...state, menuSection: action.payload.menuSection };
    case FETCH_MENU_ITEM:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemPhotos: action.payload.menuItem.photos,
      };
    case FETCH_MENU_ITEM_SIZE:
      return { ...state, menuItemSize: action.payload.menuItemSize };
    case FETCH_MENU_ITEM_ITEM:
      return { ...state, menuItemITem: action.payload.menuItemItem };
    case FETCH_MENU_ITEM_OPTION:
      return { ...state, menuItemOption: action.payload.menuItemOption };
    case UPDATE_MENU_INFO:
      return {
        ...state,
        menu: action.payload.menu,
        menus: state.menus.map((menu) => {
          if (menu._id === action.payload.menu._id) {
            return action.payload.menu;
          } else {
            return menu;
          }
        }),
      };
    case UPDATE_ASSIGNED_LOCATIONS:
      return {
        ...state,
        menu: action.payload.menu,
        menus: state.menus.map((menu) => {
          if (menu._id === action.payload.menu._id) {
            return action.payload.menu;
          } else {
            return menu;
          }
        }),
      };
    case ARCHIVE_MENU:
      return {
        ...state,
        menus: state.menus.filter(
          (menu) => menu._id !== action.payload.menu._id
        ),
        archivedItems: {
          ...state.archivedItems,
          menus: [...state.archivedItems.menus, action.payload.menu],
        },
      };
    case UNARCHIVE_MENU:
      return {
        ...state,
        menus: [...state.menus, action.payload.menu],
        archivedItems: {
          ...state.archivedItems,
          menus: state.archivedItems.menus.filter(
            (menu) => menu._id !== action.payload.menu._id
          ),
        },
      };
    case ARCHIVE_MENU_SECTION:
      return {
        ...state,
        // menus: state.menus.filter(menu => menu._id !== action.payload.menu._id),
        archivedItems: {
          ...state.archivedItems,
          sections: [...state.archivedItems.sections, action.payload.section],
        },
      };
    case UNARCHIVE_MENU_SECTION:
      return {
        ...state,
        // menus: [...state.menus, action.payload.menu],
        archivedItems: {
          ...state.archivedItems,
          sections: state.archivedItems.sections.filter(
            (section) => section._id !== action.payload.section._id
          ),
        },
      };
    case ARCHIVE_MENU_ITEM:
      return {
        ...state,
        // menus: state.menus.filter(menu => menu._id !== action.payload.menu._id),
        archivedItems: {
          ...state.archivedItems,
          items: [...state.archivedItems.items, action.payload.item],
        },
      };
    case UNARCHIVE_MENU_ITEM:
      return {
        ...state,
        // menus: [...state.menus, action.payload.menu],
        archivedItems: {
          ...state.archivedItems,
          items: state.archivedItems.items.filter(
            (item) => item._id !== action.payload.item._id
          ),
        },
      };
    case UPDATE_MENU_SECTION_INFO:
      return { ...state, menuSection: action.payload.menuSection };
    case UPDATE_MENU_ITEM_INFO:
      return { ...state, menuItem: action.payload.menuItem };
    case MARK_MENU_ITEM_AS_AVAILABLE:
      return { ...state, menuItem: action.payload.menuItem };
    case MARK_MENU_ITEM_AS_SOLD_OUT:
      return { ...state, menuItem: action.payload.menuItem };
    case MOVE_MENU_ITEM:
      return { ...state, menuItem: action.payload.menuItem };
    case MENU_ERROR:
      return { ...state, errorMessage: action.payload };
    case ADD_MENU:
      let menu = action.payload.menu;
      menu.sections = [];
      return { ...state, menus: [...state.menus, menu] };
    case ADD_MENU_SECTION:
      let menuSection = action.payload.menuSection;
      menuSection.items = [];
      return {
        ...state,
        message: action.payload.message,
        menu: {
          ...state.menu,
          sections: [...state.menu.sections, menuSection],
        },
        menus: state.menus.map((menu) => {
          if (menu._id === state.menu._id) {
            return {
              ...state.menu,
              sections: [...state.menu.sections, menuSection],
            };
          } else {
            return menu;
          }
        }),
      };
    case ADD_MENU_ITEM:
      return {
        ...state,
        menuSection: {
          ...state.menuSection,
          items: [...state.menuSection.items, action.payload.menuItem],
        },
      };
    case ADD_MENU_ITEM_SIZE:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemSize: undefined,
      };
    case UPDATE_MENU_ITEM_SIZE:
      return {
        ...state,
        menuItemSize: action.payload.menuItemSize,
        menuItem: action.payload.menuItem,
      };
    case REMOVE_MENU_ITEM_SIZE:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemSize: undefined,
      };
    case ADD_MENU_ITEM_PHOTO:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemPhoto: undefined,
        menuItemPhotos: [
          ...state.menuItemPhotos,
          action.payload.addedMenuItemPhoto,
        ],
      };
    case UPDATE_MENU_ITEM_PHOTO:
      return {
        ...state,
        menuItemPhoto: action.payload.menuItemPhoto,
        menuItem: action.payload.menuItem,
      };
    case REMOVE_MENU_ITEM_PHOTO:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemPhoto: undefined,
        menuItemPhotos: state.menuItemPhotos.filter(
          (photo) => photo._id !== action.payload.removedMenuItemPhoto._id
        ),
      };
    case ADD_MENU_ITEM_ITEM:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemItem: undefined,
      };
    case UPDATE_MENU_ITEM_ITEM:
      return {
        ...state,
        menuItemItem: action.payload.menuItemItem,
        menuItem: action.payload.menuItem,
      };
    case REMOVE_MENU_ITEM_ITEM:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemITEM: undefined,
      };
    case ADD_MENU_ITEM_OPTION:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemOption: undefined,
      };
    case UPDATE_MENU_ITEM_OPTION:
      return {
        ...state,
        menuItemOption: action.payload.menuItemOption,
        menuItem: action.payload.menuItem,
      };
    case REMOVE_MENU_ITEM_OPTION:
      return {
        ...state,
        menuItem: action.payload.menuItem,
        menuItemOption: undefined,
      };
    case UPDATE_MENU_SECTION_PHOTO:
      return {
        ...state,
        menuSection: {
          ...state.menuSection,
          image: {
            url: action.payload.photoUrl,
          },
        },
        menuSectionPhotoUrl: action.payload.photoUrl,
      };
    case UPDATE_MENU_PHOTO:
      return {
        ...state,
        menu: {
          ...state.menu,
          image: {
            url: action.payload.photoUrl,
          },
        },
        menuPhotoUrl: action.payload.photoUrl,
      };
    case REMOVE_MENU_SECTION_PHOTO:
      return {
        ...state,
        menuSection: {
          ...state.menuSection,
          image: {},
        },
        menuSectionPhotoUrl: undefined,
      };
    case REMOVE_MENU_PHOTO:
      return {
        ...state,
        menu: {
          ...state.menu,
          image: {},
        },
        menuPhotoUrl: undefined,
      };
      case UPDATE_MENU_SECTION_ASSIGNED_LOCATIONS:
      return {
        ...state,
        menuSection: action.payload.menuSection,
      };
    default:
      return state;
  }
}
