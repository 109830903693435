import moment from "moment-timezone";
import Numeral from "numeral";

import { isSame } from "../../utils/equalUtil";

export const printOrder = (order, coupon, restaurant, tenant, autoPrint, kitchenReceipt, customerReceipt) => {
  const printerIpAddress = restaurant.settings.printing_settings.printer_ip_address;
  const printerPortNumber = restaurant.settings.printing_settings.printer_port_number;
  const printerId = restaurant.settings.printing_settings.printer_id;

  let _kitchenReceipt = false;
  let _customerReceipt = false;
  if (order.channel === 'Online') {
    if (order.deliveryMethod === 'Pickup') {
      if (_kitchenReceipt === false) {
        _kitchenReceipt = restaurant.settings.printing_settings.print_kitchen_receipt_after_accept_pickup_online_order;
      }
      if (_customerReceipt === false) {
        _customerReceipt = restaurant.settings.printing_settings.print_customer_receipt_after_accept_pickup_online_order;
      }

    }
    if (order.deliveryMethod === 'Delivery') {
      if (_kitchenReceipt === false) {
        _kitchenReceipt = restaurant.settings.printing_settings.print_kitchen_receipt_after_accept_delivery_online_order;
      }
      if (_customerReceipt === false) {
        _customerReceipt = restaurant.settings.printing_settings.print_customer_receipt_after_accept_delivery_online_order;
      }

    }
  }
  if (order.channel === 'CallIn') {
    if (order.deliveryMethod === 'Pickup') {
      if (_kitchenReceipt === false) {
        _kitchenReceipt = restaurant.settings.printing_settings.print_kitchen_receipt_after_accept_pickup_call_in_order;
      }
      if (_customerReceipt === false) {
        _customerReceipt = restaurant.settings.printing_settings.print_customer_receipt_after_accept_pickup_call_in_order;
      }

    }
    if (order.deliveryMethod === 'Delivery') {
      if (_kitchenReceipt === false) {
        _kitchenReceipt = restaurant.settings.printing_settings.print_kitchen_receipt_after_accept_delivery_call_in_order;
      }
      if (_customerReceipt === false) {
        _customerReceipt = restaurant.settings.printing_settings.print_customer_receipt_after_accept_delivery_call_in_order;
      }

    }
  }

  let printer = null;

  const ePosDev = new window.epson.ePOSDevice();

  ePosDev.connect(printerIpAddress, printerPortNumber, cbConnect);

  function cbConnect(resultConnect) {
    if (resultConnect === 'OK' || resultConnect === 'SSL_CONNECT_OK') {
      ePosDev.createDevice(printerId, ePosDev.DEVICE_TYPE_PRINTER, { 'crypto': true, 'buffer': false }, cbCreateDevice_printer);
    } else {
      alert(resultConnect);
    }
  }
  function cbCreateDevice_printer(devobj, retcode) {
    if (retcode === 'OK') {
      printer = devobj;
      if ((autoPrint === false && kitchenReceipt) || (autoPrint === true && _kitchenReceipt)) {
        printKitchenReceipt();
      }
      if ((autoPrint === false && customerReceipt) || (autoPrint === true && _customerReceipt)) {
        printCustomerReceipt();
      }
    } else {
      alert(retcode);
    }
  }

  function printKitchenReceipt() {

    let items = [];
    if (order.items) {
      order.items.forEach(item => {
        items.push(Object.assign({}, item))
      })
    }
    if (order.promotions) {
      order.promotions.forEach(promo => {
        promo.discountedItems.forEach(item1 => {
          let found = false;
          items.forEach(item2 => {
            if (isSame(item2, item1)) {
              item2.quantity += item1.quantity;
              found = true;
            }
          });
          if (!found) {
            items.push(Object.assign({}, item1));
          }
        })
      })
    }

    const startAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime).tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)}` : `${moment(order.pickupTime)
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`;

    const endAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
        .add((order.deliveryMethod === 'Pickup' ? 1 : 2) * restaurant.settings.pickup_time_interval_in_minutes, 'minutes')
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
        .add((order.deliveryMethod === 'Pickup' ? 1 : 2) * restaurant.settings.pickup_time_interval_in_minutes, 'minutes')
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`;

    const pickupTimeRange = `${startAt} - ${endAt}`;

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextSize(1, 2);
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    printer.addText(`${order.deliveryMethod} `);
    printer.addTextSize(1, 1);
    printer.addTextStyle(false, false, false, printer.COLOR_1);
    printer.addText(`Time:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${pickupTimeRange}\n`);
    printer.addTextStyle(false, false, false, printer.COLOR_1);

    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Order #:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${order.orderNumber}\n`);

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Phone:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${order.phone}\n`);

    printer.addTextAlign(printer.ALIGN_LEFT);
    if (order.nameOrderedUnder) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`Name:\r`);
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText(`${order.nameOrderedUnder}\n`);
    }
    printer.addText(`---------------------------------\n`);

    if (order.specialRequests) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addTextStyle(false, false, true, printer.COLOR_2);

      printer.addText(`${order.specialRequests}\n`);

      printer.addText(`---------------------------------\n`);
    }
    printer.addTextStyle(false, false, false, printer.COLOR_1);

    let totalQuantity = 0;
    items.forEach(item => {
      printer.addTextLineSpace(15);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText(`${item.quantity}`);
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addText(`X `);
      if (item.cd) {
        printer.addText(`${item.cd}. `);
      }
      printer.addText(`${item.printName ? item.printName : item.name}\n`);
      if (item.size) {
        printer.addTextLineSpace(15);
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, false, printer.COLOR_2);
        printer.addText(`       ${item.size}\n`);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
      }
      if (item.options) {
        item.options.forEach(option => {
          printer.addTextLineSpace(15);
          printer.addTextAlign(printer.ALIGN_LEFT);
          printer.addTextStyle(false, false, false, printer.COLOR_2);
          printer.addText(`       ${option.value ? option.value : option.name}\n`);
          printer.addTextStyle(false, false, false, printer.COLOR_1);
        });
        printer.addTextStyle(false, false, false, printer.COLOR_1);
      }
      
      if (item.specialInstructions && item.specialInstructions.filter(si => !!si).length > 0) {
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, false, printer.COLOR_2);
        printer.addText(`       ${item.specialInstructions.filter(si => !!si).join("; ")}\n`);
      }

      printer.addTextStyle(false, false, false, printer.COLOR_1);

      totalQuantity += item.quantity
      printer.addTextLineSpace(5);
      printer.addText(`\n`);
    });

    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);
    printer.addText(`Total Quantity\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addTextStyle(false, false, false, printer.COLOR_2);
    printer.addText(`${totalQuantity}\n`);
    printer.addTextStyle(false, false, false, printer.COLOR_1);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Total\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${Numeral(order.total).format("$0,0.00")}\n`);

    printer.addCut(printer.CUT_FEED);

    printer.send();
  }


  function printCustomerReceipt() {
    const startAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime).tz(restaurant.settings.time_zone).format(restaurant.settings.time_format)}` : `${moment(order.pickupTime)
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`;

    const endAt = order.adjustedPickupTime
      ? `${moment(order.adjustedPickupTime)
        .add((order.deliveryMethod === 'Pickup' ? 1 : 2) * restaurant.settings.pickup_time_interval_in_minutes, 'minutes')
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`
      : `${moment(order.pickupTime)
        .add((order.deliveryMethod === 'Pickup' ? 1 : 2) * restaurant.settings.pickup_time_interval_in_minutes, 'minutes')
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.time_format)}`;

    const pickupTimeRange = `${startAt} - ${endAt}`;

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addTextStyle(false, false, true, printer.COLOR_1);
    printer.addTextSize(1, 2);
    printer.addText(`${order.deliveryMethod} `);
    printer.addTextStyle(false, false, false, printer.COLOR_1);
    printer.addTextSize(1, 1);
    printer.addText(`Time:\r`);

    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${pickupTimeRange}\n`);

    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);

    printer.addTextLineSpace(15);
    if (order.deliveryMethod === "Delivery" && order.deliveryAddress.address) {
      printer.addText(`Address: ${order.deliveryAddress.address}\n`);
      if (order.deliveryAddress.suiteApartmentNumber) {
        printer.addTextLineSpace(10);
        printer.addText(`SuiteApartment#: ${order.deliveryAddress.suiteApartmentNumber}\n`);
      }
      if (order.deliveryAddress.businessName) {
        printer.addTextLineSpace(10);
        printer.addText(`Business Name: ${order.deliveryAddress.businessName}\n`);
      }
      if (order.deliveryAddress.otherVenueName) {
        printer.addTextLineSpace(10);
        printer.addText(`Other Venue: ${order.deliveryAddress.otherVenueName}\n`);
      }

      printer.addTextLineSpace(15);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`---------------------------------\n`);
    }


    printer.addTextLineSpace(15);

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Order #:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${order.orderNumber}\n`);

    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Phone:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${order.phone}\n`);

    if (order.nameOrderedUnder) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`Name:\r`);
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText(`${order.nameOrderedUnder}\n`);
    }

    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);
    
    if (order.specialRequests) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addTextStyle(false, false, true, printer.COLOR_2);

      printer.addText(`${order.specialRequests}\n`);
      printer.addTextLineSpace(15);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`---------------------------------\n`);
    }
    printer.addTextStyle(false, false, false, printer.COLOR_1);

    order.items.forEach(item => {
      printer.addTextLineSpace(15);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addTextStyle(false, false, true, printer.COLOR_1);
      printer.addText(`${item.quantity}X `);
      printer.addTextStyle(false, false, false, printer.COLOR_1);
      if (item.cd) {
        printer.addText(`${item.cd}. `);
      }
      printer.addText(`${item.printName ? item.printName : item.name}\n`);

      if (item.size) {
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, false, printer.COLOR_2);
        printer.addText(`       ${item.size}\n`);
      }
      if (item.options) {
        item.options.forEach(option => {

          printer.addTextAlign(printer.ALIGN_LEFT);
          printer.addTextStyle(false, false, false, printer.COLOR_2);
          printer.addText(`       ${option.value ? option.value : option.name}\n`);
        });
      }

      if (item.specialInstructions && item.specialInstructions.filter(si => !!si).length > 0) {
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, false, printer.COLOR_2);
        printer.addText(`       ${item.specialInstructions.filter(si => !!si).join("; ")}\n`);
      }

      printer.addTextStyle(false, false, false, printer.COLOR_1);
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText(`${Numeral(item.baseTotal + item.extraTotal).format("$0,0.00")}\n`);
    });

    printer.addTextLineSpace(15);
    printer.addTextStyle(false, false, false, printer.COLOR_1);

    if (order.promotions) {
      order.promotions.forEach(promotion => {
        printer.addTextLineSpace(15);
        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addTextStyle(false, false, true, printer.COLOR_1);
        printer.addText(`${promotion.quantity}`);
        printer.addTextStyle(false, false, false, printer.COLOR_1);
        printer.addText(`X `);
        printer.addText(`${promotion.promotionName}\n`);

        printer.addTextAlign(printer.ALIGN_LEFT);
        printer.addText(`You saved: ${Numeral(promotion.discountAmount).format(
          "$0,0.00"
        )}\n`);

        printer.addTextAlign(printer.ALIGN_RIGHT);
        printer.addText(`${Numeral(promotion.amount).format(
          "$0,0.00"
        )}\n`);

        if (promotion.discountedItems) {
          promotion.discountedItems.forEach(discountedItem => {
            printer.addTextLineSpace(15);
            printer.addTextAlign(printer.ALIGN_LEFT);

            printer.addText(`${promotion.quantity}X `);
            if (discountedItem.cd) {
              printer.addText(`${discountedItem.cd}. `);
            }
            printer.addText(`${discountedItem.printName ? discountedItem.printName : discountedItem.name}\n`);
            if (discountedItem.size) {
              printer.addTextLineSpace(15);
              printer.addTextAlign(printer.ALIGN_LEFT);
              printer.addText(`       ${discountedItem.size}\n`);
            }
            if (discountedItem.options) {
              discountedItem.options.forEach(option => {
                printer.addTextLineSpace(15);
                printer.addTextAlign(printer.ALIGN_LEFT);
                printer.addTextStyle(false, false, false, printer.COLOR_2);
                printer.addText(`       ${option.value ? option.value : option.name}\n`);
                printer.addTextStyle(false, false, false, printer.COLOR_1);
              });
            }
            printer.addTextAlign(printer.ALIGN_RIGHT);
            printer.addText(`${Numeral(discountedItem.baseTotal + discountedItem.extraTotal).format(
              "$0,0.00"
            )}\n`);

            if (discountedItem.discountAmount > 0) {
              printer.addTextAlign(printer.ALIGN_RIGHT);
              printer.addText(`${Numeral(-1 * discountedItem.discountAmount).format(
                "$0,0.00"
              )}\n`);
            }
          });
        }
      });
    }

    printer.addTextLineSpace(15);

    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);
    printer.addTextLineSpace(15);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Subtotal:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${Numeral(order.subtotal).format("$0,0.00")}\n`);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`GST:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${Numeral(order.gst).format("$0,0.00")}\n`);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`PST:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${Numeral(order.pst).format("$0,0.00")}\n`);
    if (order.deliveryFee) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`Delivery Fee:\r`);
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText(`${Numeral(order.deliveryFee).format("$0,0.00")}\n`);
    }
    if (order.deliveryGST) {
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`Delivery GST:\r`);
      printer.addTextAlign(printer.ALIGN_RIGHT);
      printer.addText(`${Numeral(order.deliveryGST).format("$0,0.00")}\n`);
    }
    if (order.discounts) {
      order.discounts
        .filter(discount => discount.isNextOrderCoupon === false)
        .forEach(discount => {
          printer.addTextAlign(printer.ALIGN_LEFT);
          printer.addText(`Coupon:\r`);
          printer.addTextAlign(printer.ALIGN_RIGHT);
          printer.addText(`${Numeral(-1 * discount.amount).format("$0,0.00")}\n`);
        });
    }
    if (order.redeemedGiftCards) {
      order.redeemedGiftCards
        .forEach(redeemedGiftCard => {
          printer.addTextAlign(printer.ALIGN_LEFT);
          printer.addText(`Gift Card: ${redeemedGiftCard.giftCardNumber}\r`);
          printer.addTextAlign(printer.ALIGN_RIGHT);
          printer.addText(`${Numeral(-1 * redeemedGiftCard.amountToRedeem).format("$0,0.00")}\n`);
        });
    }
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`Total:\r`);
    printer.addTextAlign(printer.ALIGN_RIGHT);
    printer.addText(`${Numeral(order.total).format("$0,0.00")}\n`);
    printer.addTextAlign(printer.ALIGN_LEFT);
    printer.addText(`---------------------------------\n`);
    if (coupon) {
      // printer.addText(`\n`);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`${coupon.name}. Code: ${coupon.couponCode.toUpperCase()}. Expires: ${moment(coupon.validUntil)
        .tz(restaurant.settings.time_zone)
        .format(restaurant.settings.date_format)}\n`);
      printer.addTextAlign(printer.ALIGN_LEFT);
      printer.addText(`---------------------------------\n`);
    }


    if (tenant.website_url) {
      printer.addTextAlign(printer.ALIGN_CENTER);
      printer.addText(`Order Online\n`);
      printer.addText(`${tenant.website_url}\n`);
    }
    printer.addTextAlign(printer.ALIGN_CENTER);
    printer.addText(`${restaurant.name}\n`);
    printer.addText(`${restaurant.address_1}, ${restaurant.city_town}\n`);
    printer.addText(`${restaurant.phone}\n`);
    printer.addText(`GST#: ${restaurant.settings.gst_number}\n`);

    printer.addCut(printer.CUT_FEED);

    printer.send();
  }
}