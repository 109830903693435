import React, { Component } from "react";
import { connect } from "react-redux";
import Numeral from "numeral";
import { fetchMenuItem } from "../../actions/menu";
import MenuItemTags from "./MenuItemTags";
import * as Icon from "react-feather";

class MenuItemInfoCard extends Component {

  componentDidMount() {
    if (
      this.props.menu !== undefined &&
      this.props.menuSection !== undefined &&
      this.props.menuItem !== undefined
    ) {
      this.props.fetchMenuItem(
        this.props.menu._id,
        this.props.menuSection._id,
        this.props.menuItem._id
      );
    }
  }

  render() {
    const {  menuItem, handleEditMenuItem } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Item Info</h5>
            </div>
            <div className="col-6 text-right">
              <button
                type="button"
                className="btn btn-light mr-2"
                onClick={() => handleEditMenuItem(menuItem)}
              >
                <Icon.Edit2 size={16} />
                {` `}
                Edit
              </button>

            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row">
            {/* <div className="col-12 col-md-3">
              {menuItem && menuItem.image && menuItem.image.url &&
                <img
                  className="img-fluid"
                  src={process.env.REACT_APP_API_BASE + `/api/eatery/images?imageUrl=${encodeURIComponent(tenant.slug + '/menu/' + menuItem.image.url)}`}
                  alt="Menu Item"
                />
              }
            </div> */}
            <div className="col-12 text-center col-md-9 text-md-left">
              {menuItem && <div>{menuItem.cd} {menuItem.cd ? '.' : ''} {menuItem.name}</div>}
              {menuItem && <small className="text-muted">{menuItem.description}</small>}
              {menuItem && menuItem.tags && (
                <MenuItemTags tags={menuItem.tags} />
              )}
            </div>
            <div className="col-12 text-center col-md-3 text-md-right">
              {menuItem && (
                <p className="card-text">
                  {menuItem.price && (
                    <small className="text-muted">
                      <strong>{Numeral(menuItem.price).format("$0,0.00")}</strong>
                      {` `}
                    </small>
                  )}

                  {menuItem.sizes &&
                    menuItem.sizes.map(itemSize => (
                      <small key={itemSize._id} className="text-muted">
                        <strong>{itemSize.name} :{` `}
                          {Numeral(itemSize.price).format("$0,0.00")}
                          {` `}</strong>
                      </small>
                    ))}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let tag_spicy = undefined;
  let tag_gluten_free = undefined;
  let tag_special = undefined;
  let tag_vegetarian = undefined;
  let tag_new = undefined;
  if (ownProps.menuItem && ownProps.menuItem.tags) {
    tag_spicy = ownProps.menuItem.tags.includes("spicy_mild")
      ? "spicy_mild"
      : ownProps.menuItem.tags.includes("spicy_medium")
        ? "spicy_medium"
        : ownProps.menuItem.tags.includes("spicy_hot")
          ? "spicy_hot"
          : undefined;
    tag_gluten_free = ownProps.menuItem.tags.includes("gluten_friendly");
    tag_special = ownProps.menuItem.tags.includes("special");
    tag_vegetarian = ownProps.menuItem.tags.includes("vegetarian");
    tag_new = ownProps.menuItem.tags.includes("new");
  }
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: {
      ...ownProps.menuItem,
      tag_spicy: tag_spicy,
      tag_gluten_free: tag_gluten_free,
      tag_special: tag_special,
      tag_vegetarian: tag_vegetarian,
      tag_new: tag_new
    },
    menuItem: ownProps.menuItem
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemInfoCard);
