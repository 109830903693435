import React, { Component } from "react";
import { connect } from "react-redux";

import { deleteGallery } from "../../actions/tenant";

import * as Icon from "react-feather";

import AddGallery from "./AddGallery";
import EditGallery from "./EditGallery";

class GallerySettings extends Component {
  constructor(props) {
    super(props);

    this.handleAddGallery = this.handleAddGallery.bind(this);
    this.handleEditGallery = this.handleEditGallery.bind(this);
    this.handleDeleteGallery = this.handleDeleteGallery.bind(this);
    this.handleViewGallerySettings = this.handleViewGallerySettings.bind(this);

    this.state = {
      mode: undefined
    };
  }

  componentDidMount() { }

  handleViewGallerySettings() {
    this.setState({ mode: undefined });
  }

  handleAddGallery() {
    this.setState({ mode: "Add" });
  }

  handleEditGallery(selectedGallery) {
    this.setState({ mode: "Edit", selectedGallery: selectedGallery });
  }

  handleDeleteGallery(selectedGallery) {
    this.props.deleteGallery(
      this.props.tenant._id,
      selectedGallery._id,
      () => {
        this.handleViewGallerySettings();
      }
    );
  }


  render() {
    const {
      gallery,
      tenant,
    } = this.props;

    if (this.state.mode === "Add") {
      return (
        <AddGallery
          tenant={this.state.selectedTenant}
          handleViewGallerySettings={this.handleViewGallerySettings}
        />
      );
    }
    if (this.state.mode === "Edit") {
      return (
        <EditGallery
          tenant={this.state.selectedTenant}
          gallery_item={this.state.selectedGallery}

          handleViewGallerySettings={this.handleViewGallerySettings}
        />
      );
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => window.location.href = "/settings"}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="far fa-images" />
                {` `}
                <span className="d-none d-sm-inline">{`Gallery`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="h-100">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h3 className="d-none d-sm-inline">Gallery</h3>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group mr-2">
                <button className="btn btn-primary" onClick={() => this.handleAddGallery()}>
                  <Icon.Plus size={16} />
                  <span className="d-none d-lg-inline"> {` `}New Item</span>
                </button>
                {/* {isOwner() && <AddGallery tenant={tenant} />} */}
              </div>
            </div>
          </div>
          <div className="mt-3 row row-cols-1 row-cols-md-6">
            {gallery.map((gallery_item) => (
              <div className="col mb-4 text-center" key={gallery_item._id}>
                {gallery_item.image && gallery_item.image.url && (
                  <img
                    className="card-img-top img-fluid"
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${tenant.slug}/${gallery_item.image.url}`
                    }
                    alt="Menu Item"
                  />
                )}

                <div className="card-body text-center">
                  <p
                    className="text-truncate"
                    data-toggle="tooltip"
                    title={gallery_item.caption}
                  >
                    {gallery_item.caption}
                  </p>
                  <button
                    type="button"
                    className="btn btn-secondary mr-3"
                    onClick={() => this.handleEditGallery(gallery_item)}
                  >
                    <Icon.Edit2 size={16} />
                    {/* {` `}
                  <span className="d-inline d-sm-none d-lg-inline">Delete</span> */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => this.handleDeleteGallery(gallery_item)}
                  >
                    <Icon.Trash size={16} />
                    {/* {` `}
                  <span className="d-inline d-sm-none d-lg-inline">Delete</span> */}
                  </button>

                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, onwProps) => {
  return {
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    gallery: state.tenant.tenant?.gallery || [],
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteGallery: (tenantId, galleryId, callback) =>
    dispatch(deleteGallery(tenantId, galleryId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GallerySettings);
