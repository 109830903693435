import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import { updateSettings } from "../../actions/restaurant";

import * as Icon from "react-feather";

const form = reduxForm({
  form: "descriptionSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class DescriptionSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleRemovePickupOrderSubtitle =
      this.handleRemovePickupOrderSubtitle.bind(this);
    this.handleRemoveDeliveryOrderSubtitle =
      this.handleRemoveDeliveryOrderSubtitle.bind(this);

    this.state = {
      pickup_order_title:
        props.restaurant.settings.descriptions?.pickup_order?.title,
      pickup_order_subtitle: "",
      pickup_order_subtitles:
        props.restaurant.settings.descriptions?.pickup_order?.subtitles || [],
      delivery_order_title:
        props.restaurant.settings.descriptions?.delivery_order?.title,
      delivery_order_subtitle: "",
      delivery_order_subtitles:
        props.restaurant.settings.descriptions?.delivery_order?.subtitles || [],
    };
  }

  componentDidMount() {}

  handleRemovePickupOrderSubtitle(subtitle) {
    this.setState((prevState) => {
      return {
        pickup_order_subtitles: [
          ...prevState.pickup_order_subtitles.filter(
            (subtitle1) => subtitle !== subtitle1
          ),
        ],
      };
    });
  }

  handleRemoveDeliveryOrderSubtitle(subtitle) {
    this.setState((prevState) => {
      return {
        delivery_order_subtitles: [
          ...prevState.delivery_order_subtitles.filter(
            (subtitle1) => subtitle !== subtitle1
          ),
        ],
      };
    });
  }

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        pickup_order_title: this.state.pickup_order_title,
        pickup_order_subtitles: this.state.pickup_order_subtitles,
        delivery_order_title: this.state.delivery_order_title,
        delivery_order_subtitles: this.state.delivery_order_subtitles,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      restaurant,
      handleViewSettings,
      handleSubmit,
      handleViewRestaurants,
    } = this.props;

    if (restaurant === undefined) {
      return <></>;
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-clipboard-list" />
                {` `}
                <span className="d-none d-sm-inline">{`Online Order Descriptions`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              {`Tenant`}
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={this.props.restaurant.name}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Pickup Order Title
            </label>
            <div className="col-sm-8">
              <input
                className="form-control"
                type="text"
                value={this.state.pickup_order_title}
                onChange={(event) => {
                  this.setState({ pickup_order_title: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Pickup Order Subtitles
            </label>
            <div className="col-sm-8">
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="addPickupSubtitle"
                  value={this.state.pickup_order_subtitle}
                  onChange={(event) => {
                    this.setState({
                      pickup_order_subtitle: event.target.value,
                    });
                  }}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      this.setState((prevState) => {
                        return {
                          pickup_order_subtitles: [
                            ...prevState.pickup_order_subtitles,
                            this.state.pickup_order_subtitle,
                          ],
                          pickup_order_subtitle: "",
                        };
                      });
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <strong>Subtitles</strong>
              </div>
              {this.state.pickup_order_subtitles.map((subtitle, index) => (
                <div className="row" key={index}>
                  <div className="col-9">{`#${index + 1} ${subtitle}`}</div>
                  <div className="col-3">
                    <button
                      type="button"
                      className="btn"
                      title="Remove"
                      onClick={() =>
                        this.handleRemovePickupOrderSubtitle(subtitle)
                      }
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Delivery Order Title
            </label>
            <div className="col-sm-8 ">
              <input
                className="form-control"
                type="text"
                value={this.state.delivery_order_title}
                onChange={(event) => {
                  this.setState({ delivery_order_title: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Delivery Order Subtitles
            </label>
            <div className="col-sm-8 ">
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  id="addDeliverySubtitle"
                  value={this.state.delivery_order_subtitle}
                  onChange={(event) => {
                    this.setState({
                      delivery_order_subtitle: event.target.value,
                    });
                  }}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      this.setState((prevState) => {
                        return {
                          delivery_order_subtitles: [
                            ...prevState.delivery_order_subtitles,
                            this.state.delivery_order_subtitle,
                          ],
                          delivery_order_subtitle: "",
                        };
                      });
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <strong>Subtitles</strong>
              </div>
              {this.state.delivery_order_subtitles.map((subtitle, index) => (
                <div className="row" key={index}>
                  <div className="col-9">{`#${index + 1} ${subtitle}`}</div>
                  <div className="col-3">
                    <button
                      type="button"
                      className="btn"
                      title="Remove"
                      onClick={() =>
                        this.handleRemoveDeliveryOrderSubtitle(subtitle)
                      }
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={this.props.handleViewSettings}
              >
                {`Cancel`}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleFormSubmit}
              >
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.restaurant.error,
    message: state.restaurant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, formProps, callback) =>
    dispatch(updateSettings(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(DescriptionSettings));
