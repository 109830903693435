
import { getData, patchData, postData2, patchData2 } from "./index";

import { FETCH_USERS, USER_ERROR, ADD_USER, FETCH_USER, UPDATE_USER, CHANGE_PASSWORD } from "./types";

export function fetchEmployees(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/users/`;
  return dispatch => getData(FETCH_USERS, USER_ERROR, true, url, dispatch);
}

export function fetchEmployee(userId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/users/${userId}`;
  return dispatch => getData(FETCH_USER, USER_ERROR, true, url, dispatch);
}

export function addEmployee(tenantId, { userName, firstName, lastName, telephone, email, imageUrl, role, restaurantIds }, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/users/`;

  let formData = new FormData();

  // formData.append('restaurantId', restaurantId);
  formData.append('userName', userName);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('telephone', telephone);
  formData.append('email', email);
  formData.append('role', role);

  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  }

  if (restaurantIds) {
    restaurantIds.forEach(restaurantId => {
      formData.append("restaurants[]", restaurantId)
    })
  }
  return dispatch =>
    postData2(ADD_USER, USER_ERROR, true, url, dispatch, formData, callback);
}

export function updateEmployee(userId, {userName, firstName, lastName, telephone, email, imageUrl, role, restaurantIds }, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/users/${userId}`;

  let formData = new FormData();

  formData.append('userName', userName);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('telephone', telephone);
  formData.append('email', email);
  if (role) {
    formData.append('role', role);
  }

  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  } 

  if (restaurantIds) {
    restaurantIds.forEach(restaurantId => {
      formData.append("restaurants[]", restaurantId)
    })
  }

  return dispatch =>
    patchData2(UPDATE_USER, USER_ERROR, true, url, dispatch, formData, callback);
}

export function changePassword(userId, { currentPassword, newPassword }, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/users/${userId}/changepassword`;
  return dispatch =>
    patchData(CHANGE_PASSWORD, USER_ERROR, true, url, dispatch, {
      currentPassword,
      newPassword
    }, callback);
}