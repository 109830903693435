import React, { Component } from "react";
import { connect } from "react-redux";

import { searchGiftCardOrders } from "../../actions/giftcardorder";

import GiftCardOrderDetail from "./GiftCardOrderDetail";
import OrderListView from "./OrderListView";
import DateTimePicker from "react-widgets/lib/DateTimePicker";

import * as AiIcons from "react-icons/ai";
import normalizePhone from "../../utils/normalizePhone";

class SearchGiftCardOrders extends Component {
  constructor(props) {
    super(props);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewOrders = this.handleViewOrders.bind(this);
    this.handleOrderNumberFilterChange =
      this.handleOrderNumberFilterChange.bind(this);
    this.handlePhoneNumberFilterChange =
      this.handlePhoneNumberFilterChange.bind(this);
    this.handlePeriodFromChange = this.handlePeriodFromChange.bind(this);
    this.handlePeriodToChange = this.handlePeriodToChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      loading: false,
      orderNumberFilter: undefined,
      phoneNumberFilter: undefined,
      periodFrom: undefined,
      periodTo: undefined,
      selectedOrder: undefined,
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
    }
  }

  componentWillUnmount() {}

  handleOrderNumberFilterChange(event) {
    this.setState({
      orderNumberFilter: event.target.value,
    });
  }

  handlePhoneNumberFilterChange(event) {
    this.setState({
      phoneNumberFilter: normalizePhone(event.target.value),
    });
  }

  handlePeriodFromChange(value) {
    this.setState({
      periodFrom: new Date(value),
    });
  }

  handlePeriodToChange(value) {
    this.setState({
      periodTo: new Date(value),
    });
  }

  handleViewDetail(order) {
    this.setState({ selectedOrder: order });
  }

  handleViewOrders() {
    this.setState({ selectedOrder: undefined });
  }

  handleSearch() {
    this.props.searchGiftCardOrders(
      this.props.tenant._id,
      this.state.orderNumberFilter,
      this.state.phoneNumberFilter,
      this.state.periodFrom,
      this.state.periodTo
    );
  }

  render() {
    const { tenant, giftCardOrders = [] } = this.props;

    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.selectedOrder) {
      return (
        <GiftCardOrderDetail
          orderId={this.state.selectedOrder._id}
          orderNumber={this.state.selectedOrder.orderNumber}
          handleViewOrders={this.handleViewOrders}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <AiIcons.AiOutlineFileSearch />
                {` `}
                <span>{`Search Gift Card Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="orderNumberFilter"
              type="text"
              className="form-control"
              placeholder={"Order Number"}
              onChange={this.handleOrderNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="phoneNumberFilter"
              type="tel"
              className="form-control"
              placeholder={"123-456-7890"}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={this.handlePhoneNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 ">
            <DateTimePicker
              onChange={this.handlePeriodFromChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"Start Date"}
              time={false}
              date={true}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <DateTimePicker
              onChange={this.handlePeriodToChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"End Date"}
              time={false}
              date={true}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSearch}
            >
              Search
            </button>
          </div>
        </div>
        
        <OrderListView
          orders={giftCardOrders}
          handleViewDetail={this.handleViewDetail}
          loading={this.state.loading}
          isSearchOrders={true}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    giftCardOrders: state.giftCardOrder.giftCardOrders,
  };
};

const mapDispatchToProps = (dispatch) => ({
  searchGiftCardOrders: (
    tenantId,
    orderNumber,
    phoneNumber,
    periodFrom,
    periodTo
  ) =>
    dispatch(
      searchGiftCardOrders(tenantId, orderNumber, phoneNumber, periodFrom, periodTo)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchGiftCardOrders);
