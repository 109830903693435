import React, { Component } from "react";
import { connect } from "react-redux";

import OnlineOrderingSettings from "./OnlineOrderingSettings";
import OnlineReservationSettings from "./OnlineReservationSettings";
import GeneralInfoSettings from "./GeneralInfoSettings";
import TaxRateSettings from "./TaxRateSettings";
import TimeZoneSettings from "./TimeZoneSettings";
import DeliverySettings from "./DeliverySettings";
import RushHourSettings from "./RushHourSettings";
import PrintingSettings from "./PrintingSettings";
import OnlinePaymentSettings from "./OnlinePaymentSettings";
import CallInOrderSettings from "./CallInOrderSettings";
import SpecialInstructionSettings from "./SpecialInstructionSettings";
import DescriptionSettings from "./DescriptionSettings";
import KitchenOrderSettings from "./KitchenOrderSettings";

import * as MdIcons from "react-icons/md";
export class Restaurant extends Component {
  constructor(props) {
    super(props);

    this.handleSelectedSettings = this.handleSelectedSettings.bind(this);
    this.handleViewSettings = this.handleViewSettings.bind(this);

    this.state = {
      selectedSettings: undefined,
    };
  }

  componentDidMount() {}

  handleSelectedSettings(selectedSettings) {
    this.setState({
      selectedSettings: selectedSettings,
    });
  }

  handleViewSettings() {
    this.setState({ selectedSettings: undefined });
  }

  render() {
    const { restaurant, handleViewRestaurants } = this.props;
    if (restaurant === undefined) {
      return <></>;
    }

    if (this.state.selectedSettings === "OnlineOrdering") {
      return (
        <OnlineOrderingSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.selectedSettings === "Payment") {
      return (
        <OnlinePaymentSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.selectedSettings === "RushHour") {
      return (
        <RushHourSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.selectedSettings === "Delivery") {
      return (
        <DeliverySettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.selectedSettings === "OnlineReservation") {
      return (
        <OnlineReservationSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "TaxRate") {
      return (
        <TaxRateSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.selectedSettings === "Info") {
      return (
        <GeneralInfoSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "TimeZone") {
      return (
        <TimeZoneSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "Printing") {
      return (
        <PrintingSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "CallInOrder") {
      return (
        <CallInOrderSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "SpecialInstruction") {
      return (
        <SpecialInstructionSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "Description") {
      return (
        <DescriptionSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (this.state.selectedSettings === "KitchenOrders") {
      return (
        <KitchenOrderSettings
          restaurant={this.props.restaurant}
          handleViewSettings={this.handleViewSettings}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }

    if (restaurant) {
      return (
        <React.Fragment>
          <nav aria-label="breadcrumb" className="pt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleViewRestaurants}
                >
                  <i className="fas fa-map-marker-alt" />
                  {` `}
                  <span className="d-none d-sm-inline">Restaurants</span>
                </button>
              </li>
              <li className="breadcrumb-item">
                <button type="button" className="btn btn-light" disabled>
                  <i className="fas fa-home" />
                  {` `}
                  <span className="d-none d-sm-inline">{restaurant.name}</span>
                </button>
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("Info")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-info fa-3x" />
                  <h5 className="card-title">{`General Info`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("OnlineOrdering")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-utensils fa-3x" />
                  <h5 className="card-title">{`Online Ordering`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("CallInOrder")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-phone fa-3x" />
                  <h5 className="card-title">{`Call-in Order`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings("SpecialInstruction")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-sticky-note fa-3x" />
                  <h5 className="card-title">{`Special Instruction`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("Payment")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-credit-card  fa-3x" />
                  <h5 className="card-title">{`Online Payments`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("RushHour")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-clock fa-3x" />
                  <h5 className="card-title">{`Rush Hour`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("Delivery")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-truck fa-3x" />
                  <h5 className="card-title">{`Delivery`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings("Description")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-clipboard-list fa-3x" />
                  <h5 className="card-title">{`Description`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("OnlineReservation")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-th-large fa-3x" />
                  <h5 className="card-title">{`Online Reservation`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("TaxRate")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-dollar-sign fa-3x" />
                  <h5 className="card-title">{`Taxation and Currency`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("TimeZone")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-globe fa-3x" />
                  <h5 className="card-title">{`Time Zone`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("Printing")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-print fa-3x" />
                  <h5 className="card-title">{`Printing`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings("KitchenOrders")}
              >
                <div className="card-body text-center">
                  {/* <i className="fas fa-refrigerator fa-3x"></i> */}
                  <MdIcons.MdKitchen size={42} />
                  <h5 className="card-title">{`Kitchen Orders`}</h5>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
