import React, { Component } from "react";

class DeliveryConditions extends Component {
  render() {
    const {
      allowed_delivery_method,
      deliveryConditionType,
      handleDeliveryConditionTypeChange,
      deliveryAreaPostalCodes,
      handleDeliveryAreaPostalCodesChange,
      deliveryDistance,
      handleDeliveryDistanceChange,
    } = this.props;

    return (
      <div className="row promo-row pt-3 pb-3 mb-3">
        <div className="col-12 mb-3">
          <strong>Delivery conditions:</strong>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="free_delivery_condition"
              id="free_delivery_by_postal_codes"
              value="PostalCode"
              checked={deliveryConditionType === "PostalCode"}
              onChange={handleDeliveryConditionTypeChange}
            />
            <label
              className="form-check-label"
              for="free_delivery_by_postal_codes"
            >
              By Postal Codes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="free_delivery_condition"
              id="free_delivery_by_distance"
              value="Distance"
              checked={deliveryConditionType === "Distance"}
              onChange={handleDeliveryConditionTypeChange}
            />
            <label className="form-check-label" for="free_delivery_by_distance">
              By Distance
            </label>
          </div>
        </div>

        <div className="col-12">
          {deliveryConditionType === "PostalCode" && (
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Postal Code</span>
              </div>
              <input
                className="form-control"
                name="delivery_area_postal_codes"
                type="text"
                disabled={allowed_delivery_method === "Pickup"}
                value={deliveryAreaPostalCodes}
                onChange={handleDeliveryAreaPostalCodesChange}
              />
            </div>
          )}
          {deliveryConditionType === "Distance" && (
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Distance in meter</span>
              </div>
              <input
                className="form-control"
                name="delivery_distance_in_meter"
                type="number"
                disabled={allowed_delivery_method === "Pickup"}
                value={deliveryDistance}
                onChange={handleDeliveryDistanceChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DeliveryConditions;
