import { getData, postData2, patchData2, deleteData, patchData } from "./index";

import {
  UPDATE_PROMOTION,
  ADD_PROMOTION,
  FETCH_PROMOTIONS,
  PROMOTION_ERROR,
  DELETE_PROMOTION,
  DUPLICATE_PROMOTION,
  ADD_PROMOTION_PHOTO,
  UPDATE_PROMOTION_PHOTO,
  REMOVE_PROMOTION_PHOTO,
  FETCH_PROMOTION,
  UPDATE_PROMOTION_ASSIGNED_LOCATIONS
} from "./types";

export function fetchPromotions(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions`;
  return dispatch =>
    getData(FETCH_PROMOTIONS, PROMOTION_ERROR, true, url, dispatch);
}

export function fetchPromotion(promotionId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/promotions/${promotionId}`;
  return dispatch =>
    getData(FETCH_PROMOTION, PROMOTION_ERROR, true, url, dispatch);
}

export function addPromo(tenantId, data, callback) {

  let formData = new FormData();

  formData.append("name", data.name);
  if (data.description !== undefined) {
    formData.append("description", data.description);
  }
  if (data.type !== undefined) {
    formData.append("type", data.type);
  }
  if (data.eligible_items_group_1 !== undefined) {
    data.eligible_items_group_1.forEach(element => {
      formData.append("eligible_items_group_1[]", element);
    });
  }
  if (data.eligible_items_group_2 !== undefined) {
    data.eligible_items_group_2.forEach(element => {
      formData.append("eligible_items_group_2[]", element);
    });
  }
  if (data.must_be_same_item !== undefined) {
    formData.append("must_be_same_item", data.must_be_same_item);
  }
  if (data.discount_setting_option !== undefined) {
    formData.append("discount_setting_option", data.discount_setting_option);
  }
  if (data.discount_on_cheapest_item !== undefined) {
    formData.append(
      "discount_on_cheapest_item",
      data.discount_on_cheapest_item
    );
  }
  if (data.discount_on_most_expensive_item !== undefined) {
    formData.append(
      "discount_on_most_expensive_item",
      data.discount_on_most_expensive_item
    );
  }
  if (data.discount_on_items_group_1 !== undefined) {
    formData.append(
      "discount_on_items_group_1",
      data.discount_on_items_group_1
    );
  }
  if (data.discount_amount !== undefined) {
    formData.append("discount_amount", data.discount_amount);
  }
  if (data.discount_percentage !== undefined) {
    formData.append("discount_percentage", data.discount_percentage);
  }
  if (data.extra_charge_option !== undefined) {
    formData.append("extra_charge_option", data.extra_charge_option);
  }
  if (data.limited_showtime_option !== undefined) {
    formData.append("limited_showtime_option", data.limited_showtime_option);
  }
  if (data.monday_selected !== undefined) {
    formData.append("monday_selected", data.monday_selected);
  }
  if (data.monday_from  !== undefined) {
    formData.append("monday_from", data.monday_from);
  }
  if (data.monday_until  !== undefined) {
    formData.append("monday_until", data.monday_until);
  }
  if (data.tuesday_selected !== undefined)  {
    formData.append("tuesday_selected", data.tuesday_selected);
  }
  if (data.tuesday_from !== undefined) {
    formData.append("tuesday_from", data.tuesday_from);
  }
  if (data.tuesday_until !== undefined) {
    formData.append("tuesday_until", data.tuesday_until);
  }
  if (data.wednesday_selected !== undefined) {
    formData.append("wednesday_selected", data.wednesday_selected);
  }
  if (data.wednesday_from !== undefined) {
    formData.append("wednesday_from", data.wednesday_from);
  }
  if (data.wednesday_until !== undefined) {
    formData.append("wednesday_until", data.wednesday_until);
  }
  if (data.thursday_selected !== undefined) {
    formData.append("thursday_selected", data.thursday_selected);
  }
  if (data.thursday_from !== undefined) {
    formData.append("thursday_from", data.thursday_from);
  }
  if (data.thursday_until !== undefined) {
    formData.append("thursday_until", data.thursday_until);
  }
  if (data.friday_selected !== undefined) {
    formData.append("friday_selected", data.friday_selected);
  }
  if (data.friday_from !== undefined) {
    formData.append("friday_from", data.friday_from);
  }
  if (data.friday_until !== undefined) {
    formData.append("friday_until", data.friday_until);
  }
  if (data.saturday_selected !== undefined) {
    formData.append("saturday_selected", data.saturday_selected);
  }
  if (data.saturday_from !== undefined) {
    formData.append("saturday_from", data.saturday_from);
  }
  if (data.saturday_until !== undefined) {
    formData.append("saturday_until", data.saturday_until);
  }
  if (data.sunday_selected !== undefined) {
    formData.append("sunday_selected", data.sunday_selected);
  }
  if (data.sunday_from !== undefined) {
    formData.append("sunday_from", data.sunday_from);
  }
  if (data.sunday_until !== undefined) {
    formData.append("sunday_until", data.sunday_until);
  }
  if (data.available_option !== undefined) {
    formData.append("available_option", data.available_option);
  }
  if (data.available_from !== undefined) {
    formData.append("available_from", data.available_from);
  }
  if (data.available_until) {
    formData.append("available_until", data.available_until);
  }
  if (data.minimum_order_amount_option !== undefined) {
    formData.append("minimum_order_amount_option", data.minimum_order_amount_option);
  }
  if (data.minimum_order_amount !== undefined) {
    formData.append("minimum_order_amount", data.minimum_order_amount);
  }
  if (data.is_next_order_coupon !== undefined) {
    formData.append("is_next_order_coupon", data.is_next_order_coupon);
  }
  if (data.allowed_delivery_method) {
    formData.append("allowed_delivery_method", data.allowed_delivery_method);
  }
  if (data.allowed_order_type !== undefined) {
    formData.append("allowed_order_type", data.allowed_order_type);
  }
  if (data.allowed_order_time !== undefined) {
    formData.append("allowed_order_time", data.allowed_order_time);
  }
  if (data.allowed_payment_method_cash !== undefined) {
    formData.append(
      "allowed_payment_method_cash",
      data.allowed_payment_method_cash
    );
  }
  if (data.allowed_payment_method_cod !== undefined) {
    formData.append(
      "allowed_payment_method_cod",
      data.allowed_payment_method_cod
    );
  }
  if (data.allowed_payment_method_phone !== undefined) {
    formData.append(
      "allowed_payment_method_phone",
      data.allowed_payment_method_phone
    );
  }
  if (data.allowed_payment_method_online !== undefined) {
    formData.append(
      "allowed_payment_method_online",
      data.allowed_payment_method_online
    );
  }
  if (data.highlight_option !== undefined) {
    formData.append("highlight_option", data.highlight_option);
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_1 !== undefined) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_1",
      data.highlight_custom_selection_match_any_item_from_item_group_1
    );
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_2 !== undefined) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_2",
      data.highlight_custom_selection_match_any_item_from_item_group_2
    );
  }
  if (data.highlight_custom_selection_cart_value_exceeds !== undefined) {
    formData.append(
      "highlight_custom_selection_cart_value_exceeds",
      data.highlight_custom_selection_cart_value_exceeds
    );
  }
  if (data.eligible_client_option !== undefined) {
    formData.append("eligible_client_option", data.eligible_client_option);
  }
  if (data.redeem_option !== undefined) {
    formData.append("redeem_option", data.redeem_option);
  }
  if (data.promo_apply_option) {
    formData.append("promo_apply_option", data.promo_apply_option);
  }
  if (data.redeem_only_once_per_client !== undefined) {
    formData.append(
      "redeem_only_once_per_client",
      data.redeem_only_once_per_client
    );
  }

  if (data.redeem_on_limited_stock !== undefined) {
    formData.append("redeem_on_limited_stock", data.redeem_on_limited_stock);
  }

  if (data.limited_stock !== undefined) {
    formData.append("limited_stock", data.limited_stock);
  }

  if (data.coupon_code_option !== undefined) {
    formData.append("coupon_code_option", data.coupon_code_option);
  }
  if (data.coupon_code !== undefined) {
    formData.append("coupon_code", data.coupon_code);
  }
  if (data.coupon_code_expire_unit) {
    formData.append("coupon_code_expire_unit", data.coupon_code_expire_unit);
  }
  if (data.coupon_code_expire_in) {
    formData.append("coupon_code_expire_in", data.coupon_code_expire_in);
  }
  if (data.exclusion_rule_option !== undefined) {
    formData.append("exclusion_rule_option", data.exclusion_rule_option);
  }

  if (data.imageUrl) {
    formData.append("imageUrl", data.imageUrl);
  }

  if (data.conditions) {
    data.conditions.forEach(element => {
      formData.append("conditions[]", element);
    });
  }

  if (data.instructions) {
    data.instructions.forEach(element => {
      formData.append("instructions[]", element);
    });
  }

  if (data.delivery_area_postal_codes) {
    formData.append("delivery_area_postal_codes", data.delivery_area_postal_codes);
  }

  if (data.delivery_distance_in_meter) {
    formData.append("delivery_distance_in_meter", data.delivery_distance_in_meter);
  }

  if (data.free_delivery_condition) {
    formData.append("free_delivery_condition", data.free_delivery_condition);
  }

  if (data.allowed_order_channel) {
    data.allowed_order_channel.forEach(element => {
      formData.append("allowed_order_channel[]", element);
    })
  }

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions/`;
  return dispatch =>
    postData2(
      ADD_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function updatePromo(tenantId, promotionId, data, callback) {


  let formData = new FormData();

  formData.append("name", data.name);
  if (data.description) {
    formData.append("description", data.description);
  }
  if (data.type) {
    formData.append("type", data.type);
  }
  if (data.eligible_items_group_1) {
    data.eligible_items_group_1.forEach(element => {
      formData.append("eligible_items_group_1[]", element);
    });
  }
  if (data.eligible_items_group_2) {
    data.eligible_items_group_2.forEach(element => {
      formData.append("eligible_items_group_2[]", element);
    });
  }
  if (data.must_be_same_item !== undefined) {
    formData.append("must_be_same_item", data.must_be_same_item);
  }
  if (data.discount_setting_option) {
    formData.append("discount_setting_option", data.discount_setting_option);
  }
  if (data.discount_on_cheapest_item >= 0) {
    formData.append(
      "discount_on_cheapest_item",
      data.discount_on_cheapest_item
    );
  }

  if (data.discount_on_most_expensive_item >= 0) {
    formData.append(
      "discount_on_most_expensive_item",
      data.discount_on_most_expensive_item
    );
  }

  if (data.discount_on_items_group_1 >= 0) {
    formData.append(
      "discount_on_items_group_1",
      data.discount_on_items_group_1
    );
  }

  if (data.discount_amount >= 0) {
    formData.append("discount_amount", data.discount_amount);
  }
  if (data.is_next_order_coupon !== undefined) {
    formData.append("is_next_order_coupon", data.is_next_order_coupon);
  }
  if (data.discount_percentage >= 0) {
    formData.append("discount_percentage", data.discount_percentage);
  }
  if (data.extra_charge_option) {
    formData.append("extra_charge_option", data.extra_charge_option);
  }
  if (data.limited_showtime_option) {
    formData.append("limited_showtime_option", data.limited_showtime_option);
  }
  if (data.monday_selected) {
    formData.append("monday_selected", data.monday_selected);
  }
  if (data.monday_from) {
    formData.append("monday_from", data.monday_from);
  }
  if (data.monday_until) {
    formData.append("monday_until", data.monday_until);
  }
  if (data.tuesday_selected) {
    formData.append("tuesday_selected", data.tuesday_selected);
  }
  if (data.tuesday_from) {
    formData.append("tuesday_from", data.tuesday_from);
  }
  if (data.tuesday_until) {
    formData.append("tuesday_until", data.tuesday_until);
  }
  if (data.wednesday_selected) {
    formData.append("wednesday_selected", data.wednesday_selected);
  }
  if (data.wednesday_from) {
    formData.append("wednesday_from", data.wednesday_from);
  }
  if (data.wednesday_until) {
    formData.append("wednesday_until", data.wednesday_until);
  }
  if (data.thursday_selected) {
    formData.append("thursday_selected", data.thursday_selected);
  }
  if (data.thursday_from) {
    formData.append("thursday_from", data.thursday_from);
  }
  if (data.thursday_until) {
    formData.append("thursday_until", data.thursday_until);
  }
  if (data.friday_selected) {
    formData.append("friday_selected", data.friday_selected);
  }
  if (data.friday_from) {
    formData.append("friday_from", data.friday_from);
  }
  if (data.friday_until) {
    formData.append("friday_until", data.friday_until);
  }
  if (data.saturday_selected) {
    formData.append("saturday_selected", data.saturday_selected);
  }
  if (data.saturday_from) {
    formData.append("saturday_from", data.saturday_from);
  }
  if (data.saturday_until) {
    formData.append("saturday_until", data.saturday_until);
  }
  if (data.sunday_selected) {
    formData.append("sunday_selected", data.sunday_selected);
  }
  if (data.sunday_from) {
    formData.append("sunday_from", data.sunday_from);
  }
  if (data.sunday_until) {
    formData.append("sunday_until", data.sunday_until);
  }
  if (data.available_option) {
    formData.append("available_option", data.available_option);
  }
  if (data.available_from) {
    formData.append("available_from", data.available_from);
  }
  if (data.available_until) {
    formData.append("available_until", data.available_until);
  }
  if (data.minimum_order_amount_option) {
    formData.append("minimum_order_amount_option", data.minimum_order_amount_option);
  }
  if (data.minimum_order_amount) {
    formData.append("minimum_order_amount", data.minimum_order_amount);
  }
  if (data.allowed_delivery_method) {
    formData.append("allowed_delivery_method", data.allowed_delivery_method);
  }
  if (data.allowed_order_type) {
    formData.append("allowed_order_type", data.allowed_order_type);
  }
  if (data.allowed_order_time) {
    formData.append("allowed_order_time", data.allowed_order_time);
  }
  if (data.allowed_payment_method_cash) {
    formData.append(
      "allowed_payment_method_cash",
      data.allowed_payment_method_cash
    );
  }
  if (data.allowed_payment_method_cod) {
    formData.append(
      "allowed_payment_method_cod",
      data.allowed_payment_method_cod
    );
  }
  if (data.allowed_payment_method_phone) {
    formData.append(
      "allowed_payment_method_phone",
      data.allowed_payment_method_phone
    );
  }
  if (data.allowed_payment_method_online) {
    formData.append(
      "allowed_payment_method_online",
      data.allowed_payment_method_online
    );
  }
  if (data.highlight_option) {
    formData.append("highlight_option", data.highlight_option);
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_1) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_1",
      data.highlight_custom_selection_match_any_item_from_item_group_1
    );
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_2) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_2",
      data.highlight_custom_selection_match_any_item_from_item_group_2
    );
  }
  if (data.highlight_custom_selection_cart_value_exceeds) {
    formData.append(
      "highlight_custom_selection_cart_value_exceeds",
      data.highlight_custom_selection_cart_value_exceeds
    );
  }
  if (data.eligible_client_option) {
    formData.append("eligible_client_option", data.eligible_client_option);
  }
  if (data.redeem_option) {
    formData.append("redeem_option", data.redeem_option);
  }
  if (data.promo_apply_option) {
    formData.append("promo_apply_option", data.promo_apply_option);
  }
  if (data.redeem_only_once_per_client !== undefined) {
    formData.append(
      "redeem_only_once_per_client",
      data.redeem_only_once_per_client
    );
  }

  if (data.redeem_on_limited_stock !== undefined) {
    formData.append("redeem_on_limited_stock", data.redeem_on_limited_stock);
  }

  if (data.limited_stock) {
    formData.append("limited_stock", data.limited_stock);
  }

  if (data.coupon_code_option) {
    formData.append("coupon_code_option", data.coupon_code_option);
  }
  if (data.coupon_code) {
    formData.append("coupon_code", data.coupon_code);
  }
  if (data.coupon_code_expire_unit) {
    formData.append("coupon_code_expire_unit", data.coupon_code_expire_unit);
  }
  if (data.coupon_code_expire_in) {
    formData.append("coupon_code_expire_in", data.coupon_code_expire_in);
  }
  if (data.exclusion_rule_option) {
    formData.append("exclusion_rule_option", data.exclusion_rule_option);
  }

  if (data.imageUrl) {
    formData.append("imageUrl", data.imageUrl);
  }

  if (data.conditions) {
    data.conditions.forEach(element => {
      formData.append("conditions[]", element);
    });
  }

  if (data.instructions) {
    data.instructions.forEach(element => {
      formData.append("instructions[]", element);
    });
  }

  if (data.delivery_area_postal_codes) {
    formData.append("delivery_area_postal_codes", data.delivery_area_postal_codes);
  }


  if (data.delivery_distance_in_meter) {
    formData.append("delivery_distance_in_meter", data.delivery_distance_in_meter);
  }

  if (data.free_delivery_condition) {
    formData.append("free_delivery_condition", data.free_delivery_condition);
  }

  if (data.allowed_order_channel) {
    data.allowed_order_channel.forEach(element => {
      formData.append("allowed_order_channel[]", element);
    })
  }

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions/${promotionId}`;
  return dispatch =>
    patchData2(
      UPDATE_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function enablePromo(tenantId, promotionId, callback) {
  
  let data = {}
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions/${promotionId}/enable`;
  return dispatch =>
    patchData2(
      UPDATE_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function disablePromo(tenantId, promotionId, callback) {
  let data = {}
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions/${promotionId}/disable`;
  return dispatch =>
    patchData2(
      UPDATE_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function duplicatePromo(tenantId, data, callback) {


  let formData = new FormData();

  formData.append("name", data.name + ' - Copy');
  if (data.description) {
    formData.append("description", data.description);
  }
  if (data.type) {
    formData.append("type", data.type);
  }
  if (data.eligible_items_group_1) {
    data.eligible_items_group_1.forEach(element => {
      formData.append("eligible_items_group_1[]", element);
    });
  }
  if (data.eligible_items_group_2) {
    data.eligible_items_group_2.forEach(element => {
      formData.append("eligible_items_group_2[]", element);
    });
  }
  if (data.discount_setting_option) {
    formData.append("discount_setting_option", data.discount_setting_option);
  }
  if (data.discount_on_cheapest_item) {
    formData.append(
      "discount_on_cheapest_item",
      data.discount_on_cheapest_item
    );
  }
  if (data.discount_on_most_expensive_item) {
    formData.append(
      "discount_on_most_expensive_item",
      data.discount_on_most_expensive_item
    );
  }
  if (data.discount_on_items_group_1) {
    formData.append(
      "discount_on_items_group_1",
      data.discount_on_items_group_1
    );
  }
  if (data.discount_amount) {
    formData.append("discount_amount", data.discount_amount);
  }
  if (data.discount_percentage) {
    formData.append("discount_percentage", data.discount_percentage);
  }
  if (data.extra_charge_option) {
    formData.append("extra_charge_option", data.extra_charge_option);
  }
  if (data.limited_showtime_option) {
    formData.append("limited_showtime_option", data.limited_showtime_option);
  }
  if (data.monday_selected) {
    formData.append("monday_selected", data.monday_selected);
  }
  if (data.monday_from) {
    formData.append("monday_from", data.monday_from);
  }
  if (data.monday_until) {
    formData.append("monday_until", data.monday_until);
  }
  if (data.tuesday_selected) {
    formData.append("tuesday_selected", data.tuesday_selected);
  }
  if (data.tuesday_from) {
    formData.append("tuesday_from", data.tuesday_from);
  }
  if (data.tuesday_until) {
    formData.append("tuesday_until", data.tuesday_until);
  }
  if (data.wednesday_selected) {
    formData.append("wednesday_selected", data.wednesday_selected);
  }
  if (data.wednesday_from) {
    formData.append("wednesday_from", data.wednesday_from);
  }
  if (data.wednesday_until) {
    formData.append("wednesday_until", data.wednesday_until);
  }
  if (data.thursday_selected) {
    formData.append("thursday_selected", data.thursday_selected);
  }
  if (data.thursday_from) {
    formData.append("thursday_from", data.thursday_from);
  }
  if (data.thursday_until) {
    formData.append("thursday_until", data.thursday_until);
  }
  if (data.friday_selected) {
    formData.append("friday_selected", data.friday_selected);
  }
  if (data.friday_from) {
    formData.append("friday_from", data.friday_from);
  }
  if (data.friday_until) {
    formData.append("friday_until", data.friday_until);
  }
  if (data.saturday_selected) {
    formData.append("saturday_selected", data.saturday_selected);
  }
  if (data.saturday_from) {
    formData.append("saturday_from", data.saturday_from);
  }
  if (data.saturday_until) {
    formData.append("saturday_until", data.saturday_until);
  }
  if (data.sunday_selected) {
    formData.append("sunday_selected", data.sunday_selected);
  }
  if (data.sunday_from) {
    formData.append("sunday_from", data.sunday_from);
  }
  if (data.sunday_until) {
    formData.append("sunday_until", data.sunday_until);
  }
  if (data.available_option) {
    formData.append("available_option", data.available_option);
  }
  if (data.available_from) {
    formData.append("available_from", data.available_from);
  }
  if (data.available_until) {
    formData.append("available_until", data.available_until);
  }
  if (data.minimum_order_amount_option) {
    formData.append("minimum_order_amount_option", data.minimum_order_amount_option);
  }
  if (data.minimum_order_amount) {
    formData.append("minimum_order_amount", data.minimum_order_amount);
  }
  if (data.allowed_order_type) {
    formData.append("allowed_order_type", data.allowed_order_type);
  }
  if (data.allowed_order_time) {
    formData.append("allowed_order_time", data.allowed_order_time);
  }
  if (data.allowed_payment_method_cash) {
    formData.append(
      "allowed_payment_method_cash",
      data.allowed_payment_method_cash
    );
  }
  if (data.allowed_payment_method_cod) {
    formData.append(
      "allowed_payment_method_cod",
      data.allowed_payment_method_cod
    );
  }
  if (data.allowed_payment_method_phone) {
    formData.append(
      "allowed_payment_method_phone",
      data.allowed_payment_method_phone
    );
  }
  if (data.allowed_payment_method_online) {
    formData.append(
      "allowed_payment_method_online",
      data.allowed_payment_method_online
    );
  }
  if (data.highlight_option) {
    formData.append("highlight_option", data.highlight_option);
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_1) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_1",
      data.highlight_custom_selection_match_any_item_from_item_group_1
    );
  }
  if (data.highlight_custom_selection_match_any_item_from_item_group_2) {
    formData.append(
      "highlight_custom_selection_match_any_item_from_item_group_2",
      data.highlight_custom_selection_match_any_item_from_item_group_2
    );
  }
  if (data.highlight_custom_selection_cart_value_exceeds) {
    formData.append(
      "highlight_custom_selection_cart_value_exceeds",
      data.highlight_custom_selection_cart_value_exceeds
    );
  }
  if (data.eligible_client_option) {
    formData.append("eligible_client_option", data.eligible_client_option);
  }
  if (data.redeem_option) {
    formData.append("redeem_option", data.redeem_option);
  }
  if (data.redeem_only_once_per_client) {
    formData.append(
      "redeem_only_once_per_client",
      data.redeem_only_once_per_client
    );
  }

  if (data.redeem_on_limited_stock) {
    formData.append("redeem_on_limited_stock", data.redeem_on_limited_stock);
  }

  if (data.limited_stock) {
    formData.append("limited_stock", data.limited_stock);
  }

  if (data.coupon_code_option) {
    formData.append("coupon_code_option", data.coupon_code_option);
  }
  if (data.coupon_code) {
    formData.append("coupon_code", data.coupon_code);
  }
  if (data.coupon_code_expire_in) {
    formData.append("coupon_code_expire_in", data.coupon_code_expire_in);
  }
  if (data.coupon_code_expire_unit) {
    formData.append("coupon_code_expire_unit", data.coupon_code_expire_unit);
  }
  if (data.exclusion_rule_option) {
    formData.append("exclusion_rule_option", data.exclusion_rule_option);
  }

  if (data.image && data.image.length > 0) {
    formData.append("image", data.image[0], data.image[0].name);
  }

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/promotions/`;
  return dispatch =>
  postData2(
      DUPLICATE_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}


export function deletePromo(promotionId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/promotions/${promotionId}`;
  return dispatch =>
    deleteData(
      DELETE_PROMOTION,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      callback
    );
}

export function addPromotionPhoto(
  promotionId,
  { caption, photoUrl },
  callback
) {

  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/promotions/${promotionId}/photos`;

  return dispatch =>
    patchData(
      ADD_PROMOTION_PHOTO,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      {
        caption: caption,
        photoUrl: photoUrl
      },
      callback
    );
}

export function updatePromotionPhoto(
  promotionId,
  promotionPhotoId,
  { caption, photoUrl }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/promotions/${promotionId}/photos/${promotionPhotoId}`;

  return dispatch =>
    patchData(UPDATE_PROMOTION_PHOTO, PROMOTION_ERROR, true, url, dispatch, {
      caption: caption,
      photoUrl: photoUrl
    });
}

export function removePromotionPhoto(
  promotionId,
  promotionPhotoId,
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/promotions/${promotionId}/photos/${promotionPhotoId}`;
  return dispatch =>
    deleteData(REMOVE_PROMOTION_PHOTO, PROMOTION_ERROR, true, url, dispatch);
}

export function updateAssignedLocations(promotionId, locationSelection, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/promotions/${promotionId}/locations`;

  const data = {
    ...locationSelection,
  };

  return (dispatch) =>
    patchData(
      UPDATE_PROMOTION_ASSIGNED_LOCATIONS,
      PROMOTION_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}