import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import MenuItemPhotoPicker from "./MenuItemPhotoPicker";
import { fetchMenuItem, removeMenuItemPhoto } from "../../actions/menu";

class MenuItemPhotosCard extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveItemPhoto = this.handleRemoveItemPhoto.bind(this);
  }

  handleRemoveItemPhoto(menuItemPhotoId) {
    this.props.removeMenuItemPhoto(
      this.props.menu._id,
      this.props.menuSection._id,
      this.props.menuItem._id,
      menuItemPhotoId
    );
  }

  generateDataTarget(menuItemPhotoId) {
    return "#menuItemPhotoEditModal" + menuItemPhotoId;
  }

  render() {
    const {
      tenant,
      restaurant,
      menu,
      menuSection,
      menuItem,
      menuItemPhotos = []
    } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Photos</h5>
            </div>
            <div className="col-6 text-right">
              <MenuItemPhotoPicker
                tenant={tenant}
                restaurant={restaurant}
                menu={menu}
                menuSection={menuSection}
                menuItem={menuItem}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-4">
            {menuItemPhotos.map((itemPhoto, index) => (
              <div className="col mb-4" key={index}>
                <div className="card">
                <img
                      className="card-img-top img-fluid"
                      src={
                        process.env.REACT_APP_S3_BASE_URL + tenant.slug + "/" + itemPhoto.url
                      }
                      alt="Menu Item"
                    />

                  <div className="card-body text-center">
                    <button
                      type="button"
                      className="btn btn-light"
                      title="Remove"
                      onClick={() =>
                        this.handleRemoveItemPhoto(
                          itemPhoto._id
                        )
                      }
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItemPhotos: state.menu.menuItemPhotos
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  removeMenuItemPhoto: (menuId, menuSectionId, menuItemId, menuItemPhotoId) =>
    dispatch(
      removeMenuItemPhoto(menuId, menuSectionId, menuItemId, menuItemPhotoId)
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemPhotosCard);
