import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Link, Redirect } from "react-router-dom";

import { login } from "../actions/auth";
import {
  renderUserNameField,
  renderTenantField,
  renderPasswordField
} from "../utils/renderFields";

import { AUTH_USER } from "../actions/types";

const form = reduxForm({
  form: "login",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.userName) {
    errors.userName = "Please enter user name";
  }
  if (!formProps.password) {
    errors.password = "Please enter password";
  }

  return errors;
}

export class Login extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      redirectToReferrer: false
    };
  }

  handleFormSubmit(formProps) {
    this.props.login(formProps, dispatch => {
      dispatch({ type: AUTH_USER });
      this.setState({ redirectToReferrer: true });
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    if (this.state.redirectToReferrer === true) {
      return <Redirect to={"/"} />;
    }

    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <div className="app row d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card-group">
                <div className="p-4 card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      {this.renderAlert()}
                      <div className="form-group mb-3">
                        {/* <label>User Name</label> */}
                        <Field
                          name="tenantSlug"
                          className="form-control"
                          component={renderTenantField}
                          placeholder="Account"
                          type="text"
                        />
                      </div>
                      <div className="form-group mb-3">
                        {/* <label>User Name</label> */}
                        <Field
                          name="userName"
                          className="form-control"
                          component={renderUserNameField}
                          placeholder="Username"
                          type="text"
                        />
                      </div>
                      <div className="form-group mb-4">
                        {/* <label>Password</label> */}
                        <Field
                          name="password"
                          className="form-control"
                          component={renderPasswordField}
                          placeholder="Password"
                          type="password"
                        />
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <button
                            type="submit"
                            className="px-4 btn btn-primary"
                            disabled={pristine || submitting}
                          >
                            Login
                          </button>
                        </div>
                        <div className="col-6 text-right">
                          <Link to="/forgot-password" className="px-0 btn btn-link">
                            {" "}
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div
                  className="text-white bg-primary py-5 d-none d-lg-block card"
                  style={{ width: "44%" }}
                >
                  <div className="text-center card-body">
                    <div>
                      <h2>Sign up</h2>
                      <p>Join the Angkor Free!</p>
                      <a href="/register">
                        <button className="mt-3 btn btn-primary active">
                          Register Now!
                        </button>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message
  };
};

const mapDispatchToProps = dispatch => ({
  login: (formProps, callback) =>
    dispatch(login(formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(Login));
