import React, { Component } from "react";

class PromoCouponInfo extends Component {
  render() {
    const {
      sectionNumber,
      handleChoiceChange,
      choice,
      handleCouponCodeChange,
      couponCode,
      couponCodeExpireIn,
      handleCouponCodeExpireInChange,
      couponCodeExpireUnit,
      handleCouponCodeExpireUnitChange
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>Set a coupon code</strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12">
              Set a coupon code for this promo deal:
                </strong>
            <div className="col-12 col-lg-6">
              <div className="form-check mt-3 mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="coupon_code_option"
                  value="Auto generated"
                  onChange={handleChoiceChange}
                  checked={choice === "Auto generated"}
                />
                <label className="form-check-label">
                  {`Auto generated (Default)`}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="coupon_code_option"
                  value="Custom"
                  onChange={handleChoiceChange}
                  checked={choice === "Custom"}
                />
                <label className="form-check-label">{`Custom`}</label>
                <div className="form-group mt-3">
                  <input
                    className="form-control"
                    name="coupon_code"
                    type="text"
                    placeholder="5 - 10 characters (no HTML allowed)"
                    disabled={choice === "Auto generated"}
                    value={couponCode}
                    onChange={handleCouponCodeChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Coupon expires in</label>
                <div className="form-row">
                  <div className="col-3">
                    <input type="number" className="form-control" name="coupon_code_expire_in" onChange={handleCouponCodeExpireInChange} value={couponCodeExpireIn} />
                  </div>
                  <div className="col-3">
                    <select className="form-control" name="coupon_code_expire_unit" onChange={handleCouponCodeExpireUnitChange}  value={couponCodeExpireUnit} >
                      <option value="day">Days</option>
                      <option value="week">Weeks</option>
                      <option value="month">Months</option>
                      <option value="year">Years</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <p>{`In most cases clients will be able to pick the promo deal directly from the menu without a coupon code. `}</p>
              <p>{`Coupon codes are used most successful when printed on flyers.`}</p>
              <p>{`You may consider marking this promo as "Hide from menu". This way only clients who know the coupon code can unlock this promo.`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoCouponInfo;