import {
  FETCH_GIFT_CARD_ORDERS,
  FETCH_GIFT_CARD_ORDER,
  FULFILL_GIFT_CARD_ORDER,
  GIFT_CARD_ORDER_ERROR,
  PAY_GIFT_CARD_ORDER,
  GENERATE_GIFT_CARD_NUMBERS,
  UPDATE_GIFT_CARD_ORDER,
  REJECT_GIFT_CARD_ORDER,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  giftCardOrders: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_GIFT_CARD_ORDERS:
      return {
        ...state,
        giftCardOrders: action.payload.giftCardOrders,
        errorMessage: undefined,
      };
    case FETCH_GIFT_CARD_ORDER:
      return {
        ...state,
        giftCardOrder: { ...action.payload.giftCardOrder },
        errorMessage: undefined,
      };
    case PAY_GIFT_CARD_ORDER:
      return {
        ...state,
        giftCardOrder: action.payload.giftCardOrder,
        errorMessage: undefined,
      };
    case UPDATE_GIFT_CARD_ORDER:

      return {
        ...state,
        giftCardOrder: action.payload.giftCardOrder,
        errorMessage: undefined,
      };
    case REJECT_GIFT_CARD_ORDER:
      return {
        ...state,
        giftCardOrder: action.payload.giftCardOrder,
        errorMessage: undefined,
      };
    case FULFILL_GIFT_CARD_ORDER:
      return {
        ...state,
        giftCardOrder: action.payload.giftCardOrder,
        giftCards: action.payload.giftCards,
        errorMessage: undefined,
        giftCardNumbers: [],
      };
    case GENERATE_GIFT_CARD_NUMBERS:
      return {
        ...state,
        giftCardNumbers: action.payload.giftCardNumbers,
        errorMessage: undefined,
      };
    case GIFT_CARD_ORDER_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
