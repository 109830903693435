import React, { Component } from "react";

class FreeDeliveryPromoHighlight extends Component {
  render() {
    const {
      sectionNumber,
      currencyCode,
      handleChoiceChange,
      choice,
      cartValueExceeds,
      handleCartValueExceedsChange,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>Choose an event to trigger promo deal showcasing</strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">
              Highlight more about this promo deal when your clients
                  encounter this context:
                </strong>
            <div className="col-12 col-lg-6">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="highlight_option"
                  value="No highlight"
                  onChange={handleChoiceChange}
                  checked={choice === "No highlight"}
                />
                <label className="form-check-label">{`No highlight (Default)`}</label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="highlight_option"
                  value="Custom selection"
                  onChange={handleChoiceChange}
                  checked={choice === "Custom selection"}
                />
                <label className="form-check-label">
                  {`Custom selection`}
                </label>
                <div className="form-check mt-3 mb-3">
                  <div className="form-group">
                    <label className="col-form-label">
                      Cart value exceeds:
                    </label>
                    <div className="input-group">
                      <input
                        name="highlight_custom_selection_cart_value_exceeds"
                        type="number"
                        className="form-control"
                        value={cartValueExceeds}
                        onChange={handleCartValueExceedsChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">{currencyCode}</span>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <p>{`Some clients may not notice the promo deal section when they start ordering or they see it and decide to look into this later. `}</p>
              <p>{`Therefore sales may be lost due to people forgetting or not noticing how to chase available promo deals. `}</p>
              <p>{`Use this setting to "step-forward" and upgrade your clients ordering experience with a nice, contextual up-selling prompt. `}</p>
              <p>{`Example: Do you grant a benefit when the cart value reaches 100? "Step-forward" with a message to your clients when the cart value crosses over 70.`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeDeliveryPromoHighlight;