import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./components/Login";
import Main from "./components/Main";
import RequireAuth from "./components/RequireAuth";

import "./App.css";

import Sidebar from "./components/Sidebar/Sidebar";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class App extends Component {
  render() {
    return (
      <>
      {cookies.get("user") && <Sidebar />}
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="*" component={RequireAuth(Main)} />
      </Switch>
      </>
     
    );
  }
}

export default App;
