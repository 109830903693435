import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import {
  updateMenuSectionInfo,
  fetchMenuSection,
  removeMenuSectionPhoto,
} from "../../actions/menu";
import { renderField } from "../../utils/renderFields";
import * as Icon from "react-feather";

import MenuSectionPhotoPicker from "./MenuSectionPhotoPicker";

const form = reduxForm({
  form: "menuSectionInfo",
  validate,
});

function validate(formProps) {
  const errors = {};
  if (!formProps.name) {
    errors.name = "Please enter menu section name";
  }

  if (!formProps.display_order) {
    errors.name = "Please enter display_order";
  }

  return errors;
}

class EditMenuSection extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (
      this.props.menuId !== undefined &&
      this.props.menuSectionId !== undefined
    ) {
      this.props.fetchMenuSection(this.props.menuId, this.props.menuSectionId);
    }
  }

  handleFormSubmit(formProps) {
    this.props.updateMenuSectionInfo(
      this.props.menu._id,
      this.props.menuSection._id,
      formProps,
      this.props.menuSectionPhotoUrl,
      () => {
        this.props.handleViewMenu(this.props.menu);
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      tenant,
      restaurant,
      menu,
      handleViewMenus,
      handleViewMenu,
      handleViewMenuSection,
      menuSection,
      menuSectionPhotoUrl,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {menuSection.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Edit
              </button>
            </li>
          </ol>
        </nav>

        {this.renderAlert()}
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="form-group">
              <label className="col-form-label">Image</label>
              {menuSectionPhotoUrl && (
                <div className="card">
                  <img
                    className="img-fluid mb-2"
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${tenant.slug}/${menuSectionPhotoUrl.replace(
                        ".jpg",
                        ""
                      )}.jpg`
                    }
                    alt="Menu Section"
                  />

                  <div className="card-body text-center">
                    <p
                      className="text-truncate"
                      data-toggle="tooltip"
                      title={menuSectionPhotoUrl}
                    >
                      {menuSectionPhotoUrl}
                    </p>
                    <button
                      type="button"
                      className="btn btn-light"
                      title="Remove"
                      onClick={() => this.props.removeMenuSectionPhoto()}
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              )}
              {!menuSectionPhotoUrl && (
                <MenuSectionPhotoPicker
                  tenant={tenant}
                  restaurant={restaurant}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <form
              encType="multipart/form-data"
              onSubmit={handleSubmit(this.handleFormSubmit)}
            >
              <div className="form-group">
                <label className="col-form-label">Name</label>
                <Field
                  name="name"
                  className="form-control"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Description</label>
                <Field
                  name="description"
                  className="form-control"
                  component={renderField}
                  type="text"
                  placeholder="2 - 255 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Display Order</label>
                <Field
                  name="display_order"
                  className="form-control"
                  component={renderField}
                  type="number"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Visibility</label>
                <div>
                  <div className="form-check form-check-inline">
                    <Field
                      name="hidden_from_public"
                      className="form-check-input"
                      component="input"
                      type="checkbox"
                    />
                    <label className="form-check-label">
                      {` `}
                      {`Hidden From Public`}
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {`Cancel`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {`Save`}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const menuSectionPhotoUrl =
    state.menu.menuSection &&
    state.menu.menuSection.image &&
    state.menu.menuSection.image.url
      ? state.menu.menuSection.image.url
      : state.menu.menuSectionPhotoUrl;
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: state.menu.menuSection
      ? state.menu.menuSection
      : ownProps.menuSection,
    menuSectionPhotoUrl: menuSectionPhotoUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMenuSection: (menuId, menuSectionId) =>
    dispatch(fetchMenuSection(menuId, menuSectionId)),
  updateMenuSectionInfo: (
    menuId,
    menuSectionId,
    formProps,
    menuSectionPhotoUrl,
    callback
  ) =>
    dispatch(
      updateMenuSectionInfo(
        menuId,
        menuSectionId,
        formProps,
        menuSectionPhotoUrl,
        callback
      )
    ),
  removeMenuSectionPhoto: () => dispatch(removeMenuSectionPhoto()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(EditMenuSection));
