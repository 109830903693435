import {
  FETCH_BUSINESS_HOURS,
  FETCH_PROMOTIONS,
  UPDATE_BUSINESS_HOUR,
  ADD_BUSINESS_HOUR,
  UPDATE_SETTINGS,
  RESTAURANT_ERROR,
  DELETE_BUSINESS_HOUR,
  FETCH_PUBLISHED_MENUS,
  FETCH_PICKUP_DATES,
  FETCH_VALID_PROMOTIONS
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  pickupDates: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case UPDATE_SETTINGS:
      return {
        ...state,
        message: action.payload.message,
        restaurant: action.payload.restaurant,
        // restaurants: state.restaurants.map(restaurant => {
        //   if (restaurant._id === action.payload.restaurant._id) {
        //     return action.payload.restaurant;
        //   } else {
        //     return restaurant;
        //   }
        // })
      };
    case ADD_BUSINESS_HOUR:
      return {
        ...state,
        businessHours: [...state.businessHours, action.payload.businesshour],
      };
    case UPDATE_BUSINESS_HOUR:
      return {
        ...state,
        message: action.payload.message,
        businessHours: state.businessHours.map((businessHour) => {
          if (businessHour._id === action.payload.businessHour._id) {
            return action.payload.businessHour;
          } else {
            return businessHour;
          }
        }),
      };
    case DELETE_BUSINESS_HOUR:
      return {
        ...state,
        businessHours: state.businessHours.filter(
          (businessHour) => businessHour._id !== action.payload.businessHourId
        ),
      };
    case FETCH_BUSINESS_HOURS:
      return { ...state, businessHours: action.payload.businessHours };
    case FETCH_PROMOTIONS:
      return { ...state, promotions: action.payload.promotions };
    case RESTAURANT_ERROR:
      return { ...state, error: action.payload };
    case FETCH_PUBLISHED_MENUS:
      return {
        ...state,
        menus: action.payload.menus,
        promotions: action.payload.promotions,
        promotionItems: action.payload.promotion_items,
      };
    case FETCH_VALID_PROMOTIONS:
      return { ...state, promotions: action.payload.promotions };
    case FETCH_PICKUP_DATES:
      return { ...state, pickupDates: action.payload.pickupDates };
    default:
      return state;
  }
}
