import React, { useState, useEffect } from "react";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { connect } from "react-redux";

const Monitoring = ({ tenant, restaurants }) => {
  const [cacheKeys, setCacheKeys] = useState([]);

  useEffect(() => {
    const fetchCacheData = (tenantId) => {
      const url =
        process.env.REACT_APP_API_BASE +
        `/api/eater/tenants/${tenantId}/cache-data`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setCacheKeys(data.cacheKeys);
        });
    };
    if (tenant) {
      fetchCacheData(tenant._id);
    }
  }, [tenant]);

  const clearCacheByKey = (tenantId, cacheKey) => {
    const url =
      process.env.REACT_APP_API_BASE +
      `/api/eater/tenants/${tenantId}/cache-data?cacheKey=${cacheKey}`;
    fetch(url, {
      method: "PATCH",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((data) => {
        setCacheKeys(cacheKeys.filter(_cacheKey => _cacheKey !== cacheKey));
        console.log(data);
      });
  };

  if (!tenant) {
    return null;
  }
  return (
    <React.Fragment>
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button type="button" className="btn btn-light" disabled>
              <FaIcons.FaWatchmanMonitoring />
              {` `}
              <span>{`Monitoring`}</span>
            </button>
          </li>
        </ol>
      </nav>
      <div className="row mt-3">
        <div className="col-12">
          <div className="card">
            <div className="card-header">{`Tenant`}</div>
            <div className="card-body">
              <div className="row border-bottom">
                <div className="col-9">
                  <strong>Cache Key</strong>
                </div>
                <div className="col-3 text-right">
                  <strong>Action</strong>
                </div>
              </div>
              {cacheKeys
                .filter((cacheKey) => cacheKey.includes(tenant._id) || cacheKey.includes(`/tenants/${tenant.slug}`))
                .map((cacheKey, index) => (
                  <div
                    className="row clickable-row pt-3 pb-3 border-bottom"
                    key={index}
                  >
                    <div className="col-9">{cacheKey}</div>
                    <div className="col-3 text-right">
                      <button
                        className="btn btn-outline-warning"
                        onClick={() => clearCacheByKey(tenant._id, cacheKey)}
                      >
                        <MdIcons.MdCached className="mr-1" />
                        {`Clear Cache`}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      {restaurants.map((restaurant) => (
        <div className="row mt-3" key={restaurant._id}>
          <div className="col-12">
            <div className="card">
              <div className="card-header">{`Restaurant: ${restaurant.name}`}</div>
              <div className="card-body">
                <div className="row border-bottom">
                  <div className="col-9">
                    <strong>Cache Key</strong>
                  </div>
                  <div className="col-3 text-right">
                    <strong>Action</strong>
                  </div>
                </div>
                {cacheKeys
                  .filter((cacheKey) => cacheKey.includes(restaurant._id))
                  .map((cacheKey, index) => (
                    <div
                      className="row clickable-row pt-3 pb-3 border-bottom"
                      key={index}
                    >
                      <div className="col-9">{cacheKey}</div>
                      <div className="col-3 text-right">
                        <button
                          className="btn btn-outline-warning"
                          onClick={() => clearCacheByKey(tenant._id, cacheKey)}
                        >
                          <MdIcons.MdCached className="mr-1" />
                          {`Clear Cache`}
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    restaurants: state.tenant.restaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
