import React from 'react';
import PropTypes from 'prop-types';

import Numeral from 'numeral';

const Total = ({total}) => {
  return (
    <div className="row pt-3 border-bottom pb-3">
      <div className="col-7 text-left col-cart-angkor">
        <span><strong>Total</strong></span>
      </div>
      <div className="col-3 text-right col-cart-angkor">
        <span>
          <small><strong>{Numeral(total).format("$0,0.00")}</strong></small>
        </span>
      </div>
    </div>
  )
};

Total.propTypes = {
  total: PropTypes.number.isRequired,
};

Total.defaultProps = {
  total: 0
};

export default Total;