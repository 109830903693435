export function sort_menu_items(items) {
  const sorted_items = (items || []).sort((a, b) => {
    if (!isNaN(a.cd)) {
      if (!isNaN(b.cd)) {
        return parseInt(a.cd) > parseInt(b.cd) ? 1 : -1;
      } else {
        return -1;
      }
    } else {
      if (!isNaN(b.cd)) {
        return 1;
      } else {
        return a.cd > b.cd ? 1 : -1;
      }
    }
  });
  return sorted_items;
}
