import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

// const Nav = styled.div`
//   background: #15171c;
//   margin-top: 100px;
//   top: 100px;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

// const NavIcon = styled(Link)`
//   margin-left: 2rem;
//   font-size: 2rem;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

const SidebarNav = styled.nav`
  background: #15171c;
  // width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 65px;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  @media screen and (max-width: 767px) {
    display: none !important;
  }
  @media screen and (max-width: 1199px) {
    width: 100px;
  }
  @media screen and (min-width: 1200px) {
    width: 250px;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  // const [sidebar, setSidebar] = useState(true);

  // const showSidebar = () => {
  //   setSidebar(!sidebar);
  // };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      {/* <Nav>
        <NavIcon to="#">
          <FaIcons.FaBars onClick={showSidebar} />
        </NavIcon>
      </Nav> */}
      <SidebarNav sidebar={true}>
        <SidebarWrap>
          {/* <NavIcon to="#">
            <AiIcons.AiOutlineClose onClick={showSidebar} />
          </NavIcon> */}
          {SidebarData.map((item, index) => {
            return <SubMenu item={item} key={index} />;
          })}
        </SidebarWrap>
      </SidebarNav>
    </IconContext.Provider>
  );
};

export default Sidebar;
