import React, { Component } from "react";

import Numeral from "numeral";

import Loading from "../Common/Loading";
class OrderListView extends Component {
  componentDidMount() {}

  renderRow(order, handleViewDetail) {
   
    return (
      <div
        className="row clickable-row pt-3 pb-3 border-bottom"
        onClick={() => handleViewDetail(order)}
      >
        <div className="col-12 col-xl-2 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Order Number: </strong>{" "}
          </span>{" "}
          <span>
            {order.giftCardOrderNumber}
          </span>
        </div>
        <div className="col-12 col-xl-3 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Product: </strong>
          </span>
          <span>
            {order.giftCardProductName}
          </span>
        </div>
        <div className="col-12 col-xl-1 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Type: </strong>
          </span>
          <span>
            {order.giftCardType}
          </span>
        </div>
        <div className="col-12 col-xl-1 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Qty: </strong>
          </span>
          <span>
            {order.quantity}
          </span>
        </div>
        <div className="col-12 col-xl-1 text-left text-xl-right">
          <span className="d-inline d-xl-none">
            <strong>Price: </strong>
          </span>
          <span>
          {Numeral(order.price).format("$0,0.00")}
          </span>
        </div>
        <div className="col-12 col-xl-1 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Status: </strong>
          </span>
          <span>
          {order.status}
          </span>
        </div>
        <div className="col-12 col-xl-3 text-left text-xl-left">
          <span className="d-inline d-xl-none">
            <strong>Customer/Phone: </strong>
          </span>
          <span>
            {`${order.nameOrderedUnder ? order.nameOrderedUnder + " / " : ""}${
              order.phone
            }`}
          </span>
        </div>
      </div>
    );
  }

  render() {
    const { orders = [], handleViewDetail} = this.props;
    const totalOrders = orders.length;
    const totalAmount = orders.reduce(
      (accumulator, order) => accumulator + order.price,
      0
    );

    return (
      <div className="row mb-3">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="flex-row h-100 d-flex justify-content-between align-items-center">
                <div>
                  <h5>
                    {this.props.loading === false && (
                      <strong className={`text-secondary`}>
                        {`${totalOrders} - ${Numeral(totalAmount).format("$0,0.00")}`}
                      </strong>
                    )}
                  </h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-3 pb-3 border-bottom">
                <div className="col-xl-2 d-none d-xl-inline text-left">
                  <strong>Order Number</strong>
                </div>
                <div className="col-xl-3 d-none d-xl-inline text-left">
                  <strong>Product</strong>
                </div>
                <div className="col-xl-1 d-none d-xl-inline text-left">
                  <strong>Type</strong>
                </div>
                <div className="col-xl-1 d-none d-xl-inline text-left">
                  <strong>Qty</strong>
                </div>
                <div className="col-xl-1 d-none d-xl-inline text-right">
                  <strong>Price</strong>
                </div>
                <div className="col-xl-1 d-none d-xl-inline text-left">
                  <strong>Status</strong>
                </div>
                <div className="col-xl-3 d-none d-xl-inline text-left">
                  <strong>Customer/Phone</strong>
                </div>
 

              </div>
              {this.props.loading && <Loading />}
              {this.props.loading === false && (
                <>
                  {orders.map((order) => (
                    <React.Fragment key={order._id}>
                      {this.renderRow(order, handleViewDetail)}
                    </React.Fragment>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderListView;
