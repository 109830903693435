import React, { Component } from "react";
// import { Field } from "redux-form";
// import { renderDropzoneInput } from "../../utils/renderFields";
import PromotionPhotoPicker from "./PromotionPhotoPicker";

class PromoBasicInfo extends Component {
  render() {
    const {
      sectionNumber,
      name,
      handleNameChange,
      description,
      handleDescriptionChange,
      imageUrl,
      tenant,
      promotion,
      handleImageUrlChange
    } = this.props;

    if (!tenant) {
      return null;
    }
    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>{`Set name, description & picture`}</strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">Name</label>
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  placeholder="2 -35 characters (no HTML allowed)"
                  onChange={handleNameChange}
                  value={name}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Description</label>
                <textarea
                  name="description"
                  className="form-control"
                  placeholder="Maximum 100 characters (no HTML allowed)"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">Image</label>
                <div className="text-center"
                  style={{
                    // width: "330px",
                    // height: "240px",
                    borderWidth: "2px",
                    borderColor: "rgb(102, 102, 102)",
                    borderStyle: "dashed",
                    borderRadius: "5px",
                    padding: "20px",
                  }}>
                  {imageUrl &&
                    <img
                      className="img-fluid"
                      width={229}
                      height={150}
                      src={
                        process.env.REACT_APP_S3_BASE_URL +
                        `${tenant.slug + "/" + imageUrl
                        }`
                      }
                      alt="Promotion"
                    />
                  }
                  <PromotionPhotoPicker
                    tenant={tenant}
                    promotion={promotion}
                    handleImageUrlChange={handleImageUrlChange}
                    imageUrl={imageUrl}
                  />

                </div>

                {/* {imageUrl && (
                  <Field
                    name="image"
                    imgsrc={tenantSlug + '/promotions/' + imageUrl}
                    component={renderDropzoneInput}
                  />
                )}
                {!imageUrl && (
                  <Field name="image" component={renderDropzoneInput} />
                )} */}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoBasicInfo;