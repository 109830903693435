import React from 'react';

const Loading = className => (
  <div className="d-flex justify-content-center align-items-center">
    <h5>
      <i className="fa fa-lg fa-spinner fa-spin mr-3" /> Loading...
    </h5>
  </div>
);

export default Loading;