import React, { Component } from "react";
import { connect } from "react-redux";

import AddMenu from "./AddMenu";
import EditMenu from "./EditMenu";
import Menu from "./Menu";
import MenuSection from "./MenuSection";
import MenuItem from "./MenuItem";
import Archive from "./Archive";

import { fetchMenus, fetchArchived } from "../../actions/menu";
import {
  archiveMenu,
  viewMenus,
  viewMenu,
  publishMenu,
  archiveMenuItem,
  archiveMenuSection,
} from "../../actions/menu";
import { VIEW_MENUS, VIEW_MENU } from "../../actions/types";
import * as Icon from "react-feather";
import AddMenuSection from "./AddMenuSection";
import EditMenuSection from "./EditMenuSection";
import AddMenuItem from "./AddMenuItem";
import EditMenuItem from "./EditMenuItem";
import MoveMenuItem from "./MoveMenuItem";
import MarkMenuItemAsSoldOut from "./MarkMenuItemAsSoldOut";
import MenuOverviewCard from "./MenuOverviewCard";
import ManageMenuLocations from "./ManageMenuLocations";
import ManageMenuSectionLocations from "./ManageMenuSectionLocations";
import ManageMenuItemLocations from "./ManageMenuItemLocations";

import * as MdIcons from "react-icons/md";

class Menus extends Component {
  constructor(props) {
    super(props);

    this.handleAddMenu = this.handleAddMenu.bind(this);
    this.handleEditMenu = this.handleEditMenu.bind(this);
    this.handleManageLocations = this.handleManageLocations.bind(this);
    this.handleAddMenuSection = this.handleAddMenuSection.bind(this);
    this.handleEditMenuSection = this.handleEditMenuSection.bind(this);
    this.handleAddMenuItem = this.handleAddMenuItem.bind(this);
    this.handleEditMenuItem = this.handleEditMenuItem.bind(this);
    this.handleMoveMenuItem = this.handleMoveMenuItem.bind(this);
    this.handleMarkMenuItemAsSoldOut =
      this.handleMarkMenuItemAsSoldOut.bind(this);
    this.handleManageMenuItemLocations = this.handleManageMenuItemLocations.bind(this);

    this.handleViewMenu = this.handleViewMenu.bind(this);
    this.handleViewMenus = this.handleViewMenus.bind(this);
    this.handleViewMenuSection = this.handleViewMenuSection.bind(this);
    this.handleViewMenuItem = this.handleViewMenuItem.bind(this);
    this.handleArchiveMenu = this.handleArchiveMenu.bind(this);
    this.handleViewArchive = this.handleViewArchive.bind(this);
    this.handlePublishMenu = this.handlePublishMenu.bind(this);
    this.handleArchiveMenuItem = this.handleArchiveMenuItem.bind(this);
    this.handleArchiveMenuSection = this.handleArchiveMenuSection.bind(this);

    this.handleManageMenuSectionLocations = this.handleManageMenuSectionLocations.bind(this);

    this.state = {
      selectedMenu: undefined,
      selectedMenuSection: undefined,
      selectedMenuItem: undefined,
      viewMenuSections: false,
      viewMenuItems: false,
      viewMenu: false,
      viewArchive: false,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchMenus(this.props.tenant._id);
      this.props.fetchArchived(this.props.tenant._id);
    }
  }

  handleViewMenu(menu) {
    this.props.viewMenu((dispatch) => {
      dispatch({ type: VIEW_MENU });
      this.setState((prevState) => ({
        viewMenuItem: false,
        viewMenu: true,
        viewArchive: false,
        viewMenuSection: false,
        selectedMenu: menu,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      }));
    });
  }

  handleAddMenu() {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenu: undefined,
      addMenu: true,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleEditMenu(menu) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: true,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenu: menu,
      addMenu: false,
      editMenu: true,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleManageLocations(menu) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenu: menu,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: true,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleManageMenuSectionLocations(menuSection) {

    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuSeciton: menuSection,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: true,
      manageMenuItemLocations: false,
    }));
  }

  handleViewMenus() {
    this.props.viewMenus((dispatch) => {
      dispatch({ type: VIEW_MENUS });
      this.setState({
        viewMenuItem: false,
        viewMenu: false,
        viewArchive: false,
        selectedMenu: undefined,
        selectedMenuSection: undefined,
        selectedMenuItem: undefined,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      });
    });
  }

  handleViewMenuSection(section) {
    this.setState({
      viewMenuItem: false,
      viewMenuSection: true,
      viewMenu: false,
      viewArchive: false,
      selectedMenuSection: section,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    });
  }

  handleAddMenuSection() {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      addMenu: false,
      editMenu: false,
      addMenuSection: true,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleEditMenuSection(section) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuSection: section,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: true,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleViewMenuItem(item) {
    this.setState((prevState) => ({
      viewMenuItem: true,
      viewMenuSection: false,
      viewMenu: false,
      viewArchive: false,
      selectedMenuItem: item,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleAddMenuItem() {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: true,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleEditMenuItem(item) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuItem: item,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: true,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleMoveMenuItem(item) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuItem: item,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: true,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleMarkMenuItemAsSoldOut(item) {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuItem: item,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: true,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleManageMenuItemLocations(menuItem) {

    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenu: false,
      viewArchive: false,
      viewMenuSection: false,
      selectedMenuItem: menuItem,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: true,
    }));
  }

  handleViewArchive() {
    this.setState((prevState) => ({
      viewMenuItem: false,
      viewMenuSection: false,
      viewMenu: false,
      viewArchive: true,
      addMenu: false,
      editMenu: false,
      addMenuSection: false,
      editMenuSection: false,
      addMenuItem: false,
      editMenuItem: false,
      moveMenuItem: false,
      selectedMenu: undefined,
      selectedMenuSection: undefined,
      selectedMenuItem: undefined,
      markMenuItemAsSoldOut: false,
      manageLocations: false,
      manageMenuSectionLocations: false,
      manageMenuItemLocations: false,
    }));
  }

  handleArchiveMenu(menuId) {
    this.props.archiveMenu(menuId, () => {
      this.setState((prevState) => ({
        viewMenuItem: false,
        viewMenuSection: false,
        viewMenu: false,
        viewArchive: false,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        selectedMenu: undefined,
        selectedMenuSection: undefined,
        selectedMenuItem: undefined,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      }));
    });
  }

  handleArchiveMenuSection(menu, section) {
    this.props.archiveMenuSection(menu._id, section._id, () => {
      this.setState((prevState) => ({
        viewMenuItem: false,
        viewMenuSection: false,
        viewMenu: true,
        viewArchive: false,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        selectedMenu: menu,
        selectedMenuSection: undefined,
        selectedMenuItem: undefined,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      }));
    });
  }

  handleArchiveMenuItem(menu, section, menuItem) {
    this.props.archiveMenuItem(menu._id, section._id, menuItem._id, () => {
      this.setState((prevState) => ({
        viewMenuItem: false,
        viewMenuSection: true,
        viewMenu: false,
        viewArchive: false,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        selectedMenu: menu,
        selectedMenuSection: section,
        selectedMenuItem: undefined,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      }));
    });
  }

  handlePublishMenu(menuId) {
    this.props.publishMenu(menuId, () => {
      this.setState((prevState) => ({
        viewMenuItem: false,
        viewMenuSection: false,
        viewMenu: false,
        viewArchive: false,
        addMenu: false,
        editMenu: false,
        addMenuSection: false,
        editMenuSection: false,
        addMenuItem: false,
        editMenuItem: false,
        moveMenuItem: false,
        selectedMenu: undefined,
        selectedMenuSection: undefined,
        selectedMenuItem: undefined,
        markMenuItemAsSoldOut: false,
        manageLocations: false,
        manageMenuSectionLocations: false,
        manageMenuItemLocations: false,
      }));
    });
  }

  render() {
    const {
      tenant,
      restaurant,
      menus = [],
      archivedItems = {},
    } = this.props;
    if (restaurant === undefined) {
      return <></>;
    }

    if (this.state.addMenu) {
      return (
        <AddMenu
          restaurant={restaurant}
          handleViewMenus={this.handleViewMenus}
        />
      );
    } else if (this.state.editMenu) {
      return (
        <EditMenu
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
        />
      );
    } else if (this.state.manageLocations) {
      return (
        <ManageMenuLocations
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
        />
      );
    } else if (this.state.manageMenuSectionLocations) {
      return (
        <ManageMenuSectionLocations
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          handleViewMenuSection={this.handleViewMenuSection}
          handleViewMenu={this.handleViewMenu}
        />
      );
    } else if (this.state.addMenuSection) {
      return (
        <AddMenuSection
          menu={this.state.selectedMenu}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
        />
      );
    } else if (this.state.editMenuSection) {
      return (
        <EditMenuSection
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
        />
      );
    } else if (this.state.addMenuItem) {
      return (
        <AddMenuItem
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
        />
      );
    } else if (this.state.editMenuItem) {
      return (
        <EditMenuItem
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          menuItem={this.state.selectedMenuItem}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
          handleViewMenuItem={this.handleViewMenuItem}
        />
      );
    } else if (this.state.moveMenuItem) {
      return (
        <MoveMenuItem
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          menuItem={this.state.selectedMenuItem}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
          handleViewMenuItem={this.handleViewMenuItem}
        />
      );
    } else if (this.state.markMenuItemAsSoldOut) {
      return (
        <MarkMenuItemAsSoldOut
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          menuItem={this.state.selectedMenuItem}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
          handleViewMenuItem={this.handleViewMenuItem}
        />
      );
    }  else if (this.state.manageMenuItemLocations) {
      return (
        <ManageMenuItemLocations
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          menuItem={this.state.selectedMenuItem}
          handleViewMenuItem={this.handleViewMenuItem}
          handleViewMenuSection={this.handleViewMenuSection}
          handleViewMenu={this.handleViewMenu}
        />
      );
    } else if (this.state.viewMenuItem) {
      return (
        <MenuItem
          tenant={tenant}
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          menuItem={this.state.selectedMenuItem}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuSection={this.handleViewMenuSection}
          handleEditMenuItem={this.handleEditMenuItem}
          handleMoveMenuItem={this.handleMoveMenuItem}
          handleArchiveMenuItem={this.handleArchiveMenuItem}
          handleMarkMenuItemAsSoldOut={this.handleMarkMenuItemAsSoldOut}
          handleManageMenuItemLocations={this.handleManageMenuItemLocations}
        />
      );
    } else if (this.state.viewMenuSection) {
      return (
        <MenuSection
          tenant={tenant}
          restaurant={restaurant}
          menu={this.state.selectedMenu}
          menuSection={this.state.selectedMenuSection}
          handleViewMenus={this.handleViewMenus}
          handleViewMenu={this.handleViewMenu}
          handleViewMenuItem={this.handleViewMenuItem}
          handleEditMenuSection={this.handleEditMenuSection}
          handleAddMenuItem={this.handleAddMenuItem}
          handleArchiveMenuSection={this.handleArchiveMenuSection}
          handleManageMenuSectionLocations={this.handleManageMenuSectionLocations}
        />
      );
    } else if (this.state.viewMenu) {
      return (
        <Menu
          menu={this.state.selectedMenu}
          handleEditMenu={this.handleEditMenu}
          handleViewMenus={this.handleViewMenus}
          handleViewMenuSection={this.handleViewMenuSection}
          handleAddMenuSection={this.handleAddMenuSection}
          handleEditMenuSection={this.handleEditMenuSection}
        />
      );
    } else if (this.state.viewArchive) {
      return (
        <Archive
          handleViewMenus={this.handleViewMenus}
          archivedItems={archivedItems}
        />
      );
    } else {
      return (
        <React.Fragment>
          <nav aria-label="breadcrumb" className="pt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <button type="button" className="btn btn-light" disabled>
                <MdIcons.MdOutlineRestaurantMenu />
                  {` `}
                  <span>{`Menus`}</span>
                </button>
              </li>
            </ol>
          </nav>
          <div className="d-flex justify-content-end mb-3 border-bottom">
            <div className="btn-toolbar mb-2">
              <div className="btn-group mr-2">
                <button
                  className="btn btn-primary"
                  onClick={this.handleAddMenu}
                >
                  <Icon.Plus size={16} />
                  <span className="d-none d-lg-inline">{` `}New Menu</span>
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            {menus.length <= 0 && (
              <div className="col-12 text-center">
                <i className="fa fa-lg fa-spinner fa-spin" /> {` `}Loading...
              </div>
            )}
            {menus.length > 0 &&
              menus.map((menu) => (
                <div className="col-12 col-sm-6 col-lg-4 mb-3" key={menu._id}>
                  <MenuOverviewCard
                    menu={menu}
                    dateTimeFormat={tenant.settings.datetime_format}
                    handleManageLocations={this.handleManageLocations}
                    handleViewMenu={this.handleViewMenu}
                    handleArchiveMenu={this.handleArchiveMenu}
                    handlePublishMenu={this.handlePublishMenu}
                  />
                </div>
              ))}
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="media clickable" onClick={this.handleViewArchive}>
                <Icon.Archive className="mr-3" size={48} />
                <div className="media-body">
                  <h5 className="mt-0">Archive</h5>
                  {archivedItems && (
                    <small className="text-muted">
                      {(archivedItems.menus ? archivedItems.menus.length : 0) +
                        (archivedItems.sections
                          ? archivedItems.sections.length
                          : 0) +
                        (archivedItems.items
                          ? archivedItems.items.length
                          : 0)}{" "}
                      items archived
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    menus: state.menu.menus.filter((menu) => menu.disabled === false) || [],
    archivedItems: state.menu.archivedItems,
    restaurants: state.tenant.restaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMenus: (tenantId) => dispatch(fetchMenus(tenantId)),
  fetchArchived: (tenantId) => dispatch(fetchArchived(tenantId)),
  archiveMenu: (menuId, callback) => dispatch(archiveMenu(menuId, callback)),
  publishMenu: (menuId, callback) => dispatch(publishMenu(menuId, callback)),
  viewMenus: (callback) => dispatch(viewMenus(callback)),
  viewMenu: (callback) => dispatch(viewMenu(callback)),
  archiveMenuItem: (menuId, sectionId, menuItemId, callback) =>
    dispatch(archiveMenuItem(menuId, sectionId, menuItemId, callback)),
  archiveMenuSection: (menuId, sectionId, callback) =>
    dispatch(archiveMenuSection(menuId, sectionId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
