import React, { Component } from "react";

import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import axios from "axios";

import Numeral from "numeral";

import { updateDeliveryInfo } from "../../actions/order";

class UpdateDeliveryInfo extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleBlur = this.handleBlur.bind(this);
    this.getDeliveryDistance = this.getDeliveryDistance.bind(this);
    this.getDeliveryFee = this.getDeliveryFee.bind(this);
    this.handleSuiteApartmentNumberChange = this.handleSuiteApartmentNumberChange.bind(this);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      address: props.order?.deliveryAddress?.address,
      postal_code: props.order?.deliveryAddress?.postalCode,
      delivery_distance: props.order?.deliveryAddress?.distanceInMeter,
      suite_apartment_number: props.order?.deliveryAddress?.suiteApartmentNumber,
      lat_lng: undefined,
      delivery_fee: props.order?.deliveryFee,
      delivery_gst: props.order?.deliveryGST
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  async getDeliveryDistance(restaurant, destination_place_id) {
    const url = `${process.env.REACT_APP_API_BASE}/api/eatery/map/direction?origin=${restaurant.latitude},${restaurant.longitude}&destination=place_id:${destination_place_id}`;
    try {
      const response = await axios.get(url);
      return response.data.direction.routes[0].legs[0].distance.value;
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = (address) => {
    this.setState({ address: address });
  };

  async handleBlur() {
    const selectedRestaurant = this.props.order.restaurant;
    try {
      const results = await geocodeByAddress(this.state.address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;
      const deliveryDistance = await this.getDeliveryDistance(
        selectedRestaurant,
        results[0].place_id
      );
      const delivery_fee = this.getDeliveryFee(this.props.order.restaurant, deliveryDistance);

      const delivery_gst = Numeral(Numeral(delivery_fee * (this.props.order.restaurant.settings.gst_rate / 100)).format("$0,0.00")).value();

      this.setState({
        postal_code: postalCode,
        lat_lng: latLng,
        address: this.state.address,
        delivery_distance: deliveryDistance,
        delivery_fee: delivery_fee,
        delivery_gst: delivery_gst
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleSelect(address) {
    // this.setState({ address });
    const selectedRestaurant = this.props.order.restaurant;
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;
      const deliveryDistance = await this.getDeliveryDistance(
        selectedRestaurant,
        results[0].place_id
      );

      const delivery_fee = this.getDeliveryFee(this.props.order.restaurant, deliveryDistance);

      const delivery_gst = Numeral(Numeral(delivery_fee * (this.props.order.restaurant.settings.gst_rate / 100)).format("$0,0.00")).value();

      this.setState({
        postal_code: postalCode,
        lat_lng: latLng,
        address: address,
        delivery_distance: deliveryDistance,
        delivery_fee: delivery_fee,
        delivery_gst: delivery_gst
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDeliveryFee(restaurant, deliveryDistance) {
    let deliveryFee = 0;

    const flat_delivery_fee = restaurant.settings.delivery_options.delivery_fee;
    const delivery_fee_ranges =
      restaurant.settings.delivery_options.delivery_fees;
    const delivery_distance = deliveryDistance / 1000;

    if (delivery_fee_ranges && delivery_fee_ranges.length > 0) {
      for (const dfr of delivery_fee_ranges) {
        if (
          delivery_distance > dfr.min_radius &&
          delivery_distance <= dfr.max_radius
        ) {
          deliveryFee = dfr.fee;
          break;
        }
      }
    } else if (flat_delivery_fee) {
      deliveryFee = flat_delivery_fee;
    }

    return Numeral(Numeral(deliveryFee).format("$0,0.00")).value();
  }

  handleFormSubmit() {
    const deliveryAddress = {
      address: this.state.address,
      postalCode: this.state.postal_code,
      // addressType: formProps.addressType,
      addressType: "Home",
      suiteApartmentNumber: this.state.suite_apartment_number,
      distanceInMeter: this.state.delivery_distance,
    };

    const total = Numeral(
      Numeral(
        this.props.order.subtotal +
          this.props.order.gst +
          this.props.order.pst +
          this.state.delivery_fee +
          this.state.delivery_gst
      ).format("$0,0.00")
    ).value();

    this.props.updateDeliveryInfo(
      this.props.tenant._id,
      this.props.order._id,
      {
        deliveryAddress: deliveryAddress,
        deliveryFee: this.state.delivery_fee,
        deliveryGST: this.state.delivery_gst,
        total: total,
      },
      (data) => {
        $(".close").click();
      }
    );
  }

  handleSuiteApartmentNumberChange(event) {
    this.setState({
      suite_apartment_number: event.target.value,
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order, restaurant } = this.props;

    if (
      order.deliveryMethod !== "Delivery" ||
      (order.deliveryAddress.distanceInMeter > 0 && order.deliveryFee > 0.01)
    ) {
      return null;
    }

    const searchOptions = {
      location: new window.google.maps.LatLng(
        restaurant.latitude,
        restaurant.longitude
      ),
      radius:
        order.channel === "Online"
          ? restaurant.settings.delivery_options.delivery_area_radius * 1000
          : restaurant.settings.delivery_options
              .callin_order_delivery_area_radius * 1000,
    };

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#updateDeliveryInfoModal_${order._id}`}
          data-backdrop="static"
        >
          <Icon.Truck size={16} className="d-inline mr-1" />
          <span className="d-none d-md-inline">Update Delivery Info</span>
        </button>
        <div
          className="modal fade"
          id={`updateDeliveryInfoModal_${order._id}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          aria-labelledby={`#updateDeliveryInfoModalLabel_${order._id}`}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header bg-primary">
                  <h5
                    className="modal-title"
                    id={`updateDeliveryInfoModalLabel_${order._id}`}
                  >
                    Update Delivery Info
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-row">
                    <div className="form-group col-12">
                      Delivery Address: <strong>{this.state.address}</strong>
                      <br />
                      Suite/Apartment Number:{" "}
                      <strong>
                        {this.state.suite_apartment_number}
                      </strong>
                      <br />
                      Postal Code: <strong>{this.state.postal_code}</strong>
                      <br />
                      Distance:{" "}
                      <strong>{this.state.delivery_distance / 1000} kms</strong>
                      <br />
                      Delivery Fee:{" "}
                      <strong>{Numeral(this.state.delivery_fee).format("$0,0.00")}</strong>
                      <br />
                      Delivery GST:{" "}
                      <strong>{Numeral(this.state.delivery_gst).format("$0,0.00")}</strong>
                      <br />
                    </div>
                      
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label className="required">Address</label>
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Search Places ...",
                                className: "form-control",
                                onBlur: this.handleBlur,
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };

                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label>Suite / Apartment #</label>
                      <input
                      name={`suiteApartmentNumber`}
                      className="form-control"
                      type="text"
                      value={this.state.suite_apartment_number}
                      onChange={this.handleSuiteApartmentNumberChange}
                    />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary" disabled={this.state.delivery_fee < 0.01} onClick={this.handleFormSubmit}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateDeliveryInfo: (tenantId, orderId, { deliveryAddress, deliveryFee, deliveryGST, total }, callback) =>
    dispatch(
      updateDeliveryInfo(tenantId, orderId, { deliveryAddress, deliveryFee, deliveryGST, total }, callback)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDeliveryInfo);
