import React, { Component } from "react";
import { connect } from "react-redux";

import RegularBusinessHours from "./RegularBusinessHours";
import HolidayBusinessHours from "./HolidayBusinessHours";

import Cookies from "universal-cookie";

import * as MdIcons from "react-icons/md";

import { fetchRestaurant } from "../../actions/restaurant";


const cookies = new Cookies();
export class BusinessHours extends Component {
  constructor(props) {
    super(props);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleViewBusinessHours = this.handleViewBusinessHours.bind(this);
    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);

    this.state = {
      selectedRestaurantId: undefined,
      businessHourType: undefined
    };
  }

  componentDidMount() {
    if (this.props.tenant && this.props.applicableRestaurants.length > 0) {
      this.setState(
        {
          selectedRestaurantId: this.props.applicableRestaurants[0].key,
        });
    }
  }

  handleSelectRestaurant(restaurantId) {
    this.setState(
      {
        selectedRestaurantId: restaurantId,
      },
      () => {
        this.props.fetchRestaurant(restaurantId)
      }
    );
  }

  handleViewRegularBusinessHour() {
    this.setState({

      businessHourType: "Regular"
    });
  }

  handleViewHolidayBusinessHour() {
    this.setState({
      businessHourType: "Holiday"
    });
  }

  handleViewBusinessHours() {
    this.setState({ businessHourType: undefined });
  }

  render() {
    const { tenant } = this.props;

    if (tenant === undefined || !this.state.selectedRestaurantId) {
      return <></>;
    }

    if (this.state.businessHourType === "Regular") {
      return (
        <RegularBusinessHours
          tenant={tenant}
          handleViewBusinessHours={this.handleViewBusinessHours}
        />
      );
    }
    if (this.state.businessHourType === "Holiday") {
      return (
        <HolidayBusinessHours
          tenant={tenant}
          handleViewBusinessHours={this.handleViewBusinessHours}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
              <div className="dropdown">
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownRestaurantButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <MdIcons.MdLocationPin />
                  {` `}
                  <span>
                    {
                      this.props.applicableRestaurants.find(
                        (ar) => ar.key === this.state.selectedRestaurantId
                      ).label
                    }
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownRestaurantButton"
                >
                  {this.props.applicableRestaurants.map((ar) => (
                    <button
                      key={ar.key}
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectRestaurant(ar.key)}
                    >
                      {ar.label}
                    </button>
                  ))}
                </div>
              </div>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-business-time" />
                {` `}
                <span>{`Business Hours`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-12 col-sm-6 mb-3">
            <div
              className="card clickable-card  mt-3 pb-3"
              onClick={() => this.handleViewRegularBusinessHour()}
            >
              <div className="card-body text-center">
                <i className="fas fa-business-time fa-3x" />
                <h5 className="card-title">{`Regular Hours`}</h5>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <div
              className="card clickable-card  mt-3 pb-3"
              onClick={() => this.handleViewHolidayBusinessHour()}
            >
              <div className="card-body text-center">
                <i className="fas fa-clock fa-3x" />
                <h5 className="card-title">{`Holiday Hours`}</h5>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const user = cookies.get("user");
  const applicableRestaurants = (
    user.role === "Owner"
      ? state.tenant.restaurants || []
      : (state.tenant.restaurants || []).filter((_restaurant) =>
          user.restaurants.includes(_restaurant._id)
        )
  ).map((_restaurant) => {
    return { key: _restaurant._id, label: _restaurant.name };
  });
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    applicableRestaurants: applicableRestaurants,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchRestaurant: (restaurantId) => dispatch(fetchRestaurant(restaurantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessHours);
