import React, { Component } from "react";
import { Field, reduxForm, reset, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import {
    renderPhoneField,
    required,
    phoneNumber,
    renderDateTimePicker,
    renderField
} from "../../utils/renderFields";

import { saveHistoricalOrder } from "../../actions/historicalorder";

import normalizePhone from "../../utils/normalizePhone";

import { fetchPublishedOnlineOrderMenus } from "../../actions/restaurant";

import Cart from "../Cart/Cart";

import MenuItem from "./MenuItem";

import AliceCarousel from 'react-alice-carousel'
import "react-alice-carousel/lib/alice-carousel.css"

const afterSubmit = (result, dispatch) =>
    dispatch(reset('newHistoricalOrder'));

const form = reduxForm({
    form: "newHistoricalOrder",
    onSubmitSuccess: afterSubmit,
    validate,
});

const selector = formValueSelector("newHistoricalOrder");

function validate(formProps) {
    const errors = {};

    if (!formProps.phone) {
        errors.phone = "Please enter phone number";
    }

    return errors;
}

class AddHistoricalOrder extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.clearFilterText = this.clearFilterText.bind(this);

        this.state = {

            currentIndex: 0,
            responsive: {
                0: {
                    items: 2,
                },
            },
        };
    }

    slideTo = (i) => this.setState({ currentIndex: i })

    onSlideChanged = (e) => this.setState({ currentIndex: e.item })

    slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

    slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

    componentDidMount() {
        if (this.props.restaurant) {
            this.props.fetchPublishedOnlineOrderMenus(this.props.restaurant._id);
        }
    }

    handleFormSubmit(formProps) {

        this.props.saveHistoricalOrder(
            this.props.restaurant._id,
            formProps.orderDate,
            formProps.phone,
            formProps.deliveryAddress,
            this.props.items,
            () => {
                $(".close").click();

            }
        );
    }

    clearFilterText() {
        this.props.dispatch(change('newHistoricalOrder', 'filterText', ""))
    }


    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    <span>
                        <strong>Error!</strong> {this.props.errorMessage}
                    </span>
                </div>
            );
        }
    }

    render() {
        const {
            restaurant,
            menus = [],
            handleSubmit,
            filterTextValue
        } = this.props;

        const { responsive, currentIndex } = this.state;

        let menuItems = [];

        menus.forEach((menu) => {
            menu.sections.forEach((section) => {
                section.items.forEach((item) => {
                    menuItems.push(item);
                });
            });
        });

        const filteredItems = filterTextValue ?
            (menuItems.filter(item => item.cd && item.cd.startsWith(filterTextValue))
                .concat(menuItems.filter(item => item.name.toUpperCase().includes(filterTextValue.toUpperCase())))
            ) : [];

        if (!restaurant) {
            return <></>;
        }

        return (
            <>
                <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addNewHistoricalOrderModal"
                    data-backdrop="static"
                >
                    <Icon.Plus size={16} />
                    {`Add New Order`}
                </button>

                <div
                    className="modal fade"
                    id="addNewHistoricalOrderModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="#addNewHistoricalOrderModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered model-lg" role="document">
                        <div className="modal-content">
                            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                                <div className="modal-header bg-primary">
                                    <h5 className="modal-title" id="menuItemOptionAddModalLabel">
                                        {"Add New Order"}
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-left">
                                    {this.renderAlert()}
                                    <div className="form-row">

                                        <div className="form-group col-md-6">
                                            <label className="required">Order Date</label>
                                            <Field
                                                name="orderDate"
                                                className="form-control"
                                                component={renderDateTimePicker}
                                                showTime={false}
                                                dateFormat={restaurant.settings.date_format}
                                                timeFormat={restaurant.settings.time_format}
                                                datetimeFormat={restaurant.settings.date_time_format}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label className="required">Phone</label>
                                            <Field
                                                name="phone"
                                                className="form-control"
                                                component={renderPhoneField}
                                                validate={[required, phoneNumber]}
                                                type="text"
                                                normalize={normalizePhone}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Address</label>
                                        <Field
                                            name="deliveryAddress"
                                            className="form-control"
                                            component={renderField}
                                            withIcon={false}
                                            type="text"
                                        />

                                    </div>

                                    <div className="row mb-3">
                                        <div className="col-12">
                                            {/* <SearchBar
                                                placeholder={`Search menu by name or number`}
                                                handleFilterTextChange={this.handleFilterTextChange}
                                            /> */}
                                            <div className="input-group">
                                                <Field
                                                    name="filterText"
                                                    component={renderField}
                                                    className="form-control"
                                                    placeholder={`Search menu by name or number`}
                                                    onChange={this.handleFilterTextChange}
                                                />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <Icon.Search />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {filterTextValue && (
                                        <AliceCarousel
                                            mouseTracking
                                            touchTracking
                                            autoHeight={false}
                                            disableDotsControls={true}
                                            disableButtonsControls={false}
                                            responsive={responsive}
                                            slideToIndex={currentIndex}
                                            onSlideChanged={this.onSlideChanged}
                                        >
                                            {filteredItems
                                                .map((item) => (
                                                    <div className="item" key={item._id}>
                                                        <MenuItem restaurant={restaurant} item={item} callback={this.clearFilterText} />
                                                    </div>
                                                ))}
                                        </AliceCarousel>

                                    )}
                                    <Cart showSubtotal={false} showTax={false} showTotal={false} />
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary mr-3"
                                        data-dismiss="modal"
                                    >
                                        {`Cancel`}
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        {"Save"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        errorMessage: state.historicalOrder.errorMessage,
        message: state.historicalOrder.message,
        restaurant: state.tenant.restaurant,
        tenant: state.tenant.tenant,
        menus: state.restaurant.menus,
        items: state.cart.items,
        filterTextValue: selector(state, "filterText"),
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchPublishedOnlineOrderMenus: (restaurantId) =>
        dispatch(fetchPublishedOnlineOrderMenus(restaurantId)),

    saveHistoricalOrder: (
        restaurantId,
        orderDate,
        phone,
        deliveryAddress,
        items,
        callback
    ) =>
        dispatch(
            saveHistoricalOrder(
                restaurantId,
                orderDate,
                phone,
                deliveryAddress,
                items,
                callback
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddHistoricalOrder));
