import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Switch from "react-switch";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "specialInstructionSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

const color_scheme = {
  Blue: "bg-primary",
  Green: "bg-success",
  Yellow: "bg-warning",
  Red: "bg-danger",
  Teal: "bg-info",
  Grey: "bg-secondary",
  Black: "bg-dark",
  // White: "bg-white",
};

class SpecialInstructionSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      enable_special_instruction:
        props.tenant.settings.special_instruction_settings
          ?.enable_special_instruction || false,
      actions:
        props.tenant.settings.special_instruction_settings?.actions || [],
      categories:
        props.tenant.settings.special_instruction_settings?.categories || [],
      action: "",
      name: "",
      color: "",
      ingredients: [],
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        special_instruction_enabled: this.state.enable_special_instruction,
        special_instruction_actions: this.state.actions,
        special_instruction_categories: this.state.categories,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { tenant, handleViewSettings } = this.props;

    if (tenant === undefined) {
      return <></>;
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-sticky-note" />
                {` `}
                <span className="d-none d-sm-inline">{`Special Instructions`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Tenant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.tenant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Enable Special Instruction
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return {
                    enable_special_instruction:
                      !prevState.enable_special_instruction,
                  };
                });
              }}
              checked={this.state.enable_special_instruction || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h5>Actions</h5>
              </div>
              <div className="col-6 text-right"></div>
            </div>

            <div className="form-row">
              <div className="col-12 col-lg-4 input-group">
                <input
                  type="text"
                  className="form-control mr-3"
                  name="action"
                  placeholder="Action name"
                  value={this.state.action}
                  onChange={(e) => this.setState({ action: e.target.value })}
                />
                <button
                  className="btn btn-primary"
                  disabled={!this.state.action}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState((prevState) => {
                      const new_actions = prevState.actions.includes(
                        this.state.action
                      )
                        ? prevState.actions
                        : [...prevState.actions, this.state.action];
                      return {
                        actions: new_actions,
                        action: "",
                      };
                    });
                  }}
                >
                  {`Add Action`}
                </button>
              </div>{" "}
            </div>
            <div className="row my-3">
              <div className="col">
                {this.state.actions.map((action, index) => (
                  <span key={index} className="removable-label bg-info mr-3">
                    <span className="mr-3">{action}</span>
                    <i
                      className="fas fa-times"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prevState) => {
                          return {
                            actions: prevState.actions.filter(
                              (item) => item !== action
                            ),
                          };
                        });
                      }}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h5>Categories</h5>
              </div>
              <div className="col-6 text-right"></div>
            </div>
            <div className="form-row">
              <div className="col-12 col-lg-3">
                <input
                  type="text"
                  className="form-control mr-3"
                  name="name"
                  placeholder="Category name"
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div className="col-12 col-lg-3">
                <select
                  name="color"
                  className="form-control"
                  value={this.state.color}
                  // disabled={this.state.enable_special_instruction === false}
                  onChange={(e) => this.setState({ color: e.target.value })}
                >
                  <option value="">Please select color</option>
                  {Object.keys(color_scheme).map((color_name) => (
                    <option value={`${color_scheme[color_name]}`}>
                      {color_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn btn-primary"
                  disabled={!this.state.name}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState((prevState) => {
                      return {
                        categories: [
                          ...(prevState.categories || []),
                          {
                            name: this.state.name,
                            color: this.state.color,
                            ingredients: [],
                          },
                        ],
                        name: "",
                        color: "",
                      };
                    });
                  }}
                >{`Add Category`}</button>
              </div>
            </div>
            <div className="row mt-3">
              {this.state.categories.map((category, index) => (
                <div className="col-12 col-lg-3">
                  <div
                    className={`card mb-3 ${category.color.replace(
                      "bg-",
                      "border-"
                    )}`}
                    key={index}
                  >
                    <div className="card-header">
                      <div className="row">
                        <div className="col-9">
                          <h5>{category.name}</h5>
                        </div>
                        <div className="col-3 text-right">
                          <i
                            className="fas fa-times"
                            onClick={() =>
                              this.setState((prevState) => {
                                return {
                                  categories: prevState.categories.filter(
                                    (item) => item.name !== category.name
                                  ),
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                    <div className="row">
                          <div className="col">
                      {category.ingredients.map((ingredient, index0) => (
                        
                            <span
                              className={`removable-label ${category.color} mr-3 mb-3`}
                              key={index0}
                            >
                              <span className="mr-3">{ingredient}</span>
                              <i
                                className="fas fa-times"
                                onClick={() =>
                                  this.setState((prevState) => {
                                    const new_categories = prevState.categories.map(category0 => {
                                      if (category0.name === category.name) {
                                        return {
                                          ...category0,
                                          ingredients: [...category0.ingredients.filter(ingredient0 => ingredient0 !== ingredient)]
                                        }
                                      } else {
                                        return category0;
                                      }
                                    })
                                    return {
                                      categories: new_categories,
                                    };
                                  })
                                }
                              />
                            </span>
                      ))}
                          </div>
                        </div>
                    </div>
                    <div className="card-footer">
                      <div className="form-row">
                        <div className="col input-group">
                          <input
                            type="text"
                            className="form-control mr-3"
                            name={`ingredient_${index}`}
                            placeholder="Ingredient"
                            value={
                              this.state.ingredients.length > index
                                ? this.state.ingredients[index]
                                : ""
                            }
                            onChange={(e) => {

                              const value = e.target.value;
                              this.setState((prevState) => {
                                const new_ingredients = [
                                  ...(prevState.ingredients || []),
                                ];

                                if (new_ingredients.length > index) {
                                  new_ingredients[index] = value;
                                } else {
                                  new_ingredients.push(value);
                                }
                                return { ingredients: new_ingredients };
                              });
                            }}
                          />
                          <button
                            className="btn btn-primary"
                            disabled={!this.state.ingredients[index]}
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState((prevState) => {
                                const new_categories = (
                                  prevState.categories || []
                                ).map((category0) => {
                                  if (category0.name === category.name) {
                                    if (
                                      category0.ingredients.includes(
                                        prevState.ingredients[index]
                                      )
                                    ) {
                                      return category0;
                                    } else {
                                      return {
                                        ...category0,
                                        ingredients: [
                                          ...(category0.ingredients || []),
                                          prevState.ingredients[index],
                                        ],
                                      };
                                    }
                                  } else {
                                    return category0;
                                  }
                                });
                                const new_ingredients = [
                                  ...prevState.ingredients,
                                ];
                                new_ingredients[index] = "";
                                return {
                                  categories: new_categories,
                                  ingredients: new_ingredients,
                                };
                              });
                            }}
                          >{`Add`}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(SpecialInstructionSettings));
