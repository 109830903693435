import React, { Component } from "react";

import { connect } from "react-redux";

import * as Icon from "react-feather";
import MenuItemInfoCard from "./MenuItemInfoCard";
import MenuItemOptionsCard from "./MenuItemOptionsCard";
import MenuItemSizesCard from "./MenuItemSizesCard";
import MenuItemItemsCard from "./MenuItemItemsCard";
import MenuItemPhotosCard from "./MenuItemPhotosCard";

class MenuItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      tenant,
      restaurant,
      menu,
      menuSection,
      menuItem,
      handleViewMenus,
      handleViewMenu,
      handleViewMenuSection,
      handleEditMenuItem,
      handleMoveMenuItem,
      handleArchiveMenuItem,
      handleMarkMenuItemAsSoldOut,
      handleManageMenuItemLocations,
    } = this.props;

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span onClick={handleViewMenus}>{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {menuSection ? menuSection.name : ""}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {menuItem.name}
              </button>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-2">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleViewMenuSection(menuSection)}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-6">
            <h2>{menuItem.name}</h2>
          </div>
          <div className="col-4 text-right">
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={() => handleManageMenuItemLocations(menuItem)}
            >
              <Icon.MapPin size={16} /> {` `}
              Locations
            </button>
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={() => handleMarkMenuItemAsSoldOut(menuItem)}
            >
              <Icon.Clock size={16} />
              {` `}
              Availability
            </button>
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={() => handleMoveMenuItem(menuItem)}
            >
              <Icon.Move size={16} />
              {` `}
              Move
            </button>
            {/* <button
                type="button"
                className="btn btn-light"
                onClick={() => handleArchiveMenuItem(menu, menuSection, menuItem)}
              >
                <Icon.Trash2 size={16} />
                {` `}
                Archive
              </button> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <MenuItemInfoCard
              menu={menu}
              menuSection={menuSection}
              menuItem={menuItem}
              handleEditMenuItem={handleEditMenuItem}
              handleMoveMenuItem={handleMoveMenuItem}
              handleArchiveMenuItem={handleArchiveMenuItem}
            />
            {menuItem.type === "Combo" && (
              <MenuItemItemsCard
                menuId={menu._id}
                menuSectionId={menuSection._id}
                menuItemId={menuItem._id}
                menuItem={menuItem}
              />
            )}
            <MenuItemPhotosCard
              tenant={tenant}
              restaurant={restaurant}
              menu={menu}
              menuSection={menuSection}
              menuItem={menuItem}
            />
            <MenuItemSizesCard
              menuId={menu._id}
              menuSectionId={menuSection._id}
              menuItemId={menuItem._id}
              menuItem={menuItem}
            />
            <MenuItemOptionsCard
              menuId={menu._id}
              menuSectionId={menuSection._id}
              menuItemId={menuItem._id}
              menuItem={menuItem}
            />
          </div>
          {/* <div className="col-4">
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItem: ownProps.menuItem ? ownProps.menuItem : state.menu.menuItem,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
