import React, { Component } from "react";
import Numeral from "numeral";
import moment from "moment";

import Loading from "../Common/Loading";
class CustomerListView extends Component {
  constructor(props) {
    super(props);
    this.sortCustomer = this.sortCustomer.bind(this);

    this.state = {
      sortField: "name",
      ascending: true,
      customers: [],
    };
  }

  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    let sortedCustomers = [];
    if (state.sortField === "name") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
          );
      }
    }

    if (state.sortField === "email") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
          );
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.email.toLowerCase() < b.email.toLowerCase() ? 1 : -1
          );
      }
    }
    if (state.sortField === "telephone") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.telephone.toLowerCase() > b.telephone.toLowerCase() ? 1 : -1
          );
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            a.telephone.toLowerCase() < b.telephone.toLowerCase() ? 1 : -1
          );
      }
    }

    if (state.sortField === "totalOrders") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) => (a.totalOrders > b.totalOrders ? 1 : -1));
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) => (a.totalOrders < b.totalOrders ? 1 : -1));
      }
    }

    if (state.sortField === "totalAmount") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) => (a.totalAmount > b.totalAmount ? 1 : -1));
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) => (a.totalAmount < b.totalAmount ? 1 : -1));
      }
    }

    if (state.sortField === "joinedSince") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            moment(a.joinedSince).isAfter(b.joinedSince) ? 1 : -1
          );
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            moment(a.joinedSince).isBefore(b.joinedSince) ? 1 : -1
          );
      }
    }

    if (state.sortField === "lastOrderAt") {
      if (state.ascending) {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            moment(a.lastOrderAt).isAfter(b.lastOrderAt) ? 1 : -1
          );
      } else {
        sortedCustomers = []
          .concat(props.customers)
          .sort((a, b) =>
            moment(a.lastOrderAt).isBefore(b.lastOrderAt) ? 1 : -1
          );
      }
    }

    const newState = {
      customers: [...sortedCustomers],
    };
    return newState;
  }

  sortCustomer(field) {
    if (this.state.sortField === field) {
      this.setState((prevState) => {
        return { ascending: !prevState.ascending };
      });
    } else {
      this.setState((prevState) => {
        return { sortField: field, ascending: !prevState.ascending };
      });
    }
  }

  render() {
    const { handleViewDetail, kind, tenant, mode } = this.props;
    const customers =
      (this.state.customers ? this.state.customers : this.props.customers) ||
      [];
    const totalCustomers = customers.length;
    if (totalCustomers === 0) {
      return null;
    }
    return (
      <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <strong className="text-secondary mr-2">
                  {this.props.loading ? 0 : totalCustomers}
                </strong>
                {mode === "search" && <span>{"Customers"}</span>}
                {mode !== "search" && (
                  <>
                    <span>
                      {kind === "guest" ? "Guests" : "Registered Users"}
                    </span>
                    <span>{" have placed orders in the selected period"}</span>
                  </>
                )}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Phone</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "telephone"
                    ? this.state.ascending
                      ? "fa-sort-alpha-up"
                      : "fa-sort-alpha-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("telephone")}
              />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Customer Name</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "name"
                    ? this.state.ascending
                      ? "fa-sort-alpha-up"
                      : "fa-sort-alpha-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("name")}
              />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Email</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "email"
                    ? this.state.ascending
                      ? "fa-sort-alpha-up"
                      : "fa-sort-alpha-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("email")}
              />
            </div>
            <div className="col-lg-1 d-none d-lg-inline text-right">
              <strong>Total Orders</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "totalOrders"
                    ? this.state.ascending
                      ? "fa-sort-numeric-up"
                      : "fa-sort-numeric-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("totalOrders")}
              />
            </div>
            <div className="col-lg-1 d-none d-lg-inline text-right">
              <strong>Total Amount</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "totalAmount"
                    ? this.state.ascending
                      ? "fa-sort-numeric-up"
                      : "fa-sort-numeric-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("totalAmount")}
              />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-right">
              <strong>Customer Since</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "joinedSince"
                    ? this.state.ascending
                      ? "fa-sort-numeric-up"
                      : "fa-sort-numeric-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("joinedSince")}
              />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-right">
              <strong>Last Order</strong>
              <i
                className={`ml-1 fas ${
                  this.state.sortField === "lastOrderAt"
                    ? this.state.ascending
                      ? "fa-sort-numeric-up"
                      : "fa-sort-numeric-down"
                    : "fa-sort"
                } clickable`}
                onClick={() => this.sortCustomer("lastOrderAt")}
              />
            </div>
          </div>
          {this.props.loading && <Loading />}
          {this.props.loading === false && (
            <>
              {customers.map((customer, index) => (
                <React.Fragment key={index}>
                  <div
                    className="row clickable-row pt-3 pb-3 border-bottom"
                    onClick={() => handleViewDetail(customer)}
                  >
                    <div className="col-12 col-lg-2 text-left ">
                      <span className="d-inline d-lg-none">
                        <strong>Phone: </strong>
                      </span>
                      {customer.isBlocked && <i className="fa fa-ban" />}{" "}
                      {customer.telephone}
                    </div>
                    <div className="col-12 col-lg-2 text-left">
                      <span className="d-inline d-lg-none">
                        <strong>Customer Name: </strong>{" "}
                      </span>{" "}
                      <span className="text-capitalize">{customer.name}</span>
                    </div>
                    <div className="col-12 col-lg-2 text-left">
                      <span className="d-inline d-lg-none">
                        <strong>Email: </strong>
                      </span>
                      {customer.email}
                    </div>
                    <div className="col-12 col-lg-1 text-left text-lg-right">
                      <span className="d-inline d-lg-none">
                        <strong>Total Orders: </strong>
                      </span>
                      <span className="text-lg-right text-left">
                        {customer.totalOrders}
                      </span>
                    </div>
                    <div className="col-12 col-lg-1 text-left text-lg-right">
                      <span className="d-inline d-lg-none">
                        <strong>Total Amount: </strong>
                      </span>
                      <span className="text-lg-right text-left">
                        {Numeral(customer.totalAmount).format("$0,0.00")}
                      </span>
                    </div>
                    <div className="col-12 col-lg-2 text-left text-lg-right">
                      <span className="d-inline d-lg-none">
                        <strong>Joined Since: </strong>
                      </span>
                      <span className="text-lg-right text-left">
                        {moment(customer.joinedSince).format(
                          tenant.settings.date_format
                        )}
                      </span>
                    </div>
                    <div className="col-12 col-lg-2 text-left text-lg-right">
                      <span className="d-inline d-lg-none">
                        <strong>Last Order: </strong>
                      </span>
                      <span className="text-lg-right text-left">
                        {moment(customer.lastOrderAt).format(
                          tenant.settings.date_format
                        )}
                      </span>
                    </div>
                    {/* <div className="col-12 col-lg-1 text-left ">
              <span className="d-inline d-lg-none">
                <strong>Points: </strong>
              </span>
              {customer.points}
            </div> */}
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CustomerListView;
