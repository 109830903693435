import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import * as Icon from "react-feather";

import {
  fetchHolidayBusinessHours,
  deleteBusinessHour,
} from "../../actions/restaurant";

import AddHolidayHour from "./AddHolidayHour";
import EditHolidayHour from "./EditHolidayHour";

import * as MdIcons from "react-icons/md";

export class HolidayBusinessHours extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleViewHolidayHours = this.handleViewHolidayHours.bind(this);

    this.state = {
      mode: "View",
      businessHour: undefined,
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.props.fetchHolidayBusinessHours(this.props.restaurant._id);
    }
  }

  handleViewHolidayHours() {
    this.setState({ mode: "View", businessHour: undefined });
  }

  handleDelete(busienssHourId) {
    this.props.deleteBusinessHour(busienssHourId, () => {});
  }

  handleAdd() {
    this.setState({ mode: "Add", businessHour: undefined });
  }

  handleEdit(businessHour) {
    this.setState({ mode: "Edit", businessHour: businessHour });
  }

  render() {
    const { holidayBusinessHours, handleViewBusinessHours, restaurant } =
      this.props;

    if (this.state.mode === "Add") {
      return (
        <AddHolidayHour handleViewHolidayHours={this.handleViewHolidayHours} />
      );
    }
    if (this.state.mode === "Edit") {
      return (
        <EditHolidayHour
          handleViewHolidayHours={this.handleViewHolidayHours}
          businessHour={this.state.businessHour}
        />
      );
    }
    if (!this.props.restaurant) {
      return null;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <MdIcons.MdLocationPin />
                {` `}
                <span className="d-none d-sm-inline">
                  {this.props.restaurant.name}
                </span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewBusinessHours}
              >
                <i className="fas fa-business-time" />
                {` `}
                <span className="d-none d-sm-inline">{`Business Hours`}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-business-time" />
                {` `}
                <span className="d-none d-sm-inline">{`Holiday Hours`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.handleAdd()}
            >
              <Icon.Plus size={16} />
              {` `}
              New Holiday Hour
            </button>
          </div>
        </div>
        <div className="row">
          {holidayBusinessHours.map((businessHour) => (
            <div
              className="col-12 col-sm-6 col-lg-4 col-xl-3 pb-3"
              key={businessHour._id}
            >
              <div className="card text-center">
                <div className="card-header ">
                  <h5>
                    {moment(businessHour.date).format(
                      this.props.restaurant.settings.date_format
                    )}
                  </h5>
                </div>
                <div className="card-body">
                  {businessHour.is_open && (
                    <span>
                      <strong>{`Open `}</strong>
                      {moment(
                        businessHour.open_time,
                        restaurant.settings.time_format
                      ).format(restaurant.settings.time_format)}{" "}
                      {`-`}{" "}
                      {moment(
                        businessHour.close_time,
                        restaurant.settings.time_format
                      ).format(restaurant.settings.time_format)}
                    </span>
                  )}
                  {!businessHour.is_open && (
                    <span>
                      <strong>Closed</strong>
                    </span>
                  )}
                </div>
                <div className="card-footer">
                  <button
                    className="btn btn-outline-danger mr-3"
                    onClick={() => this.handleDelete(businessHour._id)}
                  >
                    <Icon.Trash2 size={16} /> Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => this.handleEdit(businessHour)}
                  >
                    <Icon.Edit2 size={16} />
                    {` `}
                    Edit
                  </button>
                  {/* <BusinessHourEditModal businessHour={businessHour} /> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    holidayBusinessHours: state.restaurant.businessHours || [],
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHolidayBusinessHours: (restaurantId) =>
    dispatch(fetchHolidayBusinessHours(restaurantId)),
  deleteBusinessHour: (businesHourId, callback) =>
    dispatch(deleteBusinessHour(businesHourId, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HolidayBusinessHours);
