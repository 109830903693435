import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import { applyCoupon } from "../../actions/order";

class ApplyCoupon extends Component {
  constructor(props) {
    super(props);

    this.handleCouponCodeChange= this.handleCouponCodeChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {

    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {}

  handleCouponCodeChange(event) {
    this.setState({
      couponCode: event.target.value,
    });
  }

  handleSave() {
    const couponCode = this.state.couponCode;

    this.props.applyCoupon(
      this.props.tenant._id,
      this.props.order._id,
      { couponCode: couponCode },
      (data) => {
        // socket.emit(
        //   "update order",
        //   this.props.restaurant._id,
        //   data.order,
        //   this.props.fetchPeriod
        // );
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order } = this.props;
    
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#applyCouponModal_${order._id}`}
          data-backdrop="static"
        >
          <Icon.DollarSign size={16} className="d-inline mr-1" />
          <span className="d-none d-md-inline">Redeem Coupon</span>
        </button>
        <div
          className="modal fade"
          id={`applyCouponModal_${order._id}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          aria-labelledby={`#applyCouponModalLabel_${order._id}`}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header bg-primary">
                  <h5
                    className="modal-title"
                    id={`applyCouponModalLabel_${order._id}`}
                  >
                   Apply Coupon 
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>Coupon Code</label>
                    <input
                      name={`couponCode`}
                      className="form-control"
                      type="text"
                      value={this.state.couponCode}
                      onChange={this.handleCouponCodeChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSave}
                  >
                    Redeem
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  applyCoupon: (tenantId, orderId, { couponCode }, callback) =>
    dispatch(
      applyCoupon(tenantId, orderId, { couponCode }, callback)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCoupon);
