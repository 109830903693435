import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";
import MenuItemTileView from "./MenuItemTileView";
import MenuItemListView from "./MenuItemListView";
import MenuSectionInfoCard from "./MenuSectionInfoCard";

import {fetchMenuSection} from '../../actions/menu';

class MenuSection extends Component {
  constructor(props) {
    super(props);

    this.handleListView = this.handleListView.bind(this);
    this.handleTileView = this.handleTileView.bind(this);

    this.state = {
      listView: true,
      tileView: false
    };
  }

  componentDidMount() { 
    if (this.props.menu && this.props.menuSection) {
      this.props.fetchMenuSection(this.props.menu._id, this.props.menuSection._id);
    }
  }

  handleListView() {
    this.setState({ listView: true, tileView: false });
  }

  handleTileView() {
    this.setState({ listView: false, tileView: true });
  }

  render() {
    const {
      menu,
      menuSection,
      handleViewMenu,
      handleViewMenus,
      handleViewMenuItem,
      tenant,
      restaurant,
      handleEditMenuSection,
      handleAddMenuItem,
      handleArchiveMenuSection,
      handleManageMenuSectionLocations
    } = this.props;

    const menu_items = menuSection.items ? menuSection.items : [];

    return (

      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {menuSection ? menuSection.name : ""}
              </button>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-1">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => handleViewMenu(menu)}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-11">
            <h2>{menuSection ? menuSection.name : ""}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 order-12 col-lg-8 order-lg-1 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-4">
                    <h5>Items</h5>
                  </div>
                  <div className="col-8 text-right">
                    <button
                      type="button"
                      className={
                        this.state.tileView
                          ? "btn btn-secondary mr-2 active"
                          : "btn btn-secondary mr-2"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Tile View"
                      onClick={this.handleTileView}
                    >
                      <Icon.Grid size={16} />
                    </button>
                    
                    {
                      <button
                        type="button"
                        className={
                          this.state.listView
                            ? "btn btn-secondary mr-2 active"
                            : "btn btn-secondary mr-2"
                        }
                        data-toggle="tooltip"
                        data-placement="top"
                        title="List View"
                        onClick={this.handleListView}
                      >
                        <Icon.Menu size={16} />
                      </button>
                    }
             
                    {menu && menuSection &&
                      <button className="btn btn-primary" onClick={handleAddMenuItem}>
                        <Icon.Plus size={16} />
                        <span className="d-none d-lg-inline"> {` `}New Item</span>
                      </button>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>
                {this.state.tileView && menuSection && (
                  <MenuItemTileView
                    tenant={tenant}
                    restaurant={restaurant}
                    menu={menu}
                    section={menuSection}
                    items={menu_items.filter(item => item.disabled === false)}
                    handleViewMenuItem={handleViewMenuItem}
                  />
                )}
                {this.state.listView && menuSection && (
                  <MenuItemListView
                    tenant={tenant}
                    restaurant={restaurant}
                    menu={menu}
                    section={menuSection}
                    items={menu_items.filter(item => item.disabled === false)}
                    handleViewMenuItem={handleViewMenuItem}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 order-1 col-lg-4 order-lg-12 mb-3">
            <MenuSectionInfoCard tenant={tenant} restaurant={restaurant} menu={menu} menuSection={menuSection} handleEditMenuSection={handleEditMenuSection} handleArchiveMenuSection={handleArchiveMenuSection} handleManageMenuSectionLocations={handleManageMenuSectionLocations}/>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menuSection: state.menu.menuSection ? state.menu.menuSection : ownProps.menuSection
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuSection: (menuId, menuSectionId) =>
  dispatch(fetchMenuSection(menuId, menuSectionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSection);
