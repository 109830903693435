import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import { updateCartItemSpecialInstructions } from "../../actions/cart";

class CartItemSpecialInstructionsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAction: "",
      currentIngredient: "",
      instructions: props.cartItem.specialInstructions || [],
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.setState({
        selectedCategory:
          this.props.restaurant.settings.special_instruction_settings
            ?.categories?.length > 0
            ? this.props.restaurant.settings.special_instruction_settings
                .categories[0].name
            : "",
      });
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { cartItem, restaurant } = this.props;
    const actions = restaurant.settings.special_instruction_settings.actions;
    const categories =
      restaurant.settings.special_instruction_settings.categories;
    const ingredients = [];
    categories
      .filter((category) => category.name === this.state.selectedCategory)
      .forEach((category) => {
        category.ingredients.forEach((ingredient) =>
          ingredients.push({ name: ingredient, color: category.color })
        );
      });

    return (
      <>
        <div
          className="modal fade"
          id={`cartItemSpecialInstructionsModal${cartItem.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#cartItemSpecialInstructionsModallLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5
                  className="modal-title"
                  id="cartItemSpecialInstructionsModallLabel"
                >
                  {"Special Instructions"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                {this.renderAlert()}
                <div className="container-fluid">
                  <div className="row border-bottom">
                    <div className="col-2">
                      <div className="btn-group-vertical">
                        {actions.map((action, index) => (
                          <button
                            className="btn btn-secondary mb-2"
                            key={index}
                            onClick={(e) => {
                              e.preventDefault();

                              this.setState((prevState) => {
                                const to_reset = !!prevState.currentIngredient;
                                const new_insructions =
                                  prevState.currentIngredient
                                    ? prevState.instructions.includes(
                                        `${action} ${prevState.currentIngredient}`
                                      )
                                      ? [...prevState.instructions]
                                      : [
                                          ...prevState.instructions,
                                          `${action} ${prevState.currentIngredient}`,
                                        ]
                                    : [...prevState.instructions];
                                return {
                                  instructions: new_insructions,
                                  currentAction: to_reset ? "" : action,
                                  currentIngredient: to_reset
                                    ? ""
                                    : prevState.currentIngredient,
                                };
                              });
                            }}
                          >
                            {`${action}`}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="col-10">
                      <div className="row border-bottom pb-3 mb-3">
                        <div className="col">
                          {categories.map((category, index) => (
                            <button
                              className={`btn ${category.color.replace(
                                "bg-",
                                "btn-"
                              )} mr-3`}
                              key={index}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  selectedCategory: category.name,
                                });
                              }}
                            >
                              {category.name}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="row">
                        {ingredients.map((ingredient, index1) => (
                          <div className="col-3 mb-1" key={`${index1}`}>
                            <button
                              className={`btn btn-block ${ingredient.color.replace(
                                "bg-",
                                "btn-"
                              )}`}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState((prevState) => {
                                  const to_reset = !!prevState.currentAction;
                                  const new_insructions = this.state
                                    .currentAction
                                    ? prevState.instructions.includes(
                                        `${this.state.currentAction} ${ingredient.name}`
                                      )
                                      ? [...prevState.instructions]
                                      : [
                                          ...prevState.instructions,
                                          `${this.state.currentAction} ${ingredient.name}`,
                                        ]
                                    : [...prevState.instructions];
                                  return {
                                    instructions: new_insructions,
                                    currentIngredient: to_reset
                                      ? ""
                                      : ingredient.name,
                                    currentAction: to_reset
                                      ? ""
                                      : prevState.currentAction,
                                  };
                                });
                              }}
                            >
                              {`${ingredient.name}`}
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="form-row mt-3">
                    <div className="col-12">
                      {this.state.instructions.map((instruction, index) => (
                        <span
                          className="removable-label bg-info mr-3"
                          key={index}
                        >
                          <span className="mr-3">{instruction}</span>
                          <i
                            className="fas fa-times"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState((prevState) => {
                                prevState.instructions.splice(index, 1);
                                return {
                                  instructions: [...prevState.instructions],
                                };
                              });
                            }}
                          />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.updateCartItemSpecialInstructions(
                      cartItem,
                      this.state.instructions
                    );
                    $(".close").click();
                  }}
                >
                  {"Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.cart.errorMessage,
    message: state.cart.message,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCartItemSpecialInstructions: (cartItem, instructions) =>
    dispatch(updateCartItemSpecialInstructions(cartItem, instructions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartItemSpecialInstructionsModal);
