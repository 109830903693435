import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { fetchPromotion, updateAssignedLocations } from "../../actions/promotion";
import * as Icon from "react-feather";

const form = reduxForm({
  form: "manageLocations",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class ManageLocations extends Component {
  constructor(props) {
    super(props);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    const assignedLocations = {};
    if (this.props.promotion.restaurants) {
      for (const restaurantId of this.props.promotion.restaurants) {
        assignedLocations[restaurantId] = true;
      }
    }

    this.state = {
      ...assignedLocations,
    };
  }

  componentDidMount() {
    if (this.props.promotion) {
      this.props.fetchPromotion(this.props.promotion._id);
    }
  }

  handleSelectionChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  }

  handleFormSubmit() {
    this.props.updateAssignedLocations(this.props.promotion._id, this.state, () => {
      this.props.handleViewPromos();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      restaurants = [],
      promotion,
      handleViewPromos,
      handleSubmit,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-tags" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewPromos}
                >{`Promotions`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                // onClick={() => handleViewMenu(menu)}
              >
                {promotion.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page" disabled>
              <button type="button" className="btn btn-light">
                Manage Locations
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewPromos}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-9">
            <h3>{promotion.name}</h3>
          </div>
        </div>
        {this.renderAlert()}
        <div className="card">
          <div className="card-header">
            <h5>{`Assigned Locations`}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              {restaurants.map((restaurant) => (
                <div className="form-check mb-3" key={restaurant._id}>
                  <input
                    name={restaurant._id}
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={(promotion.restaurants || []).includes(
                      restaurant._id
                    )}
                    onChange={this.handleSelectionChange}
                  />
                  <label className="form-check-label">{restaurant.name}</label>
                </div>
              ))}
              <div className="row pt-3 mt-3 border-top">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-3"
                    onClick={() => handleViewPromos()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {"Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenant.tenant,
    restaurants: state.tenant.restaurants,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPromotion: (promotionId) => dispatch(fetchPromotion(promotionId)),
  updateAssignedLocations: (promotionId, locationSelection, callback) =>
    dispatch(updateAssignedLocations(promotionId, locationSelection, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ManageLocations));
