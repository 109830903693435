import Cookies from "universal-cookie";

const cookies = new Cookies();

export function isAdmin() {
  const user = cookies.get("user");
  return user ? user.role === 'Admin' : false;
}

export function isManager() {
  const user = cookies.get("user");
  return user ? user.role === 'Manager' : false;
}

export function isOwner() {
  const user = cookies.get("user");
  return user ? user.role === 'Owner' : false;
}

export function isWaiter() {
  const user = cookies.get("user");
  return user ? user.role === 'Waiter' : false;
}

export function getRole(checkRole) {
  let role;

  switch (checkRole) {
    case "Admin": role = 6; break;
    case "Owner": role = 5; break;
    case "Manager": role = 4; break;
    case "Waiter": role = 3; break;
    case "Courier": role = 2; break;
    case "Customer": role = 1; break;
    default: role = 1;
  }

  return role;
};

export function isGreaterThanEqual(checkRole) {
  const checkRoleLevel = getRole(checkRole);
  const user = cookies.get("user");
  const roleLevel = getRole(user.role);
  return roleLevel >= checkRoleLevel;
}