import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";

import { fetchImages, addImage } from "../../actions/image";

import * as Icon from "react-feather";

import { renderDropzoneInput } from "../../utils/renderFields";

import SearchBar from "../Common/SearchBar";
import AddFolder from "./AddFolder";

import $ from "jquery";

import Loading from "../Common/Loading";

const form = reduxForm({
  form: "imageLibrary",
  validate,
});

function validate(formProps) {
  const errors = {};

  // if (!formProps.image) {
  //   errors.image = "Please upload image";
  // }

  return errors;
}

class ImageLibrary extends Component {
  constructor(props) {
    super(props);

    this.handleDrop = this.handleDrop.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);

    this.state = {
      loading: false,
      filterText: undefined,
      relativePath: undefined,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.setState({
        loading: true,
      });

      this.props.fetchImages(this.props.tenant._id, undefined, () => {
        this.setState({ loading: false });
      });
    }
  }

  handleDrop(files) {
    if (files) {
      this.props.addImage(
        this.props.tenant._id,
        {
          caption: undefined,
          image: files,
          relativePath: this.state.relativePath,
        },
        () => {
          this.props.clearDropzone();
        }
      );
    }
  }

  componentDidUpdate() {
    $(".card").hover(
      function () {
        $(this).addClass("shadow-lg").css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow-lg");
      }
    );
  }

  handleFilterTextChange(event) {
    this.setState({
      filterText: event.target.value,
    });
  }

  handleNavigate(relative_path) {
    if (this.props.tenant) {
      this.setState({ relativePath: relative_path }, () => {
        this.props.fetchImages(this.props.tenant._id, relative_path, () => {
          this.setState({ loading: false });
        });
      });
    }
  }

  render() {
    const {
      tenant,
      images = [],
      folders = [],
      parent_folders = [],
    } = this.props;

    const filteredImages = this.state.filterText
      ? images.filter((image) => image.name.includes(this.state.filterText))
      : images;

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => this.handleNavigate(undefined)}
              >
                <i className="fas fa-images" />
                {` `}
                <span className="d-none d-sm-inline">{`Image Library`}</span>
              </button>
            </li>
            {parent_folders.map((parent_folder, index) => (
              <li
                className="breadcrumb-item active"
                aria-current="page"
                key={index}
              >
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() =>
                    this.handleNavigate(parent_folder.relative_path)
                  }
                >
                  {parent_folder.name}
                </button>
              </li>
            ))}
          </ol>
        </nav>
        <div className="d-flex justify-content-start mb-3 border-bottom">
          <SearchBar
            placeholder={`Search image by name`}
            handleFilterTextChange={this.handleFilterTextChange}
            showSearchIcon={false}
          />
        </div>
        <div className="row row-cols-1 row-cols-md-6">
          <div className="col mb-4">
            <Field
              name="image"
              component={renderDropzoneInput}
              width={150}
              height={150}
              uploader={(files) => this.handleDrop(files)}
            />
          </div>
          <div className="col mb-4">
            <AddFolder />
          </div>
          {this.state.loading === true && <Loading />}
          {this.state.loading === false && (
            <>
              {folders.map((folder, index) => (
                <div className="col mb-4 text-center" key={index}>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.handleNavigate(folder.relative_path);
                    }}
                  >
                    <Icon.Folder size={32} />
                    <p
                      className="text-truncate"
                      data-toggle="tooltip"
                      title={folder.name}
                    >
                      {folder.name}
                    </p>
                  </button>
                </div>
              ))}
              {filteredImages.map((image, index) => (
                <div
                  className="col mb-4"
                  key={index}
                  onClick={() => this.setState({ selectedImage: image.name })}
                >
                  <div className="card">
                    {image && (
                      <img
                        className="card-img-top img-fluid"
                        src={
                          process.env.REACT_APP_S3_BASE_URL +
                          `${encodeURIComponent(
                            tenant.slug + "/" + image.relative_path
                          )}`
                        }
                        alt="Library Item"
                      />
                    )}

                    <div className="card-body text-center">
                      <p
                        className="text-truncate"
                        data-toggle="tooltip"
                        title={image.name}
                      >
                        {image.name}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    folders: state.imagelibrary.folders || [],
    images: state.imagelibrary.images || [],
    parent_folders: state.imagelibrary.parent_folders || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchImages: (tenantId, relativePath, callback) =>
    dispatch(fetchImages(tenantId, relativePath, callback)),
  addImage: (tenantId, { caption, image, relativePath }, callback) =>
    dispatch(addImage(tenantId, { caption, image, relativePath }, callback)),
  clearDropzone: () => dispatch(reset("imageLibrary")),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ImageLibrary));
