import React, { Component } from "react";
import * as Icon from "react-feather";

class ConditionsAndInstructions extends Component {

  render() {
    const {
      sectionNumber,
      handleConditionChange,
      handleAddCondition,
      handleRemoveCondition,
      conditions,
      newCondition,
      handleInstructionChange,
      handleAddInstruction,
      handleRemoveInstruction,
      instructions,
      newInstruction
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Conditions and redeem instructions
          </strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">

              <div className="input-group mb-2">
                <input type="text" className="form-control" id="addCondition" value={newCondition} placeholder={`New condition`} onChange={handleConditionChange} />
                <div className="input-group-append">
                  <button type="button" className="btn btn-outline-secondary" onClick={handleAddCondition}>Add</button>
                </div>
              </div>

              <div className="mb-3">
                <strong>Conditions</strong>
              </div>
              {conditions.map((condition, index) => (
                <div className="row" key={index}>
                  <div className="col-9">
                    {`#${index + 1} ${condition}`}
                  </div>
                  <div className="col-3">

                    <button
                      type="button"
                      className="btn"
                      title="Remove"
                      onClick={() => handleRemoveCondition(condition)}
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="input-group mb-2">
                <input type="text" className="form-control" id="addInstruction" value={newInstruction} placeholder={`New instruction`} onChange={handleInstructionChange} />
                <div className="input-group-append">
                  <button type="button" className="btn btn-outline-secondary" onClick={handleAddInstruction}>Add</button>
                </div>
              </div>
              <div className="mb-3">
                <strong>Instructions</strong>
              </div>
              {instructions.map((instruction, index) => (
                <div className="row" key={index}>
                  <div className="col-9">
                    {`#${index + 1} ${instruction}`}
                  </div>
                  <div className="col-3">

                    <button
                      type="button"
                      className="btn"
                      title="Remove"
                      onClick={() => handleRemoveInstruction(instruction)}
                    >
                      <Icon.Trash2 size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConditionsAndInstructions;