import {
  SAVE_HISTORICAL_ORDER,
  FETCH_HISTORICAL_ORDERS,
  HISTORICAL_ORDER_ERROR,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  orders: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_HISTORICAL_ORDER:
      return {
        ...state,
        orders: [action.payload.order, ...state.orders],
      };
    case FETCH_HISTORICAL_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
      };

    case HISTORICAL_ORDER_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
