import axios from "axios";

import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATION,
  ACCEPT_RESERVATION,
  REJECT_RESERVATION,
  RESERVATION_ERROR,
  SEND_EMAIL
} from "./types";

import { errorHandler, getData, patchData } from "./index";

export function acceptReservation(reservationId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/reservations/${reservationId}/accept`;
  return dispatch => patchData(ACCEPT_RESERVATION, RESERVATION_ERROR, true, url, dispatch);
}

export function rejectReservation(reservationId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/reservations/${reservationId}/reject`;
  return dispatch => patchData(REJECT_RESERVATION, RESERVATION_ERROR, true, url, dispatch);
}

export function fetchReservations(restaurantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/reservations`;
  return dispatch =>
    getData(FETCH_RESERVATIONS, RESERVATION_ERROR, true, url, dispatch);
}

export function fetchReservation(reservationId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/reservations/${reservationId}`;
  return dispatch =>
    getData(FETCH_RESERVATION, RESERVATION_ERROR, true, url, dispatch);
}

export function sendEmail({ sender, recipient, subject, message }, callback) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eatery/communication/email`, { sender, recipient, subject, message })
      .then((response) => {
        callback()
        dispatch({
          type: SEND_EMAIL,
          payload: response.data.message
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, RESERVATION_ERROR);
      });
  };
}
