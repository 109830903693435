import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import * as Icon from "react-feather";
import * as FaIcons from "react-icons/fa";

import {
  fetchPromotions,
  enablePromo,
  disablePromo,
  duplicatePromo,
  deletePromo,
} from "../../actions/promotion";
import SelectPromotionType from "./SelectPromotionType";
import BuyOneGetOneFreePromo from "./BuyOneGetOneFreePromo";
import PercentDiscountOnSelectedItemsPromo from "./PercentDiscountOnSelectedItemsPromo";
import FixedDiscountAmountOnCartPromo from "./FixedDiscountAmountOnCartPromo";
import PercentDiscountOnCartPromo from "./PercentDiscountOnCartPromo";
import FreeDeliveryPromo from "./FreeDeliveryPromo";
import PaymentMethodRewardPromo from "./PaymentMethodRewardPromo";
import ManageLocations from "./ManageLocations";

import Switch from "react-switch";

class Promotions extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleManageLocations = this.handleManageLocations.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleViewPromotions = this.handleViewPromotions.bind(this);
    this.handleAddPromo = this.handleAddPromo.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);

    this.state = {
      mode: undefined,
      promotions: undefined,
      promoType: undefined,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchPromotions(this.props.tenant._id);
    }
  }

  handleAdd() {
    this.setState({ mode: "Add" });
  }

  handleAddPromo(promoType) {
    this.setState({ promoType: promoType });
  }

  handleEdit(promotion) {
    this.setState({
      mode: "Edit",
      promotion: promotion,
      promoType: promotion.type,
    });
  }

  handleManageLocations(promotion) {
    this.setState({
      mode: "Locations",
      promotion: promotion,
      promoType: promotion.type,
    });
  }

  handleDuplicate(promotion) {
    this.props.duplicatePromo(this.props.tenant._id, promotion, () => {});
  }

  handleDelete(promotion) {
    this.props.deletePromo(promotion._id, () => {});
  }

  handleViewPromotions() {
    this.setState({ mode: "View", promoType: undefined });
  }

  handleStatusChange(checked, promotion) {
    if (checked) {
      this.props.enablePromo(this.props.tenant._id, promotion._id, () => {});
    } else {
      this.props.disablePromo(this.props.tenant._id, promotion._id, () => {});
    }
  }

  componentDidUpdate() {}

  render() {
    const { tenant, promotions } = this.props;

    if (tenant === undefined) {
      return <></>;
    }

    if (this.state.mode === "Add") {
      if (!this.state.promoType) {
        return (
          <SelectPromotionType
            handleViewPromos={this.handleViewPromotions}
            handleAddPromo={this.handleAddPromo}
          />
        );
      }

      if (this.state.promoType === "Buy one, get one free") {
        return (
          <BuyOneGetOneFreePromo handleViewPromos={this.handleViewPromotions} />
        );
      }

      if (this.state.promoType === "% discount on selected items") {
        return (
          <PercentDiscountOnSelectedItemsPromo
            handleViewPromos={this.handleViewPromotions}
          />
        );
      }

      if (this.state.promoType === "Fixed discount amount on cart") {
        return (
          <FixedDiscountAmountOnCartPromo
            handleViewPromos={this.handleViewPromotions}
          />
        );
      }

      if (this.state.promoType === "% discount on cart") {
        return (
          <PercentDiscountOnCartPromo
            handleViewPromos={this.handleViewPromotions}
          />
        );
      }

      if (this.state.promoType === "Free delivery") {
        return (
          <FreeDeliveryPromo handleViewPromos={this.handleViewPromotions} />
        );
      }

      if (this.state.promoType === "Payment method reward") {
        return (
          <PaymentMethodRewardPromo
            handleViewPromos={this.handleViewPromotions}
          />
        );
      }
    }
    if (this.state.mode === "Edit") {
      if (this.state.promoType === "Buy one, get one free") {
        return (
          <BuyOneGetOneFreePromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }

      if (this.state.promoType === "% discount on selected items") {
        return (
          <PercentDiscountOnSelectedItemsPromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }

      if (this.state.promoType === "Fixed discount amount on cart") {
        return (
          <FixedDiscountAmountOnCartPromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }

      if (this.state.promoType === "% discount on cart") {
        return (
          <PercentDiscountOnCartPromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }

      if (this.state.promoType === "Free delivery") {
        return (
          <FreeDeliveryPromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }

      if (this.state.promoType === "Payment method reward") {
        return (
          <PaymentMethodRewardPromo
            handleViewPromos={this.handleViewPromotions}
            promotion={this.state.promotion}
          />
        );
      }
    }

    if (this.state.mode === "Locations") {
      return (
        <ManageLocations
          handleViewPromos={this.handleViewPromotions}
          promotion={this.state.promotion}
        />
      );
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <FaIcons.FaTags />
                {` `}
                <span>{`Promotions`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <button className="btn btn-primary" onClick={this.handleAdd}>
              <Icon.Plus size={16} />
              {` `}New Promotion
            </button>
          </div>
        </div>
        <div className="row border-bottom pb-1 mb-3">
          <div className="col-xl-1 d-none d-xl-inline text-left">
            <span>Status</span>
          </div>
          <div className="col-xl-3 d-none d-xl-inline text-left">
            <span>Name</span>
          </div>
          <div className="col-xl-3 d-none d-xl-inline text-left">
            <span>Type</span>
          </div>
          <div className="col-xl-2 d-none d-xl-inline text-left">
            <span>Coupon</span>
          </div>
          <div className="col-xl-1 d-none d-xl-inline text-left">
            <span>Created</span>
          </div>
          <div className="col-xl-3 d-none d-xl-inline text-right">{` `}</div>
        </div>
        {promotions.map((promotion) => (
          <div className="row border-bottom pb-1 mb-3" key={promotion._id}>
            <div className="col-12 col-xl-1 text-left text-xl-left">
              <span className="d-inline d-xl-none">
                <strong>Status: </strong>{" "}
              </span>{" "}
              <Switch
                className="react-switch"
                onChange={(checked) =>
                  this.handleStatusChange(checked, promotion)
                }
                checked={!promotion.disabled}
              />
            </div>
            <div className="col-12 col-xl-3 text-left text-xl-left">
              <span className="d-inline d-xl-none">
                <strong>Name: </strong>{" "}
              </span>{" "}
              <span>{promotion.name}</span>
            </div>
            <div className="col-12 col-xl-3 text-left text-xl-left">
              <span className="d-inline d-xl-none">
                <strong>Type: </strong>{" "}
              </span>{" "}
              <span>{promotion.type}</span>
            </div>
            <div className="col-12 col-xl-2 text-left text-xl-left">
              <span className="d-inline d-xl-none">
                <strong>Coupon: </strong>{" "}
              </span>{" "}
              <span className="text-uppercase">{promotion.coupon_code}</span>
            </div>
            <div className="col-12 col-xl-1 text-left text-xl-left">
              <span className="d-inline d-xl-none">
                <strong>Created: </strong>{" "}
              </span>{" "}
              <span>
                {moment(promotion.createdAt).format(
                  tenant.settings.date_format
                )}
              </span>
            </div>
            <div className="col-12 col-xl-2 text-center text-xl-right">
              <div className="btn-toolbar">
                <div className="btn-group btn-group-sm">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.handleDuplicate(promotion)}
                  >
                    <Icon.Copy />
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.handleEdit(promotion)}
                  >
                    <Icon.Edit2 />
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.handleManageLocations(promotion)}
                  >
                    <Icon.MapPin />
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.handleDelete(promotion)}
                    disabled={!promotion.disabled}
                  >
                    <Icon.Trash2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    promotions: state.promotion.promotions || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPromotions: (tenantId) => dispatch(fetchPromotions(tenantId)),
  enablePromo: (tenantId, promotionId, callback) =>
    dispatch(enablePromo(tenantId, promotionId, callback)),
  disablePromo: (tenantId, promotionId, callback) =>
    dispatch(disablePromo(tenantId, promotionId, callback)),
  duplicatePromo: (tenantId, promotion, callback) =>
    dispatch(duplicatePromo(tenantId, promotion, callback)),
  deletePromo: (promotionId, callback) =>
    dispatch(deletePromo(promotionId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
