import React, { Component } from "react";

class AllowedPaymentMethod extends Component {
  render() {
    const {
      allowCash,
      handleAllowCashChange,
      allowCod,
      handleAllowCodChange,
      allowPhone,
      handleAllowPhoneChange,
      allowOnline,
      handleAllowOnlineChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Allowed payment method:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="allowed_payment_method_cash"
              defaultChecked={allowCash}
              onChange={handleAllowCashChange}
            />
            <label className="form-check-label">{`Cash`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="allowed_payment_method_cod"
              defaultChecked={allowCod}
              onChange={handleAllowCodChange}
            />
            <label className="form-check-label">
              {`Cash to delivery person or at pickup counter`}
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="allowed_payment_method_phone"
              defaultChecked={allowPhone}
              onChange={handleAllowPhoneChange}
            />
            <label className="form-check-label">
              {`Call back and take card details via phone `}
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              name="allowed_payment_method_online"
              defaultChecked={allowOnline}
              onChange={handleAllowOnlineChange}
            />
            <label className="form-check-label">
              {`Online payments`}
            </label>
          </div>
        </div>
      </div>

    );
  }
}

export default AllowedPaymentMethod;