import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import Switch from "react-switch";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "membershipSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class MembershipSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      tenantId: props.tenant._id,
      membership: props.tenant.settings.membership
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        membership: this.state.membership
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleViewSettings
    } = this.props;

    return (
      <>
      <nav aria-label="breadcrumb" className="pt-3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewSettings}
            >
              <i className="fas fa-cog" />
              {` `}
              <span className="d-none d-sm-inline">{`Settings`}</span>
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <button type="button" className="btn btn-light" disabled>
              <i className="fa fa-users" />
              {` `}
              <span className="d-none d-sm-inline">{`Membership`}</span>
            </button>
          </li>
        </ol>
      </nav>
      {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
      {this.renderAlert()}

      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          {`Tenant`}
        </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <input
            type="text"
            readOnly
            className="form-control-plaintext"
            value={this.props.tenant.name}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          {`Membership`}
        </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <Switch
            className="react-switch"
            onChange={() => { this.setState(prevState => { return { membership: !prevState.membership } }) }}
            checked={this.state.membership || false}
            uncheckedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  paddingRight: 2
                }}
              >
                No
                  </div>
            }
            checkedIcon={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 15,
                  paddingRight: 2
                }}
              >
                Yes
                  </div>
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center border-top pb-3 pt-3">
          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={this.props.handleViewSettings}
          >
            {`Cancel`}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleFormSubmit}
          >
            {"Save"}
          </button>
        </div>
      </div>

      {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MembershipSettings));
