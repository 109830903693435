import React, { Component } from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

const isSame = (item1, item2) => {
  const result =
    Object.is(item1.menuItem, item2.menuItem) &&
    Object.is(item1.size, item2.size) &&
    isEqual(item1.options, item2.options) &&
    isEqual(item1.specialInstructions, item2.specialInstructions);
  return result;
};

const isEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

class KitchenOrder extends Component {
  render() {
    const { order, restaurant, onHandleStartOrder, onHandleCompleteOrder } =
      this.props;
    if (!order) {
      return <></>;
    }

    let items = [];
    if (order.items) {
      order.items.forEach((item) => {
        items.push(Object.assign({}, item));
      });
    }
    if (order.promotions) {
      order.promotions.forEach((promo) => {
        promo.discountedItems.forEach((item1) => {
          let found = false;
          items.forEach((item2) => {
            if (isSame(item2, item1)) {
              item2.quantity += item1.quantity;
              found = true;
            }
          });
          if (!found) {
            items.push(Object.assign({}, item1));
          }
        });
      });
    }

    return (
      <div className="card">
        <h5 className="card-header">
          {order.adjustedPickupTime
            ? moment(order.adjustedPickupTime)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)
            : moment(order.pickupTime)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
        </h5>
        <div className="card-body">
          <h5 className="card-title">
            {order.orderType} / {order.deliveryMethod}
          </h5>
          <div className="row">
            <div className="col-12">
              <strong>Order details:</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>Number:</span>
            </div>
            <div className="col-6 text-right">
              <span>{order.orderNumber}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <span>Accepted at:</span>
            </div>
            <div className="col-6 text-right">
              <span>
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            </div>
          </div>
          {order.nameOrderedUnder && (
            <div className="row">
              <div className="col-6">
                <span>Customer Name:</span>
              </div>
              <div className="col-6 text-right">
                <span>{order.nameOrderedUnder}</span>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-6">
              <span>Phone:</span>
            </div>
            <div className="col-6 text-right">
              <span>{order.phone}</span>
            </div>
          </div>
          {order.email && (
            <div className="row">
              <div className="col-6">
                <span>Email:</span>
              </div>
              <div className="col-6 text-right">
                <span>{order.email}</span>
              </div>
            </div>
          )}
          {order.address && (
            <div className="row">
              <div className="col-6">
                <span>Address:</span>
              </div>
              <div className="col-6 text-right">
                <span>{order.address}</span>
              </div>
            </div>
          )}

          {order.specialRequests && (
            <div className="row">
              <div className="col-2">
                <i className="fas fa-comment" />
              </div>
              <div className="col-10">
                <strong>{order.specialRequests}</strong>
              </div>
            </div>
          )}
          <div className="row pt-3 border-bottom mb-3">
            <div className="col-12">
              <strong>Item:</strong>
            </div>
          </div>
          {items.map((item) => (
            <div key={item._id} className="row border-bottom mb-3">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <strong>
                      <span>{`${item.quantity}X `}</span>
                    </strong>
                    {item.cd && <span>{`${item.cd}. `}</span>}
                    <span>{item.name}</span>

                    <ul className="list-unstyled ml-3">
                      {item.size && (
                        <li className="text-muted font-italic">
                          <small>Size: {item.size}</small>
                        </li>
                      )}
                      {item.options.map((option, index) => (
                        <li className="text-muted font-italic" key={index}>
                          <small>
                            {option.name}{" "}
                            {option.value && <span>: {option.value}</span>}
                          </small>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {item.specialInstructions &&
                  item.specialInstructions.lenght > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <i className="fas fa-comment mr-3" />
                        {item.specialInstructions.map((instruction, index) => (
                          <small className="highlight-text mr-3" key={index}>
                            {instruction}
                          </small>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-6">{`Subtotal`}</div>
            <div className="col-6 text-right">
              {Numeral(order.subtotal).format("$0,0.00")}
            </div>
          </div>
          {order.tax && order.tax > 0 && (
            <div className="row">
              <div className="col-6">{`Tax`}</div>
              <div className="col-6 text-right">
                {Numeral(order.tax).format("$0,0.00")}
              </div>
            </div>
          )}
          <div className="row mb-3">
            <div className="col-6">
              <strong>{`Total`}</strong>
            </div>
            <div className="col-6 text-right">
              <strong>{Numeral(order.total).format("$0,0.00")}</strong>
            </div>
          </div>
        </div>
        <div className="card-footer text-center">
          {order.status === "Accepted" && (
            <button className="btn btn-primary" onClick={onHandleStartOrder}>
              Start
            </button>
          )}
          {order.status === "InPreparation" && (
            <>
              <span className="mr-3">Under Cooking</span>
              <button
                className="btn btn-primary"
                onClick={onHandleCompleteOrder}
              >
                Complete
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default KitchenOrder;
