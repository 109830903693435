import React, { Component } from "react";
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  reset,
  SubmissionError,
} from "redux-form";
// import { SubmissionError } from "redux-form/immutable";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import {
  renderPhoneField,
  required,
  phoneNumber,
  renderField,
} from "../../utils/renderFields";

import { saveOrder } from "../../actions/order";

import normalizePhone from "../../utils/normalizePhone";

import {
  fetchPublishedOnlineOrderMenus,
  fetchPickupDates,
  fetchPromotions,
} from "../../actions/restaurant";

import Cart from "../Cart/Cart";

import MenuItem from "./MenuItem";

import moment from "moment-timezone";

import { socket } from "../../service/socket";

import AliceCarousel from "react-alice-carousel";
// import "react-alice-carousel/lib/alice-carousel.css"

import { getOpeningHourRange } from "../Common/OpeningHourRange";

import {
  fetchCustomerFrequentlyOrderedItems,
  clearCustomerFrequentlyOrderedItems,
  // fetchPromotions,
} from "../../actions/tenant";

import {
  getSubtotal,
  getTax,
  getGST,
  getPST,
  getQuantity,
  getDiscountTotal,
  getCouponTotal,
  getRedemptionTotal,
  applyPromotions,
} from "../../actions/cart";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import { printOrder } from "../Print/PrintOrder";

import { isPointWithinRadius } from "geolib";

import Cookies from "universal-cookie";

import axios from "axios";

import Numeral from "numeral";

const afterSubmit = (result, dispatch) => dispatch(reset("takeOrder"));

const form = reduxForm({
  form: "takeOrder",
  onSubmitSuccess: afterSubmit,
  validate,
});

const selector = formValueSelector("takeOrder");

function validate(formProps) {
  const errors = {};

  if (!formProps.phone) {
    errors.name = "Please enter phone number";
  }

  if (!formProps.deliveryMethod) {
    errors.deliveryMethod = "Please choose one delivery method";
  }

  if (!formProps.timeWantedType) {
    errors.timeWanted = "Please choose one time wanted";
  }

  if (formProps.timeWantedIn === 0 && formProps.timeWantedAt === 0) {
    errors.timeWanted = "Please choose one pickup/delivery time";
  }

  return errors;
}

const calculateTimeslots = (startAt, endAt, intervalInMinutes, tenant) => {
  if (!intervalInMinutes || intervalInMinutes <= 0) {
    intervalInMinutes = 15;
  }
  let timeslots = [];
  let duration = moment.duration(endAt.diff(startAt));
  let minutes = duration.asMinutes();
  let count = Math.ceil(minutes / intervalInMinutes);
  for (let i = 0; i <= count; i++) {
    const timeslot = moment(startAt)
      .tz(tenant.settings.time_zone)
      .add(i * intervalInMinutes, "minutes");
    if (timeslot.isAfter(moment().tz(tenant.settings.time_zone))) {
      timeslots.push(timeslot);
    }
  }

  return timeslots;
};

const cookies = new Cookies();
class TakeOrder extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleGotIt = this.handleGotIt.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFetchCustomerFrequentlyOrderedItems =
      this.handleFetchCustomerFrequentlyOrderedItems.bind(this);

    this.clearFilterText = this.clearFilterText.bind(this);
    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleFieldKeyPress = this.handleFieldKeyPress.bind(this);

    this.handleBlur = this.handleBlur.bind(this);
    this.getDeliveryDistance = this.getDeliveryDistance.bind(this);
    this.getDeliveryFee = this.getDeliveryFee.bind(this);
    this.getDeliveryGST = this.getDeliveryGST.bind(this);

    this.handleDeliveryMethodChange =
      this.handleDeliveryMethodChange.bind(this);
    this.handleDateWantedChange = this.handleDateWantedChange.bind(this);
    this.handleTimeWantedTypeChange =
      this.handleTimeWantedTypeChange.bind(this);
    this.handleTimeWantedInChange = this.handleTimeWantedInChange.bind(this);
    this.handleTimeWantedAtChange = this.handleTimeWantedAtChange.bind(this);

    this.state = {
      currentIndex: 0,
      responsive: {
        0: {
          items: 2,
        },
      },
      address: "",
      postal_code: "",
      lat_lng: undefined,
      selectedRestaurantId: undefined,
      delivery_distance: undefined,
      deliveryFee: 0,
      deliveryGST: 0,
    };
  }

  componentDidMount() {
    if (this.props.restaurants.length > 0) {
      this.props.fetchPublishedOnlineOrderMenus(this.props.restaurants[0]._id);
      this.props.fetchPickupDates(this.props.restaurants[0]._id);
      this.props.fetchPromotions(this.props.restaurants[0]._id);
      this.setState({
        selectedRestaurantId: this.props.restaurants[0]._id,
        selectedRestaurant: this.props.restaurants[0],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { change } = this.props;

    if (this.props.customerFrequentlyOrderedItems) {
      change(
        "takeOrder",
        "nameOrderedUnder",
        this.props.customerFrequentlyOrderedItems.name
      );
    }
  }

  handleFetchCustomerFrequentlyOrderedItems(
    event,
    newValue,
    previousValue,
    name
  ) {
    this.props.fetchCustomerFrequentlyOrderedItems(
      this.props.tenant._id,
      newValue,
      () => {}
    );
  }

  async getDeliveryDistance(restaurant, destination_place_id) {
    const url = `${process.env.REACT_APP_API_BASE}/api/eatery/map/direction?origin=${restaurant.latitude},${restaurant.longitude}&destination=place_id:${destination_place_id}`;
    try {
      const response = await axios.get(url);
      return response.data.direction.routes[0].legs[0].distance.value;
    } catch (error) {
      console.log(error);
    }
  }

  handleFormSubmit(formProps) {
    if (!formProps.phone) {
      throw new SubmissionError({
        phone: "Please enter phone number",
        _error: "Save order failed!",
      });
    }

    if (!formProps.deliveryMethod) {
      throw new SubmissionError({
        deliveryMethod: "Please choose one delivery method",
        _error: "Save order failed!",
      });
    }

    if (!formProps.timeWantedType) {
      throw new SubmissionError({
        timeWantedType: "Please choose one timeWanted type",
        _error: "Save order failed!",
      });
    }

    if (formProps.timeWantedType === "in" && formProps.timeWantedIn === 0) {
      throw new SubmissionError({
        timeWantedIn: "Please choose one pickup/delivery time",
        _error: "Save order failed!",
      });
    }

    if (formProps.timeWantedType === "at" && formProps.timeWantedAt === 0) {
      throw new SubmissionError({
        timeWantedAt: "Please choose one pickup/delivery time",
        _error: "Save order failed!",
      });
    }

    const orderLink = window.location.href.includes("localhost")
      ? window.location.href.replace(":3010", ":3011")
      : `${this.props.tenant.website_url}/order`;

    const isCallInOrder = true;

    let timeWanted = moment();
    if (formProps.timeWantedType === "in" && formProps.timeWantedIn > 0) {
      timeWanted = timeWanted.add(formProps.timeWantedIn, "minutes");
    } else {
      if (formProps.timeWantedAt) {
        timeWanted = moment(parseInt(formProps.timeWantedAt));
      }
    }

    const deliveryAddress = {
      address: this.state.address,
      postalCode: this.state.postal_code,
      // addressType: formProps.addressType,
      addressType: "Home",
      suiteApartmentNumber: formProps.suiteApartmentNumber,
      businessName: formProps.businessName,
      otherVenueName: formProps.otherVenueName,
      distanceInMeter: this.state.delivery_distance,
    };

    const selectedRestaurant = this.props.restaurants.find(
      (r) => r._id === this.state.selectedRestaurantId
    );

    const total = Numeral(
      Numeral(
        this.props.subtotal +
          this.props.gst +
          this.props.pst +
          (formProps.deliveryMethod === "Delivery"
            ? this.state.deliveryFee
            : 0) +
          (formProps.deliveryMethod === "Delivery"
            ? this.state.deliveryGST
            : 0) -
          this.props.discountTotal -
          this.props.couponTotal -
          this.props.redemptionTotal
      ).format("$0,0.00")
    ).value();

    this.props.saveOrder(
      isCallInOrder,
      this.state.selectedRestaurantId,
      this.props.items,
      this.props.promotions,
      this.props.discounts,
      this.props.discountTotal,
      this.props.subtotal,
      this.props.tax,
      this.props.gst,
      this.props.pst,
      total,
      formProps.specialRequests,
      this.props.pickupDates[formProps.dateWanted],
      Number(timeWanted),
      formProps.nameOrderedUnder,
      formProps.phone,
      formProps.deliveryMethod === "Delivery" ? this.state.address : undefined,
      "Offline",
      formProps.deliveryMethod,
      formProps.deliveryMethod === "Delivery" ? deliveryAddress : undefined,
      formProps.deliveryMethod === "Delivery" ? this.state.deliveryFee : 0,
      formProps.deliveryMethod === "Delivery" ? this.state.deliveryGST : 0,
      orderLink,
      (data) => {
        this.setState({ order: data.order });
        socket.emit(
          "new order",
          this.state.selectedRestaurantId,
          data.order,
          this.props.tenant.settings.time_zone
        );

        this.props.clearCustomerFrequentlyOrderedItems();

        if (
          selectedRestaurant.settings.printing_settings
            .print_call_in_order_after_save
        ) {
          printOrder(
            data.order,
            null,
            selectedRestaurant,
            this.props.tenant,
            true,
            true
          );
        }
      }
    );
  }

  handleGotIt() {
    this.setState({
      order: undefined,
      filterText: undefined,
      address: undefined,
    });
  }

  clearFilterText() {
    this.props.dispatch(change("takeOrder", "filterText", ""));
  }

  handleChange = (address) => {
    this.setState({ address: address });
  };

  async handleBlur() {
    const selectedRestaurant = this.props.restaurants.find(
      (r) => r._id === this.state.selectedRestaurantId
    );
    try {
      const results = await geocodeByAddress(this.state.address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;
      const deliveryDistance = await this.getDeliveryDistance(
        selectedRestaurant,
        results[0].place_id
      );
      const deliveryFee = this.getDeliveryFee(
        this.state.selectedRestaurant,
        deliveryDistance
      );
      const deliveryGST = this.getDeliveryGST(
        this.state.selectedRestaurant,
        deliveryDistance,
        this.state.selectedRestaurant.settings.gst_rate
      );
      this.setState(
        {
          postal_code: postalCode,
          lat_lng: latLng,
          address: this.state.address,
          delivery_distance: deliveryDistance,
          deliveryFee: deliveryFee,
          deliveryGST: deliveryGST,
        },
        () => {
          let timeWanted = moment();
          if (
            this.props.timeWantedTypeValue === "in" &&
            this.props.timeWantedInValue > 0
          ) {
            timeWanted = timeWanted.add(
              this.props.timeWantedInValue,
              "minutes"
            );
          } else {
            if (this.props.timeWantedAtValue) {
              timeWanted = moment(parseInt(this.props.timeWantedAtValue));
            }
          }

          if (this.props.promotionRules?.length > 0) {
            this.props.applyPromotions(
              this.props.promotionRules,
              this.state.selectedRestaurant.settings.time_zone,
              this.state.selectedRestaurant.settings.time_format,
              this.props.deliveryMethodValue,
              timeWanted,
              this.props.pickupDates[this.props.dateWantedValue],
              undefined,
              this.state.delivery_distance,
              this.state.postal_code,
              this.state.deliveryFee,
              this.state.deliveryGST,
              this.state.selectedRestaurant.id
            );
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  async handleSelect(address) {
    // this.setState({ address });
    const selectedRestaurant = this.props.restaurants.find(
      (r) => r._id === this.state.selectedRestaurantId
    );
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const postalCode = results[0].address_components.filter((ac) =>
        ac.types.includes("postal_code")
      )[0].short_name;
      const deliveryDistance = await this.getDeliveryDistance(
        selectedRestaurant,
        results[0].place_id
      );
      this.setState({
        postal_code: postalCode,
        lat_lng: latLng,
        address: address,
        delivery_distance: deliveryDistance,
      });
    } catch (error) {
      console.log(error);
    }
  }

  handleSelectRestaurant(event) {
    this.setState(
      {
        selectedRestaurantId: event.target.value,
        selectedRestaurant: this.props.restaurants.find(
          (r) => r._id === event.target.value
        ),
      },
      () => {
        this.props.fetchPublishedOnlineOrderMenus(
          this.state.selectedRestaurantId
        );
        this.props.fetchPickupDates(this.state.selectedRestaurantId);
        this.props.fetchPromotions(this.state.selectedRestaurantId);
      }
    );
  }

  handleFieldKeyPress(e) {
    if (e.key === "Enter") {
      if (e.target.name === "filterText") {
        let items = [];

        this.props.menus.forEach((menu) => {
          menu.sections.forEach((section) => {
            section.items.forEach((item) => {
              items.push(item);
            });
          });
        });

        const filterTextValue = e.target.value;

        const filteredItems = filterTextValue
          ? items
              .filter(
                (item) =>
                  item.cd &&
                  item.cd
                    .toUpperCase()
                    .startsWith(filterTextValue.toUpperCase().trim())
              )
              .concat(
                items.filter((item) =>
                  item.name
                    .toUpperCase()
                    .includes(filterTextValue.toUpperCase().trim())
                )
              )
          : [];

        if (!filterTextValue || filteredItems.length < 1) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    }
  }

  handleDeliveryMethodChange(event) {
    let timeWanted = moment();
    if (
      this.props.timeWantedTypeValue === "in" &&
      this.props.timeWantedInValue > 0
    ) {
      timeWanted = timeWanted.add(this.props.timeWantedInValue, "minutes");
    } else {
      if (this.props.timeWantedAtValue) {
        timeWanted = moment(parseInt(this.props.timeWantedAtValue));
      }
    }

    if (this.props.promotionRules?.length > 0) {
      this.props.applyPromotions(
        this.props.promotionRules,
        this.state.selectedRestaurant.settings.time_zone,
        this.state.selectedRestaurant.settings.time_format,
        event.target.value,
        timeWanted,
        this.props.pickupDates[this.props.dateWantedValue],
        undefined,
        this.state.delivery_distance,
        this.state.postal_code,
        this.state.deliveryFee,
        this.state.deliveryGST,
        this.state.selectedRestaurant.id
      );
    }
  }

  handleDateWantedChange(event) {
    let timeWanted = moment();
    if (
      this.props.timeWantedTypeValue === "in" &&
      this.props.timeWantedInValue > 0
    ) {
      timeWanted = timeWanted.add(this.props.timeWantedInValue, "minutes");
    } else {
      if (this.props.timeWantedAtValue) {
        timeWanted = moment(parseInt(this.props.timeWantedAtValue));
      }
    }

    if (this.props.promotionRules?.length > 0) {
      this.props.applyPromotions(
        this.props.promotionRules,
        this.state.selectedRestaurant.settings.time_zone,
        this.state.selectedRestaurant.settings.time_format,
        this.props.deliveryMethodValue,
        timeWanted,
        this.props.pickupDates[event.target.value],
        undefined,
        this.state.delivery_distance,
        this.state.postal_code,
        this.state.deliveryFee,
        this.state.deliveryGST,
        this.state.selectedRestaurant.id
      );
    }
  }

  handleTimeWantedTypeChange(event) {
    let timeWanted = moment();
    if (event.target.value === "in" && this.props.timeWantedInValue > 0) {
      timeWanted = timeWanted.add(this.props.timeWantedInValue, "minutes");
    } else {
      if (this.props.timeWantedAtValue) {
        timeWanted = moment(parseInt(this.props.timeWantedAtValue));
      }
    }

    if (this.props.promotionRules?.length > 0) {
      this.props.applyPromotions(
        this.props.promotionRules,
        this.state.selectedRestaurant.settings.time_zone,
        this.state.selectedRestaurant.settings.time_format,
        this.props.deliveryMethodValue,
        timeWanted,
        this.props.pickupDates[event.target.value],
        undefined,
        this.state.delivery_distance,
        this.state.postal_code,
        this.state.deliveryFee,
        this.state.deliveryGST,
        this.state.selectedRestaurant.id
      );
    }
  }

  handleTimeWantedInChange(event) {
    let timeWanted = moment();
    if (this.props.timeWantedTypeValue === "in" && event.target.value > 0) {
      timeWanted = timeWanted.add(event.target.value, "minutes");
    } else {
      if (this.props.timeWantedAtValue) {
        timeWanted = moment(parseInt(this.props.timeWantedAtValue));
      }
    }

    if (this.props.promotionRules?.length > 0) {
      this.props.applyPromotions(
        this.props.promotionRules,
        this.state.selectedRestaurant.settings.time_zone,
        this.state.selectedRestaurant.settings.time_format,
        this.props.deliveryMethodValue,
        timeWanted,
        this.props.pickupDates[event.target.value],
        undefined,
        this.state.delivery_distance,
        this.state.postal_code,
        this.state.deliveryFee,
        this.state.deliveryGST,
        this.state.selectedRestaurant.id
      );
    }
  }

  handleTimeWantedAtChange(event) {
    let timeWanted = moment();
    if (
      this.props.timeWantedTypeValue === "in" &&
      this.props.timeWantedInValue > 0
    ) {
      timeWanted = timeWanted.add(this.props.timeWantedInValue, "minutes");
    } else {
      if (event.target.value) {
        timeWanted = moment(parseInt(event.target.value));
      }
    }

    if (this.props.promotionRules?.length > 0) {
      this.props.applyPromotions(
        this.props.promotionRules,
        this.state.selectedRestaurant.settings.time_zone,
        this.state.selectedRestaurant.settings.time_format,
        this.props.deliveryMethodValue,
        timeWanted,
        this.props.pickupDates[event.target.value],
        undefined,
        this.state.delivery_distance,
        this.state.postal_code,
        this.state.deliveryFee,
        this.state.deliveryGST,
        this.state.selectedRestaurant.id
      );
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  getDeliveryFee(restaurant, deliveryDistance) {
    let deliveryFee = 0;
    if (this.props.deliveryMethodValue === "Delivery") {
      const flat_delivery_fee =
        restaurant.settings.delivery_options.delivery_fee;
      const delivery_fee_ranges =
        restaurant.settings.delivery_options.delivery_fees;
      const delivery_distance = deliveryDistance / 1000;

      if (delivery_fee_ranges && delivery_fee_ranges.length > 0) {
        for (const dfr of delivery_fee_ranges) {
          if (
            delivery_distance > dfr.min_radius &&
            delivery_distance <= dfr.max_radius
          ) {
            deliveryFee = dfr.fee;
            break;
          }
        }
      } else if (flat_delivery_fee) {
        deliveryFee = flat_delivery_fee;
      }
    }

    return Numeral(Numeral(deliveryFee).format("$0,0.00")).value();
  }

  getDeliveryGST(restaurant, deliveryDistance, gstRate) {
    const deliveryGST =
      this.getDeliveryFee(restaurant, deliveryDistance) * (gstRate / 100);
    return Numeral(Numeral(deliveryGST).format("$0,0.00")).value();
  }

  render() {
    const {
      tenant,
      menus = [],
      handleSubmit,
      deliveryMethodValue,
      timeWantedTypeValue,
      holidayHours = [],
      pristine,
      reset,
      submitting,
      filterTextValue,
      pickupDates = [],
      applicableRestaurants,
    } = this.props;

    const selectedRestaurant = this.props.restaurants.find(
      (r) => r._id === this.state.selectedRestaurantId
    );

    if (!tenant || !selectedRestaurant) {
      return null;
    }

    if (this.state.order) {
      return (
        <div className="h-100 row align-items-center">
          <div className="col">
            <div className="card border-success text-center mx-auto">
              <div className="card-body">
                <h3 className="card-title">
                  {`Order has been saved successfully`}
                </h3>

                <div className="row">
                  <div className="{col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {this.state.order.orderNumber}
                        </p>
                        <p className="card-text">{`Order Number`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="card border-secondary text-center p-3 m-3">
                      <div className="card-body">
                        <p className="card-text">
                          {moment(this.state.order.pickupTime)
                            .tz(tenant.settings.time_zone)
                            .format(tenant.settings.datetime_format)}
                        </p>
                        <p className="card-text mt-3">{`${this.state.order.deliveryMethod} Time`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-success btn-lg"
                  onClick={this.handleGotIt}
                >
                  {`GOT IT`}
                </button>

                <p className="card-text mt-3">
                  {`Your order was submitted at ${moment(
                    this.state.order.updatedAt
                  )
                    .tz(tenant.settings.time_zone)
                    .format(tenant.settings.datetime_format)}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const { responsive, currentIndex } = this.state;

    let items = [];

    menus.forEach((menu) => {
      menu.sections.forEach((section) => {
        section.items.forEach((item) => {
          items.push(item);
        });
      });
    });

    const postalCodes =
      tenant.settings.delivery_options.callin_order_delivery_area_postal_codes
        ?.split(",")
        .map((postalCode) => postalCode.trim(" "));
    const isInRangeByPostalCode =
      postalCodes
        .map((postalCode) => postalCode.substring(0, 3))
        .filter(
          (postalCode) =>
            postalCode.toLowerCase() ===
            this.state.postal_code.toLowerCase().substring(0, 3)
        ).length > 0;

    const restaurantLatLong = {
      latitude: selectedRestaurant.latitude,
      longitude: selectedRestaurant.longitude,
    };
    const isWithinRadius = this.state.lat_lng
      ? isPointWithinRadius(
          restaurantLatLong,
          this.state.lat_lng,
          tenant.settings.delivery_options.callin_order_delivery_area_radius *
            1000
        )
      : false;

    const searchOptions = {
      location: new window.google.maps.LatLng(
        selectedRestaurant.latitude,
        selectedRestaurant.longitude
      ),
      radius:
        selectedRestaurant.settings.delivery_options
          .callin_order_delivery_area_radius * 1000,
    };

    const isInRange = isInRangeByPostalCode && isWithinRadius;

    let timeRange = getOpeningHourRange(
      selectedRestaurant,
      moment(this.props.pickupDates[this.props.dateWantedValue])
        .tz(tenant.settings.time_zone)
        .startOf("day"),
      selectedRestaurant.regular_business_hours,
      holidayHours,
      tenant.settings.call_in_order_start,
      tenant.settings.call_in_order_end
    );

    let timeslots = calculateTimeslots(
      timeRange.startAt,
      timeRange.endAt,
      tenant.settings.call_in_pickup_time_interval_in_minutes,
      selectedRestaurant
    );

    let frequentlyOrderedItems = [];
    if (this.props.customerFrequentlyOrderedItems) {
      frequentlyOrderedItems = this.props.customerFrequentlyOrderedItems.items;
    }

    const filteredItems = filterTextValue
      ? items
          .filter(
            (item) =>
              item.cd &&
              item.cd
                .toUpperCase()
                .startsWith(filterTextValue.toUpperCase().trim())
          )
          .concat(
            items.filter((item) =>
              item.name
                .toUpperCase()
                .includes(filterTextValue.toUpperCase().trim())
            )
          )
      : [];

    const total = Numeral(
      Numeral(
        this.props.subtotal +
          this.props.gst +
          this.props.pst +
          (deliveryMethodValue === "Delivery" ? this.state.deliveryFee : 0) +
          (deliveryMethodValue === "Delivery" ? this.state.deliveryGST : 0) -
          this.props.discountTotal -
          this.props.couponTotal -
          this.props.redemptionTotal
      ).format("$0,0.00")
    ).value();

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <Icon.PhoneIncoming size={16} /> {` `}
                <span>{`Take Order`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {this.renderAlert()}
        <form
          onSubmit={handleSubmit(this.handleFormSubmit)}
          onKeyPress={this.handleFieldKeyPress}
        >
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label className="required">Restaurant</label>
                  <select
                    name="restaurantId"
                    className="form-control"
                    onChange={this.handleSelectRestaurant}
                  >
                    {applicableRestaurants.map((ar, index) => (
                      <option key={index} value={ar.key}>
                        {ar.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label className="required">Phone</label>
                  <Field
                    name="phone"
                    className="form-control"
                    component={renderPhoneField}
                    validate={[required, phoneNumber]}
                    type="text"
                    normalize={normalizePhone}
                    onBlur={this.handleFetchCustomerFrequentlyOrderedItems}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label>Name</label>
                  <Field
                    name="nameOrderedUnder"
                    className="form-control"
                    component={renderField}
                  />
                </div>
              </div>
              {this.props.customerFrequentlyOrderedItems && (
                <>
                  <div className="alert alert-info">
                    {this.props.customerFrequentlyOrderedItems.names.length >
                      0 && (
                      <div>
                        {`Name: `}
                        {this.props.customerFrequentlyOrderedItems.names.map(
                          (name, idex) => (
                            <span key={idex}>{`${name} `}</span>
                          )
                        )}
                      </div>
                    )}
                    {this.props.customerFrequentlyOrderedItems.addresses
                      .length > 0 && (
                      <div>
                        {`Address: ${this.props.customerFrequentlyOrderedItems.addresses[0]}`}
                        {/* <select className="form-control">
                    {this.props.customerFrequentlyOrderedItems.addresses.map(address => (
                        <option value={address}>{address}</option>
                      ))}
                    </select> */}

                        {/* <ul>
                        {this.props.customerFrequentlyOrderedItems.addresses.slice(0, 1).map(address => (
                          <li>{`${address}`}</li>
                        ))}
                      </ul> */}
                      </div>
                    )}
                  </div>
                  {this.props.customerFrequentlyOrderedItems.isBlocked && (
                    <div className="alert alert-danger">
                      <span>
                        This customer has been blocked for ordering due to
                        suspicious activity, please don't take order.
                      </span>
                    </div>
                  )}
                </>
              )}
              {!this.props.customerFrequentlyOrderedItems?.isBlocked && (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label className="required">Delivery Method</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <Field
                            name="deliveryMethod"
                            component="input"
                            type="radio"
                            value="Pickup"
                            className="form-check-input"
                            onChange={this.handleDeliveryMethodChange}
                          />
                          <label className="form-check-label">{` Pickup `}</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Field
                            name="deliveryMethod"
                            component="input"
                            type="radio"
                            value="Delivery"
                            className="form-check-input"
                            onChange={this.handleDeliveryMethodChange}
                          />
                          <label className="form-check-label">{` Delivery `}</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="required">Date Wanted</label>
                      <Field
                        name="dateWanted"
                        className="form-control"
                        component={"select"}
                        onChange={this.handleDateWantedChange}
                      >
                        {pickupDates.map((pickupDate, index) => (
                          <option key={index} value={index}>{`${moment(
                            pickupDate
                          )
                            .tz(tenant.settings.time_zone)
                            .format(tenant.settings.date_format)}`}</option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="required">Time Wanted</label>
                      <div className="form-row">
                        <div className="col-12">
                          <div className="input-group">
                            <div className="input-group-prepend mr-3">
                              <div className="form-check form-check-inline">
                                <Field
                                  name="timeWantedType"
                                  component="input"
                                  type="radio"
                                  value="in"
                                  className="form-check-input"
                                  onChange={this.handleTimeWantedTypeChange}
                                  // disabled={!isDateWantedToday}
                                />
                                <label className="form-check-label">{` In `}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <Field
                                  name="timeWantedType"
                                  component="input"
                                  type="radio"
                                  value="at"
                                  className="form-check-input"
                                  onChange={this.handleTimeWantedTypeChange}
                                />
                                <label className="form-check-label">{` At `}</label>
                              </div>
                            </div>
                            {timeWantedTypeValue === "in" && (
                              <Field
                                name="timeWantedIn"
                                className="form-control "
                                component={"select"}
                                onChange={this.handleTimeWantedInChange}
                              >
                                <option value={0}>Please select</option>
                                {tenant.settings.call_in_pickup_time_adjustment_options
                                  .filter((option) => option > 0)
                                  .map((option, index) => (
                                    <option
                                      key={index}
                                      value={option}
                                    >{`In ${option} mins`}</option>
                                  ))}
                              </Field>
                            )}
                            {timeWantedTypeValue === "at" && (
                              <Field
                                name="timeWantedAt"
                                className="form-control"
                                component={"select"}
                                onChange={this.handleTimeWantedAtChange}
                              >
                                <option value={0}>Please select</option>
                                {timeslots.map((timeslot, index) => (
                                  <option
                                    key={index}
                                    value={timeslot}
                                  >{`At ${moment(timeslot).format(
                                    tenant.settings.time_format
                                  )}`}</option>
                                ))}
                              </Field>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {deliveryMethodValue === "Delivery" && (
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="required">Address</label>
                        <PlacesAutocomplete
                          value={this.state.address}
                          onChange={this.handleChange}
                          onSelect={this.handleSelect}
                          searchOptions={searchOptions}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "form-control",
                                  onBlur: this.handleBlur,
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };

                                  return (
                                    <div
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {/* {this.props.customerFrequentlyOrderedItems && (
                      <div className="alert alert-info">
                        <ul>
                          {this.props.customerFrequentlyOrderedItems.addresses.map(address => (
                            <li>{`${address}`}</li>
                          ))}
                        </ul>
                      </div>
                    )} */}
                      </div>

                      <div className="form-group col-md-4">
                        <label>Suite / Apartment #</label>
                        <Field
                          component={renderField}
                          type="text"
                          className="form-control"
                          name="suiteApartmentNumber"
                        />
                      </div>
                    </div>
                  )}
                  {this.state.postal_code && !isInRange && (
                    <div className="alert alert-danger">
                      Sorry the address you entered is not in our delivery
                      range. Change to pickup order
                    </div>
                  )}
                  {/* {deliveryMethodValue === "Delivery" &&
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="required">Select Address Type</label>
                    <Field
                      name="addressType"
                      className="form-control"
                      component={"select"}
                    >
                      <option value="">{`Select Address Type`}</option>
                      <option value={"Business"}>{`Business`}</option>
                      <option value={"Home"}>
                        {`Private Residence (House, Apt)`}
                      </option>
                      <option value={"Other"}>{`Other / Public Venue`}</option>
                    </Field>
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      {addressTypeValue === "Business"
                        ? "Business"
                        : addressTypeValue === "Other"
                          ? "Other/Public Venue"
                          : ""}
                    </label>
                    {addressTypeValue === "Business" && (
                      <Field
                        component={renderField}
                        type="text"
                        className="form-control"
                        name="businessName"
                        placeholder="Enter Company Name"

                      />
                    )}
                    {addressTypeValue === "Other" && (
                      <Field
                        component={renderField}
                        type="text"
                        className="form-control"
                        name="otherVenueName"
                        placeholder="Name of the school, church, etc"
                      />
                    )}
                  </div>
                </div>
              } */}
                  <div className="form-group">
                    <label className="required">Menu Items</label>
                    <div className="input-group">
                      <Field
                        name="filterText"
                        component={renderField}
                        className="form-control"
                        placeholder={`Search menu by code or name`}
                      />
                      {/* <div className="input-group-append">
                    <div className="input-group-text">
                      <Icon.Search />
                    </div>
                  </div> */}
                    </div>
                    {filterTextValue && (
                      <div className="mt-3">
                        <div className="alert alert-info">{`${filteredItems.length} filtered menu items. Click the right arrow below to see more`}</div>
                        <AliceCarousel
                          mouseTracking
                          touchTracking
                          autoHeight={false}
                          disableDotsControls={true}
                          disableButtonsControls={false}
                          responsive={responsive}
                          activeIndex={this.state.index}
                        >
                          {filteredItems.map((item) => (
                            <div className="item" key={item._id}>
                              <MenuItem
                                restaurant={selectedRestaurant}
                                item={item}
                                callback={this.clearFilterText}
                                deliveryMethod={deliveryMethodValue}
                                deliveryDistance={this.state.delivery_distance}
                                postalCode={this.state.postal_code}
                                deliveryFee={this.state.deliveryFee}
                                deliveryGST={this.state.deliveryGST}
                                promotions={this.props.promotionRules}
                              />
                            </div>
                          ))}
                        </AliceCarousel>
                        {/* <MenuItemCarousel items={filteredItems} restaurant={restaurant} /> */}
                      </div>
                    )}

                    {!filterTextValue &&
                      frequentlyOrderedItems &&
                      frequentlyOrderedItems.length > 0 && (
                        <div className="mt-3">
                          <div className="alert alert-info">{`${frequentlyOrderedItems.length} frequently ordered menu items. Click the right arrow below to see more`}</div>
                          <AliceCarousel
                            mouseTracking
                            touchTracking
                            autoHeight={false}
                            disableDotsControls={true}
                            disableButtonsControls={false}
                            responsive={responsive}
                            slideToIndex={currentIndex}
                            onSlideChanged={this.onSlideChanged}
                            renderSlideInfo={
                              <div className="alert alert-info">{`${frequentlyOrderedItems.length} frequently ordered items. Click the right arrow below to see more`}</div>
                            }
                          >
                            {frequentlyOrderedItems.map((foi) => (
                              <div className="item" key={foi.item._id}>
                                <span className="badge badge-pill badge-success">{`Ordered ${foi.quantity} times`}</span>
                                <MenuItem
                                  restaurant={selectedRestaurant}
                                  item={foi.item}
                                  callback={this.clearFilterText}
                                  deliveryMethod={deliveryMethodValue}
                                  deliveryDistance={
                                    this.state.delivery_distance
                                  }
                                  postalCode={this.state.postal_code}
                                  deliveryFee={this.state.deliveryFee}
                                  deliveryGST={this.state.deliveryGST}
                                  promotions={this.props.promotionRules}
                                />
                              </div>
                            ))}
                          </AliceCarousel>
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
            {!this.props.customerFrequentlyOrderedItems?.isBlocked && (
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <Cart
                    deliveryMethod={deliveryMethodValue}
                    deliveryFee={this.state.deliveryFee}
                    deliveryGST={this.state.deliveryGST}
                    deliveryDistance={this.state.delivery_distance}
                    postalCode={this.state.postal_code}
                    restaurant={this.state.selectedRestaurant}
                    total={total}
                  />
                </div>
                <div className="form-group">
                  <label>{`Special Requests`}</label>
                  <Field
                    name="specialRequests"
                    className="form-control"
                    component="textarea"
                  ></Field>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                disabled={pristine || submitting}
                onClick={reset}
              >
                {`Clear`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  pristine ||
                  submitting ||
                  this.props.customerFrequentlyOrderedItems?.isBlocked
                }
              >
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const user = cookies.get("user");
  const applicableRestaurants = (
    user.role === "Owner"
      ? state.tenant.restaurants || []
      : (state.tenant.restaurants || []).filter((_restaurant) =>
          user.restaurants.includes(_restaurant._id)
        )
  ).map((_restaurant) => {
    return { key: _restaurant._id, label: _restaurant.name };
  });

  const gstRate = state.tenant.restaurant
    ? state.tenant.tenant.settings.gst_rate
    : 0;

  const pstRate = state.tenant.restaurant
    ? state.tenant.tenant.settings.pst_rate
    : 0;

  let _businessHours = {};

  if (state.tenant.restaurant) {
    _businessHours[0] = {
      is_open: state.tenant.restaurant.regular_business_hours.sunday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.sunday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.sunday.close_time,
    };
    _businessHours[1] = {
      is_open: state.tenant.restaurant.regular_business_hours.monday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.monday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.monday.close_time,
    };
    _businessHours[2] = {
      is_open: state.tenant.restaurant.regular_business_hours.tuesday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.tuesday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.tuesday.close_time,
    };
    _businessHours[3] = {
      is_open: state.tenant.restaurant.regular_business_hours.wednesday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.wednesday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.wednesday.close_time,
    };
    _businessHours[4] = {
      is_open: state.tenant.restaurant.regular_business_hours.thursday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.thursday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.thursday.close_time,
    };
    _businessHours[5] = {
      is_open: state.tenant.restaurant.regular_business_hours.friday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.friday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.friday.close_time,
    };
    _businessHours[6] = {
      is_open: state.tenant.restaurant.regular_business_hours.saturday.is_open,
      open_time:
        state.tenant.restaurant.regular_business_hours.saturday.open_time,
      close_time:
        state.tenant.restaurant.regular_business_hours.saturday.close_time,
    };
  }
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant._id
      )
    : [];

  const dateWantedInitialValue =
    state.restaurant.pickupDates.length > 0
      ? state.restaurant.pickupDates[0]
      : moment();
  const isDateWantedToday = moment(dateWantedInitialValue).isSame(
    moment(),
    "Date"
  );
  const timeWantedTypeInitialValue = isDateWantedToday ? "in" : "at";

  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    restaurants: state.tenant.restaurants || [],
    tenant: state.tenant.tenant,
    menus: state.restaurant.menus,
    businessHours: _businessHours,
    items: state.cart.items,
    promotionRules: state.restaurant.promotions,
    promotions: state.cart.promotions,
    discounts: state.cart.discounts,
    subtotal: getSubtotal(state),
    tax: getTax(state, gstRate, pstRate),
    gst: getGST(state, gstRate),
    pst: getPST(state, pstRate),
    // total: getTotal(state, selector(state, "deliveryMethod"), gstRate, pstRate),
    quantity: getQuantity(state),
    initialValues: {
      timeWantedIn: 0,
      deliveryMethod: "Pickup",
      timeWantedAt: 0,
      timeWantedType: timeWantedTypeInitialValue,
      addressType: "Home",
      dateWanted: 0,
    },
    timeWantedInValue: selector(state, "timeWantedIn"),
    timeWantedAtValue: selector(state, "timeWantedAt"),
    timeWantedTypeValue: selector(state, "timeWantedType"),
    deliveryMethodValue: selector(state, "deliveryMethod"),
    addressTypeValue: selector(state, "addressType"),
    specialRequests: state.order.specialRequests,
    // deliveryFee: getDeliveryFee(state, selector(state, "deliveryMethod")),
    // deliveryGST: getDeliveryGST(
    //   state,
    //   selector(state, "deliveryMethod"),
    //   gstRate
    // ),
    holidayHours: holidayHours,
    customerFrequentlyOrderedItems: state.tenant.customerFrequentlyOrderedItems,
    phoneValue: selector(state, "phone"),
    filterTextValue: selector(state, "filterText"),
    pickupDates: state.restaurant.pickupDates,
    dateWantedValue: selector(state, "dateWanted"),
    applicableRestaurants: applicableRestaurants,
    discountTotal: getDiscountTotal(state),
    couponTotal: getCouponTotal(state),
    redemptionTotal: getRedemptionTotal(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  change,
  fetchPublishedOnlineOrderMenus: (restaurantId) =>
    dispatch(fetchPublishedOnlineOrderMenus(restaurantId)),
  fetchCustomerFrequentlyOrderedItems: (tenantId, phone, callback) =>
    dispatch(fetchCustomerFrequentlyOrderedItems(tenantId, phone, callback)),
  fetchPromotions: (restaurantId) => dispatch(fetchPromotions(restaurantId)),
  clearCustomerFrequentlyOrderedItems: () =>
    dispatch(clearCustomerFrequentlyOrderedItems()),
  fetchPickupDates: (restaurantId) => dispatch(fetchPickupDates(restaurantId)),
  saveOrder: (
    isCallInOrder,
    restaurantId,
    items,
    promotions,
    discounts,
    discountTotal,
    subtotal,
    tax,
    gst,
    pst,
    total,
    specialRequests,
    pickupDate,
    pickupTime,
    pickupPerson,
    nameOrderedUnder,
    phone,
    address,
    payment,
    deliveryMethod,
    deliveryAddress,
    deliveryFee,
    deliveryGST,
    orderLink,
    callback
  ) =>
    dispatch(
      saveOrder(
        isCallInOrder,
        restaurantId,
        items,
        promotions,
        discounts,
        discountTotal,
        subtotal,
        tax,
        gst,
        pst,
        total,
        specialRequests,
        pickupDate,
        pickupTime,
        pickupPerson,
        nameOrderedUnder,
        phone,
        address,
        payment,
        deliveryMethod,
        deliveryAddress,
        deliveryFee,
        deliveryGST,
        orderLink,
        callback
      )
    ),
  applyPromotions: (
    promotions,
    restaurantTimezone,
    timeFormat,
    deliveryMethod,
    pickupTime,
    pickupDate,
    couponAmount,
    deliveryDistance,
    deliveryPostalCode,
    deliveryFee,
    deliveryGST,
    restaurantId
  ) =>
    dispatch(
      applyPromotions(
        promotions,
        restaurantTimezone,
        timeFormat,
        deliveryMethod,
        pickupTime,
        pickupDate,
        couponAmount,
        deliveryDistance,
        deliveryPostalCode,
        deliveryFee,
        deliveryGST,
        restaurantId
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(TakeOrder));
