import React, { Component } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import * as Icon from "react-feather";

import { updateOrder } from "../../actions/giftcardorder";

import { socket } from "../../service/socket";

class UpdateOrder extends Component {
  constructor(props) {
    super(props);

    this.handleGiftCardTypeChange = this.handleGiftCardTypeChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {
      giftCardType: props.order.giftCardType,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  handleGiftCardTypeChange(event) {
    this.setState({
      giftCardType: event.target.value,
    });
  }

  handleSave() {
    this.props.updateOrder(
      this.props.restaurant._id,
      this.props.order._id,
      { giftCardType: this.state.giftCardType },
      (data) => {
        socket.emit(
          "update gift card order",
          this.props.restaurant._id,
          data.giftCardOrder,
          this.props.fetchPeriod
        );
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order } = this.props;

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#updateOrderModal_${order._id}`}
          data-backdrop="static"
        >
          <Icon.Edit size={16} className="d-inline mr-1" />
          <span className="d-none d-md-inline">Update Order</span>
        </button>
        <div
          className="modal fade"
          id={`updateOrderModal_${order._id}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          aria-labelledby={`#updateOrderModalLabel_${order._id}`}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header bg-primary">
                  <h5
                    className="modal-title"
                    id={`updateOrderModalLabel_${order._id}`}
                  >
                    Update Order
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>Gift Card Type</label>
                    <select
                      name={`giftCardType`}
                      className="form-control"
                      value={this.state.giftCardType}
                      onChange={this.handleGiftCardTypeChange}
                    >
                      <option value={"Digital"}>{`Digital`}</option>
                      <option value={"Physical"}>{`Physical`}</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSave}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.giftCardOrder.errorMessage,
    message: state.giftCardOrder.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (restaurantId, orderId, { giftCardType }, callback) =>
    dispatch(updateOrder(restaurantId, orderId, { giftCardType }, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrder);
