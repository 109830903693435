import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderField, renderPriceField, renderDateTimePicker } from "../../utils/renderFields";
import { addGiftCardProduct } from "../../actions/giftcardproduct";
import GiftCardProductPhotoPicker from "./GiftCardProductPhotoPicker";

const form = reduxForm({
  form: "addGiftCardProduct",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.price) {
    errors.price = "Please enter price";
  }

  return errors;
}

class AddGiftCardProduct extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      imageUrl: "",
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    const data = { ...formProps, imageUrl: this.state.imageUrl };
    this.props.addGiftCardProduct(this.props.restaurant._id, data, () => {
      this.props.handleViewGiftCardProducts();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleViewGiftCardProducts,
      handleSubmit,
      pristine,
      submitting,
      tenant,
      restaurant,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewGiftCardProducts}
              >
                <i className="fas fa-gift" />
                {` `}
                <span className="d-none d-sm-inline">{`Gift Card Products`}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                {`Add New Gift Card Product`}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <div
                    className="text-center"
                    style={{
                      // width: "330px",
                      // height: "240px",
                      borderWidth: "2px",
                      borderColor: "rgb(102, 102, 102)",
                      borderStyle: "dashed",
                      borderRadius: "5px",
                      padding: "20px",
                    }}
                  >
                    {this.state.imageUrl && (
                      <img
                        className="img-fluid"
                        width={229}
                        height={150}
                        src={
                          process.env.REACT_APP_S3_BASE_URL +
                          `${tenant.slug + "/" + this.state.imageUrl}`
                        }
                        alt="GiftCardProduct"
                      />
                    )}
                    <GiftCardProductPhotoPicker
                      tenant={tenant}
                      handleImageUrlChange={(imageUrl) => {
                        this.setState({ imageUrl: imageUrl });
                      }}
                      imageUrl={this.state.imageUrl}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label  className="required">Name</label>
                  <Field
                    name="name"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label  className="required">Description</label>
                  <Field
                    name="description"
                    className="form-control"
                    component={renderField}
                    type="text"
                    isTextarea={true}
                  />
                </div>
                <div className="form-group">
                  <label className="required">Unit Price</label>
                  <Field
                    name="unitPrice"
                    className="form-control"
                    component={renderPriceField}
                    type="number"
                  />
                </div>
                <div className="form-group">
                  <label className="required">Quantity</label>
                  <Field
                    name="quantity"
                    className="form-control"
                    component={renderField}
                    type="number"
                  />
                </div>
                <div className="form-group">
                  <label className="required">Price</label>
                  <Field
                    name="price"
                    className="form-control"
                    component={renderPriceField}
                    type="number"
                  />
                </div>
                <div className="form-group">
                  <label>Valid From</label>
                  <Field
                    name="validFrom"
                    className="form-control"
                    component={renderDateTimePicker}
                    showTime={false}
                    dateFormat={restaurant.settings.date_format}
                    timeFormat={restaurant.settings.time_format}
                    datetimeFormat={restaurant.settings.date_time_format}
                  />
                </div>
                <div className="form-group">
                  <label>Valid Until</label>
                  <Field
                    name="validUntil"
                    className="form-control"
                    component={renderDateTimePicker}
                    showTime={false}
                    dateFormat={restaurant.settings.date_format}
                    timeFormat={restaurant.settings.time_format}
                    datetimeFormat={restaurant.settings.date_time_format}
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={handleViewGiftCardProducts}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            {"Save"}
          </button>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.giftCardProduct.error,
    message: state.giftCardProduct.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addGiftCardProduct: (restaurantId, formProps, callback) =>
    dispatch(addGiftCardProduct(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddGiftCardProduct));
