import React, { Component } from "react";
import { connect } from "react-redux";
import {  reduxForm } from "redux-form";

import $ from "jquery";

import {
  issueCards,
  generateGiftCardNumbers,
} from "../../actions/giftcardorder";
import { socket } from "../../service/socket";

const form = reduxForm({
  form: "issueDigitalCards",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.paymentReceiptNumber) {
    errors.message = "Please enter paymentReceiptNumber";
  }

  return errors;
}

class IssueCards extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      disabled: false,
      physical_card_number: undefined,
      physical_card_numbers: [],
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.issueCards(
      this.props.tenant._id,
      this.props.order._id,
      this.props.order.giftCardType === "Digital"
        ? this.props.giftCardNumbers
        : this.state.physical_card_numbers,
      (data) => {
        socket.emit(
          "fulfill gift card order",
          this.props.tenant._id,
          data.giftCardOrder,
          this.props.fetchPeriod
        );
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      order,
      tenant,
      giftCardNumbers = [],
    } = this.props;
    return (
      <>
        <button
          type="button"
          disabled={order.status !== "Paid"}
          className="btn btn-outline-info mr-2"
          data-toggle="modal"
          data-target="#issueCardModal"
          data-backdrop="static"
        >
          <i className="far fa-credit-card d-inline mr-1" />
          <span className="d-none d-lg-inline">Issue Cards</span>
        </button>

        <div
          className="modal fade"
          id="issueCardModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#issueCardModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="issueCardModalLabel">
                    {`Issue Cards`}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="row mb-3">
                    <div className="col-12">
                      <strong className="mr-3">Email: </strong>
                      <span>{order.email}</span>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <strong className="mr-3">Number of Cards: </strong>
                      <span>{order.giftCardQuantity}</span>
                    </div>
                  </div>
                  {order.giftCardType === "Digital" && (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              this.props.generateGiftCardNumbers(
                                tenant._id,
                                order._id
                              )
                            }
                          >{`Generate e-GiftCard Numbers`}</button>
                        </div>
                      </div>
                      <div className="row mt-3">
                        {giftCardNumbers.map((giftCardNumber, index) => (
                          <div className="col-4" key={index}>
                            <span className="bg-info mr-3">
                              <span className="text-uppercase">
                                {giftCardNumber}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {order.giftCardType === "Physical" && (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="form-row">
                          <div className="col-12 input-group">
                            <input
                              type="number"
                              className="form-control mr-3"
                              name="physical_card_number"
                              placeholder="Card Number"
                              value={this.state.physical_card_number}
                              onChange={(e) =>
                                this.setState({
                                  physical_card_number: e.target.value,
                                })
                              }
                            />
                            <button
                              className="btn btn-primary"
                              disabled={!this.state.physical_card_number}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState((prevState) => {
                                  const new_physical_card_numbers =
                                    prevState.physical_card_numbers.includes(
                                      this.state.physical_card_number
                                    )
                                      ? prevState.physical_card_numbers
                                      : [
                                          ...prevState.physical_card_numbers,
                                          this.state.physical_card_number,
                                        ];
                                  return {
                                    physical_card_numbers:
                                      new_physical_card_numbers,
                                    physical_card_number: "",
                                  };
                                });
                              }}
                            >
                              {`Add Card Number`}
                            </button>
                          </div>{" "}
                        </div>
                        <div className="row my-3">
                          <div className="col">
                            {this.state.physical_card_numbers.map(
                              (physical_card_number, index) => (
                                <span
                                  key={index}
                                  className="removable-label bg-info mr-3"
                                >
                                  <span className="mr-3 text-uppercase">
                                    {physical_card_number}
                                  </span>
                                  <i
                                    className="fas fa-times"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState((prevState) => {
                                        return {
                                          physical_card_numbers:
                                            prevState.physical_card_numbers.filter(
                                              (item) =>
                                                item !== physical_card_number
                                            ),
                                        };
                                      });
                                    }}
                                  />
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="modal-footer d-block text-center">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.disabled}
                  >
                    {order.paymentMethod === "Online" ? `OK` : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.giftCardOrder.errorMessage,
    message: state.giftCardOrder.message,
    tenant: state.tenant.tenant,
    giftCardNumbers: state.giftCardOrder.giftCardNumbers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  generateGiftCardNumbers: (tenantId, giftCardOrderId) =>
    dispatch(generateGiftCardNumbers(tenantId, giftCardOrderId)),
  issueCards: (tenantId, giftCardOrderId, giftCardNumbers, callback) =>
    dispatch(
      issueCards(tenantId, giftCardOrderId, giftCardNumbers, callback)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(IssueCards));
