import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Header from "./Header";
import NotFound from "./NotFound";

import Logout from "./Logout";

import { isManager, isOwner, isAdmin } from "./User";
import Dashboard from "./Dashboard";
import FoodOrders from "./Order/FoodOrders";
import GiftCardOrders from "./GiftCardOrder/GiftCardOrders";
import Reservations from "./Reservation/Reservations";
import Customers from "./Customer/Customers";
import SearchCustomers from "./Customer/SearchCustomers";
import SearchCustomerView from "./Customer/SearchCustomerView";
import Menus from "./Menu/Menus";
import Employees from "./Employee/Employees";
import RequireAuth from "./RequireAuth";
import BusinessHours from "./BusinessHour/BusinessHours";
import Restaurants from "./Restaurant/Restaurants";
import Settings from "./Settings/Settings";
import GiftCardProducts from "./GiftCardProduct/GiftCardProducts";
import Profile from "./Profile/Profile";
import Announcements from "./Announcement/Announcements";
import Promotions from "./Promotion/Promotions";
import TakeOrder from "./TakeOrder/TakeOrder";
import KitchenOrders from "./KitchenOrder/KitchenOrders";
import HistoricalOrders from "./TakeOrder/HistoricalOrders";
import Billing from "./Billing/Billing";
import Coupons from "./Coupon/Coupons";
import CarouselSettings from "./Settings/CarouselSettings";
import GallerySettings from "./Settings/GallerySettings";
import GiftCards from "./GiftCard/GiftCards";
import SearchOrders from "./Order/SearchOrders";
import SearchGiftCardOrders from "./GiftCardOrder/SearchGiftCardOrders";
import GiftCardDetail from "./GiftCard/GiftCardDetail";
import SearchOrderDetail from "./Order/SearchOrderDetail";
import SearchCoupons from "./Coupon/SearchCoupons";
import Monitoring from "./Monitoring/Monitoring";
import CookingQueue from "./KitchenOrder/CookingQueue";

import "./Main.css";
import { ToastContainer } from "react-toastify";
import ImageLibrary from "./ImageLibrary/ImageLibrary";

class Main extends Component {
  render() {
    const { tenant } = this.props;
    return (
      <>
        {tenant && tenant.favicon && (
          <Helmet title={tenant.name}>
            <link
              rel="shortcut icon"
              href={
                process.env.REACT_APP_S3_BASE_URL +
                `${tenant.slug}/${tenant.favicon.url}`
              }
            />
          </Helmet>
        )}

        <Header />

        <main role="main" className="main-content">
          <ToastContainer />
          <Switch>
            <Redirect from="/" exact to="/orders" />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/dashboard" component={RequireAuth(Dashboard)} />
            <Route
              exact
              path="/take-order"
              component={RequireAuth(TakeOrder)}
            />
            <Route
              exact
              path="/historical-orders"
              component={RequireAuth(HistoricalOrders)}
            />
            <Route
              exact
              path="/kitchen-orders"
              component={RequireAuth(KitchenOrders)}
            />
            <Route
              exact
              path="/cooking-queue"
              component={RequireAuth(CookingQueue)}
            />
            <Route exact path="/orders" component={RequireAuth(FoodOrders)} />
            <Route
              exact
              path="/searchorders/:orderNumber"
              component={RequireAuth(SearchOrderDetail)}
            />
            <Route
              exact
              path="/searchorders"
              component={RequireAuth(SearchOrders)}
            />
            <Route exact path="/coupons" component={RequireAuth(Coupons)} />
            <Route
              exact
              path="/searchcoupons"
              component={RequireAuth(SearchCoupons)}
            />
            <Route exact path="/customers" component={RequireAuth(Customers)} />
            <Route
              exact
              path="/searchcustomers/:customerId"
              component={RequireAuth(SearchCustomerView)}
            />
            <Route
              exact
              path="/searchcustomers"
              component={RequireAuth(SearchCustomers)}
            />
            <Route
              exact
              path="/reservations"
              component={RequireAuth(Reservations)}
            />
            <Route
              exact
              path="/giftcardorders"
              component={RequireAuth(GiftCardOrders)}
            />
            <Route
              exact
              path="/searchgiftcardorders"
              component={RequireAuth(SearchGiftCardOrders)}
            />
            <Route
              exact
              path="/giftcards/:giftCardNumber"
              component={RequireAuth(GiftCardDetail)}
            />
            <Route exact path="/giftcards" component={RequireAuth(GiftCards)} />
            {(isManager() || isOwner() || isAdmin()) && (
              <>
                <Route
                  exact
                  path="/restaurants"
                  component={RequireAuth(Restaurants)}
                />
                <Route
                  exact
                  path="/businesshours"
                  component={RequireAuth(BusinessHours)}
                />
                <Route exact path="/menus" component={RequireAuth(Menus)} />
                <Route
                  exact
                  path="/images"
                  component={RequireAuth(ImageLibrary)}
                />
                <Route
                  exact
                  path="/employees"
                  component={RequireAuth(Employees)}
                />
                <Route
                  exact
                  path="/monitoring"
                  component={RequireAuth(Monitoring)}
                />
                <Route
                  exact
                  path="/promotions"
                  component={RequireAuth(Promotions)}
                />
                <Route
                  exact
                  path="/giftcardproducts"
                  component={RequireAuth(GiftCardProducts)}
                />
                <Route
                  exact
                  path="/announcements"
                  component={RequireAuth(Announcements)}
                />
                <Route
                  exact
                  path="/settings"
                  component={RequireAuth(Settings)}
                />
                <Route
                  exact
                  path="/settings/carousel"
                  component={RequireAuth(CarouselSettings)}
                />
                <Route
                  exact
                  path="/settings/gallery"
                  component={RequireAuth(GallerySettings)}
                />
                <Route exact path="/billing" component={RequireAuth(Billing)} />
              </>
            )}
            <Route path="*" component={NotFound} />
          </Switch>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
