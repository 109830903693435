import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";
import * as Icon from "react-feather";
import { redeemGiftCard } from "../../actions/giftcard";

import { renderField } from "../../utils/renderFields";
import Numeral from "numeral";

const form = reduxForm({
  form: "redeemGiftCard",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.amountToRedeem) {
    errors.message = "Please enter redeem amount";
  }

  return errors;
}

export class RedeemGiftCard extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      amountToRedeem: undefined,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.redeemGiftCard(
      this.props.tenant._id,
      this.props.giftCard.giftCardNumber,
      formProps.amountToRedeem,
      () => {
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <>
        <button
          type="button"
          className="btn btn-primary mr-2"
          data-toggle="modal"
          data-target="#redeemGiftCardModal"
          data-backdrop="static"
        >
          <span>Redeem</span>
        </button>

        <div
          className="modal fade"
          id="redeemGiftCardModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#redeemGiftCardModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="redeemGiftCardModalLabel">
                    {`Redeem Gift Card`}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="row pb-3">
                    <div className="col-12">Gift Card Number: {this.props.giftCard.giftCardNumber}</div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12">Balance: {Numeral(this.props.giftCard.balance).format("$0,0.00")}</div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-12">Pending Balance: {Numeral(this.props.giftCard.pendingBalance).format("$0,0.00")}</div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Amount</label>
                    <Field
                      name="amountToRedeem"
                      className="form-control"
                      component={renderField}
                      type="number"
                    />
                  </div>
                </div>

                <div className="modal-footer d-block text-center">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={pristine || submitting}
                  >
                    <Icon.Send size={16} /> {` `}
                    {`Save`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.giftCard.errorMessage,
    message: state.giftCard.message,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  redeemGiftCard: (tenantId, giftCardNumber, amountToRedeem, callback) =>
    dispatch(
      redeemGiftCard(tenantId, giftCardNumber, amountToRedeem, callback)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(RedeemGiftCard));
