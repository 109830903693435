import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { updateMenuItemSize } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemSizeEdit",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.price) {
    errors.price = "Please enter price";
  }

  return errors;
}

class MenuItemSizeEditModal extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);

    this.state = {
      name: props.menuItemSize.name,
      description: props.menuItemSize.description,
      price: props.menuItemSize.price
    };
  }

  componentDidMount() {}

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange(event) {

    this.setState({ description: event.target.value });
  }

  handlePriceChange(event) {
    this.setState({ price: event.target.value });
  }

  handleFormSubmit(formProps) {
    this.props.updateMenuItemSize(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      this.props.menuItemSizeId,
      {
        name: this.state.name,
        description: this.state.description,
        price: this.state.price
      }
    );
    $(".close").click();
  }

  generateModalId(menuItemSizeId) {
    return "menuItemSizeEditModal" + menuItemSizeId;
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      menuItemSizeId
    } = this.props;

    return (
      <>
        <div
          className="modal fade"
          id={this.generateModalId(menuItemSizeId)}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#menuItemSizeEditModallLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5 className="modal-title" id="menuItemSizeEditModalLabel">
                    {"Size Info"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleNameChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Price</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <Icon.DollarSign size={16} />
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="price"
                        value={this.state.price}
                        onChange={this.handlePriceChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {"Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
  };
};

const mapDispatchToProps = dispatch => ({
  updateMenuItemSize: (
    menuId,
    menuSectionId,
    menuItemId,
    menuItemSizeId,
    formProps
  ) =>
    dispatch(
      updateMenuItemSize(
        menuId,
        menuSectionId,
        menuItemId,
        menuItemSizeId,
        formProps
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MenuItemSizeEditModal));
