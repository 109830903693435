import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import { updateTenantSettings } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";
import moment_timzone from "moment-timezone";

const form = reduxForm({
  form: "timeZoneSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

const datetime_formats = [
  "MMM DD YYYY hh:mm A",
  "MMM DD YYYY HH:mm",
  "DD MMM YYYY hh:mm A",
  "DD MMM YYYY HH:mm",
];

const date_formats = [
  "MMM DD YYYY",
  "DD MMM YYYY",
];

const time_formats = [
  "hh:mm A",
  "HH:mm",
];

class TimeZoneSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
    this.handleDateTimeFormatChange = this.handleDateTimeFormatChange.bind(this);
    this.handleDateFormatChange = this.handleDateFormatChange.bind(this);
    this.handleTimeFormatChange = this.handleTimeFormatChange.bind(this);


    this.state = {
      tenantId: props.tenant._id,
      time_zone: props.tenant.settings.time_zone
        ? props.tenant.settings.time_zone
        : moment_timzone.tz.guess(),
      datetime_format: props.tenant.settings.datetime_format ? props.tenant.settings.datetime_format : datetime_formats[0],
      date_format: props.tenant.settings.date_format ? props.tenant.settings.date_format : date_formats[0],
      time_format: props.tenant.settings.time_format ? props.tenant.settings.time_format : time_formats[0]
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateTenantSettings(
      this.props.tenant._id,
      {
        time_zone: this.state.time_zone,
        datetime_format: this.state.datetime_format,
        date_format: this.state.date_format,
        time_format: this.state.time_format,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleTimeZoneChange(event) {
    this.setState({ time_zone: event.target.value });
  }

  handleDateTimeFormatChange(event) {
    this.setState({ datetime_format: event.target.value });
  }

  handleDateFormatChange(event) {
    this.setState({ date_format: event.target.value });
  }


  handleTimeFormatChange(event) {
    this.setState({ time_format: event.target.value });
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewSettings } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-globe" />
                {` `}
                <span className="d-none d-sm-inline">{`Time Zone`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Tenant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.tenant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Time Zone
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="time_zone"
              className="form-control"
              value={this.state.time_zone}
              onChange={this.handleTimeZoneChange}
            >
              {moment_timzone.tz.names().map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            DateTime Format
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="datetime_format"
              className="form-control"
              value={this.state.datetime_format}
              onChange={this.handleDateTimeFormatChange}
            >
              {datetime_formats.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Date Format
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="date_format"
              className="form-control"
              value={this.state.date_format}
              onChange={this.handleDateFormatChange}
            >
              {date_formats.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Time Format
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <select
              name="time_format"
              className="form-control"
              value={this.state.time_format}
              onChange={this.handleTimeFormatChange}
            >
              {time_formats.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              // disabled={pristine || submitting}
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateTenantSettings: (tenantId, formProps, callback) =>
    dispatch(updateTenantSettings(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(TimeZoneSettings));
