import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { fetchEmployee, updateEmployee } from "../../actions/employee";

import { renderPhoneField, renderField } from "../../utils/renderFields";
import normalizePhone from "../../utils/normalizePhone";

const form = reduxForm({
  form: "updateProfile",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = "Please enter first name";
  }
  if (!formProps.lastName) {
    errors.lastName = "Please enter last name";
  }
  if (!formProps.telephone) {
    errors.telephone = "Please enter telephone";
  }
  if (!formProps.email) {
    errors.email = "Please enter email";
  }

  return errors;
}

class UpdateProfile extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(formProps) {
    let data = {
      userName: this.props.userName,
      firstName: formProps.firstName,
      lastName: formProps.lastName,
      email: formProps.email,
      telephone: formProps.telephone,
    };
    this.props.updateEmployee(this.props.userId, data, () => {
      this.props.handleViewProfile();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewProfile, handleSubmit, pristine, submitting } =
      this.props;
    return (
      <>
        <div className="row pt-3 pb-6 mb-3 border-bottom">
          <div className="col-12">
            <h3>
              <strong>UPDATE PROFILE</strong>
            </h3>
          </div>
        </div>

        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              {`User Name`}
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={this.props.userName}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              First Name
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="firstName"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Last Name
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="lastName"
                className="form-control"
                component={renderField}
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Email
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="email"
                className="form-control-plaintext"
                component={renderField}
                readonly
                type="text"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              Phone
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <Field
                name="telephone"
                className="form-control"
                component={renderPhoneField}
                type="text"
                normalize={normalizePhone}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewProfile}
              >
                {`Cancel`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.employee.error,
    message: state.employee.message,
    initialValues: {...state.employee.user},
    userId: state.employee.user._id,
    userName: state.employee.user.userName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEmployee: (userId) => dispatch(fetchEmployee(userId)),
  updateEmployee: (userId, data, callback) =>
    dispatch(updateEmployee(userId, data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(UpdateProfile));
