import React, { Component } from "react";

class AllowedOrderType extends Component {
  render() {
    const {
      allowedOrderType,
      handleAllowedOrderTypeChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Allowed order type:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_type"
              value="Any type"
              onChange={handleAllowedOrderTypeChange}
              checked={allowedOrderType === "Any type"}
            />
            <label className="form-check-label">{`Any type (Default)`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_type"
              value="Pick-up"
              onChange={handleAllowedOrderTypeChange}
              checked={allowedOrderType === "Pick-up"}
            />
            <label className="form-check-label">{`Pick-up`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_type"
              value="Order ahead"
              onChange={handleAllowedOrderTypeChange}
              checked={allowedOrderType === "Order ahead"}
            />
            <label className="form-check-label">{`Order ahead`}</label>
          </div>
        </div>
      </div>

    );
  }
}

export default AllowedOrderType;