import React, { Component } from "react";
import { connect } from "react-redux";

import Numeral from "numeral";

import { addToCart } from "../../actions/cart";

import moment from "moment-timezone";

class MenuItem extends Component {
  constructor(props) {
    super(props);

    this.handleSizesChange = this.handleSizesChange.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);

    this.handleAddToCart = this.handleAddToCart.bind(this);

    let options = [];
    if (props.item) {
      props.item.options
        .filter((option) => option.selection_type === "Single")
        .forEach((option) => {
          options.push({
            option_name: option.name,
            option_value: option.items[0].name,
            option_id: option.items[0]._id,
            option_quantity: 1,
            option_price: option.items[0].additional_cost,
            option_total: option.items[0].additional_cost,
          });
        });
    }

    this.state = {
      item: props.item,
      size:
        props.item && props.item.sizes && props.item.sizes.length > 0
          ? props.item.sizes[0].name
          : "",
      options: options,
      discounts: [],
      quantity: 1,
      basePrice: props.item
        ? props.item.price
          ? props.item.price
          : props.item.sizes && props.item.sizes.length > 0
          ? props.item.sizes[0].price
          : 0
        : 0,
      baseTotal: props.item
        ? props.item.price
          ? props.item.price
          : props.item.sizes && props.item.sizes.length > 0
          ? props.item.sizes[0].price
          : 0
        : 0,
      extraPrice:
        options.length > 0
          ? options[0].option_price
            ? options[0].option_price
            : 0
          : 0,
      extraTotal:
        options.length > 0
          ? options[0].option_price
            ? options[0].option_price
            : 0
          : 0,
      specialInstructions: "",
    };
  }

  handlePlus() {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1,
      baseTotal: (prevState.quantity + 1) * prevState.basePrice,
      extraTotal: (prevState.quantity + 1) * prevState.extraPrice,
      options: prevState.options.map((op) => {
        op = {
          ...op,
          option_quantity: op.option_quantity + 1,
          option_total:
            op.option_total && op.option_price
              ? op.option_total + op.option_price
              : undefined,
        };
        return op;
      }),
    }));
  }

  handleMinus() {
    this.setState((prevState) => ({
      quantity: prevState.quantity - 1,
      baseTotal: (prevState.quantity - 1) * prevState.basePrice,
      extraTotal: (prevState.quantity - 1) * prevState.extraPrice,
      options: prevState.options.map((op) => {
        op = {
          ...op,
          option_quantity: op.option_quantity - 1,
          option_total:
            op.option_total && op.option_price
              ? op.option_total - op.option_price
              : undefined,
        };
        return op;
      }),
    }));
  }

  handleSpecialInstructionsChange(event) {
    this.setState({ specialInstructions: event.target.value });
  }

  handleOptionsChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let options = Array.from(this.state.item.options);

    if (target.type === "radio") {
      options
        .filter((option) => option._id === name)
        .forEach((option) => {
          option.items
            .filter((item) => item._id === value)
            .forEach((item) => {
              let array = [...this.state.options]; // make a separate copy of the array
              let found = false;
              for (let o of array) {
                if (o.option_name === option.name) {
                  o.option_value = item.name;
                  o.option_id = item._id;
                  o.option_quantity = this.state.quantity;
                  o.option_price = item.additional_cost;
                  o.option_total = item.additional_cost
                    ? this.state.quantity * item.additional_cost
                    : undefined;
                  found = true;
                  break;
                }
              }

              if (!found) {
                array.push({
                  option_name: option.name,
                  option_value: item.name,
                  option_id: item._id,
                  option_quantity: this.state.quantity,
                  option_price: item.additional_cost,
                  option_total: item.additional_cost
                    ? this.state.quantity * item.additional_cost
                    : undefined,
                });
              }

              this.setState((prevState) => ({
                extraPrice:
                  (item.additional_cost
                    ? item.additional_cost * prevState.quantity
                    : 0),
                options: array,
              }));

              this.setState((prevState) => ({
                extraTotal: prevState.quantity * prevState.extraPrice,
              }));
            });
        });
    }
    if (target.type === "checkbox") {
      options.forEach((option) => {
        option.items
          .filter((item) => item._id === name)
          .forEach((item) => {
            if (value) {
              this.setState((prevState) => {
                return {
                  extraPrice:
                    prevState.extraPrice +
                    (item.additional_cost ? item.additional_cost : 0),
                  options: [
                    ...prevState.options,
                    {
                      option_name: item.name,
                      option_id: item._id,
                      option_quantity: prevState.quantity,
                      option_price: item.additional_cost,
                      option_total: item.additional_cost
                        ? prevState.quantity * item.additional_cost
                        : undefined,
                    },
                  ],
                  extraTotal:
                    prevState.quantity *
                    (prevState.extraPrice +
                      (item.additional_cost ? item.additional_cost : 0)),
                };
              });
            } else {
              this.setState((prevState) => {
                return {
                  extraPrice:
                    prevState.extraPrice -
                    (item.additional_cost ? item.additional_cost : 0),
                  options: prevState.options.filter(
                    (state_option) => state_option.option_id !== name
                  ),
                  extraTotal:
                    prevState.quantity *
                    (prevState.extraPrice -
                      (item.additional_cost ? item.additional_cost : 0)),
                };
              });
            }
          });
      });
    }
  }

  handleSizesChange(event) {
    const target = event.target;
    const sizeId = target.value;
    const basePrice = this.state.item.sizes.filter(
      (itemsize) => itemsize._id === sizeId
    )[0].price;
    const sizeName = this.state.item.sizes.filter(
      (itemsize) => itemsize._id === sizeId
    )[0].name;
    this.setState((prevState) => ({
      size: sizeName,
      basePrice: basePrice,
      baseTotal: prevState.quantity * basePrice,
    }));
  }

  handleAddToCart(event, item, quantity) {

    this.props.addToCart(
      item._id,
      item.cd,
      item.name,
      item.printName,
      this.state.size,
      this.state.options,
      quantity,
      this.state.basePrice,
      this.state.baseTotal * quantity,
      this.state.extraPrice,
      this.state.extraTotal * quantity,
      this.state.specialInstructions,
      this.state.duration,
      this.props.tenant.settings.coupon,
      this.props.promotions,
      this.props.restaurant.settings.time_zone,
      this.props.restaurant.settings.time_format,
      this.props.deliveryMethod,
      moment(),
      moment(),
      this.props.deliveryDistance,
      this.props.postalCode,
      this.props.deliveryFee,
      this.props.deliveryGST,
      this.props.restaurant.id,
      () => {

        const sizeName =
          item ? (
          item.sizes &&
          item.sizes.length > 0
            ? item.sizes[0].name
            : "") : "";

        const options = [];
        if (this.props.menuItem) {
          this.props.item.options
            .filter((option) => option.selection_type === "Single")
            .forEach((option) => {
              options.push({
                option_name: option.name,
                option_value: option.items[0].name,
                option_id: option.items[0]._id,
                option_quantity: 1,
                option_price: option.items[0].additional_cost,
                option_total: option.items[0].additional_cost,
              });
            });
        }

        this.setState(
          (prevState) => ({
            quantity: 1,
            baseTotal: prevState.basePrice * 1,
            extraPrice:
              options.length > 0
                ? options[0].option_price
                  ? options[0].option_price
                  : 0
                : 0,
            extraTotal:
              options.length > 0
                ? options[0].option_price
                  ? options[0].option_price
                  : 0
                : 0,
            specialInstructions: "",
            size: sizeName,
            options: options,
            discounts: [],
          }),
          () => {
            this.props.callback && this.props.callback();
          }
        );
      }
    );
    event.preventDefault();
  }

  render() {
    const { restaurant, item } = this.props;

    return (
      <div className="card">
        <div className="card-body">
          <div className="card-title text-left">{`${item.cd ? item.cd : ""} ${
            item.name
          }`}</div>

          {item.price && <div>{Numeral(item.price).format("$0,0.00")}</div>}
          {item.sizes && item.sizes.length > 0 && (
            <div className="form-group">
              <label>Sizes</label>

              {item.sizes.map((itemsize, index) => (
                <div key={itemsize._id}>
                  <label>
                    <input
                      name="sizes"
                      type="radio"
                      value={itemsize._id}
                      checked={this.state.size === itemsize.name}
                      onChange={this.handleSizesChange}
                    />{" "}
                    {itemsize.name} {Numeral(itemsize.price).format("$0,0.00")}
                  </label>
                </div>
              ))}
            </div>
          )}
          {item.options &&
            item.options
              .filter((option) => option.selection_type === "Single")
              .map((option, index1) => (
                <div key={`option_${index1}`} className="form-group">
                  <label>{option.name}</label>

                  {option.items.map((optionitem, index) => (
                    <div key={`optionitem_${index}`} className="form-check">
                      <label className="form-check-label">
                        <input
                          name={option._id}
                          className="form-check-input"
                          type="radio"
                          value={optionitem._id}
                          // defaultChecked={optionitem.pre_selected}
                          checked={
                            this.state.options
                              ? this.state.options.filter(
                                  (o1) => o1.option_id === optionitem._id
                                ).length > 0
                              : false
                          }
                          onChange={this.handleOptionsChange}
                        />{" "}
                        {optionitem.name}{" "}
                        {optionitem.additional_cost && (
                          <span>
                            {` + ${Numeral(optionitem.additional_cost).format(
                              "$0,0.00"
                            )}`}
                          </span>
                        )}
                      </label>
                    </div>
                  ))}
                </div>
              ))}
          {restaurant.settings &&
            restaurant.settings.menu_extra_option &&
            item.options
              .filter((option) => option.selection_type !== "Single")
              .map((option, index1) => (
                <div key={`option_${index1}`} className="form-group">
                  <label>{option.name}</label>

                  {option.items.map((optionitem, index) =>
                    optionitem.pre_selected ? (
                      <div key={`optionitem_${index}`} className="form-check">
                        <label className="form-check-label">
                          <input
                            name={optionitem._id}
                            className="form-check-input"
                            type="checkbox"
                            disabled
                            checked
                            value={optionitem._id}
                            onChange={this.handleOptionsChange}
                          />{" "}
                          {optionitem.name}{" "}
                          {optionitem.additional_cost && (
                            <span>
                              {Numeral(optionitem.additional_cost).format(
                                "$0,0.00"
                              )}
                            </span>
                          )}
                        </label>
                      </div>
                    ) : (
                      <div key={`optionitem_${index}`} className="form-check">
                        <label className="form-check-label">
                          <input
                            name={optionitem._id}
                            className="form-check-input"
                            type="checkbox"
                            value={optionitem._id}
                            checked={
                              this.state.options
                                ? this.state.options.filter(
                                    (o1) => o1.option_id === optionitem._id
                                  ).length > 0
                                : false
                            }
                            onChange={this.handleOptionsChange}
                          />{" "}
                          {optionitem.name}{" "}
                          {optionitem.additional_cost && (
                            <span>
                              {Numeral(optionitem.additional_cost).format(
                                "$0,0.00"
                              )}
                            </span>
                          )}
                        </label>
                      </div>
                    )
                  )}
                </div>
              ))}

          <div className="mt-3">
            <button
              className="btn btn-primary mr-2"
              onClick={(event) => this.handleAddToCart(event, item, 1)}
            >{`1`}</button>
            <button
              className="btn btn-primary mr-2"
              onClick={(event) => this.handleAddToCart(event, item, 2)}
            >{`2`}</button>
            <button
              className="btn btn-primary"
              onClick={(event) => this.handleAddToCart(event, item, 3)}
            >{`3`}</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    tenant: state.tenant.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (
    id,
    cd,
    name,
    printName,
    size,
    options,
    discounts,
    discountTotal,
    quantity,
    basePrice,
    baseTotal,
    extraPrice,
    extraTotal,
    specialInstructions,
    duration,
    isCouponEnabled,
    promotions,
    timeZone,
    timeFormat,
    deliveryMethod,
    pickupTime,
    pickupDate,
    deliveryDistance,
    postalCode,
    deliveryFee,
    deliveryGST,
    restaurantId,
    callback
  ) =>
    dispatch(
      addToCart(
        id,
        cd,
        name,
        printName,
        size,
        options,
        discounts,
        discountTotal,
        quantity,
        basePrice,
        baseTotal,
        extraPrice,
        extraTotal,
        specialInstructions,
        duration,
        isCouponEnabled,
        promotions,
        timeZone,
        timeFormat,
        deliveryMethod,
        pickupTime,
        pickupDate,
        deliveryDistance,
        postalCode,
        deliveryFee,
        deliveryGST,
        restaurantId,
        callback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
