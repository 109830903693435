import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchGiftCardOrders } from "../../actions/giftcardorder";

import GiftCardOrderDetail from "./GiftCardOrderDetail";
import OrderListView from "./OrderListView";

import { socket } from "../../service/socket";
import moment from "moment-timezone";

import * as IoIcons from "react-icons/io";

class GiftCardOrders extends Component {
  constructor(props) {
    super(props);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewOrders = this.handleViewOrders.bind(this);

    this.handleShowAllOrders = this.handleShowAllOrders.bind(this);

    this.handleShowNewOrders = this.handleShowNewOrders.bind(this);
    this.handleShowPaidOrders = this.handleShowPaidOrders.bind(this);
    this.handleShowFulfilledOrders = this.handleShowFulfilledOrders.bind(this);
    this.handleShowRejectedOrders = this.handleShowRejectedOrders.bind(this);

    this.state = {
      loading: false,
      showAllOrders: false,
      showNewOrders: true,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      selectedOrder: undefined,
      period: "Today",
      from: undefined,
      to: undefined,
    };
  }

  getGiftCardOrders = (giftCardOrders) => {
    this.setState((prevState) => {
      return {
        loading: false,
        orders: giftCardOrders.filter(
          (giftCardOrder) =>
            (moment(giftCardOrder.receivedAt).isSameOrAfter(this.state.from) &&
              moment(giftCardOrder.receivedAt).isBefore(this.state.to)) ||
            (giftCardOrder.confirmedAt &&
              moment(giftCardOrder.confirmedAt).isSameOrAfter(
                this.state.from
              ) &&
              moment(giftCardOrder.confirmedAtAt).isBefore(this.state.to)) ||
            (giftCardOrder.cancelledAt &&
              moment(giftCardOrder.cancelledAt).isSameOrAfter(
                this.state.from
              ) &&
              moment(giftCardOrder.cancelledAt).isBefore(this.state.to)) ||
            (giftCardOrder.rejectedAt &&
              moment(giftCardOrder.rejectedAt).isSameOrAfter(this.state.from) &&
              moment(giftCardOrder.rejectedAt).isBefore(this.state.to)) ||
            (giftCardOrder.fulfilledAt &&
              moment(giftCardOrder.paidAt).isSameOrAfter(this.state.from) &&
              moment(giftCardOrder.paidAt).isBefore(this.state.to)) ||
            (giftCardOrder.fulfilledAt &&
              moment(giftCardOrder.fulfilledAt).isSameOrAfter(
                this.state.from
              ) &&
              moment(giftCardOrder.fulfilledAt).isBefore(this.state.to)) ||
            (giftCardOrder.status === "Received" &&
              moment(giftCardOrder.pickupTime).isSameOrAfter(this.state.from))
        ),
      };
    });
  };

  componentDidMount() {
    if (this.props.tenant) {
      var state_current = this;
      this.setState(
        {
          loading: true,
          from: moment()
            .tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .toDate(),
          to: moment()
            .tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate(),
        },
        () => {
          socket.emit("initial_gift_card_orders", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to,
          });
          socket.on("get_gift_card_orders", state_current.getGiftCardOrders);
        }
      );
    }
  }

  componentWillUnmount() {
    socket.off("get_gift_card_orders");
  }

  handleViewDetail(giftCardOrder) {
    this.setState({ selectedOrder: giftCardOrder });
  }

  handleViewOrders() {
    this.setState({ selectedOrder: undefined });
  }

  handleShowNewOrders() {
    this.setState({
      showNewOrders: true,
      showAllOrders: false,
      showPaidOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
    });
  }

  handleShowPaidOrders() {
    this.setState({
      showNewOrders: false,
      showAllOrders: false,
      showPaidOrders: true,
      showFulfilledOrders: false,
      showRejectedOrders: false,
    });
  }

  handleShowFulfilledOrders() {
    this.setState({
      showNewOrders: false,
      showAllOrders: false,
      showPaidOrders: false,
      showFulfilledOrders: true,
      showRejectedOrders: false,
    });
  }

  handleShowRejectedOrders() {
    this.setState({
      showNewOrders: false,
      showAllOrders: false,
      showPaidOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: true,
    });
  }

  handleShowAllOrders() {
    this.setState({
      showNewOrders: false,
      showFulfilledOrders: false,
      showPaidOrders: false,
      showAllOrders: true,
      showRejectedOrders: false,
    });
  }

  render() {
    const { tenant } = this.props;
    const allOrders = this.state.orders || [];
    const newOrders = allOrders.filter(
      (giftCardOrder) => giftCardOrder.status === "Received"
    );

    const fulfilledOrders = allOrders.filter(
      (giftCardOrder) => giftCardOrder.status === "Fulfilled"
    );
    const paidOrders = allOrders.filter(
      (giftCardOrder) => giftCardOrder.status === "Paid"
    );
    const rejectedOrders = allOrders.filter(
      (giftCardOrder) =>
        giftCardOrder.status === "Rejected" ||
        giftCardOrder.status === "Cancelled"
    );

    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.selectedOrder) {
      return (
        <GiftCardOrderDetail
          orderId={this.state.selectedOrder._id}
          orderNumber={this.state.selectedOrder.giftCardOrderNumber}
          handleViewOrders={this.handleViewOrders}
          fetchPeriod={{
            from: this.state.from,
            to: this.state.to,
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <IoIcons.IoIosPaper />
                {` `}
                <span>{`Gift Card Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="border-bottom mb-3 py-3">
          <button
            type="button"
            className={`btn btn-lg ${
              this.state.showNewOrders ? " btn-primary" : "btn-outline-primary"
            }  mr-2`}
            onClick={this.handleShowNewOrders}
          >
            <span className="d-inline">New</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-danger">{newOrders.length}</span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showPaidOrders ? " btn-info" : "btn-outline-info"
            }  mr-2`}
            onClick={this.handleShowPaidOrders}
          >
            <span>Paid</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">{paidOrders.length}</span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showFulfilledOrders ? " btn-info" : "btn-outline-info"
            }  mr-2`}
            onClick={this.handleShowFulfilledOrders}
          >
            <span>Fulfilled</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">
                {fulfilledOrders.length}
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showRejectedOrders
                ? " btn-danger"
                : "btn-outline-danger"
            }  mr-2`}
            onClick={this.handleShowRejectedOrders}
          >
            <span>{`Rejected / Cancelled`}</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">
                {rejectedOrders.length}
              </span>
            )}
          </button>
        </div>

        {this.state.showFulfilledOrders && (
          <OrderListView
            orders={fulfilledOrders}
            handleViewDetail={this.handleViewDetail}
            status="Fulfilled"
            loading={this.state.loading}
          />
        )}
        {this.state.showPaidOrders && (
          <OrderListView
            orders={paidOrders}
            handleViewDetail={this.handleViewDetail}
            status="Paid"
            loading={this.state.loading}
          />
        )}
        {this.state.showNewOrders && (
          <OrderListView
            orders={newOrders}
            handleViewDetail={this.handleViewDetail}
            status="Received"
            loading={this.state.loading}
          />
        )}
        {this.state.showRejectedOrders && (
          <OrderListView
            orders={rejectedOrders}
            handleViewDetail={this.handleViewDetail}
            status="Rejected"
            loading={this.state.loading}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    giftCardOrder: state.giftCardOrder.giftCardOrder,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCardOrders: (tenantId) =>
    dispatch(fetchGiftCardOrders(tenantId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftCardOrders);
