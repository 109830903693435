import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchOrders } from "../../actions/order";

import OrderDetail from "./OrderDetail";
import OrderListView from "./OrderListView";

import { socket } from "../../service/socket";
import moment from "moment-timezone";

import * as MdIcons from "react-icons/md";

import Cookies from "universal-cookie";

const cookies = new Cookies();

class FoodOrders extends Component {
  constructor(props) {
    super(props);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewOrders = this.handleViewOrders.bind(this);

    this.handleShowAllOrders = this.handleShowAllOrders.bind(this);
    this.handleShowInProgressOrders =
      this.handleShowInProgressOrders.bind(this);
    this.handleShowNewOrders = this.handleShowNewOrders.bind(this);
    this.handleShowFulfilledOrders = this.handleShowFulfilledOrders.bind(this);
    this.handleShowRejectedOrders = this.handleShowRejectedOrders.bind(this);

    this.handleShowCookingOrders = this.handleShowCookingOrders.bind(this);
    this.handleShowReadyOrders = this.handleShowReadyOrders.bind(this);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);

    this.state = {
      loading: false,
      showAllOrders: false,
      showNewOrders: true,
      showInProgressOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: false,
      selectedOrder: undefined,
      period: "Today",
      from: undefined,
      to: undefined,
      selectedRestaurantId: undefined,
    };
  }

  getOrders = (orders) => {
    // console.log(orders)
    this.setState((prevState) => {
      return {
        loading: false,
        orders: orders.filter(
          (order) =>
            (moment(order.receivedAt).isSameOrAfter(this.state.from) &&
              moment(order.receivedAt).isBefore(this.state.to)) ||
            (order.confirmedAt &&
              moment(order.confirmedAt).isSameOrAfter(this.state.from) &&
              moment(order.confirmedAtAt).isBefore(this.state.to)) ||
            (order.cancelledAt &&
              moment(order.cancelledAt).isSameOrAfter(this.state.from) &&
              moment(order.cancelledAt).isBefore(this.state.to)) ||
            (order.rejectedAt &&
              moment(order.rejectedAt).isSameOrAfter(this.state.from) &&
              moment(order.rejectedAt).isBefore(this.state.to)) ||
            (order.fulfilledAt &&
              moment(order.fulfilledAt).isSameOrAfter(this.state.from) &&
              moment(order.fulfilledAt).isBefore(this.state.to)) ||
            (["Received", "Accepted"].includes(order.status) &&
              moment(order.pickupTime).isSameOrAfter(this.state.from))
        ),
      };
    });
  };

  componentDidMount() {
    if (this.props.tenant && this.props.applicableRestaurants.length > 0) {
      const periodFrom = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("day")
        .toDate();
      const periodTo = moment()
        .tz(this.props.tenant.settings.time_zone)
        .endOf("day")
        .toDate();

      var state_current = this;
      this.setState(
        {
          loading: true,
          from: periodFrom,
          to: periodTo,
          selectedRestaurantId: this.props.applicableRestaurants[0].key,
        },
        () => {
          socket.emit("join", this.state.selectedRestaurantId);
          socket.emit("initial_orders", this.state.selectedRestaurantId, {
            from: periodFrom,
            to: periodTo,
          });
          socket.on("get_orders", state_current.getOrders);
        }
      );
    }
  }

  componentWillUnmount() {
    socket.off("get_orders");
  }

  handleViewDetail(order) {
    this.setState({ selectedOrder: order });
  }

  handleViewOrders() {
    this.setState({ selectedOrder: undefined });
    // if (this.props.restaurant) {
    //   this.props.fetchOrders(this.props.restaurant._id);
    // }
  }

  handleShowNewOrders() {
    this.setState({
      showNewOrders: true,
      showInProgressOrders: false,
      showAllOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: false,
    });
  }

  handleShowInProgressOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: true,
      showAllOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: false,
    });
  }

  handleShowFulfilledOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: false,
      showAllOrders: false,
      showFulfilledOrders: true,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: false,
    });
  }

  handleShowRejectedOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: false,
      showAllOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: true,
      showCookingOrders: false,
      showReadyOrders: false,
    });
  }

  handleShowCookingOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: false,
      showAllOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      showCookingOrders: true,
      showReadyOrders: false,
    });
  }

  handleShowReadyOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: false,
      showAllOrders: false,
      showFulfilledOrders: false,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: true,
    });
  }

  handleShowAllOrders() {
    this.setState({
      showNewOrders: false,
      showInProgressOrders: false,
      showFulfilledOrders: false,
      showAllOrders: true,
      showRejectedOrders: false,
      showCookingOrders: false,
      showReadyOrders: false,
    });
  }

  handleSelectRestaurant(restaurantId) {
    this.setState(
      {
        selectedRestaurantId: restaurantId,
      },
      () => {
        socket.emit("join", this.state.selectedRestaurantId);
        socket.emit("initial_orders", this.state.selectedRestaurantId, {
          from: this.state.from,
          to: this.state.to,
        });
        socket.on("get_orders", this.getOrders);
      }
    );
  }

  render() {
    const { tenant } = this.props;
    const allOrders = this.state.orders || [];
    const newOrders = allOrders.filter(
      (order) =>
        order.status === "Received" &&
        moment(order.pickupTime)
          .tz(tenant.settings.time_zone)
          .isSame(moment().tz(tenant.settings.time_zone), "day")
    );

    const futureNewOrders = allOrders.filter(
      (order) =>
        order.status === "Received" &&
        moment(order.pickupTime)
          .tz(tenant.settings.time_zone)
          .isAfter(moment().tz(tenant.settings.time_zone).endOf("day"))
    );
    const inProgressOrders = allOrders.filter(
      (order) =>
        (order.status === "Accepted" || order.status === "Cancelled") &&
        moment(order.pickupTime)
          .tz(tenant.settings.time_zone)
          .isSame(moment().tz(tenant.settings.time_zone), "day")
    );
    const futureInProgressOrders = allOrders.filter(
      (order) =>
        order.status === "Accepted" &&
        moment(order.pickupTime)
          .tz(tenant.settings.time_zone)
          .isAfter(moment().tz(tenant.settings.time_zone).endOf("day"))
    );
    const fulfilledOrders = allOrders.filter(
      (order) => order.status === "Fulfilled"
    );
    const rejectedOrders = allOrders.filter(
      (order) => order.status === "Rejected" || order.status === "Cancelled"
    );
    const cookingOrders = allOrders.filter(
      (order) => order.status === "InPreparation"
    );
    const readyOrders = allOrders.filter(
      (order) => order.status === "ReadyForPickup"
    );

    if (tenant === undefined || !this.state.selectedRestaurantId) {
      return <></>;
    }
    if (this.state.selectedOrder) {
      return (
        <OrderDetail
          orderId={this.state.selectedOrder._id}
          orderNumber={this.state.selectedOrder.orderNumber}
          handleViewOrders={this.handleViewOrders}
          fetchPeriod={{
            from: this.state.from,
            to: this.state.to,
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <div className="dropdown">
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownRestaurantButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <MdIcons.MdLocationPin />
                  {` `}
                  <span>
                    {
                      this.props.applicableRestaurants.find(
                        (ar) => ar.key === this.state.selectedRestaurantId
                      ).label
                    }
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownRestaurantButton"
                >
                  {this.props.applicableRestaurants.map((ar) => (
                    <button
                      key={ar.key}
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectRestaurant(ar.key)}
                    >
                      {ar.label}
                    </button>
                  ))}
                </div>
              </div>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <MdIcons.MdFastfood />
                {` `}
                <span>{`Food Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="border-bottom mb-3 pb-3">
          <button
            type="button"
            className={`btn btn-lg ${
              this.state.showNewOrders ? " btn-primary" : "btn-outline-primary"
            }  mr-2`}
            onClick={this.handleShowNewOrders}
          >
            <span className="d-inline">New</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-danger">{newOrders.length}</span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showInProgressOrders
                ? " btn-secondary"
                : "btn-outline-secondary"
            }  mr-2`}
            onClick={this.handleShowInProgressOrders}
          >
            <span>Accepted</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-warning">
                {inProgressOrders.length}
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showCookingOrders
                ? " btn-secondary"
                : "btn-outline-secondary"
            }  mr-2`}
            onClick={this.handleShowCookingOrders}
          >
            <span>InPreparation</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">
                {cookingOrders.length}
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showReadyOrders
                ? " btn-secondary"
                : "btn-outline-secondary"
            }  mr-2`}
            onClick={this.handleShowReadyOrders}
          >
            <span>ReadyForPickup</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">{readyOrders.length}</span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showFulfilledOrders ? " btn-info" : "btn-outline-info"
            }  mr-2`}
            onClick={this.handleShowFulfilledOrders}
          >
            <span>Fulfilled</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">
                {fulfilledOrders.length}
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              this.state.showRejectedOrders
                ? " btn-danger"
                : "btn-outline-danger"
            }  mr-2`}
            onClick={this.handleShowRejectedOrders}
          >
            <span>{`Rejected / Cancelled`}</span>
            {` `}{" "}
            {this.state.loading === false && (
              <span className="badge badge-success">
                {rejectedOrders.length}
              </span>
            )}
          </button>
        </div>

        {this.state.showFulfilledOrders && (
          <OrderListView
            orders={fulfilledOrders}
            handleViewDetail={this.handleViewDetail}
            tenant={tenant}
            status="Fulfilled"
            loading={this.state.loading}
          />
        )}
        {this.state.showNewOrders && (
          <>
            <OrderListView
              orders={newOrders}
              handleViewDetail={this.handleViewDetail}
              tenant={tenant}
              status="Received"
              loading={this.state.loading}
            />
            <OrderListView
              orders={futureNewOrders}
              handleViewDetail={this.handleViewDetail}
              tenant={tenant}
              status="Received"
              loading={this.state.loading}
              isNextDayOrder={true}
            />
          </>
        )}
        {this.state.showInProgressOrders && (
          <>
            <OrderListView
              orders={inProgressOrders}
              handleViewDetail={this.handleViewDetail}
              tenant={tenant}
              status="Accepted"
              loading={this.state.loading}
            />
            <OrderListView
              orders={futureInProgressOrders}
              handleViewDetail={this.handleViewDetail}
              tenant={tenant}
              status="Accepted"
              loading={this.state.loading}
              isNextDayOrder={true}
            />
          </>
        )}
        {this.state.showCookingOrders && (
          <OrderListView
            orders={cookingOrders}
            handleViewDetail={this.handleViewDetail}
            tenant={tenant}
            loading={this.state.loading}
          />
        )}
        {this.state.showReadyOrders && (
          <OrderListView
            orders={readyOrders}
            handleViewDetail={this.handleViewDetail}
            tenant={tenant}
            loading={this.state.loading}
          />
        )}
        {this.state.showRejectedOrders && (
          <OrderListView
            orders={rejectedOrders}
            handleViewDetail={this.handleViewDetail}
            tenant={tenant}
            status="Rejected"
            loading={this.state.loading}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const user = cookies.get("user");
  const applicableRestaurants = (
    user.role === "Owner"
      ? state.tenant.restaurants || []
      : (state.tenant.restaurants || []).filter((_restaurant) =>
          user.restaurants.includes(_restaurant._id)
        )
  ).map((_restaurant) => {
    return { key: _restaurant._id, label: _restaurant.name };
  });
  return {
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant,
    order: state.order.order,
    applicableRestaurants: applicableRestaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrders: (restaurantId) => dispatch(fetchOrders(restaurantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodOrders);
