import React, { Component } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import { addPromotionPhoto } from "../../actions/promotion";
import { fetchImages, addImage } from "../../actions/image";

import { renderDropzoneInput } from "../../utils/renderFields";

import SearchBar from "../Common/SearchBar";

const form = reduxForm({
  form: "promotionPhoto",
  validate
});

function validate(formProps) {
  const errors = {};

  // if (!formProps.image) {
  //   errors.image = "Please upload image";
  // }

  return errors;
}

const cardClassName = (image, selectedImage) => {
  return `card ${selectedImage === image ? "shadow-lg bg-info" : ""}`
};

class PromotionPhotoPicker extends Component {
  constructor(props) {
    super(props);

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleAddToPage = this.handleAddToPage.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);

    this.state = {
      filterText: undefined,
      selectedImage: undefined,
      relativePath: undefined
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchImages(this.props.tenant._id);
    }
  }

  componentDidUpdate() {
    $(".card").hover(
      function () {
        $(this)
          .css("cursor", "pointer");
      },
      function () {

      }
    );
  }

  handleFilterTextChange(event) {
    this.setState({
      filterText: event.target.value
    });
  }

  handleAddToPage() {
    let photoUrl = undefined;
    if (this.state.selectedImage) {
      photoUrl = this.state.selectedImage.relative_path;
    }
    this.props.handleImageUrlChange(photoUrl);
    $(".close").click();

  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  handleDrop(files) {
    if (files) {
      this.props.addImage(
        this.props.tenant._id,
        {
          caption: undefined,
          image: files,
          relativePath: this.state.relativePath
        },
        () => {
          this.props.clearDropzone();
        }
      );
    }
  }

  handleNavigate(relative_path) {
    if (this.props.tenant) {
      this.props.fetchImages(this.props.tenant._id, relative_path);
    }
  }

  render() {
    const {
      tenant,
      images = [],
      folders = [],
      parent_folders = [],
      imageUrl
    } = this.props;

    const filteredImages = this.state.filterText
      ? images.filter(image => image.name.includes(this.state.filterText))
      : images;

    return (
      <>
        {!imageUrl &&
          <button
            type="button"
            className="btn btn-outline-primary btn-lg"
            data-toggle="modal"
            data-target="#promotionPhotoPicker"
            data-backdrop="static"
          >
            <Icon.Plus size={72} />
          </button>
        }
        {imageUrl &&
          <button
            type="button"
            className="btn btn-sm"
            data-toggle="modal"
            data-target="#promotionPhotoPicker"
            data-backdrop="static"
          >
            <Icon.Edit size={24} />
          </button>
        }
        <div
          className="modal fade"
          id="promotionPhotoPicker"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#promotionPhotoPickerLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mr-3" id="promotionPhotoPickerLabel">
                  {"Choose Image"}
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <nav aria-label="breadcrumb" className="pt-3">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => this.handleNavigate(undefined)}
                      >
                        <i className="fas fa-images" />
                        {` `}
                        <span className="d-none d-sm-inline">{`Image Library`}</span>
                      </button>
                    </li>
                    {parent_folders.map((parent_folder, index) => (
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                        key={index}
                      >
                        <button
                          type="button"
                          className="btn btn-light"
                          onClick={() =>
                            this.handleNavigate(parent_folder.relative_path)
                          }
                        >
                          {parent_folder.name}
                        </button>
                      </li>
                    ))}
                  </ol>
                </nav>
                <SearchBar
                  placeholder={`Search image by name`}
                  handleFilterTextChange={this.handleFilterTextChange}
                  showSearchIcon={false}
                />
                <div className="mt-3 row row-cols-1 row-cols-md-6">
                  <div className="col mb-4" key={-1}>
                    <Field
                      name="image"
                      component={renderDropzoneInput}
                      width={150}
                      height={150}
                      uploader={files => this.handleDrop(files)}
                    />
                  </div>
                  {folders.map((folder, index) => (
                    <div className="col mb-4 text-center" key={index}>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          this.handleNavigate(folder.relative_path);
                        }}
                      >
                        <Icon.Folder size={32} />
                        <p
                          className="text-truncate"
                          data-toggle="tooltip"
                          title={folder.name}
                        >
                          {folder.name}
                        </p>
                      </button>
                    </div>
                  ))}
                  {filteredImages.map((image, index) => (
                    <div
                      className="col mb-4"
                      key={index}
                      onClick={() => this.setState({ selectedImage: image })}
                    >
                      <div className={cardClassName(image, this.state.selectedImage)}>
                        {image && (
                          <img
                            className="card-img-top img-fluid"
                            src={
                              process.env.REACT_APP_S3_BASE_URL +
                              `${tenant.slug + "/" + image.relative_path
                              }`
                            }
                            alt="Library Item"
                          />
                        )}

                        <div className="card-body text-center">
                          <p
                            className="text-truncate"
                            data-toggle="tooltip"
                            title={image.name}
                          >
                            {image.name}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.handleAddToPage()}
                >
                  {"Add To Page"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.promotion.errorMessage,
    message: state.promotion.message,
    initialValues: {},
    folders: state.imagelibrary.folders || [],
    images: state.imagelibrary.images || [],
    parent_folders: state.imagelibrary.parent_folders || []
  };
};

const mapDispatchToProps = dispatch => ({
  addPromotionPhoto: (promotionId, formProps, callback) =>
    dispatch(
      addPromotionPhoto(promotionId, formProps, callback)
    ),
  fetchImages: (tenantId, relativePath) =>
    dispatch(fetchImages(tenantId, relativePath)),
  addImage: (tenantId, { caption, image }, callback) =>
    dispatch(addImage(tenantId, { caption, image }, callback)),
  clearDropzone: () => dispatch(reset("promotionPhoto"))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(PromotionPhotoPicker));
