import React, { Component } from "react";

import $ from "jquery";
import * as Icon from "react-feather";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const generateNodes = menus => {
  const menu_nodes = [];
  if (menus) {
    menus.forEach(menu => {
      const section_nodes = [];
      menu.sections.forEach(section => {
        const item_nodes = [];
        section.items.forEach(item => {
          item_nodes.push({
            value: item._id,
            label: item.name
          });
        });

        section_nodes.push({
          value: section._id,
          label: section.name,
          children: item_nodes
        });
      });

      menu_nodes.push({
        value: menu._id,
        label: menu.name,
        children: section_nodes
      });
    });
  }
  return menu_nodes;
};


export class EligibleItemsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    }
  }

  componentDidMount() {

  }

  handleFormSubmit(formProps) {
    $(".close").click();
  }

  render() {
    const { name, type, selectedItems, menus, handleCheck } = this.props;
    return (
      <React.Fragment>
        <div className="input-group">
          <input name={name} type={type} className="form-control" value={`${selectedItems.length} items selected`} disabled />
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-light btn-sm input-group-text"
              data-toggle="modal"
              data-target={`#eligibleItemsModal_${name}`}
              data-backdrop="static"
            >
              <Icon.Edit2 />
            </button>
          </div>
        </div>
        <div
          className="modal fade"
          id={`eligibleItemsModal_${name}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby={`#eligibleItemsModalLabel_${name}`}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id={`eligibleItemsModalLabel_${name}`}>
                  {`Eligible Items`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <CheckboxTree
                  nodes={generateNodes(menus)}
                  checkModel={`leaf`}
                  checked={selectedItems}
                  expanded={this.state.expanded}
                  onCheck={handleCheck}
                  onExpand={expanded => this.setState({ expanded })}
                  iconsClass="fa5"
                />
              </div>

              <div className="modal-footer d-block text-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  data-dismiss="modal"
                >
                  {`Close`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EligibleItemsModal;
