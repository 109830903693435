import React, { Component } from "react";
import { connect } from "react-redux";

import buy_x_get_1_free from "../../assets/buy_x_get_1_free.png";
import percent_discount_on_selected_items from "../../assets/percent_discount_on_selected_items.png";
import fixed_discount_on_cart from "../../assets/fixed_discount_on_cart.png";
import percent_discount_on_cart from "../../assets/percent_discount_on_cart.png";
import free_delivery from "../../assets/free_delivery.png";
import payment_method_reward from "../../assets/payment_method_reward.png";
// import fixed_discount_amount_on_combo_meal from "../../assets/fixed_discount_amount_on_combo_meal.png";
// import percent_discount_on_combo_meal from "../../assets/percent_discount_on_combo_meal.png";
// import free_item_as_part_of_a_meal from "../../assets/free_item_as_part_of_a_meal.png";
// import value_meal_bundle from "../../assets/value_meal_bundle.png";
import PromoRulesAndRestrictionsHelp from "./PromoRulesAndRestrictionsHelp";

// import value_meal_bundle from "../../assets/value_meal_bundle.png";

export class SelectPromotionType extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() { }

  render() {
    const { handleViewPromos, handleAddPromo } = this.props;

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewPromos}
              >
                <i className="fas fa-percent" />
                {` `}
                <span className="d-none d-sm-inline">{`Promotions`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <span>{`Add`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="row mt-3">
          <div className="col-12">
            <span>
              <strong>{`Select menu promo deal type`}</strong>
            </span>
          </div>
        </div>
        <div className="row pb-3 border-bottom">
          <div className="col-12">
            <PromoRulesAndRestrictionsHelp />
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={buy_x_get_1_free}
                alt={`buy 1 get 1 free`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Buy one, get one free</strong>
                </div>
                <div>
                  <small>
                    For example: Buy a main dish and get the second for free.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Buy one, get one free')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={buy_x_get_1_free}
                alt={`buy 1 get 1 free`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Buy X, get Y free</strong>
                </div>
                <div>
                  <small>
                    For example: Buy 7 gift cards get 1 for free.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Buy X, get Y free')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={percent_discount_on_selected_items}
                alt={`% discount on selected items`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>% discount on selected items</strong>
                </div>
                <div>
                  <small>
                    For example: 30% off on any dessert or drink.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('% discount on selected items')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={fixed_discount_on_cart}
                alt={`fixed discount amount on cart`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Fixed discount amount on cart</strong>
                </div>
                <div>
                  <small>
                    Fixed discount amount on the total cart value, usually combined with a condition like minimum ordering amount.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Fixed discount amount on cart')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={percent_discount_on_cart}
                alt={`% discount on cart`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>% discount on cart</strong>
                </div>
                <div>
                  <small>
                    % discount on your total cart value, usually combined with a condition like minimum ordering amount.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('% discount on cart')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={free_delivery}
                alt={`free_delivery`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Free delivery</strong>
                </div>
                <div>
                  <small>
                    Free delivery (discounted delivery) for orders over a certain cart value.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Free delivery')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={payment_method_reward}
                alt={`payment_method_reward`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Payment method reward</strong>
                </div>
                <div>
                  <small>
                    % discount on your total cart value if you pay with a certain payment method. For example: Credit card online.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Payment method reward')}>
              Next
            </button>
          </div>
        </div>
        {/* <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={buy_x_get_1_free}
                alt={`buy_x_get_1_free`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Buy 2,3,... get one free</strong>
                </div>
                <div>
                  <small>
                    For example: Buy two main dishes and get the third for free.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Buy X, get one free')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={fixed_discount_amount_on_combo_meal}
                alt={`fixed_discount_amount_on_combo_meal`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Fixed discount amount on combo deal</strong>
                </div>
                <div>
                  <small>
                    For example: Buy any main dish plus dessert and get $5 off.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Fixed discount amount on combo deal')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={percent_discount_on_combo_meal}
                alt={`percent_discount_on_combo_meal`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>% discount on combo deal</strong>
                </div>
                <div>
                  <small>
                    For example: Buy any main dish plus dessert and get 10% off.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('% discount on combo deal')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={free_item_as_part_of_a_meal}
                alt={`free_item_as_part_of_a_meal`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Free dish or discounted item as part of a meal</strong>
                </div>
                <div>
                  <small>
                    For example: Free dessert or drink if you purchase starter + main dish.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Free dish or discounted item as part of meal')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={value_meal_bundle}
                alt={`value_meal_bundle`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Meal bundle</strong>
                </div>
                <div>
                  <small>
                    A selection of dishes at a fixed price. For example: Any 2 appetizers + 2 main dishes + 2 deserts => All for $55!
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success" onClick={() => handleAddPromo('Meal bundle')}>
              Next
            </button>
          </div>
        </div>
        <div className="row mb-3 mt-3 pb-3 border-bottom">
          <div className="col-9">
            <div className="d-flex flex-row">
              <img
                src={value_meal_bundle}
                alt={`value_meal_bundle`}
                className="img-fluid mr-3"
              />
              <div>
                <div className="pb-1">
                  <strong>Meal bundle with speciality</strong>
                </div>
                <div>
                  <small>
                    Same as "Meal bundle" but a subset of selected items are only available with an extra fee.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 text-right">
            <button type="button" className="btn btn-success"  onClick={() => handleAddPromo('Meal bundle with speciality')}>
              Next
            </button>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPromotionType);
