import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import Switch from "react-switch";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "onlinePaymentSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class OnlineOrderSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleStripeApiSecurityKeyChange = this.handleStripeApiSecurityKeyChange.bind(this);
    this.handleStripeApiPublishableKeyChange = this.handleStripeApiPublishableKeyChange.bind(this);
    this.state = {
      online_ordering: props.restaurant.settings.online_ordering,
      accept_online_payment: props.restaurant.settings.online_payment_settings.accept_online_payment,
      stripe_api_security_key: props.restaurant.settings.online_payment_settings.stripe_api_security_key,
      stripe_api_publishable_key: props.restaurant.settings.online_payment_settings.stripe_api_publishable_key,
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        accept_online_payment: this.state.accept_online_payment,
        stripe_api_security_key: this.state.stripe_api_security_key,
        stripe_api_publishable_key: this.state.stripe_api_publishable_key,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleStripeApiSecurityKeyChange(event) {
    this.setState({ stripe_api_security_key: event.target.value });
  }

  handleStripeApiPublishableKeyChange(event) {
    this.setState({ stripe_api_publishable_key: event.target.value });
  }

  handleAcceptOnlinePaymentChange(event) {

    this.setState({ accept_online_payment: event.target.value });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      restaurant,
      handleViewSettings,
      handleViewRestaurants
    } = this.props;

    if (restaurant === undefined) {
      return (<></>)
    }
    return (
      <>
      <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-credit-card" />
                {` `}
                <span className="d-none d-sm-inline">{`Online Payments`}</span>
              </button>
            </li>
          </ol>
        </nav>
      {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
      {this.renderAlert()}
      <div className="form-group row">
        <label className="col-form-label col-sm-4 text-sm-right">
          {`Restaurant`}
        </label>
        <div className="col-sm-8 col-md-6 col-lg-4">
          <input
            type="text"
            readOnly
            className="form-control-plaintext"
            value={this.props.restaurant.name}
          />
        </div>
      </div>
      <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Accept Online Payment
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
          <Switch
                className="react-switch"
                onChange={() => {this.setState(prevState => {return {accept_online_payment: !prevState.accept_online_payment}})}}
                checked={this.state.accept_online_payment || false}
                disabled={!this.state.online_ordering}
                uncheckedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      paddingRight: 2
                    }}
                  >
                    No
                  </div>
                }
                checkedIcon={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 15,
                      paddingRight: 2
                    }}
                  >
                    Yes
                  </div>
                }
              />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Stripe API Secruirty Key
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="stripe_api_security_key"
              value={this.state.stripe_api_security_key}
              onChange={this.handleStripeApiSecurityKeyChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Stripe API Publishable Key
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="stripe_api_publishable_key"
              value={this.state.stripe_api_publishable_key}
              onChange={this.handleStripeApiPublishableKeyChange}
            />
          </div>
        </div>

      <div className="row">
        <div className="col-12 text-center border-top pb-3 pt-3">
          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={this.props.handleViewSettings}
          >
            {`Cancel`}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleFormSubmit}
          >
            {"Save"}
          </button>
        </div>
      </div>

      {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.restaurant.error,
    message: state.restaurant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateSettings: (tenantId, formProps, callback) =>
    dispatch(updateSettings(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(OnlineOrderSettings));
