import { getData, postData2, patchData2 } from "./index";

import {
  UPDATE_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENTS,
  ANNOUNCEMENT_ERROR
} from "./types";

export function fetchAnnouncements(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/announcements`;
  return dispatch =>
    getData(FETCH_ANNOUNCEMENTS, ANNOUNCEMENT_ERROR, true, url, dispatch);
}

export function addAnnouncement(
  tenantId,
  { title, short_description, description, imageUrl },
  callback
) {
  let formData = new FormData();

  // formData.append('restaurantId', restaurantId);
  formData.append("title", title);
  formData.append("description", description);
  formData.append("short_description", short_description);


  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  }

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/announcements`;
  return dispatch =>
    postData2(
      ADD_ANNOUNCEMENT,
      ANNOUNCEMENT_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function updateAnnouncement(
  { _id, title, description, short_description, imageUrl, disabled },
  callback
) {
  let formData = new FormData();

  formData.append("title", title);
  formData.append("description", description);
  formData.append("short_description", short_description);
  formData.append("disabled", disabled);

  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  }
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/announcements/${_id}`;
  return dispatch =>
    patchData2(
      UPDATE_ANNOUNCEMENT,
      ANNOUNCEMENT_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}
