import React, { Component } from "react";
import * as Icon from "react-feather";

class MenuSectionInfoCard extends Component {
  render() {
    const {
      tenant,
      menu,
      menuSection,
      handleEditMenuSection,
      handleArchiveMenuSection,
      handleManageMenuSectionLocations
    } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Section Info</h5>
            </div>
            <div className="col-6 text-right">
              {menu && menuSection && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit menu section"
                    onClick={() => handleEditMenuSection(menuSection)}
                  >
                    <Icon.Edit2 size={16} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info mr-2"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Manage locations"
                    onClick={() => handleManageMenuSectionLocations(menuSection)}
                  >
                    <Icon.MapPin size={16} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Archive menu section"
                    onClick={() => handleArchiveMenuSection(menu, menuSection)}
                  >
                    <Icon.Trash2 size={16} />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {menuSection.image && menuSection.image.url && (
                <img
                  className="img-fluid mb-2"
                  src={
                    process.env.REACT_APP_API_BASE +
                    `${tenant.slug}/${menuSection.image.url.replace(
                      ".jpg",
                      ""
                    )}.jpg`
                  }
                  alt="Menu Section"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h6>{menuSection ? menuSection.name : ""}</h6>
              <p>{menuSection ? menuSection.description : ""}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSectionInfoCard;
