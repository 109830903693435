import axios from "axios";
import {
  FETCH_HISTORICAL_ORDERS,
  HISTORICAL_ORDER_ERROR,
  SAVE_HISTORICAL_ORDER,
  CLEAR_CART,
} from "./types";

import { errorHandler, getData } from "./index";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export function fetchHistoricalOrders(restaurantId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/historicalorders`;
  return dispatch => getData(FETCH_HISTORICAL_ORDERS, HISTORICAL_ORDER_ERROR, true, url, dispatch, callback);
}

export function saveHistoricalOrder(
  restaurantId,
  orderDate,
  phone,
  deliveryAddress,
  items,
  successCallback
) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/historicalorders`;

  let headers = { headers: { Authorization: cookies.get("utoken") } };

  return function (dispatch) {
    axios
      .post(
        url,
        {
          orderDate,
          phone,
          deliveryAddress,
          items
        },
        headers)
      .then(response => {
        successCallback(response.data);
        dispatch({ type: SAVE_HISTORICAL_ORDER,  payload: response.data});
        dispatch({ type: CLEAR_CART });

      })
      .catch(error => {
        errorHandler(dispatch, error.response, HISTORICAL_ORDER_ERROR);
      });
  }
}

