import React, { Component } from "react";
import Numeral from "numeral";

import Loading from "../Common/Loading";
class GiftCardListView extends Component {

  componentDidMount() {}

  render() {
    const { giftCards = [], handleViewDetail } = this.props;

    return (
      <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <strong className="text-secondary mr-2">
                  {giftCards.length}
                </strong>
                {"GiftCards"}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Number</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-right">
              <strong>Pending Balance</strong>
            </div>
            <div className="col-lg-1 d-none d-lg-inline text-right">
              <strong>Balance</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Customer</strong>
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Phone</strong>
            </div>
            <div className="col-lg-3 d-none d-lg-inline text-left">
              <strong>Email</strong>
            </div>
          </div>
          {this.props.loading === true && <Loading />}
          {this.props.loading === false && (
            <>
              {giftCards.map((giftCard, index) => (
                <React.Fragment key={index}>
                  <div className="row clickable-row pt-3 pb-3 border-bottom" onClick={() => handleViewDetail(giftCard)}>
                    <div className="col-12 col-lg-2 text-left ">
                      <span className="d-inline d-lg-none">
                        <strong>Number: </strong>
                      </span>
                      <span className="text-uppercase">{giftCard.giftCardNumber}</span>
                    </div>
                    <div className="col-12 col-lg-2 text-right ">
                      <span className="d-inline d-lg-none">
                        <strong>Pending Balance: </strong>
                      </span>
                      <span>{Numeral(giftCard.pendingBalance).format("$0,0.00")}</span>
                    </div>
                    <div className="col-12 col-lg-1 text-right ">
                      <span className="d-inline d-lg-none">
                        <strong>Balance: </strong>
                      </span>
                      <span>{Numeral(giftCard.balance).format("$0,0.00")}</span>
                    </div>
                    <div className="col-12 col-lg-2 text-left ">
                      <span className="d-inline d-lg-none">
                        <strong>Customer: </strong>
                      </span>
                      <span>{giftCard.nameOrderedUnder}</span>
                    </div>
                    <div className="col-12 col-lg-2 text-left ">
                      <span className="d-inline d-lg-none">
                        <strong>Phone: </strong>
                      </span>
                      <span>{giftCard.phone}</span>
                    </div>
                    <div className="col-12 col-lg-3 text-left ">
                      <span className="d-inline d-lg-none">
                        <strong>Email: </strong>
                      </span>
                      <span>{giftCard.email}</span>
                    </div>
                    
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default GiftCardListView;
