import React, { Component } from "react";

class MinimumOrderAmount extends Component {
  render() {
    const {
      currencyCode,
      minimumOrderAmountOption,
      handleMinimumOrderAmountOptionChange,
      minimumOrderAmount,
      handleMinimumOrderAmountChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Minimum order amount:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="miminum_order_amount_option"
              value="Not applicable"
              onChange={handleMinimumOrderAmountOptionChange}
              defaultChecked={minimumOrderAmountOption === "Not applicable"}
            />
            <label className="form-check-label">{`Not applicable (Default)`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="miminum_order_amount_option"
              value="Custom selection"
              onChange={handleMinimumOrderAmountOptionChange}
              defaultChecked={minimumOrderAmountOption === "Custom selection"}
            />
            <label className="form-check-label">
              {`Custom selection `}
            </label>
            <div className="form-group mt-3">
              <div className="input-group col-4">
                <input
                  className="form-control"
                  name="miminum_order_amount"
                  type="number"
                  disabled={minimumOrderAmountOption === "Not applicable"}
                  value={minimumOrderAmount}
                  onChange={handleMinimumOrderAmountChange}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{currencyCode}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default MinimumOrderAmount;