import React, { Component } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { renderField } from "../../utils/renderFields";
import { addFolder } from "../../actions/image";

const afterSubmit = (result, dispatch) =>
  dispatch(reset('addFolder'));

const form = reduxForm({
  form: "addFolder",
  onSubmitSuccess: afterSubmit,
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  return errors;
}

class AddFolder extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.addFolder(
      this.props.tenant._id,
      {
        name: formProps.name,
        relativePath: this.props.parent_folders.length > 0 ? this.props.parent_folders[this.props.parent_folders.length-1].relative_path : ""
      },
      () => {

      }
    );
    $(".close").click();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting
    } = this.props;

    return (
      <div className="row justify-content-center">
        <div className="col-12">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              borderWidth: "2px",
              borderColor: "rgb(102, 102, 102)",
              borderStyle: "dashed",
              borderRadius: "5px",
              padding: "20px",
            }}>
            <button
              className="btn btn-outline-primary btn-lg"
              data-toggle="modal"
              data-target="#addFolderModal"
              data-backdrop="static"
            >
              <Icon.Plus size={48} />
              <p><small>Folder</small></p>
            </button>

            <div
              className="modal fade"
              id="addFolderModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="#addFolderModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title" id="addFolderModalLabel">
                        {"Add Folder"}
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body text-left">
                      {this.renderAlert()}
                      <div className="form-group">
                        <label className="col-form-label">Folder Name</label>
                        <Field
                          name="name"
                          className="form-control"
                          component={renderField}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                        {"Add"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    folders: state.imagelibrary.folders || [],
    images: state.imagelibrary.images || [],
    parent_folders: state.imagelibrary.parent_folders || []
  };
};

const mapDispatchToProps = dispatch => ({
  addFolder: (tenantId, { name, relativePath }, callback) =>
    dispatch(addFolder(tenantId, { name, relativePath }, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddFolder));
