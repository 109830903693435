import React, { Component } from "react";
import { connect } from "react-redux";

import CustomerListView from "./CustomerListView";

import CustomerView from "./CustomerView";

import {
  fetchCustomerOrders,
} from "../../actions/tenant";

import moment from "moment-timezone";
import { socket } from '../../service/socket';

import * as FiIcons from "react-icons/fi";

import DateTimePicker from "react-widgets/lib/DateTimePicker";

class Customers extends Component {
  constructor(props) {
    super(props);

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);

    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleViewCustomers = this.handleViewCustomers.bind(this);
    this.handleShowRegisteredUsers = this.handleShowRegisteredUsers.bind(this);
    this.handleShowGuests = this.handleShowGuests.bind(this);

    this.handlePeriodFromChange = this.handlePeriodFromChange.bind(this);
    this.handlePeriodToChange = this.handlePeriodToChange.bind(this);

    this.state = {
      loading: false,
      filterText: undefined,
      selectedCustomer: undefined,
      showRegisteredUsers: true,
      showGuests: false,
      period: "Today",
      from: undefined,
      to: undefined,
      customers: []
    };
  }

  getCustomers = (customers) => {
    this.setState((prevState) => {
      return { loading: false, customers: customers };
    });
  };

  componentDidMount() {
    if (this.props.tenant) {
      var state_current = this;
      this.setState({
        loading: true,
        from: moment().tz(this.props.tenant.settings.time_zone)
          .startOf("day")
          .toDate(),
        to: moment().tz(this.props.tenant.settings.time_zone)
          .endOf("day")
          .toDate()
      }, () => {
        socket.emit("initial_customers", this.props.tenant._id, {
          from: this.state.from,
          to: this.state.to
        });
        socket.on("get_customers", state_current.getCustomers);

      })

    }

  }

  componentWillUnmount() {
    socket.off("get_customers");
  }

  handleSelectPeriod(period) {
    this.setState({
      loading: true
    });

    if (period === "Today") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Yesterday") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("day")
            .subtract(1, "days")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .subtract(1, "days")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 7 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(7, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 14 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(14, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 30 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(30, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 90 days") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(90, 'days')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last 12 months") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .subtract(12, 'months')
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last week") {
      const startOfLastWeek = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("week")
        .subtract(1, 'weeks');
      const endOfLastWeek = moment(startOfLastWeek).endOf("week");
      this.setState(
        {
          period: period,
          from: startOfLastWeek
            .toDate(),
          to: endOfLastWeek
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last month") {
      const startOfLastMonth = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("month")
        .subtract(1, 'months');
      const endOfLastMonth = moment(startOfLastMonth).endOf("month");
      this.setState(
        {
          period: period,
          from: startOfLastMonth.toDate(),
          to: endOfLastMonth.toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last quarter") {
      const startOfLastQuarter = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("quarter")
        .subtract(1, 'quarters');
      const endOfLastQuarter = moment(startOfLastQuarter).endOf("quarter");
      this.setState(
        {
          period: period,
          from: startOfLastQuarter
            .toDate(),
          to: endOfLastQuarter
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Last year") {
      const startOfLasYear = moment().tz(this.props.tenant.settings.time_zone)
        .startOf("year")
        .subtract(1, 'years');
      const endOfLastYear = moment(startOfLasYear).endOf("year");
      this.setState(
        {
          period: period,
          from: startOfLasYear
            .toDate(),
          to: endOfLastYear
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Week to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("week")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Month to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("month")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Quarter to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("quarter")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
    if (period === "Year to date") {
      this.setState(
        {
          period: period,
          from: moment().tz(this.props.tenant.settings.time_zone)
            .startOf("year")
            .toDate(),
          to: moment().tz(this.props.tenant.settings.time_zone)
            .endOf("day")
            .toDate()
        },
        () => {
          socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to
          });
        }
      );
    }
  }

  handlePeriodFromChange(value) {
    this.setState(
      {
        loading: true,
        from: moment(value)
          .tz(this.props.tenant.settings.time_zone)
          .startOf("day")
          .toDate(),
      },
      () => {
        socket.emit("initial_customers", this.props.tenant._id, 
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handlePeriodToChange(value) {
    this.setState(
      {
        loading: true,
        to: moment(value)
          .tz(this.props.restaurant.settings.time_zone)
          .endOf("day")
          .toDate(),
      },
      () => {
        socket.emit("initial_customers", this.props.tenant._id, {
            from: this.state.from,
            to: this.state.to,
          }
        );
      }
    );
  }

  handleFilterTextChange(event) {

    this.setState({
      filterText: event.target.value,
    });
  }

  handleViewDetail(customer) {
    this.props.fetchCustomerOrders(this.props.tenant._id, customer.telephone, (data) => {
      this.setState({ selectedCustomer: customer, orders: data.orders, favoriteItems: data.favoriteItems, analyticsPerDay: data.analyticsPerDay, analyticsPerMenu: data.analyticsPerMenu });
    })
  }

  handleViewCustomers() {
    this.setState({ selectedCustomer: undefined });
  }

  handleShowRegisteredUsers() {
    this.setState({
      showRegisteredUsers: true,
      showGuests: false,
    });
  }

  handleShowGuests() {
    this.setState({
      showRegisteredUsers: false,
      showGuests: true,
    });
  }

  render() {
    const { tenant, restaurant } = this.props;

    const registeredUsers = this.state.customers.filter(customer => customer.isGuest === false);
    const guests = this.state.customers.filter(customer => customer.isGuest === true);
    const customers = this.state.showRegisteredUsers ? registeredUsers : guests;

    let filteredCustomers = this.state.filterText
      ? customers.filter(customer =>
        customer.telephone
          .toLowerCase()
          .includes(this.state.filterText.toLowerCase())
      )
      : customers;

    if (tenant === undefined) {
      return <></>;
    }

    if (this.state.selectedCustomer) {
      return (<CustomerView customer={this.state.selectedCustomer} orders={this.state.orders} favoriteItems={this.state.favoriteItems} analyticsPerDay={this.state.analyticsPerDay} analyticsPerMenu={this.state.analyticsPerMenu} handleViewCustomers={this.handleViewCustomers} restaurant={restaurant} />)
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <FiIcons.FiUsers />
                {` `}
                <span>{`Customers`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row border-bottom">
          {/* <div className="col-12 col-sm-12 col-md-6">
            <SearchBar
              placeholder={`Search by phone number`}
              handleFilterTextChange={this.handleFilterTextChange}
            />
          </div> */}

          <div className="col-12 col-sm-12 col-lg-12 text-right">
            <div className="d-flex justify-content-end mb-3">
              <div className="btn-toolbar">
              <DateTimePicker
              onChange={this.handlePeriodFromChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"Start Date"}
              value={this.state.from}
              time={false}
              date={true}
              className="mr-3"
            />
            <DateTimePicker
              onChange={this.handlePeriodToChange}
              format={this.props.tenant.settings.date_format}
              placeholder={"End Date"}
              value={this.state.to}
              time={false}
              date={true}
              className="mr-3"
            />
                {/* <button className="btn btn-light mr-3" disabled><strong>{`${moment(this.state.from).tz(this.props.tenant.settings.time_zone).format(this.props.tenant.settings.date_format)} - ${moment(this.state.to).tz(this.props.tenant.settings.time_zone).format(this.props.tenant.settings.date_format)}`}</strong></button> */}
                <div className="dropdown">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-calendar-alt" />
                    {` `}
                    <span>{this.state.period}</span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Today")}
                    >{`Today`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Yesterday")}
                    >{`Yesterday`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Week to date")}
                    >{`Week to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 7 days")}
                    >{`Last 7 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last week")}
                    >{`Last week`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 14 days")}
                    >{`Last 14 days`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Month to date")}
                    >{`Month to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 30 days")}
                    >{`Last 30 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last month")}
                    >{`Last month`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Quarter to date")}
                    >{`Quarter to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 90 days")}
                    >{`Last 90 days`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last quarter")}
                    >{`Last quarter`}</button>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Year to date")}
                    >{`Year to date`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last 12 months")}
                    >{`Last 12 months`}</button>
                    <button
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectPeriod("Last year")}
                    >{`Last year`}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3 py-3 border-bottom">
          <div className="btn-toolbar">
            <button
              type="button"
              className={`btn ${this.state.showRegisteredUsers ? " btn-primary" : "btn-outline-primary"
                }  mr-2`}
              onClick={this.handleShowRegisteredUsers}
            >
              <span className="d-inline">Registered Users</span>
              {` `} {this.state.loading === false && <span className="badge badge-danger">{registeredUsers.length}</span>}
            </button>
            <button
              type="button"
              className={`btn ${this.state.showGuests
                ? " btn-secondary"
                : "btn-outline-secondary"
                }  mr-2`}
              onClick={this.handleShowGuests}
            >
              <span>Guests</span>
              {` `}{" "}
              {this.state.loading === false && <span className="badge badge-warning">
                {guests.length}
              </span>}
            </button>
          </div>
        </div>


        <CustomerListView
          customers={filteredCustomers}
          kind={this.state.showGuests ? "guest" : "registered-user"}
          handleViewDetail={this.handleViewDetail}
          tenant={tenant}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    customers: state.tenant.customers || []
  };
};

const mapDispatchToProps = dispatch => ({

  fetchCustomerOrders: (tenantId, phone, callback) => dispatch(fetchCustomerOrders(tenantId, phone, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customers);
