import React, { Component } from "react";
import { connect } from "react-redux";

import * as FaIcons from "react-icons/fa";

import { fetchInvoices, fetchCurrentInvoice } from "../../actions/tenant";

import Overview from "./Overview";
import Invoices from "./Invoices";
import InvoiceDetail from "./InvoiceDetail";
import Payments from "./Payments";
class Billing extends Component {
  constructor(props) {
    super(props);

    this.handleShowOverview = this.handleShowOverview.bind(this);
    this.handleShowInvoices = this.handleShowInvoices.bind(
      this
    );
    this.handleShowPayments = this.handleShowPayments.bind(
      this
    );

    this.handleShowInvoiceDetail = this.handleShowInvoiceDetail.bind(this);

    this.state = {
      showOverview: true,
      showInvoices: false,
      showInvoiceDetail: false,
    }
  }

  handleShowOverview() {
    this.setState({
      showOverview: true,
      showInvoices: false,
      showPayments: false,
      showInvoiceDetail: false
    });
  }

  handleShowInvoices() {
    this.setState({
      showOverview: false,
      showInvoices: true,
      showPayments: false,
      showInvoiceDetail: false
    });
  }

  handleShowPayments() {
    this.setState({
      showOverview: false,
      showInvoices: false,
      showPayments: true,
      showInvoiceDetail: false
    });
  }

  handleShowInvoiceDetail(invoice) {
    this.setState({
      showOverview: false,
      showInvoices: false,
      showInvoiceDetail: true,
      selectedInvoice: invoice
    });
  }

  render() {
    const { restaurant } = this.props;

    if (restaurant === undefined) {
      return <></>;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <FaIcons.FaFileInvoiceDollar />
                {` `}
                <span>{`Billing`}</span>
              </button>
            </li>
          </ol>
        </nav>

        <div className="border-bottom mb-3 py-3">
          <button
            type="button"
            className={`btn ${this.state.showOverview ? " btn-primary" : "btn-outline-primary"
              }  mr-3`}
            onClick={this.handleShowOverview}
          >
            <span className="d-inline">Overview</span>
          </button>
          <button
            type="button"
            className={`btn ${this.state.showInvoices
              ? " btn-secondary"
              : "btn-outline-secondary"
              }  mr-3`}
            onClick={this.handleShowInvoices}
          >
            <span>Invoices</span>
          </button>
          <button
            type="button"
            className={`btn ${this.state.showPayments
              ? " btn-secondary"
              : "btn-outline-secondary"
              }  mr-3`}
            onClick={this.handleShowPayments}
          >
            <span>Payments</span>
          </button>
        </div>

        {this.state.showOverview && (
          <Overview handleViewInvoice={this.handleShowInvoiceDetail} />
        )}

        {this.state.showInvoices && (
          <Invoices handleViewInvoice={this.handleShowInvoiceDetail} />
        )}

        {this.state.showPayments && (
          <Payments tenant={this.props.tenant} />
        )}

        {this.state.showInvoiceDetail && (
          <InvoiceDetail invoice={this.state.selectedInvoice} />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant,
    invoice: state.tenant.currentInvoice,
    invoices: state.tenant.invoices
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchInvoices: (tenantId) => dispatch(fetchInvoices(tenantId)),
  fetchCurrentInvoice: (tenantId) => dispatch(fetchCurrentInvoice(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
