import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";

import MenuItemItemAddModal from "./MenuItemItemAddModal";
import { fetchMenuItem, removeMenuItemItem } from "../../actions/menu";
import MenuItemItemEditModal from "./MenuItemItemEditModal";

import spicy_mild_img from '../../assets/spicy_mild_16.png';
import spicy_medium_img from '../../assets/spicy_medium_16.png';

class MenuItemItemssCard extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveItemItem = this.handleRemoveItemItem.bind(this);
  }

  handleRemoveItemItem(menuId, menuSectionId, menuItemId, menuItemItemId) {
    this.props.removeMenuItemItem(
      menuId,
      menuSectionId,
      menuItemId,
      menuItemItemId
    );
  }

  generateDataTarget(menuItemItemId) {
    return "#menuItemItemEditModal" + menuItemItemId
  }

  render() {
    const { menuItem, menuId, menuSectionId, menuItemId } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Items</h5>
            </div>
            <div className="col-6 text-right">
              <MenuItemItemAddModal
                menuId={menuId}
                menuSectionId={menuSectionId}
                menuItemId={menuItemId}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <ul className="list-unstyled">
            {menuItem &&
              menuItem.items &&
              menuItem.items.map((itemItem, index) => (
                <li key={itemItem._id}>
                  <>
                    <div className="row">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          title="Remove"
                          onClick={() =>
                            this.handleRemoveItemItem(
                              menuId,
                              menuSectionId,
                              menuItemId,
                              itemItem._id
                            )
                          }
                        >
                          <Icon.Trash2 size={16} />
                        </button>
                        {` `}
                        <button
                          type="button"
                          className="btn btn-light"
                          data-toggle="modal"
                          data-target={this.generateDataTarget(itemItem._id)}
                          data-backdrop="static"

                        >
                          <Icon.Edit2 size={16} />
                        </button>
                        <MenuItemItemEditModal
                          menuId={menuId}
                          menuSectionId={menuSectionId}
                          menuItemId={menuItemId}
                          menuItemItemId={itemItem._id}
                          menuItemItem={itemItem}
                        />
                      </div>
                      <div className="col-9">{itemItem.name}
                        {itemItem.spicy && itemItem.spicy === "mild" && <img src={spicy_mild_img} alt="" />}
                        {itemItem.spicy && itemItem.spicy === "medium" && <img src={spicy_medium_img} alt="" />}
                        {itemItem.spicy && itemItem.spicy === "hot" && <><img src={spicy_medium_img} alt="" /><img src={spicy_medium_img} alt="" /></>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItem: state.menu.menuItem
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  removeMenuItemItem: (menuId, menuSectionId, menuItemId, menuItemItemId) =>
    dispatch(
      removeMenuItemItem(menuId, menuSectionId, menuItemId, menuItemItemId)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemItemssCard);
