import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCustomers } from "../../actions/tenant";

import CustomerListView from "./CustomerListView";
import { Redirect } from "react-router-dom";

import * as RiIcons from "react-icons/ri";

import normalizePhone from "../../utils/normalizePhone";

class SearchCustomers extends Component {
  constructor(props) {
    super(props);
    this.handleViewDetail = this.handleViewDetail.bind(this);
    this.handleEmailFilterChange =
      this.handleEmailFilterChange.bind(this);
    this.handlePhoneNumberFilterChange =
      this.handlePhoneNumberFilterChange.bind(this);
    this.handleNameFilterChange = this.handleNameFilterChange.bind(this);

    this.handleSearch = this.handleSearch.bind(this);

    this.state = {
      loading: false,
      emailFilter: undefined,
      phoneNumberFilter: undefined,
      nameFilter: undefined,
      selectedCustomer: undefined,
    };
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {}

  handleEmailFilterChange(event) {
    this.setState({
      emailFilter: event.target.value,
    });
  }

  handlePhoneNumberFilterChange(event) {
    this.setState({
      phoneNumberFilter: normalizePhone(event.target.value),
    });
  }

  handleNameFilterChange(event) {
    this.setState({
      nameFilter: event.target.value,
    });
  }

  handleViewDetail(customer) {
    this.setState({ selectedCustomer: customer });
  }


  handleSearch() {
    this.props.fetchCustomers(
      this.props.tenant._id,
      this.state.nameFilter,
      this.state.phoneNumberFilter,
      this.state.emailFilter,
    );
  }

  render() {
    const { tenant, customers = [] } = this.props;

    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.selectedCustomer) {
      return (
        <Redirect
          to={`/searchcustomers/${this.state.selectedCustomer._id}`}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <RiIcons.RiUserSearchLine />
                {` `}
                <span>{`Search Customers`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="nameFilter"
              type="text"
              className="form-control"
              placeholder={"Customer Name"}
              onChange={this.handleNameFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="phoneNumberFilter"
              type="tel"
              className="form-control"
              placeholder={"123-456-7890"}
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              onChange={this.handlePhoneNumberFilterChange}
            />
          </div>
          <div className="col-6 col-lg-3 col-xl-2 mb-3">
            <input
              name="emailFilter"
              type="text"
              className="form-control"
              placeholder={"Email"}
              onChange={this.handleEmailFilterChange}
            />
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleSearch}
            >
              Search
            </button>
          </div>
        </div>

        <CustomerListView
          customers={customers}
          kind={this.state.showGuests ? "guest" : "registered-user"}
          mode="search"
          handleViewDetail={this.handleViewDetail}
          tenant={tenant}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    customers: state.tenant.customers || []
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchCustomers: (
    tenantId,
    name,
    phoneNumber,
    email,
  ) =>
    dispatch(
      fetchCustomers(tenantId, name, phoneNumber, email)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomers);
