import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone";

import Numeral from "numeral";

import Loading from "../Common/Loading";

const Payments = ({ tenant }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState({loading: false});

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      const result = await axios(`${process.env.REACT_APP_API_BASE}/api/eatery/tenants/${tenant._id}/payments`);
      const payments = result.data.payments;
      payments.sort((a, b) => moment(a.paid_on).isBefore(moment(b.paid_on)) ? 1 : -1);
      setPayments(payments);
      setLoading(false);
    };

    fetchData();
  }, [tenant._id]);

  return (
    <>
      <div className="row border-bottom pb-3">
        <div className="col-xl-2 d-none d-xl-inline text-left">
          <strong>{`Invoice Number`}</strong>
        </div>
        <div className="col-xl-2 d-none d-xl-inline text-left">
          <strong>{`Invoice Period`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-right">
          <strong>{`Amount Invoiced`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-right">
          <strong>{`Amount Paid`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-right">
          <strong>{`Amount Unpaid`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-left">
          <strong>{`Payment Method`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-left">
          <strong>{`Reference Number`}</strong>
        </div>
        <div className="col-xl-2 d-none d-xl-inline text-left">
          <strong>{`Paid On`}</strong>
        </div>
        <div className="col-xl-1 d-none d-xl-inline text-left">
          <strong>{`Paid By`}</strong>
        </div>
      </div>
      {loading && <Loading />}
      {loading === false && payments.map(payment => (
        <div className="row py-3 border-bottom" key={payment._id}>
          <div className="col-12 col-xl-2 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Invoice Number: </strong>{" "}
            </span>{" "}
            {payment.invoice}
          </div>
          <div className="col-12 col-xl-2 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Invoice Period: </strong>{" "}
            </span>{" "}
            {`${moment(payment.billing_period_from).tz(tenant.settings.time_zone).format(tenant.settings.date_format)} - ${moment(payment.billing_period_to).tz(tenant.settings.time_zone).format(tenant.settings.date_format)}`}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-right">
            <span className="d-inline d-xl-none">
              <strong>Amount Invoiced: </strong>{" "}
            </span>{" "}
            {Numeral(payment.amount_invoiced).format(
              "$0,0.00"
            )}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-right">
            <span className="d-inline d-xl-none">
              <strong>Amount Paid: </strong>{" "}
            </span>{" "}
            {Numeral(payment.amount_paid).format(
              "$0,0.00"
            )}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-right">
            <span className="d-inline d-xl-none">
              <strong>Amount Unpaid: </strong>{" "}
            </span>{" "}
            {Numeral(payment.amount_unpaid).format(
              "$0,0.00"
            )}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Payment Method: </strong>{" "}
            </span>{" "}
            {payment.payment_method}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Reference Number: </strong>{" "}
            </span>{" "}
            {payment.reference_number}
          </div>
          <div className="col-12 col-xl-2 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Padi On: </strong>{" "}
            </span>{" "}
            {`${moment(payment.paid_on).tz(tenant.settings.time_zone).format(tenant.settings.datetime_format)}`}
          </div>
          <div className="col-12 col-xl-1 text-left text-xl-left">
            <span className="d-inline d-xl-none">
              <strong>Padi By: </strong>{" "}
            </span>{" "}
            {payment.paid_by}
          </div>
        </div>
      ))}
    </>
  );
}

export default Payments;