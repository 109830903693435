import React, { Component } from "react";
import { connect } from "react-redux";

import { selectRestaurant } from "../../actions/tenant";

import { isOwner } from "../User";

import AddRestaurant from "./AddRestaurant";
import EditRestaurant from "./EditRestaurant";
import * as Icon from "react-feather";
import { Restaurant } from "./Restaurant";

class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.handleViewRestaurants = this.handleViewRestaurants.bind(this);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleAddRestaurant = this.handleAddRestaurant.bind(this);

    this.state = {
      mode: undefined,
      selectRestaurant: undefined
    };
  }

  componentDidMount() {}

  handleSelectRestaurant(restaurant) {
    this.setState({ mode: "View", selectRestaurant: restaurant });
  }

  handleViewRestaurants() {
    this.setState({ selectedRestaurant: undefined, mode: undefined });
  }

  handleAddRestaurant() {
    this.setState({ selectedRestaurant: undefined, mode: "Add" });
  }

  render() {
    const { tenant, restaurants } = this.props;
    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.mode === "Edit" && this.state.selectRestaurant != null) {
      return (
        <EditRestaurant
          restaurant={this.state.selectRestaurant}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    if (this.state.mode === "Add") {
      return (
        <AddRestaurant handleViewRestaurants={this.handleViewRestaurants} />
      );
    }
    if (this.state.mode === "View") {
      return (
        <Restaurant
          tenant={tenant}
          restaurant={this.state.selectRestaurant}
          handleViewRestaurants={this.handleViewRestaurants}
        />
      );
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{tenant.name}</span>
              </button>
            </li> */}
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span>{`Restaurants`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <div className="btn-group mr-2">
              {isOwner() && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleAddRestaurant}
                >
                  <Icon.Plus size={16} />
                  {` `}
                  Add New Restaurant
                </button>
              )}
            </div>
          </div>
        </div>
        {restaurants.map((restaurant, index) => (
          <div
            className="row clickable-row"
            key={restaurant._id}
            onClick={() => this.handleSelectRestaurant(restaurant)}
          >
            <div className="col-12">
              <div className="card mb-3">
                {/* <Image
                  className="card-img-top img-fluid"
                  src={restaurant.image ? restaurant.image.url : undefined}
                  alt="Restaurant Image"
                /> */}
                <div className="card-body text-center">
                  <h5 className="card-title">{restaurant.name}</h5>
                  <address>
                    {restaurant.address_1} <br />
                    {restaurant.city_town}, {restaurant.state_province}{" "}
                    {restaurant.postal_code} <br />
                    {restaurant.phone} <br />
                  </address>
                </div>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    restaurants: state.tenant.restaurants || [],
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  selectRestaurant: restaurant => dispatch(selectRestaurant(restaurant))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Restaurants);
