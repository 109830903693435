import React, { Component } from "react";

import { connect } from "react-redux";

import { fetchHistoricalOrders } from "../../actions/historicalorder";

import moment from "moment-timezone";

import AddExternalOrder from "./AddHistoricalOrder";

import Loading from "../Common/Loading";

import * as AiIcons from "react-icons/ai";
class HistoricalOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.restaurant) {
      this.setState({
        loading: true,
      });
      this.props.fetchHistoricalOrders(this.props.restaurant._id, () => {
        this.setState({
          loading: false,
        });
      });
    }
  }

  render() {
    const { restaurant, historicalOrders = [] } = this.props;

    if (!restaurant) {
      return <></>;
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
              <AiIcons.AiOutlineFileDone /> {` `}
                <span>{`Historical Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <AddExternalOrder />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th>#</th>
                <th>Order Date</th>
                <th>Phone Number</th>
                <th>Delivery Address</th>
                <th>Items</th>
              </tr>
            </thead>
            <tbody>
              {this.state.loading && (
                <tr>
                  <td colSpan="5">
                    <Loading />
                  </td>
                </tr>
              )}
              {this.state.loading === false && (
                <>
                  {historicalOrders.map((order, index) => (
                    <tr key={order._id}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(order.orderDate)
                          .tz(restaurant.settings.time_zone)
                          .format(restaurant.settings.date_format)}
                      </td>
                      <td>{order.phone}</td>
                      <td>{order.deliveryAddress}</td>
                      <td>
                        {order.items.map((item) => (
                          <div className="row" key={item._id}>
                            <div className="col-9">
                              {`${item.cd} ${item.name}`}
                              {item.options &&
                                item.options.map((option, index1) => (
                                  <div className="row" key={index1}>
                                    <div className="col-12">
                                      <span className="text-muted ml-3">
                                        <small>
                                          {`${option.name}:`}
                                          {option.value && (
                                            <span>{` ${option.value}`}</span>
                                          )}
                                        </small>
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>
                            <div className="col-3">{`X ${item.quantity}`}</div>
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.historicalOrder.errorMessage,
    message: state.historicalOrder.message,
    restaurant: state.tenant.restaurant,
    tenant: state.tenant.tenant,
    historicalOrders: state.historicalOrder.orders,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHistoricalOrders: (restaurantId, callback) =>
    dispatch(fetchHistoricalOrders(restaurantId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalOrders);
