import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderField } from "../../utils/renderFields";
import { addAnnouncement } from "../../actions/announcement";
import AnnoucementPhotoPicker from "./AnnoucementPhotoPicker";

const form = reduxForm({
  form: "addAnnouncement",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.title) {
    errors.title = "Please enter title";
  }

  if (!formProps.short_description) {
    errors.short_description = "Please enter short_description";
  }

  if (!formProps.description) {
    errors.description = "Please enter description";
  }

  return errors;
}

class AddAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      imageUrl: ""
    }
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    const data = {...formProps, imageUrl: this.state.imageUrl};
    this.props.addAnnouncement(this.props.tenant._id, data, () => {
      this.props.handleView();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      tenant,
      handleView,
      handleSubmit,
      pristine,
      submitting
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleView}
              >
                <i className="fas fa-bullhorn" />
                {` `}
                <span className="d-none d-sm-inline">{`Announcements`}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                {`Add Announcement`}
              </button>
            </li>
          </ol>
        </nav>
        <form
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          {this.renderAlert()}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <div className="text-center"
                    style={{
                      // width: "330px",
                      // height: "240px",
                      borderWidth: "2px",
                      borderColor: "rgb(102, 102, 102)",
                      borderStyle: "dashed",
                      borderRadius: "5px",
                      padding: "20px",
                    }}>
                    {this.state.imageUrl &&
                      <img
                        className="img-fluid"
                        width={229}
                        height={150}
                        src={
                          process.env.REACT_APP_S3_BASE_URL +
                          `${tenant.slug + "/" + this.state.imageUrl
                          }`
                        }
                        alt="Annoucement"
                      />
                    }
                    <AnnoucementPhotoPicker
                      tenant={tenant}
                      handleImageUrlChange={imageUrl => {
                        this.setState({ imageUrl: imageUrl });
                      }}
                      imageUrl={this.state.imageUrl}
                    />

                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">Title</label>
                  <Field
                    name="title"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Short Description</label>
                  <Field
                    name="short_description"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Description</label>
                  <Field
                    name="description"
                    className="form-control"
                    component={renderField}
                    type="text"
                    isTextarea={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={handleView}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            {"Save"}
          </button>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.announcement.errorMessage,
    message: state.announcement.message
  };
};

const mapDispatchToProps = dispatch => ({
  addAnnouncement: (tenantId, formProps, callback) =>
    dispatch(addAnnouncement(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddAnnouncement));
