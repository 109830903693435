import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import * as MdIcons from "react-icons/md";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "kitchenOrderSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class KitchenOrderSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      applicable_order_statuses:
        props.restaurant.settings.kitchen_order_settings
          ?.applicable_order_statuses || [],
      cooking_queue_intervals:
        props.restaurant.settings.kitchen_order_settings?.cooking_queue_intervals ||
        [],
      order_status: "",
      interval: 0,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        kitchen_order_settings_applicable_order_statuses:
          this.state.applicable_order_statuses,
        kitchen_order_settings_cooking_queue_intervals:
          this.state.cooking_queue_intervals,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } =
      this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <MdIcons.MdKitchen />
                {` `}
                <span className="d-none d-sm-inline">{`Kitche Orders`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Tenant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.tenant.name}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h5>Applicable Order Status</h5>
              </div>
              <div className="col-6 text-right"></div>
            </div>

            <div className="form-row">
              <div className="col-12 col-lg-4 input-group">
                <select
                  name="order_status"
                  className="form-control mr-3"
                  value={this.state.order_status}
                  onChange={(e) =>
                    this.setState({ order_status: e.target.value })
                  }
                >
                  <option value="">Please select one</option>
                  <option value="Accepted">Accepted</option>
                  <option value="InPreparation">InPreparation</option>
                </select>

                <button
                  className="btn btn-primary"
                  disabled={!this.state.order_status}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState((prevState) => {
                      const new_statuses =
                        prevState.applicable_order_statuses.includes(
                          this.state.order_status
                        )
                          ? prevState.applicable_order_statuses
                          : [
                              ...prevState.applicable_order_statuses,
                              this.state.order_status,
                            ];
                      return {
                        applicable_order_statuses: new_statuses,
                        order_status: "",
                      };
                    });
                  }}
                >
                  {`Add Status`}
                </button>
              </div>{" "}
            </div>
            <div className="row my-3">
              <div className="col">
                {this.state.applicable_order_statuses.map(
                  (order_status, index) => (
                    <span key={index} className="removable-label bg-info mr-3">
                      <span className="mr-3">{order_status}</span>
                      <i
                        className="fas fa-times"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState((prevState) => {
                            return {
                              applicable_order_statuses:
                                prevState.applicable_order_statuses.filter(
                                  (item) => item !== order_status
                                ),
                            };
                          });
                        }}
                      />
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <h5>Cooking Queue Intervals</h5>
              </div>
              <div className="col-6 text-right"></div>
            </div>

            <div className="form-row">
              <div className="col-12 col-lg-4 input-group">
                <input
                  type="number"
                  className="form-control mr-3"
                  name="interval"
                  value={this.state.interval}
                  onChange={(e) => this.setState({ interval: e.target.value })}
                />
                <button
                  className="btn btn-primary"
                  disabled={!this.state.interval}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState((prevState) => {
                      const new_intervals =
                        prevState.cooking_queue_intervals.includes(
                          this.state.interval
                        )
                          ? prevState.cooking_queue_intervals
                          : [
                              ...prevState.cooking_queue_intervals,
                              this.state.interval,
                            ];
                      return {
                        cooking_queue_intervals: new_intervals,
                        interval: 0,
                      };
                    });
                  }}
                >
                  {`Add Interval`}
                </button>
              </div>{" "}
            </div>
            <div className="row my-3">
              <div className="col">
                {this.state.cooking_queue_intervals.map((interval, index) => (
                  <span key={index} className="removable-label bg-info mr-3">
                    <span className="mr-3">{interval}</span>
                    <i
                      className="fas fa-times"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState((prevState) => {
                          return {
                            cooking_queue_intervals:
                              prevState.cooking_queue_intervals.filter(
                                (item) => item !== interval
                              ),
                          };
                        });
                      }}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, formProps, callback) =>
    dispatch(updateSettings(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(KitchenOrderSettings));
