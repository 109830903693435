import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";
import moment from "moment-timezone";

import OrderInfoCard from "./OrderInfoCard";
import CustomerInfoCard from "./CustomerInfoCard";

import { printOrder } from "../Print/PrintOrder";

import {
  fetchOrder,
  acceptOrder,
  acceptOrderWithDelay,
} from "../../actions/order";

import "./OrderDetail.css";
import SendEmail from "./SendEmail";
import RejectOrder from "./RejectOrder";
import UpdateOrder from "./UpdateOrder";

import PrintKitchenReceipt from "./PrintKitchenReceipt";
import PrintCustomerReceipt from "./PrintCustomerReceipt";

import { socket } from "../../service/socket";

import FulfillOrder from "./FulfillOrder";

import { fetchCouponByOrderNumber } from "../../actions/coupon";
import UpdateDeliveryInfo from "./UpdateDeliveryInfo";
import ApplyCoupon from "./ApplyCoupon";

class OrderDetailCard extends Component {
  render() {
    const { order, restaurant, coupon } = this.props;
    return (
      <div className="row">
        <div className="col-12">
          <OrderInfoCard
            order={order}
            restaurant={restaurant}
            coupon={coupon}
          />
        </div>
        <div className="col-12">
          <br />
        </div>
        <div className="col-12">
          <CustomerInfoCard order={order} restaurant={restaurant} />
        </div>
      </div>
    );
  }
}

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.handleAcceptOrder = this.handleAcceptOrder.bind(this);
    this.handleAcceptOrderWithDelay =
      this.handleAcceptOrderWithDelay.bind(this);
  }

  componentDidMount() {
    if (this.props.orderNumber) {
      this.props.fetchOrder(this.props.tenant._id, this.props.orderNumber);
      if (this.props.restaurant && this.props.orderNumber) {
        this.props.fetchCouponByOrderNumber(
          this.props.tenant._id,
          this.props.orderNumber
        );
      }
    }
  }

  handleAcceptOrder(order) {
    this.props.acceptOrder(this.props.tenant._id, order._id, (data) => {
      socket.emit(
        "accept order",
        this.props.restaurant._id,
        data.order,
        this.props.fetchPeriod
      );
      if (
        this.props.restaurant.settings.printing_settings
          .print_order_after_accept
      ) {
        printOrder(
          data.order,
          this.props.coupon,
          this.props.restaurant,
          this.props.tenant,
          true,
          true,
          true
        );
      }
    });
  }

  handleAcceptOrderWithDelay(order, delayInMinutes) {
    this.props.acceptOrderWithDelay(
      this.props.tenant._id,
      order._id,
      delayInMinutes,
      (data) => {
        socket.emit(
          "accept order",
          this.props.restaurant._id,
          data.order,
          this.props.fetchPeriod
        );
        if (
          this.props.restaurant.settings.printing_settings
            .print_order_after_accept
        ) {
          printOrder(
            data.order,
            this.props.coupon,
            this.props.restaurant,
            this.props.tenant,
            true,
            true,
            true
          );
        }
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order, tenant, restaurant, settings, handleViewOrders, coupon } =
      this.props;

    if (!order) {
      return <></>;
    }

    const isNextDayOrder = moment(order.pickupTime)
      .tz(restaurant.settings.time_zone)
      .isAfter(moment().tz(restaurant.settings.time_zone).endOf("day"));

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewOrders}
              >
                Orders
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {order.orderNumber}
              </button>
            </li>
          </ol>
        </nav>
        {this.renderAlert()}
        <div className="row pb-3">
          <div className="col-1">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewOrders}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-11 text-right">
            <div className="btn-group">
              {order.status !== "Cancelled" &&
               <>
                <ApplyCoupon order={order} />
                <UpdateDeliveryInfo order={order}  fetchPeriod={this.props.fetchPeriod} />
                <UpdateOrder order={order} fetchPeriod={this.props.fetchPeriod} />
               </>
              }
              <SendEmail order={order} />
              <PrintKitchenReceipt
                order={order}
                coupon={coupon}
                restaurant={restaurant}
                tenant={tenant}
              />
              <PrintCustomerReceipt
                order={order}
                coupon={coupon}
                restaurant={restaurant}
                tenant={tenant}
              />
              {order.status === "Received" && (
                <>
                  <RejectOrder
                    order={order}
                    fetchPeriod={this.props.fetchPeriod}
                  />

                  <div className="dropdown">
                    <button
                      id="dropdownMenuButton"
                      type="button"
                      className="btn btn-outline-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      disabled={isNextDayOrder}
                    >
                      <Icon.Check size={16} className="d-inline mr-1" />
                      <span className="d-none d-md-inline">Accept</span>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {settings.pickup_time_adjustment_options.map(
                        (option, index) => (
                          <>
                            {option > 0 ? (
                              <button
                                key={index}
                                className="dropdown-item clickable"
                                onClick={() =>
                                  this.handleAcceptOrderWithDelay(order, option)
                                }
                              >
                                Accept with {option} minutes delay
                              </button>
                            ) : (
                              <button
                                key={index}
                                className="btn-lg dropdown-item clickable"
                                onClick={() => this.handleAcceptOrder(order)}
                              >
                                <span className="acceptButton">Accept</span>
                              </button>
                            )}
                            <div className="dropdown-divider"></div>
                          </>
                        )
                      )}
                    </div>
                  </div>
                </>
              )}
              {order.status === "Rejected" && (
                <div className="dropdown">
                  <button
                    id="dropdownMenuButton"
                    type="button"
                    className="btn btn-outline-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={isNextDayOrder}
                  >
                    <Icon.Check size={16} className="d-inline mr-1" />
                    <span className="d-none d-md-inline">Accept</span>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {settings.pickup_time_adjustment_options.map(
                      (option, index) => (
                        <React.Fragment key={index}>
                          {option > 0 ? (
                            <button
                              key={index}
                              className="dropdown-item clickable"
                              onClick={() =>
                                this.handleAcceptOrderWithDelay(order, option)
                              }
                            >
                              Accept with {option} minutes delay
                            </button>
                          ) : (
                            <button
                              key={index}
                              className="btn-lg dropdown-item clickable"
                              onClick={() => this.handleAcceptOrder(order)}
                            >
                              <span className="acceptButton">Accept</span>
                            </button>
                          )}
                          <div className="dropdown-divider"></div>
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              )}
              {["Accepted", "InPreparation", "ReadyForPickup", "InTransit"].includes(order.status) && (
                <>
                  <RejectOrder
                    order={order}
                    fetchPeriod={this.props.fetchPeriod}
                  />

                  <FulfillOrder
                    order={order}
                    fetchPeriod={this.props.fetchPeriod}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {/* {this.renderAlert()} */}
        <OrderDetailCard
          order={order}
          restaurant={restaurant}
          coupon={coupon}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.order.order,
    settings: state.tenant.restaurant.settings || state.tenant.tenant.settings,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.order.errorMessage,
    coupon: state.coupon.coupon,
    // message: state.order.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (tenantId, orderNumber) =>
    dispatch(fetchOrder(tenantId, orderNumber)),
  fetchCouponByOrderNumber: (tenantId, orderNumber) =>
    dispatch(fetchCouponByOrderNumber(tenantId, orderNumber)),
  acceptOrder: (tenantId, orderId, callback) =>
    dispatch(acceptOrder(tenantId, orderId, callback)),
  acceptOrderWithDelay: (tenantId, orderId, delayInMinutes, callback) =>
    dispatch(acceptOrderWithDelay(tenantId, orderId, delayInMinutes, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);
