import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import moment from "moment";
import Switch from "react-switch";

import { addBusinessHour } from "../../actions/restaurant";

import { renderDateTimePicker } from "../../utils/renderFields";

const form = reduxForm({
  form: "addBusinessHour",
  validate
});

function validate(formProps) {
  const errors = {};
  if (!formProps.isOpen) {
    errors.isOpen = "Please choose open/close";
  }
  if (!formProps.openTime) {
    errors.openTime = "Please enter openTime";
  }
  if (!formProps.closeTime) {
    errors.closeTime = "Please enter closeTime";
  }

  return errors;
}

class AddHolidayHour extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleOpenClose = this.handleOpenClose.bind(this);

    this.state = {
      is_open: false,
    };
  }

  componentDidMount() {}

  handleOpenClose() {
    this.setState(prevState => ({ is_open: !prevState.is_open }));
  }

  handleFormSubmit(formProps) {
    let open_time = moment(formProps.openTime).format(this.props.restaurant.settings.time_format);
    let close_time = moment(formProps.closeTime).format(this.props.restaurant.settings.time_format);

    this.props.addBusinessHour(
      this.props.restaurant._id,
      formProps.date,
      this.state.is_open,
      open_time,
      close_time,
      () => {
        this.props.handleViewHolidayHours();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      restaurant,
      handleViewHolidayHours,
      handleSubmit,
      pristine,
      submitting
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewHolidayHours}
              >
                <i className="fas fa-business-time" />
                {` `}
                <span className="d-none d-sm-inline">{`Holiday Hours`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                Add
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}

          <div className="form-group row">
            <label className="col-form-label col-sm-4 col-md-3 col-xl-2 text-sm-right">
              Date
            </label>
            <div className="col-sm-4 col-lg-3">
              <Field
                name="date"
                className="form-control"
                component={renderDateTimePicker}
                showTime={false}
                dateFormat={restaurant.settings.date_format}
                timeFormat={restaurant.settings.time_format}
                datetimeFormat={restaurant.settings.date_time_format}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 col-md-3 col-xl-2 text-sm-right">
              Open/Close
            </label>
            <div className="col-sm-4 col-lg-3">
              <Switch
                className="align-middle"
                onChange={() => this.handleOpenClose()}
                checked={this.state.is_open}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 col-md-3 col-xl-2 text-sm-right">
              Open Time
            </label>
            <div className="col-sm-4 col-lg-3">
              <Field
                name="openTime"
                className="form-control"
                component={renderDateTimePicker}
                showDate={false}
                showTime={true}
                disabled={!this.state.is_open}
                dateFormat={restaurant.settings.date_format}
                timeFormat={restaurant.settings.time_format}
                datetimeFormat={restaurant.settings.date_time_format}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 col-md-3 col-xl-2 text-sm-right">
              Close Time
            </label>
            <div className="col-sm-4 col-lg-3">
              <Field
                name="closeTime"
                className="form-control"
                component={renderDateTimePicker}
                showDate={false}
                showTime={true}
                disabled={!this.state.is_open}
                dateFormat={restaurant.settings.date_format}
                timeFormat={restaurant.settings.time_format}
                datetimeFormat={restaurant.settings.date_time_format}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-8 col-md-7 col-lg6 col-xl-5 text-center">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewHolidayHours}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurant: state.tenant.restaurant,
    errorMessage: state.restaurant.error,
    message: state.restaurant.message
  };
};

const mapDispatchToProps = dispatch => ({
  addBusinessHour: (
    restaurantId,
    date,
    is_open,
    open_time,
    close_time,
    callback
  ) =>
    dispatch(
      addBusinessHour(
        restaurantId,
        date,
        is_open,
        open_time,
        close_time,
        callback
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddHolidayHour));
