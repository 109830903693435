import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Redirect,
} from 'react-router-dom'

export default function (ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    }

    constructor(props) {
      super(props)
      this.state = {
        redirect: false
      }
    }

    componentWillMount() {
      if (!this.props.authenticated) {
        this.setState({ redirect: true })
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        this.setState({ redirect: true })
      }
    }

    render() {
      return !this.state.redirect ? <ComposedComponent {...this.props} /> : <Redirect to={{
        pathname: '/login',
        state: { from: this.props.location }
      }} />
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps)(Authentication);
}