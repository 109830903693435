import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../utils/renderFields";
import { addMenu } from "../../actions/menu";
import MenuPhotoPicker from "./MenuPhotoPicker";

const form = reduxForm({
  form: "menuInfo",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter menu name";
  }

  return errors;
}

class AddMenu extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.addMenu(
      this.props.tenant._id,
      formProps,
      this.props.menuPhotoUrl,
      () => {
        this.props.handleViewMenus();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      tenant,
      restaurant,
      handleViewMenus,
      handleSubmit,
      pristine,
      submitting,
      menuPhotoUrl,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Add New Menu
              </button>
            </li>
          </ol>
        </nav>

        {this.renderAlert()}
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="form-group">
              <label className="col-form-label">Image</label>
              <div>
                {menuPhotoUrl && (
                  <img
                    className="img-fluid mb-2"
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${tenant.slug}/${menuPhotoUrl.replace(".jpg", "")}.jpg`
                    }
                    alt="Menu"
                  />
                )}
                {!menuPhotoUrl && (
                  <MenuPhotoPicker tenant={tenant} restaurant={restaurant} />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="form-group">
                <label className="col-form-label">Name</label>
                <Field
                  name="name"
                  className="form-control"
                  component={renderField}
                  type="text"
                  placeholder="2 -50 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Description</label>
                <Field
                  name="description"
                  className="form-control"
                  component="textarea"
                  placeholder="Maximum 255 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Note</label>
                <Field
                  name="note"
                  className="form-control"
                  component="textarea"
                  placeholder="Maximum 255 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">
                  {`Online Ordering`}
                </label>
                <Field
                  name="online_ordering"
                  className="form-control"
                  component="select"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Available For</label>
                <Field
                  name="available_for"
                  className="form-control"
                  component="select"
                  multiple={true}
                >
                  <option value="dinein">Dine In</option>
                  <option value="takeout">Take Out</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Available During</label>
                <Field
                  name="duration.name"
                  className="form-control"
                  component="select"
                >
                  <option value="breakfast">Breakfast</option>
                  <option value="brunch">Brunch</option>
                  <option value="lunch">Lunch</option>
                  <option value="breakfast-lunch">Breakfast-Lunch</option>
                  <option value="dinner">Dinner</option>
                  <option value="lunch-dinner">Lunch-Dinner</option>
                  <option value="late-night">Late-Night</option>
                  <option value="all">All</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Time Start (Format: {tenant.settings.time_format})</label>
                <Field
                  name="duration.time_start"
                  className="form-control"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Time End (Format: {tenant.settings.time_format})</label>
                <Field
                  name="duration.time_end"
                  className="form-control"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Display Order</label>
                <Field
                  name="display_order"
                  className="form-control"
                  component={renderField}
                  type="number"
                />
              </div>
              <div className="form-group">
                          <label className="col-form-label">Visibility</label>
                          <div>
                          <div className="form-check form-check-inline">
                            <Field
                              name="hidden_from_public"
                              className="form-check-input"
                              component="input"
                              type="checkbox"
                            />
                            <label className="form-check-label">
                              {` `}
                              {`Hidden From Public`}
                            </label>
                          </div>
                          </div>
                        </div>
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewMenus}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const menuPhotoUrl = state.menu.menuPhotoUrl;
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: {
      name: undefined,
      description: undefined,
      note: undefined,
      available_for: [],
      display_order: undefined,
      online_ordering: false,
      hidden_from_public: false,
    },
    menuPhotoUrl: menuPhotoUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMenu: (tenantId, formProps, menuPhotoUrl, callback) =>
    dispatch(addMenu(tenantId, formProps, menuPhotoUrl, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddMenu));
