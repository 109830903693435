import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import { updateOrder } from "../../actions/order";

import DateTimePicker from "react-widgets/lib/DateTimePicker";

import moment from "moment-timezone";

import { getOpeningHourRange } from "../Common/OpeningHourRange";

const calculateTimeslots = (startAt, endAt, intervalInMinutes, restaurant) => {
  if (!intervalInMinutes || intervalInMinutes <= 0) {
    intervalInMinutes = 15;
  }
  let timeslots = [];
  let duration = moment.duration(endAt.diff(startAt));
  let minutes = duration.asMinutes();
  let count = Math.ceil(minutes / intervalInMinutes);
  for (let i = 0; i <= count; i++) {
    const timeslot = moment(startAt)
      .tz(restaurant.settings.time_zone)
      .add(i * intervalInMinutes, "minutes");
    timeslots.push(timeslot);
  }
  return timeslots;
};

class UpdateOrder extends Component {
  constructor(props) {
    super(props);

    this.handlePickupDateChange = this.handlePickupDateChange.bind(this);
    this.handlePickupTimeChange = this.handlePickupTimeChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {

    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {}

  handlePhoneChange(event) {
    this.setState({
      phone: event.target.value,
    });
  }

  handlePickupDateChange(value) {
    this.setState({
      pickupDate: new Date(value),
    });
  }

  handlePickupTimeChange(event) {
    this.setState({
      pickupTime: event.target.value,
    });
  }


  handleSave() {
    const pickupDate = moment(
      this.state.pickupDate || new Date(this.props.order.pickupTime)
    )
      .tz(this.props.restaurant.settings.time_zone)
      .startOf("date");
    const pickupTimeStr =
      this.state.pickupTime ||
      moment(this.props.order.pickupTime)
        .tz(this.props.restaurant.settings.time_zone)
        .format(this.props.restaurant.settings.time_format);

    const hours = moment(
      pickupTimeStr,
      this.props.restaurant.settings.time_format
    ).hour();
    const minutes = moment(
      pickupTimeStr,
      this.props.restaurant.settings.time_format
    ).minute();
    const seconds = moment(
      pickupTimeStr,
      this.props.restaurant.settings.time_format
    ).second();

    const pickupTime = pickupDate
      .add(hours, "hour")
      .add(minutes, "minute")
      .add(seconds, "second");

    const phone = this.state.phone || this.props.order.phone;

    this.props.updateOrder(
      this.props.tenant._id,
      this.props.order._id,
      { pickupTime: pickupTime, phone: phone },
      (data) => {
        // socket.emit(
        //   "update order",
        //   this.props.restaurant._id,
        //   data.order,
        //   this.props.fetchPeriod
        // );
        $(".close").click();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order, restaurant, holidayHours } = this.props;

    let timeRange = getOpeningHourRange(
      restaurant,
      moment(this.props.pickupDate)
        .tz(restaurant.settings.time_zone)
        .startOf("day"),
      restaurant.regular_business_hours,
      holidayHours,
      restaurant.settings.online_order_start,
      restaurant.settings.online_order_end
    );

    let timeslots = calculateTimeslots(
      timeRange.startAt,
      timeRange.endAt,
      restaurant.settings.online_order_pickup_time_interval_in_minutes,
      restaurant
    );
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-primary mr-2"
          data-toggle="modal"
          data-target={`#updateOrderModal_${order._id}`}
          data-backdrop="static"
        >
          <Icon.Edit size={16} className="d-inline mr-1" />
          <span className="d-none d-md-inline">Update Order</span>
        </button>
        <div
          className="modal fade"
          id={`updateOrderModal_${order._id}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          aria-labelledby={`#updateOrderModalLabel_${order._id}`}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form>
                <div className="modal-header bg-primary">
                  <h5
                    className="modal-title"
                    id={`updateOrderModalLabel_${order._id}`}
                  >
                    Update Order
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>{`${order.deliveryMethod} Date & Time`}</label>
                    <div className="form-row">
                      <div className="col">
                        <DateTimePicker
                          name={`pickupDate`}
                          onChange={this.handlePickupDateChange}
                          format={this.props.restaurant.settings.date_format}
                          value={
                            this.state.pickupDate ||
                            new Date(this.props.order.pickupTime)
                          }
                          time={false}
                          date={true}
                        />
                      </div>
                      <div className="col">
                        <select
                          name={`pickupTime`}
                          className="form-control"
                          value={
                            this.state.pickupTime ||
                            moment(this.props.order.pickupTime)
                              .tz(this.props.restaurant.settings.time_zone)
                              .format(
                                this.props.restaurant.settings.time_format
                              )
                          }
                          onChange={this.handlePickupTimeChange}
                        >
                          {timeslots.map((timeslot, index) => (
                            <option
                              key={index}
                              value={`${moment(timeslot).format(
                                restaurant.settings.time_format
                              )}`}
                            >{`${moment(timeslot).format(
                              restaurant.settings.time_format
                            )}`}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      name={`phone`}
                      className="form-control"
                      type="tel"
                      value={this.state.phone || this.props.order.phone}
                      onChange={this.handlePhoneChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSave}
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const holidayHours = state.tenant.businessHours
    ? state.tenant.businessHours.filter(
        (businessHour) =>
          businessHour.restaurant.valueOf() === state.tenant.restaurant._id
      )
    : [];
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message,
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    holidayHours: holidayHours,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateOrder: (tenantId, orderId, { pickupTime, phone }, callback) =>
    dispatch(
      updateOrder(tenantId, orderId, { pickupTime, phone }, callback)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrder);
