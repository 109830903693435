import React, { Component } from "react";
import Numeral from "numeral";
import moment from "moment-timezone";

import Loading from "../Common/Loading";
class CouponListView extends Component {
  constructor(props) {
    super(props);
    this.sortCoupon = this.sortCoupon.bind(this);

    this.state = {
      sortField: "name",
      ascending: true,
      coupons: []
    };

  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state) {

    let sortedCoupons = [];

    // if (state.sortField === "name") {
    //   if (state.ascending) {
    //     sortedCoupons = [].concat(props.coupons)
    //       .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

    //   } else {
    //     sortedCoupons = [].concat(props.coupons)
    //       .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1);

    //   }
    // }

    if (state.sortField === "email") {
      if (state.ascending) {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1);

      } else {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.email.toLowerCase() < b.email.toLowerCase() ? 1 : -1);

      }
    }
    if (state.sortField === "phone") {
      if (state.ascending) {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.phone.toLowerCase() > b.phone.toLowerCase() ? 1 : -1);

      } else {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.phone.toLowerCase() < b.phone.toLowerCase() ? 1 : -1);

      }
    }

    if (state.sortField === "couponCode") {
      if (state.ascending) {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.couponCode > b.couponCode ? 1 : -1);

      } else {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.couponCode < b.couponCode ? 1 : -1);

      }
    }

    if (state.sortField === "validUntil") {
      if (state.ascending) {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => moment(a.validUntil).isAfter(b.validUntil) ? 1 : -1);

      } else {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => moment(a.validUntil).isBefore(b.validUntil) ? 1 : -1);

      }
    }

    if (state.sortField === "amount") {
      if (state.ascending) {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.amount > b.amount ? 1 : -1);

      } else {
        sortedCoupons = [].concat(props.coupons)
          .sort((a, b) => a.amount < b.amount ? 1 : -1);

      }
    }

    const newState = {
      coupons: [...sortedCoupons]
    };
    return newState;
  }

  sortCoupon(field) {
    if (this.state.sortField === field) {
      this.setState(prevState => {
        return { ascending: !prevState.ascending }
      });
    } else {
      this.setState(prevState => {
        return { sortField: field, ascending: !prevState.ascending }
      });
    }
  }

  render() {
    const coupons = (this.state.coupons && this.state.coupons.length > 0 ? this.state.coupons : this.props.coupons) || [];

    return (
      <div className="card">
        <div className="card-header">
          <div className="flex-row h-100 d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <strong className="text-secondary mr-2">
                  {coupons.length}
                </strong>
                {"Coupons"}
              </h5>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row pb-3 border-bottom">
            {/* <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Name</strong><i className={`ml-1 fas ${this.state.sortField === "name" ? (this.state.ascending ? "fa-sort-alpha-up" : "fa-sort-alpha-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("name")} />
            </div> */}
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Code</strong><i className={`ml-1 fas ${this.state.sortField === "couponCode" ? (this.state.ascending ? "fa-sort-alpha-up" : "fa-sort-alpha-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("couponCode")} />
            </div>
            <div className="col-lg-1 d-none d-lg-inline text-right">
              <strong>Amount</strong><i className={`ml-1 fas ${this.state.sortField === "amount" ? (this.state.ascending ? "fa-sort-numeric-up" : "fa-sort-numeric-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("amount")} />
            </div>
            {this.props.showExpireColumn &&
              <div className="col-lg-1 d-none d-lg-inline">
                <strong>Expires</strong><i className={`ml-1 fas ${this.state.sortField === "validUntil" ? (this.state.ascending ? "fa-sort-numeric-up" : "fa-sort-numeric-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("validUntil")} />
              </div>
            }

            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Email</strong><i className={`ml-1 fas ${this.state.sortField === "email" ? (this.state.ascending ? "fa-sort-alpha-up" : "fa-sort-alpha-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("email")} />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Phone</strong><i className={`ml-1 fas ${this.state.sortField === "phone" ? (this.state.ascending ? "fa-sort-alpha-up" : "fa-sort-alpha-down") : "fa-sort"} clickable`} onClick={() => this.sortCoupon("phone")} />
            </div>
            <div className="col-lg-2 d-none d-lg-inline text-left">
              <strong>Generated By</strong>
            </div>
            {this.props.showRedeemedColumn &&
              <div className="col-lg-2 d-none d-lg-inline text-left">
                <strong>Redeemed By</strong>
              </div>
            }

          </div>
          {this.props.loading === true && <Loading />}
          {this.props.loading === false &&
            <>
              {
                coupons.map((coupon, index) => (
                  <React.Fragment key={index}>
                    <div
                      className="row pt-3 pb-3 border-bottom"
                    >
                      {/* <div className="col-12 col-lg-2 text-left ">
                        <span className="d-inline d-lg-none">
                          <strong>Name: </strong>
                        </span>
                        <span>{coupon.name}</span>
                      </div> */}
                      <div className="col-12 col-lg-2 text-left ">
                        <span className="d-inline d-lg-none">
                          <strong>Code: </strong>
                        </span>
                        <span className="text-uppercase">{coupon.couponCode}</span>
                      </div>
                      <div className="col-12 col-lg-1 text-left text-lg-right">
                        <span className="d-inline d-lg-none">
                          <strong>Amount: </strong>{" "}
                        </span>{" "}
                        {Numeral(coupon.amount).format(
                          "$0,0.00"
                        )}
                      </div>
                      {this.props.showExpireColumn &&
                        <div className="col-12 col-lg-1 text-left">
                          <span className="d-inline d-lg-none">
                            <strong>Expires: </strong>
                          </span>
                          {coupon.validUntil && moment(coupon.validUntil).tz(this.props.tenant.settings.time_zone).format(this.props.tenant.settings.date_format)}
                        </div>
                      }

                      <div className="col-12 col-lg-2 text-left">
                        <span className="d-inline d-lg-none">
                          <strong>Email: </strong>
                        </span>
                        {coupon.email}
                      </div>
                      <div className="col-12 col-lg-2 text-left">
                        <span className="d-inline d-lg-none">
                          <strong>Phone: </strong>
                        </span>
                        {coupon.phone}
                      </div>
                      <div className="col-12 col-lg-2 text-left">
                        <span className="d-inline d-lg-none">
                          <strong>Generated By: </strong>
                        </span>
                        <span>{coupon.generatedBy}</span>
                      </div>
                      {this.props.showRedeemedColumn &&
                        <div className="col-12 col-lg-2 text-left">
                          <span className="d-inline d-lg-none">
                            <strong>Redeemeded By: </strong>
                          </span>
                          <span>{coupon.redeemedBy}</span>
                        </div>
                      }
                    </div>
                  </React.Fragment>
                ))
              }
            </>
          }

        </div>
      </div>
    );
  }
}

export default CouponListView;
