import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../utils/renderFields";
import { fetchMenu, updateMenuInfo, removeMenuPhoto } from "../../actions/menu";
import MenuPhotoPicker from "./MenuPhotoPicker";
import * as Icon from "react-feather";

const form = reduxForm({
  form: "menuInfo",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter menu name";
  }

  if (!formProps.available_for) {
    errors.name = "Please select available_for";
  }

  return errors;
}

class EditMenu extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.menu) {
      this.props.fetchMenu(this.props.menu._id);
    }
  }

  handleFormSubmit(formProps) {
    this.props.updateMenuInfo(formProps, this.props.menuPhotoUrl, () => {
      this.props.handleViewMenus();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      tenant,
      restaurant,
      menu,
      handleViewMenus,
      handleViewMenu,
      handleSubmit,
      pristine,
      submitting,
      menuPhotoUrl,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page" disabled>
              <button type="button" className="btn btn-light">
                Edit
              </button>
            </li>
          </ol>
        </nav>

        {this.renderAlert()}
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="form-group">
              <label className="col-form-label">Image</label>
              <div>
                {menuPhotoUrl && menuPhotoUrl !== null && (
                  <div className="card">
                    <img
                      className="img-fluid mb-2"
                      src={
                        process.env.REACT_APP_S3_BASE_URL +
                        `${tenant.slug}/${menuPhotoUrl.replace(".jpg", "")}.jpg`
                      }
                      alt="Menu"
                    />

                    <div className="card-body text-center">
                      <p
                        className="text-truncate"
                        data-toggle="tooltip"
                        title={menuPhotoUrl}
                      >
                        {menuPhotoUrl}
                      </p>
                      <button
                        type="button"
                        className="btn btn-light"
                        title="Remove"
                        onClick={() => this.props.removeMenuPhoto()}
                      >
                        <Icon.Trash2 size={16} />
                      </button>
                    </div>
                  </div>
                )}
                {!menuPhotoUrl && (
                  <MenuPhotoPicker tenant={tenant} restaurant={restaurant} />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="form-group">
                <label className="col-form-label">Name</label>
                <Field
                  name="name"
                  className="form-control"
                  component={renderField}
                  type="text"
                  placeholder="2 -50 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Description</label>
                <Field
                  name="description"
                  className="form-control"
                  component="textarea"
                  placeholder="Maximum 255 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Note</label>
                <Field
                  name="note"
                  className="form-control"
                  component="textarea"
                  placeholder="Maximum 255 characters (no HTML allowed)"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">
                  {`Online Ordering`}
                </label>
                <Field
                  name="online_ordering"
                  className="form-control"
                  component="select"
                >
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Available For</label>
                <Field
                  name="available_for"
                  className="form-control"
                  component="select"
                  multiple={true}
                >
                  <option value="dinein">Dine In</option>
                  <option value="takeout">Take Out</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Available During</label>
                <Field
                  name="duration.name"
                  className="form-control"
                  component="select"
                >
                  <option value="breakfast">Breakfast</option>
                  <option value="brunch">Brunch</option>
                  <option value="lunch">Lunch</option>
                  <option value="breakfast-lunch">Breakfast-Lunch</option>
                  <option value="dinner">Dinner</option>
                  <option value="lunch-dinner">Lunch-Dinner</option>
                  <option value="late-night">Late-Night</option>
                  <option value="all">All</option>
                </Field>
              </div>
              <div className="form-group">
                <label className="col-form-label">Time Start (Format: {tenant.settings.time_format})</label>
                <Field
                  name="duration.time_start"
                  className="form-control"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Time End (Format: {tenant.settings.time_format})</label>
                <Field
                  name="duration.time_end"
                  className="form-control"
                  component={renderField}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Display Order</label>
                <Field
                  name="display_order"
                  className="form-control"
                  component={renderField}
                  type="number"
                />
              </div>
              <div className="form-group">
                          <label className="col-form-label">Visibility</label>
                          <div>
                          <div className="form-check form-check-inline">
                            <Field
                              name="hidden_from_public"
                              className="form-check-input"
                              component="input"
                              type="checkbox"
                            />
                            <label className="form-check-label">
                              {` `}
                              {`Hidden From Public`}
                            </label>
                          </div>
                          </div>
                        </div>
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={() => handleViewMenu(menu)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const menu = state.menu.menu ? state.menu.menu : ownProps.menu;
  const menuPhotoUrl =
    menu.image && menu.image.url ? menu.image.url : state.menu.menuPhotoUrl;
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: menu,
    menuPhotoUrl: menuPhotoUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMenu: (menuId) => dispatch(fetchMenu(menuId)),
  updateMenuInfo: (formProps, menuPhotoUrl, callback) =>
    dispatch(updateMenuInfo(formProps, menuPhotoUrl, callback)),
  removeMenuPhoto: () => dispatch(removeMenuPhoto()),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(EditMenu));
