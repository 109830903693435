import React from "react";
import Numeral from "numeral";
import "./CartItem.css";
import * as Icon from "react-feather";

import CartItemSpecailInstructionsModal from "./CartItemSpecialInstructionsModal";

const CartItem = ({
  item,
  handleRemoveItem,
  changeable,
  enableSpecialInstruction,
}) => {

  return (
    <>
      <div className="row border-bottom pb-3 pt-3">
        <div className="col-12">
          <div className="row">
            <div className="col-7 text-left col-cart-angkor">
              <strong>{`${item.quantity}X ${item.cd}. ${item.name}`}</strong>
              <ul className="list-unstyled ml-3">
                <li className="text-muted font-italic">
                  <small>
                    Base Price: {Numeral(item.basePrice).format("$0,0.00")}
                  </small>
                </li>
                {item.size && item.size.length > 0 && (
                  <li className="text-muted font-italic">
                    <small className="highlight-text">Size: {item.size}</small>
                  </li>
                )}
                {item.options.map((option, index) => (
                  <li className="text-muted font-italic" key={index}>
                    <small className="highlight-text">
                      {`${option.option_name}:`}
                      {option.option_value && (
                        <span>{` ${option.option_value}`}</span>
                      )}
                      {option.option_total && (
                        <span>{` ${Numeral(option.option_total).format(
                          "$0,0.00"
                        )}`}</span>
                      )}
                    </small>
                  </li>
                ))}
                {item.specialInstructions &&
                  item.specialInstructions.length > 0 && (
                    <small className="highlight-text">
                      {item.specialInstructions.map((instruction, index) => (
                        <li key={index}>
                          <span>{`${instruction}`}</span>
                        </li>
                      ))}
                    </small>
                  )}
              </ul>
            </div>
            <div className="col-3 text-right col-cart-angkor">
              <strong>
                {Numeral(item.baseTotal + item.extraTotal).format("$0,0.00")}
              </strong>
            </div>
            {changeable && (
              <div className="col-2 text-right col-cart-angkor">
                <button
                  className="btn btn-danger btn-sm btn-sm-angkor"
                  onClick={() => handleRemoveItem(item)}
                >
                  <Icon.X size={16} />
                </button>
                {enableSpecialInstruction && (
                  <>
                    <button
                      className="btn btn-warning btn-sm btn-sm-angkor ml-2"
                      data-toggle="modal"
                      data-target={`#cartItemSpecialInstructionsModal${item.id}`}
                      data-backdrop="static"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Icon.Edit size={16} />
                    </button>
                    <CartItemSpecailInstructionsModal cartItem={item} />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;
