import React from 'react';

import spicy_mild_img from '../../assets/spicy_mild_16.png';
import spicy_medium_img from '../../assets/spicy_medium_16.png';
import gluten_free_img from '../../assets/gluten_free_16.png';
import new_menu_img from '../../assets/new_menu_16.png';
import peanut_img from '../../assets/peanut_16.png';
import vegetarian_img from '../../assets/vegetarian_16.png';

const MenuItemTags = ({ tags }) => {
  if (tags) {
    return (
      <div>
        {" "}
        {
          tags.indexOf('spicy_mild') >= 0 &&
          <img src={spicy_mild_img} alt="" />
        }
        {" "}
        {
          tags.indexOf('spicy_medium') >= 0 &&
          <img src={spicy_medium_img} alt="" />
        }
        {" "}
        {
          tags.indexOf('spicy_hot') >= 0 &&
          <>
          <img src={spicy_medium_img} alt="" />
          <img src={spicy_medium_img} alt="" />
          </>
        }        
        {" "}
        {
          tags.indexOf("gluten_friendly") >= 0 &&
          <img src={gluten_free_img} alt="" />
        }
        {" "}
        {
          tags.indexOf("new") >= 0 &&
          <img src={new_menu_img} alt="" />
        }
        {" "}
        {
          tags.indexOf("peanut") >= 0 &&
          <img src={peanut_img} alt="" />
        }
        {" "}
        {
          tags.indexOf("vegetarian") >= 0 &&
          <img src={vegetarian_img} alt="" />
        }
      </div>
    );
  }
  return (
    <div></div>
  )
};

export default MenuItemTags;
