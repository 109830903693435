import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { deleteGallery } from "../../actions/tenant";

import OnlineOrderingSettings from "./OnlineOrderingSettings";
import MembershipSettings from "./MembershipSettings";
import OnlineReservationSettings from "./OnlineReservationSettings";
import LoyaltyRewardProgramSettings from "./LoyaltyRewardProgramSettings";
import GiftCardSettings from "./GiftCardSettings";
import PromotionSettings from "./PromotionSettings";
import LogoSettings from "./LogoSettings";
import FaviconSettings from "./FaviconSettings";
import BannerSettings from "./BannerSettings";
import GeneralInfoSettings from "./GeneralInfoSettings";
import TaxRateSettings from "./TaxRateSettings";

import PosterSettings from "./PosterSettings";
import DeliverySettings from "./DeliverySettings";
import RushHourSettings from "./RushHourSettings";
import AnalyticsSettings from "./AnalyticsSettings";
import TimeZoneSettings from "./TimeZoneSettings";
import DescriptionSettings from "./DescriptionSettings";
import OnlinePaymentSettings from "./OnlinePaymentSettings";
import CallInOrderSettings from "./CallInOrderSettings";
import SpecialInstructionSettings from "./SpecialInstructionSettings";
import KitchenOrderSettings from "./KitchenOrderSettings";

import * as MdIcons from "react-icons/md";

export class Settings extends Component {
  constructor(props) {
    super(props);

    this.handleSelectTenant = this.handleSelectTenant.bind(this);
    this.handleSelectedSettings = this.handleSelectedSettings.bind(this);
    this.handleViewSettings = this.handleViewSettings.bind(this);

    this.state = {
      selectedTenant: undefined,
      selectedSettings: undefined,
      mode: undefined
    };
  }

  componentDidMount() { }

  handleSelectTenant(tenant) {
    this.setState({ selectedTenant: tenant });
  }

  handleSelectedSettings(selectedTenant, selectedSettings) {
    this.setState({
      selectedTenant: selectedTenant,
      selectedSettings: selectedSettings
    });
  }

  handleViewSettings() {
    this.setState({ selectedTenant: undefined, selectedSettings: undefined });
  }

  render() {
    const { tenant } = this.props;
    if (tenant === undefined) {
      return <></>;
    }

    if (this.state.selectedSettings === "Membership") {
      return (
        <MembershipSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "OnlineOrdering") {
      return (
        <OnlineOrderingSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "RushHour") {
      return (
        <RushHourSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Delivery") {
      return (
        <DeliverySettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Description") {
      return (
        <DescriptionSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "OnlineReservation") {
      return (
        <OnlineReservationSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "LoyaltyReward") {
      return (
        <LoyaltyRewardProgramSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Promotion") {
      return (
        <PromotionSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "GiftCard") {
      return (
        <GiftCardSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "TaxRate") {
      return (
        <TaxRateSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Analytics") {
      return (
        <AnalyticsSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Info") {
      return (
        <GeneralInfoSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Logo") {
      return (
        <LogoSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Favicon") {
      return (
        <FaviconSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Banner") {
      return (
        <BannerSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "Poster") {
      return (
        <PosterSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }

    if (this.state.selectedSettings === "Payment") {
      return (
        <OnlinePaymentSettings
          tenant={this.state.selectedTenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "TimeZone") {
      return (
        <TimeZoneSettings
          tenant={this.props.tenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "CallInOrder") {
      return (
        <CallInOrderSettings
          tenant={this.props.tenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "SpecialInstruction") {
      return (
        <SpecialInstructionSettings
          tenant={this.props.tenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (this.state.selectedSettings === "KitchenOrders") {
      return (
        <KitchenOrderSettings
          tenant={this.props.tenant}
          handleViewSettings={this.handleViewSettings}
        />
      );
    }
    if (tenant) {
      return (
        <React.Fragment>
          <nav aria-label="breadcrumb" className="pt-3">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <button type="button" className="btn btn-light" disabled>
                  <i className="fas fa-cog" />
                  {` `}
                  <span>{`Settings`}</span>
                </button>
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Info")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-info fa-3x" />
                  <h5 className="card-title">{`General Info`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Logo")}
              >
                <div className="card-body text-center">
                  <i className="far fa-image fa-3x" />
                  <h5 className="card-title">{`Logo`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Favicon")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-star fa-3x" />
                  <h5 className="card-title">{`Favicon`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Banner")}
              >
                <div className="card-body text-center">
                  <i className="far fa-image fa-3x" />
                  <h5 className="card-title">{`Banner`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Poster")}
              >
                <div className="card-body text-center">
                  <i className="far fa-image fa-3x" />
                  <h5 className="card-title">{`Poster`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <Link to="/settings/carousel">
                <div
                  className="card clickable-card  mt-3 pb-3"
                // onClick={() => this.handleSelectedSettings(tenant, "Carousel")}
                >
                  <div className="card-body text-center">
                    <i className="far fa-images fa-3x" />
                    <h5 className="card-title">{`Carousel`}</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <Link to="/settings/gallery">
                <div
                  className="card clickable-card  mt-3 pb-3"
                // onClick={() => this.handleSelectedSettings(tenant, "Gallery")}
                >
                  <div className="card-body text-center">
                    <i className="far fa-images fa-3x" />
                    <h5 className="card-title">{`Gallery`}</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "Membership")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-users fa-3x" />
                  <h5 className="card-title">{`Membership`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "OnlineOrdering")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-utensils fa-3x" />
                  <h5 className="card-title">{`Online Ordering`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "CallInOrder")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-phone fa-3x" />
                  <h5 className="card-title">{`Call-in Order`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "SpecialInstruction")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-sticky-note fa-3x" />
                  <h5 className="card-title">{`Special Instruction`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "RushHour")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-clock fa-3x" />
                  <h5 className="card-title">{`Rush Hour`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "Delivery")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-truck fa-3x" />
                  <h5 className="card-title">{`Delivery`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "Description")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-clipboard-list fa-3x" />
                  <h5 className="card-title">{`Description`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "OnlineReservation")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-th-large fa-3x" />
                  <h5 className="card-title">{`Online Reservation`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() =>
                  this.handleSelectedSettings(tenant, "LoyaltyReward")
                }
              >
                <div className="card-body text-center">
                  <i className="fa fa-award fa-3x" />
                  <h5 className="card-title">{`Loyalty & Reward`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Promotion")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-tags fa-3x" />
                  <h5 className="card-title">{`Promotion`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "GiftCard")}
              >
                <div className="card-body text-center">
                  <i className="fa fa-gift fa-3x" />
                  <h5 className="card-title">{`Gift Card`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Payment")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-credit-card fa-3x" />
                  <h5 className="card-title">{`Online Payments`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "TaxRate")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-dollar-sign fa-3x" />
                  <h5 className="card-title">{`Taxation and Currency`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "Analytics")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-chart-line fa-3x" />
                  <h5 className="card-title">{`Analytics`}</h5>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "TimeZone")}
              >
                <div className="card-body text-center">
                  <i className="fas fa-globe fa-3x" />
                  <h5 className="card-title">{`Time Zone`}</h5>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-3">
              <div
                className="card clickable-card  mt-3 pb-3"
                onClick={() => this.handleSelectedSettings(tenant, "KitchenOrders")}
              >
                <div className="card-body text-center">
                  {/* <i className="fas fa-refrigerator fa-3x"></i> */}
                  <MdIcons.MdKitchen size={42} />
                  <h5 className="card-title">{`Kitchen Orders`}</h5>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant
  };
};

const mapDispatchToProps = dispatch => ({
  deleteGallery: (tenantId, galleryItemId, callback) =>
    dispatch(deleteGallery(tenantId, galleryItemId, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
