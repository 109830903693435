import React, { Component } from "react";

class BuyOneGetOneFreePromoHighlight extends Component {
  render() {
    const {
      sectionNumber,
      handleChoiceChange,
      choice,
      trigger1,
      handleTrigger1Change,
      trigger2,
      handleTrigger2Change,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>Choose an event to trigger promo deal showcasing</strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">
              Highlight more about this promo deal when your clients
                  encounter this context:
                </strong>
            <div className="col-12 col-lg-6">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="highlight_option"
                  value="No highlight"
                  onChange={handleChoiceChange}
                  checked={choice === "No highlight"}
                />
                <label className="form-check-label">{`No highlight`}</label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="highlight_option"
                  value="Custom selection"
                  onChange={handleChoiceChange}
                  checked={choice === "Custom selection"}
                />
                <label className="form-check-label">
                  {`Custom selection (Default)`}
                </label>
                <div className="form-check mt-3 mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="highlight_custom_selection_match_any_item_from_item_group_1"
                    defaultChecked={trigger1}
                    onChange={handleTrigger1Change}
                  />
                  <label className="form-check-label">
                    {`Client adds an item to the cart that matches any item from "Item group 1".`}
                  </label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="highlight_custom_selection_match_any_item_from_item_group_2"
                    defaultChecked={trigger2}
                    onChange={handleTrigger2Change}
                  />
                  <label className="form-check-label">
                    {`Client adds an item to the cart that matches any item from "Item group 2".`}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <p>{`Some clients may not notice the promo deal section when they start ordering or they see it and decide to look into this later. `}</p>
              <p>{`Therefore sales may be lost due to people forgetting or not noticing how to chase available promo deals. `}</p>
              <p>{`Use this setting to "step-forward" and upgrade your clients ordering experience with a nice, contextual up-selling prompt. `}</p>
              <p>{`Example: Do you grant a free Pizza for every ordered Pizza? Then "Step-forward" with a message about this to your food client when he already added to the cart a first Pizza item`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyOneGetOneFreePromoHighlight;