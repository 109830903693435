import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";

import { startOrder, completeOrder } from "../../actions/order";

import { socket } from "../../service/socket";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const isSame = (item1, item2) => {
  const result =
    Object.is(item1.menuItem, item2.menuItem) &&
    Object.is(item1.size, item2.size) &&
    isEqual(item1.options, item2.options) &&
    isEqual(item1.specialInstructions, item2.specialInstructions) &&
    Object.is(item1.checked, item2.checked);
  return result;
};

const isEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length;
  var otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

const aggregateItems = (items = []) => {
  let aggregatedItems = [];
  for (const item1 of items) {
    let found = false;
    for (const aggregatedItem of aggregatedItems) {
      if (isSame(aggregatedItem.item, item1)) {
        aggregatedItem.item.quantity += item1.quantity;
        aggregatedItem.ids.push(item1._id);
        found = true;
        break;
      }
    }
    if (!found) {
      aggregatedItems.push({
        item: Object.assign({}, item1),
        ids: [item1._id],
      });
    }
  }
  return aggregatedItems.sort((a, b) => (a.item.cd >= b.item.cd ? 1 : -1));
};

const renderAggregatedItem = (aggregatedItem, handleMarkAsChecked) => {
  return (
    <div key={aggregatedItem.item._id} className="row border-bottom mb-3">
            <div className="col-12">
              <div className="row mb-3">
                <div
                  className="col-12 col-xl-9"
                  style={{
                    textDecoration: aggregatedItem.item.checked
                      ? "line-through"
                      : "",
                  }}
                >
                  <strong>
                    <span>{`${aggregatedItem.item.quantity}X `}</span>
                  </strong>
                  {aggregatedItem.item.cd && (
                    <span>{`${aggregatedItem.item.cd}. `}</span>
                  )}
                  <span>{aggregatedItem.item.name}</span>
                  <ul className="list-unstyled ml-3">
                    {aggregatedItem.item.size && (
                      <li className="text-muted font-italic">
                        <small>Size: {aggregatedItem.item.size}</small>
                      </li>
                    )}
                    {aggregatedItem.item.options.map((option, index) => (
                      <li className="text-muted font-italic" key={index}>
                        <small>
                          {option.name}{" "}
                          {option.value && <span>: {option.value}</span>}
                        </small>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-12 col-xl-3 text-center text-xl-right">
                  {!aggregatedItem.item.checked && (
                    <button
                      className="btn btn-primary"
                      onClick={() => handleMarkAsChecked(aggregatedItem.ids)}
                    >
                      Check
                    </button>
                  )}
                </div>
              </div>
              {aggregatedItem.item.specialInstructions &&
                aggregatedItem.item.specialInstructions.lenght > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <i className="fas fa-comment mr-3" />
                      {aggregatedItem.item.specialInstructions.map(
                        (instruction, index) => (
                          <small className="highlight-text mr-3" key={index}>
                            {instruction}
                          </small>
                        )
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
  )
}
const CookingQueueCard = ({
  bucketStart,
  bucketItems = [],
  restaurant,
  borderColor,
  interval,
  handleMarkAsChecked,
}) => {

  const aggregatedCheckedItems = aggregateItems(bucketItems.filter(item => item.checked));
  const aggregatedUncheckedItems = aggregateItems(bucketItems.filter(item => !item.checked));
  return (
    <div className={`card ${borderColor}`}>
      <div className="card-header text-center">
        <h5>{`${moment(bucketStart).format(
          restaurant.settings.time_format
        )} ~ ${moment(bucketStart)
          .add((interval-1), "minutes")
          .format(restaurant.settings.time_format)}`}</h5>
      </div>
      <div className="card-body">
        {aggregatedUncheckedItems.map((aggregatedItem) => (
          renderAggregatedItem(aggregatedItem, handleMarkAsChecked)
        ))}
        {aggregatedCheckedItems.map((aggregatedItem) => (
          renderAggregatedItem(aggregatedItem, handleMarkAsChecked)
        ))}
      </div>
    </div>
  );
};

class CookingQueue extends Component {
  constructor(props) {
    super(props);

    this.handleSelectRestaurant = this.handleSelectRestaurant.bind(this);
    this.handleMarkAsChecked = this.handleMarkAsChecked.bind(this);

    this.state = {
      from: undefined,
      to: undefined,
      selectedRestaurantId: undefined,
      now: moment(),
      interval: 20,
      items: [],
    };

    this.intervalId = undefined;
  }

  getKitchenOrderItems = (items) => {
    this.setState((prevState) => {
      return { now: moment(), items: items };
    });
  };

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState({
        now: moment()
      })
    }, 1000 * 60);

    if (this.props.tenant && this.props.applicableRestaurants.length > 0) {
      const periodFrom = moment()
        .tz(this.props.tenant.settings.time_zone)
        .startOf("day")
        .toDate();
      const periodTo = moment()
        .tz(this.props.tenant.settings.time_zone)
        .endOf("day")
        .toDate();

      var state_current = this;
      this.setState(
        {
          loading: true,
          from: periodFrom,
          to: periodTo,
          selectedRestaurantId: this.props.applicableRestaurants[0].key,
        },
        () => {
          socket.emit("join", this.state.selectedRestaurantId);
          socket.emit(
            "initial_kitchen_order_items",
            this.state.selectedRestaurantId,
            {
              from: periodFrom,
              to: periodTo,
            }
          );
          socket.on(
            "get_kitchen_order_items",
            state_current.getKitchenOrderItems
          );
        }
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    socket.off("get_kitchen_order_items");
  }

  handleSelectRestaurant(restaurantId) {
    this.setState(
      {
        selectedRestaurantId: restaurantId,
      },
      () => {
        socket.emit("join", this.state.selectedRestaurantId);
        socket.emit(
          "initial_kitchen_order_items",
          this.state.selectedRestaurantId,
          {
            from: this.state.from,
            to: this.state.to,
          }
        );
        socket.on("get_kitchen_order_items", this.getKitchenOrderItems);
      }
    );
  }

  handleMarkAsChecked(ids) {
    socket.emit(
      "check kitchen order item",
      this.state.selectedRestaurantId,
      ids,
      {
        from: this.state.from,
        to: this.state.to,
      }
    );
  }

  render() {
    const { tenant } = this.props;
    const items = this.state.items || [];

    if (tenant === undefined || !this.state.selectedRestaurantId) {
      return <></>;
    }

    const selectedRestaurant = tenant.restaurants.find(
      (ar) => ar._id === this.state.selectedRestaurantId
    );

    const cookingQueueIntervals = selectedRestaurant.settings.kitchen_order_settings.cooking_queue_intervals || [];

    const now = moment(this.state.now).tz(
      selectedRestaurant.settings.time_zone
    );
    const minute = now.minute();

    const currentBucketStart = moment(
      now.subtract(
        minute -
          (minute < this.state.interval
            ? 0
            : minute < this.state.interval * 2
            ? this.state.interval
            : this.state.interval * 2),
        "minutes"
      )
    );

    const nextBucketStart = moment(currentBucketStart).add(
      this.state.interval,
      "minutes"
    );

    const afterNextBucketStart = moment(nextBucketStart).add(
      this.state.interval,
      "minutes"
    );

    const currentBucketItems = items.filter((item) =>
      moment(item.orderReadyBy).isBetween(
        currentBucketStart,
        nextBucketStart,
        "[)"
      )
    );

    const nextBucketItems = items.filter((item) =>
      moment(item.orderReadyBy).isBetween(
        nextBucketStart,
        moment(nextBucketStart).add(this.state.interval, "minutes"),
        "[)"
      )
    );


    const afterNextBucketItems = items.filter((item) =>
      moment(item.orderReadyBy).isBetween(
        afterNextBucketStart,
        moment(afterNextBucketStart).add(this.state.interval, "minutes"),
        "[)"
      )
    );

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <div className="dropdown">
                <button
                  className="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownRestaurantButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <MdIcons.MdLocationPin />
                  {` `}
                  <span>
                    {
                      this.props.applicableRestaurants.find(
                        (ar) => ar.key === this.state.selectedRestaurantId
                      ).label
                    }
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownRestaurantButton"
                >
                  {this.props.applicableRestaurants.map((ar) => (
                    <button
                      key={ar.key}
                      className="dropdown-item clickable"
                      onClick={() => this.handleSelectRestaurant(ar.key)}
                    >
                      {ar.label}
                    </button>
                  ))}
                </div>
              </div>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <BsIcons.BsLayoutThreeColumns />
                {` `}
                <span>{`Cooking Queue`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row border-bottom">
          <div className="col-3 col-md-4 col-lg-5 mb-3 input-group justify-content-start">
            <div className="btn-toolbar">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Previous ${this.state.interval} minutes`}
                  disabled={moment(currentBucketStart).isSameOrBefore(
                    this.state.from
                  )}
                  onClick={() =>
                    this.setState((prevState) => ({
                      now: moment(prevState.now).subtract(
                        this.state.interval,
                        "minutes"
                      ),
                    }))
                  }
                >{`<`}</button>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-4 col-lg-2 mb-3 input-group justify-content-center">
            <select
              name="interval"
              className="form-control"
              value={this.state.interval}
              onChange={(event) =>
                this.setState({ interval: event.target.value })
              }
            >
              {cookingQueueIntervals.map(queueInterval => (
                <option value={queueInterval}>{`${queueInterval} minutes`}</option>
              ))}
            </select>
          </div>
          <div className="col-3 col-md-4 col-lg-5 mb-3 input-group justify-content-end">
            <div className="btn-toolbar">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={`Next ${this.state.interval} minutes`}
                  disabled={moment(afterNextBucketStart).isSameOrAfter(
                    this.state.to
                  )}
                  onClick={() =>
                    this.setState((prevState) => ({
                      now: moment(prevState.now).add(
                        this.state.interval,
                        "minutes"
                      ),
                    }))
                  }
                >{`>`}</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-4 mb-3">
            <CookingQueueCard
              bucketStart={currentBucketStart}
              bucketItems={currentBucketItems}
              restaurant={selectedRestaurant}
              borderColor={"border-danger"}
              interval={this.state.interval}
              handleMarkAsChecked={this.handleMarkAsChecked}
            />
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <CookingQueueCard
              bucketStart={nextBucketStart}
              bucketItems={nextBucketItems}
              restaurant={selectedRestaurant}
              borderColor={"border-warning"}
              interval={this.state.interval}
              handleMarkAsChecked={this.handleMarkAsChecked}
            />
          </div>
          <div className="col-12 col-lg-4 mb-3">
            <CookingQueueCard
              bucketStart={afterNextBucketStart}
              bucketItems={afterNextBucketItems}
              restaurant={selectedRestaurant}
              borderColor={"border-info"}
              interval={this.state.interval}
              handleMarkAsChecked={this.handleMarkAsChecked}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const user = cookies.get("user");
  const applicableRestaurants = (
    user.role === "Owner"
      ? state.tenant.restaurants || []
      : (state.tenant.restaurants || []).filter((_restaurant) =>
          user.restaurants.includes(_restaurant._id)
        )
  ).map((_restaurant) => {
    return { key: _restaurant._id, label: _restaurant.name };
  });
  return {
    tenant: state.tenant.tenant,
    order: state.order.order,
    applicableRestaurants: applicableRestaurants,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startOrder: (tenantId, orderId, callback) =>
    dispatch(startOrder(tenantId, orderId, callback)),
  completeOrder: (tenantId, orderId, callback) =>
    dispatch(completeOrder(tenantId, orderId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookingQueue);
