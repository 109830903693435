import React, { Component } from "react";

class AllowedOrderTime extends Component {
  render() {
    const {
      allowedOrderTime,
      handleAllowedOrderTimeChange,
    } = this.props;

    return (

      <div className="row promo-row pt-3 pb-3 mb-3">
        <strong className="col-12 mb-3">Allowed order time:</strong>
        <div className="col-12">
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_time"
              value="Any time"
              onChange={handleAllowedOrderTimeChange}
              checked={allowedOrderTime === "Any time"}
            />
            <label className="form-check-label">{`Any time (Default)`}</label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_time"
              value="Now (as soon as possible)"
              onChange={handleAllowedOrderTimeChange}
              checked={allowedOrderTime === "Now (as soon as possible)"}
            />
            <label className="form-check-label">
              {`Now (as soon as possible)`}
            </label>
          </div>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="allowed_order_time"
              value="Order for later"
              onChange={handleAllowedOrderTimeChange}
              checked={allowedOrderTime === "Order for later"}
            />
            <label className="form-check-label">
              {`Order for later`}
            </label>
          </div>
        </div>
      </div>

    );
  }
}

export default AllowedOrderTime;