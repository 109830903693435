import React, { Component } from "react";

import { EligibleItemsModal } from "./EligibleItemsModal";

class BuyOneGetOneFreePromoBenefit extends Component {
  render() {
    const {
      sectionNumber,
      menus,
      eligibleItemsGroup1,
      handleEligibleItemsGroup1Change,
      eligibleItemsGroup2,
      handleEligibleItemsGroup2Change,
      discountOnCheapestItem,
      handleDiscountOnCheapestItemChange,
      discountOnMostExpensiveItem,
      handleDiscountOnMostExpensiveItemChange,
      discountSettingOption,
      handleDiscountSettingOptionChange,
      extraChargeOption,
      handleExtraChargeOptionChange,
      mustBeSameItem,
      handleMustBeSameItemChange
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Set promo deal benefit (What's in it for your client)
              </strong>
        </div>
        <div className="card-body">
          <div className="pb-3">
            <strong>{`Set which items are eligible in this bundle`}</strong>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">
              Set which items are eligible:
                </strong>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">Items Group 1</label>
                <EligibleItemsModal
                  name="eligible_items_group_1"
                  type="text"
                  selectedItems={eligibleItemsGroup1}
                  menus={menus}
                  handleCheck={handleEligibleItemsGroup1Change}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label">Items Group 2</label>
                <EligibleItemsModal
                  name="eligible_items_group_2"
                  type="text"
                  selectedItems={eligibleItemsGroup2}
                  menus={menus}
                  handleCheck={handleEligibleItemsGroup2Change}
                />
              </div>
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="mustBeSameItem"
                  onChange={handleMustBeSameItemChange}
                  defaultChecked={mustBeSameItem}
                />
                <label className="form0-check-label" for="mustBeSameItem">Must be the same item</label>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              {`Example: Buy 1 Pizza and get the 2nd Pizaa free would require to select all Pizzas for "Item Group 1" and the same for "Items Group 2". By default it is set to discount the cheapest item by 100%.`}
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">Set the benefit:</strong>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">
                  Discount for cheapest item:
                    </label>
                <div className="input-group">
                  <input
                    name="discount_on_cheapest_item"
                    type="number"
                    className="form-control"
                    value={discountOnCheapestItem}
                    onChange={handleDiscountOnCheapestItemChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">
                  Discount for most expensive item:
                    </label>
                <div className="input-group">
                  <input
                    name="discount_on_most_expensive_item"
                    type="number"
                    className="form-control"
                    value={discountOnMostExpensiveItem}
                    onChange={handleDiscountOnMostExpensiveItemChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="discount_setting_option"
                  value="Manual"
                  onChange={handleDiscountSettingOptionChange}
                  checked={discountSettingOption === "Manual"}
                />
                <label className="form-check-label">
                  {`Manually set which item gets which discount`}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="discount_setting_option"
                  value="Automatic"
                  onChange={handleDiscountSettingOptionChange}
                  checked={
                    discountSettingOption === "Automatic"
                  }
                />
                <label className="form-check-label">
                  {`Automatically determine & sort items from cheapest to most expensive`}
                </label>
              </div>
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12 ">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="No extra charges"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption === "No extra charges"
                  }
                />
                <label className="form-check-label">
                  {`No extra charges `}
                  <br />
                  <small>{`Any "Choices / Addons" or "Sizes" selection will not increase the price.`}</small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="Charge extra for 'Choices/Addons'"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption ===
                    "Charge extra for 'Choices/Addons'"
                  }
                />
                <label className="form-check-label">
                  {`Charge extra for "Choices / Addons" `}
                  <br />
                  <small>{`Only "Choices / Addons" will preserve their prices (if any) unaffected of the promo logic.`}</small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="Charge extra for 'Choices/Addons' & 'Sizes'"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption ===
                    "Charge extra for 'Choices/Addons' & 'Sizes'"
                  }
                />
                <label className="form-check-label">
                  {`Charge extra for "Choices / Addons" `}
                  <br />
                  <small>{`Only "Choices / Addons" will preserve their prices (if any) unaffected of the promo logic.`}</small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyOneGetOneFreePromoBenefit;