import React, { Component } from "react";
import * as Icon from "react-feather";

class SearchBar extends Component {

  render() {
    const { placeholder, handleFilterTextChange, showSearchIcon=true } = this.props;
    return (
      // <form>
        <div className="input-group">
          <input
            name="filterText"
            type="text"
            className="form-control"
            placeholder={placeholder}
            onChange={handleFilterTextChange}
          />
          {showSearchIcon && (
            <div className="input-group-append">
              <div className="input-group-text">
                <Icon.Search />
              </div>
            </div>
          )}
        </div>
      // </form>
    );
  }
}
export default SearchBar;
