import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import $ from "jquery";
import * as Icon from "react-feather";

import { rejectOrder } from "../../actions/order";

import { renderField } from "../../utils/renderFields";

import {socket} from '../../service/socket';

const form = reduxForm({
  form: "rejectOrder",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.rejectReason) {
    errors.rejectReason = "Please enter reject reason";
  }

  return errors;
}

class RejectOrder extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

  }

  componentDidMount() {}

  handleFormSubmit(formProps) {

    this.props.rejectOrder(this.props.tenant._id, this.props.order._id, formProps.rejectReason, (data) => {
      socket.emit('reject order', this.props.restaurant._id, data.order, this.props.fetchPeriod);
      $(".close").click();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { order, handleSubmit } = this.props;

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-danger mr-2"
          data-toggle="modal"
          data-target="#rejectOrderModal"
          data-backdrop="static"
        >
          <Icon.Slash size={16} className="d-inline mr-1" />
          <span className="d-none d-md-inline">Reject</span>
        </button>
        <div
          className="modal fade"
          id="rejectOrderModal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          aria-labelledby="#rejectOrderModalLabel"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
                <div className="modal-header bg-danger">
                  <h5 className="modal-title" id="rejectOrderModalLabel">
                    Reject Order
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="form-group">
                    <label>Why are we rejecting this order?</label>
                    <Field
                      name="rejectReason"
                      className="form-control"
                      component={renderField}
                      placeholder="eg. customer didn't show up"
                      type="text"
                    />
                    <p className="text-muted">
                      You must call {order.nameOrderedUnder} at {order.phone} to
                      let them know their order is rejected.
                    </p>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-2"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-danger">
                    Reject
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.order.errorMessage,
    message: state.order.message, 
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
  };
};

const mapDispatchToProps = dispatch => ({
  rejectOrder: (tenantId, orderNumber, rejectReason, callback) => dispatch(rejectOrder(tenantId, orderNumber, rejectReason, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(RejectOrder));
