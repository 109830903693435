import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { renderPriceField, renderField } from "../../utils/renderFields";
import { addMenuItemSize } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemSize",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.price) {
    errors.price = "Please enter price";
  }

  return errors;
}

class MenuItemSizeAddModal extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.addMenuItemSize(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      formProps
    );
    $(".close").click();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
    } = this.props;
    return (
      <>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#menuItemSizeAddModal"
        data-backdrop="static"
      >
        <Icon.Plus size={16} />
        {` `}New Size
        </button>

      <div
        className="modal fade"
        id="menuItemSizeAddModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="#menuItemSizeAddModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="menuItemSizeAddModalLabel">
                  {"Add Size"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                {this.renderAlert()}
                <div className="form-group">
                  <label className="col-form-label">Name</label>
                  <Field
                    name="name"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Description</label>
                  <Field
                    name="description"
                    className="form-control"
                    component="textarea"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Price</label>
                  <Field
                    name="price"
                    className="form-control"
                    component={renderPriceField}
                    type="number"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                  </button>
                <button type="submit" className="btn btn-primary">
                  {"Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: {}
  };
};

const mapDispatchToProps = dispatch => ({
  addMenuItemSize: (menuId, menuSectionId, menuItemId, formProps) =>
    dispatch(addMenuItemSize(menuId, menuSectionId, menuItemId, formProps))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MenuItemSizeAddModal));
