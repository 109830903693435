import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Switch from "react-switch";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";
import * as Icon from "react-feather";

const form = reduxForm({
  form: "deliveryOptionsSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class DeliverySettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDeliveryAreaRadiusChange =
      this.handleDeliveryAreaRadiusChange.bind(this);
    this.handleDeliveryAreaPostalCodesChange =
      this.handleDeliveryAreaPostalCodesChange.bind(this);
    this.handleCallInOrderDeliveryAreaRadiusChange =
      this.handleCallInOrderDeliveryAreaRadiusChange.bind(this);
    this.handleCallInOrderDeliveryAreaPostalCodesChange =
      this.handleCallInOrderDeliveryAreaPostalCodesChange.bind(this);
    this.handleMinimumOrderAmountChange =
      this.handleMinimumOrderAmountChange.bind(this);
    this.handleDeliveryFeeChange = this.handleDeliveryFeeChange.bind(this);
    this.handleDeliveryInformationChange =
      this.handleDeliveryInformationChange.bind(this);

    this.handleAddDeliveryFee = this.handleAddDeliveryFee.bind(this);
    this.handleRemoveDeliveryFee = this.handleRemoveDeliveryFee.bind(this);

    this.handleUpdateMinRadius = this.handleUpdateMinRadius.bind(this);
    this.handleUpdateMaxRadius = this.handleUpdateMaxRadius.bind(this);
    this.handleUpdateFee = this.handleUpdateFee.bind(this);

    this.state = {
      support_delivery:
        props.restaurant.settings.delivery_options.support_delivery,
      support_pickup: props.restaurant.settings.delivery_options.support_pickup,
      delivery_area_radius:
        props.restaurant.settings.delivery_options.delivery_area_radius,
      delivery_area_postal_codes:
        props.restaurant.settings.delivery_options.delivery_area_postal_codes,
      callin_order_delivery_area_radius:
        props.restaurant.settings.delivery_options
          .callin_order_delivery_area_radius,
      callin_order_delivery_area_postal_codes:
        props.restaurant.settings.delivery_options
          .callin_order_delivery_area_postal_codes,
      minimum_order_amount:
        props.restaurant.settings.delivery_options.minimum_order_amount,
      delivery_fee: props.restaurant.settings.delivery_options.delivery_fee,
      delivery_fees: props.tenant.settings.delivery_options.delivery_fees || [],
      delivery_information:
        props.restaurant.settings.delivery_options.delivery_information,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        support_delivery: this.state.support_delivery,
        support_pickup: this.state.support_pickup,
        delivery_area_radius: this.state.delivery_area_radius,
        delivery_area_postal_codes: this.state.delivery_area_postal_codes,
        callin_order_delivery_area_radius:
          this.state.callin_order_delivery_area_radius,
        callin_order_delivery_area_postal_codes:
          this.state.callin_order_delivery_area_postal_codes,
        minimum_order_amount: this.state.minimum_order_amount,
        delivery_fee: this.state.delivery_fee,
        delivery_fees: this.state.delivery_fees,
        delivery_information: this.state.delivery_information,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleDeliveryAreaRadiusChange(event) {
    this.setState({ delivery_area_radius: event.target.value });
  }

  handleDeliveryAreaPostalCodesChange(event) {
    this.setState({ delivery_area_postal_codes: event.target.value });
  }

  handleCallInOrderDeliveryAreaRadiusChange(event) {
    this.setState({ callin_order_delivery_area_radius: event.target.value });
  }

  handleCallInOrderDeliveryAreaPostalCodesChange(event) {
    this.setState({
      callin_order_delivery_area_postal_codes: event.target.value,
    });
  }

  handleMinimumOrderAmountChange(event) {
    this.setState({ minimum_order_amount: event.target.value });
  }

  handleDeliveryFeeChange(event) {
    this.setState({ delivery_fee: event.target.value });
  }

  handleDeliveryInformationChange(event) {
    this.setState({ delivery_information: event.target.value });
  }

  handleAddDeliveryFee() {
    this.setState((prevState) => {
      return {
        ...prevState,
        delivery_fees: [
          ...prevState.delivery_fees,
          { min_radius: 0, max_radius: 0, fee: 0 },
        ],
      };
    });
  }

  handleRemoveDeliveryFee(index) {
    this.setState((prevState) => {
      return {
        ...prevState,
        delivery_fees: prevState.delivery_fees.filter(
          (fee, _index) => _index !== index
        ),
      };
    });
  }

  handleUpdateMinRadius(event) {
    const name = event.target.name;
    const _index = name.split("_")[2];
    const value = Number(event.target.value);
    this.setState((prevState) => {
      return {
        ...prevState,
        delivery_fees: prevState.delivery_fees.map((delivery_fee, index) => {
          if (index === parseInt(_index)) {
            return { ...delivery_fee, min_radius: value };
          } else {
            return delivery_fee;
          }
        }),
      };
    });
  }

  handleUpdateMaxRadius(event) {
    const name = event.target.name;
    const _index = name.split("_")[2];
    const value = Number(event.target.value);
    this.setState((prevState) => {
      return {
        ...prevState,
        delivery_fees: prevState.delivery_fees.map((delivery_fee, index) => {
          if (index === parseInt(_index)) {
            return { ...delivery_fee, max_radius: value };
          } else {
            return delivery_fee;
          }
        }),
      };
    });
  }

  handleUpdateFee(event) {
    const name = event.target.name;
    const _index = name.split("_")[2];
    const value = Number(event.target.value);
    this.setState((prevState) => {
      return {
        ...prevState,
        delivery_fees: prevState.delivery_fees.map((delivery_fee, index) => {
          if (index === parseInt(_index)) {
            return { ...delivery_fee, fee: value };
          } else {
            return delivery_fee;
          }
        }),
      };
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } = this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-truck" />
                {` `}
                <span className="d-none d-sm-inline">{`Delivery`}</span>
              </button>
            </li>
          </ol>
        </nav>
        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Restaurant`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              value={this.props.restaurant.name}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Support Pickup
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { support_pickup: !prevState.support_pickup };
                });
              }}
              checked={this.state.support_pickup || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Support Delivery
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Switch
              className="react-switch"
              onChange={() => {
                this.setState((prevState) => {
                  return { support_delivery: !prevState.support_delivery };
                });
              }}
              checked={this.state.support_delivery || false}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  No
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    paddingRight: 2,
                  }}
                >
                  Yes
                </div>
              }
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Online Order Delivery Area Radius (Kilometers)
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="delivery_area_radius"
              value={this.state.delivery_area_radius}
              onChange={this.handleDeliveryAreaRadiusChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Online Delivery Area Postal Codes
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="delivery_area_postal_codes"
              value={this.state.delivery_area_postal_codes}
              onChange={this.handleDeliveryAreaPostalCodesChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Call-In Order Delivery Area Radius (Kilometers)
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="callin_order_delivery_area_radius"
              value={this.state.callin_order_delivery_area_radius}
              onChange={this.handleCallInOrderDeliveryAreaRadiusChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Call-In Order Delivery Area Postal Codes
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="callin_order_delivery_area_postal_codes"
              value={this.state.callin_order_delivery_area_postal_codes}
              onChange={this.handleCallInOrderDeliveryAreaPostalCodesChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Minimum Order Amount
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="minimum_order_amount"
              value={this.state.minimum_order_amount}
              onChange={this.handleMinimumOrderAmountChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Fee
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="number"
              className="form-control"
              name="delivery_fee"
              value={this.state.delivery_fee}
              onChange={this.handleDeliveryFeeChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Fee By Radius (Kilometers)
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="row border-bottom">
                  <div className="col-3 d-flex align-items-center">{`Min`}</div>
                  <div className="col-3 d-flex align-items-center">{`Max`}</div>
                  <div className="col-3 d-flex align-items-center">{`Fee`}</div>
                  <div className="col-3 d-flex align-items-center text-right">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      onClick={this.handleAddDeliveryFee}
                    >
                      <Icon.Plus size={16} />
                    </button>
                  </div>
                </div>
                {(this.state.delivery_fees || []).map((delivery_fee, index) => (
                  <div className="row mt-3" key={index}>
                    <div className="col-3">
                      <input
                        type="number"
                        className="form-control"
                        name={`min_radius_${index}`}
                        value={delivery_fee.min_radius}
                        onChange={this.handleUpdateMinRadius}
                      />
                    </div>
                    <div className="col-3">
                      <input
                        type="number"
                        className="form-control"
                        name={`max_radius_${index}`}
                        value={delivery_fee.max_radius}
                        onChange={this.handleUpdateMaxRadius}
                      />
                    </div>
                    <div className="col-3">
                      <input
                        type="number"
                        className="form-control"
                        name={`delivery_fee_${index}`}
                        value={delivery_fee.fee}
                        onChange={this.handleUpdateFee}
                      />
                    </div>
                    <div className="col-3 text-right">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                        onClick={() => this.handleRemoveDeliveryFee(index)}
                      >
                        <Icon.Trash2 size={16} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            Delivery Infromation
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <input
              type="text"
              className="form-control"
              name="delivery_information"
              value={this.state.delivery_information}
              onChange={this.handleDeliveryInformationChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, data, callback) =>
    dispatch(updateSettings(restaurantId, data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(DeliverySettings));
