import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { addPromo, updatePromo } from "../../actions/promotion";
import { fetchMenus } from "../../actions/menu";

import "./index.css";
import percent_discount_on_selected_items from "../../assets/percent_discount_on_selected_items.png";

import PromoBasicInfo from "./PromoBasicInfo";
import PercentDiscountOnSelectedItemsPromoBenefit from "./PercentDiscountOnSelectedItemsPromoBenefit";
import PromoAvailability from "./PromoAvailability";
import PromoRulesAndRestrictions from "./PromoRulesAndRestrictions";
import PromoClientInfo from "./PromoClientInfo";
import PromoRedeemInfo from "./PromoRedeemInfo";
import PromoCouponInfo from "./PromoCouponInfo";
import PromoExclusionRules from "./PromoExclusionRules";
import ConditionsAndInstructions from "./ConditionsAndInstructions";

const form = reduxForm({
  form: "promoInfo",
  validate
});

function validate(formProps) {
  const errors = {};
  return errors;
}

class PercentDiscountOnSelectedItemsPromo extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.handleRemoveCondition = this.handleRemoveCondition.bind(this);
    this.handleRemoveInstruction = this.handleRemoveInstruction.bind(this);

    this.state = {
      name: props.promotion ? props.promotion.name : undefined,
      description: props.promotion ? props.promotion.description : undefined,
      type: props.promotion
        ? props.promotion.type
        : "% discount on selected items",
      eligible_items_group_1: props.promotion
        ? props.promotion.eligible_items_group_1
        : [],
      discount_on_items_group_1: props.promotion
        ? props.promotion.discount_on_items_group_1
        : 10,
      extra_charge_option: props.promotion
        ? props.promotion.extra_charge_option
        : "No extra charges",
      limited_showtime_option: props.promotion
        ? props.promotion.limited_showtime_option
        : "24/7",
      monday_selected: props.promotion
        ? props.promotion.monday_selected
        : false,
      monday_from: props.promotion && props.promotion.monday_from
        ? new Date(props.promotion.monday_from)
        : undefined,
      monday_until: props.promotion && props.promotion.monday_until
        ? new Date(props.promotion.monday_until)
        : undefined,
      tuesday_selected: props.promotion
        ? props.promotion.tuesday_selected
        : false,
      tuesday_from: props.promotion && props.promotion.tuesday_from
        ? new Date(props.promotion.tuesday_from)
        : undefined,
      tuesday_until: props.promotion && props.promotion.tuesday_until
        ? new Date(props.promotion.tuesday_until)
        : undefined,
      wednesday_selected: props.promotion
        ? props.promotion.wednesday_selected
        : false,
      wednesday_from: props.promotion && props.promotion.wednesday_from
        ? new Date(props.promotion.wednesday_from)
        : undefined,
      wednesday_until: props.promotion && props.promotion.wednesday_until
        ? new Date(props.promotion.wednesday_until)
        : undefined,
      thursday_selected: props.promotion
        ? props.promotion.thursday_selected
        : false,
      thursday_from: props.promotion && props.promotion.thursday_from
        ? new Date(props.promotion.thursday_from)
        : undefined,
      thursday_until: props.promotion && props.promotion.thursday_until
        ? new Date(props.promotion.thursday_until)
        : undefined,
      friday_selected: props.promotion
        ? props.promotion.friday_selected
        : false,
      friday_from: props.promotion && props.promotion.friday_from
        ? new Date(props.promotion.friday_from)
        : undefined,
      friday_until: props.promotion && props.promotion.friday_until
        ? new Date(props.promotion.friday_until)
        : undefined,
      saturday_selected: props.promotion
        ? props.promotion.saturday_selected
        : false,
      saturday_from: props.promotion && props.promotion.saturday_from
        ? new Date(props.promotion.saturday_from)
        : undefined,
      saturday_until: props.promotion && props.promotion.saturday_until
        ? new Date(props.promotion.saturday_until)
        : undefined,
      sunday_selected: props.promotion
        ? props.promotion.sunday_selected
        : false,
      sunday_from: props.promotion && props.promotion.sunday_from
        ? new Date(props.promotion.sunday_from)
        : undefined,
      sunday_until: props.promotion && props.promotion.sunday_until
        ? new Date(props.promotion.sunday_until)
        : undefined,
      available_option: props.promotion
        ? props.promotion.available_option
        : "Unlimited",
      available_from: props.promotion && props.promotion.available_from
        ? new Date(props.promotion.available_from)
        : undefined,
      available_until: props.promotion && props.promotion.available_until
        ? new Date(props.promotion.available_until)
        : undefined,
      allowed_order_type: props.promotion
        ? props.promotion.allowed_order_type
        : "Any type",
      allowed_order_time: props.promotion
        ? props.promotion.allowed_order_time
        : "Any time",
      allowed_payment_method_cash: props.promotion
        ? props.promotion.allowed_payment_method_cash
        : false,
      allowed_payment_method_cod: props.promotion
        ? props.promotion.allowed_payment_method_cod
        : false,
      allowed_payment_method_phone: props.promotion
        ? props.promotion.allowed_payment_method_phone
        : false,
      allowed_payment_method_online: props.promotion
        ? props.promotion.allowed_payment_method_online
        : false,
      eligible_client_option: props.promotion
        ? props.promotion.eligible_client_option
        : "Any client, new or returning",
      redeem_option: props.promotion
        ? props.promotion.redeem_option
        : "Show on menu",
      promo_apply_option: props.promotion?.promo_apply_option ?? "Automatically",
      redeem_only_once_per_client: props.promotion
        ? props.promotion.redeem_only_once_per_client
        : false,
      redeem_on_limited_stock: props.promotion
        ? props.promotion.redeem_on_limited_stock
        : false,
      limited_stock: props.promotion
        ? props.promotion.limited_stock
        : undefined,
      coupon_code_option: props.promotion
        ? props.promotion.coupon_code_option
        : "Auto generated",
      coupon_code: props.promotion ? props.promotion.coupon_code : undefined,
      coupon_code_expire_in: props.promotion ? props.promotion.coupon_code_expire_in : undefined,
      coupon_code_expire_unit: props.promotion ? props.promotion.coupon_code_expire_unit : undefined,
      exclusion_rule_option: props.promotion
        ? props.promotion.exclusion_rule_option
        : "Not exclusive",
      conditions: props.promotion?.conditions || [],
      instructions: props.promotion?.instructions || [],
      delivery_area_postal_codes: props.promotion?.delivery_area_postal_codes,
      allowed_order_channel: props.promotion?.allowed_order_channel || []
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchMenus(this.props.tenant._id);
    }
  }

  handleFormSubmit(formProps) {
    let data = {
      name: this.state.name,
      description: this.state.description,
      image: formProps.image,
      type: this.state.type,
      eligible_items_group_1: this.state.eligible_items_group_1,
      discount_on_items_group_1: this.state.discount_on_items_group_1,
      extra_charge_option: this.state.extra_charge_option,
      limited_showtime_option: this.state.limited_showtime_option,
      monday_selected: this.state.monday_selected,
      monday_from: this.state.monday_from,
      monday_until: this.state.monday_until,
      tuesday_selected: this.state.tuesday_selected,
      tuesday_from: this.state.tuesday_from,
      tuesday_until: this.state.tuesday_until,
      wednesday_selected: this.state.wednesday_selected,
      wednesday_from: this.state.wednesday_from,
      wednesday_until: this.state.wednesday_until,
      thursday_selected: this.state.thursday_selected,
      thursday_from: this.state.thursday_from,
      thursday_until: this.state.thursday_until,
      friday_selected: this.state.friday_selected,
      friday_from: this.state.friday_from,
      friday_until: this.state.friday_until,
      saturday_selected: this.state.saturday_selected,
      saturday_from: this.state.saturday_from,
      saturday_until: this.state.saturday_until,
      sunday_selected: this.state.sunday_selected,
      sunday_from: this.state.sunday_from,
      sunday_until: this.state.sunday_until,
      available_option: this.state.available_option,
      available_from: this.state.available_from,
      available_until: this.state.available_until,
      allowed_order_type: this.state.allowed_order_type,
      allowed_order_time: this.state.allowed_order_time,
      allowed_payment_method_cash: this.state.allowed_payment_method_cash,
      allowed_payment_method_cod: this.state.allowed_payment_method_cod,
      allowed_payment_method_phone: this.state.allowed_payment_method_phone,
      allowed_payment_method_online: this.state.allowed_payment_method_online,
      eligible_client_option: this.state.eligible_client_option,
      redeem_option: this.state.redeem_option,
      promo_apply_option: this.state.promo_apply_option,
      redeem_only_once_per_client: this.state.redeem_only_once_per_client,
      redeem_on_limited_stock: this.state.redeem_on_limited_stock,
      limited_stock: this.state.limited_stock,
      coupon_code_option: this.state.coupon_code_option,
      coupon_code: this.state.coupon_code,
      coupon_code_expire_in: this.state.coupon_code_expire_in,
      coupon_code_expire_unit: this.state.coupon_code_expire_unit,
      exclusion_rule_option: this.state.exclusion_rule_option,
      conditions: this.state.conditions,
      instructions: this.state.instructions,
      delivery_area_postal_codes: this.state.delivery_area_postal_codes,
      allowed_order_channel: this.state.allowed_order_channel
    };
    
    if (this.props.promotion) {
      this.props.updatePromo(
        this.props.tenant._id,
        this.props.promotion._id,
        data,
        () => {
          this.props.handleViewPromos();
        }
      );
    } else {
      this.props.addPromo(this.props.tenant._id, data, () => {
        this.props.handleViewPromos();
      });
    }
  }

  handleRemoveCondition(condition) {
    this.setState((prevState) => { return { conditions: [...prevState.conditions.filter(condition1 => condition !== condition1)] } });
  }

  handleRemoveInstruction(instruction) {
    this.setState((prevState) => { return { instructions: [...prevState.instructions.filter(instruction1 => instruction !== instruction1)] } });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleViewPromos,
      handleSubmit,
      menus,
      tenant
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-percent" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewPromos}
                >{`Promos`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <img
                  src={percent_discount_on_selected_items}
                  alt={`% discount on selected items`}
                  width={24}
                  height={24}
                  className="mr-3"
                />
                % discount on selected items
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <PromoBasicInfo
            sectionNumber={1}
            name={this.state.name}
            handleNameChange={event => {
              this.setState({ name: event.target.value });
            }}
            description={this.state.description}
            handleDescriptionChange={event => {
              this.setState({ description: event.target.value });
            }}
            imageUrl={
              this.props.promotion && this.props.promotion.image
                ? this.props.promotion.image.url
                : undefined
            }
            tenantSlug={tenant.slug}
          />
          <PercentDiscountOnSelectedItemsPromoBenefit
            sectionNumber={2}
            menus={menus}
            eligibleItemsGroup1={this.state.eligible_items_group_1}
            handleEligibleItemsGroup1Change={checked => {
              this.setState({ eligible_items_group_1: checked });
            }}
            discountOnItemsGroup1={this.state.discount_on_items_group_1}
            handleDiscountOnItemsGroup1Change={event => {
              this.setState({ discount_on_items_group_1: event.target.value });
            }}
            extraChargeOption={this.state.extra_charge_option}
            handleExtraChargeOptionChange={event => {
              this.setState({ extra_charge_option: event.target.value });
            }}
          />
          <PromoAvailability
            sectionNumber={3}
            limitedShowtimeOption={this.state.limited_showtime_option}
            handleLimitedShowtimeOptionChange={event => {
              this.setState({ limited_showtime_option: event.target.value });
            }}
            timeFormat={tenant.settings.time_format}
            dateFormat={tenant.settings.date_format}
            mondaySelected={this.state.monday_selected}
            handleMondaySelectedChange={() => {
              this.setState(prevState => ({
                monday_selected: !prevState.monday_selected
              }));
            }}
            mondayTimeFrom={this.state.monday_from}
            handleMondayFromChange={value =>
              this.setState({ monday_from: value })
            }
            mondayTimeUntil={this.state.monday_until}
            handleMondayTimeUntilChange={value =>
              this.setState({ monday_until: value })
            }
            tuesdaySelected={this.state.tuesday_selected}
            handleTuesdaySelectedChange={() => {
              this.setState(prevState => ({
                tuesday_selected: !prevState.tuesday_selected
              }));
            }}
            tuesdayTimeFrom={this.state.tuesday_from}
            handleTuesdayFromChange={value =>
              this.setState({ tuesday_from: value })
            }
            tuesdayTimeUntil={this.state.tuesday_until}
            handleTuesdayTimeUntilChange={value =>
              this.setState({ tuesday_until: value })
            }
            wednesdaySelected={this.state.wednesday_selected}
            handleWednesdaySelectedChange={() => {
              this.setState(prevState => ({
                wednesday_selected: !prevState.wednesday_selected
              }));
            }}
            wednesdayTimeFrom={this.state.wednesday_from}
            handleWednesdayFromChange={value =>
              this.setState({ wednesday_from: value })
            }
            wednesdayTimeUntil={this.state.wednesday_until}
            handleWednesdayTimeUntilChange={value =>
              this.setState({ wednesday_until: value })
            }
            thursdaySelected={this.state.thursday_selected}
            handleThursdaySelectedChange={() => {
              this.setState(prevState => ({
                thursday_selected: !prevState.thursday_selected
              }));
            }}
            thursdayTimeFrom={this.state.thursday_from}
            handleThursdayFromChange={value =>
              this.setState({ thursday_from: value })
            }
            thursdayTimeUntil={this.state.thursday_until}
            handleThursdayTimeUntilChange={value =>
              this.setState({ thursday_until: value })
            }
            fridaySelected={this.state.friday_selected}
            handleFridaySelectedChange={() => {
              this.setState(prevState => ({
                friday_selected: !prevState.friday_selected
              }));
            }}
            fridayTimeFrom={this.state.friday_from}
            handleFridayFromChange={value =>
              this.setState({ friday_from: value })
            }
            fridayTimeUntil={this.state.friday_until}
            handleFridayTimeUntilChange={value =>
              this.setState({ friday_until: value })
            }
            saturdaySelected={this.state.saturday_selected}
            handleSaturdaySelectedChange={() => {
              this.setState(prevState => ({
                saturday_selected: !prevState.saturday_selected
              }));
            }}
            saturdayTimeFrom={this.state.saturday_from}
            handleSaturdayFromChange={value =>
              this.setState({ saturday_from: value })
            }
            saturdayTimeUntil={this.state.saturday_until}
            handleSaturdayTimeUntilChange={value =>
              this.setState({ saturday_until: value })
            }
            sundaySelected={this.state.sunday_selected}
            handleSundaySelectedChange={() => {
              this.setState(prevState => ({
                sunday_selected: !prevState.sunday_selected
              }));
            }}
            sundayTimeFrom={this.state.sunday_from}
            handleSundayFromChange={value =>
              this.setState({ sunday_from: value })
            }
            sundayTimeUntil={this.state.sunday_until}
            handleSundayTimeUntilChange={value =>
              this.setState({ sunday_until: value })
            }
            availableOption={this.state.available_option}
            handleavailableOptionChange={event => {
              this.setState({ available_option: event.target.value });
            }}
            availableFrom={this.state.available_from}
            handleavailableFromChange={value => {
              this.setState({ available_from: value });
            }}
            availableUntil={this.state.available_until}
            handleavailableUntilChange={value => {
              this.setState({ available_until: value });
            }}
          />

          <PromoRulesAndRestrictions
            sectionNumber={4}
            allowedOrderType={this.state.allowed_order_type}
            handleAllowedOrderTypeChange={event => {
              this.setState({
                allowed_order_type: event.target.value
              });
            }}
            allowedOrderTime={this.state.allowed_order_time}
            handleAllowedOrderTimeChange={event => {
              this.setState({
                allowed_order_time: event.target.value
              });
            }}
            deliveryAreaPostalCodes={this.state.delivery_area_postal_codes}
            handleDeliveryAreaPostalCodesChange={event => {
              this.setState({
                delivery_area_postal_codes: event.target.value
              });
            }}
            allowCash={this.state.allowed_payment_method_cash}
            handleAllowCashChange={() => {
              this.setState(prevState => ({
                allowed_payment_method_cash: !prevState.allowed_payment_method_cash
              }));
            }}
            allowCod={this.state.allowed_payment_method_cod}
            handleAllowCodChange={() => {
              this.setState(prevState => ({
                allowed_payment_method_cod: !prevState.allowed_payment_method_cod
              }));
            }}
            allowPhone={this.state.allowed_payment_method_phone}
            handleAllowPhoneChange={() => {
              this.setState(prevState => ({
                allowed_payment_method_phone: !prevState.allowed_payment_method_phone
              }));
            }}
            allowOnline={this.state.allowed_payment_method_online}
            handleAllowOnlineChange={() => {
              this.setState(prevState => ({
                allowed_payment_method_online: !prevState.allowed_payment_method_online
              }));
            }}
            allowedChannel={this.state.allowed_order_channel}
            handleAllowedChannelChange={(event) => {

              const addCallIn = event.target.name === "allowed_order_channel_callin" && event.target.checked === true;
              const removeCallIn = event.target.name === "allowed_order_channel_callin" && event.target.checked === false;
              const addOnline = event.target.name === "allowed_order_channel_online" && event.target.checked === true;
              const removeOnline = event.target.name === "allowed_order_channel_online" && event.target.checked === false;

              this.setState(prevState => ({
                allowed_order_channel: 
                  addCallIn ? [...prevState.allowed_order_channel, "CallIn"] : 
                  (removeCallIn ? prevState.allowed_order_channel.filter(item => item !== "CallIn") : 
                    (addOnline ? [...prevState.allowed_order_channel, "Online"] : 
                      (removeOnline ? prevState.allowed_order_channel.filter(item => item !== "Online") : 
                      prevState.allowed_order_channel)))  
              }))
            }
            }
          />

          <PromoClientInfo
            sectionNumber={5}
            choice={this.state.eligible_client_option}
            handleChoiceChange={event => {
              this.setState({
                eligible_client_option: event.target.value
              });
            }}
          />
          <PromoRedeemInfo
            sectionNumber={6}
            choice={this.state.redeem_option}
            handleChoiceChange={event => {
              this.setState({ redeem_option: event.target.value });
            }}
            promoApplyChoice={this.state.promo_apply_option}
            handlePromoApplyChoiceChange={event => { this.setState({ promo_apply_option: event.target.value }); }}
            redeemOncePerClient={this.state.redeem_only_once_per_client}
            handleRedeemOncePerClientChange={() => {
              this.setState(prevState => ({
                redeem_only_once_per_client: !prevState.redeem_only_once_per_client
              }));
            }}
            handleRedeemOnLimitedStockChange={() => {
              this.setState(prevState => ({
                redeem_on_limited_stock: !prevState.redeem_on_limited_stock
              }));
            }}
            redeemOnLimitedStock={this.state.redeem_on_limited_stock}
            handleLimitedStockChange={event => {
              this.setState({
                limited_stock: event.target.value
              });
            }}
            limitedStock={this.state.limited_stock}
          />
          <PromoCouponInfo
            sectionNumber={7}
            choice={this.state.coupon_code_option}
            handleChoiceChange={event => {
              this.setState({ coupon_code_option: event.target.value });
            }}
            couponCode={this.state.coupon_code}
            handleCouponCodeChange={event => {
              this.setState({ coupon_code: event.target.value });
            }}
            couponCodeExpireIn={this.state.coupon_code_expire_in}
            handleCouponCodeExpireInChange={event => {
              this.setState({ coupon_code_expire_in: event.target.value });
            }}
            couponCodeExpireUnit={this.state.coupon_code_expire_unit}
            handleCouponCodeExpireUnitChange={event => {
              this.setState({ coupon_code_expire_unit: event.target.value });
            }}
          />
          <PromoExclusionRules
            sectionNumber={8}
            choice={this.state.exclusion_rule_option}
            handleChoiceChange={event => {
              this.setState({ exclusion_rule_option: event.target.value });
            }}
          />
          <ConditionsAndInstructions
            sectionNumber={9}
            conditions={this.state.conditions}
            newCondition={this.state.condition}
            handleConditionChange={event => {
              this.setState({ condition: event.target.value });
            }}
            handleAddCondition={() => { this.setState((prevState) => { return { conditions: [...prevState.conditions, this.state.condition], condition: "" } }) }}
            handleRemoveCondition={this.handleRemoveCondition}
            instructions={this.state.instructions}
            newInstruction={this.state.instruction}
            handleInstructionChange={event => {
              this.setState({ instruction: event.target.value });
            }}
            handleAddInstruction={() => { this.setState((prevState) => { return { instructions: [...prevState.instructions, this.state.instruction], instruction: "" } }) }}
            handleRemoveInstruction={this.handleRemoveInstruction}
          />
          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={handleViewPromos}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            {"Save"}
          </button>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.promotion.errorMessage,
    message: state.promotion.message,
    tenant: state.tenant.tenant,
    menus: state.menu.menus.filter(menu => menu.disabled === false) || [],
    initialValues: ownProps.promotion
  };
};

const mapDispatchToProps = dispatch => ({
  addPromo: (tenantId, data, callback) =>
    dispatch(addPromo(tenantId, data, callback)),
  updatePromo: (tenantId, promotionId, data, callback) =>
    dispatch(updatePromo(tenantId, promotionId, data, callback)),
  fetchMenus: tenantId => dispatch(fetchMenus(tenantId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(PercentDiscountOnSelectedItemsPromo));
