import axios from 'axios';
import Cookies from 'universal-cookie';

import {
  errorHandler
} from './index';

import {
  AUTH_ERROR,
  AUTH_USER,
  UNAUTH_USER,
  FORGOT_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST
} from './types';

const cookies = new Cookies();

//==================================================
// Authentication actions
//==================================================

export function register({ tenantName, accountType, userName, firstName, lastName, email, telephone, password }, successCallback) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eatery/auth/register`, { tenantName, accountType, userName, firstName, lastName, email, telephone, password })
      .then(response => {
        cookies.set('utoken', response.data.token, { path: '/' });
        cookies.set('user', response.data.user, { path: '/' });
        successCallback()
        dispatch({ type: AUTH_USER });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}

export function login({ tenantSlug, userName, password }, successCallback) {
  return function (dispatch) {
    const url = `${process.env.REACT_APP_API_BASE}/api/eatery/auth/login`;
    console.log(url)
    const _userName = `${tenantSlug}|${userName}`;
    axios.post(url, { userName: _userName, password })
      .then(response => {
        cookies.set('utoken', response.data.token, { path: '/' });
        cookies.set('user', response.data.user, { path: '/' });
        successCallback(dispatch);
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR)
      });
  }
}

export function logout(error) {
  return function (dispatch) {
    dispatch({ type: UNAUTH_USER, payload: error || '' });
    cookies.remove('utoken', { path: '/' });
    cookies.remove('userName', { path: '/' });
    cookies.remove('user', { path: '/' });
    window.location.href="/";
    // window.location.href = CLIENT_ROOT_URL + '/home';
  }
}

export function getForgotPasswordToken({ email }, baseUrl) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eatery/auth/forgot-password`, { email, baseUrl })
      .then((response) => {
        dispatch({
          type: FORGOT_PASSWORD_REQUEST,
          payload: response.data.message,
        });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR);
      });
  };
}

export function resetPassword(token, { password }) {
  return function (dispatch) {
    axios.post(process.env.REACT_APP_API_BASE + `/api/eatery/auth/reset-password/${token}`, { password })
      .then((response) => {
        dispatch({
          type: RESET_PASSWORD_REQUEST,
          payload: response.data.message,
        });

      })
      .catch((error) => {
        errorHandler(dispatch, error.response, AUTH_ERROR);
      });
  };
}


