import {
  getData,
  postData2,
  patchData2,
  deleteData
} from "./index";

import {
  UPDATE_GIFTCARDPRODUCT,
  ADD_GIFTCARDPRODUCT,
  FETCH_GIFTCARDPRODUCTS,
  GIFTCARDPRODUCT_ERROR,
  DELETE_GIFTCARDPRODUCT
} from "./types";

export function fetchGiftCardProducts(tenantId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/giftcardproducts`;
  return dispatch => getData(FETCH_GIFTCARDPRODUCTS, GIFTCARDPRODUCT_ERROR, true, url, dispatch);
}

export function addGiftCardProduct(restaurantId, { name, description, price, unitPrice, quantity, validFrom, validUntil, imageUrl }, callback) {
  let formData = new FormData();

  // formData.append('restaurantId', restaurantId);
  formData.append('name', name);
  if (description) {
    formData.append('description', description);
  }
  
  formData.append('price', price);
  formData.append('unitPrice', unitPrice);
  formData.append('quantity', quantity);
  if (validFrom) {
    formData.append('validFrom', validFrom);
  }
  if (validUntil) {
    formData.append('validUntil', validUntil);
  }

  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  }

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/restaurants/${restaurantId}/giftcardproducts`;
  return dispatch =>
    postData2(ADD_GIFTCARDPRODUCT, GIFTCARDPRODUCT_ERROR, true, url, dispatch, formData, callback);
}

export function updateGiftCardProduct({ _id, name, description, price, unitPrice, quantity, validFrom, validUntil, imageUrl }, callback) {
  let formData = new FormData();

  formData.append('name', name);
  if (description) {
    formData.append('description', description);
  }
  formData.append('price', price);
  formData.append('unitPrice', unitPrice);
  formData.append('quantity', quantity);
  if (validFrom) {
    formData.append('validFrom', validFrom);
  }
  if (validUntil) {
    formData.append('validUntil', validUntil);
  }

  if (imageUrl) {
    formData.append("imageUrl", imageUrl);
  }
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/giftcardproducts/${_id}`;
  return dispatch =>
    patchData2(UPDATE_GIFTCARDPRODUCT, GIFTCARDPRODUCT_ERROR, true, url, dispatch, formData, callback);
}

export function deleteGiftCardProduct(giftCardProductId, callback) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/giftcardproducts/${giftCardProductId}`;
  return dispatch => deleteData(DELETE_GIFTCARDPRODUCT, GIFTCARDPRODUCT_ERROR, true, url, dispatch, callback);
}
