import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";

import { updateMenuItemOption } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemOptionOption",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.type) {
    errors.type = "Please choose type";
  }

  return errors;
}

class MenuItemOptionEditModal extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleMinSelectedChange = this.handleMinSelectedChange.bind(this);
    this.handleMaxSelectedChange = this.handleMaxSelectedChange.bind(this);
    this.handleNumberOfFreeOptionsChange = this.handleNumberOfFreeOptionsChange.bind(this);
    this.handleInformationChange = this.handleInformationChange.bind(this);
    this.handleHiddenFromPublicChange =
      this.handleHiddenFromPublicChange.bind(this);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);

    this.handleItemNameChange = this.handleItemNameChange.bind(this);
    this.handleItemPriceChange = this.handleItemPriceChange.bind(this);
    this.handleItemVisibilityChange =
      this.handleItemVisibilityChange.bind(this);

    this.state = {
      name: props.menuItemOption.name,
      selection_type: props.menuItemOption.selection_type,
      min_selected: props.menuItemOption.min_selected,
      max_selected: props.menuItemOption.max_selected,
      number_of_free_options: props.menuItemOption.number_of_free_options,
      information: props.menuItemOption.information,
      hidden_from_public: props.menuItemOption.hidden_from_public,
      items: props.menuItemOption.items,
    };
  }

  componentDidMount() {}

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }

  handleTypeChange(event) {
    this.setState({ selection_type: event.target.checked });
  }

  handleMinSelectedChange(event) {
    this.setState({ min_selected: event.target.value });
  }

  handleMaxSelectedChange(event) {
    this.setState({ max_selected: event.target.value });
  }

  handleNumberOfFreeOptionsChange(event) {
    this.setState({ number_of_free_options: event.target.value });
  }

  handleInformationChange(event) {
    this.setState({ information: event.target.value });
  }

  handleHiddenFromPublicChange(event) {
    this.setState({ hidden_from_public: event.target.value });
  }

  handleAddItem() {
    const newItems = this.state.items.slice();
    newItems.push({ name: undefined, additional_cost: undefined });
    this.setState({ items: newItems });
  }

  handleRemoveItem(itemId) {
    this.setState((prevState) => ({
      items: prevState.items.filter((item) => item._id !== itemId),
    }));
  }

  handleItemNameChange(event) {
    const id = event.target.id;
    const index = id.substring("name_".length, id.length);
    const newItems = this.state.items.slice();
    newItems[index].name = event.target.value;
    this.setState({ items: newItems });
  }

  handleItemPriceChange(event) {
    const id = event.target.id;
    const index = id.substring("additional_cost_".length, id.length);
    const newItems = this.state.items.slice();
    newItems[index].additional_cost = event.target.value;
    this.setState({ items: newItems });
  }

  handleItemVisibilityChange(event) {
    const id = event.target.id;
    const index = id.substring("hidden_from_public_".length, id.length);
    const newItems = this.state.items.slice();
    newItems[index].hidden_from_public = event.target.checked;
    this.setState({ items: newItems });
  }

  handleFormSubmit(formProps) {
    this.props.updateMenuItemOption(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      this.props.menuItemOptionId,
      {
        name: this.state.name,
        selection_type: this.state.selection_type,
        min_selected: this.state.min_selected,
        max_selected: this.state.max_selected,
        number_of_free_options: this.state.number_of_free_options,
        information: this.state.information,
        hidden_from_public: this.state.hidden_from_public,
        items: this.state.items,
      }
    );
    $(".close").click();
  }

  generateModalId(menuItemOptionId) {
    return "menuItemOptionEditModal" + menuItemOptionId;
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit, menuItemOptionId } = this.props;

    return (
      <>
        <div
          className="modal fade"
          id={this.generateModalId(menuItemOptionId)}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#menuItemOptionEditModallLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                <div className="modal-header bg-primary">
                  <h5
                    className="modal-title"
                    id="menuItemOptionEditModallLabel"
                  >
                    {"Option Info"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-left">
                  {this.renderAlert()}
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label className="col-form-label">Name</label>
                          <input
                            name="name"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            type="text"
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Type</label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                name="selection_type"
                                className="form-check-input"
                                value={this.state.selection_type}
                                onChange={this.handleTypeChange}
                                type="radio"
                                checked={this.state.selection_type === "Single"}
                              />
                              <label className="form-check-label">
                                {` `}
                                {`Single Selection`}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                name="selection_type"
                                className="form-check-input"
                                value={this.state.selection_type}
                                onChange={this.handleTypeChange}
                                type="radio"
                                checked={
                                  this.state.selection_type === "Multiple"
                                }
                              />
                              <label className="form-check-label">
                                {` `}
                                {`Multiple Selection`}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Minimum items to select</label>
                          <input
                            name="min_selected"
                            className="form-control"
                            value={this.state.min_selected}
                            onChange={this.handleMinSelectedChange}
                            type="number"
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">Maximum items to select</label>
                          <input
                            name="max_selected"
                            className="form-control"
                            value={this.state.max_selected}
                            onChange={this.handleMaxSelectedChange}
                            type="number"
                          />
                        </div>     
                        <div className="form-group">
                          <label className="col-form-label">Number of free options</label>
                          <input
                            name="max_selected"
                            className="form-control"
                            value={this.state.number_of_free_options}
                            onChange={this.handleNumberOfFreeOptionsChange}
                            type="number"
                          />
                        </div>   
                        <div className="form-group">
                          <label className="col-form-label">Note</label>
                          <input
                            name="information"
                            className="form-control"
                            value={this.state.information}
                            onChange={this.handleInformationChange}
                            type="text"
                          />
                        </div>                   
                        <div className="form-group">
                          <label className="col-form-label">Visibility</label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                name="hidden_from_public"
                                className="form-check-input"
                                type="checkbox"
                                defaultChecked={this.state.hidden_from_public}
                                onChange={this.handleHiddenFromPublicChange}
                              />
                              <label className="form-check-label">
                                {` `}
                                {`Hidden From Public`}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="form-row">
                          <div className="col-6">Choices</div>
                          <div className="col-6 text-right">
                            <button
                              type="button"
                              className="btn btn-light"
                              onClick={this.handleAddItem}
                            >
                              <Icon.Plus size={16} />
                              {` `}
                              {`Add Choice`}
                            </button>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12">
                            <hr />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-1">{` `}</div>
                          <div className="col-6">{`Name`}</div>
                          <div className="col-3">{`Price`}</div>
                          <div className="col-2">{`Hidden`}</div>
                        </div>
                        {this.state.items.map((item, index) => (
                          <React.Fragment key={index}>
                            <div className="form-row">
                              <div className="col-1">
                                <button
                                  type="button"
                                  className="btn btn-outline-danger btn-sm form-control"
                                  title="Remove Item"
                                  onClick={() =>
                                    this.handleRemoveItem(item._id)
                                  }
                                >
                                  <Icon.Trash2 size={16} />
                                </button>
                              </div>
                              <div className="col-6">
                                <input
                                  id={`name_${index}`}
                                  className="form-control"
                                  placeholder="Name"
                                  type="text"
                                  value={this.state.items[index].name}
                                  onChange={this.handleItemNameChange}
                                />
                              </div>
                              <div className="col-3">
                                <input
                                  id={`additional_cost_${index}`}
                                  className="form-control"
                                  type="number"
                                  step=".01"
                                  value={
                                    this.state.items[index].additional_cost
                                  }
                                  onChange={this.handleItemPriceChange}
                                />
                              </div>
                              <div className="col-2">
                                <input
                                  id={`hidden_from_public_${index}`}
                                  className="form-control"
                                  type="checkbox"
                                  defaultChecked={
                                    this.state.items[index].hidden_from_public
                                  }
                                  onChange={this.handleItemVisibilityChange}
                                />
                              </div>
                            </div>
                 
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {"Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMenuItemOption: (
    menuId,
    menuSectionId,
    menuItemId,
    menuItemOptionId,
    formProps
  ) =>
    dispatch(
      updateMenuItemOption(
        menuId,
        menuSectionId,
        menuItemId,
        menuItemOptionId,
        formProps
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MenuItemOptionEditModal));
