import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { renderPriceField, renderField } from "../../utils/renderFields";
import { addMenuItem } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemInfo",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter menu item name";
  }

  return errors;
}

class AddMenuItem extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.addMenuItem(
      this.props.menu._id,
      this.props.menuSection._id,
      formProps,
      () => {
        this.props.handleViewMenuSection(this.props.menuSection);
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      menu,
      menuSection,
      handleSubmit,
      pristine,
      submitting,
      handleViewMenu,
      handleViewMenus,
      handleViewMenuSection,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {menuSection.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {"Add New Item"}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <Field
                    name="image"
                    component={renderDropzoneInput}
                  />
                </div>
              </div> */}
              <div className="col-12">
                <div className="form-group">
                  <label className="col-form-label">Name</label>
                  <Field
                    name="name"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Print Name (Optional)</label>
                  <Field
                    name="printName"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Price</label>
                  <Field
                    name="price"
                    className="form-control"
                    component={renderPriceField}
                    type="number"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Description</label>
                  <Field
                    name="description"
                    className="form-control"
                    component="textarea"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Type</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="type"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="Single"
                      />
                      <label className="form-check-label">Single Item</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="type"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="Combo"
                      />
                      <label className="form-check-label">Multiple Items</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Code</label>
                  <Field
                    name="cd"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Display Order</label>
                  <Field
                    name="display_order"
                    className="form-control"
                    component={renderField}
                    type="number"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Spicy</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="spicy_not"
                      />
                      <label className="form-check-label">Not</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="spicy_mild"
                      />
                      <label className="form-check-label">Mild</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="spicy_medium"
                      />
                      <label className="form-check-label">Medium</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="spicy_hot"
                      />
                      <label className="form-check-label">Hot</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Tags</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_special"
                        className="form-check-input"
                        component="input"
                        type="checkbox"
                      />
                      <label className="form-check-label">Special</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_vegetarian"
                        className="form-check-input"
                        component="input"
                        type="checkbox"
                      />
                      <label className="form-check-label">Vegetarian</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_gluten_free"
                        className="form-check-input"
                        component="input"
                        type="checkbox"
                      />
                      <label className="form-check-label">Gluten Free</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="tag_new"
                        className="form-check-input"
                        component="input"
                        type="checkbox"
                      />
                      <label className="form-check-label">New</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-form-label">Visibility</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="hidden_from_public"
                        className="form-check-input"
                        component="input"
                        type="checkbox"
                      />
                      <label className="form-check-label">
                        {` `}
                        {`Hidden From Public`}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={() => handleViewMenuSection(menuSection)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pristine || submitting}
            >
              {"Save"}
            </button>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let tag_spicy = undefined;
  let tag_gluten_free = undefined;
  let tag_special = undefined;
  let tag_vegetarian = undefined;
  let tag_new = undefined;
  if (state.menu.menuItem && state.menu.menuItem.tags) {
    tag_spicy = state.menu.menuItem.tags.includes("spicy_mild")
      ? "spicy_mild"
      : state.menu.menuItem.tags.includes("spicy_medium")
      ? "spicy_medium"
      : state.menu.menuItem.tags.includes("spicy_hot")
      ? "spicy_hot"
      : undefined;
    tag_gluten_free = state.menu.menuItem.tags.includes("gluten_friendly");
    tag_special = state.menu.menuItem.tags.includes("special");
    tag_vegetarian = state.menu.menuItem.tags.includes("vegetarian");
    tag_new = state.menu.menuItem.tags.includes("new");
  }
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItem: state.menu.menuItem,
    initialValues: {
      ...state.menu.menuItem,
      tag_spicy: tag_spicy,
      tag_gluten_free: tag_gluten_free,
      tag_special: tag_special,
      tag_vegetarian: tag_vegetarian,
      tag_new: tag_new,
      type: "Single",
      hidden_from_public: false,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  addMenuItem: (menuId, menuSectionId, formProps, callback) =>
    dispatch(addMenuItem(menuId, menuSectionId, formProps, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddMenuItem));
