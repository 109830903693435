import React, { Component } from "react";
import { connect } from "react-redux";

import * as Icon from "react-feather";
import MenuSectionList from "./MenuSectionList";
import MenuInfoCard from "./MenuInfoCard";

import { fetchMenu } from "../../actions/menu";

class Menu extends Component {
  componentDidMount() {
    if (this.props.menu) {
      this.props.fetchMenu(this.props.menu._id)
    }
  }

  render() {
    const {
      tenant,
      restaurant,
      menu,
      handleEditMenu,
      handleViewMenus,
      handleViewMenuSection,
      handleAddMenuSection,
      handleEditMenusection
    } = this.props;

    if (!menu) {
      return <></>;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                {menu.name}
              </button>
            </li>
          </ol>
        </nav>

        <div className="row">
          <div className="col-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleViewMenus}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-9">
            <h3>{menu.name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12 order-12 col-lg-8 order-lg-1 mb-3">
            <MenuSectionList
              tenant={tenant}
              restaurant={restaurant}
              menu={menu}
              sections={menu.sections ? menu.sections.filter(section => section.disabled === false) : []}
              handleViewMenuSection={handleViewMenuSection}
              handleAddMenuSection={handleAddMenuSection}
              handleEditMenusection={handleEditMenusection}
            />
          </div>
          <div className="col-12 order-1 col-lg-4 order-lg-12 mb-3">
            <MenuInfoCard tenant={tenant} menu={menu} handleEditMenu={handleEditMenu} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menu: state.menu.menu ? state.menu.menu : ownProps.menu
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenu: (menuId) => dispatch(fetchMenu(menuId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
