import React, { Component } from "react";
import { Field, reduxForm, reset, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { socket } from '../../service/socket';

import {
    renderPhoneField,
    renderEmailField,
    required,
    phoneNumber,
    renderField,
    email,
    renderDateTimePicker
} from "../../utils/renderFields";

import { addCoupon, generateCouponCode, fetchEmailByPhone } from "../../actions/coupon";

import normalizePhone from "../../utils/normalizePhone";

import moment from "moment-timezone";

const selector = formValueSelector("addCoupon");

const afterSubmit = (result, dispatch) =>
    dispatch(reset('addCoupon'));

const form = reduxForm({
    form: "addCoupon",
    onSubmitSuccess: afterSubmit,
    validate,
});


function validate(formProps) {
    const errors = {};

    if (!formProps.couponType) {
        errors.couponType = "Please select coupon type";
    }
    if (formProps.reason === "MissedFromOrder" && !formProps.orderNumber) {
        errors.orderNumber = "Please enter order number";
    }
    if (!formProps.phone) {
        errors.phone = "Please enter phone number";
    }
    if (formProps.sendEmail === true && !formProps.message) {
        errors.message = "Please enter message";
    }

    return errors;
}

const couponTypes = [
    {
        code: "5OFF",
        amount: 5,
        description: "$5 off next online order"
    },
    {
        code: "10OFF",
        amount: 10,
        description: "$10 off next online order"
    },
    {
        code: "15OFF",
        amount: 15,
        description: "$15 off next online order"
    },
    {
        code: "20OFF",
        amount: 20,
        description: "$20 off next online order"
    },
    {
        code: "25OFF",
        amount: 25,
        description: "$25 off next online order"
    }
];

const couponMessage = (couponAmount, websiteUrl, couponCode, couponValidUntil, timeZone, dateFormat) => {
    let message = `We truly appreciate your business and look forward to serving ou again. Here is $${couponAmount} off E-coupon for your next online order @ ${websiteUrl}. \nCode: ${couponCode}\n` ;
    if (couponValidUntil) {
        message = message +  `Valid until: ${moment(couponValidUntil).tz(timeZone).format(dateFormat)}`;
    }
    return message;
}
    
   

class AddCoupon extends Component {
    constructor(props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.handleFetchEmailByPhone = this.handleFetchEmailByPhone.bind(this);

        this.state = {


        };
    }



    componentDidMount() {

    }

    handleFetchEmailByPhone(event, newValue, previousValue) {
        this.props.fetchEmailByPhone(this.props.tenant._id, newValue)
    }

    

    handleFormSubmit(formProps) {

        const couponAmount = couponTypes.filter(couponType => couponType.code === formProps.couponType)[0].amount;
        const couponName = couponTypes.filter(couponType => couponType.code === formProps.couponType)[0].description;

        this.props.addCoupon(
            this.props.tenant._id,
            {
                couponName: couponName,
                couponCode: this.props.couponCode,
                couponAmount,
                orderNumber: formProps.orderNumber,
                couponValidUntil: formProps.validUntil,
                email: formProps.email,
                phone: formProps.phone,
                sendEmail: formProps.sendEmail,
                message: couponMessage(couponAmount, this.props.tenant.website_url, this.props.couponCode, formProps.validUntil, this.props.tenant.settings.time_zone, this.props.tenant.settings.date_format),
                reason: formProps.reason
            },
            () => {
                $(".close").click();
                socket.emit("initial_coupons", this.props.tenant._id, this.props.period);
            }
        );
    }

    renderAlert() {
        if (this.props.errorMessage) {
            return (
                <div className="alert alert-danger">
                    <span>
                        <strong>Error!</strong> {this.props.errorMessage}
                    </span>
                </div>
            );
        }
    }

    render() {
        const {
            handleSubmit,
            reasonValue,
            couponTypeValue,
            tenant,
            couponCode,
            emails = []
        } = this.props;

        if (!tenant) {
            return <></>;
        }

        return (
            <>
                <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#addCouponModal"
                    data-backdrop="static"
                >
                    <Icon.Plus size={16} />
                    {`Add Coupon`}
                </button>

                <div
                    className="modal fade"
                    id="addCouponModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="#addCouponModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered model-lg" role="document">
                        <div className="modal-content">
                            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
                                <div className="modal-header bg-primary">
                                    <h5 className="modal-title" id="addCouponModalLabel">
                                        {"Add Coupon"}
                                    </h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-left">
                                    {this.renderAlert()}
                                    <div className="form-group">
                                        <label className="required">Coupon</label>
                                        <Field
                                            name="couponType"
                                            className="form-control"
                                            component={"select"}
                                        >
                                            {couponTypes.map(couponType => (
                                                <option key={couponType.code} value={couponType.code}>{couponType.description}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className="form-group">
                                        <label className="required">Coupon Code</label>
                                        <div className="input-group">
                                            {/* <input type="text" className="form-control mr-3" id="couponCode" value={couponCode} disabled /> */}
                                            <Field name="couponCode" className="form-control mr-3" component={renderField} type="text" input={{ disabled: true, value: couponCode }} />
                                            <button type="button" className="ml-3 btn btn-primary" onClick={() => this.props.generateCouponCode(tenant._id, couponTypeValue)}>{`Generate Coupon Code`}</button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="required">Reason</label>
                                        <Field
                                            name="reason"
                                            className="form-control"
                                            component={"select"}
                                        >
                                            <option value="MissedFromOrder">{`Missed from order`}</option>
                                            <option value="MigratedFromPaperCoupon">{`Migrated from paper coupon`}</option>
                                        </Field>
                                    </div>
                                    {reasonValue === "MissedFromOrder" &&
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label className="required">Order Number</label>
                                                <Field
                                                    name="orderNumber"
                                                    className="form-control"
                                                    component={renderField}
                                                    validate={reasonValue === "MissedFromOrder" ? [required] : []}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="required">Coupon Valid Until</label>
                                                <Field
                                                    name="validUntil"
                                                    className="form-control"
                                                    component={renderDateTimePicker}
                                                    showTime={false}
                                                    validate={reasonValue === "MissedFromOrder" ? [required] : []}
                                                    dateFormat={tenant.settings.date_format}
                                                    timeFormat={tenant.settings.time_format}
                                                    datetimeFormat={tenant.settings.date_time_format}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="form-group">
                                        <label className="required">Phone</label>
                                        <Field
                                            name="phone"
                                            className="form-control"
                                            component={renderPhoneField}
                                            validate={[required, phoneNumber]}
                                            type="text"
                                            normalize={normalizePhone}
                                            onBlur={this.handleFetchEmailByPhone}
                                        />
                                    </div>
                                    {emails && emails.length > 0 &&
                                        <ul>
                                            {emails.map((email, index) => (
                                                <li key={index}>{`${email} `}</li>
                                            ))}
                                        </ul>
                                    }
                                    <div className="form-group">
                                        <label className="required">Email</label>
                                        <Field
                                            name="email"
                                            className="form-control"
                                            component={renderEmailField}
                                            validate={[required, email]}
                                            // input={{value: emails.join(",")}}
                                            type="text"
                                        />
                                    </div>
                                    <div className="form-check">
                                        <Field name="sendEmail" component="input" type="checkbox" className="form-check-input" />
                                        <label className="form-check-label">Send coupon code via email</label>
                                    </div>
                                    {/* {sendEmailValue === true &&
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                <label className="required">Message</label>
                                            </label>
                                            <Field
                                                name="message"
                                                className="form-control"
                                                validate={sendEmailValue === true ? [required] : []}
                                                component="textarea"
                                            />
                                        </div>
                                    } */}

                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary mr-3"
                                        data-dismiss="modal"
                                    >
                                        {`Cancel`}
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        {"Save"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        errorMessage: state.coupon.errorMessage,
        message: state.coupon.message,
        tenant: state.tenant.tenant,
        reasonValue: selector(state, "reason"),
        sendEmailValue: selector(state, "sendEmail"),
        couponTypeValue: selector(state, "couponType"),
        couponCode: state.coupon.couponCode,
        emails: state.coupon.emails,
        initialValues: {
            couponType: "5OFF",
            reason: "MigratedFromPaperCoupon",
            message: "We truly appreciate your business and look forward to serving ou again. Here is $5 off E-coupon for your next online order @ angkordelight@.com. \nCode: {{coupon_code}}\nValid until: {{coupon_valid_until}}"
        }
    };
};

const mapDispatchToProps = (dispatch) => ({
    generateCouponCode: (
        tenantId,
        couponTypeCode
    ) =>
        dispatch(
            generateCouponCode(
                tenantId,
                couponTypeCode
            )
        ),
    fetchEmailByPhone: (
        tenantId,
        phoneNumber
    ) =>
        dispatch(
            fetchEmailByPhone(
                tenantId,
                phoneNumber
            )
        ),
    addCoupon: (
        tenantId,
        data,
        callback
    ) =>
        dispatch(
            addCoupon(
                tenantId,
                data,
                callback
            )
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddCoupon));
