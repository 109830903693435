import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Switch from "react-switch";

import { updateSettings } from "../../actions/restaurant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "onlineOrderingSettings",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

const leadTimes = [
  15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105,
  100, 115, 120,
];

class RushHourSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleMondayRushHourStartChange =
      this.handleMondayRushHourStartChange.bind(this);
    this.handleMondayRushHourEndChange =
      this.handleMondayRushHourEndChange.bind(this);
    this.handleMondayRushHourOrderLeadTimeChange =
      this.handleMondayRushHourOrderLeadTimeChange.bind(this);
    this.handleMondayRushHourDeliveryOrderLeadTimeChange =
      this.handleMondayRushHourDeliveryOrderLeadTimeChange.bind(this);
    this.handleTuesdayRushHourStartChange =
      this.handleTuesdayRushHourStartChange.bind(this);
    this.handleTuesdayRushHourEndChange =
      this.handleTuesdayRushHourEndChange.bind(this);
    this.handleTuesdayRushHourOrderLeadTimeChange =
      this.handleTuesdayRushHourOrderLeadTimeChange.bind(this);
    this.handleTuesdayRushHourDeliveryOrderLeadTimeChange =
      this.handleTuesdayRushHourDeliveryOrderLeadTimeChange.bind(this);
    this.handleWednesdayRushHourStartChange =
      this.handleWednesdayRushHourStartChange.bind(this);
    this.handleWednesdayRushHourEndChange =
      this.handleWednesdayRushHourEndChange.bind(this);
    this.handleWednesdayRushHourOrderLeadTimeChange =
      this.handleWednesdayRushHourOrderLeadTimeChange.bind(this);
    this.handleWednesdayRushHourDeliveryOrderLeadTimeChange =
      this.handleWednesdayRushHourDeliveryOrderLeadTimeChange.bind(this);
    this.handleThursdayRushHourStartChange =
      this.handleThursdayRushHourStartChange.bind(this);
    this.handleThursdayRushHourEndChange =
      this.handleThursdayRushHourEndChange.bind(this);
    this.handleThursdayRushHourOrderLeadTimeChange =
      this.handleThursdayRushHourOrderLeadTimeChange.bind(this);
    this.handleThursdayRushHourDeliveryOrderLeadTimeChange =
      this.handleThursdayRushHourDeliveryOrderLeadTimeChange.bind(this);
    this.handleFridayRushHourStartChange =
      this.handleFridayRushHourStartChange.bind(this);
    this.handleFridayRushHourEndChange =
      this.handleFridayRushHourEndChange.bind(this);
    this.handleFridayRushHourOrderLeadTimeChange =
      this.handleFridayRushHourOrderLeadTimeChange.bind(this);
    this.handleFridayRushHourDeliveryOrderLeadTimeChange =
      this.handleFridayRushHourDeliveryOrderLeadTimeChange.bind(this);

    this.handleSaturdayRushHourStartChange =
      this.handleSaturdayRushHourStartChange.bind(this);
    this.handleSaturdayRushHourEndChange =
      this.handleSaturdayRushHourEndChange.bind(this);

    this.handleSaturdayRushHourOrderLeadTimeChange =
      this.handleSaturdayRushHourOrderLeadTimeChange.bind(this);
    this.handleSaturdayRushHourDeliveryOrderLeadTimeChange =
      this.handleSaturdayRushHourDeliveryOrderLeadTimeChange.bind(this);
    this.handleSundayRushHourStartChange =
      this.handleSundayRushHourStartChange.bind(this);
    this.handleSundayRushHourEndChange =
      this.handleSundayRushHourEndChange.bind(this);
    this.handleSundayRushHourOrderLeadTimeChange =
      this.handleSundayRushHourOrderLeadTimeChange.bind(this);
    this.handleSundayRushHourDeliveryOrderLeadTimeChange =
      this.handleSundayRushHourDeliveryOrderLeadTimeChange.bind(this);

    this.state = {
      monday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.monday.disabled,
      monday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.monday.rush_hour_start,
      monday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.monday.rush_hour_end,
      monday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.monday
          .online_order_lead_time,
      monday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.monday
          .delivery_order_lead_time,

      tuesday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.tuesday.disabled,
      tuesday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.tuesday.rush_hour_start,
      tuesday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.tuesday.rush_hour_end,
      tuesday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.tuesday
          .online_order_lead_time,
      tuesday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.tuesday
          .delivery_order_lead_time,

      wednesday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.wednesday.disabled,
      wednesday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.wednesday.rush_hour_start,
      wednesday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.wednesday.rush_hour_end,
      wednesday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.wednesday
          .online_order_lead_time,
      wednesday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.wednesday
          .delivery_order_lead_time,

      thursday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.thursday.disabled,
      thursday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.thursday.rush_hour_start,
      thursday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.thursday.rush_hour_end,
      thursday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.thursday
          .online_order_lead_time,
      thursday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.thursday
          .delivery_order_lead_time,

      friday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.friday.disabled,
      friday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.friday.rush_hour_start,
      friday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.friday.rush_hour_end,
      friday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.friday
          .online_order_lead_time,
      friday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.friday
          .delivery_order_lead_time,

      saturday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.saturday.disabled,
      saturday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.saturday.rush_hour_start,
      saturday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.saturday.rush_hour_end,
      saturday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.saturday
          .online_order_lead_time,
      saturday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.saturday
          .delivery_order_lead_time,

      sunday_rush_hour_enabled:
        !props.restaurant.settings.rush_hour_settings.sunday.disabled,
      sunday_rush_hour_start:
        props.restaurant.settings.rush_hour_settings.sunday.rush_hour_start,
      sunday_rush_hour_end:
        props.restaurant.settings.rush_hour_settings.sunday.rush_hour_end,
      sunday_rush_hour_order_lead_time:
        props.restaurant.settings.rush_hour_settings.sunday
          .online_order_lead_time,
      sunday_rush_hour_delivery_order_lead_time:
        props.restaurant.settings.rush_hour_settings.sunday
          .delivery_order_lead_time,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    this.props.updateSettings(
      this.props.restaurant._id,
      {
        monday_rush_hour_enabled: this.state.monday_rush_hour_enabled,
        monday_rush_hour_start: this.state.monday_rush_hour_start,
        monday_rush_hour_end: this.state.monday_rush_hour_end,
        monday_rush_hour_order_lead_time:
          this.state.monday_rush_hour_order_lead_time,
        monday_rush_hour_delivery_order_lead_time:
          this.state.monday_rush_hour_delivery_order_lead_time,

        tuesday_rush_hour_enabled: this.state.tuesday_rush_hour_enabled,
        tuesday_rush_hour_start: this.state.tuesday_rush_hour_start,
        tuesday_rush_hour_end: this.state.tuesday_rush_hour_end,
        tuesday_rush_hour_order_lead_time:
          this.state.tuesday_rush_hour_order_lead_time,
        tuesday_rush_hour_delivery_order_lead_time:
          this.state.tuesday_rush_hour_delivery_order_lead_time,

        wednesday_rush_hour_enabled: this.state.wednesday_rush_hour_enabled,
        wednesday_rush_hour_start: this.state.wednesday_rush_hour_start,
        wednesday_rush_hour_end: this.state.wednesday_rush_hour_end,
        wednesday_rush_hour_order_lead_time:
          this.state.wednesday_rush_hour_order_lead_time,
        wednesday_rush_hour_delivery_order_lead_time:
          this.state.wednesday_rush_hour_delivery_order_lead_time,

        thursday_rush_hour_enabled: this.state.thursday_rush_hour_enabled,
        thursday_rush_hour_start: this.state.thursday_rush_hour_start,
        thursday_rush_hour_end: this.state.thursday_rush_hour_end,
        thursday_rush_hour_order_lead_time:
          this.state.thursday_rush_hour_order_lead_time,
        thursday_rush_hour_delivery_order_lead_time:
          this.state.thursday_rush_hour_delivery_order_lead_time,

        friday_rush_hour_enabled: this.state.friday_rush_hour_enabled,
        friday_rush_hour_start: this.state.friday_rush_hour_start,
        friday_rush_hour_end: this.state.friday_rush_hour_end,
        friday_rush_hour_order_lead_time:
          this.state.friday_rush_hour_order_lead_time,
        friday_rush_hour_delivery_order_lead_time:
          this.state.friday_rush_hour_delivery_order_lead_time,

        saturday_rush_hour_enabled: this.state.saturday_rush_hour_enabled,
        saturday_rush_hour_start: this.state.saturday_rush_hour_start,
        saturday_rush_hour_end: this.state.saturday_rush_hour_end,
        saturday_rush_hour_order_lead_time:
          this.state.saturday_rush_hour_order_lead_time,
        saturday_rush_hour_delivery_order_lead_time:
          this.state.saturday_rush_hour_delivery_order_lead_time,

        sunday_rush_hour_enabled: this.state.sunday_rush_hour_enabled,
        sunday_rush_hour_start: this.state.sunday_rush_hour_start,
        sunday_rush_hour_end: this.state.sunday_rush_hour_end,
        sunday_rush_hour_order_lead_time:
          this.state.sunday_rush_hour_order_lead_time,
        sunday_rush_hour_delivery_order_lead_time:
          this.state.sunday_rush_hour_delivery_order_lead_time,
      },
      () => {
        this.props.handleViewSettings();
      }
    );
  }

  handleMondayRushHourStartChange(event) {
    this.setState({ monday_rush_hour_start: event.target.value });
  }

  handleMondayRushHourEndChange(event) {
    this.setState({ monday_rush_hour_end: event.target.value });
  }

  handleMondayRushHourOrderLeadTimeChange(event) {
    this.setState({ monday_rush_hour_order_lead_time: event.target.value });
  }

  handleMondayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      monday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleTuesdayRushHourStartChange(event) {
    this.setState({ tuesday_rush_hour_start: event.target.value });
  }

  handleTuesdayRushHourEndChange(event) {
    this.setState({ tuesday_rush_hour_end: event.target.value });
  }

  handleTuesdayRushHourOrderLeadTimeChange(event) {
    this.setState({ tuesday_rush_hour_order_lead_time: event.target.value });
  }

  handleTuesdayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      tuesday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleWednesdayRushHourStartChange(event) {
    this.setState({ wednesday_rush_hour_start: event.target.value });
  }

  handleWednesdayRushHourEndChange(event) {
    this.setState({ wednesday_rush_hour_end: event.target.value });
  }

  handleWednesdayRushHourOrderLeadTimeChange(event) {
    this.setState({ wednesday_rush_hour_order_lead_time: event.target.value });
  }

  handleWednesdayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      wednesday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleThursdayRushHourStartChange(event) {
    this.setState({ thursday_rush_hour_start: event.target.value });
  }

  handleThursdayRushHourEndChange(event) {
    this.setState({ thursday_rush_hour_end: event.target.value });
  }

  handleThursdayRushHourOrderLeadTimeChange(event) {
    this.setState({ thursday_rush_hour_order_lead_time: event.target.value });
  }

  handleThursdayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      thursday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleFridayRushHourStartChange(event) {
    this.setState({ friday_rush_hour_start: event.target.value });
  }

  handleFridayRushHourEndChange(event) {
    this.setState({ friday_rush_hour_end: event.target.value });
  }

  handleFridayRushHourOrderLeadTimeChange(event) {
    this.setState({ friday_rush_hour_order_lead_time: event.target.value });
  }

  handleFridayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      friday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleSaturdayRushHourStartChange(event) {
    this.setState({ saturday_rush_hour_start: event.target.value });
  }

  handleSaturdayRushHourEndChange(event) {
    this.setState({ saturday_rush_hour_end: event.target.value });
  }

  handleSaturdayRushHourOrderLeadTimeChange(event) {
    this.setState({ saturday_rush_hour_order_lead_time: event.target.value });
  }

  handleSaturdayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      saturday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  handleSundayRushHourStartChange(event) {
    this.setState({ sunday_rush_hour_start: event.target.value });
  }

  handleSundayRushHourEndChange(event) {
    this.setState({ sunday_rush_hour_end: event.target.value });
  }

  handleSundayRushHourOrderLeadTimeChange(event) {
    this.setState({ sunday_rush_hour_order_lead_time: event.target.value });
  }

  handleSundayRushHourDeliveryOrderLeadTimeChange(event) {
    this.setState({
      sunday_rush_hour_delivery_order_lead_time: event.target.value,
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { restaurant, handleViewSettings, handleViewRestaurants } =
      this.props;

    if (restaurant === undefined) {
      return <></>;
    }
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                <i className="fas fa-map-marker-alt" />
                {` `}
                <span className="d-none d-sm-inline">Restaurants</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-home" />
                {` `}
                <span className="d-none d-sm-inline">{restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="fa fa-clock" />
                {` `}
                <span className="d-none d-sm-inline">{`Rush Hour`}</span>
              </button>
            </li>
          </ol>
        </nav>

        {/* <form onSubmit={handleSubmit(this.handleFormSubmit)}> */}
        {this.renderAlert()}

        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Monday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            monday_rush_hour_enabled:
                              !prevState.monday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.monday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="monday_rush_hour_start"
                      disabled={this.state.monday_rush_hour_enabled === false}
                      value={this.state.monday_rush_hour_start}
                      onChange={this.handleMondayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="monday_rush_hour_end"
                      disabled={this.state.monday_rush_hour_enabled === false}
                      value={this.state.monday_rush_hour_end}
                      onChange={this.handleMondayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="monday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.monday_rush_hour_enabled === false}
                      value={this.state.monday_rush_hour_order_lead_time}
                      onChange={this.handleMondayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="monday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.monday_rush_hour_enabled === false}
                      value={
                        this.state.monday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleMondayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Tuesday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            tuesday_rush_hour_enabled:
                              !prevState.tuesday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.tuesday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="tuesday_rush_hour_start"
                      disabled={this.state.tuesday_rush_hour_enabled === false}
                      value={this.state.tuesday_rush_hour_start}
                      onChange={this.handleTuesdayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="tuesday_rush_hour_end"
                      disabled={this.state.tuesday_rush_hour_enabled === false}
                      value={this.state.tuesday_rush_hour_end}
                      onChange={this.handleTuesdayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="tuesday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.tuesday_rush_hour_enabled === false}
                      value={this.state.tuesday_rush_hour_order_lead_time}
                      onChange={this.handleTuesdayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="tuesday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.tuesday_rush_hour_enabled === false}
                      value={
                        this.state.tuesday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleTuesdayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Wednesday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            wednesday_rush_hour_enabled:
                              !prevState.wednesday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.wednesday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="wednesday_rush_hour_start"
                      disabled={
                        this.state.wedensday_rush_hour_enabled === false
                      }
                      value={this.state.wednesday_rush_hour_start}
                      onChange={this.handleWednesdayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="wednesday_rush_hour_end"
                      disabled={
                        this.state.wedensday_rush_hour_enabled === false
                      }
                      value={this.state.wednesday_rush_hour_end}
                      onChange={this.handleWednesdayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="wednesday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={
                        this.state.wednesday_rush_hour_enabled === false
                      }
                      value={this.state.wednesday_rush_hour_order_lead_time}
                      onChange={this.handleWednesdayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="wednesday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={
                        this.state.wednesday_rush_hour_enabled === false
                      }
                      value={
                        this.state.wednesday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleWednesdayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Thursday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            thursday_rush_hour_enabled:
                              !prevState.thursday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.thursday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="thursday_rush_hour_start"
                      value={this.state.thursday_rush_hour_start}
                      disabled={this.state.thursday_rush_hour_enabled === false}
                      onChange={this.handleThursdayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="thursday_rush_hour_end"
                      disabled={this.state.thursday_rush_hour_enabled === false}
                      value={this.state.thursday_rush_hour_end}
                      onChange={this.handleThursdayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="monday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.thursday_rush_hour_enabled === false}
                      value={this.state.thursday_rush_hour_order_lead_time}
                      onChange={this.handleThursdayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="thursday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.thursday_rush_hour_enabled === false}
                      value={
                        this.state.thursday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleThursdayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Friday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            friday_rush_hour_enabled:
                              !prevState.friday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.friday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="friday_rush_hour_start"
                      value={this.state.friday_rush_hour_start}
                      disabled={this.state.friday_rush_hour_enabled === false}
                      onChange={this.handleFridayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="friday_rush_hour_end"
                      value={this.state.friday_rush_hour_end}
                      disabled={this.state.friday_rush_hour_enabled === false}
                      onChange={this.handleFridayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="friday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.friday_rush_hour_enabled === false}
                      value={this.state.friday_rush_hour_order_lead_time}
                      onChange={this.handleFridayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="friday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.friday_rush_hour_enabled === false}
                      value={
                        this.state.friday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleFridayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Saturday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            saturday_rush_hour_enabled:
                              !prevState.saturday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.saturday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="saturday_rush_hour_start"
                      disabled={this.state.saturday_rush_hour_enabled === false}
                      value={this.state.saturday_rush_hour_start}
                      onChange={this.handleSaturdayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="saturday_rush_hour_end"
                      disabled={this.state.saturday_rush_hour_enabled === false}
                      value={this.state.saturday_rush_hour_end}
                      onChange={this.handleSaturdayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="saturday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.saturday_rush_hour_enabled === false}
                      value={this.state.saturday_rush_hour_order_lead_time}
                      onChange={this.handleSaturdayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="saturday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.saturday_rush_hour_enabled === false}
                      value={
                        this.state.saturday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleSaturdayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card">
              <div className="card-header text-center">{`Sunday`}</div>
              <div className="card-body">
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Enabled`}
                  </label>
                  <div className="col-sm-6">
                    <Switch
                      className="react-switch"
                      onChange={() => {
                        this.setState((prevState) => {
                          return {
                            sunday_rush_hour_enabled:
                              !prevState.sunday_rush_hour_enabled,
                          };
                        });
                      }}
                      checked={this.state.sunday_rush_hour_enabled || false}
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            paddingRight: 2,
                          }}
                        ></div>
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour Start`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="sunday_rush_hour_start"
                      value={this.state.sunday_rush_hour_start}
                      disabled={this.state.sunday_rush_hour_enabled === false}
                      onChange={this.handleSundayRushHourStartChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Rush Hour End`}
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      name="sunday_rush_hour_end"
                      value={this.state.sunday_rush_hour_end}
                      disabled={this.state.sunday_rush_hour_enabled === false}
                      onChange={this.handleSundayRushHourEndChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Pickup Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="sunday_rush_hour_order_lead_time"
                      className="form-control"
                      disabled={this.state.sunday_rush_hour_enabled === false}
                      value={this.state.sunday_rush_hour_order_lead_time}
                      onChange={this.handleSundayRushHourOrderLeadTimeChange}
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-form-label col-sm-6 text-sm-right">
                    {`Delivery Order Lead Time`}
                  </label>
                  <div className="col-sm-6">
                    <select
                      name="sunday_rush_hour_delivery_order_lead_time"
                      className="form-control"
                      disabled={this.state.sunday_rush_hour_enabled === false}
                      value={
                        this.state.sunday_rush_hour_delivery_order_lead_time
                      }
                      onChange={
                        this.handleSundayRushHourDeliveryOrderLeadTimeChange
                      }
                    >
                      {leadTimes.map((leadTime) => (
                        <option
                          value={`${leadTime}`}
                        >{`${leadTime} Minutes`}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={this.props.handleViewSettings}
            >
              {`Cancel`}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleFormSubmit}
            >
              {"Save"}
            </button>
          </div>
        </div>

        {/* </form> */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSettings: (restaurantId, formProps, callback) =>
    dispatch(updateSettings(restaurantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(RushHourSettings));
