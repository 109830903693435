import {
  FETCH_GIFT_CARD,
  FETCH_GIFT_CARD_REDEMPTIONS,
  FETCH_GIFT_CARDS,
  GIFT_CARD_ERROR,
  REMOVE_GIFT_CARD_REDEMPTION,
  REDEEM_GIFT_CARD,
} from "./types";

import {  getData, deleteData, postData } from "./index";

export function fetchGiftCard(tenantId, giftCardNumber) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/giftcards/${giftCardNumber}`;
  return (dispatch) =>
    getData(FETCH_GIFT_CARD, GIFT_CARD_ERROR, true, url, dispatch);
}

export function fetchGiftCards(
  tenantId,
  email,
  phoneNumber,
  giftCardNumber
) {
  const queryParams = [];
  if (email) {
    queryParams.push(`email=${email}`);
  }
  if (phoneNumber) {
    queryParams.push(`phoneNumber=${phoneNumber}`);
  }
  if (giftCardNumber) {
    queryParams.push(`giftCardNumber=${giftCardNumber}`);
  }

  const queryString = queryParams.join("&");

  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/giftcards` +
    (queryParams.length > 0 ? `?${queryString}` : "");

  return (dispatch) =>
    getData(FETCH_GIFT_CARDS, GIFT_CARD_ERROR, true, url, dispatch);
}

export function fetchGiftCardRedemptions(tenantId, giftCardNumber) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/giftcards/${giftCardNumber}/redemptions`;
  return (dispatch) =>
    getData(FETCH_GIFT_CARD_REDEMPTIONS, GIFT_CARD_ERROR, true, url, dispatch);
}

export function removeGiftCardRedemption(tenantId, giftCardRedemptionId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/giftcardredemptions/${giftCardRedemptionId}`;

  return (dispatch) =>
    deleteData(
      REMOVE_GIFT_CARD_REDEMPTION,
      GIFT_CARD_ERROR,
      false,
      url,
      dispatch
    );
}

export function redeemGiftCard(
  tenantId,
  giftCardNumber,
  amountToRedeem,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/giftcards/${giftCardNumber}/redeem`;
  const data = {
    amountToRedeem: amountToRedeem,
  };
  return (dispatch) =>
    postData(
      REDEEM_GIFT_CARD,
      GIFT_CARD_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}
