import React from "react";
import Numeral from "numeral";
import MenuItemTags from "./MenuItemTags";

import spicy_mild_img from "../../assets/spicy_mild_16.png";
import spicy_medium_img from "../../assets/spicy_medium_16.png";

import moment from "moment-timezone";

import sold_out_img from "../../assets/sold_out_48_32.png";

const MenuItemListView = ({
  tenant,
  restaurant,
  menu,
  section,
  items = [],
  handleViewMenuItem,
}) => {
  return items.map((menuItem) => (
    <React.Fragment key={menuItem._id}>
      <div
        className="row clickable-row"
        onClick={() => handleViewMenuItem(menuItem)}
      >
        <div className="col-12 col-lg-2">
          {menuItem.photos &&
            menuItem.photos.map((itemPhoto, index) => (
              <img
                key={index}
                className="img-fluid mb-2"
                src={
                  process.env.REACT_APP_S3_BASE_URL +
                  `${tenant.slug}/${itemPhoto.url}`
                }
                alt="Menu Item"
              />
            ))}
        </div>
        <div className="col-12 text-center col-lg-6 text-lg-left">
          <div>
            {menuItem.cd} {menuItem.cd ? "." : ""} {menuItem.name}
          </div>
          <div>
            <small className="text-muted">{menuItem.description} </small>
          </div>
          {menuItem && menuItem.tags && <MenuItemTags tags={menuItem.tags} />}
          {menuItem &&
            menuItem.items &&
            menuItem.items.map((item, index) => (
              <div key={index}>
                <small className="text-muted">{item.name}</small>
                {item.spicy && item.spicy === "mild" && (
                  <img src={spicy_mild_img} alt="" />
                )}
                {item.spicy && item.spicy === "medium" && (
                  <img src={spicy_medium_img} alt="" />
                )}
                {item.spicy && item.spicy === "hot" && (
                  <>
                    <img src={spicy_medium_img} alt="" />
                    <img src={spicy_medium_img} alt="" />
                  </>
                )}
              </div>
            ))}
          {menuItem.sold_out_until &&
            moment(menuItem.sold_out_until)
              .tz(restaurant.settings.time_zone)
              .isAfter(moment().tz(restaurant.settings.time_zone)) && (
              <div className="alert alert-warning">
                <img src={sold_out_img} alt={"Sold out"} className="mr-3" />
                <strong>{`Sold out until ${moment(menuItem.sold_out_until)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}`}</strong>
              </div>
            )}
        </div>
        <div className="col-12 text-center col-lg-4 text-lg-right">
          <div className="card-text">
            {menuItem.price && (
              <small className="text-muted">
                <strong>{Numeral(menuItem.price).format("$0,0.00")}</strong>
                {` `}
              </small>
            )}

            {menuItem.sizes && (
              <>
                {menuItem.sizes.map((itemSize) => (
                  <div className="row" key={itemSize._id}>
                    <div className="col-12 text-center text-lg-right">
                      <small key={itemSize._id} className="text-muted">
                        <strong>
                          {itemSize.name} :{` `}
                          {Numeral(itemSize.price).format("$0,0.00")}
                          {` `}
                        </strong>
                      </small>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <hr />
        </div>
      </div>
    </React.Fragment>
  ));
};

export default MenuItemListView;
