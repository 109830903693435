import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchEmployees } from "../../actions/employee";
import * as Icon from "react-feather";
import * as FaIcons from "react-icons/fa";

import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";

import nophoto from "../../assets/nophoto.png";

class Employees extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleView = this.handleView.bind(this);

    this.state = {
      mode: "View",
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchEmployees(this.props.tenant._id);
    }
  }

  handleAdd() {
    this.setState({ mode: "Add" });
  }

  handleDelete(workerId) {
    this.props.deleteWorker(workerId, () => {});
  }

  handleEdit(user) {
    this.setState({ mode: "Edit", user: user });
  }

  handleView() {
    this.setState({ mode: "View" });
  }

  render() {
    const { tenant, users } = this.props;

    if (tenant === undefined) {
      return <></>;
    }
    if (this.state.mode === "Add") {
      return <AddEmployee handleView={this.handleView} />;
    }
    if (this.state.mode === "Edit") {
      return (
        <EditEmployee handleView={this.handleView} user={this.state.user} />
      );
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <FaIcons.FaUsersCog />
                {` `}
                <span>{`Employees`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="d-flex justify-content-end mb-3 border-bottom">
          <div className="btn-toolbar mb-2">
            <div className="btn-group mr-2">
              {/* <WorkerAddModal /> */}
              <button className="btn btn-primary" onClick={this.handleAdd}>
                <Icon.Plus size={16} />
                {` `}New Employee
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {users &&
            users
              .filter((user) => user.role !== "Owner")
              .map((user, index) => (
                <div className="col-12 col-sm-6 col-lg-4 mb-3" key={user._id}>
                  <div className="card mt-3 pb-3">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-4">
                          <h5>{user.userName}</h5>
                        </div>
                        <div className="col-8 d-flex justify-content-end">
                          <div className="btn-toolbar">
                            <div className="btn-group-sm mr-2">
                              <button
                                className="btn btn-outline-secondary mr-2"
                                onClick={() => this.handleEdit(user)}
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Edit"
                              >
                                <Icon.Edit2 size={16} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body text-center">
                      {user.image && (
                        <img
                          className="rounded-circle"
                          src={
                            process.env.REACT_APP_S3_BASE_URL +
                            `${encodeURIComponent(
                              tenant.slug + "/" + user.image.url
                            )}`
                          }
                          alt="User"
                        />
                      )}
                      {!user.image && 
                      <img className="rounded-circle" src={nophoto} alt="Nophoto" />}

                      <div className="row">
                        <div className="col">
                          {user.firstName}
                          {` `}
                          {user.lastName}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">{user.role}</div>
                      </div>
                      <div className="row">
                        <div className="col">{user.phone}</div>
                      </div>
                      <div className="row">
                        <div className="col">{user.email}</div>
                      </div>
                      <div className="row">
                        <div className="col">{user.telephone}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    users: state.employee.users || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEmployees: (tenantId) => dispatch(fetchEmployees(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
