import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import * as Icon from "react-feather";
import moment from "moment-timezone";
import Numeral from "numeral";

import RedeemGiftCard from "./RedeemGiftCard";

import {
  fetchGiftCardRedemptions,
  fetchGiftCard,
  removeGiftCardRedemption,
} from "../../actions/giftcard";

class GiftCardDetail extends Component {
  constructor(props) {
    super(props);
    this.handleViewGiftCards = this.handleViewGiftCards.bind(this);

    this.handleDeleteRedemption = this.handleDeleteRedemption.bind(this);

    this.state = {
      redirectTo: undefined,
    };
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchGiftCard(
        this.props.tenant._id,
        this.props.match.params.giftCardNumber
      );
      // this.props.fetchGiftCardRedemptions(
      //   this.props.tenant._id,
      //   this.props.match.params.giftCardNumber
      // );
    }
  }

  handleViewGiftCards() {
    this.setState({ redirectTo: "/giftcards" });
  }

  handleDeleteRedemption(giftCardRedemptionId) {
    this.props.removeGiftCardRedemption(
      this.props.tenant._id,
      giftCardRedemptionId
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { giftCard, giftCardRedemptions, tenant } = this.props;

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (!giftCard) {
      return <></>;
    }

    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={this.handleViewGiftCards}
              >
                Gift Cards
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <span className="text-uppercase">
                  {giftCard.giftCardNumber}
                </span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row mb-3">
          <div className="col-1">
            <button
              type="button"
              className="btn btn-light"
              onClick={this.handleViewGiftCards}
            >
              <Icon.ChevronLeft size={24} />
            </button>
          </div>
          <div className="col-11 text-right">
            <div className="btn-group"></div>
          </div>
        </div>
        {this.renderAlert()}
        <div className="card mb-3">
          <div className="card-body">
            <div className="row pb-3">
              <div className="col-4">
                <strong>Gift Card Number: </strong>
                <span>{giftCard.giftCardNumber}</span>
              </div>
              <div className="col-4">
                <strong>Pending Balance: </strong>
                <span>
                  {Numeral(giftCard.pendingBalance).format("$0,0.00")}
                </span>
              </div>
              <div className="col-4">
                <strong>Balance: </strong>
                <span>{Numeral(giftCard.balance).format("$0,0.00")}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <strong>Customer: </strong>
                <span>{giftCard.nameOrderedUnder}</span>
              </div>
              <div className="col-4">
                <strong>Phone: </strong>
                <span>{giftCard.phone}</span>
              </div>
              <div className="col-4">
                <strong>Email: </strong>
                <span>{giftCard.email}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">{"Redemptions"}</div>
              <div className="col-6 text-right">
                <RedeemGiftCard giftCard={giftCard} />
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row pb-3 border-bottom">
              <div className="col-2">
                <strong>Order Number</strong>
              </div>
              <div className="col-2 text-right">
                <strong>Amount Redeemed</strong>
              </div>
              <div className="col-2">
                <strong>Status</strong>
              </div>
              <div className="col-2">
                <strong>Created At</strong>
              </div>
              <div className="col-2">
                <strong>Updated At</strong>
              </div>
              <div className="col-2">
                <strong></strong>
              </div>
            </div>
            {giftCardRedemptions.map((giftCardRedemption) => (
              <div
                className="row pt-3 pb-3 border-bottom"
                key={giftCardRedemption._id}
              >
                <div className="col-2">{giftCardRedemption.orderNumber}</div>
                <div className="col-2 text-right">
                  {Numeral(giftCardRedemption.amountToRedeem).format("$0,0.00")}
                </div>
                <div className="col-2">{giftCardRedemption.status}</div>
                <div className="col-2">
                  {moment(giftCardRedemption.createdAt)
                    .tz(tenant.settings.time_zone)
                    .format(tenant.settings.datetime_format)}
                </div>
                <div className="col-2">
                  {moment(giftCardRedemption.updatedAt)
                    .tz(tenant.settings.time_zone)
                    .format(tenant.settings.datetime_format)}
                </div>
                <div className="col-2">
                  {!giftCardRedemption.orderNumber &&
                    giftCardRedemption.status === "Pending" && (
                      <button
                        className="btn btn-danger btn-sm btn-sm-angkor"
                        onClick={() =>
                          this.handleDeleteRedemption(giftCardRedemption._id)
                        }
                      >
                        <Icon.X size={16} />
                      </button>
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    giftCardRedemptions: state.giftCard.giftCardRedemptions,
    giftCard: state.giftCard.giftCard,
    tenant: state.tenant.tenant,
    errorMessage: state.giftCard.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCard: (tenantId, giftCardNumber) =>
    dispatch(fetchGiftCard(tenantId, giftCardNumber)),
  fetchGiftCardRedemptions: (tenantId, giftCardNumber) =>
    dispatch(fetchGiftCardRedemptions(tenantId, giftCardNumber)),
  removeGiftCardRedemption: (tenantId, giftCardRedemptionId) =>
    dispatch(removeGiftCardRedemption(tenantId, giftCardRedemptionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardDetail);
