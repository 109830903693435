import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { addRestaurant } from "../../actions/tenant";

import {
  renderEmailField,
  renderPhoneField,
  renderField,
} from "../../utils/renderFields";
import normalizePhone from "../../utils/normalizePhone";

const form = reduxForm({
  form: "restaurantAddModal",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter restaurant name";
  }

  return errors;
}

class AddRestaurant extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    if (this.props.tenant) {
      this.props.addRestaurant(this.props.tenant._id, formProps, () => {
        this.props.handleViewRestaurants();
      });
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewRestaurants, handleSubmit } = this.props;
    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewRestaurants}
              >
                {`Restaurants`}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                {`New Restaurant`}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group">
            <label className="required">
              Restaurant Name (2 ~ 255 characters){" "}
            </label>

            <Field
              name="name"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label className="required">Store Number (4 characters)</label>

            <Field
              name="store_number"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>

          <div className="form-group">
            <label className="required">
              Brief Description (2 ~ 255 characters)
            </label>

            <Field
              name="brief_description"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Full Description (Maximum 2000 characters)</label>

            <Field
              name="full_description"
              className="form-control"
              component="textarea"
            />
          </div>
          <div className="form-group">
            <label className="required">
              Street Address (2 ~ 120 characters)
            </label>

            <Field
              name="address_1"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label>Address 2</label>

            <Field
              name="address_2"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label className="required">City (2 ~ 50 characters)</label>

            <Field
              name="city_town"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label className="required">Province</label>

            <Field
              name="state_province"
              className="form-control"
              component="select"
            >
              <option />
              <option value="SK">Saskatchewan</option>
              <option value="AB">Alberta</option>
            </Field>
          </div>
          <div className="form-group">
            <label className="required">Postal Code</label>

            <Field
              name="postal_code"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
          <div className="form-group">
            <label className="required">Country</label>

            <Field name="country" className="form-control" component="select">
              <option />
              <option value="CA">Canada</option>
              <option value="US">United States</option>
            </Field>
          </div>
          <div className="form-group">
            <label className="required">Latitude</label>

            <Field
              name="latitude"
              className="form-control"
              component={renderField}
              type="number"
            />
          </div>

          <div className="form-group">
            <label className="required">Longitude</label>

            <Field
              name="longitude"
              className="form-control"
              component={renderField}
              type="number"
            />
          </div>
          <div className="form-group">
            <label className="required">Email</label>

            <Field
              name="email"
              className="form-control"
              component={renderEmailField}
              type="email"
            />
          </div>

          <div className="form-group">
            <label className="required">Phone</label>

            <Field
              name="phone"
              className="form-control"
              component={renderPhoneField}
              type="text"
              normalize={normalizePhone}
            />
          </div>

          <div className="form-group">
            <label>Fax</label>

            <Field
              name="fax"
              className="form-control"
              component={renderPhoneField}
              type="text"
              normalize={normalizePhone}
            />
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewRestaurants}
              >
                {`Cancel`}
              </button>
              <button type="submit" className="btn btn-primary">
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.tenant.errorMessage,
    message: state.tenant.message,
    tenant: state.tenant.tenant,
    initialValues: {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  addRestaurant: (tenantId, formProps, callback) =>
    dispatch(addRestaurant(tenantId, formProps, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(AddRestaurant));
