import React, { Component } from "react";

import { EligibleItemsModal } from "./EligibleItemsModal";


class PercentOnSelectedItemsPromoBenefit extends Component {
  render() {
    const {
      sectionNumber,
      menus,
      eligibleItemsGroup1,
      handleEligibleItemsGroup1Change,
      discountOnItemsGroup1,
      handleDiscountOnItemsGroup1Change,
      extraChargeOption,
      handleExtraChargeOptionChange
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Set promo deal benefit (What's in it for your client)
              </strong>
        </div>
        <div className="card-body">
          <div className="pb-3">
            <strong>{`Grant a discount for certain items or categories`}</strong>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">
              Set which items are eligible:
                </strong>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">Items Group 1</label>
                <EligibleItemsModal
                  name="eligible_items_group_1"
                  type="text"
                  selectedItems={eligibleItemsGroup1}
                  menus={menus}
                  handleCheck={handleEligibleItemsGroup1Change}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              {`Select one or more items. When clients add an item from your selection to the cart, the discount set below will be applied automatically.`}
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">Set the benefit:</strong>
            <div className="col-12">
              <div className="form-group">
                <label className="col-form-label">
                  Items Group 1:
                    </label>
                <div className="input-group col-2">
                  <input
                    name="discount_on_items_group_1"
                    type="number"
                    className="form-control"
                    value={discountOnItemsGroup1}
                    onChange={handleDiscountOnItemsGroup1Change}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>         
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12 ">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="No extra charges"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption === "No extra charges"
                  }
                />
                <label className="form-check-label">
                  {`No extra charges `}
                  <br />
                  <small>{`Any "Choices / Addons" or "Sizes" selection will not increase the price.`}</small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="Charge extra for 'Choices/Addons'"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption ===
                    "Charge extra for 'Choices/Addons'"
                  }
                />
                <label className="form-check-label">
                  {`Charge extra for "Choices / Addons" `}
                  <br />
                  <small>{`Only "Choices / Addons" will preserve their prices (if any) unaffected of the promo logic.`}</small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="extra_charge_option"
                  value="Charge extra for 'Choices/Addons' & 'Sizes'"
                  onChange={handleExtraChargeOptionChange}
                  checked={
                    extraChargeOption ===
                    "Charge extra for 'Choices/Addons' & 'Sizes'"
                  }
                />
                <label className="form-check-label">
                  {`Charge extra for "Choices / Addons" `}
                  <br />
                  <small>{`Only "Choices / Addons" will preserve their prices (if any) unaffected of the promo logic.`}</small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PercentOnSelectedItemsPromoBenefit;