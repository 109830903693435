import { getData, patchData, deleteData, postData } from "./index";
import {
  UPDATE_MENU_INFO,
  UPDATE_MENU_SECTION_INFO,
  UPDATE_MENU_ITEM_INFO,
  FETCH_MENU,
  FETCH_MENUS,
  MENU_ERROR,
  FETCH_MENU_SECTION,
  FETCH_MENU_ITEM,
  ADD_MENU,
  ADD_MENU_SECTION,
  ADD_MENU_ITEM,
  ADD_MENU_ITEM_SIZE,
  UPDATE_MENU_ITEM_SIZE,
  FETCH_MENU_ITEM_SIZE,
  REMOVE_MENU_ITEM_SIZE,
  ADD_MENU_ITEM_ITEM,
  UPDATE_MENU_ITEM_ITEM,
  REMOVE_MENU_ITEM_ITEM,
  ADD_MENU_ITEM_OPTION,
  UPDATE_MENU_ITEM_OPTION,
  FETCH_MENU_ITEM_OPTION,
  REMOVE_MENU_ITEM_OPTION,
  ARCHIVE_MENU,
  UNARCHIVE_MENU,
  ARCHIVE_MENU_SECTION,
  UNARCHIVE_MENU_SECTION,
  ARCHIVE_MENU_ITEM,
  UNARCHIVE_MENU_ITEM,
  PUBLISH_MENU,
  MOVE_MENU_ITEM,
  ADD_MENU_ITEM_PHOTO,
  REMOVE_MENU_ITEM_PHOTO,
  UPDATE_MENU_ITEM_PHOTO,
  UPDATE_MENU_SECTION_PHOTO,
  UPDATE_MENU_PHOTO,
  REMOVE_MENU_PHOTO,
  REMOVE_MENU_SECTION_PHOTO,
  MARK_MENU_ITEM_AS_SOLD_OUT,
  FETCH_ARCHIVED_MENUS,
  UPDATE_ASSIGNED_LOCATIONS,
  UPDATE_MENU_SECTION_ASSIGNED_LOCATIONS,
  UPDATE_MENU_ITEM_ASSIGNED_LOCATIONS,
  MARK_MENU_ITEM_AS_AVAILABLE,
} from "./types";

export function fetchMenus(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/menus`;
  return (dispatch) => getData(FETCH_MENUS, MENU_ERROR, true, url, dispatch);
}

export function fetchArchived(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/archived`;
  return (dispatch) =>
    getData(FETCH_ARCHIVED_MENUS, MENU_ERROR, true, url, dispatch);
}

export function viewMenus(callback) {
  return function (dispatch) {
    callback(dispatch);
  };
}

export function fetchMenu(menuId) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}`;
  return (dispatch) => getData(FETCH_MENU, MENU_ERROR, false, url, dispatch);
}

export function viewMenu(callback) {
  return function (dispatch) {
    callback(dispatch);
  };
}

export function fetchMenuSection(menuId, menuSectionId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}`;
  return (dispatch) =>
    getData(FETCH_MENU_SECTION, MENU_ERROR, false, url, dispatch);
}

export function fetchMenuItem(menuId, menuSectionId, menuItemId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}`;
  return (dispatch) =>
    getData(FETCH_MENU_ITEM, MENU_ERROR, false, url, dispatch);
}

export function addMenu(
  tenantId,
  {
    name,
    description,
    note,
    available_for,
    duration,
    display_order,
    online_ordering,
    hidden_from_public,
  },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/menus/`;
  let data = {
    name: name,
    description: description,
    note: note,
    available_for: available_for,
    display_order: display_order,
    duration: duration,
    photoUrl: photoUrl,
    online_ordering: online_ordering,
    hidden_from_public: hidden_from_public,
  };

  return (dispatch) =>
    postData(ADD_MENU, MENU_ERROR, true, url, dispatch, data, callback);
}

export function addMenuSection(
  menuId,
  { name, description, note, display_order, hidden_from_public },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/sections`;

  let data = {
    name: name,
    description: description,
    note: note,
    display_order: display_order,
    photoUrl: photoUrl,
    hidden_from_public: hidden_from_public,
  };

  return (dispatch) =>
    postData(ADD_MENU_SECTION, MENU_ERROR, true, url, dispatch, data, callback);
}

export function addMenuItem(
  menuId,
  menuSectionId,
  {
    name,
    printName,
    price,
    description,
    tag_spicy,
    tag_special,
    tag_vegetarian,
    tag_gluten_free,
    tag_new,
    cd,
    display_order,
    type,
    hidden_from_public,
  },
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items`;
  let tags = [];
  if (tag_spicy) {
    tags.push(tag_spicy);
  }
  if (tag_special) {
    tags.push("special");
  }
  if (tag_vegetarian) {
    tags.push("vegetarian");
  }
  if (tag_gluten_free) {
    tags.push("gluten_friendly");
  }
  if (tag_new) {
    tags.push("new");
  }
  let data = {
    name: name,
    printName: printName,
    description: description,
    price: price,
    tags: tags,
    cd: cd,
    type: type,
    display_order: display_order,
    hidden_from_public: hidden_from_public,
  };

  return (dispatch) =>
    postData(ADD_MENU_ITEM, MENU_ERROR, true, url, dispatch, data, callback);
}

export function updateMenuInfo(
  {
    _id,
    name,
    description,
    note,
    available_for,
    duration,
    display_order,
    online_ordering,
    hidden_from_public,
  },
  photoUrl,
  callback
) {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/menus/${_id}`;

  let data = photoUrl
    ? {
        name: name,
        description: description,
        note: note,
        available_for: available_for,
        duration: duration,
        display_order: display_order,
        online_ordering: online_ordering,
        photoUrl: photoUrl,
        hidden_from_public: hidden_from_public,
      }
    : {
        name: name,
        description: description,
        note: note,
        available_for: available_for,
        duration: duration,
        display_order: display_order,
        online_ordering: online_ordering,
        hidden_from_public: hidden_from_public,
      };
  return (dispatch) =>
    patchData(
      UPDATE_MENU_INFO,
      MENU_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function archiveMenu(menuId, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/disable`;
  return (dispatch) =>
    patchData(ARCHIVE_MENU, MENU_ERROR, true, url, dispatch, {}, callback);
}

export function publishMenu(menuId, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/publish`;
  return (dispatch) =>
    patchData(PUBLISH_MENU, MENU_ERROR, true, url, dispatch, {}, callback);
}

export function unarchiveMenu(menuId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/enable`;
  return (dispatch) =>
    patchData(UNARCHIVE_MENU, MENU_ERROR, true, url, dispatch, {});
}

export function archiveMenuSection(menuId, sectionId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${sectionId}/disable`;
  return (dispatch) =>
    patchData(
      ARCHIVE_MENU_SECTION,
      MENU_ERROR,
      true,
      url,
      dispatch,
      {},
      callback
    );
}

export function unarchiveMenuSection(menuId, sectionId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${sectionId}/enable`;
  return (dispatch) =>
    patchData(UNARCHIVE_MENU_SECTION, MENU_ERROR, true, url, dispatch, {});
}

export function archiveMenuItem(menuId, sectionId, itemId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${sectionId}/items/${itemId}/disable`;
  return (dispatch) =>
    patchData(ARCHIVE_MENU_ITEM, MENU_ERROR, true, url, dispatch, {}, callback);
}

export function unarchiveMenuItem(menuId, sectionId, itemId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${sectionId}/items/${itemId}/enable`;
  return (dispatch) =>
    patchData(UNARCHIVE_MENU_ITEM, MENU_ERROR, true, url, dispatch, {});
}

export function updateMenuSectionInfo(
  menuId,
  menuSectionId,
  { name, description, note, display_order, hidden_from_public },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}`;

  let data = photoUrl
    ? {
        name: name,
        description: description,
        note: note,
        display_order: display_order,
        photoUrl: photoUrl,
        hidden_from_public: hidden_from_public,
      }
    : {
        name: name,
        description: description,
        note: note,
        display_order: display_order,
        hidden_from_public: hidden_from_public,
      };

  return (dispatch) =>
    patchData(
      UPDATE_MENU_SECTION_INFO,
      MENU_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateMenuItemInfo(
  menuId,
  menuSectionId,
  menuItemId,
  {
    name,
    printName,
    price,
    description,
    tag_spicy,
    tag_special,
    tag_vegetarian,
    tag_gluten_free,
    tag_new,
    image,
    display_order,
    cd,
    type,
    hidden_from_public,
  },
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}`;
  let tags = [];
  if (tag_spicy) {
    tags.push(tag_spicy);
  }
  if (tag_special) {
    tags.push("special");
  }
  if (tag_vegetarian) {
    tags.push("vegetarian");
  }
  if (tag_gluten_free) {
    tags.push("gluten_friendly");
  }
  if (tag_new) {
    tags.push("new");
  }
  let formData = new FormData();
  if (name) {
    formData.append("name", name);
  }
  if (printName) {
    formData.append("printName", printName);
  }
  if (description) {
    formData.append("description", description);
  }
  if (price) {
    formData.append("price", price);
  }
  if (tags) {
    tags.forEach((element) => {
      formData.append("tags[]", element);
    });
  }
  if (display_order) {
    formData.append("display_order", display_order);
  }
  if (cd) {
    formData.append("cd", cd);
  }

  if (type) {
    formData.append("type", type);
  }

  if (image && image.length > 0) {
    formData.append("image", image[0], image[0].name);
  }

  if (hidden_from_public) {
    formData.append("hidden_from_public", hidden_from_public);
  }

  return (dispatch) =>
    patchData(
      UPDATE_MENU_ITEM_INFO,
      MENU_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function moveMenuItem(
  menuId,
  menuSectionId,
  menuItemId,
  { sectionId },
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/move`;
  return (dispatch) =>
    patchData(
      MOVE_MENU_ITEM,
      MENU_ERROR,
      true,
      url,
      dispatch,
      { sectionId: sectionId },
      callback
    );
}

export function fetchMenuItemSize(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemSizeId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/sizes/${menuItemSizeId}`;
  return (dispatch) =>
    getData(FETCH_MENU_ITEM_SIZE, MENU_ERROR, false, url, dispatch);
}

export function addMenuItemSize(
  menuId,
  menuSectionId,
  menuItemId,
  { name, price, description }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/sizes`;
  return (dispatch) =>
    patchData(ADD_MENU_ITEM_SIZE, MENU_ERROR, true, url, dispatch, {
      name: name,
      price: price,
      description: description,
    });
}

export function updateMenuItemSize(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemSizeId,
  { name, price, description }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/sizes/${menuItemSizeId}`;
  return (dispatch) =>
    patchData(UPDATE_MENU_ITEM_SIZE, MENU_ERROR, true, url, dispatch, {
      name: name,
      price: price,
      description: description,
    });
}

export function removeMenuItemSize(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemSizeId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/sizes/${menuItemSizeId}`;
  return (dispatch) =>
    deleteData(REMOVE_MENU_ITEM_SIZE, MENU_ERROR, true, url, dispatch);
}

export function addMenuItemItem(
  menuId,
  menuSectionId,
  menuItemId,
  { name, spicy }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/items`;
  return (dispatch) =>
    patchData(ADD_MENU_ITEM_ITEM, MENU_ERROR, true, url, dispatch, {
      name: name,
      spicy: spicy,
    });
}

export function updateMenuItemItem(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemItemId,
  { name, spicy }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/items/${menuItemItemId}`;
  return (dispatch) =>
    patchData(UPDATE_MENU_ITEM_ITEM, MENU_ERROR, true, url, dispatch, {
      name: name,
      spicy: spicy,
    });
}

export function removeMenuItemItem(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemItemId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/items/${menuItemItemId}`;
  return (dispatch) =>
    deleteData(REMOVE_MENU_ITEM_ITEM, MENU_ERROR, true, url, dispatch);
}

export function fetchMenuItemOption(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemOptionId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/options/${menuItemOptionId}`;
  return (dispatch) =>
    getData(FETCH_MENU_ITEM_OPTION, MENU_ERROR, false, url, dispatch);
}

export function addMenuItemOption(
  menuId,
  menuSectionId,
  menuItemId,
  { name, selection_type, hidden_from_public, items }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/options`;
  return (dispatch) =>
    patchData(ADD_MENU_ITEM_OPTION, MENU_ERROR, true, url, dispatch, {
      name: name,
      selection_type: selection_type,
      hidden_from_public: hidden_from_public,
      items: items,
    });
}

export function updateMenuItemOption(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemOptionId,
  { name, selection_type, min_selected, max_selected, number_of_free_options, information, hidden_from_public, items }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/options/${menuItemOptionId}`;
  return (dispatch) =>
    patchData(UPDATE_MENU_ITEM_OPTION, MENU_ERROR, true, url, dispatch, {
      name: name,
      selection_type: selection_type,
      min_selected: min_selected,
      max_selected: max_selected,
      number_of_free_options: number_of_free_options,
      information: information,
      hidden_from_public: hidden_from_public,
      items: items,
    });
}

export function removeMenuItemOption(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemOptionId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/options/${menuItemOptionId}`;
  return (dispatch) =>
    deleteData(REMOVE_MENU_ITEM_OPTION, MENU_ERROR, true, url, dispatch);
}

export function addMenuItemPhoto(
  menuId,
  menuSectionId,
  menuItemId,
  { caption, photoUrl },
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/photos`;

  return (dispatch) =>
    patchData(
      ADD_MENU_ITEM_PHOTO,
      MENU_ERROR,
      true,
      url,
      dispatch,
      {
        caption: caption,
        photoUrl: photoUrl,
      },
      callback
    );
}

export function updateMenuItemPhoto(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemPhotoId,
  { caption, photoUrl }
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/photos/${menuItemPhotoId}`;

  return (dispatch) =>
    patchData(UPDATE_MENU_ITEM_PHOTO, MENU_ERROR, true, url, dispatch, {
      caption: caption,
      photoUrl: photoUrl,
    });
}

export function removeMenuItemPhoto(
  menuId,
  menuSectionId,
  menuItemId,
  menuItemPhotoId
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/photos/${menuItemPhotoId}`;
  return (dispatch) =>
    deleteData(REMOVE_MENU_ITEM_PHOTO, MENU_ERROR, true, url, dispatch);
}

export function updateMenuSectionPhoto({ photoUrl }, callback) {
  callback();
  return {
    type: UPDATE_MENU_SECTION_PHOTO,
    payload: { photoUrl: photoUrl },
  };
}

export function updateMenuPhoto({ photoUrl }, callback) {
  callback();
  return {
    type: UPDATE_MENU_PHOTO,
    payload: { photoUrl: photoUrl },
  };
}

export function removeMenuSectionPhoto() {
  return {
    type: REMOVE_MENU_SECTION_PHOTO,
    payload: {},
  };
}

export function removeMenuPhoto() {
  return {
    type: REMOVE_MENU_PHOTO,
    payload: {},
  };
}

export function markMenuItemAsSoldOut(
  menuId,
  menuSectionId,
  menuItemId,
  { soldOutOption, soldOutUntil },
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/sold-out`;
  return (dispatch) =>
    patchData(
      MARK_MENU_ITEM_AS_SOLD_OUT,
      MENU_ERROR,
      true,
      url,
      dispatch,
      { soldOutOption: soldOutOption, soldOutUntil: soldOutUntil },
      callback
    );
}

export function updateAssignedLocations(menuId, locationSelection, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/locations`;

  const data = {
    ...locationSelection,
  };

  return (dispatch) =>
    patchData(
      UPDATE_ASSIGNED_LOCATIONS,
      MENU_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateMenuSectionAssignedLocations(menuId, menuSectionId, locationSelection, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/sections/${menuSectionId}/locations`;

  const data = {
    ...locationSelection,
  };

  return (dispatch) =>
    patchData(
      UPDATE_MENU_SECTION_ASSIGNED_LOCATIONS,
      MENU_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateMenuItemAssignedLocations(menuId, menuSectionId, menuItemId, locationSelection, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/locations`;

  const data = {
    ...locationSelection,
  };

  return (dispatch) =>
    patchData(
      UPDATE_MENU_ITEM_ASSIGNED_LOCATIONS,
      MENU_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function markMenuItemAsAvailable(
  menuId,
  menuSectionId,
  menuItemId,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/menus/${menuId}/sections/${menuSectionId}/items/${menuItemId}/available`;
  return (dispatch) =>
    patchData(
      MARK_MENU_ITEM_AS_AVAILABLE,
      MENU_ERROR,
      true,
      url,
      dispatch,
      {},
      callback
    );
}
