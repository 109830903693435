import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../utils/renderFields";

import { updateGallery } from "../../actions/tenant";
import GalleryPhotoPicker from "./GalleryPhotoPicker";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "editGallery",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class EditGallery extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      imageUrl: this.props.gallery_item.image.url
    }

  }

  componentDidMount() {
    this.setState({
      imageUrl: this.props.gallery_item.image.url
    });
   }

  handleFormSubmit(formProps) {

    this.props.updateGallery(
      this.props.tenant._id,
      this.props.gallery_item._id,
      formProps,
      this.state.imageUrl,
      () => {
        this.props.handleViewGallerySettings();
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }


  render() {
    const {
      handleViewGallerySettings,
      handleSubmit,
      tenant,
      pristine,
      submitting,

    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => window.location.href = "/settings"}
              >
                {`Settings`}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" onClick={handleViewGallerySettings}>
                {`Gallery`}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light">
                {`Edit`}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}

          <div className="form-row pb-3">
            <div className="col-3 text-right">
              {`Caption`}
            </div>
            <div className="col-9">
              <Field
                name={`caption`}
                className="form-control"
                component={renderField}
                placeholder="Caption"
                type="text"
              />
            </div>
          </div>
          <div className="form-row pb-3">
          <div className="col-3 text-right">
              {`Image`}
            </div>
            <div className="col-9">
              <div className="text-center"
                style={{
                  // width: "330px",
                  // height: "240px",
                  borderWidth: "2px",
                  borderColor: "rgb(102, 102, 102)",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  padding: "20px",
                }}>
                {this.state.imageUrl &&
                  <img
                    className="img-fluid"
                    width={229}
                    height={150}
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${tenant.slug + "/" + this.state.imageUrl
                      }`
                    }
                    alt="Gallery"
                  />
                }
                <GalleryPhotoPicker
                  tenant={tenant}
                  handleImageUrlChange={imageUrl => {
                    this.setState({ imageUrl: imageUrl });
                  }}
                  imageUrl={this.state.imageUrl}
                />
                {/* <p className="text-muted pt-3">Image will be automaticaly resized to 1900 x 646</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={handleViewGallerySettings}
              >
                {`Cancel`}
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={pristine || submitting}
              >
                {"Save"}
              </button>
            </div>
          </div>

        </form>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    errorMessage: state.tenant.error,
    message: state.tenant.message,
    initialValues: ownProps.gallery_item,
    tenant: state.tenant.tenant,
    photoUrl: ownProps.gallery_item.image.url
  };
};

const mapDispatchToProps = dispatch => ({
  updateGallery: (tenantId, galleryItemId, formProps, photoUrl, callback) =>
    dispatch(updateGallery(tenantId, galleryItemId, formProps, photoUrl, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(EditGallery));
