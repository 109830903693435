import React from "react";

import moment from "moment-timezone";
import Numeral from "numeral";

const OrderInfoCard = ({ order, restaurant, coupon }) => {
  if (!order) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Order Number: {order.giftCardOrderNumber}
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            <span>Gift Card Type: {order.giftCardType} </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            <span>Payment Method: {order.paymentMethod} </span>
            {order.paymentMethod === "Offline" && (
              <i className="fas fa-cash-register" />
            )}
            {order.paymentMethod === "Online" && (
              <i className="fas fa-credit-card" />
            )}
          </div>
        </div>
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Status: {order.status}
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Channel: {order.channel}
          </div>
          <div className="col-12 col-sm-6 col-lg-3 pb-1">
            Restaurant: {restaurant.name}
          </div>
          {order.paymentReceiptNumber && (
            <div className="col-12 col-sm-6 col-lg-4 pb-1">
              Payment Receipt Number: {order.paymentReceiptNumber}
            </div>
          )}
        </div>
        <div className="row pb-3">
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            <span>
              Received At:{" "}
              {moment(order.receivedAt)
                .tz(restaurant.settings.time_zone)
                .format(restaurant.settings.datetime_format)}
            </span>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            {order.status === "Cancelled" && (
              <span>
                Cancelled At:{" "}
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            )}
            {order.status === "Rejected" && (
              <>
                <div>
                  Rejected At:{" "}
                  {moment(order.confirmedAt)
                    .tz(restaurant.settings.time_zone)
                    .format(restaurant.settings.datetime_format)}
                </div>
                <div>Reject Reason: {order.rejectReason}</div>
              </>
            )}
            {order.status === "Paid" && (
              <>
                <div>
                  Paid At:{" "}
                  {moment(order.paidAt)
                    .tz(restaurant.settings.time_zone)
                    .format(restaurant.settings.datetime_format)}
                </div>
              </>
            )}
            {order.status === "Fulfilled" && (
              <span>
                Fulfilled At:{" "}
                {moment(order.confirmedAt)
                  .tz(restaurant.settings.time_zone)
                  .format(restaurant.settings.datetime_format)}
              </span>
            )}
          </div>
        </div>
        <div className="row pb-3 border-bottom">
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Customer Name: {order.nameOrderedUnder}
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Phone: {order.phone}
          </div>
          <div className="col-12 col-sm-6 col-lg-4 pb-1">
            Email: {order.email ?? ""}
          </div>
        </div>

        <div className="row pt-3 pb-3 ">
          <div className="col-12">
            Gift Card Name: {order.giftCardProductName}
          </div>
        </div>
        <div className="row  pb-3 ">
          <div className="col-12">
            Unit Price:{" "}
            {Numeral(order.giftCardProductUnitPrice).format("$0,0.00")}
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-12">Quantity: {order.quantity}</div>
        </div>

        <div className="row pb-3 ">
          <div className="col-12">
            Number of Gift Cards: {order.giftCardQuantity}
          </div>
        </div>

        <div className="row pb-3 ">
          <div className="col-12">
            Amount on Card:{" "}
            {Numeral(order.giftCardUnitPrice).format("$0,0.00")}
          </div>
        </div>

        <div className="row pb-3 ">
          <div className="col-12">
            Price: {Numeral(order.price).format("$0,0.00")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfoCard;
