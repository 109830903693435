import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import normalizePhone from "../../utils/normalizePhone";
import {
  phoneNumber,
  email,
  required,
  renderEmailField,
  renderUserNameField,
  renderPhoneField,
} from "../../utils/renderFields";
import { addEmployee } from "../../actions/employee";
import EmployeePhotoPicker from "./EmployeePhotoPicker";

const form = reduxForm({
  form: "addEmployee",
  validate,
});

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstNme = "Please enter first name";
  }
  if (!formProps.lastName) {
    errors.lastName = "Please enter last name";
  }

  return errors;
}

class AddEmployee extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      imageUrl: undefined,
    };
  }

  componentDidMount() {}

  handleFormSubmit(formProps) {
    const restaurantIds = [];
    Object.keys(formProps).filter(key => key.startsWith("restaurant_")).forEach(key => {
      if (formProps[key]) {
        restaurantIds.push(key.split("_")[1]);
      }
    })
    const data = {
      userName: formProps.userName, 
      firstName: formProps.firstName, 
      lastName: formProps.lastName, 
      telephone: formProps.telephone, 
      email: formProps.email, 
      imageUrl: this.state.imageUrl, 
      role: formProps.role, 
      restaurantIds: restaurantIds,
    }
    this.props.addEmployee(this.props.tenant._id, data, () => {
      this.props.handleView();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleView,
      handleSubmit,
      pristine,
      submitting,
      restaurants = [],
      tenant,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleView}
              >
                <i className="fas fa-gift" />
                {` `}
                <span className="d-none d-sm-inline">{`Employees`}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                {`Add New Employee`}
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">Image</label>
                <div
                  className="text-center"
                  style={{
                    // width: "330px",
                    // height: "240px",
                    borderWidth: "2px",
                    borderColor: "rgb(102, 102, 102)",
                    borderStyle: "dashed",
                    borderRadius: "5px",
                    padding: "20px",
                  }}
                >
                  {this.state.imageUrl && (
                    <img
                      className="img-fluid"
                      width={229}
                      height={150}
                      src={
                        process.env.REACT_APP_S3_BASE_URL +
                        `${tenant.slug + "/" + this.state.imageUrl}`
                      }
                      alt="Employee"
                    />
                  )}
                  <EmployeePhotoPicker
                    tenant={tenant}
                    imageUrl={this.state.imageUrl}
                    handleImageUrlChange={(imageUrl) => {
                      this.setState({ imageUrl: imageUrl });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label required">User Name</label>
                <Field
                  name="userName"
                  className="form-control"
                  component={renderUserNameField}
                  validate={[required]}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label required">First Name</label>
                <Field
                  name="firstName"
                  className="form-control"
                  component={renderUserNameField}
                  validate={[required]}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label required">Last Name</label>
                <Field
                  name="lastName"
                  className="form-control"
                  component={renderUserNameField}
                  validate={[required]}
                  type="text"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label required">Phone</label>
                <Field
                  name="telephone"
                  className="form-control"
                  component={renderPhoneField}
                  type="text"
                  validate={[required, phoneNumber]}
                  normalize={normalizePhone}
                />
              </div>
              <div className="form-group">
                <label className="col-form-label required">Email</label>
                <Field
                  name="email"
                  className="form-control"
                  component={renderEmailField}
                  validate={[required, email]}
                  type="email"
                />
              </div>
              <div className="form-group">
                <label className="col-form-label required">Role</label>
                <div className="form-check">
                  <Field
                    name="role"
                    component="input"
                    type="radio"
                    value="Courier"
                    className="form-check-input"
                  />
                  <label className="form-check-label">Courier</label>
                </div>
                <div className="form-check">
                  <Field
                    name="role"
                    component="input"
                    type="radio"
                    value="Waiter"
                    className="form-check-input"
                  />
                  <label className="form-check-label">Waiter</label>
                </div>
                <div className="form-check">
                  <Field
                    name="role"
                    component="input"
                    type="radio"
                    value="Manager"
                    className="form-check-input"
                  />
                  <label className="form-check-label">Manager</label>
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label required">Work for</label>
                {restaurants.map((restaurant) => (
                  <div className="form-check" key={restaurant._id}>
                    <Field
                      name={`restaurant_${restaurant._id}`}
                      component="input"
                      type="checkbox"
                      // value={restaurant._id}
                      className="form-check-input"
                      id={`restaurant_${restaurant._id}`}
                    />
                    <label className="form-check-label">
                      {restaurant.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-outline-secondary mr-3"
            onClick={handleView}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={pristine || submitting}
          >
            {"Add"}
          </button>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tenant: state.tenant.tenant,
    restaurants: state.tenant.restaurants,
    errorMessage: state.employee.error,
    message: state.employee.message,
    initialValues: {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  addEmployee: (tenantId, formProps, callback) =>
    dispatch(addEmployee(tenantId, formProps, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddEmployee));
