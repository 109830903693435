//===========================================================
// Auth Actions
//===========================================================
export const AUTH_USER = "auth_user",
  UNAUTH_USER = "unauth_user",
  AUTH_ERROR = "auth_error",
  FORGOT_PASSWORD_REQUEST = "forgot_password_request",
  RESET_PASSWORD_REQUEST = "reset_password_request",
  REGISTER_PAGE_UNLOADED = "register_page_unload",
  LOGIN_PAGE_UNLOADED = "login_page_unload",
  CHANGE_PASSWORD = "change_password",
  PROTECTED_TEST = "protected_test";

//===========================================================
// User Profile Actions
//===========================================================
export const FETCH_USER = "fetch_user",
  FETCH_USERS = "fetch_users",
  ADD_USER = "add_user",
  UPDATE_USER = "update_user",
  USER_ERROR = "user_error";

//===========================================================
// Tenant Actions
//===========================================================
export const 
  FETCH_TENANT = "fetch_tenant",  
  UPDATE_FAVICON = "update_favicon",
  FETCH_SUBSCRIPTION = "fetch_subscription",
  UPDATE_SUBSCRIPTION = "update_subscription",
  FETCH_INVOICES = "fetch_invoices",
  FETCH_CURRENT_INVOICE = "fetch_current_invoice",
  FETCH_BILLING_PROFILE = "fetch_billing_profile",
  UPDATE_BILLING_PROFILE = "update_billing_profile",
  FETCH_PAYMENTS = "fetch_payments",
  TENANT_ERROR = "tenant_error";  

//===========================================================
// Restaurant Actions
//===========================================================
export const FETCH_RESTAURANTS = "fetch_restaurants",
  FETCH_TENANTS = "fetch_tenants",
  SELECT_TENANT = 'select_tenant',
  FETCH_RESTAURANT = "fetch_restaurant",
  UPDATE_RESTAURANT_INFO = "update_restaurant_info",
  FETCH_BUSINESS_HOURS = "fetch_business_hours",
  FETCH_MENUS = "fetch_menus",
  FETCH_PUBLISHED_MENUS = "fetch_published_menus",
  FETCH_ARCHIVED_MENUS = "fetch_archived_menus",
  UPDATE_BUSINESS_HOUR = "update_business_hour",
  ADD_BUSINESS_HOUR = "add_business_hour",
  FETCH_CUSTOMERS = "fetch_customers",
  FETCH_CUSTOMER = "fetch_customer",
  BLOCK_CUSTOMER = "block_customer",
  UNBLOCK_CUSTOMER = "unblock_customer",
  FETCH_GUESTS = "fetch_guests",
  FETCH_REGISTERED_USERS = "fetch_registered_users",
  ADD_RESTAURANT = "add_restaurant",
  SELECT_RESTAURANT = "select_restaurant",
  FETCH_SETTINGS = "fetch_settings",
  UPDATE_SETTINGS = "update_settings",
  UPDATE_LOGO = "update_logo",
  UPDATE_BANNER = "update_banner",
  UPDATE_POSTER = "update_poster",
  UPDATE_CAROUSEL = "update_carousel",
  UPDATE_CAROUSEL_PHOTO = "update_carousel_photo",
  UPDATE_GALLERY = "update_gallery",
  UPDATE_GALLERY_PHOTO = "update_gallery_photo",
  UPDATE_TENANT_INFO = "update_tenant_info",
  DELETE_BUSINESS_HOUR = "delete_business_hour",
  UPDATE_TENANT_SETTINGS = "update_tenant_settings",
  FETCH_PICKUP_DATES = "fetch_pickup_dates",
  RESTAURANT_ERROR = "restaurant_error";

//===========================================================
// Order Actions
//===========================================================
export const CANCEL_ORDER = "cancel_order",
  SAVE_ORDER = "save_order",
  CHANGE_PICKUP_LOCATION = "change_pickup_location",
  CHANGE_PICKUP_TIME = "change_pickup_time",
  CHANGE_PICKUP_PERSON = "change_pickup_person",
  CHANGE_CUSTOMER = "change_customer",
  LOGIN_TO_PROCEED = "login_to_proceed",
  CHANGE_SPECIAL_REQUEST = "change_special_request",
  FETCH_ORDER = "fetch_order",
  FETCH_ORDERS = "fetch_orders",
  ACCEPT_ORDER = "accept_order",
  REJECT_ORDER = "reject_order",
  START_ORDER = "start_order",
  COMPLETE_ORDER = "complete_order",
  FULFILL_ORDER = "fulfill_order",
  CHANGE_ORDER_TYPE = "change_order_type",
  SEND_EMAIL = "send_email",
  FETCH_CUSTOMER_ORDERS = "fetch_customer_orders",
  FETCH_CALL_IN_ORDERS = "fetch_call_in_orders",
  FETCH_CUSTOMER_FREQUENTLY_ORDERED_ITEMS = "fetch_customer_frequently_ordered_items",
  CLEAR_CUSTOMER_FREQUENTLY_ORDERED_ITEMS = "clear_customer_frequently_ordered_items",
  UPDATE_ORDER = "update_order",
  UPDATE_DELIVERY_INFO = "update_delivery_info",
  APPLY_COUPON_TO_ORDER = "apply_coupon_to_oder",
  ORDER_ERROR = "order_error";

//===========================================================
// Historical Order Actions
//===========================================================
export const 
SAVE_HISTORICAL_ORDER = "save_historical_order",
FETCH_HISTORICAL_ORDERS = "fetch_historical_orders",
HISTORICAL_ORDER_ERROR = "historical_order_error";

//===========================================================
// Menu Actions
//===========================================================
export const UPDATE_MENU_INFO = "update_menu_info",
  UPDATE_MENU_SECTION_INFO = "update_menu_section_info",
  UPDATE_MENU_ITEM_INFO = "update_menu_item_info",
  FETCH_MENU = "fetch_menu",
  FETCH_MENU_SECTION = "fetch_menu_section",
  FETCH_MENU_ITEM = "fetch_menu_item",
  ADD_MENU = "add_menu",
  ADD_MENU_SECTION = "add_menu_section",
  UPDATE_MENU_PHOTO = "update_menu_photo",
  UPDATE_MENU_SECTION_PHOTO = "update_menu_section_photo",
  ADD_MENU_ITEM = "add_menu_item",
  ADD_MENU_ITEM_SIZE = "add_menu_item_size",
  UPDATE_MENU_ITEM_SIZE = "update_menu_item_size",
  FETCH_MENU_ITEM_SIZE = "fetch_menu_item_size",
  REMOVE_MENU_ITEM_SIZE = "remove_menu_item_size",
  ADD_MENU_ITEM_ITEM = "add_menu_item_item",
  UPDATE_MENU_ITEM_ITEM = "update_menu_item_item",
  FETCH_MENU_ITEM_ITEM = "fetch_menu_item_item",
  REMOVE_MENU_ITEM_ITEM = "remove_menu_item_item",
  ADD_MENU_ITEM_OPTION = "add_menu_item_option",
  UPDATE_MENU_ITEM_OPTION = "update_menu_item_option",
  FETCH_MENU_ITEM_OPTION = "fetch_menu_item_option",
  REMOVE_MENU_ITEM_OPTION = "remove_menu_item_option",
  ARCHIVE_MENU = "archive_menu",
  UNARCHIVE_MENU = "unarchive_menu",
  ARCHIVE_MENU_SECTION  = "archive_menu_section",
  UNARCHIVE_MENU_SECTION = "unarchive_menu_section", 
  ARCHIVE_MENU_ITEM  = "archive_menu_item",
  UNARCHIVE_MENU_ITEM = "unarchive_menu_item", 
  VIEW_MENUS = "view_menus",
  VIEW_MENU = "view_menu",
  PUBLISH_MENU = "publish_menu",
  MOVE_MENU_ITEM = "move_menu_item",
  ADD_MENU_ITEM_PHOTO = "add_menu_item_photo",
  REMOVE_MENU_ITEM_PHOTO = "remove_menu_item_photo",
  UPDATE_MENU_ITEM_PHOTO = "update_menu_item_photo",
  REMOVE_MENU_SECTION_PHOTO = "remove_menu_section_photo",
  REMOVE_MENU_PHOTO = "remove_menu_photo",
  MARK_MENU_ITEM_AS_SOLD_OUT = "mark_menu_item_as_sold_out",
  UPDATE_ASSIGNED_LOCATIONS = "update_assigned_locations",
  UPDATE_MENU_SECTION_ASSIGNED_LOCATIONS = "update_menu_section_assigned_locations",
  UPDATE_MENU_ITEM_ASSIGNED_LOCATIONS = "update_menu_item_assigned_locations",
  MARK_MENU_ITEM_AS_AVAILABLE = "mark_menu_item_as_available",
  MENU_ERROR = "menu_error";

//===========================================================
// Image Library Actions
//===========================================================
export const ADD_IMAGE = "add_image",
UPDATE_IMAGE = "update_image",
FETCH_IMAGES = "fetch_images",
DELETE_IMAGE= "delete_image",
ADD_FOLDER = "add_folder",
IMAGE_ERROR = "image_library_error";

//===========================================================
// Coupon Actions
//===========================================================
export const ADD_COUPON = "add_coupon",
  UPDATE_COUPON = "update_coupon",
  FETCH_COUPONS = "fetch_coupons",
  DELETE_COUPON = "delete_coupon",
  GENERATE_COUPON_CODE = "generate_coupon_code",
  FETCH_EMAIL_BY_PHONE = "fetch_email_by_phone",
  FETCH_COUPON_BY_ORDER_NUMBER = "fetch_order_by_order_number",
  COUPON_ERROR = "coupon_error";

//===========================================================
// Promotion Actions
//===========================================================
export const ADD_PROMOTION = "add_promotion",
  UPDATE_PROMOTION = "update_promotion",
  FETCH_PROMOTIONS = "fetch_promotions",
  FETCH_VALID_PROMOTIONS = "fetch_valid_promotions",
  FETCH_PROMOTION = "fetch_promotion",
  DELETE_PROMOTION = "delete_promotion",
  ENABLE_PROMOTION = "enable_promotion",
  DISABLE_PROMOTION = "disable_promotion",
  DUPLICATE_PROMOTION = "duplicate_promotion",
  ADD_PROMOTION_PHOTO = "add_promotion_photo",
  UPDATE_PROMOTION_PHOTO = "update_promotion_photo",
  REMOVE_PROMOTION_PHOTO = "remove_promotion_photo",
  UPDATE_PROMOTION_ASSIGNED_LOCATIONS = "update_promotion_assigned_locations",
  PROMOTION_ERROR = "promotion_error";  

//===========================================================
// GiftCardProduct Actions
//===========================================================
export const ADD_GIFTCARDPRODUCT = "add_giftcardproduct",
  UPDATE_GIFTCARDPRODUCT = "update_giftcardproduct",
  FETCH_GIFTCARDPRODUCTS = "fetch_giftcardproducts",
  DELETE_GIFTCARDPRODUCT = "delete_giftcardproduct",
  GIFTCARDPRODUCT_ERROR = "giftcardproduct_error";

//===========================================================
// GiftCardOrder Actions
//===========================================================
export const  FETCH_GIFT_CARD_ORDER = "fetch_gift_card_order",
FETCH_GIFT_CARD_ORDERS = "fetch_gift_card_orders",
FULFILL_GIFT_CARD_ORDER = "fulfill_gift_card_order",
PAY_GIFT_CARD_ORDER = "pay_gift_card_order",
UPDATE_GIFT_CARD_ORDER = "update_gift_card_order",
REJECT_GIFT_CARD_ORDER = "reject_gift_card_order",
GENERATE_GIFT_CARD_NUMBERS = "generate_gift_card_numbers",
GIFT_CARD_ORDER_ERROR = "gift_card_oarder_error";

//===========================================================
// GiftCard Actions
//===========================================================
export const  FETCH_GIFT_CARDS = "fetch_gift_cards",
FETCH_GIFT_CARD = "fetch_gift_card",
FETCH_GIFT_CARD_REDEMPTIONS = "fetch_gift_card_redemptions",
GIFT_CARD_ERROR = "gift_card_error";

//===========================================================
// Announcement Actions
//===========================================================
export const ADD_ANNOUNCEMENT = "add_announcement",
  UPDATE_ANNOUNCEMENT = "update_announcement",
  FETCH_ANNOUNCEMENTS = "fetch_announcement",
  DELETE_ANNOUNCEMENT = "delete_announcement",
  ANNOUNCEMENT_ERROR = "announcement_error";  

//===========================================================
// Cart Actions
//===========================================================
export const ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  CLEAR_CART = "clear_cart",
  CART_ERROR = "cart_error",
  CHECKOUT_SUCCESS = "checkout_success",
  CHECKOUT_REQUEST = "checkout_request",
  INCREASE_QUANTITY = "increase_quantity",
  DECREASE_QUANTITY = "decrease_quantity",
  VALIDATE_AND_APPLY_PROMOTIONS = "validate_and_apply_promotions",
  REMOVE_PROMOTION_FROM_CART = "remove_promotion_from_cart",
  APPLY_COUPON = "apply_coupon",
  REMOVE_COUPON_FROM_CART = "remove_coupon_from_cart",
  UPDATE_CART_ITEM_SPECIAL_INSTRUCTIONS = "update_cart_item_special_instructions",
  REMOVE_GIFT_CARD_REDEMPTION = "remove_gift_card_redemption",
  REDEEM_GIFT_CARD = "redeem_gift_card",
  CHARGE = "charge";

//===========================================================
// Messaging Actions
//===========================================================
export const FETCH_CONVERSATIONS = "fetch_conversations",
  FETCH_RECIPIENTS = "fetch_recipients",
  START_CONVERSATION = "start_conversation",
  FETCH_SINGLE_CONVERSATION = "fetch_single_conversation",
  FETCH_PARTICIPANT = "fetch_participant",
  CHAT_ERROR = "chat_error",
  SEND_REPLY = "send_reply";

//===========================================================
// Page Actions
//===========================================================
export const SEND_CONTACT_FORM = "send_contact_form",
  STATIC_ERROR = "static_error";

//===========================================================
// Reservation Actions
//===========================================================
export const ACCEPT_RESERVATION = "accept_reservation",
  REJECT_RESERVATION = "reject_reservation",
  FETCH_RESERVATIONS = "fetch_reservations",
  FETCH_RESERVATION = "fetch_reservation",
  RESERVATION_ERROR = "reservation_error";
