import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as FiIcons from "react-icons/fi";
// import * as GiIcons from "react-icons/gi";
// import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaIcons.FaChartLine />,
    role: "Waiter",
  },
  {
    title: "Orders",
    icon: <MdIcons.MdFileCopy />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Waiter",
    subNav: [
      {
        title: "Food Orders",
        path: "/orders",
        icon: <MdIcons.MdFastfood />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        role: "Waiter",
      },
      {
        title: "Take Order",
        path: "/take-order",
        icon: <FiIcons.FiPhoneIncoming />,
        role: "Waiter",
      },
      {
        title: "Search Orders",
        path: "/searchorders",
        icon: <AiIcons.AiOutlineFileSearch />,
        role: "Waiter",
      },
      // {
      //   title: "Historical Orders",
      //   path: "/historical-orders",
      //   icon: <AiIcons.AiOutlineFileDone />,
      //   role: "Manager",
      // },
      // {
      //   title: "Reservations",
      //   path: "/reservations",
      //   icon: <RiIcons.RiReservedLine />,
      //   role: "Waiter",
      // },
    ],
  },
  {
    title: "Kitchen",
    icon: <MdIcons.MdKitchen />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Waiter",
    subNav: [
      {
        title: "Kitchen Orders",
        path: "/kitchen-orders",
        icon: <MdIcons.MdOutlineReceipt />,
        role: "Waiter",
      },
      {
        title: "Cooking Queue",
        path: "/cooking-queue",
        icon: <BsIcons.BsLayoutThreeColumns />,
        role: "Waiter",
      },
    ],
  },
  {
    title: "Coupons",
    icon: <RiIcons.RiCoupon2Line />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Waiter",
    subNav: [
      {
        title: "Coupons",
        path: "/coupons",
        icon: <RiIcons.RiCoupon2Line />,
        role: "Waiter",
      },
      {
        title: "Search Coupons",
        path: "/searchcoupons",
        icon: <FaIcons.FaSearchDollar />,
        cName: "sub-nav",
        role: "Waiter",
      },
    ],
  },
  {
    title: "Gift Cards",
    icon: <MdIcons.MdCardGiftcard />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Waiter",
    subNav: [
      {
        title: "Gift Cards",
        path: "/giftcards",
        icon: <MdIcons.MdCardGiftcard />,
        role: "Waiter",
      },
      {
        title: "Gift Card Orders",
        path: "/giftcardorders",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
        role: "Waiter",
      },
      {
        title: "Search Gift Card Orders",
        path: "/searchgiftcardorders",
        icon: <AiIcons.AiOutlineFileSearch />,
        cName: "sub-nav",
        role: "Waiter",
      },
    ],
  },
  {
    title: "Customers",
    icon: <FiIcons.FiUsers />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Waiter",
    subNav: [
      {
        title: "Customers",
        path: "/customers",
        icon: <FiIcons.FiUsers />,
        role: "Waiter",
      },
      {
        title: "Search Customers",
        path: "/searchcustomers",
        icon: <RiIcons.RiUserSearchLine />,
        cName: "sub-nav",
        role: "Waiter",
      },
    ],
  },
  {
    title: "Marketing",
    icon: <MdIcons.MdCampaign />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Manager",
    subNav: [
      {
        title: "Promotions",
        path: "/promotions",
        icon: <FaIcons.FaTags />,
        role: "Manager",
      },
      {
        title: "Announcements",
        path: "/announcements",
        icon: <FaIcons.FaBullhorn />,
        role: "Manager",
      },
    ],
  },
  // {
  //   title: "Reports",
  //   icon: <HiIcons.HiDocumentReport />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   role: "Manager",
  //   subNav: [
  //     {
  //       title: "End of Period Summary",
  //       path: "/end-of-period-summary",
  //       icon: <BsIcons.BsCalendarWeekFill />,
  //       role: "Manager",
  //     }
  //   ],
  // },
  {
    title: "Products",
    icon: <MdIcons.MdInventory />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Manager",
    subNav: [
      {
        title: "Menus",
        path: "/menus",
        icon: <MdIcons.MdOutlineRestaurantMenu />,
        cName: "sub-nav",
        role: "Manager",
      },
      {
        title: "Gift Cards",
        path: "/giftcardproducts",
        icon: <MdIcons.MdOutlineCardGiftcard />,
        cName: "sub-nav",
        role: "Manager",
      },
      {
        title: "Image Library",
        path: "/images",
        icon: <FaIcons.FaImages />,
        role: "Manager",
      },
    ],
  },
  {
    title: "Admins",
    icon: <FaIcons.FaToolbox />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    role: "Manager",
    subNav: [
      {
        title: "Settings",
        path: "/settings",
        icon: <FiIcons.FiSettings />,
        role: "Manager",
      },
      {
        title: "Restaurants",
        path: "/restaurants",
        icon: <FaIcons.FaMapMarkerAlt />,
        role: "Owner",
      },
      {
        title: "Business Hours",
        path: "/businesshours",
        icon: <FaIcons.FaBusinessTime />,
        role: "Manager",
      },
      {
        title: "Employees",
        path: "/employees",
        icon: <FaIcons.FaUsersCog />,
        role: "Manager",
      },
      {
        title: "Monitoring",
        path: "/monitoring",
        icon: <FaIcons.FaWatchmanMonitoring />,
        role: "Manager",
      },
    ],
  },
  {
    title: "Billing",
    path: "/billing",
    icon: <FaIcons.FaFileInvoiceDollar />,
    role: "Owner",
  },
];
