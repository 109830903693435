import {
  FETCH_PROMOTIONS,
  PROMOTION_ERROR,
  ADD_PROMOTION,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  ENABLE_PROMOTION,
  DISABLE_PROMOTION,
  DUPLICATE_PROMOTION,
  ADD_PROMOTION_PHOTO,
  UPDATE_PROMOTION_PHOTO,
  REMOVE_PROMOTION_PHOTO,
  UPDATE_PROMOTION_ASSIGNED_LOCATIONS,
  FETCH_PROMOTION,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
  promotions: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROMOTIONS:
      return { ...state, promotions: action.payload.promotions };
    case ADD_PROMOTION:
      return {
        ...state,
        promotions: [...state.promotions, action.payload.promotion],
      };
    case DUPLICATE_PROMOTION:
      return {
        ...state,
        promotions: [...state.promotions, action.payload.promotion],
      };

    case UPDATE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.map((promotion) => {
          if (promotion._id === action.payload.promotion._id) {
            return action.payload.promotion;
          } else {
            return promotion;
          }
        }),
      };
    case ENABLE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.map((promotion) => {
          if (promotion._id === action.payload.promotion._id) {
            return action.payload.promotion;
          } else {
            return promotion;
          }
        }),
      };
    case DISABLE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.map((promotion) => {
          if (promotion._id === action.payload.promotion._id) {
            return action.payload.promotion;
          } else {
            return promotion;
          }
        }),
      };
    case UPDATE_PROMOTION_ASSIGNED_LOCATIONS:
      return {
        ...state,
        promotion: action.payload.promotion,
        promotions: state.promotions.map((promotion) => {
          if (promotion._id === action.payload.menu._id) {
            return action.payload.promotion;
          } else {
            return promotion;
          }
        }),
      };
    case FETCH_PROMOTION:
      return {
        ...state,
        promotion: action.payload.promotion,
      };
    case ADD_PROMOTION_PHOTO:
      return {
        ...state,
        promotion: action.payload.promotion,
        promotionPhoto: undefined,
        promotionPhotos: [
          ...state.promotionPhotos,
          action.payload.addedPromotionmPhoto,
        ],
      };
    case UPDATE_PROMOTION_PHOTO:
      return {
        ...state,
        promotionPhoto: action.payload.promotionPhoto,
        promotion: action.payload.promotion,
      };
    case REMOVE_PROMOTION_PHOTO:
      return {
        ...state,
        promotion: action.payload.promotion,
        promotionPhoto: undefined,
        promotionPhotos: state.promotionPhotos.filter(
          (photo) => photo._id !== action.payload.removedPromotionPhoto._id
        ),
      };
    case DELETE_PROMOTION:
      return {
        ...state,
        promotions: state.promotions.filter(
          (promotion) => promotion._id !== action.payload.promotionId
        ),
      };
    case PROMOTION_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
