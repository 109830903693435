import React from "react";
import * as Icon from "react-feather";
import moment from "moment-timezone";

const MenuOverviewCard = ({
  menu,
  dateTimeFormat,
  handleArchiveMenu,
  handleManageLocations,
  handleViewMenu,
  handlePublishMenu,
}) => {
  let sectionCount = 0;
  let itemCount = 0;
  let lastModified = menu.updatedAt;
  (menu.sections || [])
    .filter((section) => section.disabled === false)
    .forEach((section) => {
      if (moment(lastModified).isBefore(moment(section.updatedAt))) {
        lastModified = section.updatedAt;
      }
      sectionCount = sectionCount + 1;
      (section.items || []).forEach((item) => {
        if (item.disabled === false) {
          if (moment(lastModified).isBefore(moment(item.updatedAt))) {
            lastModified = section.updatedAt;
          }
          itemCount = itemCount + 1;
        }
      });
    });

  return (
    <div className="card">
      <div className="card-header">
        <div className="row">
          <div className="col-4">
            <h5>{menu.name}</h5>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <div className="btn-toolbar">
              <div className="btn-group-sm mr-2">
                <button
                  className="btn btn-outline-secondary mr-1"
                  onClick={() => handleViewMenu(menu)}
                  data-toggle="tooltip"
                  data-placement="bottom" 
                  title="Edit menu"
                >
                  <Icon.Edit2 size={16} />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-info mr-1"
                  onClick={() => handleManageLocations(menu)}
                  data-toggle="tooltip"
                  data-placement="bottom" 
                  title="Manage locations"
                >
                  <i className="fas fa-map-marker-alt" />
                </button>
                {/* <button
                  type="button"
                  className="btn btn-outline-primary mr-1"
                  onClick={() => handlePublishMenu(menu._id)}
                  data-toggle="tooltip"
                  data-placement="bottom" 
                  title="Publish menu"
                >
                  <Icon.Send size={16} />
                </button> */}
              </div>
              <div className="btn-group-sm">
                <button
                  type="button"
                  className="btn btn-outline-danger mr-1"
                  onClick={() => handleArchiveMenu(menu._id)}
                  data-toggle="tooltip"
                  data-placement="bottom" 
                  title="Archive menu"
                >
                  <Icon.Trash2 size={16} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body text-center">
        <div className="row">
          <div className="col-6 text-muted text-left">Items:</div>
          <div className="col-6 text-left">
            <strong>{itemCount}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-muted text-left">Sections:</div>
          <div className="col-6 text-left">
            <strong>{sectionCount}</strong>
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-muted text-left">Assigned Locations:</div>
          <div className="col-6 text-left">
            <strong>{menu.restaurants.length}</strong>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <div className="row">
          <div className="col-6 text-muted text-left">Last modified:</div>
          <div className="col-6 text-left">
            <strong>{moment(lastModified).format(dateTimeFormat)}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuOverviewCard;
