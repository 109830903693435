import {
  getData,
  postData,
  patchData,
} from "./index";

import {
  UPDATE_COUPON,
  ADD_COUPON,
  FETCH_COUPONS,
  COUPON_ERROR,
  GENERATE_COUPON_CODE,
  FETCH_EMAIL_BY_PHONE,
  FETCH_COUPON_BY_ORDER_NUMBER
} from "./types";

export function addCoupon(tenantId, data, callback) {

  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/coupons`;
  return dispatch =>
    postData(ADD_COUPON, COUPON_ERROR, true, url, dispatch, data, callback);
}

export function updateCoupon(formProps, callback) {
  const couponId = formProps._id;
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/coupons/${couponId}`;
  return dispatch =>
    patchData(UPDATE_COUPON, COUPON_ERROR, true, url, dispatch, formProps, callback);
}

export function fetchCoupons(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/coupons`;
  return dispatch =>
    getData(FETCH_COUPONS, COUPON_ERROR, true, url, dispatch);
}

export function generateCouponCode(tenantId, couponTypeCode) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/generate-coupon-code?couponTypeCode=${couponTypeCode}`;
  return dispatch =>
    getData(GENERATE_COUPON_CODE, COUPON_ERROR, true, url, dispatch);
}

export function fetchEmailByPhone(tenantId, phoneNumber) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/email-by-phone?phoneNumber=${phoneNumber}`;
  return dispatch =>
    getData(FETCH_EMAIL_BY_PHONE, COUPON_ERROR, true, url, dispatch);
}

export function fetchCouponByOrderNumber(tenantId, orderNumber) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/coupon-by-order-number?orderNumber=${orderNumber}`;
  return dispatch =>
    getData(FETCH_COUPON_BY_ORDER_NUMBER, COUPON_ERROR, true, url, dispatch);
}

export function searchCoupons(tenantId, couponNumber, phoneNumber, orderNumber) {
  const queryParams = [];
  if (couponNumber) {
    queryParams.push(`couponNumber=${couponNumber}`);
  }
  if (phoneNumber) {
    queryParams.push(`phoneNumber=${phoneNumber}`);
  }
  if (orderNumber) {
    queryParams.push(`orderNumber=${orderNumber}`);
  }
  const queryString = queryParams.join("&");
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/coupons` + ((queryParams.length > 0) ? `?${queryString}` : "");
  return dispatch => getData(FETCH_COUPONS, COUPON_ERROR, true, url, dispatch);
}