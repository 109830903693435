import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";

import { fetchBillingProfile, updateBillingProfile } from "../../actions/tenant";

import { renderPhoneField, renderField, renderEmailField, required, email, phoneNumber } from "../../utils/renderFields";
import normalizePhone from "../../utils/normalizePhone";

const form = reduxForm({
  form: "updateBillingProfile",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }
  if (!formProps.email) {
    errors.email = "Please enter email";
  }
  if (!formProps.address_line_1) {
    errors.address_line_1 = "Please enter address_line_1";
  }
  if (!formProps.city_town) {
    errors.city_town = "Please enter city_town";
  }
  if (!formProps.province) {
    errors.province = "Please enter province";
  }
  if (!formProps.postal_code) {
    errors.postal_code = "Please enter postal_code";
  }
  if (!formProps.country) {
    errors.country = "Please enter country";
  }
  return errors;
}

class UpdateBillingProfile extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.tenant) {
      this.props.fetchBillingProfile(this.props.tenant._id);
    }
  }

  handleFormSubmit(formProps) {
    this.props.updateBillingProfile(this.props.tenant._id, formProps, () => {
      this.props.handleViewProfile()
    });
  }


  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const { handleViewProfile, handleSubmit, pristine, submitting } = this.props;
    return (
      <>
      <div className="row pt-3 pb-6 mb-3 border-bottom">
        <div className="col-12">
          <h3>
            <strong>UPDATE BILLING PROFILE</strong>
          </h3>
        </div>
      </div>

      <form onSubmit={handleSubmit(this.handleFormSubmit)}>
        {this.renderAlert()}
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">
            {`Name`}
          </label>
          <div className="col-sm-8 col-md-6 col-lg-4">
          <Field
              name="name"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Email</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="email"
              className="form-control-plaintext"
              component={renderEmailField}
              validate={[required, email]}
              type="text"
            />
          </div>
        </div>        
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Phone</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="phone"
              className="form-control"
              component={renderPhoneField}
              type="text"
              validate={[phoneNumber]}
              normalize={normalizePhone}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Address Line 1</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="address_line_1"
              className="form-control"
              component={renderField}
              validate={[required]}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Address Line 2</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="lastName"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">City/Town</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="city_town"
              className="form-control"
              component={renderField}
              validate={[required]}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Province</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="province"
              className="form-control"
              component={renderField}
              validate={[required]}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Postal Code</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="postal_code"
              className="form-control"
              component={renderField}
              validate={[required]}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Country</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="country"
              className="form-control"
              component={renderField}
              validate={[required]}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">GST/HST Registration Number (optional)</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="gst_hst_number"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">Provincial Sales Tax Number (optional)</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="pst_number"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-form-label col-sm-4 text-sm-right">QST Registration Number (optional)</label>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <Field
              name="qst_number"
              className="form-control"
              component={renderField}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center border-top pb-3 pt-3">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={handleViewProfile}
            >
              {`Cancel`}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={pristine || submitting}
            >
              {"Save"}
            </button>
          </div>
        </div>
        
      </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.tenant.errorMessage,
    message: state.tenant.message,
    tenant: state.tenant.tenant,
    initialValues: state.tenant.billingProfile,
    billingProfile: state.tenant.billingProfile
  };
};

const mapDispatchToProps = dispatch => ({
  fetchBillingProfile: tenantId => dispatch(fetchBillingProfile(tenantId)),
  updateBillingProfile: (tenantId, formProps, callback) =>
    dispatch(updateBillingProfile(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(UpdateBillingProfile));
