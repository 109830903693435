import React, { Component } from "react";
import AllowedDeliveryMethod from "./AllowedDeliveryMethod";
import AllowedOrderType from "./AllowedOrderType";
import AllowedOrderTime from "./AllowedOrderTime";
import AllowedPaymentMethod from "./AllowedPaymentMethod";
import MinimumOrderAmount from "./MinimumOrderAmount";
import DeliveryConditions from "./DeliveryConditions";
import AllowedOrderChannel from "./AllowedOrderChannel";

class PromoRulesAndRestrictions extends Component {
  render() {
    const {
      sectionNumber,
      currencyCode,
      minimumOrderAmountOption,
      handleMinimumOrderAmountOptionChange,
      minimumOrderAmount,
      handleMinimumOrderAmountChange,
      allowedDeliveryMethod,
      deliveryConditionType,
      handleDeliveryConditionTypeChange,
      handleAllowedDeliveryMethodChange,
      deliveryAreaPostalCodes,
      handleDeliveryAreaPostalCodesChange,
      deliveryDistance,
      handleDeliveryDistanceChange,
      allowedOrderType,
      handleAllowedOrderTypeChange,
      allowedOrderTime,
      handleAllowedOrderTimeChange,
      allowCash,
      handleAllowCashChange,
      allowCod,
      handleAllowCodChange,
      allowPhone,
      handleAllowPhoneChange,
      allowOnline,
      handleAllowOnlineChange,
      allowedChannel,
      handleAllowedChannelChange,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Apply promo deal rules and restrictions (What orders can benefit
            from this promo deal)
          </strong>
        </div>
        <div className="card-body">
          {minimumOrderAmountOption && (
            <MinimumOrderAmount
              currencyCode={currencyCode}
              minimumOrderAmountOption={minimumOrderAmountOption}
              handleMinimumOrderAmountOptionChange={
                handleMinimumOrderAmountOptionChange
              }
              minimumOrderAmount={minimumOrderAmount}
              handleMinimumOrderAmountChange={handleMinimumOrderAmountChange}
            />
          )}

          <AllowedDeliveryMethod
            allowedDeliveryMethod={allowedDeliveryMethod}
            handleAllowedDeliveryMethodChange={
              handleAllowedDeliveryMethodChange
            }
          />

          <DeliveryConditions
            allowedDeliveryMethod={allowedDeliveryMethod}
            handleDeliveryConditionTypeChange={handleDeliveryConditionTypeChange}
            deliveryConditionType={deliveryConditionType}
            deliveryAreaPostalCodes={deliveryAreaPostalCodes}
            handleDeliveryAreaPostalCodesChange={
              handleDeliveryAreaPostalCodesChange
            }
            deliveryDistance={deliveryDistance}
            handleDeliveryDistanceChange={handleDeliveryDistanceChange}
          />

          <AllowedOrderType
            allowedOrderType={allowedOrderType}
            handleAllowedOrderTypeChange={handleAllowedOrderTypeChange}
          />
          <AllowedOrderTime
            allowedOrderTime={allowedOrderTime}
            handleAllowedOrderTimeChange={handleAllowedOrderTimeChange}
          />
          <AllowedPaymentMethod
            allowCash={allowCash}
            handleAllowCashChange={handleAllowCashChange}
            allowCod={allowCod}
            handleAllowCodChange={handleAllowCodChange}
            allowPhone={allowPhone}
            handleAllowPhoneChange={handleAllowPhoneChange}
            allowOnline={allowOnline}
            handleAllowOnlineChange={handleAllowOnlineChange}
          />
          <AllowedOrderChannel
            allowedChannel={allowedChannel}
            handleAllowedChannelChange={handleAllowedChannelChange}
          />
        </div>
      </div>
    );
  }
}

export default PromoRulesAndRestrictions;
