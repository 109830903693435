import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DateTime from "react-datetime";

import Switch from "react-switch";

import { updateRegularBusinessHours } from "../../actions/restaurant";
import * as MdIcons from "react-icons/md";
export class RegularBusinessHours extends Component {
  constructor(props) {
    super(props);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleMondayOpenTimeChange = this.handleMondayOpenTimeChange.bind(
      this
    );
    this.handleMondayCloseTimeChange = this.handleMondayCloseTimeChange.bind(
      this
    );
    this.handleTuesdayOpenTimeChange = this.handleTuesdayOpenTimeChange.bind(
      this
    );
    this.handleTuesdayCloseTimeChange = this.handleTuesdayCloseTimeChange.bind(
      this
    );
    this.handleWednesdayOpenTimeChange = this.handleWednesdayOpenTimeChange.bind(
      this
    );
    this.handleWednesdayCloseTimeChange = this.handleWednesdayCloseTimeChange.bind(
      this
    );
    this.handleThursdayOpenTimeChange = this.handleThursdayOpenTimeChange.bind(
      this
    );
    this.handleThursdayCloseTimeChange = this.handleThursdayCloseTimeChange.bind(
      this
    );
    this.handleFridayOpenTimeChange = this.handleFridayOpenTimeChange.bind(
      this
    );
    this.handleFridayCloseTimeChange = this.handleFridayCloseTimeChange.bind(
      this
    );
    this.handleSaturdayOpenTimeChange = this.handleSaturdayOpenTimeChange.bind(
      this
    );
    this.handleSaturdayCloseTimeChange = this.handleSaturdayCloseTimeChange.bind(
      this
    );
    this.handleSundayOpenTimeChange = this.handleSundayOpenTimeChange.bind(
      this
    );
    this.handleSundayCloseTimeChange = this.handleSundayCloseTimeChange.bind(
      this
    );

    this.handleSave = this.handleSave.bind(this);

    const regular_business_hours = props.restaurant.regular_business_hours;

    this.state = {
      monday_is_open: regular_business_hours
        ? regular_business_hours.monday.is_open
        : undefined,
      monday_open_time: regular_business_hours
        ? regular_business_hours.monday.open_time
        : undefined,
      monday_close_time: regular_business_hours
        ? regular_business_hours.monday.close_time
        : undefined,
      tuesday_is_open: regular_business_hours
        ? regular_business_hours.tuesday.is_open
        : undefined,
      tuesday_open_time: regular_business_hours
        ? regular_business_hours.tuesday.open_time
        : undefined,
      tuesday_close_time: regular_business_hours
        ? regular_business_hours.tuesday.close_time
        : undefined,
      wednesday_is_open: regular_business_hours
        ? regular_business_hours.wednesday.is_open
        : undefined,
      wednesday_open_time: regular_business_hours
        ? regular_business_hours.wednesday.open_time
        : undefined,
      wednesday_close_time: regular_business_hours
        ? regular_business_hours.wednesday.close_time
        : undefined,
      thursday_is_open: regular_business_hours
        ? regular_business_hours.thursday.is_open
        : undefined,
      thursday_open_time: regular_business_hours
        ? regular_business_hours.thursday.open_time
        : undefined,
      thursday_close_time: regular_business_hours
        ? regular_business_hours.thursday.close_time
        : undefined,
      friday_is_open: regular_business_hours
        ? regular_business_hours.friday.is_open
        : undefined,
      friday_open_time: regular_business_hours
        ? regular_business_hours.friday.open_time
        : undefined,
      friday_close_time: regular_business_hours
        ? regular_business_hours.friday.close_time
        : undefined,
      saturday_is_open: regular_business_hours
        ? regular_business_hours.saturday.is_open
        : undefined,
      saturday_open_time: regular_business_hours
        ? regular_business_hours.saturday.open_time
        : undefined,
      saturday_close_time: regular_business_hours
        ? regular_business_hours.saturday.close_time
        : undefined,
      sunday_is_open: regular_business_hours
        ? regular_business_hours.sunday.is_open
        : undefined,
      sunday_open_time: regular_business_hours
        ? regular_business_hours.sunday.open_time
        : undefined,
      sunday_close_time: regular_business_hours
        ? regular_business_hours.sunday.close_time
        : undefined
    };
  }

  componentDidMount() {}

  handleToggle(dayOfWeek) {
    if (dayOfWeek === 0) {
      this.setState(prevState => ({
        sunday_is_open: !prevState.sunday_is_open
      }));
    }
    if (dayOfWeek === 1) {
      this.setState(prevState => ({
        monday_is_open: !prevState.monday_is_open
      }));
    }
    if (dayOfWeek === 2) {
      this.setState(prevState => ({
        tuesday_is_open: !prevState.tuesday_is_open
      }));
    }
    if (dayOfWeek === 3) {
      this.setState(prevState => ({
        wednesday_is_open: !prevState.wednesday_is_open
      }));
    }
    if (dayOfWeek === 4) {
      this.setState(prevState => ({
        thursday_is_open: !prevState.thursday_is_open
      }));
    }
    if (dayOfWeek === 5) {
      this.setState(prevState => ({
        friday_is_open: !prevState.friday_is_open
      }));
    }
    if (dayOfWeek === 6) {
      this.setState(prevState => ({
        saturday_is_open: !prevState.saturday_is_open
      }));
    }
  }

  handleMondayOpenTimeChange(value) {
    this.setState({ monday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleMondayCloseTimeChange(value) {
    this.setState({ monday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleTuesdayCloseTimeChange(value) {
    this.setState({ tuesday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleTuesdayOpenTimeChange(value) {
    this.setState({ tuesday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleWednesdayOpenTimeChange(value) {
    this.setState({ wednesday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleWednesdayCloseTimeChange(value) {
    this.setState({ wednesday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleThursdayOpenTimeChange(value) {
    this.setState({ thursday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleThursdayCloseTimeChange(value) {
    this.setState({ thursday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleFridayOpenTimeChange(value) {
    this.setState({ friday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleFridayCloseTimeChange(value) {
    this.setState({ friday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleSaturdayCloseTimeChange(value) {
    this.setState({ saturday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleSaturdayOpenTimeChange(value) {
    this.setState({ saturday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleSundayOpenTimeChange(value) {
    this.setState({ sunday_open_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleSundayCloseTimeChange(value) {
    this.setState({ sunday_close_time: moment(value).format(this.props.restaurant.settings.time_format) });
  }

  handleSave() {
    const data = {
      sunday: {
        is_open: this.state.sunday_is_open,
        open_time: this.state.sunday_open_time,
        close_time: this.state.sunday_close_time
      },
      monday: {
        is_open: this.state.monday_is_open,
        open_time: this.state.monday_open_time,
        close_time: this.state.monday_close_time
      },
      tuesday: {
        is_open: this.state.tuesday_is_open,
        open_time: this.state.tuesday_open_time,
        close_time: this.state.tuesday_close_time
      },
      wednesday: {
        is_open: this.state.wednesday_is_open,
        open_time: this.state.wednesday_open_time,
        close_time: this.state.wednesday_close_time
      },
      thursday: {
        is_open: this.state.thursday_is_open,
        open_time: this.state.thursday_open_time,
        close_time: this.state.thursday_close_time
      },
      friday: {
        is_open: this.state.friday_is_open,
        open_time: this.state.friday_open_time,
        close_time: this.state.friday_close_time
      },
      saturday: {
        is_open: this.state.saturday_is_open,
        open_time: this.state.saturday_open_time,
        close_time: this.state.saturday_close_time
      }
    };


    this.props.updateRegularBusinessHours(
      this.props.restaurant._id,
      data,
      () => {
        this.props.handleViewBusinessHours();
      }
    );
  }

  render() {
    const { handleViewBusinessHours } = this.props;
    if (!this.props.restaurant) {
      return null;
    }
    return (
      <React.Fragment>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
              <MdIcons.MdLocationPin />
                {` `}
                <span className="d-none d-sm-inline">{this.props.restaurant.name}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" onClick={handleViewBusinessHours}>
                <i className="fas fa-business-time" />
                {` `}
                <span className="d-none d-sm-inline">{`Business Hours`}</span>
              </button>
            </li>
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light" disabled>
                <i className="fas fa-business-time" />
                {` `}
                <span className="d-none d-sm-inline">Regular Hours</span>
              </button>
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Sunday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(0)}
                    checked={this.state.sunday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.sunday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.sunday_open_time}
                      onChange={this.handleSundayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.sunday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.sunday_close_time}
                      onChange={this.handleSundayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Monday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(1)}
                    checked={this.state.monday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.monday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.monday_open_time}
                      onChange={this.handleMondayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.monday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.monday_close_time}
                      onChange={this.handleMondayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Tuesday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(2)}
                    checked={this.state.tuesday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.tuesday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.tuesday_open_time}
                      onChange={this.handleTuesdayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.tuesday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.tuesday_close_time}
                      onChange={this.handleTuesdayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Wednesday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(3)}
                    checked={this.state.wednesday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.wednesday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.wednesday_open_time}
                      onChange={this.handleWednesdayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.wednesday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.wednesday_close_time}
                      onChange={this.handleWednesdayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Thursday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(4)}
                    checked={this.state.thursday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.thursday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.thursday_open_time}
                      onChange={this.handleThursdayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.thursday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.thursday_close_time}
                      onChange={this.handleThursdayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Friday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(5)}
                    checked={this.state.friday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.friday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.friday_open_time}
                      onChange={this.handleFridayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.friday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.friday_close_time}
                      onChange={this.handleFridayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-xl-4">
            <div className="card">
              <div className="card-header text-center">
                <h5>
                  Saturday {` `}
                  <Switch
                    className="align-middle"
                    onChange={() => this.handleToggle(6)}
                    checked={this.state.saturday_is_open}
                  />
                </h5>
              </div>
              <div className="card-body">
                <div className="form-row text-left">
                  <div className="form-group col-6">
                    <label>Open Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.saturday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.saturday_open_time}
                      onChange={this.handleSaturdayOpenTimeChange}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label>Close Time</label>
                    <DateTime
                      viewMode={"time"}
                      dateFormat={false}
                      inputProps={{ disabled: !this.state.saturday_is_open }}
                      timeConstraints={this.timeConstraints}
                      value={this.state.saturday_close_time}
                      onChange={this.handleSaturdayCloseTimeChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={handleViewBusinessHours}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={this.handleSave}
            >
              {"Save"}
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    restaurant: state.tenant.restaurant
  };
};

const mapDispatchToProps = dispatch => ({
  updateRegularBusinessHours: (restaurantId, data, callback) =>
    dispatch(updateRegularBusinessHours(restaurantId, data, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegularBusinessHours);
