import React, { Component } from "react";

import $ from "jquery";
import * as Icon from "react-feather";
import KitchenReceipt from "./KitchenReceipt";

import { printOrder } from "../Print/PrintOrder";

export class PrintKitchenReceipt extends Component {
  componentDidMount() {

  }

  handleFormSubmit(formProps) {
    $(".close").click();
  }

  render() {
    const { order, coupon, restaurant, tenant } = this.props;

    return (
      <>
        <button
          type="button"
          className="btn btn-outline-info mr-2"
          data-toggle="modal"
          data-target="#kitchenReceiptModal"
          data-backdrop="static"
        >
          <i className="fas fa-receipt d-inline mr-1"></i>
          <span className="d-none d-lg-inline">Kitchen Receipt</span>
        </button>

        <div
          className="modal fade"
          id="kitchenReceiptModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="#kitchenReceiptModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="kitchenReceiptModalLabel">
                  {`Kitchen Receipt`}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                <KitchenReceipt
                  order={order}
                  restaurant={restaurant}
                />
              </div>

              <div className="modal-footer d-block text-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary mr-2"
                  data-dismiss="modal"
                >
                  Close
                </button>
                {restaurant.settings.printing_settings.enable_printing === true &&
                  <button type="submit" className="btn btn-primary" onClick={() => printOrder(order, coupon, restaurant, tenant, false, true, false)}>
                    <Icon.Printer size={16} /> {` `}
                    {`Print`}
                  </button>
                }

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PrintKitchenReceipt;
