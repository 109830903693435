import React from "react";
import Numeral from "numeral";
import MenuItemTags from "./MenuItemTags";

import spicy_mild_img from "../../assets/spicy_mild_16.png";
import spicy_medium_img from "../../assets/spicy_medium_16.png";

import moment from "moment-timezone";

import sold_out_img from "../../assets/sold_out_48_32.png";

const MenuItemTileView = ({
  tenant,
  restaurant,
  menu,
  section,
  items = [],
  handleViewMenuItem,
}) => {
  return (
    <div className="row">
      {items.map((menuItem, index) => (
        <div className="col-4" key={menuItem._id}>
          <div
            className="card mb-4 clickable-card"
            key={menuItem._id}
            onClick={() => handleViewMenuItem(menuItem)}
          >
            {menuItem.photos &&
              menuItem.photos.map((itemPhoto) => (
                <img
                  className="img-fluid mb-2"
                  src={
                    process.env.REACT_APP_S3_BASE_URL +
                    `${tenant.slug}/${itemPhoto.url}`
                  }
                  alt="Menu Item"
                />
              ))}

            <div className="card-body">
              <h5 className="card-title">
                {menuItem.cd} {menuItem.cd ? "." : ""} {menuItem.name}
              </h5>
              <p className="card-text text-muted">{menuItem.description}</p>
              {menuItem && menuItem.tags && (
                <MenuItemTags tags={menuItem.tags} />
              )}
              {menuItem &&
                menuItem.items &&
                menuItem.items.map((item, index) => (
                  <div key={index}>
                    <small className="text-muted">{item.name}</small>
                    {item.spicy && item.spicy === "mild" && (
                      <img src={spicy_mild_img} alt="" />
                    )}
                    {item.spicy && item.spicy === "medium" && (
                      <img src={spicy_medium_img} alt="" />
                    )}
                    {item.spicy && item.spicy === "hot" && (
                      <>
                        <img src={spicy_medium_img} alt="" />
                        <img src={spicy_medium_img} alt="" />
                      </>
                    )}
                  </div>
                ))}
              {menuItem.sold_out_until &&
                moment(menuItem.sold_out_until)
                  .tz(restaurant.settings.time_zone)
                  .isAfter(moment().tz(restaurant.settings.time_zone)) && (
                  <div className="pt-3 pb-3">
                    <alert className="alert alert-warning">
                      <img
                        src={sold_out_img}
                        alt={"Sold out"}
                        className="mr-3"
                      />
                      <strong>{`Sold out until ${moment(menuItem.sold_out_until)
                        .tz(restaurant.settings.time_zone)
                        .format(restaurant.settings.datetime_format)}`}</strong>
                    </alert>
                  </div>
                )}
              {menuItem.price && (
                <p className="card-text">
                  <small className="text-muted">
                    {Numeral(menuItem.price).format("$0,0.00")}
                  </small>
                </p>
              )}
              <ul className="list-unstyled">
                {menuItem.sizes &&
                  menuItem.sizes.map((itemSize) => (
                    <li key={itemSize._id} className="card-text">
                      <small className="text-muted">
                        {itemSize.name} :{" "}
                        {Numeral(itemSize.price).format("$0,0.00")}
                      </small>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuItemTileView;
