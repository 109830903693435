import { combineReducers } from 'redux';  
import { reducer as formReducer } from 'redux-form';  
import { connectRouter } from 'connected-react-router';

import authReducer from './auth_reducer';
import employeeReducer from './employee_reducer';
import restaurantReducer from './restaurant_reducer';
import orderReducer from './order_reducer';
import menuReducer from './menu_reducer';
import reservationReducer from './reservation_reducer';
import cartReducer from './cart_reducer';
import giftcardProductReducer from './giftcardproduct_reducer';
import giftcardOrderReducer from './gift_card_order_reducer';
import giftcardReducer from './gift_card_reducer';
import announcementReducer from './announcement_reducer';
import tenantReducer from './tenant_reducer';
import promotionReducer from './promotion_reducer';
import imageReducer from './image_reducer';
import historicalOrderReducer from './historical_order_reducer';
import couponReducer from './coupon_reducer';

import {
  SAVE_ORDER,
} from "../actions/types";

const rootReducer = (history) => combineReducers({  
  router: connectRouter(history),
  form: formReducer.plugin({
    takeOrder: (state, action) => {
      switch(action.type) {
        case SAVE_ORDER:
          return undefined;
        default:
          return state;
      }
    }
  }),
  auth: authReducer,
  employee: employeeReducer,
  restaurant: restaurantReducer,
  cart: cartReducer,
  order: orderReducer,
  menu: menuReducer,
  reservation: reservationReducer,
  giftCardProduct: giftcardProductReducer,
  giftCardOrder: giftcardOrderReducer,
  giftCard: giftcardReducer,
  announcement: announcementReducer,
  tenant: tenantReducer,
  promotion: promotionReducer,
  imagelibrary: imageReducer,
  historicalOrder: historicalOrderReducer,
  coupon: couponReducer
});

export default rootReducer;  
