import React from "react";
import * as Icon from "react-feather";
import nophoto from "../../assets/nophoto.png";

const CustomerInfoCard = ({ order, restaurant }) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12 text-center">
            <img className="rounded-circle" src={nophoto} alt="Nophoto" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">{order.nameOrderedUnder}</div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Icon.Phone size={16} /> {order.phone}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Icon.MapPin size={16} />{" "}
            {order.pickupLocation ? order.pickupLocation.name : (order.deliveryAddress ? order.deliveryAddress.address + " " + order.deliveryAddress.postalCode : "")}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12">
            <Icon.Clock size={16} />{" "}
            {moment(order.pickupTime).tz(restaurant.settings.time_zone).format(restaurant.settings.datetime_format)}
          </div>
        </div> */}
        <div className="row">
          <div className="col-12">
            <Icon.Mail size={16} /> {order.email}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfoCard;
