import {
  FETCH_TENANT,
  ADD_RESTAURANT,
  SELECT_RESTAURANT,
  UPDATE_TENANT_SETTINGS,
  UPDATE_LOGO,
  UPDATE_CAROUSEL,
  UPDATE_TENANT_INFO,
  UPDATE_BANNER,
  UPDATE_POSTER,
  UPDATE_GALLERY,
  UPDATE_FAVICON,
  TENANT_ERROR,
  FETCH_CUSTOMERS,
  FETCH_GUESTS,
  FETCH_CUSTOMER_ORDERS,
  UPDATE_CAROUSEL_PHOTO,
  UPDATE_GALLERY_PHOTO,
  FETCH_REGISTERED_USERS,
  FETCH_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
  CLEAR_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
  FETCH_SUBSCRIPTION,
  FETCH_INVOICES,
  FETCH_CURRENT_INVOICE,
  FETCH_BILLING_PROFILE,
  UPDATE_BILLING_PROFILE,
  FETCH_COUPONS,
  FETCH_PAYMENTS,
  BLOCK_CUSTOMER,
  UNBLOCK_CUSTOMER,
  FETCH_CUSTOMER,
  FETCH_RESTAURANT,
  UPDATE_RESTAURANT_INFO,
} from "../actions/types";

const INITIAL_STATE = {
  message: "",
  error: "",
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload.restaurant,
      };
    case FETCH_TENANT:
      let _restaurants = action.payload.tenant
        ? action.payload.tenant.restaurants
        : [];
      let _restaurant = _restaurants.length > 0 ? _restaurants[0] : undefined;
      return {
        ...state,
        tenant: action.payload.tenant,
        restaurants: _restaurants,
        restaurant: _restaurant,
      };
    case ADD_RESTAURANT:
      return {
        ...state,
        restaurants: [...state.restaurants, action.payload.restaurant],
      };
    case SELECT_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload.restaurant,
      };
    case UPDATE_LOGO:
      return {
        ...state,
        tenant: action.payload.tenant,
        logo: action.payload.tenant.logo,
      };
    case UPDATE_FAVICON:
      return {
        ...state,
        tenant: action.payload.tenant,
        favicon: action.payload.tenant.favicon,
      };
    case UPDATE_BANNER:
      return {
        ...state,
        tenant: action.payload.tenant,
        banner: action.payload.tenant.banner,
      };
    case UPDATE_POSTER:
      return {
        ...state,
        tenant: action.payload.tenant,
        poster: action.payload.tenant.poster,
      };
    case UPDATE_CAROUSEL:
      return {
        ...state,
        tenant: action.payload.tenant,
        carousel_items: action.payload.tenant.carousel_items,
        carouselPhotoUr: undefined,
      };
    case UPDATE_GALLERY:
      return {
        ...state,
        tenant: action.payload.tenant,
        gallery: action.payload.tenant.gallery,
      };
    case UPDATE_TENANT_INFO:
      return {
        ...state,
        tenant: action.payload.tenant,
      };
    case UPDATE_TENANT_SETTINGS:
      return {
        ...state,
        message: action.payload.message,
        tenant: action.payload.tenant,
        restaurant: action.payload.restaurant
          ? action.payload.restaurant
          : state.restaurant,
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload.customerViews,
      };
    case FETCH_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customerView,
        orders: action.payload.orders,
        favoriteItems: action.payload.favoriteItems,
        analyticsPerDay: action.payload.analyticsPerDay,
        analyticsPerMenu: action.payload.analyticsPerMenu,
      };
    case BLOCK_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customerView,
        customers: state.customers.map((customer) => {
          if (customer._id === action.payload.customerView._id) {
            return action.payload.customerView;
          } else {
            return customer;
          }
        }),
      };
    case UNBLOCK_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customerView,
        customers: state.customers.map((customer) => {
          if (customer._id === action.payload.customerView._id) {
            return action.payload.customerView;
          } else {
            return customer;
          }
        }),
      };
    case FETCH_GUESTS:
      return {
        ...state,
        guests: action.payload.guests,
      };
    case FETCH_REGISTERED_USERS:
      return {
        ...state,
        registeredUsers: action.payload.registeredUsers,
      };
    case FETCH_CUSTOMER_FREQUENTLY_ORDERED_ITEMS:
      return {
        ...state,
        customerFrequentlyOrderedItems: action.payload,
      };
    case CLEAR_CUSTOMER_FREQUENTLY_ORDERED_ITEMS:
      return {
        ...state,
        customerFrequentlyOrderedItems: undefined,
      };
    case TENANT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case FETCH_CUSTOMER_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
      };
    case UPDATE_CAROUSEL_PHOTO:
      return {
        ...state,
        carouselPhotoUrl: action.payload.photoUrl,
      };
    case UPDATE_GALLERY_PHOTO:
      return {
        ...state,
        galleryPhotoUrl: action.payload.photoUrl,
      };
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload.subscription,
      };
    case FETCH_INVOICES:
      return {
        ...state,
        invoices: action.payload.invoices,
      };
    case FETCH_PAYMENTS:
      return {
        ...state,
        payments: action.payload.payments,
      };
    case FETCH_CURRENT_INVOICE:
      return {
        ...state,
        currentInvoice: action.payload.invoice,
      };
    case FETCH_BILLING_PROFILE:
      return {
        ...state,
        billingProfile: action.payload.billingProfile,
      };
    case UPDATE_BILLING_PROFILE:
      return {
        ...state,
        billingProfile: action.payload.billingProfile,
      };
    case FETCH_COUPONS:
      return {
        ...state,
        coupons: action.payload.coupons,
      };
    case UPDATE_RESTAURANT_INFO:
      return {
        ...state,
        message: action.payload.message,
        restaurant: action.payload.restaurant,
        restaurants: state.restaurants.map((restaurant) => {
          if (restaurant._id === action.payload.restaurant._id) {
            return action.payload.restaurant;
          } else {
            return restaurant;
          }
        }),
      };
    default:
      return state;
  }
}
