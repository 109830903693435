import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  fetchMenuItem,
  updateMenuItemAssignedLocations,
} from "../../actions/menu";

const form = reduxForm({
  form: "manageMenuItemLocations",
  validate,
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class ManageMenuItemLocations extends Component {
  constructor(props) {
    super(props);

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    const assignedLocations = {};
    if (this.props.menuItem?.restaurants) {
      for (const restaurantId of this.props.menuSection.restaurants) {
        assignedLocations[restaurantId] = true;
      }
    }

    this.state = {
      ...assignedLocations,
    };
  }

  componentDidMount() {
    if (this.props.menu && this.props.menuSection && this.props.menuItem) {
      this.props.fetchMenuItem(
        this.props.menu._id,
        this.props.menuSection._id,
        this.props.menuItem._id
      );
    }
  }

  handleSelectionChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value,
    });
  }

  handleFormSubmit() {

    this.props.updateMenuItemAssignedLocations(
      this.props.menu._id,
      this.props.menuSection._id,
      this.props.menuItem._id,
      this.state,
      () => {
        this.props.handleViewMenuItem(this.props.menuItem);
      }
    );
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      restaurants = [],
      menu,
      menuSection,
      menuItem,
      handleViewMenus,
      handleViewMenu,
      handleSubmit,
      handleViewMenuSection,
      handleViewMenuItem,
    } = this.props;


    
    if (!menuSection) {
      return null;
    }

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button type="button" className="btn btn-light">
                <i className="fas fa-bars" />
                {` `}
                <span
                  className="d-none d-sm-inline"
                  onClick={handleViewMenus}
                >{`Menus`}</span>
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenu(menu)}
              >
                {menu.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuSection(menuSection)}
              >
                {menuSection.name}
              </button>
            </li>
            <li className="breadcrumb-item" aria-current="page">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleViewMenuItem(menuItem)}
              >
                {menuItem.name}
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                Manage Locations
              </button>
            </li>
          </ol>
        </nav>

        {this.renderAlert()}
        <div className="card">
          <div className="card-header">
            <h5>{`Assigned Locations`}</h5>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              {restaurants.map((restaurant) => (
                <div className="form-check mb-3" key={restaurant._id}>
                  <input
                    name={restaurant._id}
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked={(menuItem.restaurants || []).includes(
                      restaurant._id
                    )}
                    onChange={this.handleSelectionChange}
                  />
                  <label className="form-check-label">{restaurant.name}</label>
                </div>
              ))}
              <div className="row pt-3 mt-3 border-top">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-outline-secondary mr-3"
                    onClick={() => handleViewMenuItem()}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {"Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tenant: state.tenant.tenant,
    restaurant: state.tenant.restaurant,
    restaurants: state.tenant.restaurants,
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuSection: state.menu.menuSection,
    menuItem: state.menu.menuItem,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  updateMenuItemAssignedLocations: (
    menuId,
    menuSectionId,
    menuItemId,
    locationSelection,
    callback
  ) =>
    dispatch(
      updateMenuItemAssignedLocations(
        menuId,
        menuSectionId,
        menuItemId,
        locationSelection,
        callback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(ManageMenuItemLocations));
