import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import $ from "jquery";
import * as Icon from "react-feather";
import { renderField } from "../../utils/renderFields";
import { addMenuItemItem } from "../../actions/menu";

const form = reduxForm({
  form: "menuItemItem",
  validate
});

function validate(formProps) {
  const errors = {};

  if (!formProps.name) {
    errors.name = "Please enter name";
  }

  if (!formProps.price) {
    errors.price = "Please enter price";
  }

  return errors;
}

class MenuItemItemAddModal extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.addMenuItemItem(
      this.props.menuId,
      this.props.menuSectionId,
      this.props.menuItemId,
      formProps
    );
    $(".close").click();
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleSubmit,
    } = this.props;
    return (
      <>
      <button
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#menuItemItemAddModal"
        data-backdrop="static"
      >
        <Icon.Plus size={16} />
        {` `}New Item
        </button>

      <div
        className="modal fade"
        id="menuItemItemAddModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="#menuItemItemAddModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSubmit(this.handleFormSubmit)}>
              <div className="modal-header bg-primary">
                <h5 className="modal-title" id="menuItemItemAddModalLabel">
                  {"Add Item"}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-left">
                {this.renderAlert()}
                <div className="form-group">
                  <label className="col-form-label">Name</label>
                  <Field
                    name="name"
                    className="form-control"
                    component={renderField}
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Spicy</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="not"
                      />
                      <label className="form-check-label">Not</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="mild"
                      />
                      <label className="form-check-label">Mild</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="medium"
                      />
                      <label className="form-check-label">Medium</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="spicy"
                        className="form-check-input"
                        component="input"
                        type="radio"
                        value="hot"
                      />
                      <label className="form-check-label">Hot</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                  </button>
                <button type="submit" className="btn btn-primary">
                  {"Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    initialValues: {}
  };
};

const mapDispatchToProps = dispatch => ({
  addMenuItemItem: (menuId, menuSectionId, menuItemId, formProps) =>
    dispatch(addMenuItemItem(menuId, menuSectionId, menuItemId, formProps))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(MenuItemItemAddModal));
