import React, { Component } from "react";
import { connect } from "react-redux";

import Numeral from "numeral";

import * as Icon from "react-feather";

import MenuItemSizeAddModal from "./MenuItemSizeAddModal";
import { fetchMenuItem, removeMenuItemSize } from "../../actions/menu";
import MenuItemSizeEditModal from "./MenuItemSizeEditModal";

class MenuItemSizesCard extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveItemSize = this.handleRemoveItemSize.bind(this);
  }

  handleRemoveItemSize(menuId, menuSectionId, menuItemId, menuItemSizeId) {
    this.props.removeMenuItemSize(
      menuId,
      menuSectionId,
      menuItemId,
      menuItemSizeId
    );
  }

  generateDataTarget(menuItemSizeId) {
    return "#menuItemSizeEditModal" +menuItemSizeId
  }

  render() {
    const { menuItem, menuId, menuSectionId, menuItemId } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Sizes</h5>
            </div>
            <div className="col-6 text-right">
              <MenuItemSizeAddModal
                menuId={menuId}
                menuSectionId={menuSectionId}
                menuItemId={menuItemId}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <ul className="list-unstyled">
            {menuItem &&
              menuItem.sizes &&
              menuItem.sizes.map((itemSize, index) => (
                <li key={itemSize._id}>
                  <>
                    <div className="row">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          title="Remove"
                          onClick={() =>
                            this.handleRemoveItemSize(
                              menuId,
                              menuSectionId,
                              menuItemId,
                              itemSize._id
                            )
                          }
                        >
                          <Icon.Trash2 size={16} />
                        </button>
                        {` `}
                        <button
                          type="button"
                          className="btn btn-light"
                          data-toggle="modal"
                          data-target={this.generateDataTarget(itemSize._id)}
                          data-backdrop="static"
                          
                        >
                          <Icon.Edit2 size={16} />
                        </button>
                        <MenuItemSizeEditModal
                          menuId={menuId}
                          menuSectionId={menuSectionId}
                          menuItemId={menuItemId}
                          menuItemSizeId={itemSize._id}
                          menuItemSize={itemSize}
                        />
                      </div>
                      <div className="col-6">{itemSize.name}</div>
                      <div className="col-3 text-right">
                        {Numeral(itemSize.price).format("$0,0.00")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItem: state.menu.menuItem
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  removeMenuItemSize: (menuId, menuSectionId, menuItemId, menuItemSizeId) =>
    dispatch(
      removeMenuItemSize(menuId, menuSectionId, menuItemId, menuItemSizeId)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemSizesCard);
