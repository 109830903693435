import { getData, postData, patchData, patchData2 } from "./index";

import {
  FETCH_TENANT,
  SELECT_RESTAURANT,
  ADD_RESTAURANT,
  UPDATE_LOGO,
  UPDATE_FAVICON,
  UPDATE_BANNER,
  UPDATE_CAROUSEL,
  UPDATE_TENANT_INFO,
  UPDATE_TENANT_SETTINGS,
  UPDATE_GALLERY,
  UPDATE_POSTER,
  FETCH_CUSTOMERS,
  FETCH_GUESTS,
  TENANT_ERROR,
  FETCH_CUSTOMER_ORDERS,
  UPDATE_CAROUSEL_PHOTO,
  UPDATE_GALLERY_PHOTO,
  FETCH_REGISTERED_USERS,
  FETCH_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
  CLEAR_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
  FETCH_SUBSCRIPTION,
  FETCH_CURRENT_INVOICE,
  FETCH_INVOICES,
  FETCH_BILLING_PROFILE,
  UPDATE_BILLING_PROFILE,
  FETCH_PAYMENTS,
  FETCH_VALID_PROMOTIONS,
  BLOCK_CUSTOMER,
  UNBLOCK_CUSTOMER,
  FETCH_CUSTOMER,
} from "./types";

export function fetchTenant() {
  const url = process.env.REACT_APP_API_BASE + `/api/eatery/tenant`;
  return (dispatch) => getData(FETCH_TENANT, TENANT_ERROR, true, url, dispatch);
}

export function selectRestaurant(restaurant, callback) {
  if (callback) {
    callback();
  }
  return (dispatch) =>
    dispatch({ type: SELECT_RESTAURANT, payload: { restaurant: restaurant } });
}

export function addRestaurant(
  tenantId,
  {
    name,
    store_number,
    brief_description,
    full_description,
    address_1,
    _address2,
    city_town,
    state_province,
    postal_code,
    country,
    email,
    phone,
    fax,
  },
  callback
) {
  let data = {
    name,
    store_number,
    brief_description,
    full_description,
    address_1,
    _address2,
    city_town,
    state_province,
    postal_code,
    country,
    email,
    phone,
    fax,
  };
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/restaurants`;
  return (dispatch) =>
    postData(ADD_RESTAURANT, TENANT_ERROR, true, url, dispatch, data, callback);
}

export function updateLogo(tenantId, { imageUrl }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/logo`;

  return (dispatch) =>
    patchData(
      UPDATE_LOGO,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { imageUrl: imageUrl },
      callback
    );
}

export function updateFavicon(tenantId, { imageUrl }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/favicon`;

  return (dispatch) =>
    patchData(
      UPDATE_FAVICON,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { imageUrl: imageUrl },
      callback
    );
}

export function updateBanner(tenantId, { imageUrl }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/banner`;

  return (dispatch) =>
    patchData(
      UPDATE_BANNER,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { imageUrl: imageUrl },
      callback
    );
}

export function addCarousel(
  tenantId,
  { sequence, caption, description },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/carousel`;

  let data = {
    sequence: sequence,
    caption: caption,
    description: description,
    photoUrl: photoUrl,
  };
  return (dispatch) =>
    patchData(
      UPDATE_CAROUSEL,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateCarousel(
  tenantId,
  carouselItemId,
  { sequence, caption, description },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/carousel/${carouselItemId}`;
  let data = {
    sequence: sequence,
    caption: caption,
    description: description,
    photoUrl: photoUrl,
  };
  return (dispatch) =>
    patchData(
      UPDATE_CAROUSEL,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function deleteCarousel(tenantId, carouselItemId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/carousel/${carouselItemId}/delete`;

  return (dispatch) =>
    patchData(UPDATE_CAROUSEL, TENANT_ERROR, true, url, dispatch, {}, callback);
}

export function updateTenantInfo(
  {
    _id,
    name,
    slug,
    slogan,
    brief_description,
    full_description,
    email,
    phone,
    fax,
    website_url,
    facebook_url,
    twitter_url,
    wechat_url,
  },
  callback
) {
  let data = {
    name,
    slug,
    slogan,
    brief_description,
    full_description,
    email,
    phone,
    fax,
    website_url,
    facebook_url,
    twitter_url,
    wechat_url,
  };
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${_id}/info`;
  return (dispatch) =>
    patchData(
      UPDATE_TENANT_INFO,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateTenantSettings(tenantId, data, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/settings`;
  return (dispatch) =>
    patchData(
      UPDATE_TENANT_SETTINGS,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function updateTenantSettingDescriptions(tenantId, formProps, callback) {
  let formData = new FormData();

  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/settings`;

  formData.append("pickup_order_title", formProps.pickup_order_title);
  if (formProps.pickup_order_subtitles !== undefined) {
    formProps.pickup_order_subtitles.forEach((element) => {
      formData.append("pickup_order_subtitles[]", element);
    });
  }

  formData.append("delivery_order_title", formProps.delivery_order_title);
  if (formProps.delivery_order_subtitles !== undefined) {
    formProps.delivery_order_subtitles.forEach((element) => {
      formData.append("delivery_order_subtitles[]", element);
    });
  }

  return (dispatch) =>
    patchData2(
      UPDATE_TENANT_SETTINGS,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      formData,
      callback
    );
}

export function addGallery(tenantId, { caption, imageUrl }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/gallery`;

  return (dispatch) =>
    postData(
      UPDATE_GALLERY,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { caption: caption, imageUrl: imageUrl },
      callback
    );
}

export function updateGallery(
  tenantId,
  galleryItemId,
  { caption },
  photoUrl,
  callback
) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/gallery/${galleryItemId}`;

  return (dispatch) =>
    patchData(
      UPDATE_GALLERY,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { caption: caption, photoUrl: photoUrl },
      callback
    );
}

export function deleteGallery(tenantId, galleryItemId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/gallery/${galleryItemId}/delete`;

  return (dispatch) =>
    patchData(UPDATE_GALLERY, TENANT_ERROR, true, url, dispatch, {}, callback);
}

export function updatePoster(tenantId, { imageUrl }, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/poster`;

  return (dispatch) =>
    patchData(
      UPDATE_POSTER,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      { imageUrl: imageUrl },
      callback
    );
}

export function fetchCustomers(tenantId, name, phoneNumber, email) {
  const queryParams = [];
  if (name) {
    queryParams.push(`name=${name}`);
  }
  if (phoneNumber) {
    queryParams.push(`phoneNumber=${phoneNumber}`);
  }
  if (email) {
    queryParams.push(`email=${email}`);
  }

  const queryString = queryParams.join("&");
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/customerviews` +
    (queryParams.length > 0 ? `?${queryString}` : "");
  return (dispatch) =>
    getData(FETCH_CUSTOMERS, TENANT_ERROR, true, url, dispatch);
}

export function fetchCustomer(tenantId, customerviewId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/customerviews/${customerviewId}`;
  return (dispatch) =>
    getData(FETCH_CUSTOMER, TENANT_ERROR, true, url, dispatch, callback);
}

export function blockCustomer(tenantId, customerviewId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/customerviews/${customerviewId}/block`;
  return (dispatch) =>
    patchData(BLOCK_CUSTOMER, TENANT_ERROR, true, url, dispatch, {}, callback);
}

export function unblockCustomer(tenantId, customerviewId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/customerviews/${customerviewId}/unblock`;
  return (dispatch) =>
    patchData(
      UNBLOCK_CUSTOMER,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      {},
      callback
    );
}

export function fetchGuests(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/guests`;
  return (dispatch) => getData(FETCH_GUESTS, TENANT_ERROR, true, url, dispatch);
}

export function fetchRegisteredUsers(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/registered-users`;
  return (dispatch) =>
    getData(FETCH_REGISTERED_USERS, TENANT_ERROR, true, url, dispatch);
}

export function fetchCustomerOrders(tenantId, phone, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/orders?tenantId=${tenantId}&phone=${phone}`;
  return (dispatch) =>
    getData(FETCH_CUSTOMER_ORDERS, TENANT_ERROR, true, url, dispatch, callback);
}

export function updateCarouselPhoto({ photoUrl }, callback) {
  callback();
  return {
    type: UPDATE_CAROUSEL_PHOTO,
    payload: { photoUrl: photoUrl },
  };
}

export function updateGalleryPhoto({ photoUrl }, callback) {
  callback();
  return {
    type: UPDATE_GALLERY_PHOTO,
    payload: { photoUrl: photoUrl },
  };
}

export function fetchCustomerFrequentlyOrderedItems(tenantId, phone, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/customer-frequently-ordered-items?phone=${phone}`;
  return (dispatch) =>
    getData(
      FETCH_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      callback
    );
}

export function clearCustomerFrequentlyOrderedItems() {
  return {
    type: CLEAR_CUSTOMER_FREQUENTLY_ORDERED_ITEMS,
    payload: {},
  };
}

export function fetchSubscription(tenantId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/subscription`;
  return (dispatch) =>
    getData(FETCH_SUBSCRIPTION, TENANT_ERROR, true, url, dispatch, callback);
}

export function fetchInvoices(tenantId, callback) {
  const url =
    process.env.REACT_APP_API_BASE + `/api/eatery/tenants/${tenantId}/invoices`;
  return (dispatch) =>
    getData(FETCH_INVOICES, TENANT_ERROR, true, url, dispatch, callback);
}

export function fetchPayments(tenantId, invoiceId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/invoices/${invoiceId}/payments`;
  return (dispatch) =>
    getData(FETCH_PAYMENTS, TENANT_ERROR, true, url, dispatch, callback);
}

export function fetchCurrentInvoice(tenantId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/current-invoice`;
  return (dispatch) =>
    getData(FETCH_CURRENT_INVOICE, TENANT_ERROR, true, url, dispatch, callback);
}

export function fetchBillingProfile(tenantId, callback) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/billing-profile`;
  return (dispatch) =>
    getData(FETCH_BILLING_PROFILE, TENANT_ERROR, true, url, dispatch, callback);
}

export function updateBillingProfile(tenantId, formProps, callback) {
  let data = formProps;

  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/billing-profile`;

  return (dispatch) =>
    patchData(
      UPDATE_BILLING_PROFILE,
      TENANT_ERROR,
      true,
      url,
      dispatch,
      data,
      callback
    );
}

export function fetchPromotions(tenantId) {
  const url =
    process.env.REACT_APP_API_BASE +
    `/api/eatery/tenants/${tenantId}/promotions`;
  return (dispatch) =>
    getData(FETCH_VALID_PROMOTIONS, TENANT_ERROR, true, url, dispatch);
}
