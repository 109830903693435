import React, { Component } from "react";
import { connect } from "react-redux";

import Numeral from "numeral";

import * as Icon from "react-feather";

import MenuItemOptionAddModal from "./MenuItemOptionAddModal";
import { fetchMenuItem, removeMenuItemOption } from "../../actions/menu";
import MenuItemOptionEditModal from './MenuItemOptionEditModal'
class MenuItemOptionsCard extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveItemOption = this.handleRemoveItemOption.bind(this);
  }

  handleRemoveItemOption(menuId, menuSectionId, menuItemId, menuItemOptionId) {
    this.props.removeMenuItemOption(
      menuId,
      menuSectionId,
      menuItemId,
      menuItemOptionId
    );
  }

  generateDataTarget(menuItemOptionId) {
    return "#menuItemOptionEditModal" +menuItemOptionId
  }

  render() {
    const { menuItem, menuId, menuSectionId, menuItemId } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5>Options</h5>
            </div>
            <div className="col-6 text-right">
              <MenuItemOptionAddModal
                menuId={menuId}
                menuSectionId={menuSectionId}
                menuItemId={menuItemId}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <hr />
            </div>
          </div>
          <ul className="list-unstyled">
            {menuItem &&
              menuItem.options &&
              menuItem.options.map((option, index) => (
                <li key={option._id}>
                  <>
                    <div className="row">
                      <div className="col-3">
                        <button
                          type="button"
                          className="btn btn-light"
                          title="Remove"
                          onClick={() =>
                            this.handleRemoveItemOption(
                              menuId,
                              menuSectionId,
                              menuItemId,
                              option._id
                            )
                          }
                        >
                          <Icon.Trash2 size={16} />
                        </button>
                        {` `}
                        <button
                          type="button"
                          className="btn btn-light"
                          data-toggle="modal"
                          data-target={this.generateDataTarget(option._id)}
                          data-backdrop="static"
                          
                        >
                          <Icon.Edit2 size={16} />
                        </button>
                        <MenuItemOptionEditModal
                          menuId={menuId}
                          menuSectionId={menuSectionId}
                          menuItemId={menuItemId}
                          menuItemOptionId={option._id}
                          menuItemOption={option}
                        />
                      </div>
                      <div className="col-9">
                        <p>{option.name}</p>
                        <p className="text-muted">
                          {option.selection_type} {`Selection:`}
                          {option.items && option.items.map(item => (
                            <span key={item._id}>{` `}{item.name} {` (`} {Numeral(item.additional_cost).format("$0,0.00")} {`) `}</span>
                          ))}
                         </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <hr />
                      </div>
                    </div>
                  </>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorMessage: state.menu.errorMessage,
    message: state.menu.message,
    menuItem: state.menu.menuItem
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMenuItem: (menuId, menuSectionId, menuItemId) =>
    dispatch(fetchMenuItem(menuId, menuSectionId, menuItemId)),
  removeMenuItemOption: (menuId, menuSectionId, menuItemId, menuItemOptionId) =>
    dispatch(
      removeMenuItemOption(menuId, menuSectionId, menuItemId, menuItemOptionId)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuItemOptionsCard);
