import React, { Component } from "react";

class PromoExclusionRules extends Component {
  render() {
    const {
      sectionNumber,
      handleChoiceChange,
      choice,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Set promo deal overlapping or exclusion rules (Optional)
        </strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exclusion_rule_option"
                  value="Not exclusive"
                  onChange={handleChoiceChange}
                  checked={choice === "Not exclusive"}
                />
                <label className="form-check-label">
                  {`Not exclusive (Default)`}
                  <br />
                  <small>
                    This means a client can also make use of other
                  promotions on the same order.
                </small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exclusion_rule_option"
                  value="Exclusive"
                  onChange={handleChoiceChange}
                  checked={choice === "Exclusive"}
                />
                <label className="form-check-label">
                  {`Exclusive`}
                  <br />
                  <small>
                    If you mark this as exclusive then no other promos on
                  the same item are possible.
                </small>
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exclusion_rule_option"
                  value="Master promo deal"
                  onChange={handleChoiceChange}
                  checked={choice === "Master promo deal"}
                />
                <label className="form-check-label">
                  {`Master promo deal`}
                  <br />
                  <small>
                    If marked as master, this promo deal can be redeemed
                  alongside other promo deals even though those are marked
                  as "Exclusive".
                </small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoExclusionRules;