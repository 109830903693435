import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import ImagePicker from "./ImagePicker";
import { updateLogo } from "../../actions/tenant";

import "react-datetime/css/react-datetime.css";

const form = reduxForm({
  form: "logoSettings",
  validate
});

function validate(formProps) {
  const errors = {};

  return errors;
}

class LogoSettings extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.state = {
      tenantId: props.tenant._id,
      logo: props.tenant.logo,
      imageUrl: props.tenant.logo ? props.tenant.logo.url : undefined
    };
  }

  componentDidMount() { }

  handleFormSubmit(formProps) {
    this.props.updateLogo(this.props.tenant._id, {imageUrl: this.state.imageUrl}, () => {
      this.props.handleViewSettings();
    });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">
          <span>
            <strong>Error!</strong> {this.props.errorMessage}
          </span>
        </div>
      );
    }
  }

  render() {
    const {
      handleViewSettings,
      handleSubmit,
      tenant,
    } = this.props;

    return (
      <>
        <nav aria-label="breadcrumb" className="pt-3">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleViewSettings}
              >
                <i className="fas fa-cog" />
                {` `}
                <span className="d-none d-sm-inline">{`Settings`}</span>
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <button type="button" className="btn btn-light" disabled>
                <i className="far fa-image" />
                {` `}
                <span className="d-none d-sm-inline">{`Logo`}</span>
              </button>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          {this.renderAlert()}
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              {`Tenant`}
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                value={this.props.tenant.name}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-form-label col-sm-4 text-sm-right">
              {`Logo`}
            </label>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <div className="text-center"
                style={{
                  width: "330px",
                  height: "240px",
                  borderWidth: "2px",
                  borderColor: "rgb(102, 102, 102)",
                  borderStyle: "dashed",
                  borderRadius: "5px",
                  padding: "20px",
                }}>
                {this.state.imageUrl &&
                  <img
                    className="img-fluid"
                    width={229}
                    height={150}
                    src={
                      process.env.REACT_APP_S3_BASE_URL +
                      `${tenant.slug + "/" + this.state.imageUrl
                      }`
                    }
                    alt="Settings"
                  />
                }
                <ImagePicker
                  tenant={tenant}
                  handleImageUrlChange={imageUrl => {
                    this.setState({ imageUrl: imageUrl });
                  }}
                  imageUrl={this.state.imageUrl}
                />

              </div>
              {/* {tenant && tenant.logo && (
              <Field
                name="image"
                imgsrc={tenant.slug + '/logo/' + tenant.logo.url}
                component={renderDropzoneInput}
              />
            )}
            {(!tenant || !tenant.logo) && (
              <Field name="image" component={renderDropzoneInput} />
            )} */}
              {/* <p className="text-muted pt-3">
                Logo will be automaticaly resized to 134 x 61
              </p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center border-top pb-3 pt-3">
              <button
                type="button"
                className="btn btn-outline-secondary mr-3"
                onClick={this.props.handleViewSettings}
              >
                {`Cancel`}
              </button>
              <button type="submit" className="btn btn-primary">
                {"Save"}
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    tenant: state.tenant.tenant,
    errorMessage: state.tenant.error,
    message: state.tenant.message
  };
};

const mapDispatchToProps = dispatch => ({
  updateLogo: (tenantId, formProps, callback) =>
    dispatch(updateLogo(tenantId, formProps, callback))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(form(LogoSettings));
