import React, { Component } from "react";

import "react-widgets/dist/css/react-widgets.css";

import DateTime from "react-datetime";

class PromoWeekday extends Component {
  render() {
    const {
      weekday,
      weekdaySelected,
      handleWeekdaySelectedChange,
      limitedShowtimeOption,
      timeFrom,
      handleTimeFromChange,
      timeUntil,
      handleTimeUntilChange,
    } = this.props;

    return (
      <div className="form-row mt-3">
        <div className="col-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name={`${weekday}_selected`}
              onChange={handleWeekdaySelectedChange}
              defaultChecked={weekdaySelected}
              disabled={
                limitedShowtimeOption !== "Custom selection"}
            />
            <label className="form-check-label">{weekday.charAt(0).toUpperCase() + weekday.slice(1)}</label>
          </div>
        </div>
        <div className="col-3">
          <DateTime
            name={`${weekday}_from`}
            viewMode={"time"}
            dateFormat={false}
            inputProps={{ disabled: !weekdaySelected || (limitedShowtimeOption !== "Custom selection") }}
            // timeConstraints={this.timeConstraints}
            value={timeFrom}
            onChange={handleTimeFromChange}
          />
          {/* <DateTimePicker
            name={`${weekday}_from`}
            format={timeFormat}
            time={true}
            date={false}
            value={timeFrom}
            onChange={handleTimeFromChange}
            disabled={
              !weekdaySelected ||
              limitedShowtimeOption !==
              "Custom selection"
            }
          /> */}
        </div>
        <div className="col-1 text-center">{` - `}</div>
        <div className="col-3">
          <DateTime
            name={`${weekday}_until`}
            viewMode={"time"}
            dateFormat={false}
            inputProps={{ disabled: !weekdaySelected || (limitedShowtimeOption !== "Custom selection") }}
            // timeConstraints={this.timeConstraints}
            value={timeUntil}
            onChange={handleTimeUntilChange}
          />
          {/* <DateTimePicker
            name={`${weekday}_until`}
            format={timeFormat}
            time={true}
            date={false}
            value={timeUntil}
            onChange={handleTimeUntilChange}
            disabled={
              !weekdaySelected ||
              limitedShowtimeOption !==
              "Custom selection"
            }
          /> */}
        </div>
      </div>
    );
  }
}

export default PromoWeekday;