import React, { Component } from "react";
import PromoWeekday from "./PromoWeekday";
import DateTimePicker from "react-widgets/lib/DateTimePicker";

import "react-widgets/dist/css/react-widgets.css";

class PromoAvailability extends Component {
  render() {
    const {
      sectionNumber,
      limitedShowtimeOption,
      handleLimitedShowtimeOptionChange,
      timeFormat,
      dateFormat,
      mondaySelected,
      handleMondaySelectedChange,
      mondayTimeFrom,
      handleMondayFromChange,
      mondayTimeUntil,
      handleMondayTimeUntilChange,
      tuesdaySelected,
      handleTuesdaySelectedChange,
      tuesdayTimeFrom,
      handleTuesdayFromChange,
      tuesdayTimeUntil,
      handleTuesdayTimeUntilChange,
      wednesdaySelected,
      handleWednesdaySelectedChange,
      wednesdayTimeFrom,
      handleWednesdayFromChange,
      wednesdayTimeUntil,
      handleWednesdayTimeUntilChange,
      thursdaySelected,
      handleThursdaySelectedChange,
      thursdayTimeFrom,
      handleThursdayFromChange,
      thursdayTimeUntil,
      handleThursdayTimeUntilChange,
      fridaySelected,
      handleFridaySelectedChange,
      fridayTimeFrom,
      handleFridayFromChange,
      fridayTimeUntil,
      handleFridayTimeUntilChange,
      saturdaySelected,
      handleSaturdaySelectedChange,
      saturdayTimeFrom,
      handleSaturdayFromChange,
      saturdayTimeUntil,
      handleSaturdayTimeUntilChange,
      sundaySelected,
      handleSundaySelectedChange,
      sundayTimeFrom,
      handleSundayFromChange,
      sundayTimeUntil,
      handleSundayTimeUntilChange,
      availableOption,
      handleavailableOptionChange,
      availableFrom,
      handleavailableFromChange,
      availableUntil,
      handleavailableUntilChange
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>Set a "limited showtime" for this promo deal</strong>
        </div>
        <div className="card-body">
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">{`Days & time of the week:`}</strong>
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="limited_showtime_option"
                  value="24/7"
                  onChange={handleLimitedShowtimeOptionChange}
                  defaultChecked={
                    limitedShowtimeOption === "24/7"
                  }
                />
                <label className="form-check-label">
                  {`24/7 (Default)`}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="limited_showtime_option"
                  value="Custom selection"
                  onChange={handleLimitedShowtimeOptionChange}
                  defaultChecked={
                    limitedShowtimeOption ===
                    "Custom selection"
                  }
                />
                <label className="form-check-label">
                  {`Custom selection`}
                </label>
                <PromoWeekday 
                  weekday={`monday`} 
                  weekdaySelected = {mondaySelected}
                  handleWeekdaySelectedChange = {handleMondaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {mondayTimeFrom}
                  handleTimeFromChange = {handleMondayFromChange}
                  timeUntil = {mondayTimeUntil}
                  handleTimeUntilChange = {handleMondayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`tuesday`} 
                  weekdaySelected = {tuesdaySelected}
                  handleWeekdaySelectedChange = {handleTuesdaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {tuesdayTimeFrom}
                  handleTimeFromChange = {handleTuesdayFromChange}
                  timeUntil = {tuesdayTimeUntil}
                  handleTimeUntilChange = {handleTuesdayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`wednesday`} 
                  weekdaySelected = {wednesdaySelected}
                  handleWeekdaySelectedChange = {handleWednesdaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {wednesdayTimeFrom}
                  handleTimeFromChange = {handleWednesdayFromChange}
                  timeUntil = {wednesdayTimeUntil}
                  handleTimeUntilChange = {handleWednesdayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`thursday`} 
                  weekdaySelected = {thursdaySelected}
                  handleWeekdaySelectedChange = {handleThursdaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {thursdayTimeFrom}
                  handleTimeFromChange = {handleThursdayFromChange}
                  timeUntil = {thursdayTimeUntil}
                  handleTimeUntilChange = {handleThursdayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`friday`} 
                  weekdaySelected = {fridaySelected}
                  handleWeekdaySelectedChange = {handleFridaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {fridayTimeFrom}
                  handleTimeFromChange = {handleFridayFromChange}
                  timeUntil = {fridayTimeUntil}
                  handleTimeUntilChange = {handleFridayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`saturday`} 
                  weekdaySelected = {saturdaySelected}
                  handleWeekdaySelectedChange = {handleSaturdaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {saturdayTimeFrom}
                  handleTimeFromChange = {handleSaturdayFromChange}
                  timeUntil = {saturdayTimeUntil}
                  handleTimeUntilChange = {handleSaturdayTimeUntilChange}
                />
                <PromoWeekday 
                  weekday={`sunday`} 
                  weekdaySelected = {sundaySelected}
                  handleWeekdaySelectedChange = {handleSundaySelectedChange}
                  limitedShowtimeOption = {limitedShowtimeOption}
                  timeFormat = {timeFormat}
                  timeFrom = {sundayTimeFrom}
                  handleTimeFromChange = {handleSundayFromChange}
                  timeUntil = {sundayTimeUntil}
                  handleTimeUntilChange = {handleSundayTimeUntilChange}
                />                                                                                                
      
              </div>
            </div>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">{`Available between:`}</strong>
            <div className="col-12">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="available_option"
                  value="Unlimited"
                  onChange={handleavailableOptionChange}
                  defaultChecked={availableOption === "Unlimited"}
                />
                <label className="form-check-label">
                  {`Unlimited (Default)`}
                </label>
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="available_option"
                  value="Custom selection"
                  onChange={handleavailableOptionChange}
                  defaultChecked={availableOption === "Custom selection"}
                />
                <label className="form-check-label">
                  {`Custom selection`}
                </label>
                <div className="form-row mt-3">
                  <div className="col-4">
                    <DateTimePicker
                      name="available_from"
                      format={dateFormat}
                      time={false}
                      date={true}
                      value={availableFrom}
                      onChange={handleavailableFromChange}
                      disabled={
                        availableOption !== "Custom selection"
                      }
                    />
                  </div>
                  <div className="col-1 text-center">{` - `}</div>
                  <div className="col-4">
                    <DateTimePicker
                      name="available_until"
                      format={dateFormat}
                      time={false}
                      date={true}
                      value={availableUntil}
                      onChange={handleavailableUntilChange}
                      disabled={
                        availableOption !== "Custom selection"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoAvailability;