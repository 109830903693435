import React from 'react';
import PropTypes from 'prop-types';

import CartItem from './CartItem';

import { sort_menu_items } from '../../utils/sortUtil';

const CartItems = ({ items, handleRemoveItem, changeable, enableSpecialInstruction }) => {
  const sorted_items = sort_menu_items(items);
  return (
    <>
    {sorted_items.map((item, index) => (
      <CartItem          
        key={item.uuid}
        item={item}
        handleRemoveItem={handleRemoveItem}
        changeable={changeable}
        enableSpecialInstruction={enableSpecialInstruction}
      />
    ))}
    </>
  );
};

CartItems.propTypes = {
  items: PropTypes.array
};

export default CartItems;
