import React, { Component } from "react";


class PaymentMethodRewardRewardBenefit extends Component {
  render() {
    const {
      sectionNumber,
      discountPercentage,
      handleDiscountPercentageChange,
    } = this.props;

    return (
      <div className="card mb-3">
        <div className="card-header">
          <span className="badge badge-secondary mr-3">{sectionNumber}</span>{" "}
          <strong>
            Set promo deal benefit (What's in it for your client)
              </strong>
        </div>
        <div className="card-body">
          <div className="pb-3">
            <strong>{`Grant % discount on total order value`}</strong>
          </div>
          <div className="row promo-row pt-3 pb-3 mb-3">
            <strong className="col-12 mb-3">Set the benefit:</strong>
            <div className="col-12 col-lg-6">
              <div className="form-group">
                <label className="col-form-label">
                  Discount:
                    </label>
                <div className="input-group">
                  <input
                    name="discount_percentage"
                    type="number"
                    className="form-control"
                    value={discountPercentage}
                    onChange={handleDiscountPercentageChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              {`The discount amount will be deducted from the order cart sub-total value and it will be explicitly shown in the cart calculation.`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMethodRewardRewardBenefit;